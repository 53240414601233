import styled from "styled-components";

export const HeaderCard = styled.span`
  width: 100%;
  line-height: 0px;
  > h1 {
    color: #1b66b0;
  }

  > h4 {
    color: #596778;
  }
`;

export const BodyCard = styled.span`
  width: 100%;
  line-height: 0px;
  > span {
    color: orange;
    font-size: 14px;
  }
`;
