import styled from "styled-components";

export const Messages = styled.div``;

export const CardWithLateralHeader = styled.div`
  display: flex;
  align-items: stretch;
  width: 100%;

  margin-top: 20px;
  margin-left: ${props => (props.marginLeft ? props.marginLeft : "")};

  min-height: ${props => (props.height ? props.height : "125px")};

  main > .input-container {
    display: flex;
  }

  main {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;

    padding: 8px;
    border: 1px solid #1b66b0;
    border-radius: 4px;
  }
`;

export const Header = styled.header`
  display: flex;
  flex-basis: 100%;
  flex: 1;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  writing-mode: vertical-lr;
  transform: rotate(-180deg);

  font-size: 15px;
  color: #fff;
  font-weight: 700;
  width: 50px;

  border: 1px solid #1b66b0;
  border-radius: 4px;
  background-color: #1b66b0;

  button {
    display: flex;
    background: none;
    margin: 5px;

    svg {
      color: #fff;
    }
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex: ${props => (props.flex ? props.flex : 1)};
  flex-direction: column;
  margin-top: 12px;
  max-width: ${props => (props.maxWidth ? props.maxWidth : "290px")};

  & + & {
    margin-left: 12px;
  }

  label,
  input,
  select,
  textarea {
    font-size: 14px;
    color: #596778;
  }

  select {
    height: 36px;
    background-color: #fff;
  }

  input,
  select,
  textarea {
    border: 1px solid #596778;
    border-radius: 5px;
    padding: 4px 10px;
  }

  /* div {
    display: flex;
    align-items: center;
  } */

  label {
    margin-left: 10px;
  }
`;

export const Button = styled.button`
  margin: 26px 0 0 12px;
  background-color: #adc86a;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  padding: 8px;
  border-radius: 5px;
  align-self: flex-start;
  margin-right: auto;
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

export const CestResult = styled.div`
  margin: 10px 0 0 0;
  border-radius: 20px;
  background-color: #204f8d;
  padding: 5px 10px 5px 20px;
  color: #fff;
  width: 200px;
  height: 40px;
  display: flex;
  border-radius: 5px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  padding: 3px 11px 5px 10px;
  color: #fff;

  &:hover {
    color: #fff;
    opacity: 1;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 18%);
    width: 100% !important;
    background: #17456b !important;
  }
`;
