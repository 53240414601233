/* eslint-disable func-names */
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = yup.object().shape({
  cest: yup.string().required(),
  anexo: yup.string(),
  segmento: yup.string(),
  item: yup.string(),
  description: yup.string(),
});

export const DefaultValues = {
  cest: "",
  anexo: "",
  segmento: "",
  descrition: "",
  item: "",
  ncm: [],
};

export const Validation = yupResolver(schema);
