import styled, { css } from "styled-components";

export const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

export const CheckSpan = styled.span`
  position: absolute;
  top: calc(50% - 12.5px);
  left: 0;
  height: 25px;
  width: 25px;
  border-radius: 2px;
  border: 2px solid #8c8b8b;

  &:after {
    content: "";
    position: absolute;
    display: none;
  }
`;

const labelModifiers = {
  disabled: () => css`
    opacity: 0.4;
  `,
};

export const Label = styled.label`
  ${({ disabled }) => css`
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    user-select: none;

    &:hover ${Input} ~ ${CheckSpan} {
      background-color: #ccc;
    }

    & ${Input}:checked ~ ${CheckSpan} {
      background-color: #1b66b0;
      border: 0px;
    }

    & ${Input}:checked ~ ${CheckSpan}:after {
      display: block;
    }

    & ${CheckSpan}:after {
      left: 8px;
      top: 1px;
      width: 7px;
      height: 14px;
      border: solid white;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }

    ${disabled && labelModifiers.disabled()}
  `}
`;
