import styled, { css } from "styled-components";

export const Form = styled.form``;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  padding: 0px 10px;
`;

const inputContainerFormModifiers = {
  flex: () => css`
    display: flex;
    flex-flow: row wrap;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
  `,
  block: () =>
    css`
      display: block;
    `,
};

export const InputContainerForm = styled.div`
  ${({ isBlock }) => css`
    ${isBlock && inputContainerFormModifiers.block()}
    ${!isBlock && inputContainerFormModifiers.flex()}
  `}
`;
