import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
`;

export const ContainerGrid = styled.div`
  width: 100%;

  & > div:nth-child(4) {
    margin-top: 10px;
    padding-bottom: 1rem;
  }

  & td:nth-child(6),
  & td:nth-child(7),
  & td:nth-child(8) {
    overflow: initial;
  }

  & td:nth-child(8) {
    width: 140px;

    ${Container} {
      padding: 0 7px;
    }
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  input {
    border-bottom: 1px solid #e5e5e5;
  }

  .input-container {
    display: flex;
  }
`;

export const TableListProducts = styled.div`
  table tbody tr td:nth-child(2) {
    max-width: 400px;
  }
`;

export const ActionsProducts = styled.div`
  margin-bottom: 1rem;
  display: flex;
  gap: 10px;
`;

export const HeaderResultProducts = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ColumnIconLink = styled.a`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
`;
