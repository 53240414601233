import styled from "styled-components";

export const Container = styled.div`
  width: fit-content;
  height: fit-content;
  border-radius: 5px;
  background-color: #fff;
  border: 0.5px solid #b9b9b9;
  border-top: 12px solid ${props => props.color};

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 16px;

  header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;

    p {
      font-size: 12px;
      color: #17456b;
    }
    p.title {
      font-size: 14px;
      font-weight: bold;
    }
  }

  .actionsContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    margin-bottom: 8px;

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      p {
        font-size: 10px;
        color: #17456b;
      }

      svg {
        fill: #17456b;
      }
    }
  }

  footer {
    p {
      font-size: 10px;
      color: #747474;
      text-align: center;
    }
  }
`;
