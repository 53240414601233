import React, { useState, useEffect, useRef, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Tooltip } from "@mix/mixfiscal-designsystem";
import Select from "react-select";
import { useOnClickOutside } from "hooks";
import { getProductFilterSession } from "utils";

import { useProductsContext } from "context/ProductsContext";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClockRotateLeft,
  faClose,
  faFilter,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

import { TextField } from "components/form";

import { StoreSelectSearch } from "./Selects";

import * as S from "./Filter.styles";

const operator = [
  {
    label: "Igual",
    value: "equals",
  },
  {
    label: "Contém",
    value: "contains",
  },
  {
    label: "Não Contém",
    value: "notContains",
  },
  {
    label: "termina com",
    value: "endsWith",
  },
  {
    label: "Vazio",
    value: "empty",
  },
  {
    label: "Não Vazio",
    value: "notEmpty",
  },
];

const operatorOR = [
  {
    label: "E",
    value: "and",
  },
  {
    label: "OU",
    value: "or",
  },
];

export const FilterForm = ({
  columns,
  listFilter,
  handleOnChange,
  handleAdd,
  handleRemove,
  onClick,
  handleRemoveAll,
  handleSetSessionFilter,
  isProductFilterSession,
}) => {
  const [isShowSpacing, setIsShowSpacing] = useState(false);

  useEffect(() => {
    if (listFilter.length === 1) {
      setIsShowSpacing(false);
    }
  }, [listFilter, setIsShowSpacing, isShowSpacing]);

  return (
    <>
      <S.FilterContentTitle>
        <h2>
          <FontAwesomeIcon
            icon={faFilter}
            style={{
              fontSize: "15px",
            }}
          />
          {` FILTROS`}
        </h2>
      </S.FilterContentTitle>
      <S.FIlterContent>
        {listFilter
          .filter(
            itemLi => itemLi.headerText !== " " || itemLi.headerText !== "",
          )
          .map((itemLi, key) => {
            return (
              <>
                <S.FIlterContentItem key={key}>
                  {itemLi?.columnField?.columnOrField && key !== 0 ? (
                    <S.FIlterContentField flex="0 0 30px" margin="25px 0 0 0">
                      <FontAwesomeIcon
                        onClick={() => handleRemove(key)}
                        icon={faClose}
                        style={{
                          fontSize: "15px",
                        }}
                      />
                    </S.FIlterContentField>
                  ) : (
                    ""
                  )}

                  <S.FIlterContentField flex="0 0 1">
                    <label>Operador (OU/E)</label>
                    <Select
                      name="columnOrField"
                      options={operatorOR.map(item => ({
                        value: item.value,
                        label: item.label,
                      }))}
                      value={(operatorOR || [])
                        .filter(
                          item =>
                            item?.value === itemLi?.columnField?.columnOrField,
                        )
                        .map(item => ({
                          value: item.value,
                          label: item.label,
                        }))}
                      onChange={e => {
                        handleOnChange(`columnOrField`, e.value, key);
                      }}
                    />
                  </S.FIlterContentField>

                  {isShowSpacing ? (
                    <>
                      <S.FIlterContentField
                        flex="0 0 30px"
                        margin="25px 0 0 0"
                      />
                    </>
                  ) : (
                    ""
                  )}

                  <S.FIlterContentField flex="1">
                    <label>Coluna</label>
                    <S.FilterContentFilterFieldITem>
                      <Select
                        name="columnField"
                        options={columns.map(item => ({
                          value: item.field,
                          label: item.headerText,
                        }))}
                        value={(columns || [])
                          .filter(
                            item =>
                              item?.field === itemLi?.columnField?.columnField,
                          )
                          .map(item => ({
                            value: item.field,
                            label: item.headerText,
                          }))}
                        onChange={e => {
                          handleOnChange(`columnField`, e.value, key);
                        }}
                      />
                    </S.FilterContentFilterFieldITem>
                  </S.FIlterContentField>
                  <S.FIlterContentField flex="1">
                    <label>Operador</label>
                    <S.FilterContentFilterFieldITem>
                      <Select
                        name="operatorValue"
                        options={operator.map(item => ({
                          value: item.value,
                          label: item.label,
                        }))}
                        value={(operator || [])
                          .filter(
                            item =>
                              item?.value ===
                              itemLi?.columnField?.operatorValue,
                          )
                          .map(item => ({
                            value: item.value,
                            label: item.label,
                          }))}
                        onChange={e => {
                          handleOnChange(`operatorValue`, e.value, key);
                        }}
                      />
                    </S.FilterContentFilterFieldITem>
                  </S.FIlterContentField>
                  <S.FIlterContentField flex="1">
                    <label>Buscar por</label>

                    <S.FilterContentFilterFieldITem>
                      {itemLi?.columnField?.columnField === "companyId" ? (
                        <>
                          <StoreSelectSearch
                            handleValue={value => {
                              handleOnChange(`value`, value, key);
                            }}
                            key={key}
                          />
                        </>
                      ) : (
                        <TextField
                          name="value"
                          value={itemLi?.columnField?.value || ""}
                          onChange={e => {
                            handleOnChange(`value`, e.target.value, key);
                          }}
                        />
                      )}
                    </S.FilterContentFilterFieldITem>
                  </S.FIlterContentField>
                </S.FIlterContentItem>
              </>
            );
          })}
        <S.FIlterContentItem align="right" margin="10px 0 0 0">
          <S.FilterContentBtnAdd
            color="transparent"
            onClick={() => {
              handleAdd();
              if (!isShowSpacing) {
                setIsShowSpacing(true);
              }
            }}
          >
            <FontAwesomeIcon
              icon={faPlus}
              style={{
                fontSize: "15px",
              }}
            />
            Adicionar
          </S.FilterContentBtnAdd>
          {/* {listFilter.length > 1 ? ( */}
          <S.FilterContentBtnAdd
            onClick={() => handleRemoveAll()}
            color="red"
            textColor="#fff"
          >
            Limpar
            <FontAwesomeIcon
              icon={faTrash}
              style={{
                margin: "0 0 0 5px",
                fontSize: "15px",
              }}
            />
          </S.FilterContentBtnAdd>
          {isProductFilterSession?.isSetFilterShow ? (
            <Tooltip
              body="Buscar por último filtro"
              position="top-start"
              zIndex={999999}
              zIndexBackdrop={1}
            >
              <S.FilterContentBtnAdd
                onClick={() => handleSetSessionFilter()}
                color="#5A8DEE"
                width="70px"
                textColor="#fff"
              >
                <FontAwesomeIcon
                  icon={faClockRotateLeft}
                  style={{
                    margin: "0 0 0 5px",
                    fontSize: "15px",
                  }}
                />
              </S.FilterContentBtnAdd>
            </Tooltip>
          ) : (
            ``
          )}
          <S.FilterContentBtnAdd
            onClick={() => onClick()}
            color="#256925"
            textColor="#fff"
          >
            Filtrar
            <FontAwesomeIcon
              icon={faFilter}
              style={{
                margin: "0 0 0 5px",
                fontSize: "15px",
              }}
            />
          </S.FilterContentBtnAdd>
        </S.FIlterContentItem>
      </S.FIlterContent>
    </>
  );
};

export const FilterNew = ({
  columns,
  handleFilter,
  isShowBtnAdd = true,
  action,
}) => {
  const productfiltersession = getProductFilterSession();
  const history = useHistory();

  const [isProductFilterSession, setIsProductFilterSession] = useState({
    isSetFilterShow: false,
    isSetFilterSeted: false,
  });

  const {
    isShowDialog,
    isShowFilter,
    setIsShowFilter,
    filters: filtersFilds,
    setFilters,
    handleOnChangeFilter,
    handleAddFilter,
    handleRemoveFilter,
    handleRemoveFilterAll,
    handleSetSessionFilter,
  } = useProductsContext();
  const ref = useRef();

  useOnClickOutside(ref, () =>
    !isShowDialog?.show ? setIsShowFilter(false) : setIsShowFilter(true),
  );

  const toogleIshow = value => {
    setIsShowFilter(value);
  };

  const handleSession = useCallback(() => {
    const filterSession = JSON.parse(productfiltersession);
    if (filterSession && filterSession?.length > 0) {
      handleSetSessionFilter(filterSession);
      setIsProductFilterSession({
        isSetFilterShow: false,
        isSetFilterSeted: true,
      });
    }
  }, [productfiltersession, handleSetSessionFilter]);

  useEffect(() => {
    const filterSession = JSON.parse(productfiltersession);
    if (filterSession && !isProductFilterSession?.isSetFilterShow) {
      setIsProductFilterSession({
        isSetFilterShow: true,
        isSetFilterSeted: false,
      });
    }
  }, [productfiltersession, isProductFilterSession, setIsProductFilterSession]);

  return (
    <S.FilterContainer>
      <S.FilterContainerActions>
        <S.FilterContainerActionsItem align="left" width="80%">
          {isShowBtnAdd && (
            <S.ButtonFilter
              type="button"
              onClick={() => history.push(`/product/new`)}
            >
              <FontAwesomeIcon
                icon={faPlus}
                style={{
                  fontSize: "15px",
                }}
              />
              ADICIONAR NOVO
            </S.ButtonFilter>
          )}
          <S.ButtonFilter
            type="button"
            onClick={() => toogleIshow(!isShowFilter)}
          >
            FILTRAR
          </S.ButtonFilter>
        </S.FilterContainerActionsItem>
        <S.FilterContainerActionsItem width="20%" align="right">
          {action ?? action}
        </S.FilterContainerActionsItem>
      </S.FilterContainerActions>

      <S.FilterItemShow ref={ref}>
        {isShowFilter ? (
          <FilterForm
            ref={ref}
            columns={columns}
            listFilter={filtersFilds}
            setListFilter={setFilters}
            handleAdd={handleAddFilter}
            handleRemove={handleRemoveFilter}
            handleOnChange={handleOnChangeFilter}
            isProductFilterSession={isProductFilterSession}
            handleSetSessionFilter={handleSession}
            onClick={() => {
              if (!isShowDialog?.show) {
                setIsShowFilter(false);
              }
              handleFilter(filtersFilds);
            }}
            handleRemoveAll={handleRemoveFilterAll}
          />
        ) : (
          ``
        )}
      </S.FilterItemShow>
    </S.FilterContainer>
  );
};
