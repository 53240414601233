import React from "react";

import * as S from "./styles";
import { getMatches, getAllPositions } from "../../../../../utils";
import Icon from "../../../../IconExporter";

/**
 * Wrapper for labels with bold effect on search
 * @param {String} label full label string
 * @param {Array} matches all regex matches for a given search
 * @param {JSX.Element} Wrapper component wapper for searchs
 * @returns returns a label with bold effect
 */
export const withBoldEffect = (label, matches) => {
  const bold = matches.map(m => m.indices.pop());
  const all = getAllPositions(label, bold);
  return all.reduce(
    (withBold, p) => [
      ...withBold,
      bold.includes(p) ? (
        <S.Bold>{label.slice(...p)}</S.Bold>
      ) : (
        label.slice(...p)
      ),
    ],
    [],
  );
};

export const DropdownCheckbox = ({
  search,
  label,
  onClick,
  height,
  withHover,
  checked,
}) => {
  const matches = getMatches(label, search);

  return (
    <S.Container height={height} onClick={onClick} withHover={withHover}>
      <S.Selection>
        {checked ? (
          <S.CheckboxSelected>
            <Icon name="check" size={10} color="white" />
          </S.CheckboxSelected>
        ) : (
          <S.Checkbox />
        )}
      </S.Selection>
      <S.Label>
        {matches.length ? withBoldEffect(label, matches) : label}
      </S.Label>
    </S.Container>
  );
};
