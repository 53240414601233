import React, { useState } from "react";
import { Switch as SwitchMaterial, withStyles } from "@material-ui/core";
// import { Container } from './styles';

const CustomSwitch = withStyles({
  input: {
    left: 0,
  },
})(SwitchMaterial);
const Switch = ({ name, initialValue = false, handleChange }) => {
  const [value, setValue] = useState(initialValue);
  return (
    <CustomSwitch
      color="primary"
      name={name}
      checked={value}
      value={value}
      onChange={e => {
        setValue(!value);
        if (handleChange) handleChange(e);
      }}
    />
  );
};

export default Switch;
