import styled from "styled-components";

export const SalesPurchaseDetails = styled.div`
  width: 70%;
  display: grid;
  margin-top: 9px;
  margin-left: 8px;
  grid-template-areas:
    "l1c1  l1c2 l1c3 l1c4 l1c5 l1c6 l1c7 l1c8"
    "l2c1  l2c2 l2c3 l2c4 l2c5 l2c6 l2c7 l2c8";
  grid-template-rows: 24px 24px;
  grid-template-columns: 94px 51px 94px 94px 94px 51px 94px 92px;
  text-align: center;
  p {
    font-size: 12px;
    font-family: Cairo;
    color: #596778;
  }

  .l1c1 {
    grid-area: l1c1;
    border: 1px solid #596778;
    background-color: #fce7c0;
  }
  .l1c2 {
    grid-area: l1c2;
    border-right: 1px solid #596778;
    border-top: 1px solid #596778;
    border-bottom: 1px solid #596778;
    background-color: #fce7c0;
  }
  .l1c3 {
    grid-area: l1c3;
    border-right: 1px solid #596778;
    border-top: 1px solid #596778;
    border-bottom: 1px solid #596778;
    background-color: #fce7c0;
  }
  .l1c4 {
    grid-area: l1c4;
    border-right: 1px solid #596778;
    border-top: 1px solid #596778;
    border-bottom: 1px solid #596778;
    background-color: #fce7c0;
  }
  .l1c5 {
    grid-area: l1c5;
    border-right: 1px solid #596778;
    border-top: 1px solid #596778;
    border-bottom: 1px solid #596778;
    background-color: #dee8cc;
  }
  .l1c6 {
    grid-area: l1c6;
    border-right: 1px solid #596778;
    border-top: 1px solid #596778;
    border-bottom: 1px solid #596778;
    background-color: #dee8cc;
  }
  .l1c7 {
    grid-area: l1c7;
    border-right: 1px solid #596778;
    border-top: 1px solid #596778;
    border-bottom: 1px solid #596778;
    background-color: #dee8cc;
  }
  .l1c8 {
    grid-area: l1c8;
    border-right: 1px solid #596778;
    border-top: 1px solid #596778;
    border-bottom: 1px solid #596778;
    background-color: #dee8cc;
  }

  .l2c1 {
    grid-area: l2c1;
    border-left: 1px solid #596778;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    font-weight: bold;
    background-color: #fce7c0;
  }
  .l2c2 {
    grid-area: l2c2;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    font-weight: bold;
    background-color: #fce7c0;
  }
  .l2c3 {
    grid-area: l2c3;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    font-weight: bold;
    background-color: #fce7c0;
  }
  .l2c4 {
    grid-area: l2c4;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    font-weight: bold;
    background-color: #fce7c0;
  }
  .l2c5 {
    grid-area: l2c5;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    font-weight: bold;
    background-color: #dee8cc;
  }
  .l2c6 {
    grid-area: l2c6;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    font-weight: bold;
    background-color: #dee8cc;
  }
  .l2c7 {
    grid-area: l2c7;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    font-weight: bold;
    background-color: #dee8cc;
  }
  .l2c8 {
    grid-area: l2c8;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    font-weight: bold;
    background-color: #dee8cc;
  }
`;
export const MidCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 232px !important;
`;

export const MidCardBlocOne = styled.div`
  height: 130px;
  width: 35%;
`;

export const MidCardBlocTwo = styled.div`
  width: 40%;
  font-size: 13px;
  color: #596778;
  font-weight: bold;
  text-align: right;
  > p:first-child {
    margin-top: 30%;
  }
`;
export const DashboardsContainer = styled.div`
  width: 100%;
  /* height: 242px !important; */
  display: grid;
  margin-top: 6px;
  margin-left: 10px;
  grid-template-areas: "dashColOne  dashColTwo";
  grid-template-rows: 230px;
  grid-template-columns: 49% 49%;
  grid-column-gap: 2%;
  h1 {
    font-size: 18px;
    font-weight: 600;
  }
  section {
    height: 242px;

    & > div:last-of-type {
      margin-top: -40px;
    }
  }
  p {
    font-size: 10px;
    font-family: Cairo;
    color: #596778;
  }
  span {
    font-size: 18px;
  }

  .dashColOne {
    grid-area: dashColOne;

    p {
      div:nth-child(2) {
        margin-top: 3px;
      }
      div:nth-child(1) {
        margin-top: 2px;
      }
    }
  }

  .dashColTwo {
    grid-area: dashColTwo;

    p {
      div:nth-child(2) {
        margin-top: 3px;
      }
      div:nth-child(1) {
        margin-top: 2px;
      }
    }
  }
`;
