import React, { useState, useEffect } from "react";
import { Tooltip } from "@mix/mixfiscal-designsystem";
import { FiPlus } from "react-icons/fi";
import { useStores } from "useCases";
import { Button } from "components/structure";
import Select from "react-select";

import * as S from "./styles";

export const SelectStore = ({
  companyId,
  onChange,
  handleAdd,
  selectedCompany,
  width,
}) => {
  const { data } = useStores({ companyId, page: 1, limit: 100 });
  const [selectedStore, setSelectedStore] = useState();
  const [parseStores, setParseStores] = useState({});

  useEffect(() => {
    let dataStore;
    if (data?.stores) {
      if (selectedCompany?.label && data?.stores?.docs?.length > 0) {
        dataStore = data?.stores.docs?.map(item => ({
          label: item.companyName,
          value: item._id,
        }));
      }
      setParseStores(dataStore);
    }
  }, [data?.stores, selectedCompany]);

  return (
    <>
      <label>Filial</label>
      <S.SelecWrapper>
        <S.SelectedItem width={width}>
          <Select
            label="Filial"
            options={parseStores}
            className="select-width-80"
            classNamePrefix="select"
            placeholder="Selecione"
            styles={{
              control: styles => ({
                ...styles,
                // width: "355px",
                border: "1px solid #596778",
              }),
            }}
            onChange={selected => {
              onChange(selected.value);
              setSelectedStore(selected);
            }}
            value={selectedStore}
          />
        </S.SelectedItem>
        {handleAdd && (
          <S.SelectedItemButton>
            <Button type="button" onClick={handleAdd}>
              <Tooltip title="Adicionar Filial" position="top">
                <FiPlus size={20} color="white" className="icon" />
              </Tooltip>
            </Button>
          </S.SelectedItemButton>
        )}
      </S.SelecWrapper>
    </>
  );
};
