import React from "react";
import { Select } from "components/form";
import { useUsers } from "useCases/user";

export const AssessoresSelect = ({ params, value, ...props }) => {
  const { data, isLoading } = useUsers({
    page: 1,
    limit: 9999,
  });

  const items = (data?.users?.docs ?? []).map(user => ({
    label: `${user.name} ${user.surname}`,
    value: user,
  }));

  return (
    <Select
      name="user"
      label="Assessor"
      labelType="top"
      value={items.find(user => user?.value._id === value?._id)}
      isLoading={isLoading}
      data={items}
      {...props}
    />
  );
};
