import React, { useState } from "react";
import { Tooltip } from "@mix/mixfiscal-designsystem";
import { DropdownSearch } from "../dropdown-search";
import { DropdownSearchCNPJ } from "../dropdown-search-cnpj";
import { usePermissions } from "../../../../../context/PermissionsContext";
import { CustomTooltip } from "./tooltip";
import * as S from "./styles";

export const LabelPermissions = () => {
  const [showSelectGroup, setShowSelectGroup] = useState(false);
  const [showSelectCompanies, setShowSelectCompanies] = useState(false);
  const [showSelectStores, setShowSelectStores] = useState(false);
  const [isDisableMatrizSearch] = useState(true);
  const {
    groups,
    selectedGroup,
    companies,
    selectedCompany,
    stores,
    selectedStore,
    selectedAllCompany,
    selectedAllStore,
    handleSelectGroup,
    handleSelectCompany,
    handleSelectStore,
    handleCheckAllCompany,
    handleCheckAllStore,
    refetchPermissionsAdv,
    selectedCompanySearch,
  } = usePermissions();

  const getSelectedCompanyLabel = selected => {
    if (selected?.length > 1) {
      return (
        <S.BadgeContainer>
          <Tooltip
            component={<CustomTooltip selected={selected} />}
            position="bottom-start"
            zIndex={99999}
          >
            {`${selected?.length} MATRIZ selecionadas`}
          </Tooltip>
        </S.BadgeContainer>
      );
    }
    if (selected?.length === 1) {
      return (
        <S.BadgeContainer>
          <span>
            {selected[0].company_social_name ||
              selected[0].company_name ||
              selected[0].tradeName ||
              selected[0].companyName}
          </span>
        </S.BadgeContainer>
      );
    }
  };

  const getSelectedCompanyTotal = selected => {
    if (selected?.length > 1) {
      return `${selected?.length} matrizes selecionadas`;
    }

    if (selected?.length === 1) {
      return (
        selected[0].company_social_name ||
        selected[0].company_name ||
        selected[0].tradeName ||
        selected[0].companyName
      );
    }
    if (!selected?.length) {
      if (companies && companies.length > 0) {
        return ` ${companies.length} Matrizes encontradas`;
      }
      return "Nenhuma matriz encontrada";
    }
  };

  const getSelectedStoreLabel = selected => {
    if (selected?.length > 1) {
      return `${selected?.length} Filiais  selecionadas`;
    }
    if (selected?.length === 1) {
      return (
        selected[0].company_social_name ||
        selected[0].company_name ||
        selected[0].tradeName ||
        selected[0].companyName
      );
    }
  };

  return (
    <S.Container>
      <p>Visualizando como</p>
      <DropdownSearch
        name="access-groups"
        placeholder="Grupos de acesso"
        isOpen={showSelectGroup}
        label={selectedGroup?.groupName ?? "Grupos de acesso"}
        selected={selectedGroup}
        onClick={() => selectedGroup && setShowSelectGroup(!showSelectGroup)}
        close={() => setShowSelectGroup(false)}
        footerMessage={{
          singular: "grupo de acesso encontrado",
          plural: "grupos de acesso encontrados",
        }}
        onChangeCallback={handleSelectGroup}
        data={(groups || []).map(group => ({
          label: group.groupName,
          ...group,
        }))}
      />
      {isDisableMatrizSearch || selectedCompanySearch ? (
        <DropdownSearchCNPJ
          name="companies"
          placeholder="Razão Social ou CNPJ"
          isOpen={showSelectCompanies}
          label={
            getSelectedCompanyLabel(selectedCompany || selectedCompanySearch) ??
            "Matriz"
          }
          selected={selectedCompany || []}
          onClick={() =>
            (companies || []).length &&
            setShowSelectCompanies(!showSelectCompanies)
          }
          close={() => setShowSelectCompanies(false)}
          footerMessage={{
            singular: "Matriz encontrada",
            plural: getSelectedCompanyTotal(selectedCompany),
          }}
          onChangeCallback={(i, company) => {
            handleSelectCompany(i, company);
            refetchPermissionsAdv();
          }}
          onSelectAll={handleCheckAllCompany}
          selectedAll={selectedAllCompany}
          data={(companies || []).map(company => ({
            label:
              company.company_social_name ||
              company.company_name ||
              company.tradeName ||
              company.companyName,
            ...company,
          }))}
          multiselect
        />
      ) : (
        ``
      )}

      {!!(selectedCompanySearch || selectedCompany)?.length && (
        <>
          <DropdownSearchCNPJ
            name="stores"
            placeholder="Razão Social ou CNPJ"
            isOpen={showSelectStores}
            label={getSelectedStoreLabel(selectedStore) ?? "Selecionar Filial"}
            selected={selectedStore || []}
            onClick={() => setShowSelectStores(!showSelectStores)}
            close={() => setShowSelectStores(false)}
            footerMessage={{
              singular: "filial encontrada",
              plural: "filiais encontradas",
            }}
            onChangeCallback={(i, store) => handleSelectStore(i, store)}
            onSelectAll={handleCheckAllStore}
            selectedAll={selectedAllStore}
            data={(stores || []).map(store => ({
              label:
                store.company_social_name ||
                store.company_name ||
                store.tradeName ||
                store.companyName,
              ...store,
            }))}
            multiselect
          />
        </>
      )}
    </S.Container>
  );
};
