import React, { useState } from "react";
import { Icon } from "@mix/mixfiscal-designsystem";
import { MdKeyboardArrowUp } from "react-icons/md";
import { useToast } from "../../../../context/ToastContext";
import authenticationApi from "../../../../services/authenticationApi";

import * as S from "./styles";

const Star = () => <Icon name="estrela" color="#ecd540" size={14} />;
const StarOff = () => <Icon name="estrela" color="#bbbcbd" size={14} />;

function TributeSide({
  dataProduct,
  dataTribute,
  ProductTwin,
  ean0200Details,
}) {
  const { addToast } = useToast();
  const [isOpen, setIsOpen] = useState(false);
  const icmsVenda = dataTribute?.data.icmsVenda;
  const diagResult = ean0200Details?.data?.diagnostico;
  const [name, setName] = useState(false);
  const [ean, setEan] = useState(false);
  const [ncm, setNcm] = useState(false);
  const [cest, setCest] = useState(false);
  const [icms, setIcms] = useState(false);
  const [pis, setPis] = useState(false);
  const [setor, setSetor] = useState(false);
  const [categoria, setCategoria] = useState(false);
  const [saida, setSaida] = useState(false);
  const [outra, setOutra] = useState(false);
  const [text, setText] = useState("");

  const token = localStorage.getItem("@mixfiscal:authenticatorToken");
  const userData = localStorage.getItem("@mixfiscal:user");

  const handleDivergenceEmail = async body => {
    const userDataParsed = JSON.parse(userData);
    if (!body) return;
    authenticationApi
      .post(
        `/user/sendDivergenceEmail`,
        {
          body,
          email: userDataParsed.email,
        },
        {
          headers: {
            Authorization: token,
          },
        },
      )
      .then(() => {
        addToast({
          type: "success",
          title: "Enviado com Sucesso",
          description: `Sua divergência foi enviada com sucesso.`,
        });
      });
  };

  function sendDivergence() {
    const dataDivergence = {
      cenario: "EI, MG, SP, LUCRO REAL",
      produto: ProductTwin?.prod_nome || dataProduct?.pcli_nome,
      namecheck: name,
      eancheck: ean,
      ncmcheck: ncm,
      cestcheck: cest,
      icmscheck: icms,
      pischeck: pis,
      setorcheck: setor,
      categoriacheck: categoria,
      saidacheck: saida,
      outracheck: outra,
    };
    handleDivergenceEmail(dataDivergence);
    setIsOpen(!isOpen);
    setName(false);
    setEan(false);
    setNcm(false);
    setCest(false);
    setIcms(false);
    setPis(false);
    setSetor(false);
    setCategoria(false);
    setSaida(false);
    setOutra(false);
    setText("");
  }
  console.log(diagResult);
  return (
    <>
      <S.DivergeContainer isOpen={isOpen}>
        <div className="head">
          <p>ENCONTROU ALGUMA DIVERGÊNCIA?&nbsp;&nbsp;</p>
          <S.IconContainer onClick={() => setIsOpen(!isOpen)} isOpen={isOpen}>
            <MdKeyboardArrowUp size={24} />
          </S.IconContainer>
        </div>
        <div className="content">
          <div className="l1c1">
            <p>Cenario</p>
          </div>
          <div className="l1c2">
            <p>EI, MG, SP, LUCRO REAL</p>
          </div>
          <div className="l2c1">
            <p>Produto</p>
          </div>
          <div className="l2c2">
            <p>{ProductTwin?.prod_nome || dataProduct?.pcli_nome}</p>
          </div>
          <div className="l3c1">
            <p>Divergência </p>
          </div>
          <div className="l3c2">
            <div>
              <input
                id="nome-check"
                type="checkbox"
                value={name}
                onChange={e => setName(e.target.checked)}
              />
              <label>Nome</label>
              <input
                id="ean-check"
                type="checkbox"
                className="first-input"
                value={ean}
                onChange={e => setEan(e.target.checked)}
              />
              <label>Ean</label>
            </div>
            <div>
              <input
                id="ncm-check"
                type="checkbox"
                value={ncm}
                onChange={e => setNcm(e.target.checked)}
              />
              <label>Ncm</label>
              <input
                id="cest-check"
                type="checkbox"
                className="second-input"
                value={cest}
                onChange={e => setCest(e.target.checked)}
              />
              <label>Cest</label>
            </div>
            <div>
              <input
                id="icms-check"
                type="checkbox"
                value={icms}
                onChange={e => setIcms(e.target.checked)}
              />
              <label>Icms</label>
              <input
                id="pis-check"
                type="checkbox"
                className="third-input"
                value={pis}
                onChange={e => setPis(e.target.checked)}
              />
              <label>Pis/Cofins</label>
            </div>
            <div>
              <input
                id="setor-check"
                type="checkbox"
                value={setor}
                onChange={e => setSetor(e.target.checked)}
              />
              <label>Setor</label>
              <input
                id="categoria-check"
                type="checkbox"
                className="four-input"
                value={categoria}
                onChange={e => setCategoria(e.target.checked)}
              />
              <label>Categoria</label>
            </div>
            <div>
              <input
                id="saida-check"
                type="checkbox"
                value={saida}
                onChange={e => setSaida(e.target.checked)}
              />
              <label>Saida Simples Nacional</label>
            </div>
            <div>
              <input
                id="outra-check"
                type="checkbox"
                value={outra}
                onChange={e => setOutra(e.target.checked)}
              />
              <label>Outra</label>
            </div>
          </div>
          <div className="l4c1">
            <textarea
              placeholder="Descreva a divergencia"
              value={text}
              onChange={e => setText(e.target.value)}
            />
          </div>
          <div className="l5c1">
            <button type="submit" onClick={() => sendDivergence()}>
              ENVIAR
            </button>
          </div>
        </div>
      </S.DivergeContainer>
      <S.CamposAdicionaisContainer>
        <div className="head">
          <p>ICMS - CAMPOS ADICIONAIS A VENDA</p>
        </div>
        <div className="l1c1">
          <p>Redução Base Cálculo</p>
        </div>
        <div className="l1c2">
          <p>{icmsVenda?.rbc === null ? "-" : icmsVenda?.rbc}</p>
        </div>
        <div className="l2c1">
          <p>Redução Base Cálculo ST</p>
        </div>
        <div className="l2c2">
          <p>{icmsVenda?.rbcst === null ? "-" : icmsVenda?.rbcst}</p>
        </div>
        <div className="l3c1">
          <p>Mva</p>
        </div>
        <div className="l3c2">
          <p>{icmsVenda?.tipo_MVA === null ? "-" : icmsVenda?.tipo_MVA}</p>
        </div>
        <div className="l4c1">
          <p>CBenef</p>
        </div>
        <div className="l4c2">
          <p>{icmsVenda?.cbenef === null ? "-" : icmsVenda?.cbenef}</p>
        </div>
        <div className="l5c1">
          <p>Fecp</p>
        </div>
        <div className="l5c2">
          <p>{icmsVenda?.fecp === null ? "-" : icmsVenda?.fecp}</p>
        </div>
      </S.CamposAdicionaisContainer>

      <S.DiagnosticContainer>
        <div className="head">
          <p>DIAGNÓSTICO DO ESPECIALISTA</p>
        </div>
        <div className="l1c1">
          <p>Poder de Arrasto</p>
        </div>
        <div className="l1c2">
          {diagResult === undefined ? (
            <p>Sem Dados</p>
          ) : (
            <>
              <p>{diagResult?.poder_arrasto >= 1 ? <Star /> : <StarOff />}</p>
              <p>{diagResult?.poder_arrasto >= 2 ? <Star /> : <StarOff />}</p>
              <p>{diagResult?.poder_arrasto >= 3 ? <Star /> : <StarOff />}</p>
              <p>{diagResult?.poder_arrasto >= 4 ? <Star /> : <StarOff />}</p>
              <p>{diagResult?.poder_arrasto >= 5 ? <Star /> : <StarOff />}</p>
            </>
          )}
        </div>
        <div className="l2c1">
          <p>Venda Agregada</p>
        </div>
        <div className="l2c2">
          {diagResult === undefined ? (
            <p>Sem Dados</p>
          ) : (
            <>
              <p>
                {diagResult?.total_venda_grenada >= 1 ? <Star /> : <StarOff />}
              </p>

              <p>
                {diagResult?.total_venda_agregada >= 2 ? <Star /> : <StarOff />}
              </p>

              <p>
                {diagResult?.total_venda_agregada >= 3 ? <Star /> : <StarOff />}
              </p>
              <p>
                {diagResult?.total_venda_agregada >= 4 ? <Star /> : <StarOff />}
              </p>
              <p>
                {diagResult?.total_venda_agregada >= 5 ? <Star /> : <StarOff />}
              </p>
            </>
          )}
        </div>
        <div className="l3c1">
          <p>Total de Vendas</p>
        </div>
        <div className="l3c2">
          {diagResult === undefined ? (
            <p>Sem Dados</p>
          ) : (
            <>
              <p>{diagResult?.total_venda >= 1 ? <Star /> : <StarOff />}</p>
              <p>{diagResult?.total_venda >= 2 ? <Star /> : <StarOff />}</p>
              <p>{diagResult?.total_venda >= 3 ? <Star /> : <StarOff />}</p>
              <p>{diagResult?.total_venda >= 4 ? <Star /> : <StarOff />}</p>
              <p>{diagResult?.total_venda >= 5 ? <Star /> : <StarOff />}</p>
            </>
          )}
        </div>
        <div className="l4c1">
          <p>Margem</p>
        </div>
        <div className="l4c2">
          {diagResult === undefined ? (
            <p>Sem Dados</p>
          ) : (
            <>
              <p>{diagResult?.margem >= 1 ? <Star /> : <StarOff />}</p>
              <p>{diagResult?.margem >= 2 ? <Star /> : <StarOff />}</p>
              <p>{diagResult?.margem >= 3 ? <Star /> : <StarOff />}</p>
              <p>{diagResult?.margem >= 4 ? <Star /> : <StarOff />}</p>
              <p>{diagResult?.margem >= 5 ? <Star /> : <StarOff />}</p>
            </>
          )}
        </div>
        <div className="l5c1">
          <p>Classe</p>
        </div>
        <div className="l5c2">
          {diagResult === undefined ? (
            <p>Sem Dados</p>
          ) : (
            <>
              <p>{diagResult?.classe >= 1 ? <Star /> : <StarOff />}</p>
              <p>{diagResult?.classe >= 2 ? <Star /> : <StarOff />}</p>
              <p>{diagResult?.classe >= 3 ? <Star /> : <StarOff />}</p>
              <p>{diagResult?.classe >= 4 ? <Star /> : <StarOff />}</p>
              <p>{diagResult?.classe >= 5 ? <Star /> : <StarOff />}</p>
            </>
          )}
        </div>
        <div className="l6c1">
          <p>Impacto Faturamento</p>
        </div>
        <div className="l6c2">
          <p>0%</p>
        </div>
      </S.DiagnosticContainer>
    </>
  );
}
export default TributeSide;
