import React, { Suspense } from "react";
import "@mix/inteligencia-comercial/dist/index.css";

const AppBusinessIntelligence = React.lazy(() =>
  import("@mix/inteligencia-comercial"),
);

export const BusinessIntelligence = ({ token }) => (
  <Suspense fallback={<div>Carregando...</div>}>
    <AppBusinessIntelligence
      token={token}
      date={{ start: "01-01-2022", end: "01-31-2022" }}
      width="100%"
    />
  </Suspense>
);
