export const INITIAL_VALUE_SELECT = {
  label: "Selecione",
  value: "",
};

export const SELECT_CATEGORY_INITIAL_VALUE = {
  label: "Categoria",
  value: "",
};

export const CARD_HEADERS_COLORS_TYPES = {
  green: "green",
  default: "default",
  greenDarken: "greenDarken",
  blueDarken: "blueDarken",
};
