import { MENU_PATHS } from "./menu";

export const LAWS = {
  BASE: "/leis",
  CREATE: "/leis/cadastro",
  CREATE_NEW: `/leis/gerenciar-leis/new`,
  BULK_UPDATE: "/leis/atualizacao-em-massa",
  DETAIL: (id = ":id") => `/leis/gerenciar-leis/${id}`,
};

export const GRID_LAWS = {
  BASE: "/leis/grid",
  DETAIL: (id = ":id") => `/leis/grid/${id}`,
};

export const NCMS = {
  BASE: `/${MENU_PATHS.CLASSIFICACAO_FISCAL}/ncms`,
  CREATE: `/${MENU_PATHS.CLASSIFICACAO_FISCAL}/ncms/cadastro`,
  DETAIL: (id = ":id") => `/${MENU_PATHS.CLASSIFICACAO_FISCAL}/ncms/${id}`,
};

export const CESTS = {
  BASE: `/${MENU_PATHS.CLASSIFICACAO_FISCAL}/cests`,
  CREATE: `/${MENU_PATHS.CLASSIFICACAO_FISCAL}/cests/cadastro`,
  DETAIL: (id = ":id") => `/${MENU_PATHS.CLASSIFICACAO_FISCAL}/cests/${id}`,
};

export const NORMS = {
  BASE: `/${MENU_PATHS.CLASSIFICACAO_FISCAL}/norms`,
  CREATE: `/${MENU_PATHS.CLASSIFICACAO_FISCAL}/norms/cadastro`,
  DETAIL: (id = ":id") => `/${MENU_PATHS.CLASSIFICACAO_FISCAL}/norms/${id}`,
};

export const ASSESSORES = {
  BASE: `/${MENU_PATHS.ASSESSORES}`,
  CREATE: `/${MENU_PATHS.ASSESSORES}/new`,
  DETAIL: (id = ":id") => `/${MENU_PATHS.ASSESSORES}/${id}`,
};
