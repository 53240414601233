/* eslint-disable no-plusplus */
/* eslint-disable no-unreachable */
import React, { useState, useEffect, useRef } from "react";
import {
  createTheme,
  ThemeProvider,
  StylesProvider,
} from "@material-ui/core/styles";

import Card from "components/UI/Card";

import { treatFilter, setSessionProductFilter } from "utils";

import { DataGrid, ptBR } from "@material-ui/data-grid";

import { useProductsContext } from "context/ProductsContext";
import { CustomRowsOverlayLoading, CustomNoRowsOverlay } from "./NoRows";

import { SelectPages } from "./SelectsPages";
import { FilterNew } from "../ProductForm/FIlters";

import { COLLUNS } from "./Columns/Columns";

import * as S from "./ProductList.styles";

const theme = createTheme(ptBR);

export const ProductListing = ({
  isShowBtnAdd = true,
  isShowColumns = true,
  isShowDensity = true,
  disabledSelected = false,
  setModel,
  modelSelect,
  handleSelected,
  isShowSelectRow,
  isUnicSelected,
  action,
}) => {
  const ref = useRef();

  const {
    rows,
    isLoading,
    handleExistentRow,
    searchText,
    handleNewList,
    setParams,
  } = useProductsContext({});

  const [paramsIt, setParamsIt] = useState([]);
  const [filterModel, setFilterModel] = useState({ items: [] });

  const handleItem = (filters = []) => {
    setSessionProductFilter(filters);
    const filterItems = treatFilter(filters);
    setParamsIt([...filterItems]);
    return filterItems;
  };

  useEffect(() => {
    if (paramsIt) {
      handleNewList([...paramsIt]);
    }
  }, [paramsIt, setParams, handleNewList]);

  return (
    <S.DataGridCustom>
      <FilterNew
        columns={COLLUNS}
        handleFilter={handleItem}
        paramsIt={paramsIt}
        setParamsIt={setParamsIt}
        action={action ?? action}
      />

      <DataGrid
        ref={ref}
        rows={rows || []}
        columns={COLLUNS}
        loading={isLoading}
        components={{
          LoadingOverlay: CustomRowsOverlayLoading,
          NoResultsOverlay: CustomNoRowsOverlay,
          NoRowsOverlay: CustomNoRowsOverlay,
          Pagination: SelectPages,
        }}
        componentsProps={{
          toolbar: {
            isShowBtn: isShowBtnAdd,
            isShowColumns,
            isShowDensity,
            value: searchText,
            onChange: event => handleExistentRow(event.target.value),
            clearSearch: () => handleExistentRow(""),
          },
        }}
        getRowClassName={params =>
          `super-app-theme--${params.getValue(params.id, "status")}`
        }
        pageSize={100}
        getRowId={row => row.id}
        rowsPerPageOptions={[100]}
        filterModel={filterModel}
        disableSelectionOnClick={!!isUnicSelected && !!disabledSelected}
        onFilterModelChange={model => setFilterModel(model)}
        checkboxSelection={isShowSelectRow && !!disabledSelected}
        onSelectionModelChange={ids => {
          const selectedIDs = new Set(ids);
          const selectedItem = rows.filter(item =>
            selectedIDs.has(item?._id.toString()),
          );
          setModel(ids);
          handleSelected(selectedItem);
        }}
        selectionModel={modelSelect}
        localization={{
          body: {
            localeText: ptBR,
          },
        }}
      />
    </S.DataGridCustom>
  );
};

export const ProductListCard = () => {
  const [model, setModel] = useState();

  const handleSelected = value => {
    return value;
  };

  return (
    <ThemeProvider theme={theme}>
      <StylesProvider injectFirst>
        <Card title="PRODUTOS" headerColor="#256925" iconName="erp">
          <ProductListing
            disabledSelected
            model={model}
            setModel={setModel}
            handleSelected={handleSelected}
          />
        </Card>
      </StylesProvider>
    </ThemeProvider>
  );
};
