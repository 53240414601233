import React, { useState, useEffect, useCallback } from "react";
import { getSessionCompanies } from "utils/session";
import { useAuth } from "context/AuthContext";
import { useToast } from "context/ToastContext";

import { MenuList } from "./components";

import templatesApi from "../../../../services/templatesApi";

import UploadDialog from "../../../Menu/UploadDialog";
import * as S from "./styles";

const validFiles = [
  ".xls",
  "application/vnd.ms-excel",
  ".xlsx",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ".xml",
  "application/xml",
  ".xml",
  "text/xml",
  ".txt",
  "text/plain",
  ".csv",
  "text/csv",
  ".pdf",
  "application/pdf",
  ".zip",
  "application/x-zip-compressed",
  ".rar",
];

export const Menu = ({
  active,
  activeTheme,
  isUploadingDialogOpen,
  setIsUploadingDialogOpen,
}) => {
  const [loadingFilesStatus, setLoadingFilesStatus] = useState("idle");
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const [uploadPercentage, setUploadPercentage] = useState(0);

  const { user } = useAuth();
  const { addToast } = useToast();

  const handleUpload = files => {
    setLoadingFilesStatus("uploading");
    const validatedFiles = files.filter(file => {
      if (validFiles.includes(file.type)) {
        return file;
      }

      const fileExtension = file.name.replace(/(.*)(\.[a-z0-9]+)$/gi, "$2");

      if (validFiles.includes(fileExtension)) {
        return file;
      }

      return false;
    });

    if (validatedFiles.length <= 0) {
      return setLoadingFilesStatus("idle");
    }

    return setUploadedFiles(prevState => [...prevState, ...validatedFiles]);
  };

  const handleSubmit = useCallback(async () => {
    const token = localStorage.getItem("@mixfiscal:authenticatorToken");
    const formData = new FormData();

    uploadedFiles.forEach(file => {
      formData.append("file", file);
    });

    formData.append("userId", user?._id);

    try {
      const response = await templatesApi.post("/sendFile", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: token,
          "X-Token-User": getSessionCompanies(),
        },
        onUploadProgress: progressEvent => {
          const { loaded, total } = progressEvent;
          const percent = Math.floor((loaded * 100) / total);
          console.log(`${loaded}kb of ${total}kb | ${percent}%`);
          if (percent <= 100) {
            setUploadPercentage(percent);
          }
        },
      });
      if (response?.data?.data?.length > 0) {
        response?.data?.data?.forEach(file => {
          if (file?.sended === false) {
            addToast({
              type: "error",
              title: "Erro ao subir arquivo",
              description: `O arquivo ${file?.name} é inválido.`,
            });
          }
        });
      }
      setLoadingFilesStatus("success");
      setTimeout(() => {
        setLoadingFilesStatus("idle");
      }, 2000);
      setUploadedFiles([]);
      setUploadPercentage(0);
    } catch (err) {
      console.log(err.message);
      setLoadingFilesStatus("failure");
      setTimeout(() => {
        setLoadingFilesStatus("idle");
      }, 2000);
    }
  }, [addToast, uploadedFiles, user?._id]);

  useEffect(() => {
    if (uploadedFiles.length > 0) handleSubmit();
  }, [uploadedFiles, handleSubmit]);

  return (
    <>
      <S.Container activeTheme={activeTheme} className={active ? "active" : ""}>
        <MenuList
          active={active}
          activeTheme={activeTheme}
          setIsUploadingDialogOpen={setIsUploadingDialogOpen}
        />
        <UploadDialog
          loadingFilesStatus={loadingFilesStatus}
          isOpen={isUploadingDialogOpen}
          handleClose={() => setIsUploadingDialogOpen(false)}
          handleUpload={handleUpload}
          uploadPercentage={uploadPercentage}
          title="IMPORTAR ARQUIVOS"
        />
      </S.Container>
    </>
  );
};
