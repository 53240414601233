import React from "react";
import { InputContainerForm } from "components/form";

import { AssessoresSelect } from "./AssessoresSelect";

export const AssessoresForm = ({ userValue, control }) => {
  return (
    <InputContainerForm isBlock>
      <AssessoresSelect control={control} value={userValue} />
    </InputContainerForm>
  );
};
