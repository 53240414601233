export const WHATSAPP_STATUS_COMPLEX = {
  "not-connected": {
    text: "Aguardando conexão",
    status: "warning",
  },
  connected: {
    text: "Conectado",
    status: "success",
  },
  failed: {
    text: "Falha ao conectar",
    status: "danger",
  },
  pending: {
    text: "Aguardando autenticação",
    status: "warning",
  },
};
