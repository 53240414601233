import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Breadcrumbs, Card } from "@mix/mixfiscal-designsystem";
import { IoFilterSharp } from "react-icons/io5";
import { useFieldArray, useForm } from "react-hook-form";
import { useApp } from "context/AppContext";

import { Container, Content, Button, ButtonLink } from "./styles";
import ActionForm from "../../componentes/ActionForm";
import ConfigForm from "../../componentes/ConfigForm";
import MonitoringForm from "../../componentes/MonitoringForm";
import TaskForm from "../../componentes/TaskForm";
import Preview from "../../componentes/Preview";
import useCheckLinks from "../../hooks/useCheckLinks";
import useCategories from "../../hooks/useCategories";

const CONDITION = {
  field: {
    name: "",
    module: "",
  },
  condition: "",
  value: 0,
  problem: "",
};

const DEFAULT = {
  name: "",
  category: "",
  description: "",
  conditions: [CONDITION],
  tasks: [],
  action: "",
};

const CheckLinkCreateUpdate = () => {
  const { app } = useApp();
  const { id } = useParams();
  const history = useHistory();
  const { checkLink, checkLinks, getOne, getAll, create, update } =
    useCheckLinks();
  const { categories } = useCategories();
  const [monitoring, setMonitoring] = useState(true);
  const [selectedModule, setSelectedModule] = useState();
  const [template, setTemplate] = useState();

  const { register, handleSubmit, control, setValue, getValues, reset, watch } =
    useForm({
      defaultValues: DEFAULT,
    });
  const { fields: fieldsConditions } = useFieldArray({
    control,
    name: "conditions",
    required: true,
  });

  const onSubmit = async data => {
    const conditions = monitoring
      ? data.conditions.map(c => {
          return {
            ...c,
            value: Number(c.value),
            field: c.field._id,
          };
        })
      : [];
    const newData = {
      category: data.category._id,
      conditions,
      description: data.description,
      name: data.name,
      tasks: data.tasks,
      action: data.action,
    };

    let ok = false;

    if (id === "new") {
      ok = await create(newData);
    } else {
      ok = await update(data._id, { ...newData, _id: data._id });
    }

    if (ok) history.push("/check-link");
  };

  const handlerChangeMonitoring = () => {
    setMonitoring(!monitoring);
  };

  useEffect(() => {
    if (id === "new") {
      reset(DEFAULT);
    } else {
      getOne(id);
    }
  }, [id, getOne, reset]);

  useEffect(() => {
    getAll();
    if (history?.location?.state?.id) {
      getOne(history?.location?.state?.id);
    }
  }, [getAll, getOne, history?.location?.state?.id]);

  useEffect(() => {
    if (checkLink) {
      if (checkLink?.conditions?.length === 0) {
        checkLink.conditions.push(CONDITION);
      }
      reset(checkLink);
    }
  }, [checkLink, reset]);

  useEffect(() => {
    const subscription = watch(value => setTemplate(value));
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <Container>
      <header>
        <Breadcrumbs
          handleClick={() => history.push("/check-link")}
          items={["Checklink", "Novo Checklink"]}
          selected="Novo Checklink"
        />
      </header>
      <Content>
        <section className="column1">
          <Card
            headerColor={!app.appBusinessIntelligence ? "#256925" : "#17456B"}
            textColor="#FFF"
            title="CHECKLINK"
            titleIcon="resumo"
            hasAccordionBehavior
          >
            <p className="title">
              Selecione um CheckLink para editar ou crie um novo.
            </p>
            <div className="div-overflow">
              <table>
                <thead>
                  <tr>
                    <th>
                      <div>
                        <p>CHECKLINK</p>
                        <IoFilterSharp size={10} color="#000" />
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {checkLinks.map((c, index) => (
                    <tr key={index}>
                      <td>
                        <ButtonLink
                          onClick={() => history.push(`/check-link/${c._id}`)}
                        >
                          {c.name}
                        </ButtonLink>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {id !== "new" && (
              <Button onClick={() => history.push("/check-link/new")}>
                CRIAR NOVO CHECKLINK
              </Button>
            )}
          </Card>
        </section>
        <section className="column2">
          <Card
            headerColor={!app.appBusinessIntelligence ? "#256925" : "#17456B"}
            textColor="#FFF"
            title={`${id === "new" ? "NOVO" : "EDITAR"} CHECKLINK`}
            titleIcon="pessoa"
          >
            <section className="newCheckLink">
              <div className="data">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <ConfigForm
                    categories={categories}
                    register={register}
                    monitoring={monitoring}
                    onChangeMonitoring={handlerChangeMonitoring}
                  />

                  <MonitoringForm
                    register={register}
                    show={monitoring}
                    fieldsConditions={fieldsConditions}
                    selectedModule={selectedModule}
                    setSelectedModule={setSelectedModule}
                  />

                  <TaskForm {...{ setValue, control, getValues }} />

                  <ActionForm register={register} />

                  <Button type="submit">SALVAR</Button>
                </form>
              </div>
              <Preview template={template} />
            </section>
          </Card>
        </section>
      </Content>
    </Container>
  );
};

export default CheckLinkCreateUpdate;
