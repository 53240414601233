import styled, { css } from "styled-components";
import { Tabs as TabsMUI, Tab as TabMUI } from "@material-ui/core";

export const Tabs = TabsMUI;

const tabModifiers = {
  selected: () => css`
    background-color: #256925;
    border-color: #256925;
    border: 1px 0px 1px 1px;

    span {
      color: #fff;
      font-weight: 700;
    }

    ${tabModifiers.borderRadius()}
  `,

  border: () => css`
    border-style: solid;
    border-color: #dee2e6;
    border-width: 1px 0px 0px 0px;

    &:first-of-type {
      border-left-width: 1px;
    }
    &:last-of-type {
      border-right-width: 1px;
    }

    ${tabModifiers.borderRadius()}
  `,

  borderRadius: () => css`
    &:first-of-type {
      border-top-left-radius: 5px;
    }
    &:last-of-type {
      border-top-right-radius: 5px;
    }
  `,

  hrLine: () => css`
    &:after {
      content: "";
      width: 1px;
      height: 60%;
      background-color: #dee2e6;
      position: absolute;
      right: 0px;
    }
  `,
};

export const Tab = styled(TabMUI)`
  && {
    position: relative;
    ${({ selected }) => css`
      ${selected && tabModifiers.selected()}
      ${!selected && tabModifiers.border()}
      ${!selected && tabModifiers.hrLine()}
      &:last-of-type:after {
        display: none;
      }
    `}
  }
`;

export const TabWrapper = styled.div``;

export const TabChildContainer = styled.div`
  border: 1px solid #dee2e6;
  border-radius: 0px 5px 5px 5px;
  box-shadow: 0px 1px 3px 0px #0000002e;
`;

export const TabIndicator = {
  backgroundColor: "#256925",
};
