import React from "react";

import * as S from "./styles";

function TributeFooterDetail({ title, subTitle, value, percent, smallText }) {
  return (
    <S.ContainerTributeFooter>
      <span>
        <h4>{title}</h4>
      </span>
      <span style={{ lineHeight: "5px" }}>
        <h5>{subTitle}</h5>
      </span>
      <S.ContainerValues>
        <S.Title>{value}</S.Title>
        <S.TitlePercent>{percent}</S.TitlePercent>
        <S.SmallTextContainer>
          <S.SmallText>{smallText}</S.SmallText>
        </S.SmallTextContainer>
      </S.ContainerValues>
    </S.ContainerTributeFooter>
  );
}
export default TributeFooterDetail;
