import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Datatable, Pagination } from "@mix/mixfiscal-designsystem";
import Card from "components/UI/Card/index";
import IconComponent from "components/IconExporter/index";
import { useACL } from "context";
import { useApiKeys } from "useCases";

import * as S from "../../styles";

function ListKeys({ baseUrl, handleEditView }) {
  const { hasEdit, hasCreate, hasShow } = useACL("api-keys");
  const history = useHistory();
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  const { data: dataApiKeys, isLoading } = useApiKeys({
    page,
    limit,
  });

  const convertKey = key => {
    return key?.slice(0, 5).padEnd(32, "*").toUpperCase();
  };

  useEffect(() => {
    if (dataApiKeys?.apiKeys?.docs.length > 0) {
      setTotalItems(dataApiKeys?.apiKeys?.totalDocs);
      setTotalPages(dataApiKeys?.apiKeys?.totalPages);
    }
  }, [dataApiKeys, setTotalPages, setTotalItems]);
  const convertDate = date => {
    const year = date.slice(0, 4);
    const month = date.slice(5, 7);
    const day = date.slice(8, 10);
    return `${day}/${month}/${year}`;
  };

  const translatePermissions = (
    getRequest,
    postRequest,
    putRequest,
    deleteRequest,
  ) => {
    if (!getRequest && !postRequest && !putRequest && !deleteRequest) {
      return "Nenhuma";
    }
    if (getRequest && postRequest && putRequest && deleteRequest) {
      return "Completa";
    }
    if (getRequest && !postRequest && !putRequest && !deleteRequest) {
      return "Obter";
    }
    if (!getRequest && postRequest && !putRequest && !deleteRequest) {
      return "Criar";
    }
    if (!getRequest && !postRequest && putRequest && !deleteRequest) {
      return "Publicar";
    }
    if (!getRequest && !postRequest && !putRequest && deleteRequest) {
      return "Deletar";
    }
    if (getRequest && postRequest && !putRequest && !deleteRequest) {
      return "Obter e Criar";
    }
    if (getRequest && !postRequest && putRequest && !deleteRequest) {
      return "Obter e Publicar";
    }
    if (getRequest && !postRequest && !putRequest && deleteRequest) {
      return "Obter e Deletar";
    }
    if (!getRequest && postRequest && putRequest && !deleteRequest) {
      return "Criar e Publicar";
    }
    if (!getRequest && postRequest && !putRequest && deleteRequest) {
      return "Criar e Deletar";
    }
    if (!getRequest && !postRequest && putRequest && deleteRequest) {
      return "Publicar e Deletar";
    }
    if (getRequest && postRequest && putRequest && !deleteRequest) {
      return "Obter, Criar e Publicar";
    }
    if (getRequest && postRequest && !putRequest && deleteRequest) {
      return "Obter, Criar e Deletar";
    }
    if (!getRequest && postRequest && putRequest && deleteRequest) {
      return "Criar, Publicar e Deletar";
    }
    if (getRequest && !postRequest && putRequest && deleteRequest) {
      return "Obter, Publicar e Deletar";
    }
  };

  const keyButton = _id => {
    const onclick = () => {
      if (hasEdit) {
        handleEditView({
          id: _id,
          url: "chaves-api",
        });
      }
    };
    return (
      <div role="button" onClick={onclick}>
        <IconComponent name="edit" className="edit-icon" />
      </div>
    );
  };

  if (!hasShow) {
    return null;
  }

  return (
    <Card title="CHAVES API" headerColor="#256925">
      <S.CardSubHeader>
        <S.CardSubHeaderDescription>
          <p className="title">Selecione uma CHAVE para editar.</p>
          {totalItems ? (
            <p>{`Mostrando ${limit} de ${totalItems} resultado(s)`}</p>
          ) : (
            ``
          )}
        </S.CardSubHeaderDescription>
        <S.CardSubHeaderAction>
          {hasCreate && (
            <S.CardSubHeaderActionButton
              onClick={() => history.push(`${baseUrl}/chaves-api/new`)}
            >
              CRIAR CHAVE API
            </S.CardSubHeaderActionButton>
          )}
        </S.CardSubHeaderAction>
      </S.CardSubHeader>
      <S.CardSubItemPagination>
        <Pagination
          handlePageChange={setPages}
          initialPage={page}
          siblingsCount={1}
          totalPages={totalPages}
          amountVariations={[10, 20, 30, 300, 500]}
          handleChangeAmount={setLimit}
        />
      </S.CardSubItemPagination>
      <div className="div-overflow storestest">
        <Datatable
          handleChangePage={() => setPages()}
          pagination={false}
          isLoading={isLoading}
          size="12px"
          columns={[
            { field: "actions", title: "" },
            { field: "name", title: "NOME" },
            { field: "key", title: "CHAVE API" },
            { field: "permission", title: "PERMISSÃO" },
            { field: "createdAt", title: "CRIAÇÃO" },
          ]}
          rawData={dataApiKeys?.apiKeys?.docs.map(item => ({
            actions: hasEdit && keyButton(item._id),
            name: item.name,
            key: convertKey(item.key),
            permission: translatePermissions(
              item.getRequest,
              item.postRequest,
              item.putRequest,
              item.deleteRequest,
            ),
            createdAt: convertDate(item.createdAt),
          }))}
        />
      </div>
    </Card>
  );
}

export default ListKeys;
