import styled from "styled-components";

export const ContainerFooter = styled.div`
  width: 100%;
  margin-top: 5px;
  display: flex;
  align-items: center;
`;

export const TagContainer = styled.div`
  margin: 4px;
`;

export const ContainerTagFooter = styled.div`
  width: fit-content;
  padding: 4px 12px;
  border-radius: 16px;
  background-color: #e5e5e5;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  &:hover {
    background-color: #bababa;
  }
  .clickable {
    cursor: pointer;
  }
  p {
    color: #596778;
    font-weight: bold;
    font-size: 14px;
    background-color: red;
  }
`;

export const ConstainerDropDown = styled.div`
  p {
    font-size: 15px;
    span {
      text-decoration: none;
      display: inline-block;
      margin: 0 4px;
      cursor: pointer;
    }
  }

  & ul > li {
    list-style-type: none;
    color: #000;
    cursor: pointer;
    transition: all 0.1s ease-in-out;

    &:hover {
      color: #adc86a;
    }
  }
`;
