import React from "react";
import { ListResultsAmostration } from "components/contexts/grid-manager/ListResultsAmostration";
import { useRegisterLaws } from "hooks/useRegisterLaws";
import { useAnalyticsGrade } from "useCases/grid";
import { useGradeFilters } from "hooks/useGrade";
import * as S from "./styles";

const Tax = ({ gradeId, lawId }) => {
  const { values, setValues } = useRegisterLaws();
  const params = useGradeFilters({ values, gradeId, lawId });
  // TODO: Depois remover!
  if (lawId) {
    delete params?.cenarios;
  }

  const { data } = useAnalyticsGrade(params);

  return (
    <S.Container>
      <ul className="flex-tax">
        <li>
          <input
            type="checkbox"
            disabled={values?.taxa?.icms}
            checked={values.taxa?.pis}
            onChange={e => {
              setValues({
                taxa: {
                  ...values?.taxa,
                  pis: e.target.checked,
                },
              });
            }}
          />
          <label>Pis</label>
        </li>
        <li>
          <input
            type="checkbox"
            disabled={values?.taxa?.icms}
            checked={values.taxa?.cofins}
            onChange={e => {
              setValues({
                taxa: {
                  ...values?.taxa,
                  cofins: e.target.checked,
                },
              });
            }}
          />
          <label>Cofins</label>
        </li>
        <li>
          <input
            type="checkbox"
            disabled={values?.taxa?.pis || values?.taxa?.cofins}
            checked={values.taxa?.icms}
            onChange={e => {
              setValues({
                taxa: {
                  ...values?.taxa,
                  icms: e.target.checked,
                },
              });
            }}
          />
          <label>ICMS</label>
        </li>
      </ul>
      {params && (
        <ListResultsAmostration
          params={params}
          gradesTotal={data?.grade?.total}
          productsClientsTotal={data?.grade?.totalProductsClients}
          productsMixFiscalTotal={data?.grade?.totalProductsMix}
        />
      )}
    </S.Container>
  );
};

export default Tax;
