const stripNumber = value => {
  if (!value) return 0;
  const rawValue = value.toString().replace(/[a-zA-Z$\s]/gi, "");

  if (+rawValue) {
    return +(+rawValue).toFixed(2);
  }

  const integer = rawValue
    .toString()
    .replace(/(?:[,|.]\d{0,2}$)/gi, "")
    .replace(/\D/gi, "")
    .padStart(2, "0");
  const cents = rawValue
    .toString()
    .replace(/((.*)(?=[,|.]\d{0,2}$))/gi, "")
    .replace(/,/gi, ".");

  const validatedCents = cents.includes(".") ? cents.padEnd(3, "0") : "";

  return +`${integer}${validatedCents}`;
};

export const add = (x, y) => {
  const finalResult = [];
  const higherLength = x?.length > y?.length ? x?.length : y?.length;
  for (let i = 0; i < higherLength; i += 1) {
    const formatedX = stripNumber(x[i]);
    const formatedY = stripNumber(y[i]);
    finalResult.push(formatedX + formatedY);
  }
  return finalResult;
};

export const subtract = (x, y) => {
  const finalResult = [];
  const higherLength = x?.length > y?.length ? x?.length : y?.length;
  for (let i = 0; i < higherLength; i += 1) {
    const formatedX = stripNumber(x[i]);
    const formatedY = stripNumber(y[i]);
    finalResult.push(formatedX - formatedY);
  }
  return finalResult;
};

export const multiply = (x, y) => {
  const finalResult = [];
  const higherLength = x?.length > y?.length ? x?.length : y?.length;
  for (let i = 0; i < higherLength; i += 1) {
    const formatedX = stripNumber(x[i]);
    const formatedY = stripNumber(y[i]);
    finalResult.push(formatedX * formatedY);
  }

  return finalResult;
};

export const divide = (x, y) => {
  const finalResult = [];
  const higherLength = x?.length > y?.length ? x?.length : y?.length;
  for (let i = 0; i < higherLength; i += 1) {
    const formatedX = stripNumber(x[i]);
    const formatedY = stripNumber(y[i]);
    finalResult.push(formatedX / formatedY);
  }
  return finalResult;
};

export const average = (x, y) => {
  const finalResult = [];
  const higherLength = x?.length > y?.length ? x?.length : y?.length;
  for (let i = 0; i < higherLength; i += 1) {
    const formatedX = stripNumber(x[i]);
    const formatedY = stripNumber(y[i]);
    finalResult.push((formatedX + formatedY) / 2);
  }
  return [finalResult];
};

export const roundDown = x => {
  const finalResult = [];
  for (let i = 0; i < x?.length; i += 1) {
    const formatedX = stripNumber(x[i]);
    finalResult.push(Math.floor(formatedX));
  }
  return [finalResult];
};

export const roundUp = x => {
  const finalResult = [];
  for (let i = 0; i < x?.length; i += 1) {
    const formatedX = stripNumber(x[i]);
    finalResult.push(Math.ceil(formatedX));
  }
  return [finalResult];
};

export const findAndReplace = (x, y) => {
  if (x) {
    const stringValue = x.toString();
    const { findValue, replaceValue } = y;
    const regex = new RegExp(findValue, "gi");
    return stringValue.replace(regex, replaceValue);
  }
  return null;
};

export const sumAll = apiRows => {
  const sum = apiRows.reduce((acc, cur) => {
    if (typeof cur === "string" || typeof cur === "number") {
      const formated = stripNumber(cur);
      const newValue = Number.isNaN(+formated) ? 0 : +stripNumber(cur);
      return acc + newValue;
    }
    return acc;
  }, 0);

  return [sum];
};

// export const groupAndSumAll = ({
//   distictionColumn,
//   valueColumn,
//   data,
// } = {}) => {
//   console.log({ distictionColumn, valueColumn, data });

//   if (!data) return null;

//   const groupedData = {};
//   data.forEach(row => {
//     const identifier = row[distictionColumn];
//     groupedData[identifier] = groupedData[identifier]
//       ? groupedData[identifier] + +row[valueColumn]
//       : +row[valueColumn];
//   });

//   console.log({ groupedData });

//   // TODO: return the values as an array.
//   return "TESTE";
// };

export const averageAll = apiRows => {
  const sum = apiRows.reduce((acc, cur) => {
    if (typeof cur === "string" || typeof cur === "number") {
      const formated = stripNumber(cur);
      const newValue = Number.isNaN(+formated) ? 0 : +stripNumber(cur);
      return acc + newValue;
    }
    return acc;
  }, 0);

  return [sum / apiRows.length];
};

// export const cherryPick = (x, y) => {
//   console.log({ x, y });
// };

// export const groupAndOrder = (x, y) => {
//   console.log({ x, y });
// };
