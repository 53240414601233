import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { getOutgoers } from "react-flow-renderer";
import { v4 as uuidv4 } from "uuid";
import ReactDOM from "react-dom";
import { BsDiamond, BsFillCircleFill, BsFillXCircleFill } from "react-icons/bs";
import { BiRectangle } from "react-icons/bi";
import { MdEdit } from "react-icons/md";

import Dialog from "../UI/Dialog";
import { useFlow } from "../../context/FlowContext";
import Flow from "./Flow";
import Icon from "../IconExporter";

import {
  Container,
  SideTitle,
  Menu,
  ActionsContainer,
  Actions,
  Action,
  FlowContainer,
  MenuItem,
  HeaderActions,
  ModalContent,
} from "./styles";

function ComplexOperations() {
  const [steps, setSteps] = useState(["início"]);
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedMenu, setSelectedMenu] = useState("");

  const [selectedElement, setSelectedElement] = useState("");
  const [selectedField, setSelectedField] = useState("");

  const [headerActionType, setHeaderActionType] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [elements, setElements] = useState([]);
  const [blockId, setBlockId] = useState("");
  const [blockName, setBlockName] = useState("");
  const [fields, setFields] = useState({});

  const {
    elements: flowElements,
    getElementsById,
    getAdvancedOperationsFlow,
    getStepsFromAdvancedOperationFlow,
    setAdvancedOperationsFlow,
    updateAdvancedOperationsName,
    getAllFieldElementsForAdvancedOperations,
    currentAdvancedOperationId,
  } = useFlow();

  const history = useHistory();
  const currentReactFlowState = useRef(null);
  const actionsContainerRef = useRef(null);

  const getCurrentElements = () => {
    const clonedElements = JSON.parse(
      JSON.stringify(currentReactFlowState.current),
    );
    const updatedElements = clonedElements.map(updatedElement => {
      if (updatedElement.position) {
        updatedElement.position = updatedElement?.__rf?.position;
      }
      delete updatedElement.__rf;
      return updatedElement;
    });

    return updatedElements;
  };

  useEffect(() => {
    const [element] = getElementsById(blockId);
    if (element && element.data && element.data.name !== blockName) {
      setBlockName(element.data.name);
    }
  }, [blockId, blockName, flowElements, getElementsById]);

  useEffect(() => {
    if (!currentAdvancedOperationId) {
      history.push("/editor");
    }

    setBlockId(currentAdvancedOperationId);
  }, [currentAdvancedOperationId, history]);

  useEffect(() => {
    if (elements.length === 0) {
      const currentFlow = getAdvancedOperationsFlow(currentAdvancedOperationId);
      const currentSteps = getStepsFromAdvancedOperationFlow(
        currentAdvancedOperationId,
      );
      if (currentFlow) {
        setElements(currentFlow);
      }
      if (currentSteps) {
        setSteps(currentSteps);
        setCurrentStep(currentSteps?.length - 1);
      }
    }
  }, [
    currentAdvancedOperationId,
    elements.length,
    getAdvancedOperationsFlow,
    getStepsFromAdvancedOperationFlow,
  ]);

  useEffect(() => {
    const currentFlow = getAdvancedOperationsFlow(currentAdvancedOperationId);
    const currentSteps = getStepsFromAdvancedOperationFlow(
      currentAdvancedOperationId,
    );
    if (currentFlow) {
      setElements(currentFlow);
    }
    if (currentSteps) {
      setSteps(currentSteps);
      setCurrentStep(currentSteps?.length - 1);
    }
  }, [
    currentAdvancedOperationId,
    flowElements,
    getAdvancedOperationsFlow,
    getStepsFromAdvancedOperationFlow,
  ]);

  useEffect(() => {
    actionsContainerRef.current.scrollLeft =
      actionsContainerRef.current.scrollWidth;
  }, [selectedMenu]);

  const handleAddNode = ({ label, nodeType, data }) => {
    // check if there is one element sitting inside the createZone
    let count = 0;
    currentReactFlowState.current.forEach(element => {
      if (
        element.__rf &&
        element.__rf.position.x === 0 &&
        element.__rf.position.y === 0
      ) {
        count += 1;
      }
    });

    if (count > 1) return;

    if (
      label === "FIM" &&
      elements.findIndex(element => element.id === "end") !== -1
    )
      return;

    if (data && data.operationType && data.operationType === "compare") {
      const idElement = uuidv4();
      const cn1 = uuidv4();
      const cn2 = uuidv4();
      const ifYes = uuidv4();
      const ifNo = uuidv4();

      return setElements(prevState => {
        return [
          ...prevState,
          {
            id: ifYes,
            type: "rectangle",
            data: {
              label: "ifYes",
              element: {
                data: {
                  label: "IGUAL",
                },
              },
            },
            position: { x: 0, y: 0 },
          },
          {
            id: ifNo,
            type: "rectangle",
            data: {
              label: "ifNo",
              element: {
                data: {
                  label: "DIFERENTE",
                },
              },
            },
            position: { x: 0, y: 0 },
          },
          {
            id: idElement,
            type: "diamond",
            data: {
              label: "Comparar",
              operationType: "compare",
              argsAmount: 2,
            },
            position: { x: 0, y: 0 },
          },
          {
            id: `reactflow__edge-${cn1}`,
            type: "default",
            source: idElement,
            sourceHandle: "head",
            target: ifYes,
            targetHandle: "head",
            arrowHeadType: "arrowclosed",
            style: {
              strokeWidth: "3px",
            },
          },
          {
            id: `reactflow__edge-${cn2}`,
            type: "default",
            source: idElement,
            sourceHandle: "head",
            target: ifNo,
            targetHandle: "head",
            arrowHeadType: "arrowclosed",
            style: {
              strokeWidth: "3px",
            },
          },
        ];
      });
    }

    if (nodeType === "comment") {
      setElements(prevState => {
        return [
          ...prevState,
          {
            id: uuidv4(),
            type: nodeType,
            data: {
              label: inputValue,
              ...data,
            },
            position: { x: 0, y: 0 },
          },
        ];
      });
      return setInputValue("");
    }

    if (nodeType === "inputValue") {
      const newId = uuidv4();
      setElements(prevState => {
        return [
          ...prevState,
          {
            id: newId,
            type: nodeType,
            data: {
              ...data,
              label,
              value: "",
              parentId: currentAdvancedOperationId,
            },
            position: { x: 0, y: 0 },
          },
        ];
      });
      return setAdvancedOperationsFlow(
        currentAdvancedOperationId,
        JSON.stringify([
          ...elements,
          {
            id: newId,
            type: nodeType,
            data: {
              ...data,
              label,
              value: "",
              parentId: currentAdvancedOperationId,
            },
            position: { x: 0, y: 0 },
          },
        ]),
      );
    }

    if (nodeType === "findAndReplace") {
      setElements(prevState => {
        return [
          ...prevState,
          {
            id: uuidv4(),
            type: nodeType,
            data: {
              label,
              ...data,
              parentId: currentAdvancedOperationId,
            },
            position: { x: 0, y: 0 },
          },
        ];
      });
      return setAdvancedOperationsFlow(
        currentAdvancedOperationId,
        JSON.stringify([
          ...elements,
          {
            id: uuidv4(),
            type: nodeType,
            data: {
              label,
              ...data,
              parentId: currentAdvancedOperationId,
            },
            position: { x: 0, y: 0 },
          },
        ]),
      );
    }

    const newElementId = label === "FIM" ? "end" : uuidv4();
    setElements(prevState => {
      setAdvancedOperationsFlow(
        currentAdvancedOperationId,
        JSON.stringify([
          ...prevState,
          {
            id: newElementId,
            type: nodeType,
            data: {
              label,
              ...data,
              parentId: currentAdvancedOperationId,
            },
            position: { x: 0, y: 0 },
          },
        ]),
      );

      return [
        ...prevState,
        {
          id: newElementId,
          type: nodeType,
          data: {
            label,
            ...data,
            dataType: data?.element?.data?.dataType,
          },
          position: label === "FIM" ? { x: 800, y: 500 } : { x: 0, y: 0 },
        },
      ];
    });

    if (Object.keys(fields).length > 0) {
      setSelectedElement(fields[Object.keys(fields)[0]]);
      setSelectedField(
        `${fields[Object.keys(fields)[0]].data.fields[0].description}-0`,
      );
    }
  };

  const handleStepChange = step => {
    setSteps(prevState => [...prevState, step]);
    setCurrentStep(prevState => prevState + 1);
  };

  useEffect(() => {
    const fieldElements = getAllFieldElementsForAdvancedOperations();

    if (fieldElements.length > 0) {
      const fieldsStructure = {};
      fieldElements.forEach(fieldEl => {
        fieldsStructure[fieldEl.id] = {
          id: fieldEl.id,
          data: {
            label: fieldEl.data.label,
            fields: fieldEl.data.fields,
            dataType: fieldEl.data.dataType,
            selectedDataFileId: fieldEl.data.selectedDataFileId,
            register: fieldEl.data.register,
          },
        };
        setFields(fieldsStructure);
      });

      setSelectedElement(fieldsStructure[Object.keys(fieldsStructure)[0]]);
      setSelectedField(
        `${
          fieldsStructure[Object.keys(fieldsStructure)[0]].data.fields[0]
            .description
        }-0`,
      );
    }
  }, [getAllFieldElementsForAdvancedOperations]);

  const stepActionsComponents = () => {
    switch (selectedMenu) {
      case "field":
        return (
          <>
            <Action noHover>
              <label>Bloco de Input</label>
              <button
                type="button"
                onClick={() => {
                  handleAddNode({
                    label: "Bloco de Input",
                    nodeType: "inputValue",
                    data: {},
                  });
                  handleStepChange("dados");
                  setSelectedMenu("");
                }}
              >
                CRIAR
              </button>
            </Action>
            <Action noHover>
              <label>NOME DO BLOCO</label>
              <select
                value={selectedElement.id}
                onChange={e => {
                  setSelectedField(
                    fields[e.target.value].data.fields[0].description,
                  );
                  setSelectedElement(fields[e.target.value]);
                }}
              >
                {fields &&
                  Object.keys(fields).map(fieldId => (
                    <option key={fieldId} value={fieldId}>
                      {fields[fieldId].data.label}
                    </option>
                  ))}
              </select>
            </Action>

            <Action noHover>
              <label>NOME DO CAMPO</label>
              <select
                value={selectedField}
                onChange={e => {
                  console.log({ event: e.target.value });
                  setSelectedField(e.target.value);
                }}
              >
                {fields &&
                  selectedElement &&
                  fields[selectedElement.id] &&
                  fields[selectedElement.id].data.fields.map((field, index) => (
                    <option
                      key={field._id}
                      value={`${field.description}-${index}`}
                    >
                      {field.description}
                    </option>
                  ))}
              </select>
            </Action>

            <Action noHover stickToBottom>
              <button
                type="button"
                onClick={() => {
                  console.log({ selectedElement });
                  const [field, fieldIndex] = selectedField.split("-");

                  handleAddNode({
                    label: "Dados",
                    nodeType: "rectangle",
                    data: {
                      element: selectedElement,
                      field,
                      fieldIndex: fieldIndex ?? "0",
                    },
                  });
                  handleStepChange("dados");
                  setSelectedMenu("");
                }}
              >
                CRIAR
              </button>
            </Action>
            <Action noHover stickToBottom>
              <button
                type="button"
                onClick={() => {
                  console.log({ selectedElement });
                  const [field, fieldIndex] = selectedField.split("-");

                  handleAddNode({
                    label: "Dados",
                    nodeType: "allValues",
                    data: {
                      element: selectedElement,
                      field,
                      fieldIndex: fieldIndex ?? "0",
                    },
                  });
                  handleStepChange("dados");
                  setSelectedMenu("");
                }}
              >
                CRIAR P/ SOMA TOTAL
              </button>
            </Action>
            <Action noHover stickToBottom>
              <button
                type="button"
                onClick={() => {
                  console.log({ selectedElement });
                  const [field, fieldIndex] = selectedField.split("-");

                  handleAddNode({
                    label: "Dados",
                    nodeType: "allAverage",
                    data: {
                      element: selectedElement,
                      field,
                      fieldIndex: fieldIndex ?? "0",
                    },
                  });
                  handleStepChange("dados");
                  setSelectedMenu("");
                }}
              >
                CRIAR P/ MÉDIA TOTAL
              </button>
            </Action>
          </>
        );
      case "function":
        return (
          <>
            <Action>
              <div
                className="operationsContainer"
                role="button"
                onClick={() => {
                  handleAddNode({
                    label: "Soma",
                    nodeType: "diamond",
                    data: {
                      operationType: "add",
                      argsAmount: 2,
                    },
                  });
                  handleStepChange("operação");
                  setSelectedMenu("");
                }}
              >
                <Icon name="sum" size={20} color="#000" />
                <p>Soma</p>
              </div>
            </Action>
            <Action>
              <div
                className="operationsContainer"
                role="button"
                onClick={() => {
                  handleAddNode({
                    label: "Subtração",
                    nodeType: "diamond",
                    data: {
                      operationType: "subtract",
                      argsAmount: 2,
                    },
                  });
                  handleStepChange("operação");
                  setSelectedMenu("");
                }}
              >
                <Icon name="subtract" size={20} color="#000" />
                <p>Subtração</p>
              </div>
            </Action>
            <Action>
              <div
                className="operationsContainer"
                role="button"
                onClick={() => {
                  handleAddNode({
                    label: "Multiplicação",
                    nodeType: "diamond",
                    data: {
                      operationType: "multiply",
                      argsAmount: 2,
                    },
                  });
                  handleStepChange("operação");
                  setSelectedMenu("");
                }}
              >
                <Icon name="multiply" size={20} color="#000" />
                <p>Multiplicação</p>
              </div>
            </Action>
            <Action>
              <div
                className="operationsContainer"
                role="button"
                onClick={() => {
                  handleAddNode({
                    label: "Divisão",
                    nodeType: "diamond",
                    data: {
                      operationType: "divide",
                      argsAmount: 2,
                    },
                  });
                  handleStepChange("operação");
                  setSelectedMenu("");
                }}
              >
                <Icon name="divide" size={20} color="#000" />
                <p>Divisão</p>
              </div>
            </Action>
            <Action>
              <div
                className="operationsContainer"
                role="button"
                onClick={() => {
                  handleAddNode({
                    label: "Média",
                    nodeType: "diamond",
                    data: {
                      operationType: "average",
                      argsAmount: 2,
                    },
                  });
                  handleStepChange("operação");
                  setSelectedMenu("");
                }}
              >
                <Icon name="average" size={20} color="#000" />
                <p>Média</p>
              </div>
            </Action>
            <Action>
              <div
                className="operationsContainer"
                role="button"
                onClick={() => {
                  handleAddNode({
                    label: "Arredondar p/ Menos",
                    nodeType: "diamondOneValue",
                    data: {
                      operationType: "roundDown",
                      argsAmount: 1,
                    },
                  });
                  handleStepChange("operação");
                  setSelectedMenu("");
                }}
              >
                <Icon name="roundDown" size={20} color="#000" />
                <p>Arredondar p/ Menos</p>
              </div>
            </Action>
            <Action>
              <div
                className="operationsContainer"
                role="button"
                onClick={() => {
                  handleAddNode({
                    label: "Arredondar p/ Mais",
                    nodeType: "diamondOneValue",
                    data: {
                      operationType: "roundUp",
                      argsAmount: 1,
                    },
                  });
                  handleStepChange("operação");
                  setSelectedMenu("");
                }}
              >
                <Icon name="roundUp" size={20} color="#000" />
                <p>Arredondar p/ Mais</p>
              </div>
            </Action>
            <Action>
              <div
                className="operationsContainer"
                role="button"
                onClick={() => {
                  handleAddNode({
                    label: "Comparar",
                    nodeType: "diamond",
                    data: {
                      operationType: "compare",
                      argsAmount: 2,
                    },
                  });
                  handleStepChange("operação");
                  setSelectedMenu("");
                }}
              >
                <Icon name="compare" size={20} color="#000" />
                <p>Comparar</p>
              </div>
            </Action>
            <Action>
              <div
                className="operationsContainer"
                role="button"
                onClick={() => {
                  handleAddNode({
                    label: "Localizar e Substituir",
                    nodeType: "findAndReplace",
                    data: {
                      operationType: "findAndReplace",
                      argsAmount: 1,
                      findValue: "",
                      replaceValue: "",
                    },
                  });
                  handleStepChange("operação");
                  setSelectedMenu("");
                }}
              >
                <Icon name="replace" size={20} color="#000" />
                <p>Localizar e Substituir</p>
              </div>
            </Action>
            <Action>
              <div
                className="operationsContainer"
                role="button"
                onClick={() => {
                  handleAddNode({
                    label: "Comparar Especial",
                    nodeType: "runUntil",
                    data: {
                      parentId: currentAdvancedOperationId,
                    },
                  });
                  handleStepChange("operação");
                  setSelectedMenu("");
                }}
              >
                <Icon name="compare" size={20} color="#000" />
                <p>Comparar Especial</p>
              </div>
            </Action>
            {/* <Action>
              <div
                className="operationsContainer"
                role="button"
                onClick={() => {
                  handleAddNode({
                    label: "Valor Total",
                    nodeType: "diamond",
                    data: {
                      operationType: "sumAll",
                    },
                  });
                  handleStepChange("operação");
                  setSelectedMenu("");
                }}
              >
                <Icon name="sum" size={20} color="#000" />
                <p>Valor Total</p>
              </div>
            </Action> */}
          </>
        );
      default:
        return null;
    }
  };

  const onClose = async () => {
    const currentElements = getCurrentElements();

    const currentAdvancedOperationNodeFlow = flowElements.filter(
      element => element?.id === currentAdvancedOperationId,
    )?.[0]?.data?.flow;

    let parsedNodeFlow;
    if (currentAdvancedOperationNodeFlow?.length > 0) {
      parsedNodeFlow = JSON.parse(currentAdvancedOperationNodeFlow);
    }

    const currentElementsUpdated = currentElements.map(element => {
      if (
        (element?.type === "runUntil" || element?.type === "inputValue") &&
        parsedNodeFlow
      ) {
        const elUpdated = parsedNodeFlow.find(el => el.id === element.id);
        return elUpdated;
      }
      return element;
    });

    const getPath = (sourceId, path = []) => {
      const tempArray = path;
      currentElementsUpdated.forEach(element => {
        if (element.id === sourceId) {
          const targetConnections = getOutgoers(
            element,
            currentElementsUpdated,
          );
          const targetIds = [...new Set(targetConnections.map(el => el.id))];
          if (!targetIds.includes("end")) {
            tempArray.push(targetConnections);
            getPath(targetIds[0], tempArray);
          }
        }
      });
      return tempArray;
    };

    const template = getPath("start");

    setAdvancedOperationsFlow(
      currentAdvancedOperationId,
      JSON.stringify(currentElementsUpdated),
      template,
      steps,
    );

    history.push("/editor");
  };

  useEffect(() => {
    if (fields && fields.length > 0 && !selectedElement && !selectedField) {
      setSelectedElement(fields[Object.keys(fields)[0]]);
      setSelectedField(
        `${fields[Object.keys(fields)[0]].data.fields[0].description}-0`,
      );
    }
  }, [fields, selectedElement, selectedField]);

  return ReactDOM.createPortal(
    <Container>
      <HeaderActions>
        <div onClick={() => setHeaderActionType("name")} role="button">
          {blockName ? (
            <p>{blockName}</p>
          ) : (
            <p>Clique aqui para adicionar um nome</p>
          )}
        </div>
        <div>
          <div onClick={() => setHeaderActionType("comment")} role="button">
            <MdEdit />
            <p>Comentário</p>
          </div>
        </div>
      </HeaderActions>
      <Menu>
        <div>
          <MenuItem
            isActive={selectedMenu === "menu"}
            onClick={() => setSelectedMenu("field")}
          >
            <BiRectangle size={20} color="#fff" />
            <p>Campo</p>
          </MenuItem>
          <MenuItem
            isActive={selectedMenu === "menu"}
            onClick={() => setSelectedMenu("function")}
          >
            <BsDiamond size={20} color="#fff" />
            <p>Função</p>
          </MenuItem>
          <MenuItem
            isActive={selectedMenu === "menu"}
            onClick={() => handleAddNode({ label: "FIM", nodeType: "circle" })}
          >
            <BsFillCircleFill size={20} color="#fff" />
            <p>Fim</p>
          </MenuItem>
        </div>
        <div role="button" className="endBlock" onClick={onClose}>
          <BsFillXCircleFill size={32} />
        </div>
      </Menu>
      <ActionsContainer ref={actionsContainerRef}>
        {steps.map((step, index) => (
          <SideTitle
            key={`${step}-${(Math.random() / Math.random()) * Math.random()}`}
            isCurrentStep={currentStep === index}
            isShowing={currentStep >= index}
            stepNumber={index + 1}
          >
            {steps[index]}
          </SideTitle>
        ))}
        <Actions>{stepActionsComponents()}</Actions>
      </ActionsContainer>
      <FlowContainer>
        <SideTitle isCurrentStep isShowing>
          FLUXO
        </SideTitle>
        <Flow
          elements={elements}
          updateElements={setElements}
          reference={currentReactFlowState}
        />
      </FlowContainer>
      {(headerActionType === "name" || headerActionType === "comment") && (
        <Dialog
          isOpen
          title="Edição"
          handleClose={() => {
            setHeaderActionType("");
            setInputValue("");
          }}
          handleConfirm={() => {
            if (headerActionType === "name") {
              updateAdvancedOperationsName({
                nodeId: blockId,
                name: inputValue,
                currentFlow: elements,
              });
              setHeaderActionType("");
              setInputValue("");
            }
            if (headerActionType === "comment") {
              handleAddNode({
                nodeType: "comment",
              });
              setHeaderActionType("");
            }
          }}
        >
          <ModalContent>
            {headerActionType === "name" && (
              <label>Digite um nome para o bloco</label>
            )}
            {headerActionType === "comment" && (
              <label>Digite seu comentário</label>
            )}
            <input
              type="text"
              value={inputValue}
              onChange={e => setInputValue(e.target.value)}
            />
          </ModalContent>
        </Dialog>
      )}
    </Container>,
    document.getElementById("root"),
  );
}

export default ComplexOperations;
