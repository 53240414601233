export const getURLWithParams = (url, params) =>
  `${url}?${new URLSearchParams(params).toString()}`;

export const getURLParams = (params = window.location.search) =>
  new URLSearchParams(params);

export const getURLWithOrigin = url => `${window.location.origin}${url}`;

export const getFieldJsonParse = (values = "[]") => {
  try {
    return JSON.parse(values) || [];
  } catch (err) {
    return [];
  }
};
