import React from "react";
import { SWRConfig } from "swr";
import { ThemeProvider } from "styled-components";
import { AuthProvider, useAuth } from "./AuthContext";
import { FlowProvider } from "./FlowContext";
import { ToastProvider } from "./ToastContext";
import { PermissionsProvider, usePermissions } from "./PermissionsContext";
import { NotificationsProvider } from "./NotificationContext";
import { PagesProvider } from "./PageContext";
import { UsersProvider } from "./UsersContext";
import { ACLProvider, useACL } from "./ACLContext";
import { AppProvider as AppProviderLocal } from "./AppContext";
import { StoreProvider } from "./StoreContext";
import { SocketProvider, useSocket } from "./SocketContext";

import theme from "../styles/theme";

const swrConfigs = { revalidateOnFocus: false, shouldRetryOnError: false };

const AppProviderAuth = ({ component }) => {
  const { token } = useAuth();

  if (!token) return component;

  return (
    <PermissionsProvider>
      <SocketProvider>
        <ACLProvider>
          <FlowProvider>{component}</FlowProvider>
        </ACLProvider>
      </SocketProvider>
    </PermissionsProvider>
  );
};

const AppProvider = ({ children }) => (
  <ThemeProvider theme={theme}>
    <NotificationsProvider>
      <ToastProvider>
        <SWRConfig value={swrConfigs}>
          <AuthProvider>
            <AppProviderLocal>
              <UsersProvider>
                <PagesProvider valuesPageProvider>
                  <AppProviderAuth component={children} valuesPageProvider />
                </PagesProvider>
              </UsersProvider>
            </AppProviderLocal>
          </AuthProvider>
        </SWRConfig>
      </ToastProvider>
    </NotificationsProvider>
  </ThemeProvider>
);

export { useACL, StoreProvider, usePermissions, useSocket };

export default AppProvider;
