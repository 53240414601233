import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 8px 16px;
  aspect-ratio: 1;

  p,
  label {
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
  }

  label {
    text-align: left;
    margin-top: 8px;
  }

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f27c2a;
  }

  input:last-of-type {
    margin-bottom: 16px;
    background-color: ${props => props.isEditing ?? "#eee"};
  }
`;
