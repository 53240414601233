import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faTrash } from "@fortawesome/free-solid-svg-icons";
import { TextField } from "components/form";

import * as S from "./Assessor.styles";

export const Search = ({
  handleSearch,
  totalItems,
  isLoadingSearch,
  handleClear,
}) => {
  const [value, setValue] = useState();

  return (
    <S.InputInline>
      <TextField
        name="search"
        placeholder="Pesquise por Nome ou Email"
        onChange={e => {
          setValue(e.target.value);
        }}
      />
      <S.Btn
        type="button"
        onClick={() => {
          handleSearch(value);
        }}
      >
        {isLoadingSearch && (
          <FontAwesomeIcon
            spin
            icon={faSpinner}
            size="lg"
            style={{
              fontSize: "1.3rem",
            }}
            color="#a4a0a0"
          />
        )}
        {!isLoadingSearch && "Buscar"}
      </S.Btn>
      {!isLoadingSearch && totalItems > 0 && (
        <S.Btn
          background="#da0f0f"
          type="button"
          onClick={() => {
            handleClear();
          }}
        >
          <FontAwesomeIcon
            icon={faTrash}
            size="lg"
            style={{
              fontSize: "1rem",
            }}
            color="#fff"
          />
        </S.Btn>
      )}
    </S.InputInline>
  );
};
