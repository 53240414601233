/* eslint-disable react/jsx-indent */
import React, { useState } from "react";
import { Card, QueryBuilder } from "@mix/mixfiscal-designsystem";
import { format, parseISO } from "date-fns";

import { ButtonOpenNewTag, Button } from "components/structure";
import { GRID_LAWS } from "constants/routes";
import { Format } from "utils/date";

import * as S from "./LawLogs.styles";

const groupByDate = logs => {
  return Object.values(
    logs.reduce((acc, { createdAt, ...rest }) => {
      const date = format(parseISO(createdAt), "MM/dd/yyyy");
      if (!acc[date]) {
        acc[date] = {
          date,
          items: [
            {
              ...rest,
              createdAt,
            },
          ],
        };
      } else {
        acc[date].items.push({ createdAt, ...rest });
      }

      return acc;
    }, Object.create(null)),
  );
};

const TYPES_LOGS = {
  UPDATE_LAW: "UPDATE_LAW",
  GRADE_UPDATE: "GRADE_UPDATE",
  GRADE_CREATE: "GRADE_CREATE",
  GRADE_RELATION: "GRADE_RELATION",
  LAW_CREATE: "LAW_CREATE",
};

const TYPES_LOGS_LABELS = {
  [TYPES_LOGS.GRADE_CREATE]: "Criando Grade",
  [TYPES_LOGS.GRADE_RELATION]: "Relacionamento de grade Antiga",
  [TYPES_LOGS.GRADE_UPDATE]: "Atualização de Grades",
  [TYPES_LOGS.UPDATE_LAW]: "Atualização da Lei",
  [TYPES_LOGS.LAW_CREATE]: "Criando Lei",
};

const listHasRelation = (list = []) =>
  Boolean(list.filter(item => item.type === TYPES_LOGS.GRADE_RELATION).length);

const LawLog = ({ type, _id, id_user: idUser, createdAt }) => {
  const handleOpenGrade = () =>
    window.open(`${GRID_LAWS.DETAIL(_id)}?type=icms`);

  const hasRelation = type === TYPES_LOGS.GRADE_RELATION;
  return (
    <div>
      <S.WrapperContainer>
        <S.Label>Log:</S.Label>
        <S.LabelValue>{TYPES_LOGS_LABELS[type]}</S.LabelValue>
        {hasRelation && <ButtonOpenNewTag onClick={handleOpenGrade} />}
      </S.WrapperContainer>
      {idUser?.name && (
        <S.WrapperContainer>
          <S.Label>Usuário:</S.Label>
          <S.LabelValue>{`${idUser?.name} ${idUser?.surname} (${idUser?.email})`}</S.LabelValue>
        </S.WrapperContainer>
      )}
      {!hasRelation && (
        <S.WrapperContainer>
          <S.Label>Data e Hora:</S.Label>
          <S.LabelValue>{Format(createdAt)}</S.LabelValue>
        </S.WrapperContainer>
      )}
    </div>
  );
};

const LawsLogsItems = ({ date, logs = [] }) => {
  const [showAll, setShowAll] = useState(false);

  const totalLogs = logs.length;

  const hasMost = totalLogs >= 1;

  const loadMost = () => setShowAll(!showAll);

  return (
    <S.LogsItems>
      <QueryBuilder
        color="#596778"
        contents={(hasMost && !showAll ? logs.slice(0, 1) : logs).map(
          (item, i) => (
            <LawLog key={`${item._id}-item-log-${i}`} {...item} />
          ),
        )}
        title={
          listHasRelation(logs) ? "Grade Inicial" : `${date} (${totalLogs})`
        }
      />
      {hasMost && (
        <S.LogItemsAction>
          <Button type="button" onClick={loadMost}>
            {showAll ? "Mostrar Menos" : "Carregar +"}
          </Button>
        </S.LogItemsAction>
      )}
    </S.LogsItems>
  );
};

export const LawLogs = ({ logs = [] }) => {
  const logsAgg = groupByDate(logs);

  return (
    <S.Container>
      <Card title="Histórico" bold={false} titleIcon="certificado" hasElevation>
        {logsAgg.map(({ date, items = [] }, index) => (
          <LawsLogsItems key={`${date}-${index}`} date={date} logs={items} />
        ))}
      </Card>
    </S.Container>
  );
};
