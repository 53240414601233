import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  > section {
    padding: 0 10px;
    > p {
      margin-left: 22px;
      font-size: 20px;
      color: #fff;
    }
  }

  .myTemplatesContainer {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    overflow: auto;
    min-width: 0px;
    min-height: 0px;

    & > div:last-of-type {
      margin-right: auto;
    }

    ::-webkit-scrollbar {
      width: 3px;
      cursor: pointer;
    }

    ::-webkit-scrollbar-track {
      background-color: #bababa;
      cursor: pointer;
    }

    ::-webkit-scrollbar-thumb {
      background: #565656;
      border-radius: 20px;
      cursor: pointer;
    }
  }

  .row {
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .card-products {
    width: 90%;

    > section {
      min-height: 534px;
    }
  }

  .card-custom-task,
  .card-custom-period {
    width: 100%;
    > section {
      min-height: 500px;
      height: 100%;
    }
  }

  .card-custom {
    > section {
      display: flex;
      flex-direction: column;
      /* justify-content: space-between; */
    }
  }
`;

export const MainSection = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const DashboardContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 16px 8px;

  display: flex;

  section {
    flex: 1;
    display: flex;
    flex-direction: column;

    & > div:first-of-type {
      margin-bottom: 16px;
    }

    & > div {
      flex: 1;
      min-height: 0px;
    }

    .headerActions {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;

      p {
        font-size: 14px;
        color: #596778;

        span {
          font-weight: bold;
          margin: 0 3px;
        }
      }

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #adc86a;
        border-radius: 50%;
        padding: 8px;
        margin-right: 8px;

        svg {
          fill: #fff;
          width: 24px;
          height: 24px;
        }
      }

      input,
      select {
        border: 1px solid #596778;
        color: #596778;
        border-radius: 5px;
        padding: 8px;
        font-size: 14px;
        align-self: stretch;
      }

      input {
        margin-right: 8px;
      }

      & > div {
        display: flex;
        align-items: center;
      }
    }
  }

  aside {
    width: 400px;
    height: 100%;

    & > div {
      height: 100%;
    }

    .content {
      overflow: auto;

      ::-webkit-scrollbar {
        width: 3px;
        cursor: pointer;
      }

      ::-webkit-scrollbar-track {
        background-color: #bababa;
        cursor: pointer;
      }

      ::-webkit-scrollbar-thumb {
        background: #565656;
        border-radius: 20px;
        cursor: pointer;
      }
    }

    .headerActions {
      width: 100%;
      margin-bottom: 16px;

      & > div {
        display: flex;
        gap: 8px;
      }

      input,
      select {
        flex: 1;
        border: 1px solid #596778;
        color: #596778;
        border-radius: 5px;
        padding: 8px;
        font-size: 14px;
        align-self: stretch;
      }

      input {
        margin-right: 8px;
      }

      & > div {
        display: flex;
        align-items: center;
      }
    }

    .templatesListContainer {
      margin-bottom: 16px;

      & > div {
        margin-bottom: 8px;
      }
    }

    h3 {
      font-size: 12px;
      color: #17456b;
    }
  }
`;

export const FlowItem = styled.div`
  width: 150px;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
  position: relative;

  &.newFlow {
    cursor: pointer;
  }

  &:not(:last-of-type) {
    margin-right: 8px;
  }

  button {
    border: 1px solid black;
    padding: 4px;
    border-radius: 8px;
    transition: all 0.2s;
    margin-top: 8px;

    &:hover {
      background-color: #e5e5e5;
    }
  }

  & > svg {
    position: absolute;
    top: 0;
    right: 0;
    margin: 8px;
    cursor: pointer;
  }
`;

export const BlocoContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 5px;
`;

export const BlocoOne = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px;
`;

export const BlocoOneTributacao = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px;
`;

export const BlocoOneOverView = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px;
`;

export const BlocoTwoTributacao = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
`;

export const BlocoTwoOverView = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
`;
export const BlocoTwo = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 9px;
  margin: 20px 0;

  .card-custom {
    width: 100%;
    height: 100%;

    > section {
      height: 100%;
    }
  }

  .container-card-tasks {
    width: 100%;
  }
`;
export const ContainerHeader = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;

  .filters {
    border: 1px solid #d1d1d1;
    border-radius: 5px;

    span > label {
      color: #596778;
      padding: 7px;
    }

    .selected {
      background: #5a8dee;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;

      > label {
        color: #ffff !important;
      }
    }
  }

  .header-titles {
    line-height: 1.1;

    > h1 {
      color: #5a8dee;
      font-weight: 600;
    }

    > span {
      color: #c4c4c4;
    }
  }

  .links {
    font-size: 12px;
    padding: 5px;
    background: #64a46d;
    border-radius: 5px;
    color: #ffff;
  }
`;

export const HeaderCard = styled.span`
  width: 100%;
  line-height: 25px;

  > h1 {
    color: #1b66b0;
  }

  > span {
    color: #596778;
  }
`;

export const ContainerBullet = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
`;

export const BulletLine = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  .container-bullet-line {
    display: flex;
    justify-content: space-between;
    align-items: center;

    div:first-child {
      width: 90%;
      height: 10px;
      background-color: #e7edf3;
      border-radius: 10px;

      .percent-line {
        width: ${props => props.percent}%;
        background: ${props => props.color};
      }

      label {
      }
    }
  }
`;
