import { useRef } from "react";
import axios from "axios";
// import { getSessionCompanies } from "utils/session";

const AUTHENTICATION_BASE_URL = "https://api.builder.mixfiscal.com.br/mxf";

export const useMxfAPI = () => {
  const apiRef = useRef(axios.create({ baseURL: AUTHENTICATION_BASE_URL }));

  apiRef.current.interceptors.request.use(config => {
    // config.headers.Authorization = getSessionCompanies();
    return config;
  });

  apiRef.current.interceptors.response.use(
    response => response,
    error => {
      // console.log(error.response);
      return Promise.reject(error);
    },
  );

  return apiRef.current;
};
