import styled, { css } from "styled-components";
import colors from "styles/tokens/colors";

export const CardFilterItemShow = styled.div`
  max-height: 0;
  overflow: hidden;
  background: #fff;
  transition: max-height 0.15s ease-out;
  ${({ active }) => css`
    ${active &&
    `
    max-height: 500px;
    transition: max-height 0.25s ease-in;
    box-shadow: -1px 1px 7px 1px hsl(0deg 0% 0% / 29%);
   `}
  `}
`;
export const CardFilterLabel = styled.label`
  color: #596778;
  display: block;
  cursor: pointer;
  font-weight: bold;
  padding: 5px 0 0px 10px;
  margin: -1px 4px 0px 4px;
  text-decoration: underline;
  -webkit-text-decoration: underline;
`;
export const CardFilterItem = styled.div`
  right: 0;
  width: 250px;
  margin-top: 60px;
  position: absolute;
  margin-right: 41px;
  margin-bottom: 10px;
  z-index: 999999;
`;

export const CardFilterContent = styled.div`
  width: 100%;
  padding: 10px 10px 5px 10px;
`;
export const CardFilterFooter = styled.div`
  text-align: center;
  width: 100%;
  padding: 5px 10px 10px 10px;
`;

export const ButtonFIlter = styled.button`
  font-weight: bold;
  padding: 3px 20px 3px 20px;
  background-color: #ebebeb;
  border-radius: 5px;
  border: 1px solid #494949;
`;
export const ButtonFIlterError = styled.button`
  color: #fff;
  font-weight: bold;
  padding: 3px 20px 3px 20px;
  background-color: ${colors.red};
  border-radius: 5px;
  margin-right: 5px;
`;
