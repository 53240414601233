const convertValuesToStringOrValue = value =>
  Array.isArray(value) ? JSON.stringify(value) : value;

const hasOutOperation = cenarios =>
  !cenarios.origem && cenarios.ufDestino && cenarios.saida;

const replaceValue = value => value?.replace(/\./g, "");

const getCenaryFormatted = cenarios => {
  const filter = {};
  if (cenarios.origem || cenarios.saida) {
    filter.operacao = hasOutOperation(cenarios)
      ? cenarios.saida
      : cenarios.origem;
  }

  if (!cenarios.ufOrigem && cenarios.ufDestino && cenarios.saida) {
    filter.uf = cenarios.ufDestino;
  }

  if (cenarios.ufOrigem) {
    filter.uf = cenarios.ufOrigem;
  }

  if (cenarios.ufDestino && cenarios.ufOrigem) {
    filter.uf_saida = cenarios.ufOrigem;
    filter.uf = cenarios.ufDestino;
  }
  if (cenarios.atividade) {
    filter.atividade = cenarios.atividade;
  }

  if (cenarios.saida && cenarios.ufDestino && cenarios.ufOrigem) {
    filter.operacao_saida = cenarios.saida;
  }

  if (cenarios.tipoEmpresa) {
    filter.regime = cenarios.tipoEmpresa;
  }

  if (cenarios.operacao) {
    filter.operacao = cenarios.operacao;
  }

  if (cenarios.regime) {
    filter.regime = cenarios.regime;
  }

  if (cenarios.uf) {
    filter.uf = cenarios.uf;
  }

  if (cenarios.uf_saida) {
    filter.uf_saida = cenarios.uf_saida;
  }

  return filter;
};

export const useGradeFilter = ({ values }) => {
  const [cenarios] = values?.cenarios ?? [];
  const [ncm] = values?.ncms ?? [];
  const [cest] = values?.cests ?? [];

  if (!cenarios || values.gradeIcms) return null;

  const filter = {};

  if (cenarios.origem || cenarios.saida) {
    filter.operacao = hasOutOperation(cenarios)
      ? cenarios.saida
      : cenarios.origem;
  }

  if (!cenarios.ufOrigem && cenarios.ufDestino && cenarios.saida) {
    filter.uf = cenarios.ufDestino;
  }

  if (cenarios.ufOrigem) {
    filter.uf = cenarios.ufOrigem;
  }

  if (cenarios.ufDestino && cenarios.ufOrigem) {
    filter.uf_saida = cenarios.ufDestino;
  }
  if (cenarios.atividade) {
    filter.atividade = cenarios.atividade;
  }

  if (cenarios.saida && cenarios.ufDestino && cenarios.ufOrigem) {
    filter.operacao_saida = cenarios.saida;
  }

  if (cenarios.tipoEmpresa) {
    filter.regime = cenarios.tipoEmpresa;
  }

  if (cest?.data?.vc_codigo) {
    filter.cest = cest.data.vc_codigo.replace(/\./g, "");
  }

  if (ncm?.data?.vc_codigo_sem_formatacao) {
    filter.ncm = ncm.data.vc_codigo_sem_formatacao.replace(/\./g, "");
  }

  return filter;
};

export const useGradeFilters = ({
  values,
  gradeId,
  lawId,
  hasCenary = true,
  isPisCofins = false,
  refLawId,
}) => {
  const filter = {};

  const prodCodigoBarras = values?.prod_codigo_barras || values?.ean;
  if (prodCodigoBarras) {
    filter.prod_codigo_barras = prodCodigoBarras;
  }

  if (values?.mix_nome) {
    filter.mix_nome = values?.mix_nome;
  }

  if (values?.cnpj) {
    if (Array.isArray(values?.cnpj)) {
      filter.cnpjs = JSON.stringify(values?.cnpj);
    } else {
      filter.cnpj = values?.cnpj;
    }
  }

  if (gradeId) {
    filter.gradeId = convertValuesToStringOrValue(gradeId);
  }

  if (lawId) {
    filter.lawId = convertValuesToStringOrValue(lawId);
  }

  if (refLawId) {
    filter.refLawId = convertValuesToStringOrValue(refLawId);
  }

  if (values?.status) {
    if (Array.isArray(values?.status) && values?.status.length) {
      filter.status = JSON.stringify(values?.status);
    } else {
      filter.status = values?.status;
    }
  }

  if (values?.cst) {
    filter.cst = convertValuesToStringOrValue(values?.cst);
  }

  const hasData = !prodCodigoBarras && !values?.mix_nome && values?.status;

  if (!values?.cenarios?.length && hasCenary && hasData) return null;

  filter.cenarios = JSON.stringify(
    (values?.cenarios ?? []).map(cenary => getCenaryFormatted(cenary)),
  );

  filter.ncms = JSON.stringify(
    (values?.ncms ?? [])
      .filter(ncm => ncm?.code ?? ncm.data.vc_codigo_sem_formatacao?.toString())
      .map(ncm => ({
        code: replaceValue(
          ncm?.code ?? ncm.data.vc_codigo_sem_formatacao?.toString(),
        ),
      })),
  );

  filter.cests = JSON.stringify(
    (values?.cests ?? []).map(cest => ({
      code: replaceValue(cest?.code ?? cest.data.vc_codigo),
    })),
  );

  if (prodCodigoBarras) {
    filter.prod_codigo_barras = prodCodigoBarras;
  }

  if (isPisCofins || values?.taxa?.pis || values?.taxa?.cofins) {
    filter.type = "piscofins";
  }

  return filter;
};
