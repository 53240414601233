import React, { useState, useEffect } from "react";
import { MdClose, MdEdit, MdDelete, MdSave } from "react-icons/md";
import { FaDatabase } from "react-icons/fa";

import { useFlow } from "../../../../../context/FlowContext";

import { Container, HeaderContainer, BodyContainer } from "./styles";

function OperationNode({ id, data }) {
  const { label, isSelected } = data;

  const [options, setOptions] = useState([]);
  const [operationType, setOperationType] = useState("");
  const [selectedFields, setSelectedFields] = useState(false);
  const [selectedNode1, setSelectedNode1] = useState("");
  const [selectedNode1Fields, setSelectedNode1Fields] = useState([]);
  const [selectedNode1Field, setSelectedNode1Field] = useState("");
  const [selectedNode2, setSelectedNode2] = useState("");
  const [selectedNode2Fields, setSelectedNode2Fields] = useState([]);
  const [selectedNode2Field, setSelectedNode2Field] = useState("");
  const [outputName, setOutputName] = useState("");
  const [isEditingName, setIsEditingName] = useState(false);

  const {
    elements,
    handleRemoveNode,
    handleUpdateNodeData,
    handleUpdateSelectedField,
  } = useFlow();

  useEffect(() => {
    console.log({
      selectedNode1,
      selectedNode1Field,
      selectedNode2,
      selectedNode2Field,
      operationType,
    });
  }, [
    selectedNode1,
    selectedNode1Field,
    selectedNode2,
    selectedNode2Field,
    operationType,
  ]);

  useEffect(() => {
    const dataElements = elements.filter(element => !!element.data.dataType);
    setOptions(dataElements);
  }, [elements]);

  useEffect(() => {
    if (data?.selectedNode1 && selectedNode1 === "" && options.length > 0) {
      setSelectedNode1(data.selectedNode1);
      const element = options.find(option => option.id === data.selectedNode1);
      setSelectedNode1Fields(element.data.fields);
    }
    if (data?.selectedNode1Field && selectedNode1Field === "")
      setSelectedNode1Field(data.selectedNode1Field);
  }, [data, options, selectedNode1, selectedNode1Field]);

  useEffect(() => {
    if (data?.selectedNode2 && selectedNode2 === "" && options.length > 0) {
      setSelectedNode2(data.selectedNode2);
      const element = options.find(option => option.id === data.selectedNode2);
      setSelectedNode2Fields(element?.data.fields);
      if (data?.selectedNode2Field && selectedNode2Field === "")
        setSelectedNode2Field(data.selectedNode2Field);
    }
  }, [data, options, selectedNode2, selectedNode2Field]);

  useEffect(() => {
    if (data?.operationType && operationType === "") {
      setOperationType(data.operationType);
    } else if (operationType === "") {
      setOperationType("add");
    }
  }, [data, operationType]);

  useEffect(() => {
    const element = options.find(option => option.id === selectedNode1);
    if (element) {
      const selectedNode = element.data.fields?.find(
        node => node._id === selectedNode1Field,
      );

      setSelectedNode1Fields(element.data.fields);
      setSelectedNode1Field(selectedNode?._id || element.data.fields?.[0]?._id);
    }
  }, [options, selectedNode1, selectedNode1Field]);

  useEffect(() => {
    const element = options.find(option => option.id === selectedNode2);
    if (element) {
      const selectedNode = element.data.fields?.find(
        node => node._id === selectedNode2Field,
      );

      setSelectedNode2Fields(element.data.fields);
      setSelectedNode2Field(selectedNode?._id || element.data.fields?.[0]?._id);
    }
  }, [options, selectedNode2, selectedNode2Field]);

  const handleFieldSelection = (field, index) => {
    const checked = !selectedFields;
    setSelectedFields(checked);
    handleUpdateSelectedField({
      id,
      fieldIndex: index,
      isSelected: checked,
    });
  };

  useEffect(() => {
    if (isSelected) {
      setSelectedFields([data.name]);
    }
  }, [data.name, isSelected]);

  return (
    <Container>
      <HeaderContainer>
        <div>
          <FaDatabase size={24} color="#fff" />
          <p>{label}</p>
        </div>
        <MdClose size={24} color="#fff" onClick={() => handleRemoveNode(id)} />
      </HeaderContainer>
      <BodyContainer style={{ display: "flex" }}>
        <div className="checkbox-preview">
          <input
            type="checkbox"
            className="checkbox"
            checked={selectedFields}
            onChange={() => handleFieldSelection(data, 0)}
          />

          {isEditingName ? (
            <>
              <input
                value={outputName}
                placeholder="Digite um nome para o bloco"
                onChange={e => {
                  setOutputName(e.target.value);
                }}
              />
              <MdSave
                size={24}
                color="#596778"
                onClick={() => {
                  setIsEditingName(false);
                  handleUpdateNodeData({
                    id,
                    data: {
                      ...data,
                      name: outputName,
                    },
                  });
                }}
              />
            </>
          ) : (
            <>
              <label>{data?.name}</label>
              <MdEdit
                size={24}
                color="#596778"
                onClick={() => {
                  setIsEditingName(true);
                  setOutputName(data?.name || "");
                }}
              />
              <MdDelete
                size={24}
                color="#F44336"
                onClick={() => handleRemoveNode(id)}
              />
            </>
          )}
        </div>

        <select
          value={selectedNode1}
          onChange={e => {
            setSelectedNode1(e.target.value);
            handleUpdateNodeData({
              id,
              data: {
                ...data,
                selectedNode1: e.target.value,
                selectedNode1Field: options.find(
                  option => option.id === e.target.value,
                ).data.fields?.[0]?._id,
                selectedNode2,
                selectedNode2Field,
                operationType,
              },
            });
          }}
        >
          <option value="">Selecione um bloco</option>
          {options &&
            options.length > 0 &&
            options.map(option => (
              <option key={option.id} value={option.id}>
                {option?.data?.label}
                {option?.data?.register && ` - ${option.data.register}`}

                {` - ${option.id}`}
              </option>
            ))}
        </select>
        <select
          value={selectedNode1Field}
          onChange={e => {
            setSelectedNode1Field(e.target.value);
            handleUpdateNodeData({
              id,
              data: {
                ...data,
                selectedNode1,
                selectedNode1Field: e.target.value,
                selectedNode2,
                selectedNode2Field,
                operationType,
              },
            });
          }}
        >
          {selectedNode1Fields &&
            selectedNode1Fields.length > 0 &&
            selectedNode1Fields.map(field => (
              <option key={field._id} value={field._id}>
                {field.description}
              </option>
            ))}
        </select>
        <select
          value={operationType}
          onChange={e => {
            setOperationType(e.target.value);
            handleUpdateNodeData({
              id,
              data: {
                ...data,
                selectedNode1,
                selectedNode1Field,
                selectedNode2,
                selectedNode2Field,
                operationType: e.target.value,
              },
            });
          }}
          className="operations"
        >
          <option value="add">Somar</option>
          <option value="subtract">Subtrair</option>
          <option value="multiply">Multiplicar</option>
          <option value="divide">Dividir</option>
          <option value="average">Media</option>
          <option value="roundDown">Arredondar Menos</option>
          <option value="roundUp">Arredondar Mais</option>
          <option value="compare">Comparação</option>
          <option value="findAndReplace">Procurar e Substituir</option>
        </select>
        {operationType === "findAndReplace" ? (
          <div>
            <label>Procurar:</label>
            <input
              value={selectedNode2}
              onChange={e => {
                setSelectedNode2(e.target.value);
                handleUpdateNodeData({
                  id,
                  data: {
                    ...data,
                    selectedNode1,
                    selectedNode1Field,
                    selectedNode2: e.target.value,
                    selectedNode2Field,
                    operationType,
                  },
                });
              }}
            />
          </div>
        ) : (
          <select
            value={selectedNode2}
            onChange={e => {
              setSelectedNode2(e.target.value);
              handleUpdateNodeData({
                id,
                data: {
                  ...data,
                  selectedNode1,
                  selectedNode1Field,
                  selectedNode2: e.target.value,
                  selectedNode2Field: options.find(
                    option => option.id === e.target.value,
                  ).data.fields?.[0]?._id,
                  operationType,
                },
              });
            }}
          >
            <option value="">Selecione um bloco</option>
            {options &&
              options.length > 0 &&
              options.map(option => (
                <option key={option.id} value={option.id}>
                  {option?.data?.label}
                  {option?.data?.register && ` - ${option.data.register}`}

                  {` - ${option.id}`}
                </option>
              ))}
          </select>
        )}
        {operationType === "findAndReplace" ? (
          <div>
            <label>Substituir:</label>
            <input
              value={selectedNode2Field}
              onChange={e => {
                setSelectedNode2Field(e.target.value);
                handleUpdateNodeData({
                  id,
                  data: {
                    ...data,
                    selectedNode1,
                    selectedNode1Field,
                    selectedNode2,
                    selectedNode2Field: e.target.value,
                    operationType,
                  },
                });
              }}
            />
          </div>
        ) : (
          <select
            value={selectedNode2Field}
            onChange={e => {
              setSelectedNode2Field(e.target.value);
              handleUpdateNodeData({
                id,
                data: {
                  ...data,
                  selectedNode1,
                  selectedNode1Field,
                  selectedNode2,
                  selectedNode2Field: e.target.value,
                  operationType,
                },
              });
            }}
          >
            {selectedNode2Fields &&
              selectedNode2Fields.length > 0 &&
              selectedNode2Fields.map(field => (
                <option key={field._id} value={field._id}>
                  {field.description}
                </option>
              ))}
          </select>
        )}
      </BodyContainer>
    </Container>
  );
}

export default OperationNode;
