import React from "react";
import { components } from "react-select";
import { Card, Tooltip } from "@mix/mixfiscal-designsystem";
import { FiPlus, FiX } from "react-icons/fi";

import {
  Alert,
  ButtonAdd,
  ButtonRemove,
  LoadPlaceholder,
  ButtonOpenNewTag,
} from "components/structure";
import { Select } from "components/form";
import { useLaws } from "useCases/laws";
import { useAnalyticsGrade } from "useCases/grid";
import { LAWS } from "constants/routes";

import {
  getListTagsValid,
  getListSelecteds,
  hasScenery,
} from "utils/context/grid-manager";
import { AttributesContainer } from "components/contexts/law-grade/LawAttributes/AttributesContainer";

import { LawReplicateInformations } from "./LawReplicateInformations";

import * as S from "./LawReplicate.styles";

// Ao ter um vínculo com outra grade as informações alteradas nessa ou na outra
//     grade/lei em questão serão replicadas automaticamente nessa Grade/Lei
const margin = 8;

export const LawReplicateAlertContent = () => (
  <p>
    Ao ter um vínculo com outra grade as informações de produtos movidos ou
    deletados serão replicadas na outra
  </p>
);

export const AttributesSelect = ({
  label = "Entrada",
  cst,
  alq,
  showPlaceholder = false,
}) => {
  return (
    <AttributesContainer title={label}>
      <LoadPlaceholder
        showNoData={showPlaceholder}
        textNoData={`Grade não tem opção de ${label}!`}
        styleBackDrop={{ backdropFilter: "blur(1px)" }}
        fontWeight="bold"
      >
        {!showPlaceholder && (
          <div style={{ width: "100%", padding: "0px 10px" }}>
            <div style={{ display: "flex" }}>
              <p style={{ marginRight: "10px" }}>CST</p>
              <strong>{cst}</strong>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ marginRight: "10px" }}>Alíquota</p>
              <strong>{alq}</strong>
            </div>
          </div>
        )}
      </LoadPlaceholder>
    </AttributesContainer>
  );
};

export const LawsSingleSelect = ({ children, data, ...props }) => (
  <components.SingleValue {...props}>
    <div style={{ display: "flex" }}>
      <p>Cenários:</p>
      <strong style={{ marginLeft: margin, marginRight: margin }}>
        {`${children?.cenarios} (${children?._id})`}
      </strong>
      {children?.regime_name && (
        <>
          <p>Regime Especial:</p>
          <strong style={{ marginLeft: margin, marginRight: margin }}>
            {children?.regime_name}
          </strong>
        </>
      )}
      <p>Ncms:</p>
      <strong style={{ marginLeft: margin, marginRight: margin }}>
        {children?.ncms}
      </strong>
      <p>Cests:</p>
      <strong style={{ marginLeft: margin, marginRight: margin }}>
        {children?.cests}
      </strong>
    </div>
  </components.SingleValue>
);

export const LawGradeOption = ({ law: children }) => {
  const sceneryEntry = hasScenery("entrada", children?.cenariosData);
  const sceneryOut = hasScenery("saida", children?.cenariosData);

  const id = children?._id;
  return (
    <div style={{ width: "100%", padding: "5px 10px 10px 10px" }}>
      <div style={{ display: "flex" }}>
        <p style={{ marginRight: "10px" }}>Cenários: </p>
        <strong>{children.cenarios}</strong>
        <strong style={{ marginLeft: "10px" }}>{`(${id})`}</strong>
        <ButtonOpenNewTag
          title="Abrir detalhe da grade"
          onClick={() => window.open(`${LAWS.DETAIL(id)}?type=icms`)}
        />
      </div>
      {children.regime_name && (
        <div style={{ display: "flex" }}>
          <p style={{ marginRight: "10px" }}>Regime Especial: </p>
          <strong>{children.regime_name}</strong>
        </div>
      )}
      <div style={{ display: "flex" }}>
        <p style={{ marginRight: "10px" }}>Ncms: </p>
        <strong>{children.ncms}</strong>
      </div>
      <div style={{ display: "flex" }}>
        <p style={{ marginRight: "10px" }}>Cests: </p>
        <strong>{children.cests}</strong>
      </div>
      <div style={{ display: "block" }}>
        <p style={{ marginRight: "10px" }}>Atributos: </p>
        <div style={{ display: "flex", width: "100%", gap: 10 }}>
          <AttributesSelect
            cst={children?.atributos?.cstEntrada}
            alq={children?.atributos?.aliqCstEntrada}
            showPlaceholder={!sceneryEntry}
          />

          <AttributesSelect
            cst={children?.atributos?.cstSaida}
            alq={children?.atributos?.aliqCstSaida}
            label="Saída"
            showPlaceholder={!sceneryOut}
          />
        </div>
      </div>
    </div>
  );
};

const CustomOption = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      <LawGradeOption law={children} />
    </components.Option>
  );
};

const filterOptions = (candidate, input) => {
  if (input) {
    return (
      candidate?.label?.grade?._id === input ||
      candidate?.value === input ||
      candidate?.label?._id === input ||
      candidate?.label?.grade?._id?.includes(input) ||
      candidate?.value?.includes(input) ||
      candidate?.label?._id?.includes(input) ||
      candidate?.label?.cenarios?.includes(input)
    );
  }
  return true;
};

const LawFormatLabel = law => {
  const cenarios = law?.cenarios
    ?.map(cenario =>
      getListSelecteds(
        getListTagsValid([
          cenario.origem,
          cenario.saida,
          cenario.ufOrigem,
          cenario.ufDestino,
          cenario.tipoEmpresa,
          cenario.atividade,
        ]),
      ),
    )
    .join(", ");

  const ncms = (law?.ncms ?? []).map(ncm => ncm.codigo).join(", ");
  const cests = (law?.cests ?? []).map(cest => cest.codigo).join(", ");

  return {
    cenarios,
    ncms,
    cests,
    _id: law._id,
    atributos: law.atributos,
    cenariosData: law.cenarios,
    regime_name: law.regime_name,
    grade: law?.grades[0] ?? {},
  };
};

export const LawReplicate = ({ lawsIds = [], setValue = () => {}, lawId }) => {
  const { data: dataAnalytics } = useAnalyticsGrade({ lawId });
  const { data } = useLaws({
    refLawId: lawId,
  });

  const laws = [...(data?.laws?.docs || [])].map(law => ({
    value: law._id,
    label: LawFormatLabel(law),
  }));

  const handleAdd = () => {
    setValue("id_laws", [...lawsIds, [""]]);
  };

  const handleRemove = id => () => {
    setValue(
      "id_laws",
      lawsIds.filter(lawIdValue => lawIdValue !== id),
    );
  };

  const onChange = index => item => {
    const ids = lawsIds;
    ids.splice(index, 1, item.value);

    setValue("id_laws", ids);
  };

  const lawsValues = lawsIds.length !== 0 ? lawsIds : [""];

  return (
    <Card title="Vincular com outra Lei/Grade">
      {lawsValues.map((idLaw, index) => (
        <S.ContainerFieldWrapper key={`wrapper-${idLaw}`}>
          <S.ContainerField key={`container-${idLaw}`}>
            <Select
              key={`select-${idLaw}`}
              label="Grade/Lei"
              labelType="top"
              data={laws}
              value={laws.find(law => law.value === idLaw)}
              components={{
                Option: CustomOption,
                SingleValue: LawsSingleSelect,
              }}
              onChange={onChange(index)}
              filterOption={filterOptions}
            />
            {index < lawsValues.length - 1 && (
              <ButtonRemove type="button" onClick={handleRemove(idLaw)}>
                <Tooltip title="Remove" position="top">
                  <FiX size={22} color="white" className="icon" />
                </Tooltip>
              </ButtonRemove>
            )}
            {index === lawsValues.length - 1 && (
              <ButtonAdd type="button" onClick={handleAdd}>
                <Tooltip title="Adicionar" position="top">
                  <FiPlus size={22} color="white" className="icon" />
                </Tooltip>
              </ButtonAdd>
            )}
          </S.ContainerField>
          {idLaw !== "" && (
            <LawReplicateInformations
              idLaw={idLaw}
              notInLawId={lawId}
              totalProducts={dataAnalytics?.gradesIcms?.totalProductsMix ?? 0}
            />
          )}
        </S.ContainerFieldWrapper>
      ))}

      <Alert
        type="info"
        title="Informativo"
        content={<LawReplicateAlertContent />}
      />
    </Card>
  );
};
