import React from "react";
import Pagination from "@material-ui/lab/Pagination";

import { useProductsContext } from "context/ProductsContext";

export const SelectPages = () => {
  const { page, setPage, setParams, totalPages } = useProductsContext({});
  return (
    <Pagination
      color="primary"
      count={totalPages}
      page={page}
      onChange={(event, value) => {
        setPage(value);
        setParams({
          page: value,
          limit: 100,
        });
      }}
    />
  );
};
