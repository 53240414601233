import React, { useEffect } from "react";
import { Card } from "@mix/mixfiscal-designsystem";
import { useForm } from "react-hook-form";
import { useGridManagerContext } from "context/GridManagerContext";

import { CardFieldsTributarySituation } from "./CardFieldsTributarySituation";
import { CardLegislation } from "./CardLegislation";
import { CardAliquotTributary } from "./CardAliquotTributary";
import { CardSearchBy } from "./CardSearchBy";
import { CardTableTipi } from "./CardTableTipi";
import { CardTableCest } from "./CardTableCest";
import { CardCenario } from "./CardCenario";

import Validation, { defaultValues } from "./validations";
import { useWatch } from "./useCases";
import * as S from "./styles";

const norms = [
  { value: "1", label: "Lei" },
  { value: "2", label: "Lei Complementar" },
  { value: "3", label: "Decreto" },
  { value: "4", label: "Ato" },
  { value: "5", label: "Ato Normativo" },
  { value: "6", label: "Parecer" },
  { value: "7", label: "Convenção" },
  { value: "8", label: "Emenda" },
  { value: "9", label: "Solução de consulta" },
  { value: "10", label: "Resolução" },
  { value: "11", label: "Instrução Normativa" },
  { value: "12", label: "Portaria" },
  { value: "13", label: "Portaria Conjuta" },
  { value: "14", label: "Portaria Normativa" },
  { value: "15", label: "Nota explicativa" },
  { value: "16", label: "Comunicado" },
  { value: "17", label: "Orientação" },
  { value: "18", label: "Resposta à consulta" },
];

export const Filters = ({
  hasTributary = true,
  hasLegislation = true,
  hasCenary = true,
  hasSearchBy = false,
  params = {},
}) => {
  const { setFilterFields, filterFields } = useGridManagerContext();
  const { handleSubmit, control, register, watch, setValue } = useForm({
    resolver: Validation,
    mode: "onChange",
    defaultValues,
  });

  useWatch({ watch, control });

  const { types, type_organization: typeOrganization, ...restFields } = watch();

  const onSubmit = data => console.log(data);

  useEffect(() => {
    if (params.ean) {
      setValue("ean", params.ean);
    }
  }, [params.ean, setValue]);

  return (
    <S.Filters>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card
          handleCtaClick={() => {}}
          hasElevation
          headerColor="#adc86a"
          textColor="#FFF"
          title="Filtro"
          titleIcon="filtro1"
          className="enable-user-select"
        >
          <CardSearchBy
            register={register}
            types={types}
            control={control}
            searchBy={restFields.search_by}
            disabledProductSearch={hasSearchBy}
          />
          {hasCenary && (
            <CardCenario
              control={control}
              handleAdd={setFilterFields}
              handleClear={setFilterFields}
              cenarios={filterFields?.cenarios}
            />
          )}

          {hasLegislation && (
            <CardLegislation
              register={register}
              types={types}
              control={control}
              normsValue={norms}
            />
          )}

          <CardTableTipi
            control={control}
            handleAdd={setFilterFields}
            handleClear={setFilterFields}
            ncms={filterFields?.ncms}
          />
          <CardTableCest
            control={control}
            handleAdd={setFilterFields}
            handleClear={setFilterFields}
            cests={filterFields?.cests}
          />

          {hasTributary && (
            <>
              <CardFieldsTributarySituation
                register={register}
                typeOrganization={typeOrganization}
              />

              <CardAliquotTributary register={register} />
            </>
          )}
        </Card>
      </form>
    </S.Filters>
  );
};
