import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFile,
  faListCheck,
  faStore,
} from "@fortawesome/free-solid-svg-icons";

export const ListDados = [
  {
    title: "Sped",
    icon: <FontAwesomeIcon icon={faFile} />,
    dados: [
      {
        title: "Primeiro sped fiscal",
        value: "",
      },
      {
        title: "Última sped fiscal",
        value: "",
      },
      {
        title: "Total sped fiscal",
        value: "",
      },
      {
        isNewLine: true,
      },
      {
        title: "Primeiro sped contribuições",
        value: "",
      },
      {
        title: "Última sped contribuições",
        value: "",
      },
      {
        title: "Total sped contribuições",
        value: "",
      },
    ],
  },
  {
    title: "Paramêtros da Loja",
    icon: <FontAwesomeIcon icon={faStore} />,

    dados: [
      {
        title: "Quantidade de lojas",
        value: "",
      },
      {
        title: "Quantidade de Caixas",
        value: "",
      },
      {
        title: "Quantidade de funcionários",
        value: "",
      },
      {
        title: "Quantidade de funcionários",
        value: "",
      },
      {
        title: "Faturamento",
        value: "",
      },
      {
        title: "Regime de apuração",
        value: "",
      },
    ],
  },
  {
    title: "CheckList",
    icon: <FontAwesomeIcon icon={faListCheck} />,
    dados: [
      {
        title: "Sem checklist",
        value: "",
      },
    ],
  },
];
