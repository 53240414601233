import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = yup.object().shape({
  firstName: yup.string().required(),
  age: yup.number().positive().integer().required(),
});

export const defaultValues = {
  officeHour: {
    afterHoursAccess: ``,
    entryTime: ``,
    departureTime: ``,
  },
  permissions: {
    export: {
      xml: ``,
      pdf: ``,
      csv: ``,
      txt: ``,
    },
    import: {
      xml: ``,
      pdf: ``,
      csv: ``,
      txt: ``,
    },
  },
  contractService: ``,
  isActive: ``,
  acceptInvite: ``,
  name: ``,
  surname: ``,
  email: ``,
  responsibility: ``,
  type: ``,
  password: ``,
  confirmPassword: ``,
  groupPermissions: ``,
};

export default yupResolver(schema);
