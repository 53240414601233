import React from "react";
import { Alert, ButtonOpenNewTag } from "components/structure";
import { GRID_LAWS } from "constants/routes";

const textAlert = ({ sceneries }) => (
  <p>
    {`Apos salvar uma grade que não possui lei vinculada as opcoes de criar novos cenarios estara ativa. Foi agregado um total de `}
    <b>{sceneries.length}</b>
    {` grades.`}
    <br />
    <b>Cenários: </b>
    {sceneries?.map(scenery => (
      <div style={{ display: "flex" }}>
        <br />
        <i>Atividade:&nbsp;</i>
        <b>{scenery.atividade}</b>
        &nbsp;
        <i>Regime:&nbsp;</i>
        <b>{scenery.regime}</b>
        <i>Operação:&nbsp;</i>
        &nbsp;
        <b>{scenery.operacao?.toUpperCase()}</b>
        <ButtonOpenNewTag
          toLink={`${GRID_LAWS.DETAIL(scenery._id)}?type=icms`}
          openNewAba
        />
      </div>
    ))}
  </p>
);

export const LawSceneriesAggregate = ({ sceneries = [], onlyInfo = true }) => {
  if (onlyInfo) {
    return (
      <Alert
        type="primary"
        title="Informativo sobre os Cenários Mesclados"
        content={textAlert({ sceneries })}
      />
    );
  }

  return <div>asfsadfsd</div>;
};
