import React from "react";
import { useInviteAccept } from "useCases";
import { Redirect } from "react-router-dom";

const GetMessage = data => {
  const message = data.error && data.error.response.data.message;
  switch (message) {
    case "invite.used":
      return "Convite já aceito anteriormente";
    default:
      return "Não foi possível validar o convite";
  }
};
export const Invite = () => {
  const params = new URLSearchParams(window.location.search);
  const resultInviteCheck = useInviteAccept(params.get("id"));
  if (resultInviteCheck) {
    return <Redirect to="/" />;
  }
  return <h1>{GetMessage(resultInviteCheck)}</h1>;
};
