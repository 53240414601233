import styled, { css } from "styled-components";
import { CARD_HEADERS_COLORS_TYPES } from "constants/enums";

export const TitleCard = styled.div`
  ${({ theme }) => css`
    width: 100%;
    padding: ${theme.spacing.xs} ${theme.spacing.sm};
    background: ${theme.colors.white};
    position: relative;

    & > div {
      text-align: center;
    }
  `}
`;

export const RowContentCard = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: ${theme.spacing["2xs"]} 0;
  `}
`;

export const SectionCards = styled.div`
  ${({ theme }) => css`
    width: 32%;
    max-width: 300px;
    border: ${theme.border.default};
    border-top: 0px;
    border-radius: 5px;
    background: ${theme.colors.white};
  `}
`;

const SectionCardHeaderModifiers = {
  setColorHeader: color => css`
    /* TODO: Colocado !important pois esta alterando a cor no elemento header */
    background-color: ${color} !important;
  `,
};

export const SectionCardHeader = styled.header`
  ${({ theme, colorHeader = CARD_HEADERS_COLORS_TYPES.default }) => css`
    ${colorHeader &&
    SectionCardHeaderModifiers.setColorHeader(
      theme.colors.card.header[colorHeader],
    )}
  `}
`;

export const ContainerDaedline = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

export const Info = styled.span`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    color: ${theme.colors.grayscale.text};

    /* TODO: Adicionar a um token de thema */
    font-size: 12px;
    line-height: ${theme.spacing.sm};

    label {
      margin-right: ${theme.spacing.xs};
    }
  `}
`;

export const Actions = styled.div`
  ${({ theme }) => css`
    width: 100%;
    min-height: 20px;
    display: flex;
    padding: 0 ${theme.spacing.sm};
    justify-content: center;
    margin-bottom: ${theme.spacing.xs};
  `}
`;

export const ActionItem = styled.button`
  ${({ theme }) => css`
    background-color: transparent;
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing.xs};
    transition: ease-in-out 0.3s;
    align-items: center;
    border-radius: 5px;

    /* TODO: Adicionar a um token de thema */
    font-size: 12px;
    color: ${theme.colors.navyBlue};

    &:hover,
    &:active {
      background-color: rgb(230 230 230);
    }
  `}
`;

export const ButtonAction = styled.button`
  ${({ theme }) => css`
    background-color: transparent;
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing.xs};
    transition: ease-in-out 0.3s;
    align-items: center;
    border-radius: 50%;

    &:hover {
      background-color: rgb(230 230 230);
    }
  `}
`;
