import { colors } from "@material-ui/core";
import styled, { css } from "styled-components";

export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 70;
  justify-content: flex-start;
  width: 100%;
  > div {
    :last-child {
      > div {
        > div {
          min-height: 30px;

          > div {
            padding-bottom: 0px;
            padding-top: 0px;
            > div {
              padding-bottom: 0px;
              padding-top: 0px;
              > span {
                font-size: 14px !important;
              }
            }
          }
        }
      }
    }
  }

  :not(:last-child) {
    margin-right: 10px;
  }

  > div {
    cursor: pointer;
    min-width: 110px;
  }
`;

export const Label = styled.div`
  color: ${({ theme }) => theme.colors.slateGray};
  font-size: 12px;
  padding-left: 8px;
`;

const customCardOption = {
  setCustomCard: active => css`
    ${active
      ? `
    border-left: 3px solid #adc86a;
    `
      : `
    border-left: 3px solid #c86a6a;

    `},
  `,
};

export const OptionsCustom = styled.div`
  width: 100%;
  padding: 0px;
  display: flex;
  padding: 2px 0 2px 8px;
  margin: 5px 0 5px 0;
  flex-direction: column;
  border-bottom: 1px solid #f3f3f3;
  ${({ active }) => customCardOption.setCustomCard(active)}
`;

export const OptionsCustomLabel = styled.div`
  display: flex;
  color: ${colors.slateGray};
`;

export const OptionsCustomLabelText = styled.div`
  flex: 0.8;
  display: flex;
  flex-direction: column;
  b {
    font-weight: bold;
  }
`;

export const OptionsCustomLabelTextItem = styled.div``;

export const OptionsCustomLabelItems = styled.div`
  width: 100%;
  flex: 0.2;
  text-align: right;
  display: flex;
  flex-direction: column;
`;

export const OptionsCustomLabelItemsBadge = styled.div`
  display: flex;
  flex-direction: row;
`;

const customBadge = {
  setType: type => css`
    ${type === `MATRIZ` &&
    `
    background-color: #adc86a;
    color:#fff
    `}

    ${type === `Matriz` &&
    `
    background-color: #adc86a;
    color:#fff
    `}
    ${type === `ativo` &&
    `
    background-color: #adc86a;
    color:#fff
    `}
    ${type === `Filial` &&
    `
    background-color: #17456B;
    color:#fff
    `}
     ${type === `FILIAL` &&
    `
    background-color: #17456B;
    color:#fff
    `}
      ${type === `inativo` &&
    `
    background-color: #c86a6a;
    color:#fff
    `}
    ${type === `warning` &&
    `
    background-color: #d58f00;
    color:#000
    font-weight: bold;
    `}
    ${type === `green` &&
    `
    background-color: #adc86a;
    color:#000
    font-weight: bold;
    `}
  `,
};

export const Cnpj = styled.div`
  font-weight: bold;
  color: #000;
  font-size: 12px;
`;
export const Badge = styled.span`
  ${({ type }) => css`
    min-width: 30px;
    font-size: 12px;
    padding: 0px 10px 0px 10px;
    border-radius: 20px;
    font-weight: bold;
    margin: 0 4px 0 4px;
    ${type && customBadge.setType(type)}
  `}
`;

export const BadgeSelect = styled.span`
  ${({ type, margin = "5px 4px 0 4px" }) => css`
    top: 0;
    right: 0;
    position: absolute;

    min-width: 30px;
    font-size: 12px;
    padding: 0px 10px 0px 10px;
    border-radius: 20px;
    font-weight: bold;
    margin: ${margin};
    ${type && customBadge.setType(type)}
  `}
`;

const dot = (color = "#acacac") => ({
  alignItems: "center",
  display: "flex",

  ":before": {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: "block",
    marginRight: 8,
    height: 10,
    width: 10,
  },
});

export const customStyles = {
  menu: base => ({
    ...base,
    width: "100%",
    minHeight: "40px",
    padding: "10px 0px 0px 0px",
  }),
  container: base => ({
    ...base,
    width: "100%",
    minWidth: "100%",
  }),
  option: provided => ({
    ...provided,
    padding: "0 3px",
    fontSize: 13,
    cursor: "pointer",
  }),

  control: (styles, { selectProps, isDisabled }) => {
    const extraStyles = {};

    if (selectProps.variant === "simple") {
      extraStyles.border = "none";
      extraStyles.borderBottom = "1px solid #596778";
      extraStyles.borderRadius = 0;
      extraStyles["&:hover"] = {
        borderColor: "#596778",
        boxShadow: "none",
      };
    }

    extraStyles.border = "1px solid #596778";

    if (isDisabled) {
      extraStyles.border = "1px solid #cacccf";
      extraStyles["& svg"] = {
        fill: "#cacccf",
      };
    }

    return {
      ...styles,

      width: `100%`,
      backgroundColor: "white",
      fontSize: 12,
      cursor: "pointer",
      ...extraStyles,
    };
  },
  indicatorSeparator: styles => ({
    ...styles,
    width: 0,
  }),

  valueContainer: styles => ({
    ...styles,
    with: `100%`,
  }),
  input: styles => ({ ...styles, ...dot() }),
  placeholder: styles => ({ ...styles, ...dot("#ccc") }),
  singleValue: (styles, state) => {
    const opacity = state.isDisabled ? 1 : 1;
    return {
      ...styles,
      fontSize: 14,
      opacity,
    };
  },
};

// TODO: CERTIFICATE

export const Title = styled.p`
  display: flex;
  flex-wrap: nowrap;
  border-bottom: 0;
  padding: 0px 8px 0px 8px !important;
  h5 {
    font-size: 12px;
    color: #000;
  }
`;

export const TitleText = styled.div`
  flex: 75%;
  line-height: 27px;
`;

export const ContainerTooltip = styled.div`
  align-items: center;
  display: block;
  padding: 0px 8px 6px 8px;
  h4 {
    padding-top: 3px;
    color: #949494;
  }
`;

export const DaysCertified = styled.div`
  opacity: 1;
  width: 65px;
  height: 65px;
  margin: 0 auto;
  text-align: center;
  border-radius: 100%;
  background-color: ${({ active }) => (active ? "#adc86a" : "#c86a6a")};
  vertical-align: middle;
  line-height: 17px;
  padding-top: 16px;
  margin-bottom: 10px;
  box-shadow: 0px 3px 6px #00000029;
  h5 {
    font-size: 22px;
    font-weight: bold;
    color: #fff !important;
  }
  h6 {
    font-size: 13px;
    font-weight: bold;
    color: #fff !important;
  }
`;

export const FooterOption = styled.div`
  width: 100%;
  padding: 5px 10px 5px 10px;
  background-color: #f3f3f3;
  bottom: 0;
  display: flex;
  position: absolute;
`;

export const ButtonRemove = styled.button`
  background-color: #f3f3f3f;
`;
export const Select = styled.div`
  width: 100;
`;

export const FooterOptionText = styled.div`
  width: 50%;
  font-size: 13px;
  margin-right: 10px;
  display: flex;
`;

export const FooterOptionTextItem = styled.div`
  margin-right: 10px;
`;
export const FooterOptionAction = styled.div`
  right: 0;
  width: 50%;
  display: flex;
  text-align: right;
  align-items: inherit;
  justify-content: right;
`;

export const FooterOptionActionBtn = styled.button`
  margin-left 10px;
  background-color: #596778;
  border-radius: 5px;
  font-size: 10px;
  padding: 2px 5px 2px 5px;
  ${({ type }) => css`
    ${type && customBadge.setType(type)}
  `}
`;

export const FormatOptionLabel = styled.div`
  min-width: 130px;
  max-width: 175px;
  display: flex;
  transition: all 0.3s;
`;

export const Span = styled.span`
  margin: 5px 0 0 0;
  width: 90%;
  font-weight: bold;
  color: #000;
  margin-left: 3px;
  overflow: hidden;
  white-space: nowrap;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  text-overflow: ellipsis;
`;

export const FormatOptionLabelBadge = styled.div`
  width: 15px;
  height: 15px;
  background-color: red;
  border-radius: 100%;
  margin: 8px 0 0 0;
  ${({ type }) => css`
    ${type && customBadge.setType(type)}
  `}
`;

export const StyledBadgePlus = styled.div`
  margin-left: auto;
  background-color: #adc86a;
  border-radius: 4px;
  font-size: 11px;
  padding: 2px 4px 2px 4px;
`;
