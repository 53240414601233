import React from "react";
import { useFormContext, useFieldArray } from "react-hook-form";
import { Container, Card } from "components/structure";
import { List, ListContent } from "components/contexts/grid-manager";
import { useGradeFilters } from "hooks/useGrade";
import { useGridManagerContext } from "context/GridManagerContext";

export const BulkUpdateLaws = () => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "grades",
  });

  const { filterFields } = useGridManagerContext();

  const cst = Object.keys(filterFields?.cst_e).filter(
    r => filterFields?.cst_e[r],
  );

  const params = useGradeFilters({
    values: {
      cenarios: filterFields?.cenarios,
      ncms: filterFields.ncms,
      cests: filterFields.cests,
      ean: filterFields.prod_codigo_barras,
      mix_nome: filterFields.mix_nome,
      status: filterFields?.status,
      cst: cst?.length >= 1 ? cst : null,
      cnpj: filterFields.cnpj,
      cnpjs: filterFields.cnpjs,
    },
    gradeId: filterFields?.gradeId,
    hasCenary: false,
  });

  const handleSelect = ({ type, value }) => {
    if (type === "add") {
      append(value);
    } else {
      const index = fields.indexOf(value);
      remove(index);
    }
  };

  return (
    <Container paddingSize gridGap>
      <Card type="local" title="Selecionar Leis/Grades para Mesclar">
        <List hasSelect params={params} handleSelect={handleSelect} />
      </Card>
      {fields.length >= 1 && (
        <Card
          type="local"
          title={`Grades/Leis selecionadas (${fields.length})`}
        >
          <ListContent data={fields} />
        </Card>
      )}
    </Container>
  );
};
