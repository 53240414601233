import React from "react";

import { Container } from "./styles";

export const Overlay = ({
  bgColor = "rgba(0,0,0,0.6)",
  zIndex = 1,
  handleClick,
}) => {
  return (
    <Container
      bgColor={bgColor}
      zIndex={zIndex}
      onClick={handleClick && handleClick}
    />
  );
};
