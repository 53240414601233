import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 30px;
`;
export const InputUpload = styled.input`
  display: none;
`;
export const SearchBox = styled.div`
  width: 80%;
  display: flex;
  justify-content: center;
  input {
    text-transform: capitalize;
  }
  & > .dropdown-search {
    width: 78%;
    & > div:nth-child(1) {
    }

    & > div:nth-child(2) {
      display: flex;
      padding: 0;
      border-radius: 5px;
      min-height: 46px;
      max-height: 340px;
      width: 88%;
      z-index: 30;
      overflow: hidden;
      overflow-y: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
      margin-left: 6%;
      ::-webkit-scrollbar {
        display: none;
      }
    }
  }
`;

export const Logo = styled.img``;

export const DropDownContainer = styled.ul`
  list-style: none;

  li {
    cursor: pointer;
    transition: all 0.3s;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
    a {
      color: #000;
    }
    &:hover {
      background-color: #cbcbcb;
      box-shadow: 0px 2px 4px #00000033;
      a {
        color: #000;
        font-weight: bold;
      }
    }
  }

  li:not(:last-child) {
    margin-bottom: 15px;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  .dropdown-style {
    z-index: 11;
    > div {
      left: -90px;
      padding: 15px 10px 15px 10px;
    }
    > span svg {
      vertical-align: bottom;
    }
  }
  & > div {
    display: flex;
    align-items: center;
    gap: 8px;

    img {
      width: 57px;
      border-radius: 8px;
    }

    .credentials {
      color: #bababa;
      font-size: 15px;
      font-weight: bold;

      p:last-of-type {
        font-size: 12px;
        font-weight: normal;
      }
    }

    button {
      background-color: transparent;
      color: white;
      font-size: 15px;
      font-weight: bold;
      transition: all 0.1s ease-in-out;

      &:hover {
        color: #adc86a;
      }
    }

    svg {
      cursor: pointer;
    }
  }
`;

export const ModulesFiscInt = styled.button`
  background: ${({ active }) =>
    active ? `#256925 !important` : `#17456B !important`};
  background: transparent;
  width: 55px;
  height: 37px;
  opacity: 1;
  display: flex;
  border-radius: 5px;
  transition: all 0.3s;
  padding: 3px 11px 5px 10px;
  color: #fff;
  svg {
    transition: all 250ms;
    fill: #fff;
    margin-left: 5px;
    margin-top: 3px;
    margin-right: 5px;
  }
  &:hover {
    color: #fff;
    opacity: 1;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 18%);
    span {
      display: block;
    }
    svg {
      fill: #fff;
    }
    width: ${({ active }) =>
      active ? `183px !important` : `212px !important`};
    background: ${({ active }) =>
      active ? `#256925 !important` : `#17456B !important`};
  }

  span {
    margin-top: -1px;
    display: none;
    margin-left: 5px;
    overflow: hidden;
    white-space: nowrap;
    transition: all 0.3s;
    text-overflow: ellipsis;
  }
`;
export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 69px;
  max-width: 69px;
  height: 37px;
  border: 1px dashed #adc86a;
  border-radius: 5px;
  cursor: pointer;
  span {
    font-size: 12px;
    color: #000;
    font-weight: bold;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;
