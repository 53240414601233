import React from "react";
import { useTheme } from "hooks";
import { Icon, Tooltip } from "@mix/mixfiscal-designsystem";
import { ButtonAction } from "components/structure";

import * as S from "./ButtonAdd.styles";

export const ButtonAdd = ({
  handleClick = () => {},
  tooltipText,
  iconName = "adicionar",
}) => {
  const { colors } = useTheme();

  return (
    <S.Action>
      <Tooltip body={tooltipText} position="top" zIndex={1000}>
        <ButtonAction onClick={handleClick}>
          <Icon color={colors.lightGreen} name={iconName} size={32} />
        </ButtonAction>
      </Tooltip>
    </S.Action>
  );
};
