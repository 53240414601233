import { useCallback, useState, useEffect } from "react";
import {
  useAPIWhatsApp,
  useAPIAuthenticateToast,
  useAPIAuthenticate,
  useToast,
} from "hooks";

export const useWhatsAppActions = () => {
  const api = useAPIWhatsApp();

  const { isLoading, onSend: onSendApi } = useAPIAuthenticateToast(api);

  const onSendActiveWhatsApp = ({ email }) =>
    onSendApi({
      endpoint: "/v1/new-integration",
      messageSuccess: `WhatsApp Ativado com sucesso!`,
      messageFail: "FALHA AO TENTAR ATIVAR WHATSAPP",
      data: { clientId: email },
    });

  const onSendRemoveWhatsApp = ({ email }) =>
    onSendApi({
      endpoint: "/v1/remove-integration",
      messageSuccess: `WhatsApp removido com sucesso!`,
      messageFail: "FALHA AO TENTAR REMOVER WHATSAPP",
      data: { clientId: email },
    });

  return {
    isLoading,
    onSendActiveWhatsApp,
    onSendRemoveWhatsApp,
  };
};

export const useWhatsAppClient = ({ clientId, isPooling = false }) => {
  const [isLoading, setIsLoading] = useState();
  const [data, setData] = useState(null);
  const api = useAPIWhatsApp();

  const fetch = useCallback(() => {
    const req = async () => {
      try {
        setIsLoading(true);
        const { data: dataRes } = await api.get(
          `/v1/information-integration/${clientId}`,
        );

        setData(dataRes);
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    };

    req();
  }, [api, clientId]);

  useEffect(() => {
    if (clientId) {
      fetch();
    }
  }, [clientId, fetch]);

  useEffect(() => {
    if (isPooling) {
      const interval = setInterval(() => {
        fetch();
      }, 10000);

      return () => clearInterval(interval);
    }
  }, [fetch, isPooling]);

  return {
    isLoading,
    fetch,
    data,
  };
};

export const useUserWhatsappSendMsg = () => {
  const api = useAPIAuthenticate();
  const [loading, setLoading] = useState(false);
  const [succesSend, setSuccesSend] = useState(true);
  const { addToast } = useToast();

  const fetch = useCallback(
    value => {
      setLoading(true);
      if (!value.phone) {
        setSuccesSend(false);

        return addToast({
          type: "alert",
          align: "top",
          title: "Ops...",
          description: "O cliente selecionado não tem telefone",
        });
      }
      const fetchData = async () => {
        try {
          const response = await api.post(
            `https://api.builder.mixfiscal.com.br/app-integration/v1/send-message`,
            value,
          );
          if (response.nessage) {
            addToast({
              type: "success",
              align: "top",
              title: "Mensagem",
              description: "Mensagem enviada",
            });
          }
          setSuccesSend(true);

          setLoading(false);
        } catch (e) {
          setLoading(false);
          setSuccesSend(false);

          console.log(e.messagge);
        }
      };
      fetchData();
    },
    [api, addToast],
  );

  return {
    fetch,
    succesSend,
    isLoading: loading,
  };
};
