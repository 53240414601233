export default {
  "2xs": "4px",
  xs: "8px",
  sm: "16px",
  md: "24px",
  lg: "32px",
  xl: "48px",
  "2xl": "64px",
  "3xl": "88px",
  "4xl": "122px",
};
