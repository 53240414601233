import styled from "styled-components";

export const CardSubHeader = styled.div`
  display: flex;
  margin-bottom: 5px;
`;

export const CardSubHeaderDescription = styled.div`
  flex: 0.7;
`;
export const CardSubItemPagination = styled.div`
  width: 100%;
  .hqzNoU {
    border-bottom: 0px;
    margin-top: 0px;
    padding: 8px;
    .iuBnlF {
      font-size: 15px;
      padding: 2px 8px;
    }
  }
`;
export const CardSubHeaderAction = styled.div`
  flex: 0.3;
  display: flex;
  align-items: center;
  justify-items: right;
  justify-content: right;
`;

export const CardSubHeaderActionButton = styled.button`
  background-color: #256926 !important;
  color: #fff !important;
  font-size: 13px !important;
  padding: 4px 8px !important;
  border-radius: 5px;
  margin-top: 0 !important;
  margin: 0 5px 0 5px;
  span {
    display: none;
    margin-left: 5px;
    overflow: hidden;
    white-space: nowrap;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    text-overflow: ellipsis;
  }
`;
