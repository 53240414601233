import React from "react";
import ReactFlow, {
  addEdge,
  Background,
  ReactFlowProvider,
  useStoreState,
} from "react-flow-renderer";

import Diamond from "../Nodes/Diamond";
import DiamondOneValue from "../Nodes/DiamondOneValue";
import Circle from "../Nodes/Circle";
import Rectangle from "../Nodes/Rectangle";
import CreateZone from "../Nodes/CreateZone";
import Comment from "../Nodes/Comment";
import Input from "../Nodes/Input";
import CustomEdge from "../Edges/CustomEdge";
import AllValues from "../Nodes/AllValues";
import RunUntil from "../Nodes/RunUntil";

import FunctionFindAndReplace from "../Nodes/Functions/FindAndReplace";

const edgeTypes = {
  custom: CustomEdge,
};

const nodeTypes = {
  diamond: Diamond,
  diamondOneValue: DiamondOneValue,
  circle: Circle,
  rectangle: Rectangle,
  createZone: CreateZone,
  comment: Comment,
  inputValue: Input,
  findAndReplace: FunctionFindAndReplace,
  allValues: AllValues,
  runUntil: RunUntil,
};

function Flow({ elements, updateElements, reference }) {
  const Observer = () => {
    const nodes = useStoreState(state => [...state.nodes, ...state.edges]);
    reference.current = nodes;
    return null;
  };

  const getUpdatedPositionElements = () => {
    const updatedElements = JSON.parse(
      JSON.stringify(
        reference.current.map(element => {
          const updatedElement = JSON.parse(JSON.stringify(element));
          updatedElement.position = element?.__rf?.position;
          return updatedElement;
        }),
      ),
    );

    return updatedElements;
  };

  const handleConnectNode = params => {
    params.arrowHeadType = "arrowclosed";
    params.style = {
      strokeWidth: "3px",
    };
    updateElements(() => addEdge(params, getUpdatedPositionElements()));
  };

  const handleRemoveConnection = (_, { nodeId, handleId, handleType }) => {
    const currentElements = getUpdatedPositionElements();
    const filteredElements = currentElements.filter(el => {
      if (handleType === "target") {
        if (el.target === nodeId) {
          return el.targetHandle !== handleId;
        }
      }
      return el;
    });

    updateElements(filteredElements);
  };

  const handleRemoveNode = (_, { id }) => {
    if (id === "start" || id === "end" || id === "createZone") return;
    const currentElements = getUpdatedPositionElements();
    const filteredElements = currentElements.filter(element => {
      if (element.id.includes("reactflow__edge")) {
        return !(element.source === id || element.target === id);
      }
      return element.id !== id;
    });
    updateElements(filteredElements);
  };

  return (
    <ReactFlowProvider>
      <Observer />
      <ReactFlow
        elements={elements}
        edgeTypes={edgeTypes}
        nodeTypes={nodeTypes}
        style={{ width: "100%", height: "100%" }}
        onNodeContextMenu={handleRemoveNode}
        onConnect={handleConnectNode}
        onConnectStart={handleRemoveConnection}
        connectionLineStyle={{ stroke: "#000", strokeWidth: 2 }}
        connectionLineType="bezier"
        snapToGrid
        snapGrid={[1, 1]}
      >
        <Background color="black" gap={16} />
      </ReactFlow>
    </ReactFlowProvider>
  );
}

export default Flow;
