import React, { useState, useEffect } from "react";
import {
  MdAttachFile,
  MdInsertChart,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
} from "react-icons/md";
import { produce } from "immer";

import { useFlow } from "../../context/FlowContext";
import Dialog from "../UI/Dialog";
import { Container, TitleContainer, ActionsContainer } from "./styles";

function PreviewDatatable({
  isOpen,
  handleClose,
  selectedElement: { id, label = "", dataType = "", selectedColumns = [] },
}) {
  const [data, setData] = useState([]);
  const [sortingBy, setSortingBy] = useState("");
  const [sortDirection, setSortDirection] = useState("");

  const [element, setElement] = useState(null);
  const [hasDataFile, setHasDataFile] = useState(false);

  console.log({ id });

  const {
    previewData,
    setIsAttachingFileToNode,
    selectedCallToActionElementId,
    handleAttachFileToNode,
    getElementsById,
    elements,
    getSelectedFieldsFromElement,
    getFileData,
  } = useFlow();

  const compare = (baseState, key, currentDirection) =>
    produce(baseState, draft => {
      draft.sort((a, b) => {
        let comparisonValue = 0;
        let firstValue = a[key] ? a[key].toString().toUpperCase() : "";
        let secondValue = b[key] ? b[key].toString().toUpperCase() : "";

        if (currentDirection === "down")
          [firstValue, secondValue] = [secondValue, firstValue];

        if (typeof firstValue === "string") {
          if (firstValue > secondValue) {
            comparisonValue = 1;
            return comparisonValue;
          }
          if (firstValue < secondValue) {
            comparisonValue = -1;
            return comparisonValue;
          }
          if (firstValue === secondValue) {
            comparisonValue = 0;
            return comparisonValue;
          }
        }

        if (typeof firstValue === "number")
          comparisonValue = firstValue - secondValue;
        return comparisonValue;
      });
    });

  const handleSortDirection = sortBy => {
    if (sortBy !== sortingBy) {
      setSortingBy(sortBy);
      setSortDirection("ascending");
    }

    switch (sortDirection) {
      case "":
        setData(compare(data, sortBy, "up"));
        return setSortDirection("ascending");
      case "ascending":
        setData(compare(data, sortBy, "down"));
        return setSortDirection("descending");
      case "descending":
        setSortingBy("");
        setData(previewData);
        return setSortDirection("");
      default:
        return null;
    }
  };

  useEffect(() => {
    const [retrievedElement] = getElementsById(selectedCallToActionElementId);
    setElement(retrievedElement);
  }, [elements, getElementsById, selectedCallToActionElementId]);

  useEffect(() => {
    getSelectedFieldsFromElement(selectedCallToActionElementId);
  }, [getSelectedFieldsFromElement, selectedCallToActionElementId]);

  useEffect(() => {
    const fetchData = async () => {
      if (element && element.data.selectedDataFileId) {
        const fileData = await getFileData(selectedCallToActionElementId);
        if (fileData) {
          setData(fileData);
          if (fileData?.length > 0) {
            setHasDataFile(true);
          }
        } else {
          setHasDataFile(false);
        }
      } else if (element) {
        if (
          element.data.dataType === "txt" ||
          element.data.dataType === "xml"
        ) {
          return;
        }
        handleAttachFileToNode({
          nodeId: selectedCallToActionElementId,
          fileId: element.data.selectedTemplateFileId,
        });
      }
    };

    fetchData();
  }, [
    element,
    getFileData,
    selectedCallToActionElementId,
    handleAttachFileToNode,
  ]);

  const title = (
    <TitleContainer>
      <MdInsertChart color="#fff" size={24} />
      <p>
        {label &&
          dataType &&
          `VISUALIZAÇÃO - ${label} - ${dataType.toUpperCase()}`}
      </p>
    </TitleContainer>
  );

  console.log({ selectedColumns });

  return (
    <Dialog title={title} isOpen={isOpen} handleClose={handleClose}>
      <ActionsContainer>
        <div>
          <MdAttachFile
            size={32}
            color="#596778"
            onClick={() => setIsAttachingFileToNode(true)}
          />
        </div>
      </ActionsContainer>
      <Container hasData={data?.length > 0}>
        {hasDataFile ? (
          <table>
            <thead>
              <tr>
                {selectedColumns?.length > 0 &&
                  selectedColumns.map(column => (
                    <th key={column.index}>
                      <div
                        role="button"
                        onClick={() => handleSortDirection(column.index)}
                        className="titleContainer"
                        title={column.fieldName}
                      >
                        <p>{column.fieldName}</p>
                        {sortDirection === "ascending" &&
                          sortingBy === column.index && (
                            <MdKeyboardArrowDown size={20} />
                          )}
                        {sortDirection === "descending" &&
                          sortingBy === column.index && (
                            <MdKeyboardArrowUp size={20} />
                          )}
                      </div>
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {data?.length > 0 ? (
                data.map((row, index) => {
                  if (index > 4) return null;
                  return (
                    <tr key={`${new Date()}-${Math.random()}-row`}>
                      {selectedColumns?.length > 0 &&
                        selectedColumns.map(column => (
                          <td
                            key={`${column.fieldName}-${Math.random()}-column`}
                          >
                            <div>
                              <p>{row[column.index]}</p>
                            </div>
                          </td>
                        ))}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td>
                    <h1>Não foram encontrados dados nesse arquivo</h1>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        ) : (
          <h1>
            Selecione um arquivo que contenha dados validos para visualizar.
          </h1>
        )}
      </Container>
    </Dialog>
  );
}

export default PreviewDatatable;
