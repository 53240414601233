import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import {
  ProductsProvider,
  useProductsContext,
  defaultValues,
} from "context/ProductsContext";

import { useACL } from "context";

import {
  Ncm,
  NcmEx,
  ProductFormMainName,
  DialogProducts,
  Upload,
  ProductFormSelect,
  Cest,
  ProductListPositivoNegativo,
  ProductLisAgrupados,
} from "components/contexts/Products";

import { useProduct, useProductRemove } from "useCases/products";

import { BtnRemove } from "components/contexts/shared";

import { Container, Button } from "components/structure";
import Card from "components/UI/Card";
import Validation from "./validations";

import * as S from "./styles";

export const ProductForm = () => {
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    resolver: Validation,
    defaultValues: defaultValues.formData,
  });

  const [actualCest, setActualCest] = useState();
  const [preview, setPreview] = useState("");
  const [namePosi, setNamePosi] = useState("");
  const [eanPosi, setEanPosi] = useState("");

  const [nameNegative, setNameNegative] = useState("");
  const [eanNegative, setEanNegative] = useState("");
  const { id } = useParams();

  const { data: dataProduct } = useProduct(id);

  const {
    uploadFile,
    setUploadFile,
    isShowDialog,
    formData,
    setFormData,
    onSubmit,
    isLoadingOnSave,
    handleAddlistWhite,
    handleAddlistBlack,
    whiteList,
    setWhiteList,
    blacklist,
    setBlackList,
    handleRemoveWhiteList,
    handleRemoveBlackList,
    allProductAggregate,
    handleAddAgreggate,
    handleRemoveAgreggate,
    setAllProductAggregate,
    currentId,
    setNcmValues,
    setCurrentId,
  } = useProductsContext();

  const { hasRemove } = useACL("product-production");

  useEffect(() => {
    if (dataProduct && dataProduct?.product) {
      reset({
        ...dataProduct?.product,
      });

      setFormData({
        ...dataProduct?.product,
      });

      if (
        dataProduct?.product?.prod_nome &&
        !dataProduct?.product?.name_in &&
        !dataProduct?.product?.marca_in &&
        !dataProduct?.product?.modificador_in &&
        !dataProduct?.product?.valume_in &&
        !dataProduct?.product?.unid_in
      ) {
        setValue(`name_in`, dataProduct?.product?.prod_nome);
      }

      setNcmValues({
        subItem: dataProduct?.product?.ncm,
      });
      if (dataProduct?.product?.image) {
        setPreview(dataProduct?.product?.image);
      }
      setWhiteList([...dataProduct?.product?.whitelist]);
      if (dataProduct?.product?.blacklist) {
        setBlackList([...dataProduct?.product?.blacklist]);
      }
      if (dataProduct?.product?.grouped) {
        setAllProductAggregate([...dataProduct?.product?.grouped]);
      }
    }
  }, [
    dataProduct,
    reset,
    setValue,
    setFormData,
    setWhiteList,
    setBlackList,
    setPreview,
    setNcmValues,
    setAllProductAggregate,
  ]);

  useEffect(() => {
    if ((id && !currentId) || id !== currentId) {
      setCurrentId(id);
    }
  }, [id, currentId, setCurrentId]);

  return (
    <>
      <DialogProducts show={isShowDialog?.show} />
      <Container>
        <S.Section>
          <Card
            title={id ? dataProduct?.product?.prod_nome : `novo produto`}
            headerColor="#256925"
            action={
              id && (
                <BtnRemove
                  isDisabled={!hasRemove}
                  id={id}
                  hook={useProductRemove}
                />
              )
            }
          >
            <S.Form onSubmit={handleSubmit(onSubmit)}>
              <S.TopPart>
                <S.FormLine>
                  <S.CollumForm width="17%" height="230px">
                    <Upload
                      preview={preview}
                      setPreview={setPreview}
                      uploadFile={uploadFile}
                      setUploadFile={setUploadFile}
                    />
                  </S.CollumForm>
                  <S.CollumForm width="83%">
                    <ProductFormMainName
                      productId={id}
                      control={control}
                      errors={errors}
                      getValues={getValues}
                      setValue={setValue}
                    />
                  </S.CollumForm>
                </S.FormLine>
                <S.FormLine margin="10px 0 0 0">
                  <S.CollumForm width="100%">
                    <Ncm getValues={getValues} />
                    <NcmEx values={formData} getValues={getValues} />
                    <Cest
                      values={formData}
                      getValues={getValues}
                      actualCest={actualCest}
                      setActualCest={setActualCest}
                    />
                  </S.CollumForm>
                </S.FormLine>
                <S.FormLine margin="10px 0 0 0">
                  <Card title="Mercadologico" headerColor="#0A4F91">
                    <S.CollumForm width="100%">
                      <ProductFormSelect
                        setValue={setValue}
                        control={control}
                        getValues={getValues}
                      />
                    </S.CollumForm>
                  </Card>
                </S.FormLine>

                <S.FormLine margin="20px 0 0 0">
                  <S.CollumForm width="50%">
                    <ProductListPositivoNegativo
                      color="#adc86a"
                      ean={eanPosi}
                      name={namePosi}
                      setName={setNamePosi}
                      setEan={setEanPosi}
                      title="Positivo"
                      type="positivo"
                      labelBtn="Adicionar ao Positivo"
                      errorTrace="Nenhum produto"
                      handleAdd={handleAddlistWhite}
                      list={whiteList}
                      handleRemove={handleRemoveWhiteList}
                    />
                  </S.CollumForm>
                  <S.CollumForm width="50%">
                    <ProductListPositivoNegativo
                      isShowGenerator
                      color="#FD5962"
                      ean={eanNegative}
                      name={nameNegative}
                      setName={setNameNegative}
                      setEan={setEanNegative}
                      title="Negativo"
                      type="negativo"
                      errorTrace="Nenhum produto"
                      labelBtn="Adicionar ao negativo"
                      handleAdd={handleAddlistBlack}
                      list={blacklist}
                      handleRemove={handleRemoveBlackList}
                    />
                  </S.CollumForm>
                </S.FormLine>

                <S.FormLine margin="20px 0 0 0">
                  <S.CollumForm width="100%">
                    <ProductLisAgrupados
                      color="#256925"
                      isShowSelected
                      ean={eanPosi}
                      name={namePosi}
                      setName={setNamePosi}
                      setEan={setEanPosi}
                      title="Produtos Agregados"
                      type="agrupados"
                      errorTrace="Nehum produto agregado"
                      handleAdd={handleAddAgreggate}
                      list={allProductAggregate}
                      setList={setAllProductAggregate}
                      handleRemove={handleRemoveAgreggate}
                    />
                  </S.CollumForm>
                </S.FormLine>
              </S.TopPart>
              <S.Footer>
                <Button
                  type="button"
                  color="gray"
                  goBack
                  style={{ marginRight: 15 }}
                >
                  VOLTAR
                </Button>
                <Button type="submit" isLoading={isLoadingOnSave}>
                  {id ? "SALVAR" : "CRIAR"}
                </Button>
              </S.Footer>
            </S.Form>
          </Card>
        </S.Section>
      </Container>
    </>
  );
};

export const ProductCreateAndUpdate = () => {
  return (
    <ProductsProvider>
      <ProductForm />
    </ProductsProvider>
  );
};
