import React, { useState, useEffect } from "react";

import templatesApi from "../../../../services/templatesApi";
import { useFlow } from "../../../../context/FlowContext";
import Dialog from "../../../UI/Dialog";
import { Container } from "./styles";

function SelectFile({ handleClose }) {
  const {
    selectedCallToActionElementId,
    getElementsById,
    handleAttachFileToNode,
  } = useFlow();
  const [files, setFiles] = useState([]);

  useEffect(() => {
    const getFile = async () => {
      const token = localStorage.getItem("@mixfiscal:authenticatorToken");
      const [element] = getElementsById(selectedCallToActionElementId);
      const fileType =
        element.data.dataType === "txt" ? "sped" : element.data.dataType;
      const {
        data: { data: responseFiles },
      } = await templatesApi.get(`/files/type/${fileType}`, {
        headers: {
          Authorization: token,
        },
      });
      setFiles(responseFiles);
    };
    getFile();
  }, [getElementsById, selectedCallToActionElementId]);

  return (
    <Dialog
      title="Anexe um arquivo neste bloco"
      isOpen
      handleClose={handleClose}
    >
      <Container>
        {files.length > 0 &&
          files.map(file => (
            <div
              key={file._id}
              onClick={() => {
                handleAttachFileToNode({
                  nodeId: selectedCallToActionElementId,
                  fileId: file._id,
                });
                handleClose();
              }}
              role="button"
            >
              <p>{file.fileName}</p>
            </div>
          ))}
      </Container>
    </Dialog>
  );
}

export default SelectFile;
