import React, { useState } from "react";

import { ProductListingDialog } from "../../ProductList";

export const DialogProductsLists = ({
  selectionModel,
  handleSelected,
  isUnicSelected = true,
  action,
}) => {
  const [model, setModel] = useState();
  return (
    <>
      <ProductListingDialog
        action={action}
        isShowSelectRow={isUnicSelected}
        isShowBtnAdd={false}
        isShowColumns={false}
        isShowDensity={false}
        modelSelect={model}
        setModel={setModel}
        selectionModel={selectionModel}
        handleSelected={handleSelected}
      />
    </>
  );
};
