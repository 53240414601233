import { useMemo } from "react";
import { useFetchAuthenticate } from "hooks";
import { LawStatesCbenef } from "constants/Types/Law.types";

import { getURLWithParams } from "utils/context";

export const useLawsCbenef = params =>
  useFetchAuthenticate(params ? getURLWithParams("/cbenef", params) : null);

export const useLawsAliquotasIcms = params =>
  useFetchAuthenticate(
    params ? getURLWithParams("/laws/aliquotas/icms", params) : null,
  );

export const useLawsFecp = params =>
  useFetchAuthenticate(params ? getURLWithParams("/fecp", params) : null);

export const useLawsNaturezaReceita = params =>
  useFetchAuthenticate(params ? getURLWithParams("/naturezaReceita") : null);

const cbenefFormatTypes = cbenefValues =>
  cbenefValues.map(cBenef => ({
    value: cBenef._id,
    title:
      String(
        `${cBenef.codigo} - ${cBenef.descricao}${
          cBenef.observacao ? ` - ${cBenef.observacao}` : ""
        }`,
      ).length > 50
        ? `${String(
            `${cBenef.codigo} - ${cBenef.descricao}${
              cBenef.observacao ? ` - ${cBenef.observacao}` : ""
            }`,
          ).substring(0, 50)}...`
        : `${cBenef.codigo} - ${cBenef.descricao}${
            cBenef.observacao ? ` - ${cBenef.observacao}` : ""
          }`,
  }));

const naturezaReceitaFormatTypes = values =>
  values?.map(natureza => ({
    value: natureza._id,
    cod_natureza: natureza.cod_natureza,
    label: `
    ${natureza.descricao}
    - 
    ${
      String(natureza.cod_natureza).length > 50
        ? `${String(natureza.cod_natureza).substring(0, 50)}...`
        : natureza.cod_natureza
    }
  `,
  }));

export const useLawsCbenefCase = ({ cenarios = [], cstEntrada, cstSaida }) => {
  const ufs = new Set();
  (cenarios ?? []).forEach(scenery => {
    if (LawStatesCbenef.includes(scenery.ufDestino)) {
      ufs.add(`"${scenery.ufDestino}"`);
    }
    if (LawStatesCbenef.includes(scenery.ufOrigem)) {
      ufs.add(`"${scenery.ufOrigem}"`);
    }
  });

  const { data } = useLawsCbenef(
    ufs.size > 0 ? { ufs: `[${[...ufs].join(",")}]` } : null,
  );

  const cBenefTypes = useMemo(() => {
    if (data?.data) {
      return cbenefFormatTypes(
        data.data.filter(
          cBenef => cBenef[`cst_${cstEntrada}`] || cBenef[`cst_${cstSaida}`],
        ),
      );
    }

    return [];
  }, [data?.data, cstEntrada, cstSaida]);

  return { data, cBenefTypes };
};

export const useLawsAliquotasIcmsCase = () => {
  const { data, isLoading } = useLawsAliquotasIcms({});

  return {
    data,
    isLoading,
  };
};

export const useLawsFecpCase = ({ cenarios = [] }) => {
  const { data, isLoading } = useLawsFecp({});

  const tooltipFecpTexts = useMemo(() => {
    const ufs = new Set();
    const tooltipTexts = [];

    cenarios?.forEach(scenery => {
      if (scenery?.ufDestino) {
        ufs.add(scenery.ufDestino);
      }
      if (scenery?.ufOrigem) {
        ufs.add(scenery.ufOrigem);
      }
    });

    ufs.forEach(uf => {
      const ufFecp = data?.data?.find(f => f.uf === uf);

      if (!ufFecp?.observacao?.includes("não")) {
        tooltipTexts.push(
          `${uf}: ${ufFecp?.aliq_1}${
            ufFecp?.aliq_2 ? ` ou ${ufFecp?.aliq_2}` : ""
          }${ufFecp?.aliq_3 ? ` ou ${ufFecp?.aliq_3}` : ""}`,
        );
      }
    });
  }, [data, cenarios]);

  return {
    data,
    isLoading,
    tooltipFecpTexts,
  };
};

export const useLawsNaturezaReceitaCase = ({ cstSaida }) => {
  const { data, isLoading } = useLawsNaturezaReceita({});

  const naturezaReceitaTypes = useMemo(() => {
    const newNReceitaValues = data?.data?.filter(
      nr => nr.cst === Number(cstSaida?.split("0")?.[1]),
    );

    return naturezaReceitaFormatTypes(newNReceitaValues);
  }, [data, cstSaida]);

  return {
    data,
    isLoading,
    naturezaReceitaTypes,
  };
};
