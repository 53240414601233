import styled from "styled-components";

export const Container = styled.div`
  .button button {
    margin-bottom: 0;
  }
`;

export const InputContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  & + & {
    margin-left: 12px;
  }
  label,
  input,
  select,
  textarea {
    font-size: 14px;
    color: #596778;
  }
  select {
    height: 36px;
    background-color: #fff;
  }
  input,
  select,
  textarea {
    border: 1px solid #596778;
    border-radius: 5px;
    padding: 4px 10px;
  }
  div {
    display: flex;
    align-items: center;
  }
`;

export const Button = styled.button`
  background-color: #5a8dee;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  padding: 8px;
  border-radius: 5px;
  align-self: flex-start;
  margin-right: auto;
`;
