import { useState, useCallback } from "react";
import {
  useAPIAuthenticateToast,
  useAPIAuthenticate,
  useFetchAuthenticate,
} from "hooks";
import { getURLWithParams } from "utils/context";
import { useToast } from "../context/ToastContext";

export const useGetApi = id =>
  useFetchAuthenticate(id ? getURLWithParams(`/apiKey/${id}`) : null);

export const useApikeyForm = item => {
  const { isLoading, onSend } = useAPIAuthenticateToast();
  const { addToast } = useToast();
  const onSave = data => {
    if (!data.name) {
      return addToast({
        title: "Campo em branco",
        type: "error",
        description: `Preencha o campo de nome para conseguir criar!`,
      });
    }
    onSend({
      endpoint: item.id ? `/apiKey/${item.id}` : "apiKey",
      messageSuccess: `CHAVE API ${
        item.id ? "atualizada" : "criada"
      } com sucesso!`,
      messageFail: "Falha ao tentar salvar CHAVE API!",
      data,
      type: item.id ? "PUT" : "POST",
    });
  };

  return {
    onSave,
    isLoading,
  };
};

export const useApiKeyNewKey = item => {
  const api = useAPIAuthenticate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();

  const onSave = useCallback(() => {
    if (!item.id) {
      return addToast({
        title: "Api Key",
        type: "error",
        description: `A primeira Api Key é gerada automaticamente ao criar.`,
      });
    }
    setLoading(true);
    const fetchData = async () => {
      try {
        const {
          data: { apiKeys },
        } = await api.post(`/apiKey/renew/${item.id}`);
        setData(apiKeys?.key);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.log(e.messagge);
      }
    };
    fetchData();
  }, [item, api, addToast]);

  return {
    data,
    onSave,
    isLoading: loading,
  };
};

export const useApiKeyRemove = () => {
  const { isLoading, onSend } = useAPIAuthenticateToast();

  const onRemove = id =>
    onSend({
      endpoint: `/apiKey/remove/${id}`,
      messageSuccess: "Não foi possível deletar a API KEY!",
      messageFail: "API KEY foi deletado com sucesso!",
      type: "DELETE",
    });

  return {
    onRemove,
    isLoading,
  };
};
