import styled from "styled-components";

export const Container = styled.div``;

export const WrapperContainer = styled.div`
  display: flex;
`;

export const Label = styled.label`
  font-size: 1rem;
`;

export const LabelValue = styled(Label)`
  margin-left: 1rem;
  font-weight: bold;
`;
