import React, { useState } from "react";

import { MdRemoveCircleOutline } from "react-icons/md";
import {
  Button,
  DialogConfirm,
  ButtonContainerIcon,
} from "components/structure";

export const BtnRemove = ({
  id,
  hook,
  isDisabled = false,
  module = "Grupo de Acesso",
}) => {
  const { onRemove, isLoading } = hook();
  const [dialogConfirm, setDialogConfirm] = useState(false);

  const handleConfirm = () => {
    onRemove(id);
    setDialogConfirm(false);
  };

  const handleClickConfirm = () => setDialogConfirm(true);

  const handleActionCancel = () => setDialogConfirm(null);

  return (
    <>
      <DialogConfirm
        isOpen={Boolean(dialogConfirm)}
        handleConfirm={handleConfirm}
        handleCancel={handleActionCancel}
        text={`Deseja realmente remover esse ${module} ?`}
      />
      <Button
        type="button"
        isDisabled={isDisabled}
        isLoading={isLoading}
        color="secondary"
        size="x2"
        onClick={handleClickConfirm}
      >
        <ButtonContainerIcon>
          <MdRemoveCircleOutline />
        </ButtonContainerIcon>
        remover
      </Button>
    </>
  );
};
