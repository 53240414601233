import React from "react";
import { Card } from "components/structure";
import { InputContainer } from "components/form";

import { useRegisterLawsGradesContext } from "context/RegisterLawsContext";
import { CestInputs, CestTags } from "components/contexts/cests";

import { useRegisterLaws } from "hooks/useRegisterLaws";

export const LawCest = ({ hasRemoveNcmGradeContext = true, params = {} }) => {
  const { values, setComplexValue, setValues } = useRegisterLaws();
  const { removeCests } = useRegisterLawsGradesContext();

  const handleAdd = ({ especificacao, item }) => {
    const exist = values?.cests?.find(
      cest =>
        cest?.data?.vc_codigo === especificacao?.value ||
        cest?.data?.vc_codigo === item?.value,
    );

    if (exist) {
      return false;
    }

    if (especificacao?.value) {
      return setComplexValue("cests", {
        data: {
          vc_codigo: especificacao?.value,
        },
      });
    }

    if (item?.value) {
      return setComplexValue("cests", {
        data: {
          vc_codigo: item?.value,
        },
      });
    }
  };

  const handleDelete = cest => {
    const newCests = values?.cests?.filter(
      c => c?.data?.vc_codigo !== cest?.data?.vc_codigo,
    );

    if (hasRemoveNcmGradeContext) removeCests([cest]);
    setValues({ ...values, cests: newCests });
  };

  return (
    <Card type="lateral" title="CEST" height="90px">
      <CestInputs handleAdd={handleAdd} params={params} />
      <InputContainer>
        <CestTags cests={values?.cests} handleDelete={handleDelete} />
      </InputContainer>
    </Card>
  );
};
