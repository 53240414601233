import React from "react";
import { Select } from "../../../../form/Select";

import * as S from "./styles";

export const AdvancedSearchSelect = ({
  label,
  isSearchable = false,
  ...rest
}) => {
  return (
    <S.SelectWrapper>
      <S.Label>{label}</S.Label>
      <Select styles={S.customStyles} isSearchable={isSearchable} {...rest} />
    </S.SelectWrapper>
  );
};
