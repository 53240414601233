import React from "react";
import { useWatch } from "react-hook-form";
import { useTheme } from "hooks";
import { Card, Icon, Tag, Tooltip } from "@mix/mixfiscal-designsystem";
import { hasValueNcm } from "utils/context/laws";

import { ButtonAction } from "components/structure";
import { Select } from "components/form";
import { useNcmNivel } from "useCases/ncm";

import * as S from "./CardTableTipi.styles";

const headerColorGray = "#e5e5e5";
const textColorGray = "#304156";

export const CardTableTipi = ({
  control,
  ncms = [],
  handleAdd,
  handleClear,
}) => {
  const { colors } = useTheme();
  const sessao = useWatch({ control, name: "sessao" });
  const capitulo = useWatch({ control, name: "capitulo" });
  const posicao = useWatch({ control, name: "posicao" });
  const subposicao = useWatch({ control, name: "subposicao" });
  const itemNcm = useWatch({ control, name: "item_ncm" });

  const sessionValue = sessao?.value;
  const capituloValue = capitulo?.value;
  const chapterLv3 = posicao?.value;
  const chapterLv4 = subposicao?.value;

  const { dataMap: dataSession } = useNcmNivel("sessao");

  const { dataMap: dataCapitulo } = useNcmNivel(
    sessionValue ? "capitulo" : null,
    sessionValue ? { sessao: sessionValue } : null,
  );

  const { dataMap: dataPosicao } = useNcmNivel(
    sessionValue ? "posicao" : null,
    capituloValue || sessionValue
      ? { ncm: capituloValue || sessionValue }
      : null,
  );

  const { dataMap: dataSubPosicao } = useNcmNivel(
    chapterLv3 || capituloValue ? "sub-posicao" : null,
    chapterLv3 || capituloValue ? { ncm: chapterLv3 || capituloValue } : null,
  );

  const subItemNcm = hasValueNcm([
    chapterLv4,
    chapterLv3,
    capituloValue,
    sessionValue,
  ]);

  const { dataMap: dataItens } = useNcmNivel(
    subItemNcm ? "item" : null,
    subItemNcm ? { ncm: subItemNcm } : null,
  );

  const handleRemoveBtn = () =>
    handleClear({
      ncms: [],
    });

  const handleAddBtn = () => {
    let code = itemNcm?.value;

    if (!code && chapterLv4 && capituloValue && chapterLv3) {
      code = subposicao?.data?.vc_codigo_sem_formatacao;
    }

    if (!code && chapterLv3 && capituloValue && !chapterLv4) {
      code = posicao?.data?.vc_codigo_sem_formatacao;
    }

    if (!code && capituloValue && !chapterLv3 && !chapterLv4) {
      code = capitulo?.data?.vc_codigo_sem_formatacao;
    }

    if (code) {
      handleAdd({
        ncms: [...(ncms || []), ...[{ code }]],
      });
    }
  };

  return (
    <Card
      className="card-custom"
      handleCtaClick={() => {}}
      hasElevation
      headerColor={headerColorGray}
      textColor={textColorGray}
      title="NCM - TABELA TIPI"
    >
      <div className="row">
        <S.Container>
          <Select
            name="sessao"
            data={dataSession}
            label="Sessão"
            control={control}
          />
          <Select
            name="capitulo"
            data={dataCapitulo}
            label="Capitulo"
            control={control}
          />
          <Select
            name="posicao"
            data={dataPosicao}
            label="Posição"
            control={control}
          />
          <Select
            name="subposicao"
            data={dataSubPosicao}
            label="Sub-Posição"
            control={control}
          />
          <S.ContainerPlus>
            <Select
              name="item_ncm"
              label="Item"
              data={dataItens?.map(item => ({
                value: item?.data?.vc_codigo_sem_formatacao,
                label: item?.label,
              }))}
              control={control}
            />
            <S.Action>
              <Tooltip
                body="Adicionar ncm ao filtro"
                position="top"
                zIndex={1000}
              >
                <ButtonAction onClick={handleAddBtn}>
                  <Icon color={colors.lightGreen} name="adicionar" size={32} />
                </ButtonAction>
              </Tooltip>
            </S.Action>
          </S.ContainerPlus>

          <S.RowTagsName>
            {ncms?.map(cestV => (
              <Tag label={cestV?.code} key={cestV?.code} type="simple" />
            ))}
            {ncms?.length > 0 && (
              <S.ActionRemove>
                <Tooltip body="Remover NCMS" position="top" zIndex={1000}>
                  <ButtonAction onClick={handleRemoveBtn}>
                    <Icon color="#000" name="fechar1" size={18} />
                  </ButtonAction>
                </Tooltip>
              </S.ActionRemove>
            )}
          </S.RowTagsName>
        </S.Container>
      </div>
    </Card>
  );
};
