import React from "react";
import { ProductsProvider } from "context/ProductsContext";
import { ProductListCard } from "components/contexts/Products";

export const ProdutosList = () => {
  return (
    <ProductsProvider>
      <ProductListCard />
    </ProductsProvider>
  );
};
