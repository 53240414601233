import React from "react";
import { Card } from "@mix/mixfiscal-designsystem";
import { useGradeFilters } from "hooks/useGrade";
import { useRegisterLawsGradesContext } from "context/RegisterLawsContext";

import { LawNcm as Ncm } from "components/contexts/law-grade/LawNcm";
import { LawCest as Cest } from "components/contexts/law-grade/LawCest";

import { ResultsProductsWithFilter } from "../ResultsProducts/ListProductsWithFilter";
import Dialog from "../../../UI/Dialog";
import { useLawLocaleLoad } from "./LawLocale.hooks";
import * as S from "./AddProductsModal.styles";

export const AddProductsModal = ({
  isOpen = false,
  handleClose,
  handleConfirm,
  refLawId,
}) => {
  useLawLocaleLoad();

  const { values, setProductsClear } = useRegisterLawsGradesContext();

  const params = useGradeFilters({
    values: {
      ncms: values?.ncms,
      cests: values?.cests,
    },
    refLawId,
  });

  const handleCloseLocal = () => {
    setProductsClear();
    handleClose();
  };

  return (
    <Dialog
      isOpen={isOpen}
      title="Adicionar produtos"
      handleClose={handleCloseLocal}
      handleConfirm={handleConfirm}
      fullScreen
    >
      <S.ModalContent>
        <Ncm hasRemoveNcmGradeContext={false} />
        <Cest hasRemoveNcmGradeContext={false} />
        <Card
          headerColor="#E5E5E5"
          textColor="#304156"
          title="Produtos"
          className="flatCard"
        >
          <ResultsProductsWithFilter
            params={params}
            typeSelectionProduct="add"
          />
        </Card>
      </S.ModalContent>
    </Dialog>
  );
};
