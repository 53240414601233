import styled from "styled-components";

export const Header = styled.div`
  width: 100%;
  text-align: center;
`;

export const HeaderText = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Container = styled.div`
  height: 76vh;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const SpaceWrapper = styled.div``;
