import styled from "styled-components";

export const ManDiv = styled.div`
  padding: 1em 0 1em 0;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .menu {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  > header {
    display: flex;
    align-items: center;
    margin-left: 8px;
  }
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  padding: 10px;
  display: flex;
  gap: 24px;
  .column1 {
    display: flex;
    flex: 0.2;
    flex-direction: column;
    gap: 24px;
    .div-overflow {
      height: 225px;
      overflow: hidden;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    & > div {
      display: flex;
      flex-direction: column;
      p.title {
        color: #17456b;
        font-size: 15px;
      }
      table {
        border-collapse: collapse;
        margin: 15px 0;
        width: 100%;
      }
      th > div {
        padding: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        p {
          font-size: 13px;
          font-weight: bold;
          color: #565656;
        }
      }
      tr {
        border: 1px solid #bababa;
        transition: all 0.1s ease-in-out;
        cursor: pointer;
        &:hover {
          background-color: #f2f4f4;
        }
      }
      td {
        padding: 4px 8px;
        font-size: 13px;
        color: #565656;
        &.action {
          text-align: center;
        }
      }
      thead {
        tr {
          cursor: normal;
          background-color: transparent;
        }
      }
    }
  }

  .column2 {
    display: flex;
    flex: 0.8;
    font-size: 14px;
    .row {
      display: flex;
      div[type="complex"] {
        margin-left: 10px;
      }
      .button {
        display: flex;
        flex-direction: column-reverse;
        flex: 0;
        button {
          height: 40px;
          width: 40px;
          margin-left: 10px;
          margin-bottom: 10px;
          border-radius: 50%;
          background-color: #adc86a;
        }
      }
      .icon {
        display: flex;
        flex-direction: column-reverse;
        flex: 0;
        button {
          height: 40px;
          width: 40px;
          background-color: #fff;
          margin-left: 5px;
          margin-bottom: 10px;
        }
      }
    }
    .radio-box {
      border: 1px solid #bababa;
      padding: 6px;
      border-radius: 5px;
    }
    .radio {
      display: flex;
      align-items: center;
      input {
        height: 20px;
        width: 20px;
      }
      span {
        margin-left: 8px;
        margin-right: 8px;
      }
    }
    .switch {
      display: flex;
      align-items: center;
      color: #596778;
      margin-right: 90px;
      span {
        font-size: 15px;
      }
    }
    .newCheckLink {
      display: flex;
      flex: 1;
      padding: 0;
      .data {
        flex: 0.7;
        display: flex;
        flex-direction: column;
        label {
          margin-left: 10px;
        }
      }
      .preview {
        flex: 0.3;
        margin-left: 30px;
        display: flex;
        flex-direction: column;
      }
    }
    p.subtitle {
      color: #596778;
      font-size: 12px;
    }
    .flatCard {
      margin-bottom: 20px;
      header {
        border-radius: 0;
        padding: 4px 10px;
        p {
          font-size: 12px;
        }
      }
      section {
        background-color: #f2f4f4;
        border-radius: 0;
      }
    }
  }
`;

export const InputContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  & + & {
    margin-left: 12px;
  }
  label,
  input,
  select,
  textarea {
    font-size: 14px;
    color: #596778;
  }
  select {
    height: 36px;
    background-color: #fff;
  }
  input,
  select,
  textarea {
    border: 1px solid #596778;
    border-radius: 5px;
    padding: 4px 10px;
  }
  div {
    display: flex;
    align-items: center;
  }
`;

export const Button = styled.button`
  background-color: #5a8dee;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  padding: 8px;
  border-radius: 5px;
  align-self: flex-start;
  margin-right: auto;
  margin-top: 15px;
  width: 100%;
`;

export const ButtonLink = styled.button`
  color: #5a8dee;
  background: transparent;
`;
