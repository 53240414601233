import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0px;
  min-height: 0px;

  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 8px #0000002e;
  width: 100%;
`;

export const Header = styled.div`
  width: 100%;
  padding: 8px 16px;
  background-color: ${props => props.color};
  display: flex;
  min-width: 0px;
  min-height: 0px;
  flex-shrink: 0;
  justify-content: space-between;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  min-height: 0px;
  padding: 16px;

  display: flex;
  flex-direction: column;
`;

export const HeaderContent = styled.div`
  display: flex;
  align-items: center;

  p {
    color: #fff;
    font-size: 15px;
    text-transform: uppercase;
  }

  svg {
    fill: #fff;
    margin-right: 8px;
  }
`;

export const HeaderAction = styled.div``;
