import styled, { css } from "styled-components";

export const Container = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-gap: ${theme.spacing.md};

    button {
      min-width: auto !important;
    }

    section {
      .funnelContainer {
        z-index: 1;
      }
      section {
        webkit-transform: unset;
        transform: unset;
      }

      > div input {
        overflow: hidden;
      }
    }
  `}
`;

export const ContainerFilter = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: ${theme.spacing.sm};
  `}
`;

export const HeadingNewTemplates = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const headingTitleModifiers = {
  disableMargin: theme => css`
    margin-left: ${theme.spacing.sm};
  `,
};

export const HeadingTitle = styled.div`
  ${({ theme, disableMargin }) => css`
    color: ${theme.colors.gray};

    ${!disableMargin && headingTitleModifiers.disableMargin(theme)}
  `}
`;

export const Actions = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

export const WrapperTextField = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  box-sizing: border-box;
  transition: all 0.2s ease 0s;
  padding: 4px 8px;
  border-radius: 5px;
  border: 1px solid rgb(89, 103, 120);
  background-color: rgb(255, 255, 255);
  font-size: 14px;
  color: rgb(89, 103, 120);
  min-width: 240px;
`;

export const TextField = styled.input`
  width: 100%;
`;

export const Select = styled.div`
  ${({ theme }) => css`
    min-width: 200px;
    margin-left: ${theme.spacing.sm};

    & > div {
      width: 100%;
    }
  `}
`;

export const Cards = styled.div`
  ${({ theme }) => css`
    display: flex;
    grid-gap: ${theme.spacing.md};
    flex-wrap: wrap;
  `}
`;
