import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  RegisterLawsContextProvider,
  RegisterLawsGradesContextProvider,
} from "context/RegisterLawsContext";

import { useRegisterLaws } from "hooks/useRegisterLaws";
import { useLaw } from "useCases/laws";
import {
  cestsBuilderToLinkmix,
  ncmBuilderToLinkmix,
  attributesBuilderToScreen,
} from "utils/context/laws";

import LawsContent from "./Laws";

const LawsWrapper = () => {
  const params = useParams();
  const { setValues } = useRegisterLaws();
  const { data, onSave, isLoading } = useLaw(
    params.id !== "new" ? params?.id : null,
  );

  useEffect(() => {
    if (data?.law) {
      const { ncms, cests, ...law } = data.law;
      return setValues({
        ...law,
        ...attributesBuilderToScreen(law?.atributos ?? {}),
        ncms: ncmBuilderToLinkmix(ncms),
        cests: cestsBuilderToLinkmix(cests),
      });
    }

    return setValues({
      isNew: params?.id === "new",
    });
  }, [data?.law, setValues, params?.id]);

  return (
    <LawsContent
      onSave={onSave}
      isLoading={isLoading}
      isNew={params?.id === "new"}
    />
  );
};

const Laws = () => (
  <RegisterLawsGradesContextProvider>
    <RegisterLawsContextProvider>
      <LawsWrapper />
    </RegisterLawsContextProvider>
  </RegisterLawsGradesContextProvider>
);

export default Laws;
