import styled from "styled-components";

export const Title = styled.p`
  display: flex;
  flex-wrap: nowrap;
  border-bottom: 0;
  padding: 0px 8px 0px 8px !important;
  h5 {
    font-size: 12px;
    color: #000;
  }
`;

export const TitleText = styled.div`
  flex: 75%;
  line-height: 27px;
`;

export const ContainerTooltip = styled.div`
  align-items: center;
  display: block;
  padding: 0px 8px 6px 8px;
  h4 {
    padding-top: 3px;
    color: #949494;
  }
`;

export const DaysCertified = styled.div`
  opacity: 1;
  width: 65px;
  height: 65px;
  margin: 0 auto;
  text-align: center;
  border-radius: 100%;
  background-color: ${({ active }) => (active ? "#adc86a" : "#c86a6a")};
  vertical-align: middle;
  line-height: 17px;
  padding-top: 16px;
  margin-bottom: 10px;
  box-shadow: 0px 3px 6px #00000029;
  h5 {
    font-size: 22px;
    font-weight: bold;
    color: #fff !important;
  }
  h6 {
    font-size: 13px;
    font-weight: bold;
    color: #fff !important;
  }
`;
