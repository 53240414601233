import React from "react";
import Dialog from "components/UI/Dialog";

import { useProductsContext } from "context/ProductsContext";
import { DialogProductsLists } from "./DialogProductsLists";

import * as S from "./DialogProducts.styles";

export const DialogProducts = ({ show }) => {
  const {
    isShowDialog,
    setIsShowDialog,
    listExistentEquals,
    listExistentEqualsSelecteds,
    handleAddListExistentWhite,
    handleSelectedListExistentEquals,
    handleAddListExistentAgrupados,
    handleUpdateItemListNegative,
    handleRemoveFilterAll,
    setListExistentEqualsSelecteds,
  } = useProductsContext();

  const handleSubmitFormList = () => {
    if (isShowDialog.type === "positivo") {
      return handleAddListExistentWhite(
        listExistentEqualsSelecteds.map(item => ({
          prod_nome: item?.prod_nome,
          prod_codigo_barras: item?.prod_codigo_barras,
        })),
      );
    }
    if (isShowDialog.type === "negativo") {
      return handleUpdateItemListNegative(
        isShowDialog?.key,
        listExistentEqualsSelecteds,
      );
    }
    if (isShowDialog.type === "agrupados") {
      handleAddListExistentAgrupados(
        listExistentEqualsSelecteds.map(item => ({
          _id: item?._id,
          prod_nome: item?.prod_nome,
          prod_codigo_barras: item?.prod_codigo_barras,
        })),
      );
    }
  };

  const handleClose = () => {
    handleRemoveFilterAll();
    setIsShowDialog({
      show: false,
    });
  };

  const Actions = () => {
    if (
      listExistentEqualsSelecteds &&
      listExistentEqualsSelecteds?.length > 0
    ) {
      return (
        <>
          <S.ButtonActions
            color="red"
            textColor="#fff"
            onClick={() => {
              handleClose();
              setListExistentEqualsSelecteds([]);
            }}
            type="button"
          >
            CANCELAR
          </S.ButtonActions>
          <S.ButtonActions
            type="button"
            textColor="#fff"
            color="#5A8DEE"
            onClick={() => {
              handleSubmitFormList();
              setIsShowDialog(false);
            }}
          >
            {isShowDialog?.labelBtn}
          </S.ButtonActions>
        </>
      );
    }

    return (
      <S.ButtonActions
        color="red"
        textColor="#fff"
        onClick={() => {
          handleClose();
          setListExistentEqualsSelecteds([]);
        }}
        type="button"
      >
        CANCELAR
      </S.ButtonActions>
    );
  };
  return (
    <Dialog
      fullScreen
      isOpen={show}
      title="Lista de produtos"
      zIndex="1250"
      handleClose={() => {
        handleRemoveFilterAll();
        setIsShowDialog({
          show: false,
        });
        setListExistentEqualsSelecteds([]);
      }}
    >
      <S.DialogContainer>
        <DialogProductsLists
          action={<Actions />}
          isUnicSelected={isShowDialog?.isUnicSelected}
          selectionModel={listExistentEqualsSelecteds}
          handleSelected={handleSelectedListExistentEquals}
          list={listExistentEquals}
        />
      </S.DialogContainer>
    </Dialog>
  );
};
