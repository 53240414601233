import React from "react";
import { Tag } from "@mix/mixfiscal-designsystem";

import * as S from "./CestTags.style";

export const CestTags = ({ cests = [], handleDelete }) => (
  <S.CestTags>
    {cests?.map(cest => (
      <Tag
        key={cest?.data?.vc_codigo}
        type="advanced"
        label={cest?.data?.vc_codigo}
        handleDelete={() => handleDelete(cest)}
      />
    ))}
  </S.CestTags>
);
