import styled from "styled-components";

import * as LoadStyles from "components/structure/Load/Load.styles";

export const Container = styled.div`
  display: flex;
  /* grid-template-columns: 30% 70%; */
  padding: 10px;

  .label-checkbox-tributary {
    display: flex;
    align-items: center;

    gap: 10px;
    padding: 5px 0;

    > div {
      width: 25px;
      min-width: 25px;
    }
  }

  .card-custom {
    margin: 0 0px 10px 0px;
    padding-bottom: 5px;
  }

  span {
    font-size: 12px;
  }

  h1 {
    font-weight: bold;
  }

  .row-eleito-trib {
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 5px 0;

    .label-checkbox {
      display: flex;
      gap: 8px;
      align-items: center;

      div {
        border: 2px solid #8c8b8b;
      }
    }
  }

  .row-legislation {
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-around;

    border: 2px solid gray;
    border-radius: 5px;
  }

  .row {
    width: 100%;

    display: flex;
    flex-direction: row;

    > div {
      width: 100%;
      padding: 2px;
    }

    .form-group-datepicker label {
      padding-left: 8px;
    }
  }

  .aliquota-icms {
    display: flex;
    flex-direction: column;

    h5 {
      padding-left: 10px;
      font-weight: bold;
      color: #304156;
    }
  }
`;

export const Header = styled.div`
  width: auto;
  display: flex;
  align-items: center;
`;

export const ContainerResult = styled.div`
  width: 100%;
  margin-left: 10px;
`;

export const ContainerHeader = styled.div``;

export const ContainerHeaderAction = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  & .switch-product {
    width: max-content;
    padding-bottom: 5px;
  }
`;

export const ContainerRow = styled.div`
  margin-bottom: 1rem;
`;

export const Content = styled.div`
  padding: 0px 10px 10px 10px;

  strong {
    font-weight: 700;
  }

  form {
    display: flex;
    width: 100%;
  }

  .flex {
    display: flex;
  }

  .flex-column {
    display: flex;
    flex-direction: column;
  }

  .flex-tax {
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #596778;
    max-width: 250px;
    width: 250px;
    border-radius: 4px;
    padding: 8px 12px;

    li {
      display: flex;
      align-items: center;
    }

    input {
      width: 20px;
      height: 20px;
      margin-right: 12px;
    }

    label {
      font-size: 14px;
      color: #596778;
    }
  }

  .flatCard {
    margin: 20px 0;

    > header {
      border-radius: 0;
      padding: 4px 10px;
      p {
        font-size: 12px;
      }
    }

    section {
      border-radius: 0;
    }

    hr {
      margin-top: 20px;
    }
  }

  .card-subtitle {
    font-size: 18px;
    line-height: 22px;
    color: #596778;
  }

  .input-container {
    display: flex;
    flex-flow: row wrap;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
  }

  .scenery-container {
    display: flex;

    .tax-tag {
      margin: 8px 8px 0 0;
    }
  }

  .max-width-400 {
    gap: 14px;
    max-width: 400px;
  }

  .tag-margin {
    margin-top: 37px;
  }

  .checkbox-container {
    display: flex;
    flex: 0.6;
    align-items: center;
    margin: 35px 10px 0 10px;

    label {
      margin-left: 8px;
    }
  }

  .cnae-container {
    label {
      margin-left: 10px;
      font-size: 14px;
      color: #596778;
    }
  }

  .tag-container {
    display: flex;
    flex-flow: row wrap;

    .tag-item {
      margin: 8px 0 0 4px;
    }
  }

  .submit {
  }
`;

export const FooterActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${LoadStyles.ContainerPlaceholder} {
    justify-content: center;
    display: flex;
  }
`;
