import React from "react";
import { Controller } from "react-hook-form";
import ContentEditable from "react-contenteditable";

import * as S from "./styles";

export const TextAreaAdvanced = ({
  name,
  control,
  label,
  helperText,
  error,
  isDisabled,
  ...props
}) => {
  if (control) {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <S.InputFieldContainer>
            {label && (
              <S.InputFieldLabel error={value && error}>
                {label}
              </S.InputFieldLabel>
            )}
            <S.InputField>
              <ContentEditable
                {...props}
                html={value}
                disabled={isDisabled}
                onChange={event =>
                  onChange({ target: { name, value: event.target.value } })
                }
              />
            </S.InputField>
            <S.InputFieldHelperText error={value && error}>
              {helperText}
            </S.InputFieldHelperText>
          </S.InputFieldContainer>
        )}
      />
    );
  }

  return (
    <S.InputFieldContainer>
      <S.InputFieldLabel>{label}</S.InputFieldLabel>
      <S.InputField {...props} />
    </S.InputFieldContainer>
  );
};
