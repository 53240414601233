import styled, { css } from "styled-components";

const columnFormItemModifiers = {
  setBackground: item => css`
    background: ${item} !important;
  `,

  setTextColor: item => css`
    color: ${item} !important;
    svg {
      color: ${item};
    }
  `,
};

export const DialogContainer = styled.div`
  margin: 0px 0 100px 0;
`;

export const DialogFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 40px;

  & button {
    margin: 0 5px 0 5px;
  }
`;

export const DialogHeaderAction = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 10px 0 10px 0;

  & button {
    margin: 0 5px 0 5px;
  }
`;

export const ButtonActions = styled.button`
  ${({ color, textColor }) => css`
    border-radius: 5px;
    padding: 5px 10px 5px 10px;
    margin: 0 0 0 10px;
    width: -moz-fit-content;
    width: fit-content;
    ${color && columnFormItemModifiers.setBackground(color)}
    ${textColor && columnFormItemModifiers.setTextColor(textColor)}
  `}
`;
