import React from "react";
import { Tooltip } from "@mix/mixfiscal-designsystem";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Pagination } from "components/structure";

import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import * as S from "./Assessor.styles";

export const AssessorList = ({
  data,
  isAdd,
  isDelete,
  handleAdd,
  handleRemove,
  totalDocs,
  page,
  setPage,
}) => {
  return (
    <>
      <S.AssessorUl>
        {(data || []).map((user, index) => {
          const emails =
            user?.email?.split(",") || user?.id_user?.email.split(",");

          return (
            <>
              <S.AssessorLi>
                {isAdd && (
                  <Tooltip
                    body="Clique aqui para adicionar assessor"
                    position="top"
                    zIndex={2}
                    zIndexBackdrop={1}
                  >
                    <S.AssessorLiItem
                      onClick={() => {
                        handleAdd(user);
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faPlusCircle}
                        size="lg"
                        style={{
                          fontSize: "20px",
                        }}
                        color="#256925"
                      />
                    </S.AssessorLiItem>
                  </Tooltip>
                )}

                {isDelete && (
                  <Tooltip
                    body="Clique aqui para remover assessor"
                    position="top"
                    zIndex={2}
                    zIndexBackdrop={1}
                  >
                    <S.AssessorLiItem
                      onClick={() => {
                        handleRemove(user?._id, index);
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faMinusCircle}
                        size="lg"
                        style={{
                          fontSize: "20px",
                        }}
                        color="#da0f0f"
                      />
                    </S.AssessorLiItem>
                  </Tooltip>
                )}

                <S.AssessorLiItem>
                  {user?.name || user?.id_user?.name}
                </S.AssessorLiItem>
                {(emails || []).splice(0, 1).map(item => {
                  return (
                    <S.AssessorLiItem>
                      {item}
                      {/* {user?.email || user?.id_user?.email} */}
                    </S.AssessorLiItem>
                  );
                })}
              </S.AssessorLi>
            </>
          );
        })}
        {totalDocs && (
          <Pagination
            count={totalDocs}
            page={page - 1}
            rowsPerPage={10}
            onPageChange={(e, value) => setPage(value + 1)}
          />
        )}
      </S.AssessorUl>
    </>
  );
};
