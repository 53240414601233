import React, { useEffect, useState } from "react";
import { useRegisterLaws } from "hooks/useRegisterLaws";
import { LoadPlaceholder } from "components/structure";

import authenticationApi from "services/authenticationApi";

import {
  calcCarga,
  hasAliq,
  hasCstEntradaTributado,
  hasCstEntradaNaoTributado,
} from "utils/context/laws";

import { InputContainer } from "pages/Laws/styles";
import Mocks from "pages/Laws/mocks";

const mocks = new Mocks();
const cst = mocks.getCst();

export const AttributesContainerEntrada = ({
  alq = 0,
  hasConflict = false,
}) => {
  const { values, setSimpleValue, setValues } = useRegisterLaws();
  const [nReceitaValues, setNReceitaValues] = useState([]);
  const [cbenefValues, setCbenefValues] = useState([]);

  useEffect(() => {
    async function getFecp() {
      try {
        const res = await authenticationApi.get("/naturezaReceita");

        setNReceitaValues(res.data.data);
      } catch (e) {
        // chamar toast
      }
    }

    getFecp();
  }, []);

  const hasEnableAliq = () =>
    !values.taxa?.icms ||
    !(values.taxa?.icms && !hasCstEntradaNaoTributado(values.cstEntrada));

  const hasPisCofins = values?.taxa?.pis || values?.taxa?.cofins;

  useEffect(() => {
    async function getcBenef() {
      try {
        const ufscBenef = ["PR", "RJ", "RS", "DF"];
        const ufs = new Set();
        values.cenarios?.forEach(scenery => {
          if (ufscBenef.includes(scenery.ufDestino)) {
            ufs.add(`"${scenery.ufDestino}"`);
          }
          if (ufscBenef.includes(scenery.ufOrigem)) {
            ufs.add(`"${scenery.ufOrigem}"`);
          }
        });

        if (ufs.size > 0) {
          const res = await authenticationApi.get(
            `/cbenef?ufs=[${[...ufs].join(",")}]`,
          );

          const cBenefValues = res.data.data.filter(cBenef => {
            if (
              cBenef[`cst_${values.cstEntrada}`] ||
              cBenef[`cst_${values.cstSaida}`]
            ) {
              return true;
            }
            return false;
          });

          setCbenefValues(cBenefValues);
        } else {
          setCbenefValues([]);
        }
      } catch (e) {
        // chamar toast
      }
    }

    if (values.taxa?.icms) {
      getcBenef();
    }
  }, [values.taxa, values.cenarios, values.cstEntrada, values.cstSaida]);

  function getOptionsCst(tax, type) {
    if (tax === "pis")
      return cst.pis[type].map(c => (
        <option key={c.value} value={c.value} title={`${c.value} - ${c.label}`}>
          {`${c.value} - ${c.label}`.length > 50
            ? `${`${c.value} - ${c.label}`.substring(0, 50)}...`
            : `${c.value} - ${c.label}`}
        </option>
      ));
    return cst.icms[type].map(c => (
      <option key={c.value} value={c.value} title={`${c.value} - ${c.label}`}>
        {`${c.value} - ${c.label}`.length > 50
          ? `${`${c.value} - ${c.label}`.substring(0, 50)}...`
          : `${c.value} - ${c.label}`}
      </option>
    ));
  }

  function getOptionsNaturezaReceita() {
    const newNReceitaValues = nReceitaValues.filter(
      nr => nr.cst === Number(values.cstSaida.split("0")?.[1]),
    );

    return newNReceitaValues.map(nr => (
      <option key={nr._id} value={nr._id} title={nr.cod_natureza}>
        {nr.descricao}
        {" - "}
        {String(nr.cod_natureza).length > 50
          ? `${String(nr.cod_natureza).substring(0, 50)}...`
          : nr.cod_natureza}
      </option>
    ));
  }

  // FIXME: Fecp - esta aguardando
  function getOptionsCbenef() {
    return cbenefValues.map(cBenef => (
      <option
        key={cBenef._id}
        value={cBenef._id}
        title={`${cBenef.codigo} - ${cBenef.descricao}${
          cBenef.observacao ? ` - ${cBenef.observacao}` : ""
        }`}
      >
        {String(
          `${cBenef.codigo} - ${cBenef.descricao}${
            cBenef.observacao ? ` - ${cBenef.observacao}` : ""
          }`,
        ).length > 50
          ? `${String(
              `${cBenef.codigo} - ${cBenef.descricao}${
                cBenef.observacao ? ` - ${cBenef.observacao}` : ""
              }`,
            ).substring(0, 50)}...`
          : `${cBenef.codigo} - ${cBenef.descricao}${
              cBenef.observacao ? ` - ${cBenef.observacao}` : ""
            }`}
      </option>
    ));
  }

  function sceneryExists(type) {
    let exists = false;
    if (type === "entrada") {
      const idx = values.cenarios?.findIndex(s => s.ufOrigem);

      if (idx !== -1) {
        exists = true;
      }
    } else {
      const idx = values.cenarios?.findIndex(s => s.ufDestino);

      if (idx !== -1) {
        exists = true;
      }
    }

    return exists;
  }

  function handleChangeCSTEntrada(e) {
    if (!values.taxa?.icms && !["01", "02", "03"].includes(e.target.value)) {
      setValues({ ...values, aliqEntrada: 0, cstEntrada: e.target.value });
    } else if (
      values.taxa?.icms &&
      ![
        "00",
        "10",
        "20",
        "70",
        "90",
        "101",
        "103",
        "201",
        "202",
        "203",
        "300",
        "400",
      ].includes(e.target.value)
    ) {
      setValues({ ...values, aliqEntrada: 0, cstEntrada: e.target.value });
    } else {
      setSimpleValue("cstEntrada", e.target.value);
    }
  }

  return (
    <LoadPlaceholder
      showNoData={(!hasPisCofins && !sceneryExists("entrada")) || hasConflict}
      textNoData={
        hasConflict ? "Grade com conflito!" : "Grade não tem opção de entrada!"
      }
      styleBackDrop={{ backdropFilter: "blur(1px)" }}
      fontWeight="bold"
    >
      <div className="input-container">
        {cbenefValues.length > 0 && (
          <>
            <InputContainer maxWidth="220px">
              <label>cBenef</label>
              <select>
                <option
                  value="Selecione"
                  onChange={e => setSimpleValue("cBenef", e.target.value)}
                >
                  Selecione
                </option>
                {getOptionsCbenef()}
              </select>
            </InputContainer>
            <InputContainer maxWidth="220px">
              <label>Alíquota cBenef</label>
              <input
                type="text"
                defaultValue={values.alqcBenef}
                onChange={e => setSimpleValue("aliqcBenef", e.target.value)}
              />
            </InputContainer>
          </>
        )}
        {(values.taxa?.pis || values.taxa?.cofins) &&
          ["04", "05", "06", "07", "08", "09"].includes(values.cstSaida) && (
            <InputContainer maxWidth="220px">
              <label>Natureza da Receita</label>
              <select>
                <option
                  value="Selecione"
                  onChange={e =>
                    setSimpleValue("naturezaReceita", e.target.value)
                  }
                >
                  Selecione
                </option>
                {getOptionsNaturezaReceita()}
              </select>
            </InputContainer>
          )}
      </div>
      <div className="input-container">
        {(sceneryExists("entrada") || hasPisCofins) && (
          <InputContainer maxWidth="220px">
            <label>CST Entrada</label>
            <select
              value={values?.cstEntrada}
              onChange={handleChangeCSTEntrada}
            >
              <option value="Selecione">Selecione</option>
              {values.taxa?.icms
                ? getOptionsCst("icms", "entrada")
                : getOptionsCst("pis", "entrada")}
            </select>
          </InputContainer>
        )}
        {((sceneryExists("entrada") && hasAliq(values?.cstEntrada)) ||
          hasPisCofins) && (
          <InputContainer maxWidth="220px">
            <label>{`Alíquota Entrada  ${hasPisCofins ? "Pis" : ""}`}</label>
            <input
              type="text"
              onChange={e => setSimpleValue("aliqEntrada", e.target.value)}
              value={alq || values.aliqEntrada || ""}
              disabled={hasEnableAliq()}
            />
          </InputContainer>
        )}
        {hasPisCofins && (
          <InputContainer maxWidth="220px">
            <label>Alíquota Entrada Cofins</label>
            <input
              type="text"
              onChange={e =>
                setSimpleValue("aliqEntradaCofins", e.target.value)
              }
              value={alq || values.aliqEntradaCofins || ""}
              disabled={hasEnableAliq()}
            />
          </InputContainer>
        )}

        {values?.taxa?.icms && hasCstEntradaTributado(values.cstEntrada) && (
          <>
            <InputContainer maxWidth="220px">
              <label>Redução de Base Cálculo</label>
              <input
                type="text"
                value={values?.reducaoBaseCalc}
                onChange={e =>
                  setSimpleValue("reducaoBaseCalc", e.target.value)
                }
              />
            </InputContainer>
            <InputContainer maxWidth="100px">
              <label>Carga</label>
              <input
                disabled
                type="text"
                value={calcCarga(values?.aliqEntrada, values?.reducaoBaseCalc)}
              />
            </InputContainer>
          </>
        )}
      </div>
    </LoadPlaceholder>
  );
};
