import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import { Icon } from "@mix/mixfiscal-designsystem";
import { CurrencyField, TextField } from "components/form";
import { cnpjGetNumbers, formatCnpj } from "utils";
import LoadingIcon from "components/UI/LoadingIcon";
import { UseGetCnpjData, UseStoresSearch, useStore } from "useCases";
import { LoadCircle } from "components/structure";
import { InputSearch } from "components/contexts/stores/SelectSearch/Components";

import { CepForm } from "components/contexts/cep";
import { OptionsComponent } from "./OptionCustom";

import * as S from "../styles";

const CustomOption = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      <OptionsComponent data={props} />
    </components.Option>
  );
};

const companytype = [
  { label: "MATRIZ", value: "Matriz" },
  { label: "FILIAL", value: "Filial" },
];
const regime = [
  { label: "LUCRO REAL", value: "LR" },
  { label: "LUCRO PRESUMIDO", value: "LP" },
  { label: "SIMPLES NACIONAL", value: "SN" },
  { label: "PESSOA FÍSICA", value: "PF" },
];

export const StoreForm = ({
  cnpjValue,
  loading,
  reset,
  setData,
  control,
  setCnpjValue,
  getValues,
  selectedCompany,
  setSelectedCompany,
  setSearchCnpjReceita,
  setValue,
  handleChangeCnpj,
  watch,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [debouncedTerm, setDebouncedTerm] = useState(searchValue);

  const id = getValues("companyId");
  const taxRegime = watch("taxRegime");
  const type = watch("type");
  const receitaBruta = watch("receitaBruta");

  const hasShowReceita = taxRegime === "SN";

  const { isLoading: dataStoreLoading, data: dataStore } = useStore(id);

  const {
    isLoading: isLoadingSearch,
    data: companies,
    fetch: searching,
  } = UseStoresSearch();

  const { isLoading, data: dataCnpj, fetch: fetchCnpj } = UseGetCnpjData({});

  const handleGetCnpjData = cnpj => {
    const rawCnpj = cnpjGetNumbers(cnpj);
    fetchCnpj({ cnpj: rawCnpj });
  };

  const cnpj = getValues("cnpj");

  useEffect(() => {
    if (!isLoading && dataCnpj) {
      reset(dataCnpj);
      setSearchCnpjReceita(true);
    }
  }, [dataCnpj, setData, reset, setSearchCnpjReceita, isLoading]);

  const messageBlank =
    ({ isLoadingItem, dataLength, searchLength, valueDefault }) =>
    () => {
      if (isLoadingItem) {
        return <LoadCircle width="25px" height="25px" />;
      }

      if (!isLoadingItem && searchLength === 0 && !dataLength) {
        return "Digite o Razão Social ou CNPJ";
      }

      if (valueDefault) {
        return valueDefault.companyName;
      }

      return "Não foi possivel encontrar dados com os parametros digitados";
    };

  const onInputChange = value => {
    setDebouncedTerm(value);
  };

  const filterOptions = () => true;

  useEffect(() => {
    const timer = setTimeout(() => {
      setSearchValue(debouncedTerm);
    }, 1000);
    return () => clearTimeout(timer);
  }, [debouncedTerm]);

  useEffect(() => {
    if (searchValue !== "") {
      searching({
        type: "MATRIZ",
        search: searchValue,
      });
    }
  }, [searchValue, searching]);

  useEffect(() => {
    if (
      dataStore &&
      dataStore?.stores !== null &&
      !searchValue &&
      !dataStoreLoading
    ) {
      setSelectedCompany(dataStore?.stores);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataStore, setSelectedCompany]);

  return (
    <>
      <S.ColumnFormItem>
        <TextField
          label="CNPJ"
          placeholder="00.000.000/0000-00"
          name="cnpj"
          maxLength="18"
          control={control}
          onChange={e => {
            const { value } = e.target;
            handleChangeCnpj(value);
            setCnpjValue(value);
            setValue(`cnpj`, formatCnpj(value));
          }}
        />
        <S.CollumFormSearch>
          {loading ? (
            <LoadingIcon
              isLoading
              width="10%"
              height="0"
              color="#0e7c34"
              size={20}
              style={{
                marginTop: "10px",
              }}
            />
          ) : (
            <Icon
              name="busca"
              color="#596778"
              className="icon"
              handleClick={() => handleGetCnpjData(cnpjValue)}
            />
          )}
        </S.CollumFormSearch>
      </S.ColumnFormItem>
      <S.ColumnFormItem>
        <TextField
          label="Nome Empresarial"
          placeholder="Nome Empresarial"
          name="companyName"
          control={control}
        />
      </S.ColumnFormItem>
      <S.ColumnFormItem>
        <TextField
          label="Fantasia"
          placeholder="Fantasia"
          name="tradeName"
          control={control}
        />
      </S.ColumnFormItem>
      <S.ColumnFormItem>
        <TextField
          label="Atividade Principal"
          name="mainActivity"
          control={control}
        />
      </S.ColumnFormItem>
      <S.ColumnFormItem>
        <TextField
          label="Atividade Secundárias"
          name="secondaryActivity"
          control={control}
        />
      </S.ColumnFormItem>
      <S.ColumnFormItem>
        <TextField
          label="Nome do Sócio 1 / Qualificação"
          name="namePartnerOne"
          control={control}
        />
        <TextField
          label="Nome do Sócio 2 / Qualificação"
          name="namePartnerTwo"
          control={control}
        />
      </S.ColumnFormItem>
      <S.ColumnFormItem>
        <TextField
          label="Natureza Jurídica"
          name="legalNature"
          control={control}
        />
      </S.ColumnFormItem>
      <S.ColumnFormItem>
        <TextField label="Data Abertura" name="openDate" control={control} />
        <TextField
          label="Ultima Atualização"
          name="lastUpdate"
          control={control}
        />
      </S.ColumnFormItem>
      <S.ColumnFormItem>
        <h3>Endereço</h3>
      </S.ColumnFormItem>
      <CepForm control={control} setValue={setValue} />
      <S.ColumnFormItem setMargin="10px 0 0 0">
        <h3>Contato</h3>
      </S.ColumnFormItem>
      <S.ColumnFormItem>
        <TextField label="Email" name="email" control={control} />
      </S.ColumnFormItem>
      <S.ColumnFormItem setMargin="0 0 0 0">
        <TextField label="Telefone" name="phone" control={control} />
        <TextField label="Celular" name="cell" control={control} />
      </S.ColumnFormItem>
      <S.ColumnFormItem setMargin="10px 0 0 0">
        <h3>Contato Whatsapp</h3>
      </S.ColumnFormItem>
      <S.ColumnFormItem setMargin="0 0 0 0">
        <TextField label="Nome" name="whatsapp_name" control={control} />
        <TextField label="Whatsapp" name="whatsapp" control={control} />
      </S.ColumnFormItem>
      <S.ColumnFormItem>
        <TextField
          label="Função dentro da loja"
          name="in_store_function"
          control={control}
        />
      </S.ColumnFormItem>
      <S.ColumnFormItem setMargin="5px 0 0 0">
        <S.InputForm style={{ flex: "auto" }}>
          <label>Tipo de Regime</label>
          <Select
            name="taxRegime"
            options={regime.map(item => ({
              value: item.value,
              label: item.label,
            }))}
            placeholder="Tipo de Regime"
            value={regime
              .filter(item => item.value === taxRegime)
              .map(item => ({
                value: item.value,
                label: item.label,
              }))}
            control={control}
            onChange={e => {
              setValue("taxRegime", e.value);
            }}
          />
        </S.InputForm>
        {hasShowReceita && (
          <S.InputForm style={{ flex: "0" }}>
            <label>Receita Bruta</label>
            <CurrencyField
              value={receitaBruta}
              required
              helperText="Receita Bruta e obrigátorio."
              name="receitaBruta"
              control={control}
            />
            {!receitaBruta && <h4>Informe o valor de receita bruta</h4>}
          </S.InputForm>
        )}
      </S.ColumnFormItem>
      <S.ColumnFormItem setMargin="5px 0 0 0">
        <S.InputForm>
          <label>Tipo</label>
          <Select
            name="type"
            options={companytype.map(item => ({
              value: item.label,
              label: item.label,
            }))}
            value={companytype
              ?.filter(item => item.label === type || item.value === type)
              .map(item => ({
                value: item.label,
                label: item.label,
              }))}
            placeholder="Tipo"
            control={control}
            onChange={e => {
              setValue("type", e.value);
            }}
          />
        </S.InputForm>
      </S.ColumnFormItem>

      {cnpj && !cnpj.includes("0001") && (
        <S.ColumnFormItem setMargin="5px 0 0 0">
          <S.InputForm>
            <label>Matriz</label>
            <Select
              components={{
                Input: props => (
                  <InputSearch
                    margin="10px 4px 0 4px !important"
                    key="input-search"
                    onInputChange={onInputChange}
                    searchValue={searchValue}
                    {...props}
                  />
                ),
                Option: CustomOption,
              }}
              labelType="top"
              placeholder="Selecione"
              label="Matriz"
              name="companyId"
              noOptionsMessage={messageBlank({
                isLoadingItem: isLoadingSearch,
                dataLength: companies?.length,
                searchLength: searchValue?.length,
              })}
              onChange={selected => {
                setSelectedCompany(selected);
                setValue("companyId", selected?._id);
              }}
              value={
                selectedCompany && {
                  label: selectedCompany.companyName,
                  value: selectedCompany._id,
                }
              }
              filterOption={filterOptions}
              options={
                isLoadingSearch
                  ? []
                  : companies?.map(company => ({
                      label:
                        company.company_name ||
                        company.tradeName ||
                        company.companyName ||
                        company.cnpj ||
                        company.company_social_name,
                      value: company,
                      ...company,
                    }))
              }
            />
          </S.InputForm>
        </S.ColumnFormItem>
      )}
    </>
  );
};
