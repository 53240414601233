import styled from "styled-components";

export const Container = styled.div`
  width: 125px;
  height: 125px;
  background-color: transparent;
  border: 2px dashed red;
  position: relative;
  transform: translate(-15%, -15%);
  cursor: normal;
`;
