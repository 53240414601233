import { useState, useCallback } from "react";
import { useAPIAuthenticate } from "hooks";

export const UseGetNotifications = () => {
  const api = useAPIAuthenticate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const fetch = useCallback(
    ({ pooling = false }) => {
      setLoading(!pooling);
      const url = `?page=1&limit=50`;
      const fetchData = async () => {
        try {
          const {
            data: { notifications },
          } = await api.get(`/notifications${url}`);
          setData(notifications.docs);

          setLoading(false);
        } catch (e) {
          setLoading(false);
          console.log(e.messagge);
          setIsError(true);
        }
      };
      fetchData(url);
    },
    [api],
  );

  return { isLoading: loading, data, fetch, isError };
};

export const UseNotificationUpdate = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdateEnd, setIsUpdateEnd] = useState(false);
  const [isError, setIsError] = useState(false);
  const api = useAPIAuthenticate();

  const fetch = async (params = {}) => {
    try {
      setIsLoading(true);
      const { data } = await (!params?.fields?.data?._id
        ? api.post(`/notification`, params)
        : api.put(
            `/notification/${params?.fields?.data?._id}`,
            params?.fields?.data,
          ));

      if (data.response) {
        // addToast({
        //   type: "success",
        //   title: "SUCESSO",
        //   description: `Atualizado com sucesso`,
        // });
        setIsUpdateEnd(true);
      }
      setIsLoading(false);
    } catch (e) {
      // addToast({
      //   type: "error",
      //   title: "FALHA",
      //   description: "FALHA ao atualizar!",
      // });
      setIsError(true);
      setIsUpdateEnd(false);
      setIsLoading(false);
    }
  };

  return {
    fetch,
    isError,
    isUpdateEnd,
    isLoading,
  };
};
