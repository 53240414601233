import React from "react";
import { Card, Tag } from "@mix/mixfiscal-designsystem";
import { Checkbox as CustomCheckbox } from "components/form";
import { useGridManagerContext } from "context/GridManagerContext";
import Mocks from "pages/Laws/mocks";
import { getTagsWithLabel, getListSelecteds } from "utils/context";

import * as S from "../styles";

const headerColorGray = "#e5e5e5";
const textColorGray = "#304156";

const mocks = new Mocks();
const cst = mocks.getCst();

export const CardFieldsTributarySituation = ({ register, hasIcms = true }) => {
  const {
    filterFields: { cst_e: cstE, cst_s: cstS },
  } = useGridManagerContext();

  const getTagName = getListSelecteds(
    [
      ...getTagsWithLabel(cstE, "cst_e"),
      ...getTagsWithLabel(cstS, "cst_s"),
    ].map(item => item.label),
  );

  return (
    <Card
      className="card-custom"
      handleCtaClick={() => {}}
      hasElevation
      headerColor={headerColorGray}
      textColor={textColorGray}
      title="CÓDIGO SITUAÇÃO TRIBUTÁRIA"
    >
      {/* {hasLucro(typeOrganization?.value) && ( */}
      <>
        <div className="row">
          <S.CardCustomCst type="purchase">
            <h1 className="title-purchase">CST COMPRA</h1>

            {cst[hasIcms ? "icms" : "pis"].entrada.map(cstItem => (
              <div className="row">
                <S.CheckBox>
                  <CustomCheckbox
                    ref={register}
                    name={`cst_e.${cstItem.value}`}
                    value={cstItem.value}
                    label={`${cstItem.value} - ${cstItem.label}`}
                  />
                </S.CheckBox>
              </div>
            ))}
          </S.CardCustomCst>
        </div>

        <div className="row">
          <S.CardCustomCst type="sale">
            <h1>CST VENDA</h1>
            {cst[hasIcms ? "icms" : "pis"].saida.map(cstItem => (
              <div className="row">
                <S.CheckBox>
                  <CustomCheckbox
                    ref={register}
                    name={`cst_s.${cstItem.value}`}
                    value={cstItem.value}
                    label={`${cstItem.value} - ${cstItem.label}`}
                  />
                </S.CheckBox>
              </div>
            ))}
          </S.CardCustomCst>
        </div>
      </>
      {/* )} */}
      {/* {!hasLucro(typeOrganization?.value) && ( */}
      <div className="row">
        <S.CardCustomDefault>
          <h1>CSOSN - CÓDIGO DE SITUAÇÃO DA OPERAÇÃO DO SIMPLES NACIONAL</h1>

          <div className="row">
            <S.CheckBox>
              <CustomCheckbox
                ref={register}
                name="csosn.imune_icms"
                value="1"
                label="300 - Imune de Icms"
              />
            </S.CheckBox>
          </div>

          <div className="row">
            <S.CheckBox>
              <CustomCheckbox
                ref={register}
                name="csosn.nao_tributada_simples_nacional"
                value="1"
                label="400 - Não tributada pelo Simples Nacional"
              />
            </S.CheckBox>
          </div>

          <div className="row">
            <S.CheckBox>
              <CustomCheckbox
                ref={register}
                name="csosn.icms_cobrado_anteriormento"
                value="1"
                label="500 - ICMS Cobrado Anteriormente por ST ou Por Antecipação"
              />
            </S.CheckBox>
          </div>
        </S.CardCustomDefault>
      </div>
      {/* )} */}
      {getTagName && (
        <div
          className="row"
          style={{
            display: "flex",
            alignItems: "center",
            padding: "8px",
          }}
        >
          <S.RowTagsName>
            <Tag label={getTagName} type="simple" />
          </S.RowTagsName>
          {/* <Icon color="#dcdcdc" name="mover" size={24} /> */}
        </div>
      )}
    </Card>
  );
};
