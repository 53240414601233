import styled, { css } from "styled-components";

const stylesTypes = {
  small: {
    containerWidth: "30px",
    containerHeight: "0px",
    bullet: "7px",
  },
};

const containerModifiers = {
  small: () => css`
    & > div {
      width: ${stylesTypes.small.containerWidth};
      height: ${stylesTypes.small.containerHeight};
      & > div {
        height: ${stylesTypes.small.bullet};
        width: ${stylesTypes.small.bullet};
      }
    }
  `,
};

export const Container = styled.div`
  ${props => css`
    position: relative;
    margin-left: 5px;

    & > div {
      margin: initial !important;
    }

    ${props.size === "small" && containerModifiers.small(props)}
  `}
`;

export const ContainerPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const Backdrop = styled.div`
  position: absolute;
  background-color: rgb(179 179 179 / 24%);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;

  ${Container} {
    width: 45px;
    margin-left: 0px;
  }
`;

export const PlaceholderContainer = styled.div`
  position: relative;
`;

export const TitleBlank = styled.h3`
  ${props => css`
    color: #596778;

    font-weight: ${props.fontWeight === "bold" && "700"};
  `}
`;
