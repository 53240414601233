import React from "react";
import { Datatable } from "@mix/mixfiscal-designsystem";
import { formatCnpj } from "utils";
import * as S from "./styles";

export const CustomTooltip = ({ selected }) => {
  return (
    <>
      <S.Title>
        <S.TitleText>
          <h5>Matrizes selecionadas</h5>
        </S.TitleText>
      </S.Title>
      <S.ContainerTooltip>
        <Datatable
          pagination={false}
          columns={[
            {
              field: "company_name",
              title: "Matriz",
            },
            {
              field: "cnpj",
              title: "CNPJ",
            },
          ]}
          rawData={selected?.map(item => ({
            company_name: item?.company_name,
            cnpj: formatCnpj(item?.cnpj),
          }))}
          selectableRowsAll
          selectedItems={[]}
          totalRawData={21}
        />
      </S.ContainerTooltip>
    </>
  );
};
