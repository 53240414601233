import React from "react";

import { Container } from "./styles";

function Comment({ data: { label } }) {
  return (
    <>
      <Container>
        <p>{label}</p>
      </Container>
    </>
  );
}

export default Comment;
