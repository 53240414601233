import React, { useState, useEffect } from "react";
import { Icon, Checkbox } from "@mix/mixfiscal-designsystem";
import { Format } from "utils";
import {
  SectionCards,
  TitleCard,
  RowContentCard,
  FooterCard,
  ContentTextDescription,
  ContainerTeam,
  ContainerImage,
  ContainerDaedline,
} from "./styles";

const imgUrl =
  "https://www.politize.com.br/wp-content/uploads/2016/08/imagem-sem-foto-de-perfil-do-facebook-1348864936180_956x5001.jpg";

const TemplatesCard = ({ template, onClick }) => {
  const [percent, setPercent] = useState(0);

  useEffect(() => {
    if (template?.tasks) {
      const total = template?.tasks?.length || 0;
      const done =
        template?.tasks?.filter(t => t.status === "DONE").length || 0;

      setPercent((done * 100) / total || 0);
    }
  }, [template]);

  return (
    <SectionCards colorHeader="#52b4af">
      <header />

      <TitleCard>
        <h1>{template?.name}</h1>
        <h3>{template?.category?.title}</h3>
      </TitleCard>

      <RowContentCard color="#59aec4">
        <div className="title-description"> DESCRIÇÃO </div>
        <ContentTextDescription padding="8px">
          {template?.description}
        </ContentTextDescription>
      </RowContentCard>

      <RowContentCard color="#59aec4">
        <div className="title-description"> TAREFAS </div>
        <ContentTextDescription padding="5px 12px">
          {template?.tasks?.map((task, index) => (
            <label>
              <div style={{ pointerEvents: "none" }}>
                <Checkbox size={25} value={task.status === "DONE"} />
              </div>
              <button type="button" onClick={() => onClick(template, index)}>
                {task.title}
              </button>
            </label>
          ))}
        </ContentTextDescription>
      </RowContentCard>

      <RowContentCard color="#52b4af">
        <div className="title-description"> IMPACTO </div>
        <ContentTextDescription padding="5px 12px">
          {template?.action}
        </ContentTextDescription>
      </RowContentCard>

      <RowContentCard color="#52b4af">
        <div className="title-description"> PRAZO </div>
        <ContainerDaedline>
          <span>
            <label>Data inicial:</label>
            {template?.tasks[0]?.deadline &&
              Format(template?.tasks[0]?.deadline, "dd/MM/yyyy")}
          </span>

          <span>
            <label>Prazo de execução:</label>
            {template?.tasks[0]?.deadline &&
              Format(template?.tasks[0]?.deadline, "dd/MM/yyyy")}
          </span>

          <span>
            <label>Data conclusão:</label>
            {template?.tasks[0]?.deadline &&
              Format(template.tasks[0]?.deadline, "dd/MM/yyyy")}
          </span>
        </ContainerDaedline>
      </RowContentCard>

      <RowContentCard color="#45924f">
        <div className="title-description"> EQUIPE </div>
        <ContainerTeam>
          <ContainerImage className="container-image">
            <div className="content-image">
              <img
                src={
                  template?.createdBy?.photo
                    ? template?.createdBy?.photo
                    : imgUrl
                }
                title={template?.createdBy?.name}
                alt=""
              />
            </div>
            <span>
              <Icon color="#2297f3" name="setadireita" size={22} />
            </span>
            {template?.tasks?.map((task, index) => (
              <div className="content-image" key={index}>
                <img
                  src={task?.assigned?.photo ? task?.assigned?.photo : imgUrl}
                  title={task?.assigned?.name}
                  alt=""
                />
              </div>
            ))}
          </ContainerImage>
        </ContainerTeam>
      </RowContentCard>

      <FooterCard>{`${percent.toFixed(2)} % concluído`}</FooterCard>
    </SectionCards>
  );
};

export default TemplatesCard;
