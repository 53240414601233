import { useCallback } from "react";
import { useAPIAuthenticateToast, useFetchAuthenticate } from "hooks";

import { getURLWithParams } from "utils/context";

export const useLawsNorms = (params = {}) =>
  useFetchAuthenticate(getURLWithParams("/laws/norms", params));

export const useLawNorm = id => useFetchAuthenticate(id && `/laws/norms/${id}`);

export const useLawNormAction = () => {
  const { isLoading, onSend } = useAPIAuthenticateToast();

  const onSave = useCallback(
    (id, data) =>
      onSend({
        endpoint: id ? `/laws/norms/${id}` : "/laws/norms",
        messageSuccess: "Norma atualizada com sucesso",
        messageFail: "Falha ao tentar atualizar norma",
        data,
        type: id ? "PUT" : "POST",
      }),
    [onSend],
  );

  const onRemove = id =>
    onSend({
      endpoint: `/laws/norms/${id}`,
      messageSuccess: `Norma deletada com sucesso!`,
      messageFail: "Não foi possivel deletar a norma!",
      type: "DELETE",
    });

  return {
    onSave,
    isLoading,
    onRemove,
  };
};
