import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  TextField,
  MaskField,
  Form,
  InputContainerForm,
} from "components/form";
import { Button } from "components/structure";
import { CestSegmentInput } from "components/contexts/cests/CestSegmentInput";
import { NcmInputs } from "components/contexts/ncms/NcmInputs";
import { NcmTags } from "components/contexts/ncms/NcmsTags";
import { CESTS } from "constants/routes";

import { DefaultValues } from "./CestForm.validations";
import * as S from "./CestForm.styles";

export const CestForm = ({ data: fieldsData, onSave = () => {} }) => {
  const { handleSubmit, control, reset, getValues, watch, setValue } = useForm({
    defaultValues: DefaultValues,
  });

  const onSubmit = () => onSave(getValues());

  const hasHasError = () => {
    return false;
  };

  const segmento = watch("segmento");
  const ncm = watch("ncm");

  const handleAdd = ncmFields => {
    if (ncmFields?.codigo?.value && !ncm.includes(ncmFields?.codigo?.value)) {
      ncm.push(ncmFields?.codigo?.value);
      return setValue("ncm", ncm);
    }
  };

  const handleDeleteNcm = value => {
    ncm.splice(
      ncm.indexOf(
        ncm.find(ncmItem => ncmItem === value?.data?.vc_codigo_sem_formatacao),
      ),
      1,
    );
    return setValue("ncm", ncm);
  };

  useEffect(() => {
    reset(fieldsData);
  }, [fieldsData, reset]);

  return (
    <Form className="flex-column" onSubmit={handleSubmit(onSubmit)}>
      <InputContainerForm>
        <S.InputContainer flex={0.6}>
          <MaskField
            name="cest"
            control={control}
            label="Código CEST"
            mask="99.999.99"
          />
        </S.InputContainer>
        <S.InputContainer flex={1} maxWidth="100%">
          <TextField
            label="Descrição"
            name="description"
            control={control}
            required
          />
        </S.InputContainer>
      </InputContainerForm>
      <InputContainerForm>
        <S.InputContainer flex={0.5} maxWidth="100%">
          <CestSegmentInput
            name="segmento"
            label="Segmento"
            control={control}
            value={segmento ?? fieldsData?.segmento}
          />
        </S.InputContainer>
        <S.InputContainer flex={0.5}>
          <TextField label="Item" name="item" control={control} required />
        </S.InputContainer>
        <S.InputContainer flex={0.5} maxWidth="100%">
          <TextField label="Anexo" name="anexo" control={control} required />
        </S.InputContainer>
      </InputContainerForm>

      <InputContainerForm>
        <NcmInputs handleAdd={handleAdd} />
      </InputContainerForm>
      <InputContainerForm>
        <S.InputContainer flex={1} maxWidth="100%">
          <NcmTags
            deleteNcm={handleDeleteNcm}
            ncms={ncm?.map(ncmCode => ({
              data: { vc_codigo_sem_formatacao: ncmCode },
            }))}
          />
        </S.InputContainer>
      </InputContainerForm>
      <S.FooterActions>
        <Button
          label="Voltar"
          color="secondary"
          toLink={CESTS.BASE}
          style={{ marginRight: "15px" }}
        />
        <Button type="submit" label="Salvar" isDisabled={hasHasError()} />
      </S.FooterActions>
    </Form>
  );
};
