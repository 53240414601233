import styled from "styled-components";
import * as StylesSelect from "components/form/Select/styles";
import {
  ButtonAdd,
  ButtonRemove,
} from "components/structure/Button/Button.styles";

export const ContainerField = styled.div`
  display: flex;
  width: 100%;

  ${StylesSelect.Select} {
    width: 100%;
  }

  ${ButtonAdd}, ${ButtonRemove} {
    margin-top: 1.5rem;
  }
`;

export const ContainerFieldWrapper = styled.div``;
