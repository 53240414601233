import styled from "styled-components";

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  transition: all 0.4s ease;
  transform-origin: center;
  transform: ${props => (props.isOpen ? "rotateZ(0deg)" : "rotateZ(-180deg)")};
`;

export const CamposAdicionaisContainer = styled.div`
  display: grid;
  width: 270px;
  margin-left: 30px;
  margin-top: 15px;
  grid-template-areas:
    "head head"
    "l1c1 l1c2"
    "l2c1 l2c2"
    "l3c1 l3c2"
    "l4c1 l4c2"
    "l5c1 l5c2";
  grid-template-columns: 160px 110px;
  grid-template-rows: 30px 30px 30px 30px 30px 30px;

  p {
    font-family: Cairo;
    font-size: 12px;
  }

  .head {
    grid-area: head;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #596778;
    background-color: #adc86a;
    p {
      color: #fff;
      font-weight: bold;
    }
  }
  .l1c1 {
    grid-area: l1c1;
    display: flex;
    align-items: center;
    border-left: 1px solid #596778;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    p {
      color: #596778;
      margin-left: 12px;
    }
  }

  .l1c2 {
    grid-area: l1c2;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    padding-left: 5px;
    p {
      color: #596778;
      margin-left: 5px;
      font-size: 12px;
    }
  }
  .l2c1 {
    grid-area: l2c1;
    display: flex;
    align-items: center;
    border-left: 1px solid #596778;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    p {
      color: #596778;
      margin-left: 12px;
    }
  }
  .l2c2 {
    grid-area: l2c2;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    padding-left: 5px;
    p {
      justify-content: space-between;
      color: #596778;
      margin-left: 5px;
      font-size: 12px;
    }
  }
  .l3c1 {
    grid-area: l3c1;
    display: flex;
    align-items: center;
    border-left: 1px solid #596778;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    p {
      color: #596778;
      margin-left: 12px;
    }
  }
  .l3c2 {
    grid-area: l3c2;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    padding-left: 5px;
    p {
      justify-content: space-between;
      color: #596778;
      margin-left: 5px;
      font-size: 12px;
    }
  }
  .l4c1 {
    grid-area: l4c1;
    display: flex;
    align-items: center;
    border-left: 1px solid #596778;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    p {
      color: #596778;
      margin-left: 12px;
    }
  }
  .l4c2 {
    grid-area: l4c2;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    padding-left: 5px;
    p {
      justify-content: space-between;
      color: #596778;
      margin-left: 5px;
      font-size: 12px;
    }
  }

  .l5c1 {
    grid-area: l5c1;
    display: flex;
    align-items: center;
    border-left: 1px solid #596778;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    p {
      color: #596778;
      margin-left: 12px;
    }
  }
  .l5c2 {
    grid-area: l5c2;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    padding-left: 5px;
    p {
      justify-content: space-between;
      color: #596778;
      margin-left: 5px;
      font-size: 12px;
    }
  }
`;

export const DiagnosticContainer = styled.div`
  display: grid;
  width: 270px;
  margin-left: 30px;
  margin-top: 15px;
  grid-template-areas:
    "head head"
    "l1c1 l1c2"
    "l2c1 l2c2"
    "l3c1 l3c2"
    "l4c1 l4c2"
    "l5c1 l5c2"
    "l6c1 l6c2";
  grid-template-columns: 160px 110px;
  grid-template-rows: 30px 30px 30px 30px 30px 30px 30px;

  p {
    font-family: Cairo;
    font-size: 12px;
  }

  .head {
    grid-area: head;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #596778;
    background-color: #adc86a;
    p {
      color: #fff;
      font-weight: bold;
    }
  }
  .l1c1 {
    grid-area: l1c1;
    display: flex;
    align-items: center;
    border-left: 1px solid #596778;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    p {
      color: #596778;
      margin-left: 12px;
    }
  }

  .l1c2 {
    grid-area: l1c2;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    padding-left: 5px;
    p {
      justify-content: space-between;
      color: #596778;
      margin-left: 5px;
      font-size: 16px;
    }
  }
  .l2c1 {
    grid-area: l2c1;
    display: flex;
    align-items: center;
    border-left: 1px solid #596778;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    p {
      color: #596778;
      margin-left: 12px;
    }
  }
  .l2c2 {
    grid-area: l2c2;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    padding-left: 5px;
    p {
      justify-content: space-between;
      color: #596778;
      margin-left: 5px;
      font-size: 16px;
    }
  }
  .l3c1 {
    grid-area: l3c1;
    display: flex;
    align-items: center;
    border-left: 1px solid #596778;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    p {
      color: #596778;
      margin-left: 12px;
    }
  }
  .l3c2 {
    grid-area: l3c2;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    padding-left: 5px;
    p {
      justify-content: space-between;
      color: #596778;
      margin-left: 5px;
      font-size: 16px;
    }
  }
  .l4c1 {
    grid-area: l4c1;
    display: flex;
    align-items: center;
    border-left: 1px solid #596778;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    p {
      color: #596778;
      margin-left: 12px;
    }
  }
  .l4c2 {
    grid-area: l4c2;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    padding-left: 5px;
    p {
      justify-content: space-between;
      color: #596778;
      margin-left: 5px;
      font-size: 16px;
    }
  }

  .l5c1 {
    grid-area: l5c1;
    display: flex;
    align-items: center;
    border-left: 1px solid #596778;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    p {
      color: #596778;
      margin-left: 12px;
    }
  }
  .l5c2 {
    grid-area: l5c2;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    padding-left: 5px;
    p {
      justify-content: space-between;
      color: #596778;
      margin-left: 5px;
      font-size: 16px;
    }
  }

  .l6c1 {
    grid-area: l6c1;
    display: flex;
    align-items: center;
    border-left: 1px solid #596778;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    p {
      color: #596778;
      margin-left: 12px;
    }
  }
  .l6c2 {
    grid-area: l6c2;
    display: flex;
    align-items: center;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    p {
      color: #596778;
      margin-left: 12px;
    }
  }
`;

export const DivergeContainer = styled.div`
  display: grid;
  width: 270px;
  max-height: 38px;
  margin-left: 30px;
  grid-template-areas:
    "head head"
    "l1c1 l1c2"
    "l2c1 l2c2"
    "l3c1 l3c2"
    "l4c1 l4c1"
    "l5c1 l5c1";
  grid-template-columns: 100px 170px;
  grid-template-rows: 30px 29px 37px 128px 80px 44px;
  background-color: ${props => (props.isOpen ? "#ffff" : "")};
  overflow: none;

  p {
    font-family: Cairo;
    font-size: 12px;
  }

  .head {
    grid-area: head;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #596778;
    background: #f6b000 0% 0% no-repeat padding-box;
    height: 40px;

    background-color: ${props => (props.isOpen ? "#f6b000" : "#f6b000")};

    border-bottom: ${props => (props.isOpen ? "none" : "1px solid #596778")};
    p {
      color: #fff;
      font-weight: bold;
    }
  }

  .content {
    display: grid;
    grid-template-areas:
      "l1c1 l1c2"
      "l2c1 l2c2"
      "l3c1 l3c2"
      "l4c1 l4c1"
      "l5c1 l5c1";
    grid-template-columns: 100px 170px;
    grid-template-rows: 29px 37px 128px 80px 44px;
    transition: all 0.3s;
    background-color: #fff;
    transform-origin: top;
    width: 100%;
    max-height: ${props => (props.isOpen ? "100000px" : "0px")};
    transform: ${props => (props.isOpen ? "scaleY(1)" : "scaleY(0)")};
    z-index: 10000;
    border-bottom: ${props => props.isOpen && "1px solid #596778"};
    margin-top: 10px;

    .l1c1 {
      grid-area: l1c1;
      display: flex;
      align-items: center;
      border-left: 1px solid #596778;
      border-right: 1px solid #596778;
      border-bottom: 1px solid #596778;
      background-color: #fff;

      p {
        color: #596778;
        margin-left: 12px;
      }
    }

    .l1c2 {
      grid-area: l1c2;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-right: 1px solid #596778;
      border-bottom: 1px solid #596778;
      padding-left: 5px;
      background-color: #fff;

      p {
        text-align: left;
        font: normal normal bold 12px/12px Cairo;
        letter-spacing: 0px;
        color: #596778;
        opacity: 1;
      }
    }
    .l2c1 {
      grid-area: l2c1;
      display: flex;
      align-items: center;
      border-left: 1px solid #596778;
      border-right: 1px solid #596778;
      border-bottom: 1px solid #596778;
      background-color: #fff;

      p {
        color: #596778;
        margin-left: 12px;
      }
    }
    .l2c2 {
      grid-area: l2c2;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-right: 1px solid #596778;
      border-bottom: 1px solid #596778;
      padding-left: 5px;
      background-color: #fff;

      p {
        text-align: left;
        font: normal normal bold 12px/12px Cairo;
        letter-spacing: 0px;
        color: #596778;
        opacity: 1;
      }
    }
    .l3c1 {
      grid-area: l3c1;
      display: flex;
      align-items: center;
      border-left: 1px solid #596778;
      border-right: 1px solid #596778;
      border-bottom: 1px solid #596778;
      background-color: #fff;

      p {
        color: #596778;
        margin-left: 12px;
      }
    }
    .l3c2 {
      grid-area: l3c2;
      display: flex;
      align-items: start;
      flex-direction: column;
      background-color: #fff;
      border-right: 1px solid #596778;
      border-bottom: 1px solid #596778;
      padding-left: 5px;

      div {
        margin-bottom: -11px;
        .first-input {
          margin-left: 37px;
        }
        .second-input {
          margin-left: 45px;
        }
        .third-input {
          margin-left: 44px;
        }
        .four-input {
          margin-left: 40px;
        }
      }
      label {
        justify-content: space-between;
        margin-left: 5px;
        color: var(--slate-gray);
        text-align: left;
        font: normal normal bold 12px/12px Cairo;
        letter-spacing: 0px;
        color: #596778;
        opacity: 1;
      }
    }
    .l4c1 {
      grid-area: l4c1;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid #596778;
      border-left: 1px solid #596778;
      opacity: 1;
      background-color: #fff;

      textarea {
        width: 260px;
        height: 60px;
        color: #fff;
        font-weight: bold;
        background: var(--white) 0% 0% no-repeat padding-box;
        border: 1px solid var(--slate-gray);
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #596778;
        border-radius: 5px;
        text-align: left;
        font: normal normal normal 12px/12px Cairo;
        letter-spacing: 0px;
        color: #596778;
        opacity: 1;
        padding-top: 6px;
        padding-left: 6px;
      }
    }
    .l5c1 {
      grid-area: l5c1;
      display: flex;
      border-right: 1px solid #596778;
      border-left: 1px solid #596778;
      border-bottom: 1px solid #596778;
      opacity: 1;
      background-color: #fff;
      border-bottom-color: black;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;

      button {
        margin-left: 80px;
        margin-top: 4px;
        text-align: center;
        font: normal normal bold 12px/12px Cairo;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 1;
        top: 529px;
        left: 860px;
        width: 100px;
        height: 25px;
        background: #5a8dee 0% 0% no-repeat padding-box;
        border-radius: 5px;
        opacity: 1;
      }
    }
  }
`;
