import React from "react";
import { Controller } from "react-hook-form";
import CurrencyInput from "react-currency-input-field";
import InputMask from "react-input-mask";

import * as S from "./styles";

export const TextField = ({
  name,
  control,
  label,
  helperText,
  error,
  ...props
}) => {
  if (control) {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <S.InputFieldContainer>
            <S.InputFieldLabel error={value && error}>
              {label}
            </S.InputFieldLabel>
            <S.InputField
              value={value}
              onChange={onChange}
              error={value && error}
              {...props}
            />
            <S.InputFieldHelperText error={value && error}>
              {helperText}
            </S.InputFieldHelperText>
          </S.InputFieldContainer>
        )}
      />
    );
  }

  return (
    <S.InputFieldContainer>
      <S.InputFieldLabel>{label}</S.InputFieldLabel>
      <S.InputField {...props} />
    </S.InputFieldContainer>
  );
};

export const TextHiddenField = ({ name, control, ...props }) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { onChange, value } }) => (
      <input type="hidden" value={value} onChange={onChange} {...props} />
    )}
  />
);

export const MaskField = ({
  name,
  control,
  label,
  onChange: onChangeProps,
  mask,
  value: valueDefault,
  ...props
}) => {
  if (control) {
    return (
      <Controller
        as={InputMask}
        control={control}
        name={name}
        required
        render={({ field: { onChange, value } }) => {
          return (
            <S.InputFieldContainer>
              <S.InputFieldLabel>{label}</S.InputFieldLabel>
              <InputMask mask={mask} value={value} onChange={onChange}>
                {inputProps => (
                  <S.InputField
                    {...props}
                    {...inputProps}
                    type="text"
                    disableUnderline
                  />
                )}
              </InputMask>
            </S.InputFieldContainer>
          );
        }}
      />
    );
  }

  return (
    <S.InputFieldContainer>
      <S.InputFieldLabel>{label}</S.InputFieldLabel>
      <InputMask mask={mask} value={valueDefault} onChange={onChangeProps}>
        {inputProps => (
          <S.InputField
            {...props}
            {...inputProps}
            type="text"
            disableUnderline
          />
        )}
      </InputMask>
    </S.InputFieldContainer>
  );
};

export const CurrencyField = ({
  name,
  control,
  onChange: onChangeProps,
  ...props
}) => {
  if (control) {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange } }) => (
          <S.CurrencyField>
            <CurrencyInput
              placeholder="R$0,00"
              prefix="R$"
              decimalsLimit={2}
              onValueChange={value => onChange(value)}
              {...props}
            />
          </S.CurrencyField>
        )}
      />
    );
  }

  return (
    <S.CurrencyField>
      <CurrencyInput
        placeholder="R$0,00"
        prefix="R$"
        decimalsLimit={2}
        onValueChange={value => onChangeProps && onChangeProps(value)}
        {...props}
      />
    </S.CurrencyField>
  );
};
