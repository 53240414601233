import React, { useCallback, useRef } from "react";
import * as S from "./styles";
import { useOnClickOutside } from "../../../hooks";

export const CardSuspended = ({ children, close, ...props }) => {
  const ref = useRef();

  useOnClickOutside(ref, useCallback(close, [close]));

  return (
    <S.Container ref={ref} {...props}>
      {children}
    </S.Container>
  );
};
