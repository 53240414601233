import React, { useState, useEffect } from "react";

import { useFlow } from "../../../../context/FlowContext";
import Dialog from "../../../UI/Dialog";
import { Container } from "./styles";

function SaveFlowDialog({ isOpen, handleClose, title }) {
  const [flowName, setFlowName] = useState("");
  const { selectedFlow, handleSaveFlow } = useFlow();

  useEffect(() => {
    if (flowName === "" && selectedFlow?.name) {
      setFlowName(selectedFlow?.name);
    }
  }, [flowName, selectedFlow]);

  const handleClick = type => {
    handleSaveFlow(flowName, type);
    handleClose();
  };

  return (
    <Dialog title={title} isOpen={isOpen} handleClose={handleClose}>
      <Container>
        <input
          type="text"
          placeholder="*Digite o nome desse fluxo"
          value={flowName}
          onChange={e => setFlowName(e.target.value)}
        />
        <p>Salvar como:</p>
        <div>
          <button type="button" onClick={() => handleClick("draft")}>
            Rascunho
          </button>
          <button type="button" onClick={() => handleClick("publish")}>
            Publicar
          </button>
        </div>
      </Container>
    </Dialog>
  );
}

export default SaveFlowDialog;
