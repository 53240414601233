import React from "react";

export const TooltipChart = ({ datum }) => {
  const total = Number(datum.data.value).toLocaleString("pt-br", {
    style: "currency",
    currency: "BRL",
  });
  return (
    <div
      style={{
        fontSize: "14px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: "rgba(255,255,255, 0.5)",
        padding: 5,
        borderRadius: 3,
      }}
    >
      <span
        style={{
          width: "10px",
          height: "10px",
          backgroundColor: `${datum.color}`,
          marginRight: "5px",
        }}
      />
      <div style={{ display: "flex", flexDirection: "column" }}>
        <p>{`Aliq: ${datum.id}`}</p>
        <p>
          {`${total} (`}
          <strong style={{ fontWeight: "700" }}>
            {`${datum.data.porcentagem}%`}
          </strong>
          )
        </p>
      </div>
    </div>
  );
};
