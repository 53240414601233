/**
 * Helper to get search matches even when not a word
 * @param {String} string full string to search content
 * @param {Array} bold all bold positions for a given search
 * @returns returns array of all elements positions
 */
export const getMatches = (string, search) => {
  if (string && search && search.length) {
    let match;
    const matches = [];
    const re = new RegExp(search, "gid");

    do {
      match = re.exec(string);
      if (match) {
        matches.push(match);
      }
    } while (match);

    return matches;
  }
  return false;
};

/**
 * Helper to found string elements positions
 * @param {String} string full string to search content
 * @param {Array} bold all bold positions for a given search
 * @returns returns array of all elements positions
 */
export const getAllPositions = (string, bold) => {
  let temp = [];
  let lastEnd;

  return bold.reduce((positions, pos, i) => {
    const [start, end] = pos;
    temp = [];

    if (i === 0 && i < start) {
      temp.push([i, start]);
    }

    if (i === 0 || lastEnd === start) {
      temp.push(pos);
    } else {
      const last = [lastEnd, start];
      temp = [last, pos];
    }

    if (i === bold.length - 1) {
      temp.push([end, string.length]);
    }

    lastEnd = end;

    return [...positions, ...temp];
  }, []);
};
