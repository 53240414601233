import React, { useState, useEffect } from "react";
import { Tooltip } from "@mix/mixfiscal-designsystem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";

import * as ST from "./styles";

export const TooltipStores = ({ name, store, remove }) => {
  const [listStores, setListStores] = useState(null);

  const handleRemoveStore = selectedItemIdStore => {
    setListStores(
      listStores.filter(item => item.value !== selectedItemIdStore),
    );
    remove(selectedItemIdStore);
  };

  useEffect(() => {
    if (store) {
      setListStores(store);
    }
  }, [store, setListStores]);
  return (
    <ST.TooltipCard>
      <ST.Title>Lojas</ST.Title>
      <ST.TooltipContent>
        {listStores &&
          listStores?.map((item, index) => {
            return (
              <ST.TooltipContentItem key={index}>
                <ST.TooltipContentItemText>
                  <FontAwesomeIcon
                    onClick={() => {
                      handleRemoveStore(item);
                    }}
                    icon={faTrashCan}
                    style={{
                      fontSize: "13px",
                    }}
                    color="#565656"
                  />
                </ST.TooltipContentItemText>
                <ST.TooltipContentItemText>
                  {name(item)}
                </ST.TooltipContentItemText>
              </ST.TooltipContentItem>
            );
          })}
      </ST.TooltipContent>
    </ST.TooltipCard>
  );
};

export const ItemStores = props => {
  const {
    findStoreName,
    tableData,
    setTableData,
    store: { store },
  } = props;

  if (store && store.length === 1) {
    return (
      <Tooltip body={findStoreName(store[0])} position="top">
        <ST.StoreItemNameFull>{findStoreName(store[0])}</ST.StoreItemNameFull>
      </Tooltip>
    );
  }
  if (store && store.length === 2) {
    return store.map((item, indexItem) => {
      return (
        <Tooltip key={indexItem} body={findStoreName(item)} position="top">
          <ST.StoreItemName>{findStoreName(item)}</ST.StoreItemName>
        </Tooltip>
      );
    });
  }

  const handleRemoveStore = selectedItemIdStore => {
    setTableData(
      tableData.map(item => ({
        ...item,
        store: item.store.filter(
          subItem => subItem.value !== selectedItemIdStore,
        ),
      })),
    );
  };
  const Too = (
    <TooltipStores
      name={findStoreName}
      remove={handleRemoveStore}
      store={store}
    />
  );

  if (store && store.length > 2) {
    return (
      <Tooltip isPopOver title="Lojas" component={Too} position="top">
        <ST.StoreItemTotal>{`${store.length}`}</ST.StoreItemTotal>
      </Tooltip>
    );
  }
  if (store && !store.length) {
    return (
      <ST.StoreItemNameFull>
        <span>TODAS LOJAS SELECIONADAS</span>
      </ST.StoreItemNameFull>
    );
  }
};
