import React, { useState } from "react";

import { Container, ItemContainer } from "./styles";

// type TabsProps = {
//   items: string[];
//   type: 'default' | 'alternative' | 'slim';
//   handleClickCallback?: (value: string) => void;
// }

const Tabs = ({ items, type = "default", handleClickCallback }) => {
  const [selectedItem, setSelectedItem] = useState(
    (items && items.length > 0 && items[0]) || "",
  );

  const handleClick = item => {
    setSelectedItem(item);
    if (handleClickCallback) handleClickCallback(item);
  };

  return (
    <Container type={type}>
      {items &&
        items.map(item => (
          <ItemContainer
            key={item}
            isSelected={selectedItem === item}
            type={type}
            onClick={() => handleClick(item)}
          >
            <p>{item}</p>
          </ItemContainer>
        ))}
    </Container>
  );
};

export default Tabs;
