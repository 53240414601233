import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: ${props => props.zIndex};

  display: flex;
  align-items: center;
  justify-content: center;
`;
export const BackDrop = styled.div`
  position: absolute;
  z-index: 100000;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
`;

export const DialogContainer = styled.div`
  position: absolute;
  z-index: 100001;
  box-shadow: 0px 2px 4px #00000033;
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: ${props => props.width};

  display: flex;
  flex-direction: column;

  min-width: ${props => (props.fullScreen ? "unset" : "400px")};
  max-height: ${props => (props.fullScreen ? "unset" : "700px")};
  width: ${props => props.fullScreen && "100%"};
  height: ${props => props.fullScreen && "100%"};
  border-radius: 8px;
`;

export const TitleContainer = styled.div`
  padding: 10px;
  font-weight: 500;
  background-color: #5a8dee;
  border-radius: 8px 8px 0 0;

  display: flex;
  align-items: center;
  justify-content: space-between;

  & > p {
    color: #fff;
    font-size: 20px;
  }

  & > div.buttonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #fff;
    padding: 8px;
    cursor: pointer;

    svg {
      fill: #5a8dee;
    }
  }
`;

export const ContentContainer = styled.div`
  overflow: initial;
  flex: 1;
  padding: 5px 10px 5px 5px;
  background-color: #fff;
  border-radius: ${props => !props.hasHandleConfirm && "0 0 8px 8px"};

  ::-webkit-scrollbar {
    display: none;
  }
`;
export const ActionsContainer = styled.div`
  padding: 16px;
  /* border-top: 1px solid #000; */
  background-color: #fff;
  border-radius: 0 0 8px 8px;

  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
