import styled from "styled-components";

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const Thead = styled.thead``;
export const Tbody = styled.tbody``;

export const RangeSlider = styled.div`
  padding: 0 18px 0 10px !important;
`;
export const TH = styled.th`
  padding: 8px 0;

  &:first-of-type {
    padding-left: 13px;
  }
  &:last-of-type {
    padding-right: 13px;
  }
`;

export const THDiv = styled.div`
  display: flex;
  align-items: center;
  margin: 0 5px 0 0;
  input {
    margin-right: 15px;
  }
  p {
    color: #565656;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: bold;
    cursor: pointer;
  }
`;

export const TheadTR = styled.tr`
  border: 1px solid #bababa !important;
`;
