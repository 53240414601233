import React from "react";
import { format } from "date-fns";
import { MdAddCircle } from "react-icons/md";
import { Icon } from "@mix/mixfiscal-designsystem";

import { Container } from "./styles";

function TemplateCard({
  color,
  type = "actions",
  title = "",
  handleEdit,
  handleRemove,
  flowData,
  hasPermissionToEdit = false,
}) {
  return (
    <Container color={color}>
      <header>
        <p className="title">{title}</p>
        {/* <p>Categoria: Financeiro</p> */}
      </header>
      <div className="actionsContainer">
        {type === "actions" && hasPermissionToEdit && (
          <>
            <div onClick={handleEdit} role="button">
              <Icon name="editarlinha" size={14} />
              <p>Editar</p>
            </div>
            {/* <div>
              <Icon name="copiar" size={14} />
              <p>Duplicar</p>
            </div>
            <div>
              <Icon name="compartilhar" size={14} />
              <p>Compartilhar</p>
            </div> */}
            <div onClick={handleRemove} role="button">
              <Icon name="fechar1" size={14} />
              <p>Excluir</p>
            </div>
          </>
        )}
        {type === "hasTemplate" && <MdAddCircle size={28} color="#ADC86A" />}
      </div>
      <footer>
        {/* <p>Criado por: Nome da Pessoa</p> */}
        <p>
          {`Data da criação:  ${format(
            new Date(flowData?.createdAt),
            "dd/MM/yyyy",
          )}`}
        </p>
        <p>
          {`Última edição:  ${format(
            new Date(flowData?.updatedAt),
            "dd/MM/yyyy",
          )}`}
        </p>
        {/* {format(new Date(flowData?.updatedAt), "dd/MM/yyyy")}
        <p>Data da criação: 21/12/2020</p>
        <p>Última edição: 21/12/2020</p> */}
      </footer>
    </Container>
  );
}

export default TemplateCard;
