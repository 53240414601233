import styled from "styled-components";

export const Container = styled.div`
  cursor: pointer;

  p {
    transition: all 0.2s;
  }

  p:hover {
    font-weight: bold;
  }
`;
