import React from "react";
import { Card } from "@mix/mixfiscal-designsystem";
import TemplatesCard from "../TemplatesCard";
import { Container } from "./styles";

const Preview = ({ template }) => {
  return (
    <Container>
      <Card
        headerColor="#59AEC4"
        textColor="#FFF"
        title="PREVIEW"
        className="flatCard"
      >
        <TemplatesCard template={template} />
      </Card>
    </Container>
  );
};

export default Preview;
