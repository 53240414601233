/* eslint-disable no-case-declarations */
import React, { useState, useEffect, useCallback, useRef, memo } from "react";
import { v4 as uuidv4 } from "uuid";
import { Handle, getIncomers, useStoreState } from "react-flow-renderer";

import { useFlow } from "../../../../context/FlowContext";
import templatesApi from "../../../../services/templatesApi";
import * as operationMethods from "./Operations";
import Select from "./Select";

import { Container } from "./styles";

const operations = [
  "add",
  "subtract",
  "multiply",
  "divide",
  "average",
  "roundDown",
  "roundUp",
  "input",
  // "cherryPick",
  // "groupAndOrder",
  "sumAll",
  "averageAll",
  // "groupAndSumAll",
  "end",
];

const RunUntil = ({ id, data: { parentId, ...remainingData } }) => {
  const parentRef = useRef(remainingData?.ref ?? {});
  const { handleUpdateNodeData } = useFlow();

  const state = useRef(null);
  const [connectedNodes, setConnectedNodes] = useState([]);
  const [dataNodes, setDataNodes] = useState([]);
  // const [operationType, setOperationType] = useState("pick");
  const [savedUniqueIds, setSavedUniqueIds] = useState([]);

  const [selects, setSelects] = useState(
    remainingData?.selects ?? [...new Array(1)],
  );

  const Observer = memo(() => {
    const obsState = useStoreState(store => store);
    state.current = obsState;
    return null;
  });

  const fetchData = useCallback(async () => {
    if (!id || !state.current) return;
    const tempData = {};
    const uniqueIds = new Set();
    const token = localStorage.getItem("@mixfiscal:authenticatorToken");

    const currentElements = [...state.current.nodes, ...state.current.edges];

    const incomers = getIncomers(
      currentElements.find(node => node.id === id),
      currentElements,
    );

    for await (const income of incomers) {
      if (!uniqueIds.has(income.id)) {
        uniqueIds.add(income.id);
        if (income.type === "inputValue") {
          tempData[income.id] = [income?.data?.value];
        } else if (income.type === "runUntil") {
          tempData[income.id] = income?.data?.finalValue;
        } else {
          const fileId = income?.data?.element?.data?.selectedDataFileId;
          const { data: responseFileContent } = await templatesApi.get(
            `/files/file/${
              income?.data?.element?.data?.register
                ? `${fileId}/${income?.data?.element?.data?.register}`
                : fileId
            }`,
            {
              headers: {
                Authorization: token,
              },
            },
          );
          tempData[income.id] = responseFileContent?.item?.body[0]?.data;
        }
      }
    }

    setSavedUniqueIds([...uniqueIds]);
    setConnectedNodes(() => incomers);
    setDataNodes(tempData);

    if (remainingData?.ref) {
      parentRef.current = remainingData?.ref;
    }
  }, [id, remainingData?.ref]);

  const handleGetResult = () => {
    let previousValue;

    Object.keys(parentRef.current).forEach(key => {
      const template = parentRef.current[key];
      const nodeId = connectedNodes[template?.selectedNodeIndex]?.id;
      const nodeData = dataNodes[nodeId];

      const nodeDataToCompare =
        dataNodes[connectedNodes[template?.selectedNodeToCompare]?.id];

      if (template?.selectedOperation !== "end") {
        const filteredData = nodeData?.reduce((acc, cur) => {
          let isValidRow;

          switch (template?.filterBy) {
            case "partial":
              const stringToValidate =
                cur[template?.selectedColumnIndex]?.toString() ?? "";
              isValidRow = stringToValidate?.includes(template?.filterByValue);
              break;
            case "total":
              isValidRow =
                cur[template?.selectedColumnIndex]?.toLowerCase() ===
                template?.filterByValue?.toLowerCase();
              break;
            case "value": {
              const valueToFind = cur[template?.selectedColumnIndex]
                ?.toString()
                ?.toLowerCase()
                ?.trim();

              isValidRow = nodeDataToCompare?.some(
                rowData =>
                  rowData[template?.selectedColumnToCompare]
                    ?.toString()
                    ?.toLowerCase()
                    ?.trim() === valueToFind,
              );
              break;
            }
            case "cherryPick":
              if (
                template?.cherryPickColumnValues?.includes(
                  cur[template?.selectedColumnIndex],
                )
              ) {
                isValidRow = true;
              } else {
                isValidRow = false;
              }
              break;
            case "noFilter":
              isValidRow = true;
              break;
            default:
              isValidRow = false;
          }
          if (isValidRow) {
            if (template?.isUsingCompareColumnAsValue) {
              // here we fetch the data from the comparison column to maintain same order to help compose reports.
              const data = nodeDataToCompare.find(
                rowData =>
                  rowData[template?.selectedColumnToCompare] ===
                  cur[template?.selectedColumnIndex],
              )?.[template?.selectedColumnToCompareAsValue];
              return [...acc, data];
            }
            if (template?.filterBy === "noFilter") {
              return [...acc, cur];
            }
            const data = Array.isArray(cur)
              ? cur[template?.selectedValueColumnIndex]
              : [cur];
            // const data = Array.isArray(cur)
            //   ? cur[template?.selectedValueColumnIndex]
            //   : `REPEAT-THIS-DATA:${cur}`;
            return [...acc, data];
          }
          return acc;
        }, []);

        let result;

        const higherLengthToProcess =
          filteredData?.length > previousValue?.length
            ? filteredData?.length
            : previousValue?.length;

        if (template?.selectedOperation) {
          let repeatedValue;
          let x;
          let y;

          if (
            typeof previousValue?.[0] === "string" &&
            previousValue?.[0]?.match(/REPEAT-THIS-DATA:/gi)
          ) {
            const stripedValue = previousValue[0].replace(
              /REPEAT-THIS-DATA:/gi,
              "",
            );
            repeatedValue = [...new Array(higherLengthToProcess)].map(
              // eslint-disable-next-line no-unused-vars
              _ => stripedValue,
            );
            x = filteredData;
            y = repeatedValue;
          } else if (
            typeof filteredData?.[0] === "string" &&
            filteredData?.[0]?.match(/REPEAT-THIS-DATA:/gi)
          ) {
            const stripedValue = filteredData[0].replace(
              /REPEAT-THIS-DATA:/gi,
              "",
            );
            repeatedValue = [...new Array(higherLengthToProcess)].map(
              // eslint-disable-next-line no-unused-vars
              _ => stripedValue,
            );
            x = previousValue;
            y = repeatedValue;
          } else {
            x = previousValue;
            y = filteredData;
          }

          if (previousValue) {
            const previousTemplate = parentRef.current[key - 1];
            if (previousTemplate?.filterBy === "noFilter") {
              if (template?.selectedOperation === "groupAndSumAll") {
                result = operationMethods[template?.selectedOperation]({
                  distictionColumn: previousTemplate?.selectedColumnIndex,
                  valueColumn: previousTemplate?.selectedValueColumnIndex,
                  data: previousValue,
                });
              } else {
                result = operationMethods[template?.selectedOperation](x, y);
              }
            } else {
              result = operationMethods[template?.selectedOperation](x, y);
            }
          } else {
            result =
              operationMethods[template?.selectedOperation](filteredData);
          }
        } else {
          result = filteredData;
        }

        previousValue = result;
      }
    });

    console.log({ previousValue });

    // save templateflow to context
    handleUpdateNodeData({
      id,
      parentId,
      data: {
        ref: parentRef.current,
        selects,
        parentId,
        finalValue: previousValue,
      },
      updatedNodesPositions: state.current,
    });
  };

  const handleDeleteItem = index => {
    const remainingIndexes = Object.keys(parentRef.current).filter(
      (_, ind) => ind !== index,
    );
    const newRefObj = {};

    remainingIndexes.forEach((oldInd, ind) => {
      newRefObj[ind] = parentRef.current[oldInd];
    });

    parentRef.current = newRefObj;

    setSelects(prevState =>
      prevState.filter((_, stateIndex) => stateIndex !== index),
    );
  };

  useEffect(() => {
    if (state.current && dataNodes?.length === 0) {
      fetchData();
    }
  }, [dataNodes, fetchData]);

  const refresh = () => {
    if (id && state.current) {
      const currentElements = [...state.current.nodes, ...state.current.edges];
      const incomers = getIncomers(
        currentElements.find(node => node.id === id),
        currentElements,
      );

      if (
        incomers.length < savedUniqueIds.length ||
        incomers.length > savedUniqueIds.length ||
        incomers.some(incomer => {
          if (savedUniqueIds.indexOf(incomer.id) === -1) {
            return true;
          }
          return false;
        })
      ) {
        fetchData();
      }
    }
  };

  return (
    <>
      <Observer />
      <Container>
        {/* <select
          value={operationType}
          onChange={e => setOperationType(e.target.value)}
        >
          <option value="pick">escolher</option>
          <option value="group">agrupar</option>
        </select> */}
        {connectedNodes?.length > 0 &&
          selects.map((_, index) => {
            return (
              <React.Fragment key={uuidv4()}>
                <Select
                  parentRef={parentRef}
                  connectedNodes={connectedNodes}
                  operations={operations}
                  index={index}
                  isLast={index + 1 === selects.length}
                  handleDelete={() => handleDeleteItem(index)}
                  dataNodes={dataNodes}
                />
              </React.Fragment>
            );
          })}
        <button
          type="button"
          onClick={() =>
            setSelects(prevState => [...new Array(prevState.length + 1)])
          }
        >
          MAIS
        </button>
      </Container>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <button type="button" onClick={refresh}>
          RECARREGAR
        </button>
        <button type="button" onClick={handleGetResult}>
          SALVAR
        </button>
      </div>
      <Handle
        id="head"
        type="target"
        position="top"
        style={{
          width: "10px",
          height: "10px",
          background: "#7b867e",
          bottom: "-5px",
        }}
      />
      <Handle
        id="tail"
        type="source"
        position="bottom"
        style={{
          width: "10px",
          height: "10px",
          background: "#0e7c34",
          bottom: "-5px",
        }}
      />
    </>
  );
};

export default RunUntil;
