export const hasCertificate = ({ value, isList, date }) => {
  let textLabel = "";
  let days = "";
  let active = false;
  let tooltipShow = false;
  if (!isList) {
    if (value && value.toString().substr(0, 1) !== `-` && date) {
      textLabel = "Seu certificado venceu tem:";
      active = false;
      days = `-${value}`;
    } else if (value && value.toString().substr(0, 1) === `-` && date) {
      textLabel = `Seu certificado vence em:`;
      active = true;
      days = value.toString().substr(1);
    } else if (value && value === 0 && !date) {
      textLabel = `Seu certificado vence em:`;
      active = false;
      days = "Sem Certi...";
    } else {
      textLabel = `Seu certificado vence em:`;
      active = true;
      days = value;
    }
  } else if (isList && value) {
    if (value.toString().substr(0, 1) === `-` && date) {
      days = value.toString().substr(1);
    } else if (value !== 0 && date) {
      days = `-${value}`;
    } else if (value === 0 && !date) {
      days = "Sem Certi...";
    } else {
      days = value;
    }
  } else {
    days = "Sem Cert...";
    tooltipShow = true;
  }

  return {
    textLabel,
    days,
    active,
    tooltipShow,
  };
};
