import React, { useState, useEffect, useRef } from "react";

import { DataGrid, ptBR } from "@material-ui/data-grid";

import { useProductsContext } from "context/ProductsContext";
import { treatFilter, setSessionProductFilter } from "utils";

import { CustomRowsOverlayLoading, CustomNoRowsOverlay } from "../NoRows";

import { SelectPages } from "../SelectsPages";
import { FilterNew } from "../../ProductForm/FIlters";

import { COLLUNS } from "./Columns";

import * as S from "../ProductList.styles";

export const ProductListingDialog = ({
  isShowBtnAdd = true,
  isShowColumns = true,
  isShowDensity = true,
  setModel,
  modelSelect,
  handleSelected,
  isShowSelectRow,
  isUnicSelected,
  action,
}) => {
  const ref = useRef();

  const {
    rowsDialog,
    isLoading,
    handleExistentRow,
    searchText,
    handleNewList,
  } = useProductsContext({});

  const [paramsSearching, setParamsSearching] = useState([]);
  const [filterModel, setFilterModel] = useState({ items: [] });

  const handleItem = (filters = []) => {
    setSessionProductFilter(filters);
    const filterItems = treatFilter(filters);
    setParamsSearching([...filterItems]);
    return filterItems;
  };

  useEffect(() => {
    if (paramsSearching) {
      handleNewList([...paramsSearching]);
    }
  }, [paramsSearching, handleNewList]);

  return (
    <S.DataGridCustom>
      <FilterNew
        action={action}
        columns={COLLUNS}
        isShowBtnAdd={false}
        handleFilter={handleItem}
      />

      <DataGrid
        ref={ref}
        rows={rowsDialog || []}
        columns={COLLUNS}
        loading={isLoading}
        components={{
          LoadingOverlay: CustomRowsOverlayLoading,
          NoResultsOverlay: CustomNoRowsOverlay,
          NoRowsOverlay: CustomNoRowsOverlay,
          Pagination: SelectPages,
        }}
        componentsProps={{
          toolbar: {
            isShowBtn: isShowBtnAdd,
            isShowColumns,
            isShowDensity,
            value: searchText,
            onChange: event => handleExistentRow(event.target.value),
            clearSearch: () => handleExistentRow(""),
          },
        }}
        getRowClassName={params =>
          `super-app-theme--${params.getValue(params.id, "status")}`
        }
        pageSize={100}
        getRowId={row => row.id}
        rowsPerPageOptions={[100]}
        filterModel={filterModel}
        disableSelectionOnClick={!!isUnicSelected}
        onFilterModelChange={model => setFilterModel(model)}
        checkboxSelection={isShowSelectRow}
        onSelectionModelChange={ids => {
          const selectedIDs = new Set(ids);
          const selectedItem = rowsDialog.filter(item =>
            selectedIDs.has(item?._id.toString()),
          );
          setModel(ids);
          handleSelected(selectedItem);
        }}
        selectionModel={modelSelect}
        localization={{
          body: {
            localeText: ptBR,
          },
        }}
      />
    </S.DataGridCustom>
  );
};
