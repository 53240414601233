import React from "react";
import moment from "moment";
import { Alert } from "components/structure";
import { WHATSAPP_STATUS_COMPLEX } from "constants/types";

const texts = {
  success: props =>
    `Conectado na data ${
      props?.connectedDate && moment(props?.connectedDate).format("DD/MM/YYYY")
    } às ${
      props?.connectedDate && moment(props?.connectedDate).format("HH:mm:ss")
    }`,
  warning: props =>
    `Foi gerado o qrcode no data ${
      props?.qrCodeDate && moment(props?.qrCodeDate).format("DD/MM/YYYY")
    } às ${props?.qrCodeDate && moment(props?.qrCodeDate).format("HH:mm:ss")}`,
};

export const WhatsAppStatusAlert = ({ status, data = {} }) => {
  const statusInternal = WHATSAPP_STATUS_COMPLEX[status];
  return (
    <>
      <Alert
        title="Alerta"
        type={statusInternal?.status}
        content={
          texts[statusInternal?.status] && texts[statusInternal?.status](data)
        }
      />
    </>
  );
};
