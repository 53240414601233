import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useProductsContext } from "context/ProductsContext";
import { Datatable, Tooltip } from "@mix/mixfiscal-designsystem";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faFolderPlus,
  faBoxesStacked,
} from "@fortawesome/free-solid-svg-icons";

import { Button } from "components/structure";

import Card from "components/UI/Card";

import { CustomNoRowsOverlay } from "../../ProductList/NoRows";

import { ProductForm } from "./Form";
import { TooltipCustom } from "./Tooltip";

import * as S from "./Form.styles";

const removeButton = (
  item,
  index,
  handleRemove,
  isShowGenerator,
  setIsShowDialog,
) => {
  const onClick = () => handleRemove(item, index);

  return (
    <>
      <S.ActionBtn>
        <S.Button color="#FD5962" type="button" onClick={onClick}>
          <FontAwesomeIcon
            icon={faTrash}
            style={{
              fontSize: "15px",
            }}
          />
        </S.Button>
        {isShowGenerator && (
          <>
            {!item?.id_prod ? (
              <Tooltip
                body="Selecionar produto"
                position="right-end"
                zIndex={2}
                zIndexBackdrop={1}
              >
                <S.Button
                  color="#adc86a"
                  type="button"
                  onClick={() => {
                    setIsShowDialog({
                      show: true,
                      type: "negativo",
                      labelBtn: "Vincular",
                      isUnicSelected: false,
                      key: index,
                    });
                  }}
                >
                  <FontAwesomeIcon
                    icon={faFolderPlus}
                    style={{
                      fontSize: "15px",
                    }}
                  />
                </S.Button>
              </Tooltip>
            ) : (
              <Tooltip
                component={<TooltipCustom index={index} product={item} />}
                position="right-end"
                zIndex={2}
                zIndexBackdrop={1}
              >
                <S.Button
                  color="#0A4F91"
                  type="button"
                  onClick={() => {
                    setIsShowDialog({
                      show: true,
                      type: "negativo",
                      labelBtn: "Vincular",
                      isUnicSelected: false,
                      key: index,
                    });
                  }}
                >
                  <FontAwesomeIcon
                    icon={faBoxesStacked}
                    style={{
                      fontSize: "15px",
                    }}
                  />
                </S.Button>
              </Tooltip>
            )}
          </>
        )}
      </S.ActionBtn>
    </>
  );
};

export const List = ({
  list,
  handleRemove,
  isShowSelected = false,
  selecteds,
  setSelecteds,
  errorTrace,
  isShowGenerator = false,
}) => {
  const { setIsShowDialog } = useProductsContext();

  return (
    <>
      {list && list.length > 0 ? (
        <S.ListCardItem>
          <Datatable
            pagination={false}
            columns={[
              { field: "action", title: "" },
              { field: "prod_nome", title: "Nome do produto" },
              { field: "prod_codigo_barras", title: "EAN (Código de barras)" },
              { field: "type", title: "" },
            ]}
            onSelectionChange={selected =>
              isShowSelected ? setSelecteds(selected) : ``
            }
            selectableRows={isShowSelected}
            selectableRowsAll
            selectedItems={isShowSelected ? selecteds : []}
            rawData={(list || []).map((item, index) => ({
              id: item,
              action: removeButton(
                item,
                index,
                handleRemove,
                isShowGenerator,
                setIsShowDialog,
              ),
              prod_nome: item.prod_nome,
              prod_codigo_barras: item.prod_codigo_barras,
            }))}
          />
        </S.ListCardItem>
      ) : (
        <CustomNoRowsOverlay margin="20px" title={errorTrace} />
      )}
    </>
  );
};

export const ProductListPositivoNegativo = ({
  title,
  color,
  ean,
  setEan,
  name,
  setName,
  list,
  handleAdd,
  handleRemove,
  type,
  isShowBtn = false,
  labelBtn,
  errorTrace,
  isShowGenerator,
}) => {
  const { setIsShowDialog } = useProductsContext();
  const idElement = uuidv4();

  const handleOpenDialog = () => {
    setIsShowDialog({
      show: true,
      type,
      labelBtn,
    });
  };

  const ActionButton = () => {
    return (
      <>
        {isShowBtn && (
          <Button type="button" onClick={() => handleOpenDialog()}>
            AGRUPAR
          </Button>
        )}
      </>
    );
  };

  return (
    <>
      <Card title={title} headerColor={color} action={<ActionButton />}>
        <ProductForm
          ean={ean}
          setEan={setEan}
          name={name}
          setName={setName}
          handleAdd={handleAdd}
          title={title}
        />
        <List
          idElement={idElement}
          errorTrace={errorTrace}
          list={list}
          handleRemove={handleRemove}
          isShowGenerator={isShowGenerator}
        />
      </Card>
    </>
  );
};

export const ProductLisAgrupados = ({
  title,
  color,
  list,
  setList,
  type,
  isShowSelected,
  handleRemove,
  errorTrace,
  isShowGenerator,
}) => {
  const { setIsShowDialog, toogleFilter, handleNewList } = useProductsContext();
  const [selecteds, setSelecteds] = useState([]);

  const idElement = uuidv4();

  const handleOpenDialog = () => {
    setIsShowDialog({
      show: true,
      type,
      labelBtn: "Agrupar",
    });
  };

  const handleRemoveSelected = () => {
    const checkExistent = selecteds.map(item => item._id);

    const newList = list
      .filter(item => !checkExistent.includes(item?._id))
      .map(item => ({
        _id: item?._id,
        prod_nome: item?.prod_nome,
        prod_codigo_barras: item?.prod_codigo_barras,
      }));
    setList(newList);
  };

  const ActionButton = () => {
    return (
      <>
        <S.DisplayActionFlex>
          <Button
            type="button"
            onClick={() => {
              handleOpenDialog();
              toogleFilter();
              handleNewList([]);
            }}
          >
            AGRUPAR
          </Button>
        </S.DisplayActionFlex>
      </>
    );
  };
  return (
    <>
      <Card title={title} headerColor={color} action={<ActionButton />}>
        {isShowSelected && list.length > 0 && selecteds.length > 0 && (
          <Button
            style={{
              width: "100px",
              backgroundColor: "#FD5962",
            }}
            type="button"
            onClick={() => handleRemoveSelected()}
          >
            remove
          </Button>
        )}
        <List
          selecteds={selecteds}
          setSelecteds={setSelecteds}
          isShowSelected={isShowSelected}
          idElement={idElement}
          errorTrace={errorTrace}
          list={list}
          handleRemove={handleRemove}
          isShowGenerator={isShowGenerator}
        />
      </Card>
    </>
  );
};
