import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faBoxArchive,
  faFileArchive,
  faClipboardCheck,
  faListCheck,
  faPenRuler,
  faChartBar,
  faUserGear,
  faCloudArrowUp,
  faLink,
  faCommentDots,
  faCartShopping,
  faCodeBranch,
  faPeopleRoof,
  faTimeline,
} from "@fortawesome/free-solid-svg-icons";

export const MENU_MAIN_TYPES = {
  DASHBOARD: "dashboard",
  GERENCIAMENTO_PRODUTOS: "gerenciarproduto",
  MONITORAMENTO_DOCUMENTOS_FISCAIS: "monitoramentodocumentosfiscais",
  INTELIGENCIADE_FORNECEDORES: "inteligenciadefornecedores",
  PAGINAS_INTELIGENTES: "paginasinteligentes",
  CHECKLINK: "checklink",
  CLASSIFICACAO_FISCAL: "classificacaofiscal",
  REPORT: "reporter",
  CONFIGURACOES_LOJA: "configuracoesloja",
  CARTEIRA_CLIENTE: "carteira-clientes",
  UPLOAD: "upload",
  INTEGRACOES: "integracoes",
  MARKETPLACE: "marketplace",
  SUPORTE: "suporte",
  ATRIBUTOS_GRADE: "atributosgrade",
  DIAGNOSTICO_FISCAL: "diagnosticofiscal",
  USUARIOS_PERMISSIOES: "usersAndPermissions",
  CORRECAO_PRODUTOS: "products",
  ASSOCIACAO: "associacao",
};

export const MENU_PATHS = {
  GRADE: "grid-manager",
  CHECKLINK: "check-link",
  GERENCIAMENTO_PRODUTOS: "produtos",
  RELATORIOS: "relatorios",
  LEIS: "leis",
  PAGINAS_INTELIGENTES: "paginas-inteligentes",
  USUARIOS_PERMISSIOES: "usuarios-e-configs",
  NFE: "nfe",
  FORNECEDORES: "fornecedores",
  DIAGNOSTICO_FISCAL: "diagnosticos",
  CLASSIFICACAO_FISCAL: "classificacao-fiscal",
  ASSESSORES: "assessores",
};

export const MENU_MAIN_TYPES_COMERCIAL = {
  DASHBOARD_COMERCIAL: "dashboard_comercial",
  FORNECEDORES: "fornecedores",
  CHECKLINK_COMERCIAL: "checklink_comercial",
};
// Menus principais
export const MENUS_MAIN = [
  {
    name: MENU_MAIN_TYPES.DASHBOARD, // Nome do menu
    title: "Dashboard", // Titulo do mainMenu
    hasSubMenu: false, // Se o menu possui um submenu
    linkTo: "/", // Link para ser enviado quando o menu for clicado
    slug: "dashboard",
    position: 1,
    icon: (
      <FontAwesomeIcon
        icon={faHome}
        size="lg"
        style={{
          fontSize: "1.5rem",
        }}
        color="#fff"
      />
    ), // Icone que vai ser utilizado no menu
  },
  {
    name: MENU_MAIN_TYPES.GERENCIAMENTO_PRODUTOS,
    title: "Gerenciamento de Produtos",
    hasSubMenu: true,
    slug: "product-management",
    position: 2,
    icon: (
      <FontAwesomeIcon
        icon={faBoxArchive}
        style={{
          fontSize: "1.5rem",
        }}
        color="#fff"
      />
    ),
  },
  {
    name: MENU_MAIN_TYPES.CORRECAO_PRODUTOS,
    title: "Padronização de Produtos",
    hasSubMenu: false,
    linkTo: "/products",
    slug: "product-production",
    position: 2,
    icon: (
      <FontAwesomeIcon
        icon={faBoxArchive}
        style={{
          fontSize: "1.5rem",
        }}
        color="#fff"
      />
    ),
  },
  {
    name: MENU_MAIN_TYPES.MONITORAMENTO_DOCUMENTOS_FISCAIS,
    title: "Monitoramento de Documentos Fiscais",
    slug: "monitoring-tax-documents",
    hasSubMenu: true,
    position: 3,
    icon: (
      <FontAwesomeIcon
        icon={faFileArchive}
        style={{
          fontSize: "1.5rem",
        }}
        color="#fff"
      />
    ),
  },

  {
    name: MENU_MAIN_TYPES.CHECKLINK,
    title: "CheckLink",
    slug: "check-link",
    hasSubMenu: true,
    link: `/${MENU_PATHS.CHECKLINK}`,
    position: 4,
    icon: (
      <FontAwesomeIcon
        icon={faListCheck}
        size="lg"
        style={{
          fontSize: "1.5rem",
        }}
        color="#fff"
      />
    ),
  },
  {
    name: MENU_MAIN_TYPES.CLASSIFICACAO_FISCAL,
    title: "Classificação Fiscal",
    hasSubMenu: true,
    slug: "tax-classification",
    link: `/${MENU_PATHS.GRADE}`,
    position: 5,
    icon: (
      <FontAwesomeIcon
        icon={faClipboardCheck}
        size="lg"
        style={{
          fontSize: "1.5rem",
        }}
        color="#fff"
      />
    ),
    // icon: <Icon name="datalake" size={30} color="#fff" />,
  },
  {
    name: MENU_MAIN_TYPES.DIAGNOSTICO_FISCAL, // Nome do menu
    title: "Diagnóstico Fiscal", // Titulo do mainMenu
    hasSubMenu: true, // Se o menu possui um submenu]
    position: 6,
    slug: "tax-diagnosis",
    icon: (
      <FontAwesomeIcon
        icon={faCodeBranch}
        size="lg"
        style={{
          fontSize: "1.5rem",
        }}
        color="#fff"
      />
    ), // Icone que vai ser utilizado no menu
  },
  {
    name: MENU_MAIN_TYPES.PAGINAS_INTELIGENTES,
    title: "Páginas Inteligentes",
    hasSubMenu: true,
    position: 7,
    slug: "smart-pages",
    link: `/${MENU_PATHS.PAGINAS_INTELIGENTES}`,
    icon: (
      <FontAwesomeIcon
        icon={faPenRuler}
        size="lg"
        style={{
          fontSize: "1.5rem",
        }}
        color="#fff"
      />
    ),
  },
  {
    name: MENU_MAIN_TYPES.REPORT,
    title: "Relatórios Inteligentes",
    hasSubMenu: true,
    slug: "reports-smart",
    position: 8,
    icon: (
      <FontAwesomeIcon
        icon={faChartBar}
        size="lg"
        style={{
          fontSize: "1.5rem",
        }}
        color="#fff"
      />
    ),
  },
  {
    name: MENU_MAIN_TYPES.DASHBOARD, // Nome do menu
    title: "Painel do Assessor",
    hasSubMenu: false,
    linkTo: "/painel-do-assessor",
    slug: "painel-assessor",
    position: 9,
    icon: (
      <FontAwesomeIcon
        icon={faPeopleRoof}
        size="lg"
        style={{
          fontSize: "1.5rem",
        }}
        color="#fff"
      />
    ),
  },
  {
    name: MENU_MAIN_TYPES.ASSOCIACAO, // Nome do menu
    title: "Associação",
    hasSubMenu: false,
    linkTo: "/associacao",
    slug: "associacao",
    position: 10,
    icon: (
      <FontAwesomeIcon
        icon={faTimeline}
        size="lg"
        style={{
          fontSize: "1.5rem",
        }}
        color="#fff"
      />
    ),
  },
  {
    name: MENU_MAIN_TYPES.USUARIOS_PERMISSIOES,
    title: "Configurações",
    hasSubMenu: true,
    slug: "users-permissions",
    position: 11,
    icon: (
      <FontAwesomeIcon
        icon={faUserGear}
        size="lg"
        style={{
          fontSize: "1.5rem",
        }}
        color="#fff"
      />
    ),
  },
  {
    name: MENU_MAIN_TYPES.UPLOAD,
    title: "Arquivos",
    hasSubMenu: false,
    position: 12,
    slug: "upload",
    icon: (
      <FontAwesomeIcon
        icon={faCloudArrowUp}
        size="lg"
        style={{
          fontSize: "1.5rem",
        }}
        color="#fff"
      />
    ),
  },
  {
    name: MENU_MAIN_TYPES.INTEGRACOES,
    title: "Integrações",
    hasSubMenu: false,
    position: 13,
    slug: "integration",
    icon: (
      <FontAwesomeIcon
        icon={faLink}
        size="lg"
        style={{
          fontSize: "1.5rem",
        }}
        color="#fff"
      />
    ),
  },
  {
    name: MENU_MAIN_TYPES.MARKETPLACE,
    title: "Marketplace",
    hasSubMenu: false,
    position: 14,
    slug: "marketplace",
    icon: (
      <FontAwesomeIcon
        icon={faCartShopping}
        size="lg"
        style={{
          fontSize: "1.5rem",
        }}
        color="#fff"
      />
    ),
    linkTo: "/404",
  },
  {
    name: MENU_MAIN_TYPES.SUPORTE,
    title: "Suporte",
    hasSubMenu: true,
    position: 15,
    slug: "suport",
    icon: (
      <FontAwesomeIcon
        icon={faCommentDots}
        size="lg"
        style={{
          fontSize: "1.5rem",
        }}
        color="#fff"
      />
    ),
  },
];
