import styled, { css } from "styled-components";
import NotFoundImage from "../../../../assets/error-404.png";

export const PrimaryContainer = styled.div`
  transition: all 0.3s;
  background-color: transparent;
  width: ${props => (props.isSecondaryActive ? "0%" : "100%")};

  ${props =>
    props.isSecondaryActive &&
    css`
      display: none;
    `}

  ${props =>
    props.isSecondaryActive &&
    css`
      & {
        p {
          display: none;
        }
      }
    `}
`;

export const SecondaryContainer = styled.div`
  transition: all 0.3s;
  width: ${props => (props.isActive ? "100%" : "0px")};
  overflow: ${props => (props.isActive ? "visible" : "hidden")};
`;

export const Title = styled.button`
  background-color: rgba(0, 0, 0, 0.2);
  font-weight: bold;
  color: #fff;
  display: flex;
  text-transform: capitalize;
  padding: 16px 5px;
  text-align: center;
  font-size: 14px;
  line-height: 1.5;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0);
  text-indent: -9999999px;

  &:before {
    content: "Voltar";
    display: block;
    width: 100%;
    text-indent: 0;
  }
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  cursor: pointer;
  position: relative;
  transition: all 250ms;
  border: 1px solid rgba(0, 0, 0, 0);

  p {
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    white-space: nowrap;
    position: absolute;
    opacity: 0;
    left: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
    padding: 0 15px;
    pointer-events: none;
    box-shadow: 4px 0 5px rgba(0, 0, 0, 0.16);
    transition: all 250ms;
    transform: translateX(-40px);
    border: 1px solid rgba(0, 0, 0, 0);
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.2);

    p {
      filter: none;
      opacity: 1;
      backdrop-filter: blur(2px);
      border-radius: 0 5px 5px 0;
      border: 1px solid rgba(0, 0, 0, 0.1);
      transform: translateX(0);
      pointer-events: initial;
    }

    svg {
      filter: none;
    }

    ${props =>
      props.hasSecondary &&
      css`
        ::after {
          content: "";
          width: 0;
          height: 0;
          border-top: 7px solid transparent;
          border-bottom: 7px solid transparent;
          border-left: 7px solid transparent;
          position: absolute;
          top: 50%;
          right: 0;
          transform: translate(-50%, -50%);
        }
      `}
  }

  ${props =>
    props.isSelected &&
    css`
      & {
        background-color: rgba(255, 255, 255, 0.1);
        p {
          color: #fff;
        }

        svg {
          fill: #fff;
        }
      }
    `}
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;

  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 30px;
    display: none;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 30px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.5);
  }

  ${ItemContainer} {
    &:hover {
      p {
        background: ${props =>
          props.activeTheme
            ? "rgba(47, 88, 122, 0.6)"
            : "rgba(59, 120, 59, 0.6)"};
      }
    }
  }

  &.active {
    overflow-y: scroll;

    ${ItemContainer} {
      p {
        position: static;
        opacity: 1;
        background: transparent;
        box-shadow: 0 0 0;
        text-transform: capitalize;
        white-space: normal;
        transform: initial;
        border: 1px solid rgba(0, 0, 0, 0);
        padding: 0;
        backdrop-filter: initial;
      }
    }

    ${Title} {
      text-indent: 0;
      text-align: center;
      justify-content: center;
      align-items: center;
      flex: 1;

      &:before {
        content: " < ";
        width: auto;
        display: inline-block;
        margin-right: 2px;
        font-size: 18px;
        font-family: cursive;
        position: relative;
        bottom: 2px;
      }
    }
  }
`;

export const NotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 10px;

  p {
    font-weight: bold;
    color: #fff;
    font-size: 12px;
  }
`;

export const NotFoundIcon = styled.img.attrs(props => ({
  ...props,
  src: NotFoundImage,
}))`
  opacity: 0.4;
`;
