import React from "react";
import { Tooltip } from "@mix/mixfiscal-designsystem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { TextField } from "components/form";

import { ischeckIfAndNumber } from "utils";

import * as S from "../../ProductForm.styles";

export const ProductForm = ({
  title,
  name,
  setName,
  ean,
  setEan,
  handleAdd,
}) => {
  const handleAddItem = (value, value2) => {
    handleAdd(value, value2);
  };
  return (
    <>
      <S.ColumnFormItem isPaddingInput="8px">
        <S.ColumnFormItemSub>
          <TextField
            name="activeName"
            label="Nome do produto"
            helperText="Nome do produto"
            maxlength={13}
            onChange={e => {
              setName(e.target.value);
            }}
          />
        </S.ColumnFormItemSub>
        <S.ColumnFormItemSub>
          <TextField
            type="number"
            name="prod_codigo_barras"
            label="EAN (Código de barras do produto)"
            helperText="EAN"
            maxlength="10"
            onChange={e => {
              if (ischeckIfAndNumber(e.target.value)) {
                setEan(e.target.value);
              }
            }}
          />
        </S.ColumnFormItemSub>
        <Tooltip
          body={`Adicionar a ${title}`}
          position="left-end"
          zIndex={2}
          zIndexBackdrop={1}
        >
          <S.Button
            margin="25px 0 0 0"
            type="button"
            color="#adc86a"
            onClick={() => handleAddItem(name, ean)}
          >
            <FontAwesomeIcon
              icon={faPlus}
              style={{
                fontSize: "15px",
              }}
            />
          </S.Button>
        </Tooltip>
      </S.ColumnFormItem>
    </>
  );
};
