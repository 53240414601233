import React, { useState, useEffect } from "react";
import Slide from "@material-ui/core/Slide";
import { IoClose, IoTimer } from "react-icons/io5";
import { Checkbox } from "@mix/mixfiscal-designsystem";
import moment from "moment";
import {
  Wrapper,
  Backgroud,
  Container,
  Close,
  Title,
  SubTitle,
  TextAreaContainer,
  TitleSession,
  SubTasks,
  TimerWrapper,
  ButtonWrapper,
  Button,
} from "./styles";

function TaskDetails({ isOpen, template, taskIndex, onClose, onSave }) {
  const [task, setTask] = useState({});
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    setTask(template?.tasks && template?.tasks[taskIndex]);
  }, [template, taskIndex]);

  useEffect(() => {
    if (task?.startDate) {
      const endTimer = task?.endDate ? moment(task?.endDate) : moment();
      setTimer(endTimer.diff(moment(task?.startDate)) / 1000);
    }
  }, [task?.startDate, task?.endDate]);

  useEffect(() => {
    if (task?.startDate && !task?.endDate) {
      setTimeout(() => setTimer(timer + 1), 1000);
    }
  }, [timer, task?.startDate, task?.endDate]);

  const toHHMMSS = sec => {
    const secNum = parseInt(sec, 10);
    let hours = Math.floor(secNum / 3600);
    let minutes = Math.floor((secNum - hours * 3600) / 60);
    let seconds = secNum - hours * 3600 - minutes * 60;

    if (hours < 10) hours = `0${hours}`;
    if (minutes < 10) minutes = `0${minutes}`;
    if (seconds < 10) seconds = `0${seconds}`;

    return `${hours}:${minutes}:${seconds}`;
  };

  const handlerSave = () => {
    const newTasks = [...template.tasks];
    newTasks[taskIndex] = task;

    const newTemplate = {
      ...template,
      tasks: [...newTasks],
    };

    setTask({});
    setTimer(0);

    onSave(newTemplate);
  };

  return (
    <>
      {isOpen && (
        <Wrapper>
          <Backgroud />
          <Slide direction="left" in={isOpen} mountOnEnter unmountOnExit>
            <Container>
              <Close
                onClick={() => {
                  setTask({});
                  setTimer(0);
                  onClose();
                }}
              >
                <IoClose />
              </Close>
              <Title>{task?.title}</Title>
              <SubTitle>{template?.name}</SubTitle>
              <TextAreaContainer>{task?.description}</TextAreaContainer>

              <TitleSession>
                <h1>Sub-tarefas</h1>
              </TitleSession>

              <SubTasks>
                {task?.subtasks?.map((subtask, index) => (
                  <label>
                    <div style={{ pointerEvents: task?.endDate && "none" }}>
                      <Checkbox
                        size={25}
                        handleChange={isCheckedValue => {
                          const t = { ...task };
                          t.subtasks[index].status = isCheckedValue
                            ? "DONE"
                            : "PENDING";
                          setTask(t);
                        }}
                        value={subtask.status === "DONE"}
                      />
                    </div>
                    {subtask.title}
                  </label>
                ))}
              </SubTasks>

              <TitleSession>
                <h1>Gerenciamento de tempo</h1>
              </TitleSession>

              <TimerWrapper>
                <IoTimer />
                <p>{toHHMMSS(timer)}</p>
                <Button
                  disabled={task?.startDate}
                  onClick={() => setTask({ ...task, startDate: new Date() })}
                >
                  INICIAR CONTAGEM
                </Button>
                <Button
                  disabled={task?.endDate || !task?.startDate}
                  onClick={() =>
                    setTask({ ...task, endDate: new Date(), status: "DONE" })
                  }
                >
                  CONCLUIR
                </Button>
              </TimerWrapper>

              <TitleSession>
                <h1>Comentário</h1>
              </TitleSession>
              <TextAreaContainer>
                <textarea
                  placeholder="Destrição de atividade realizadas"
                  onChange={e => setTask({ ...task, comment: e.target.value })}
                  value={task?.comment}
                />
              </TextAreaContainer>
              <ButtonWrapper>
                <Button onClick={handlerSave}>Salvar</Button>
              </ButtonWrapper>
            </Container>
          </Slide>
        </Wrapper>
      )}
    </>
  );
}

export default TaskDetails;
