import React from "react";
import { useTheme } from "hooks";
import { useWatch } from "react-hook-form";
import { Card, Icon, Tag, Tooltip } from "@mix/mixfiscal-designsystem";
import {
  useCestsItems,
  useCestsSegments,
  useCestsSegmentsItems,
} from "useCases/cest";
import { Select } from "components/form";
import { ButtonAction } from "components/structure";

import * as S from "./CardTableCest.styles";

const headerColorGray = "#e5e5e5";
const textColorGray = "#304156";

const getCestFirstNumbers = value => value.replace(/\./g, "").substr(0, 5);

export const CardTableCest = ({
  control,
  handleAdd,
  handleClear,
  cests = [],
}) => {
  const { colors } = useTheme();

  const segmento = useWatch({ control, name: "segmento" });
  const item = useWatch({ control, name: "item" });
  const cest = useWatch({ control, name: "especificacao" });

  const { data: dataSegments } = useCestsSegments();

  const { data: dataSegmentsItens, isLoading: isLoadingSegment } =
    useCestsSegmentsItems(segmento?.value);

  const { data: dataItems, isLoading: isLoadingCest } = useCestsItems({
    cest: getCestFirstNumbers(item?.value ?? ""),
  });

  const handleAddBtn = () =>
    handleAdd({
      cests: [...cests, ...[{ code: cest?.value }]],
    });

  const handleRemoveBtn = () =>
    handleClear({
      cests: [],
    });

  return (
    <Card
      className="card-custom"
      handleCtaClick={() => {}}
      hasElevation
      headerColor={headerColorGray}
      textColor={textColorGray}
      title="TABELA CEST"
    >
      <div className="row">
        <S.Container>
          <Select
            name="segmento"
            data={dataSegments?.segments?.map(segment => ({
              value: segment._id,
              label: segment.name,
            }))}
            label="Segmento"
            control={control}
          />
          <Select
            isDisabled={isLoadingSegment}
            placeholder={
              isLoadingSegment && segmento ? "Carregando..." : "Selecione"
            }
            name="item"
            label="Item"
            data={dataSegmentsItens?.data?.map(iten => ({
              value: iten.cest,
              label: `${iten.cest} - ${iten.description}`,
            }))}
            control={control}
          />

          <S.ContainerPlus>
            <Select
              isDisabled={isLoadingSegment || isLoadingCest}
              name="especificacao"
              label="Especificação"
              placeholder={
                isLoadingCest && segmento && item
                  ? "Carregando..."
                  : "Selecione"
              }
              data={dataItems?.data?.map(especification => ({
                value: especification.cest,
                label: `${especification.cest} - ${especification.description}`,
              }))}
              control={control}
            />
            <S.Action>
              <Tooltip
                body="Adicionar cest ao filtro"
                position="top"
                zIndex={1000}
              >
                <ButtonAction
                  onClick={handleAddBtn}
                  disabled={isLoadingSegment || isLoadingCest}
                >
                  <Icon color={colors.lightGreen} name="adicionar" size={32} />
                </ButtonAction>
              </Tooltip>
            </S.Action>
          </S.ContainerPlus>

          <S.RowTagsName>
            {cests?.map(cestV => (
              <Tag label={cestV?.code} key={cestV?.code} type="simple" />
            ))}
            {cests?.length > 0 && (
              <S.ActionRemove>
                <Tooltip body="Remover Cests" position="top" zIndex={1000}>
                  <ButtonAction onClick={handleRemoveBtn}>
                    <Icon color="#000" name="fechar1" size={18} />
                  </ButtonAction>
                </Tooltip>
              </S.ActionRemove>
            )}
          </S.RowTagsName>
        </S.Container>
      </div>
    </Card>
  );
};
