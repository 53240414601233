/* eslint-disable react/jsx-wrap-multilines */
import React, { useState } from "react";
import {
  Alert,
  Button,
  DialogConfirm,
  ButtonOpenNewTag,
  Container,
  CopyLink,
} from "components/structure";

import { LAWS } from "constants/routes";
import { STATUS_GRADES, LAW_STATUS } from "constants/types";
import { getURLWithOrigin } from "utils/context";

import * as S from "./LawGradeActions.styles";

const dialogs = {
  remove: "Remover",
  clone: "Clonar",
  archive: "Arquivar",
  active: "Ativar",
  inactive: "Desativar",
  recovery: "Restaurar",
};

const InfoClone = ({ clone }) => (
  <Container isFlex>
    <span>Link de acesso</span>
    <S.InfoCloneLink>
      <CopyLink link={getURLWithOrigin(LAWS.DETAIL(clone._id))} />
    </S.InfoCloneLink>
    <ButtonOpenNewTag
      openNewAba
      toLink={LAWS.DETAIL(clone._id)}
      title="Abrir detalhe da grade"
    />
  </Container>
);

export const LawGradeActions = ({
  grade,
  law = {},
  onSendArchive,
  onSendRemove,
  onSendInactive,
  onSendRecovery,
  onSendClone = () => {},
  onSendActive = () => {},
  onChange = () => {},
}) => {
  const [dialogConfirm, setDialogConfirm] = useState(null);

  const handleConfirm = () => {
    const typesActions = {
      archive: onSendArchive,
      remove: onSendRemove,
      clone: onSendClone,
      active: onSendActive,
      inactive: onSendInactive,
      recovery: onSendRecovery,
    };

    if (typesActions[dialogConfirm]) {
      typesActions[dialogConfirm]().then(res => {
        if (onChange) {
          onChange({ type: dialogConfirm, response: res });
        }
      });
    }

    setDialogConfirm(null);
  };

  const handleClickConfirm = type => () => setDialogConfirm(type);

  const handleActionCancel = () => setDialogConfirm(null);

  const hasGradeConflito = grade?.status === STATUS_GRADES.CONFLITO;

  const hasGradePreGrade = grade?.status === STATUS_GRADES.PRE_GRADE;

  const hasGradeArchived = grade?.status === STATUS_GRADES.ARQUIVADA;

  const hasLawDeleted = law?.status === LAW_STATUS.DELETED;

  return (
    <>
      <DialogConfirm
        isOpen={Boolean(dialogConfirm)}
        handleConfirm={handleConfirm}
        handleCancel={handleActionCancel}
        text={
          dialogConfirm &&
          `Deseja realmente ${dialogs[dialogConfirm]} essa Lei/Grade ?`
        }
      />

      {grade?.alq?.length >= 2 && hasGradeConflito && !hasGradeArchived && (
        <Alert
          title="Alerta"
          content="Essa Lei/Grade possuí conflito de grades!"
          subContent="Ao salvar vai gerar 2 grades separadas"
        />
      )}
      {hasGradePreGrade && (
        <Alert
          type="info"
          title="Alerta"
          content="Essa grade esta salva como Pre-grade"
        />
      )}
      {hasGradeArchived && (
        <Alert
          type="info"
          title="Informativo"
          content="Essa Grade/Lei esta arquivada"
        />
      )}

      {law?.status === LAW_STATUS.CLONE && (
        <Alert
          type="warning"
          title="Alerta"
          content="Essa Lei/Grade foi clonada por favor antes de utilizar ela ative!"
        />
      )}

      {law?.status === LAW_STATUS.INACTIVE && (
        <Alert
          type="info"
          title="Alerta"
          content="Essa Lei/Grade esta inativa!"
        />
      )}

      {law?.status === LAW_STATUS.DRAFT && (
        <Alert
          type="warning"
          title="Informativo"
          content={
            <>
              {"Essa Lei/Grade esta salva como "}
              <b>rascunho</b>
            </>
          }
        />
      )}

      {hasLawDeleted && (
        <Alert
          type="danger"
          title="Alerta"
          content={
            <>
              {"Essa Lei/Grade foi "}
              <b>DELETADA</b>
            </>
          }
        />
      )}

      {law?.clone?._id && (
        <Alert
          type="info"
          title="Alerta Lei/Grade Clonada"
          content={<InfoClone clone={law.clone} />}
        />
      )}
      <S.Container>
        {hasGradeConflito && (
          <Button type="button" onClick={handleClickConfirm("archive")}>
            Arquivar
          </Button>
        )}
        {hasGradePreGrade && (
          <Button type="button" color="darkGreen" onClick={onSendActive}>
            Ativar
          </Button>
        )}

        {!hasGradeConflito && grade?.id_law && !hasLawDeleted && (
          <Button type="button" onClick={handleClickConfirm("clone")}>
            Clonar
          </Button>
        )}

        {law.status !== LAW_STATUS.ACTIVE && !hasLawDeleted && (
          <Button
            type="button"
            color="darkGreen"
            onClick={handleClickConfirm("active")}
          >
            Ativar
          </Button>
        )}

        {law.status === LAW_STATUS.ACTIVE && (
          <Button
            type="button"
            color="gray"
            scale="500"
            onClick={handleClickConfirm("inactive")}
          >
            Desativar
          </Button>
        )}
        {hasLawDeleted && (
          <Button
            type="button"
            color="gray"
            scale="500"
            onClick={handleClickConfirm("recovery")}
          >
            Restaurar
          </Button>
        )}

        {!hasGradeConflito && !hasLawDeleted && (
          <Button
            isDisabled={hasGradeArchived}
            type="button"
            color="secondary"
            onClick={handleClickConfirm("remove")}
          >
            Remover
          </Button>
        )}
      </S.Container>
    </>
  );
};
