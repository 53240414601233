import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 94%;

  .minimap {
    bottom: 80px;
  }
`;

export const FloatActionsContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 80px;
  z-index: 10000;

  margin: 24px;
`;

export const FloatFlowActionsContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10000;
  justify-content: space-between;
  width: 10%;

  margin: 24px;

  & > button:not(:last-of-type) {
    margin-right: 8px;
  }
`;
