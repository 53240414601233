import React from "react";
import { Card } from "@mix/mixfiscal-designsystem";

import { ListProducts } from "../ResultsProducts/ListProducts";
import Dialog from "../../../UI/Dialog";
import * as S from "./RemoveProductsModal.styles";

export const RemoveProductsModal = ({
  isOpen = false,
  handleClose,
  handleConfirm,
  productsSelecteds = [],
}) => {
  return (
    <Dialog
      isOpen={isOpen}
      title="Remover produtos dessa grade ?"
      handleClose={handleClose}
      handleConfirm={handleConfirm}
    >
      <S.ModalContent>
        <Card
          headerColor="#E5E5E5"
          textColor="#304156"
          title="Produtos"
          className="flatCard"
        >
          <ListProducts
            data={productsSelecteds.productsMove}
            isAction={false}
          />
        </Card>
      </S.ModalContent>
    </Dialog>
  );
};
