import React, { forwardRef } from "react";
import * as S from "./styles";

export const Checkbox = forwardRef(
  ({ name, label, disabled, ...rest }, ref) => (
    <S.Label disabled={disabled}>
      {label}
      <S.Input
        type="checkbox"
        disabled={disabled}
        {...rest}
        name={name}
        {...(ref && ref(name))}
      />
      <S.CheckSpan />
    </S.Label>
  ),
);
