import React, { useEffect } from "react";

import { TextField } from "components/form";
import { useGetCep } from "../../../useCases/cep";

import * as S from "./cepForm.styles";

export const CepForm = ({ control, setValue }) => {
  const { data: dataCep, fetch: search } = useGetCep();

  const getCep = value => {
    if (value.length === 8) {
      search(value);
    }
    return null;
  };

  useEffect(() => {
    if (dataCep) {
      setValue("district", dataCep?.bairro);
      setValue("state", dataCep?.uf);
      setValue("city", dataCep?.localidade);
      setValue("address", dataCep?.logradouro);
      setValue("complement", dataCep?.complemento);
    }
  }, [dataCep, setValue]);

  return (
    <>
      <S.ColumnFormItem>
        <TextField
          label="Cep"
          name="zipcode"
          onChange={e => {
            const { value } = e.target;
            setValue("zipcode", value);
            getCep(value);
          }}
          control={control}
        />
        <TextField label="Bairro" name="district" control={control} />
        <TextField label="UF" name="state" control={control} />
        <TextField label="Cidade" name="city" control={control} />
      </S.ColumnFormItem>

      <S.ColumnFormItem>
        <TextField label="Endereço" name="address" control={control} />
        <TextField label="Número" name="number" control={control} />
      </S.ColumnFormItem>
      <S.ColumnFormItem>
        <TextField label="Complemento" name="complement" control={control} />
      </S.ColumnFormItem>
    </>
  );
};
