import React from "react";

import * as S from "./Preload.styles";

export const Preload = ({ label }) => {
  return (
    <S.PreloadContainer>
      <S.PreloadItem>
        <S.PreloadAnimatiom />
        <h3>{label}</h3>
      </S.PreloadItem>
    </S.PreloadContainer>
  );
};
