import React, { createContext, useContext, useMemo } from "react";
import { usePermissions } from "context/PermissionsContext";
import { getPermissionsByModules, getModulesOnList } from "utils/context/acl";
import { useModules } from "useCases";

const ACLContext = createContext({});

export const useACL = slug => {
  const context = useContext(ACLContext);

  if (slug) {
    return {
      module: context?.getModule ? context?.getModule(slug) : false,
      hasCreate: context?.hasCreate ? context?.hasCreate(slug) : false,
      hasShow: context?.hasShow ? context?.hasShow(slug) : false,
      hasRemove: context?.hasRemove ? context?.hasRemove(slug) : false,
      hasEdit: context?.hasEdit ? context?.hasEdit(slug) : false,
    };
  }

  if (!context)
    throw new Error(
      "useACL precisa ser utilizado dentro de um PermissionsProvider",
    );
  return context;
};

export const ACLProvider = ({ children }) => {
  const { selectedGroup } = usePermissions();
  const { data: dataModules } = useModules();

  const modulesAggregates = useMemo(
    () =>
      getPermissionsByModules(
        dataModules?.modules ?? [],
        selectedGroup?.modules ?? [],
      ),
    [dataModules?.modules, selectedGroup?.modules],
  );

  const modules = useMemo(
    () => getModulesOnList(modulesAggregates),
    [modulesAggregates],
  );

  const getModule = slug =>
    modules.find(m => m.slug === slug || m.moduleId === slug);
  const hasModule = slug => Boolean(getModule(slug));
  const hasEdit = slug => getModule(slug)?.rules?.put;
  const hasRemove = slug => getModule(slug)?.rules?.delete;
  const hasCreate = slug => getModule(slug)?.rules?.post;
  const hasShow = slug => getModule(slug)?.rules?.get;

  return (
    <ACLContext.Provider
      value={{
        modules,
        getModule,
        hasModule,
        hasEdit,
        hasRemove,
        hasCreate,
        hasShow,
        modulesAggregates,
      }}
    >
      {children}
    </ACLContext.Provider>
  );
};
