import React from "react";
import { useHistory } from "react-router-dom";
import { getURLWithParams } from "utils/context";
import * as S from "./styles";

export const ListResultsAmostration = ({ totalLeis, gradesTotal, params }) => {
  const { push } = useHistory();
  return (
    <S.Container>
      <S.Table onClick={() => push(getURLWithParams("/grid-manager", params))}>
        <tr>
          <S.Th>Leis</S.Th>
          <S.Th>Grades</S.Th>
        </tr>
        <tr>
          <S.Td>
            <strong>{totalLeis ?? "-"}</strong>
          </S.Td>
          <S.Td>
            <strong>{gradesTotal ?? "-"}</strong>
          </S.Td>
        </tr>
      </S.Table>
    </S.Container>
  );
};
