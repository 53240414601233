import React, { useState, useEffect } from "react";
import { IoAdd } from "react-icons/io5";
import { useForm } from "react-hook-form";
import { Tag } from "@mix/mixfiscal-designsystem";

import authenticationApi from "../../../services/authenticationApi";
import { useRegisterLaws } from "../../../hooks/useRegisterLaws";
import { CardWithLateralHeader, InputContainer, Button } from "../styles";

function cfopMapToSelect(response) {
  return response?.data?.data.map(item => ({
    label: `${item.codigo} - ${item.descricao}`,
    value: item._id,
    codigo: item.codigo,
  }));
}

async function getCfop() {
  try {
    const res = await authenticationApi.get(`/cfop?tipo=Entrada`);

    return cfopMapToSelect(res);
  } catch (e) {
    // chamar toast
  }
}

const CfopEntrada = () => {
  const { values, setComplexValue, setValues } = useRegisterLaws();
  const { register, handleSubmit, reset } = useForm();
  const [cfops, setCfops] = useState([]);

  useEffect(() => {
    getCfop().then(data => setCfops(data));
  }, []);

  function handleNewCfopEntrada(cfop) {
    if (cfop.data !== "Selecione") {
      setComplexValue("cfopsEntrada", cfop.data);
      reset({});
    }
  }

  function deleteCfopEntrada(cfop) {
    const newCfops = values?.cfopsEntrada?.filter(ex => ex !== cfop);

    setValues({ ...values, cfopsEntrada: newCfops });
  }

  return (
    <CardWithLateralHeader>
      <header>CFOP ENTRADA</header>
      <main>
        <form onSubmit={handleSubmit(handleNewCfopEntrada)}>
          <div className="input-container">
            <InputContainer maxWidth="100%">
              <label>Segmento</label>
              <select {...register("data")}>
                <option value="Selecione">Selecione</option>
                {cfops?.map(cfop => (
                  <option
                    key={cfop.value}
                    value={cfop.value}
                    title={cfop.label}
                  >
                    {String(cfop.label).length > 50
                      ? `${String(cfop.label).substring(0, 50)}...`
                      : cfop.label}
                  </option>
                ))}
              </select>
            </InputContainer>
            <div>
              <Button type="submit">
                <IoAdd />
              </Button>
            </div>
          </div>
        </form>
        <div className="tag-container">
          {values?.cfopsEntrada?.map(cfop => (
            <div className="tag-item" key={cfop}>
              <Tag
                type="advanced"
                label={cfops?.find(c => c.value === cfop)?.codigo}
                handleDelete={() => deleteCfopEntrada(cfop)}
              />
            </div>
          ))}
        </div>
      </main>
    </CardWithLateralHeader>
  );
};

export default CfopEntrada;
