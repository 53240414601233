import React, { useState, useEffect } from "react";
import {
  useWalletStore,
  useWalletStoreRemove,
  useUsersU,
  useStoreCreateWallet,
} from "useCases";
import { useStoreContext } from "context/StoreContext";
import { AssessorList } from "./AssessorList";
import { Search } from "./AssessorSearch";

import * as S from "./Assessor.styles";

export const Assessor = ({ id }) => {
  const [totalUsersSearch, setTotalUsersSearch] = useState();
  const [listSearch, setListSearch] = useState([]);
  const [search, setSearch] = useState(null);
  const [page, setPage] = useState(1);

  const { listSelectedUsersWallet, setListSelectedUsersWallet } =
    useStoreContext();

  const { fetch: saveWallet } = useStoreCreateWallet();

  const { onRemove } = useWalletStoreRemove();

  const { data, isLoading } = useWalletStore(id);

  const { data: dataSearch, isLoading: isLoadingSearch } = useUsersU({
    page,
    limit: 10,
    search,
  });

  const handleSearch = value => {
    setSearch(value);
  };

  useEffect(() => {
    if (dataSearch && dataSearch?.users?.docs.length > 0) {
      setListSearch(dataSearch?.users?.docs.map(item => item));
      setTotalUsersSearch(dataSearch?.users?.totalDocs);
    }
  }, [dataSearch]);

  useEffect(() => {
    if (data) {
      setListSelectedUsersWallet(data.map(item => item));
    }
  }, [data, setListSelectedUsersWallet]);

  const handleAdd = user => {
    const listS = listSearch.filter(item => item._id !== user._id);
    setListSelectedUsersWallet([...listSelectedUsersWallet, user]);
    if (id) {
      saveWallet({
        id_user: user?._id,
        id_store: id,
      });
    }
    setListSearch([...listS]);
  };

  const handleRemove = (_id, value) => {
    if (id) {
      const newList = listSelectedUsersWallet.filter(item => item?._id !== _id);
      setListSelectedUsersWallet([...newList]);
      onRemove(_id);
    }
    const newList = listSelectedUsersWallet.filter(
      (item, index) => index !== value,
    );
    setListSelectedUsersWallet([...newList]);
  };

  const handleClear = () => {
    setListSearch([]);
    setSearch();
    setPage(1);
    setTotalUsersSearch(0);
  };

  return (
    <>
      <Search
        handleSearch={handleSearch}
        totalItems={totalUsersSearch}
        isLoadingSearch={isLoadingSearch}
        handleClear={handleClear}
      />
      {listSearch.length > 0 && (
        <>
          <S.TitleAssessores>
            {`Buscando novo assessor por: ${search}`}
          </S.TitleAssessores>
          <AssessorList
            isAdd
            page={page}
            setPage={setPage}
            data={listSearch}
            handleAdd={handleAdd}
            isLoading={isLoadingSearch}
            totalDocs={totalUsersSearch}
          />
        </>
      )}
      {listSelectedUsersWallet.length > 0 && (
        <>
          <S.TitleAssessores>Assessores dessa loja</S.TitleAssessores>
          <AssessorList
            data={listSelectedUsersWallet}
            handleRemove={handleRemove}
            isDelete
            isLoading={isLoading}
          />
        </>
      )}
    </>
  );
};
