import styled, { css } from "styled-components";

const tagWrapperModifiers = {
  isVencida: () => css`
    & > div,
    & > div:hover {
      background-color: red;
      svg {
        fill: red;
      }
    }
  `,
};

export const TagContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
`;

export const TagWrapper = styled.div`
  ${({ isVencida }) => css`
    ${isVencida && tagWrapperModifiers.isVencida()}
  `}
`;

export const Field = styled.div`
  display: flex;
`;

export const FieldLabel = styled.label`
  font-weight: bold;
  margin-right: 8px;
  font-size: 16px;
`;

export const FieldValue = styled.div``;

export const Title = styled.p`
  width: 460px;
  border-bottom: 0;
`;

export const ContainerTooltip = styled.div`
  display: block;
  padding: 8px;
`;
