import React from "react";
import { Controller } from "react-hook-form";
import { Switch as DsSwitch } from "@mix/mixfiscal-designsystem";

import * as S from "./Switch.styles";

export const Switch = ({
  name,
  control,
  label,
  helperText,
  error,
  ...props
}) => {
  if (control) {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <S.InputFieldContainer>
            <DsSwitch
              checked={value}
              handleChangeCallback={v =>
                onChange({ target: { name, value: v } })
              }
              label={label}
              {...props}
            />
          </S.InputFieldContainer>
        )}
      />
    );
  }

  const { value, onChange } = props;

  return (
    <S.InputFieldContainer>
      <DsSwitch
        checked={value}
        handleChangeCallback={v =>
          onChange ? onChange({ target: { name, value: v } }) : null
        }
        label={label}
      />
    </S.InputFieldContainer>
  );
};
