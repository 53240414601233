import styled from "styled-components";

export const Wrapper = styled.div`
  table {
    thead {
      th {
        padding: 5px 8px !important;
      }
      .headerCell {
        [role="button"] {
          font-size: 0.9rem;
        }
        svg {
          display: none;
        }
      }
    }
    tbody tr td {
      font-size: 0.9rem;
    }
  }
`;

export const Title = styled.p`
  width: 460px;
  border-bottom: 0;
`;

export const Footer = styled.div`
  border-top: 1px solid #ccc;
  justify-content: space-between;
  display: flex;
`;

export const FooterContainer = styled.div`
  justify-content: space-between;
  display: flex;
  padding: 0px 10px;
  width: 100%;
`;

export const TextBold = styled.b``;
