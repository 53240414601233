import React from "react";
import { useForm } from "react-hook-form";
import { Select } from "components/form";
import { Card, ButtonAdd, Tags } from "components/structure";

import { useRegisterLaws } from "hooks/useRegisterLaws";
import { useNcmEx } from "useCases/ncm";
import * as S from "./LawNcmEx.styles";

export const LawNcmEx = () => {
  const { values, setComplexValue, setValues } = useRegisterLaws();
  const { handleSubmit, reset, control } = useForm();

  const { ncmExMap: ncmEx } = useNcmEx({ ncms: values?.ncms });

  const handleNewNcmEx = excessao => {
    if (excessao?.data?.label !== "Selecione") {
      setComplexValue("ncmEx", excessao?.data?.value);
      reset({});
    }
  };

  const deleteNcmEx = excessao => {
    const newNcmEx = values?.ncmEx?.filter(ex => ex !== excessao);

    setValues({ ...values, ncmEx: newNcmEx });
  };

  return (
    <form onSubmit={handleSubmit(handleNewNcmEx)}>
      <Card type="lateral" title="EXCEÇÃO NCM" height="90px">
        <S.Container>
          <S.InputContainer>
            <S.SelectWrapper>
              <Select
                data={ncmEx}
                control={control}
                name="data"
                label="Exceção NCM"
              />
            </S.SelectWrapper>
            <S.PlusBtn>
              <ButtonAdd type="submit" />
            </S.PlusBtn>
          </S.InputContainer>

          <Tags
            name="excessao"
            handleDelete={deleteNcmEx}
            data={values?.ncmEx ?? values?.excecoesNcm ?? []}
            labelRender={item =>
              `${ncmEx.find(ex => ex.value === item)?.label}`
            }
          />
        </S.Container>
      </Card>
    </form>
  );
};
