import styled from "styled-components";

export const Container = styled.div`
  background-color: #fff;
  width: 250px;
  border-radius: 5px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;

  & select {
    width: 200px;
    height: 25px;
    margin: 8px 0;

    border: none;
    border-bottom: 1px solid #596778;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & select.operations {
    border: 1px solid #596778;
    border-radius: 5px;
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  label {
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
  }

  input {
    width: 200px;
    height: 25px;
    margin: 0 0 8px 0;

    border: none;
    border-bottom: 1px solid #596778;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    font-size: 13px;
    background-color: buttonface;
  }

  .checkbox-preview {
    width: 100%;
    display: flex;
    flex-direction: row;
    max-width: 200px;

    input.checkbox {
      width: 15px;
    }

    input {
      margin: 0 4px;
      background-color: #fff;
    }

    label {
      padding-left: 5px;
      width: 100%;
    }

    svg {
      cursor: pointer;
    }
  }
`;

export const HeaderContainer = styled.div`
  width: 100%;
  background-color: #f6b000;

  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;

  & > div {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  p {
    color: #fff;
    text-transform: uppercase;
  }

  svg {
    position: relative;
    z-index: 2;
    cursor: pointer;
  }
`;
