import React, { lazy, Suspense } from "react";
import { Switch, Route as ReactRouterDomRoute } from "react-router-dom";
import * as ROUTES from "constants/routes";
import { MENU_PATHS } from "constants/menu";
import { Private } from "components/contexts/routes";
import TemplateEditor from "pages/TemplateEditor";
import Dashboard from "pages/Dashboard";
import Login from "pages/Login";
import AdvancedEditor from "pages/AdvancedEditor";
import Fornecedores from "pages/Fornecedores";
import Report from "pages/Report";
import ReportView from "pages/Report/view";
import { UsersAllConfigs } from "pages/UsersAllConfigs";

import Profile from "pages/Profile";
import {
  CheckLinkDashboard,
  CheckLinkCreateUpdate,
  CheckLinkTasks,
} from "pages/CheckLink/pages";
import { NotFound } from "pages/NotFound";
import { LawsManager } from "pages/Laws/List";
import { AssessoresCrud, AssessoresList } from "pages/Assessores";

import { NcmsCollectionsList, NcmCrud } from "pages/Classificacao/Ncms";
import { CestList, CestCrud } from "pages/Classificacao/Cests";

import NotasFiscais from "pages/NotasFiscais";
import NotasFiscaisTax from "pages/NotasFiscais/taxnote";
import { Association } from "pages/Association";
import { StatusService } from "pages/StatusService";
import Produtos from "pages/Produtos";
import { ProdutosList, ProductCreateAndUpdate } from "pages/Produtos/Products";
import { SpedDiagnostic } from "pages/SpedsDiagnostic";
import { Invite } from "pages/Invite";
import MyTemplates from "pages/MyTemplates";

import Laws from "pages/Laws";
import { LawGradeDetail } from "pages/Laws/LawsGrade";
import { Grid } from "pages/Laws/Grid";
import { LawBulkUpdate } from "pages/Laws/BulkUpdate";

import { ContactClient } from "pages/ContactClient";
import { AbcReport } from "pages/Reports/AbcReport";
import { OportunidadeNegociacao } from "pages/Reports/OportunidadeNegociacao";
import { ObservacoesReport } from "pages/Reports/ObservacoesReport";
import { StoreConfigParams } from "pages/Stores/StoresConfigParams/StoreConfigParams";
import { ErrorBoundarySpace } from "components/structure/ErrorBoundarySpace/ErrorBoundarySpace";
import { RobotPage } from "pages/Robot";

import { Route } from "./Routes";

const DiagnosticoXmlReport = lazy(() => import("pages/Reports/DiagnosticoXml"));

const LawNormList = lazy(() =>
  import("pages/Classificacao/LawNorms/LawNormsList"),
);

const LawNormCrud = lazy(() =>
  import("pages/Classificacao/LawNorms/Crud/LawNormCrud"),
);

const LazyLoad = ({ component: Component }) => (
  <Suspense fallback={<div>carregando</div>}>
    <Component />
  </Suspense>
);

const Routes = () => (
  <Switch>
    <Route path="/login" component={Login} />
    <Route path="/invite" component={Invite} />
    <ReactRouterDomRoute path="/">
      <Private>
        <Switch>
          <Route path="/" exact component={Dashboard} isPrivate />
          <Route path="/editor" exact component={TemplateEditor} isPrivate />
          <Route
            path="/diagnosticos/xml-relatorio"
            exact
            component={() => <LazyLoad component={DiagnosticoXmlReport} />}
            isPrivate
          />
          <Route
            path="/diagnosticos/pis"
            exact
            component={SpedDiagnostic}
            isPrivate
          />
          <Route
            path="/editor-avancado"
            exact
            component={AdvancedEditor}
            isPrivate
          />
          <Route isPrivate path="/relatorio/abc" component={AbcReport} />
          <Route
            isPrivate
            path="/relatorio/oportunidade-negociacao"
            component={OportunidadeNegociacao}
          />
          <Route
            isPrivate
            path="/relatorio/observacoes"
            component={ObservacoesReport}
          />
          <Route isPrivate path="/status-servicos" component={StatusService} />
          <Route
            path="/relatorio/visualizacao/:id"
            exact
            component={ReportView}
            isPrivate
          />
          <Route
            path={["/relatorio", "/relatorio/:id"]}
            exact
            component={Report}
            isPrivate
          />
          <Route path="/perfil" exact component={Profile} isPrivate />
          <Route
            exact
            path={`/${MENU_PATHS.USUARIOS_PERMISSIOES}/config-params`}
            component={StoreConfigParams}
            isPrivate
          />
          <Route
            exact
            path="/painel-do-assessor"
            component={ContactClient}
            isPrivate
          />

          <Route
            exact
            path="/assessores/:id"
            component={AssessoresCrud}
            isPrivate
          />

          <Route
            exact
            path="/assessores"
            component={AssessoresList}
            isPrivate
          />
          {/* <Route
         
          <Route
            path={[
              `/usuarios-e-permissoes`,
              `/usuarios-e-permissoes/criar-usuario`,
              `/usuarios-e-permissoes/criar-usuario/:id`,
              `/usuarios-e-permissoes/criar-grupo`,
              `/usuarios-e-permissoes/criar-grupo/:id`,
              `/usuarios-e-permissoes/criar-chaves-api`,
              `/usuarios-e-permissoes/criar-chaves-api/:id`,
              `/usuarios-e-permissoes/criar-loja`,
              `/usuarios-e-permissoes/criar-loja/:id`,
            ]}
            exact
            component={UsersAndPermissions}
            isPrivate
          /> */}
          <Route
            path={[
              // TODO: USUÁRIOS
              `/usuarios-e-configs`,
              `/usuarios-e-configs/convidar-usuario`,

              // TODO: GRUPO
              `/usuarios-e-configs/grupos-de-acesso`,
              `/usuarios-e-configs/grupos-de-acesso/:id`,

              // TODO: LOJA
              `/usuarios-e-configs/lojas`,
              `/usuarios-e-configs/lojas/:id`,

              // TODO: API KEY
              `/usuarios-e-configs/chaves-api`,
              `/usuarios-e-configs/chaves-api/:id`,
              `/usuarios-e-configs/:id`,
            ]}
            exact
            component={UsersAllConfigs}
            isPrivate
          />
          <Route isPrivate path="/grid-manager" component={LawsManager} />
          {/* NCMS */}
          <Route
            isPrivate
            path={ROUTES.LAWS.BULK_UPDATE}
            component={LawBulkUpdate}
          />
          <Route isPrivate path={ROUTES.NCMS.CREATE} component={NcmCrud} />
          <Route isPrivate path={ROUTES.NCMS.DETAIL()} component={NcmCrud} />
          <Route
            isPrivate
            path={ROUTES.NCMS.BASE}
            component={NcmsCollectionsList}
          />

          <Route
            isPrivate
            path={ROUTES.NORMS.CREATE}
            component={() => <LazyLoad component={LawNormCrud} />}
          />
          <Route
            isPrivate
            path={ROUTES.NORMS.DETAIL()}
            component={() => <LazyLoad component={LawNormCrud} />}
          />
          <Route
            isPrivate
            path={ROUTES.NORMS.BASE}
            component={() => <LazyLoad component={LawNormList} />}
          />
          {/* NCMS */}
          {/* CESTS */}
          <Route isPrivate path={ROUTES.CESTS.CREATE} component={CestCrud} />
          <Route isPrivate path={ROUTES.CESTS.DETAIL()} component={CestCrud} />
          <Route isPrivate path={ROUTES.CESTS.BASE} component={CestList} />
          {/* CESTS */}

          <Route
            isPrivate
            path="/check-link/tasks"
            component={CheckLinkTasks}
          />
          <Route
            isPrivate
            path="/check-link/test"
            component={CheckLinkCreateUpdate}
          />
          <Route
            isPrivate
            path="/check-link/:id"
            component={CheckLinkCreateUpdate}
          />
          <Route isPrivate path="/check-link" component={CheckLinkDashboard} />
          <Route isPrivate path="/nfe" component={NotasFiscais} />
          <Route isPrivate path="/associacao" component={Association} />
          <Route
            isPrivate
            path="/notas-fiscais-tax"
            component={NotasFiscaisTax}
          />
          <Route isPrivate path="/produtos" component={Produtos} />
          <Route isPrivate path="/products" component={ProdutosList} />
          <Route
            isPrivate
            path="/product/new"
            component={ProductCreateAndUpdate}
          />
          <Route
            isPrivate
            path="/product/detail/:id"
            component={ProductCreateAndUpdate}
          />
          {/* <Route
            path={[
              // TODO: PRODUTOS
              `/products`,
              `/products/new`,
              `/usuarios-e-configs/lojas/:id`,
            ]}
            exact
            component={Products}
            isPrivate
          /> */}
          <Route isPrivate path="/fornecedores" component={Fornecedores} />
          <Route
            isPrivate
            path="/paginas-inteligentes"
            component={MyTemplates}
          />
          <Route isPrivate path="/mytemplates" component={MyTemplates} />

          <Route isPrivate path={ROUTES.LAWS.CREATE} component={Laws} />
          <Route isPrivate path={ROUTES.LAWS.DETAIL()} component={Laws} />
          <Route
            isPrivate
            path={ROUTES.LAWS.CREATE_NEW}
            component={LawGradeDetail}
          />

          <Route
            isPrivate
            path={ROUTES.GRID_LAWS.DETAIL()}
            component={LawGradeDetail}
          />
          <Route isPrivate path={ROUTES.GRID_LAWS.BASE} component={Grid} />

          <Route isPrivate path="/space" component={ErrorBoundarySpace} />
          <Route isPrivate path="/robot" component={RobotPage} />
          <Route isPrivate path="*" component={NotFound} />
        </Switch>
      </Private>
    </ReactRouterDomRoute>
  </Switch>
);
export default Routes;
