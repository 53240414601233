import React from "react";
import { Card } from "@mix/mixfiscal-designsystem";
import { Button } from "components/structure";
import { NcmsCollectionsProvider } from "context/NcmsCollections";
import { List } from "components/contexts/cests";

import { CESTS } from "constants/routes";
import * as S from "./styles";

export const CestList = () => {
  return (
    <NcmsCollectionsProvider>
      <S.Header />
      <S.Container>
        <S.ContainerResult>
          <Card headerColor="#5A8DEE" textColor="#fff" title="RESULTADOS">
            <S.ContainerHeader>
              <S.ContainerHeaderAction>
                <Button label="Novo CEST" toLink={CESTS.CREATE} />
              </S.ContainerHeaderAction>
            </S.ContainerHeader>
            <List />
          </Card>
        </S.ContainerResult>
      </S.Container>
    </NcmsCollectionsProvider>
  );
};
