export default {
  primary: "#ADC86A",
  gray: "#596778",
  lightGreen: "#ADC86A",
  lightGreenHover: "#96b548",
  darkGreen: "#256925",
  navyBlue: "#17456B",
  navyBlueHover: "#143E60",
  white: "#fff",
  gray50: "#F2F4F4",
  gray200: "#E5E5E5",
  gray500: "#8c8b8b",
  slateGray: "#596778",
  blueInfo: "#5A8DEE",
  red: "#da0f0f",
  grayscale: {
    text: "#747474",
  },
  icon: {
    green: "#45924F",
    navyBlue: "#17456B",
  },
  checkbox: {
    selected: "#1b66b0",
  },
  card: {
    header: {
      default: "#5A8DEE",
      green: "#45924F",
      greenDarken: "#256925",
      blueDarken: "#59aec4",
      suspended: "#F2F4F4",
    },
  },
};
