import React, { useEffect } from "react";
import { useRegisterLaws } from "hooks/useRegisterLaws";
import { LoadPlaceholder } from "components/structure";

import authenticationApi from "services/authenticationApi";

import { calcCarga, hasAliq, hasReducaoBase } from "utils/context/laws";

import { InputContainer } from "pages/Laws/styles";
import Mocks from "pages/Laws/mocks";

const mocks = new Mocks();
const cst = mocks.getCst();

const hasAliqByCst = ({ icms, cstSaida }) =>
  (!icms && !["01", "02", "03"].includes(cstSaida)) ||
  (icms &&
    ![
      "00",
      "10",
      "20",
      "70",
      "90",
      "101",
      "103",
      "201",
      "202",
      "203",
      "300",
      "400",
    ].includes(cstSaida));

export const AttributesContainerSaida = () => {
  const { values, setSimpleValue, setValues } = useRegisterLaws();

  useEffect(() => {
    async function getAliquotasIcms() {
      try {
        const res = await authenticationApi.get("/laws/aliquotas/icms");

        if (values.taxa?.icms) {
          const completeSnecery = values.cenarios.find(
            s => s.ufOrigem && s.ufDestino,
          );

          if (completeSnecery) {
            const aliq = res.data.data.find(
              a => a.UF === completeSnecery.ufOrigem,
            );
            if (aliq) {
              const aliqValue = aliq[completeSnecery.ufDestino];
              setValues({
                ...values,
                aliqEntrada: aliqValue,
                aliqSaida: aliqValue,
              });
            }
          } else {
            setValues({
              ...values,
              aliqEntrada: 0,
              aliqSaida: 0,
            });
          }
        }
      } catch (e) {
        // chamar toast
      }
    }

    getAliquotasIcms();
  }, [values.cenarios, values.taxa, setValues, values]);

  function getOptionsCst(tax, type) {
    if (tax === "pis")
      return cst.pis[type].map(c => (
        <option key={c.value} value={c.value} title={`${c.value} - ${c.label}`}>
          {`${c.value} - ${c.label}`.length > 50
            ? `${`${c.value} - ${c.label}`.substring(0, 50)}...`
            : `${c.value} - ${c.label}`}
        </option>
      ));
    return cst.icms[type].map(c => (
      <option key={c.value} value={c.value} title={`${c.value} - ${c.label}`}>
        {`${c.value} - ${c.label}`.length > 50
          ? `${`${c.value} - ${c.label}`.substring(0, 50)}...`
          : `${c.value} - ${c.label}`}
      </option>
    ));
  }

  const hasPisCofins = values?.taxa?.pis || values?.taxa?.cofins;

  function sceneryExists(type) {
    let exists = false;
    if (type === "entrada") {
      const idx = values.cenarios?.findIndex(s => s.ufOrigem);

      if (idx !== -1) {
        exists = true;
      }
    } else {
      const idx = values.cenarios?.findIndex(s => s.ufDestino);

      if (idx !== -1) {
        exists = true;
      }
    }

    return exists;
  }

  function handleChangeCSTSaida(e) {
    if (!values.taxa?.icms && !["01", "02", "03"].includes(e.target.value)) {
      setValues({ ...values, aliqSaida: 0, cstSaida: e.target.value });
    } else if (
      values.taxa?.icms &&
      ![
        "00",
        "10",
        "20",
        "70",
        "90",
        "101",
        "103",
        "201",
        "202",
        "203",
        "300",
        "400",
      ].includes(e.target.value)
    ) {
      setValues({ ...values, aliqSaida: 0, cstSaida: e.target.value });
    } else {
      setSimpleValue("cstSaida", e.target.value);
    }
  }

  return (
    <LoadPlaceholder
      showNoData={!sceneryExists("saida")}
      textNoData="Grade não tem opção de saída!"
      styleBackDrop={{ backdropFilter: "blur(1px)" }}
      fontWeight="bold"
    >
      <div className="input-container">
        {sceneryExists("saida") && (
          <InputContainer maxWidth="220px">
            <label>CST Saída</label>
            <select value={values?.cstSaida} onChange={handleChangeCSTSaida}>
              <option value="Selecione">Selecione</option>
              {values.taxa?.icms
                ? getOptionsCst("icms", "saida")
                : getOptionsCst("pis", "saida")}
            </select>
          </InputContainer>
        )}
        {sceneryExists("saida") && hasAliq(values?.cstSaida) && (
          <InputContainer maxWidth="220px">
            <label>{`Alíquota Saída ${hasPisCofins ? "Pis" : ""}`}</label>
            <input
              type="text"
              onChange={e => setSimpleValue("aliqSaida", e.target.value)}
              value={values.aliqSaida || ""}
              disabled={hasAliqByCst({
                icms: values?.taxa?.icms,
                cstSaida: values?.cstSaida,
              })}
            />
          </InputContainer>
        )}
        {hasPisCofins && (
          <InputContainer maxWidth="220px">
            <label>Alíquota Saída Cofins</label>
            <input
              type="text"
              onChange={e => setSimpleValue("aliqSaidaCofins", e.target.value)}
              value={values.aliqSaidaCofins || ""}
              disabled={hasAliqByCst({
                icms: values?.taxa?.icms,
                cstSaida: values?.cstSaida,
              })}
            />
          </InputContainer>
        )}
        {values.taxa?.icms && hasReducaoBase(values.cstSaida) && (
          <>
            <InputContainer maxWidth="220px">
              <label>Redução de Base Cálculo</label>
              <input
                type="text"
                value={values?.reducaoBaseCalc}
                onChange={e =>
                  setSimpleValue("reducaoBaseCalc", e.target.value)
                }
              />
            </InputContainer>
            <InputContainer maxWidth="100px">
              <label>Carga</label>
              <input
                disabled
                type="text"
                value={calcCarga(values?.aliqSaida, values?.reducaoBaseCalc)}
              />
            </InputContainer>
          </>
        )}
      </div>
    </LoadPlaceholder>
  );
};
