import React, { useEffect, useState } from "react";
import { ResponsiveBar } from "@nivo/bar";
import { subMonths, lastDayOfMonth, format } from "date-fns";
import SalesBuyCard from "components/contexts/dashboard/card-buy-x-sales";
import { useAuth } from "context/AuthContext";
import { LoadPlaceholder } from "components/structure/Load";

import {
  useAnalyticsSalesMonths,
  useAnalyticsPurchasesMonths,
} from "useCases/dashboard";
import { getData } from "./services";

const renderTick = ({ value, tickIndex, x, y, textAnchor, textBaseline }) => {
  const resultado = tickIndex % 2 === 0;
  return (
    <g transform={`translate(${x},${!resultado ? 13 : y})`}>
      <text
        alignmentBaseline={textBaseline}
        style={{ fontSize: 11 }}
        textAnchor={textAnchor}
      >
        {value}
      </text>
    </g>
  );
};

function DashSalesPurchase({ monthDateStart, monthDateEnd, fileType = "XML" }) {
  const { tokenCompanies } = useAuth();
  const {
    data: dataSales,
    fetch: fetchSales,
    isLoading: isLoadingSale,
  } = useAnalyticsSalesMonths();

  const {
    data: dataPurchases,
    fetch: fetchPast,
    isLoading: isLoadingPurchases,
  } = useAnalyticsPurchasesMonths();

  const [salesPurchaseDateStart, setSalesPurchaseDateStart] =
    useState(monthDateStart);
  const [salesPurchaseDateEnd, setSalesPurchaseDateEnd] =
    useState(monthDateEnd);

  const [tag, setTag] = useState(fileType);
  const [linearValues, setLinearValues] = useState([]);
  const [hasDates, setHasDates] = useState(false);
  const [salesPurchases, setSalesPurchases] = useState([]);
  const [scaleData, setScaleData] = useState("");

  const tagType =
    tag === "Sped Contribuições"
      ? "contribuicoes"
      : tag === "Sped Fiscal"
      ? "fiscal"
      : "xml";

  const handleClick = async (start, end) => {
    setSalesPurchaseDateStart(start);
    setSalesPurchaseDateEnd(end);
  };

  const handleClickType = item => {
    setTag(item);
  };

  useEffect(() => {
    const data = {
      purchases: [],
      sales: [],
    };

    if (dataPurchases?.data?.data) {
      data.purchases = dataPurchases?.data?.data;
    }

    if (dataSales?.data?.data) {
      data.sales = dataSales?.data?.data;
    }
    const resp = getData(data);
    setSalesPurchases(resp?.salesPurchasesFormatedValue);
    setLinearValues(resp?.linearValues);
    setScaleData(resp?.scaleData);
  }, [dataPurchases?.data?.data, dataSales?.data?.data]);

  useEffect(() => {
    if (hasDates && salesPurchaseDateStart && salesPurchaseDateEnd) {
      const lastDayOfMonthValue =
        format(new Date(), "MM") === format(salesPurchaseDateStart, "MM")
          ? new Date()
          : lastDayOfMonth(salesPurchaseDateStart);

      const dateStart = format(
        subMonths(salesPurchaseDateStart, 12),
        "ddMMyyyy",
      );
      const dateEnd = format(lastDayOfMonthValue, "ddMMyyyy");
      fetchPast({
        dt_ini: dateStart,
        dt_fim: dateEnd,
        tipo: tagType,
      });

      fetchSales({
        dt_ini: dateStart,
        dt_fim: dateEnd,
        tipo: tagType,
      });
    }
  }, [
    salesPurchaseDateStart,
    salesPurchaseDateEnd,
    tag,
    hasDates,
    tokenCompanies,
    tagType,
    fetchPast,
    fetchSales,
  ]);

  useEffect(() => {
    setSalesPurchaseDateStart(monthDateStart);
    setSalesPurchaseDateEnd(monthDateEnd);
    if (monthDateEnd) setHasDates(true);
  }, [monthDateStart, monthDateEnd]);

  return (
    <>
      <SalesBuyCard
        invertDate
        title="Compras x Vendas"
        subTitle="Período 13 meses"
        typeList="month"
        tag={fileType}
        tagsList={[
          "XML",
          {
            dtStart: subMonths(salesPurchaseDateStart, 12),
            dtEnd: salesPurchaseDateStart,
          },
        ]}
        handleClick={(start, end) => handleClick(start, end, "compravenda")}
        handleClickType={item => handleClickType(item, "compravenda")}
      >
        <div style={{ height: "490px" }}>
          <LoadPlaceholder
            show={isLoadingSale || isLoadingPurchases}
            showNoData={
              !(isLoadingSale || isLoadingPurchases) &&
              salesPurchases.length === 0
            }
          >
            <ResponsiveBar
              data={salesPurchases}
              keys={["compras", "vendas"]}
              indexBy="mes"
              margin={{ top: 10, right: 60, bottom: 80, left: 60 }}
              padding={0.2}
              groupMode="grouped"
              valueScale={{ type: "linear" }}
              indexScale={{ type: "band", round: true }}
              valueFormat={{ format: "", enabled: false }}
              label=""
              colors={["#EDA366", "#A2C659", "red"]}
              defs={[
                {
                  id: "dots",
                  type: "patternDots",
                  background: "inherit",
                  color: "#38bcb2",
                  size: 4,
                  padding: 1,
                  stagger: true,
                },
                {
                  id: "lines",
                  type: "patternLines",
                  background: "inherit",
                  color: "#eed312",
                  rotation: -45,
                  lineWidth: 6,
                  spacing: 10,
                },
              ]}
              borderColor={{
                from: "color",
                modifiers: [["darker", 1.6]],
              }}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "meses",
                legendPosition: "middle",
                legendOffset: 42,
                renderTick,
              }}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: scaleData !== "" ? `Valor (${scaleData})` : "Valor",
                legendPosition: "middle",
                legendOffset: -55,
                tickValues: linearValues,
              }}
              labelSkipWidth={100}
              labelSkipHeight={12}
              labelTextColor={{
                from: "color",
                modifiers: [["darker", 1.6]],
              }}
              legends={[
                {
                  dataFrom: "keys",
                  anchor: "bottom-left",
                  direction: "row",
                  justify: false,
                  translateX: -15,
                  translateY: 95,
                  itemsSpacing: 2,
                  itemWidth: 100,
                  itemHeight: 50,
                  itemDirection: "left-to-right",
                  itemOpacity: 0.85,
                  symbolSize: 10,
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
              tooltip={e => {
                const title = e.id === "compras" ? "Compras" : "Vendas";
                const value =
                  e.id === "compras"
                    ? e.data.valueOrigCompras
                    : e.data.valueOrigVendas;
                return (
                  <div
                    style={{
                      fontSize: "14px",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        width: "10px",
                        height: "10px",
                        backgroundColor: `${e.color}`,
                        marginRight: "5px",
                      }}
                    />
                    <p>
                      {`${title}: ${value.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })}`}
                    </p>
                  </div>
                );
              }}
            />
          </LoadPlaceholder>
        </div>
      </SalesBuyCard>
    </>
  );
}

export default DashSalesPurchase;
