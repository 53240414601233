import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  .tableContainer {
    width: 100%;
    overflow: auto;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
  }

  tr {
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
  }

  tr.selected {
    background-color: lightblue;
  }

  th,
  td {
    padding: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 200px;
  }

  tr {
    th:first-of-type,
    td:first-of-type {
      border-left: 1px solid #ccc;
    }
  }

  tr {
    th:last-of-type,
    td:last-of-type {
      border-right: 1px solid #ccc;
    }
  }

  th {
    text-align: left;
  }

  th div.headerCell {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    max-width: 200px;

    & > div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;

export const FilterContainer = styled.div`
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 8px;

  input {
    border-bottom: 1px solid #596778;
  }

  button {
    background-color: transparent;
  }
`;
