import React from "react";
import { TablePagination } from "@material-ui/core";
import * as S from "./Pagination.styles";

export const Pagination = ({ ...props }) => (
  <S.Pagination>
    <TablePagination
      {...props}
      labelRowsPerPage="Linhas por página:"
      labelDisplayedRows={({ from, to, count }) =>
        `${from}–${to} de ${count !== -1 ? count : `mais do que ${to}`}`
      }
    />
  </S.Pagination>
);
