import styled, { css } from "styled-components";

const setItem = {
  setHeigth: height => css`
    height: ${height} !important;
    overflow: scroll;
  `,
};

const customList = {
  setType: item => css`
    ${item === `ativo` &&
    `
    background-color: #adc86a !important;
    color:#fff !important;
    `}
    ${item === `info` &&
    `
    background-color: #17456B  !important;
    color:#fff !important;
    `}
      ${item === `inativo` &&
    `
    background-color: #c86a6a  !important;
    color:#fff !important;
    `}
    ${item === `warning` &&
    `
    background-color: #d58f00  !important;
    color:#000;
    font-weight: bold;
    `}
  `,
};

export const ContainerRowItemContentListDescription = styled.div`
  ${({ height }) => setItem.setHeigth(height)}
`;

export const Table = styled.table`
  width: 100%;
`;

export const TableTr = styled.tr``;

export const TableTrTitle = styled.td`
  width: 50px;
  background-color: #f3f3f3;
`;

export const UlList = styled.ul`
  list-style: none;
`;
export const UlListSub = styled.ul`
  list-style: none;
  padding: 0 26px 5px 15px;
`;
export const UlListItem = styled.li`
  border-bottom: 1px solid #ebebeb;
  padding: 10px 0 10px 0;

  svg {
    color: #17456b;
    margin: 0 5px 0 10px;
  }

  &:hover {
    background-color: #ebebeb;
    border-bottom: 1px solid #fff;
  }
`;
export const UlListItemSub = styled.li`
  font-size: 12px;
  svg {
    font-size: 11px;
    color: #929292;
    margin: 0 5px 1px 0 !important;
  }
  &:hover {
    background-color: #ebebeb;
    svg {
      font-size: 11px;
      color: #17456b !important;
    }
  }
`;

export const UlListItemSubStatus = styled.li`
  ${({ status }) => css`
    font-size: 12px;
    padding-left: 10px;
    ${status && customList.setType(status)}
    border-radius: 20px;

    svg {
      font-size: 11px;
      color: #929292;
      margin: 0 5px 1px 0 !important;
    }
    &:hover {
      background-color: #ebebeb;
      svg {
        font-size: 11px;
        color: #17456b !important;
      }
    }
  `}
`;

export const ContainerRowItemNull = styled.div``;

export const UlListItemSubMargin = styled.li`
  margin: 10px 0 10px 0;
`;

export const UlListItemHeader = styled.div`
  display: flex;
`;
export const UlListItemHeaderDescription = styled.div`
  width: 90%;
`;
export const UlListItemHeaderIcon = styled.div`
  width: 10%;
  text-algin: right;
`;
