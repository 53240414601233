import React, { useState, useEffect } from "react";
import ReactFlow, {
  // removeElements,
  Background,
  // Controls,
  MiniMap,
  ReactFlowProvider,
} from "react-flow-renderer";
import { MdAdd, MdSave, MdRemoveRedEye } from "react-icons/md";
import { Tooltip } from "@mix/mixfiscal-designsystem";
// import templatesApi from "../../services/templatesApi";
import authenticationApi from "../../services/authenticationApi";
import { useFlow } from "../../context/FlowContext";

import AddNodeDialog from "./Dialogs/AddNodeDialog";
import SaveFlowDialog from "./Dialogs/SaveFlowDialog";
import GeneralPreview from "./Dialogs/GeneralPreview";
import SelectFileDialog from "./Dialogs/SelectFile";
import CustomNode from "./Nodes/NodeStructure";
import OperationNode from "./Nodes/LogicNodes/OperationNode";
import AdvancedOperationsNode from "./Nodes/LogicNodes/AdvancedOperationNode";
import CircleButton from "../UI/Button/CircleButton";
import PreviewDatatable from "../PreviewDatatable";
import {
  Container,
  FloatActionsContainer,
  FloatFlowActionsContainer,
} from "./styles";

function NodeBasedGraph() {
  const [xmlTemplates, setXmlTemplates] = useState([]);
  const [isAddNodeDialogOpen, setIsAddNodeDialogOpen] = useState(false);
  const [isPreviewDialogOpen] = useState(false);
  const [isGeneralPreviewOpen, setIsGeneralPreviewOpen] = useState(false);
  const [previewColumns, setPreviewColumns] = useState({
    columnIndex: 0,
    columnValue: "",
  });
  const [isSaveFlowDialogOpen, setIsSaveFlowDialogOpen] = useState(false);

  const {
    elements,
    selectedElementToPreview,
    isPreviewingData,
    isAttachingFileToNode,
    setIsAttachingFileToNode,
    Observer,
    handleConnectNode,
    handleRemoveConnection,
    handleCloseSinglePreview,
  } = useFlow();

  useEffect(() => {
    if (!isPreviewDialogOpen && Object.keys(previewColumns).length > 0) {
      setPreviewColumns({});
    }
  }, [isPreviewDialogOpen, previewColumns]);

  useEffect(() => {
    async function getXmlData() {
      const token = localStorage.getItem("@mixfiscal:authenticatorToken");
      const xmlTemplatesResponse = await authenticationApi.get(
        `/xmlmodels?limit=9999&page=1&type=nfe&version=4.00`,
        {
          headers: {
            Authorization: token,
          },
        },
      );
      setXmlTemplates(xmlTemplatesResponse.data.xmlmodels.docs);
    }

    getXmlData();
  }, []);

  const onLoad = reactFlowInstance => {
    reactFlowInstance.fitView();
  };

  return (
    <Container>
      <ReactFlowProvider>
        <Observer />
        <ReactFlow
          elements={elements}
          nodeTypes={{
            custom: CustomNode,
            operation: OperationNode,
            advancedOperations: AdvancedOperationsNode,
          }}
          style={{ width: "100%" }}
          onLoad={onLoad}
          onConnect={handleConnectNode}
          onConnectStart={handleRemoveConnection}
          connectionLineStyle={{ stroke: "#000", strokeWidth: 2 }}
          connectionLineType="bezier"
          snapToGrid
          snapGrid={[1, 1]}
        >
          <Background color="black" gap={16} />
          <MiniMap
            className="minimap"
            nodeColor={node => {
              return node.type === "input" ? "blue" : "white";
            }}
          />
          {/* <Controls /> */}
        </ReactFlow>
      </ReactFlowProvider>

      <FloatActionsContainer>
        <CircleButton onClick={() => setIsAddNodeDialogOpen(true)}>
          <MdAdd size={24} color="#fff" />
        </CircleButton>
      </FloatActionsContainer>

      <FloatActionsContainer>
        <Tooltip zIndex={999999} title="Adicionar Bloco" position="top">
          <CircleButton onClick={() => setIsAddNodeDialogOpen(true)}>
            <MdAdd size={24} color="#fff" />
          </CircleButton>
        </Tooltip>
      </FloatActionsContainer>

      <FloatFlowActionsContainer>
        <Tooltip title="Preview Geral de todos os blocos" position="top">
          <CircleButton onClick={() => setIsGeneralPreviewOpen(true)}>
            <MdRemoveRedEye size={24} color="#fff" />
          </CircleButton>
        </Tooltip>

        <Tooltip title="Salvar Template" position="top">
          <CircleButton onClick={() => setIsSaveFlowDialogOpen(true)}>
            <MdSave size={24} color="#fff" />
          </CircleButton>
        </Tooltip>
      </FloatFlowActionsContainer>

      {isAddNodeDialogOpen && (
        <AddNodeDialog
          title="Selecione um Template"
          isOpen={isAddNodeDialogOpen}
          handleClose={() => setIsAddNodeDialogOpen(false)}
          xmlTemplates={xmlTemplates}
        />
      )}

      <SaveFlowDialog
        title="Salvar Fluxo"
        isOpen={isSaveFlowDialogOpen}
        handleClose={() => setIsSaveFlowDialogOpen(false)}
      />

      {isPreviewingData && (
        <PreviewDatatable
          selectedElement={selectedElementToPreview}
          isOpen={isPreviewingData}
          handleClose={() => handleCloseSinglePreview(false)}
        />
      )}
      {isGeneralPreviewOpen && (
        <GeneralPreview handleClose={() => setIsGeneralPreviewOpen(false)} />
      )}
      {isAttachingFileToNode && (
        <SelectFileDialog handleClose={() => setIsAttachingFileToNode(false)} />
      )}
    </Container>
  );
}

export default NodeBasedGraph;
