import styled from "styled-components";

export const TooltipMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 13px;
  padding: 10px 0px 10px 0px;
  margin-left: 10px;
  margin-right: 10px;
`;
