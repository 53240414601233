import styled, { css } from "styled-components";
import { InputContainer as FormInputContainer } from "components/form";

export const InputContainer = styled(FormInputContainer)`
  ${({ flex, maxWidth }) => css`
    flex: ${flex ?? 1};
    max-width: ${maxWidth ?? "290px"};
  `}
`;

export const FooterActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 16px;
`;

export const ContainerRow = styled.div`
  margin-bottom: 1rem;
`;
