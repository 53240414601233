import React, { useEffect, useState } from "react";
import { Container } from "../Box";
import * as S from "./Tabs.styles";

export const Tabs = ({
  handleChange: handleChangeCallback,
  tab: tabValue,
  items = [],
  childTab: ChildTab = () => null,
}) => {
  const [tab, setTab] = useState(tabValue);
  const handleChange = (event, newValue) => {
    setTab(newValue);
    if (handleChangeCallback) {
      handleChangeCallback(event, newValue);
    }
  };

  useEffect(() => {
    setTab(tabValue);
  }, [tabValue, setTab]);

  return (
    <S.TabWrapper>
      <S.Tabs
        value={tab}
        TabIndicatorProps={{ style: S.TabIndicator }}
        textColor="primary"
        onChange={handleChange}
      >
        {items.map((value, index) => (
          <S.Tab
            selected={index === tab}
            value={index}
            key={index}
            label={value?.label}
          />
        ))}
      </S.Tabs>
      {(tab === 0 || tab) && (
        <S.TabChildContainer>
          <Container paddingSize>
            <ChildTab item={items[tab]} />
          </Container>
        </S.TabChildContainer>
      )}
    </S.TabWrapper>
  );
};
