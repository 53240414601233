import React from "react";
import { Card } from "@mix/mixfiscal-designsystem";
import { ButtonOpenNewTag } from "components/structure";
import { LAWS } from "constants/routes";

import * as S from "./History.styles";

export const History = ({ gradeId = "", grade = {} }) => {
  const handle =
    ({ id_law: idLaw }) =>
    () => {
      window.open(`${LAWS.DETAIL(idLaw)}?type=icms`);
    };

  const grades =
    grade?.grades?.length >= 1
      ? grade?.grades
      : grade?.parent_id
      ? [{ _id: grade?.parent_id, alq: ["Grade antiga"] }]
      : [];

  return (
    <S.Container>
      <Card title="Histórico" bold={false} titleIcon="certificado" hasElevation>
        {grades.map(row => (
          <S.WrapperContainer key={`grade-${row?.id_law}`}>
            <S.Label>Lei/Grade: </S.Label>
            <S.LabelValue>
              {`${gradeId} Código: ${row?.id_law} (${
                row.alq[0] || row.alq_saida[0]
              })`}
            </S.LabelValue>
            <ButtonOpenNewTag
              title={`Abrir detalhe da Lei relacionada Código: ${row?.id_law}`}
              onClick={handle(row)}
            />
          </S.WrapperContainer>
        ))}
      </Card>
    </S.Container>
  );
};
