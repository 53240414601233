import React from "react";
import { useTheme } from "hooks";
import { useWatch } from "react-hook-form";
import { Card, Icon, Tag, Tooltip } from "@mix/mixfiscal-designsystem";
import { ButtonAction } from "components/structure";
import { Select } from "components/form";
import { getListSelecteds, getListTagsValid } from "utils/context";
import {
  COMPANIES_TYPES_LIST,
  OUTS_TYPES_LIST,
  ENTRIES_TYPES_LIST,
  STATES_LIST,
  BLANK_SELECT_LIST,
  ACTIVITIES_LIST,
} from "constants/types";

import * as S from "./CardCenario.styles";

const headerColorGray = "#e5e5e5";
const textColorGray = "#304156";

export const CardCenario = ({
  control,
  cenarios = [],
  handleAdd,
  handleClear,
  types,
}) => {
  const { colors } = useTheme();

  const opercaoEntrada = useWatch({ control, name: "operacao_e" });
  const opercaoSaida = useWatch({ control, name: "operacao_s" });
  const estadoEntrada = useWatch({ control, name: "state_entry" });
  const estadoSaida = useWatch({ control, name: "state_out" });
  const typeOrganization = useWatch({ control, name: "type_organization" });
  const atividade = useWatch({ control, name: "atividade" });

  const handleRemoveBtn = () =>
    handleClear({
      cenarios: [],
    });

  const handleAddBtn = () => {
    handleAdd({
      cenarios: [
        ...cenarios,
        ...[
          {
            origem: opercaoEntrada?.value,
            saida: opercaoSaida?.value,
            ufOrigem: estadoEntrada?.value,
            ufDestino: estadoSaida?.value,
            atividade: atividade?.value,
            tipoEmpresa: typeOrganization?.value,
          },
        ],
      ],
    });
  };

  const tagsNames = (values = cenarios) => {
    return values?.map(cenario =>
      getListSelecteds(
        getListTagsValid([
          cenario?.origem,
          cenario?.saida,
          cenario?.ufOrigem,
          cenario?.ufDestino,
          cenario?.atividade,
          cenario?.tipoEmpresa,
          cenario?.status,
        ]),
      ),
    );
  };

  const hasPisCofins = Boolean(types?.pis || types?.cofins);

  return (
    <Card
      className="card-custom"
      handleCtaClick={() => {}}
      hasElevation
      headerColor={headerColorGray}
      textColor={textColorGray}
      title="CÉNARIO DE COMPRA E VENDA"
    >
      <div className="row">
        <Select
          name="operacao_e"
          isDisabled={hasPisCofins}
          data={[...BLANK_SELECT_LIST, ...ENTRIES_TYPES_LIST]}
          label="ORIGEM"
          defaultValue={BLANK_SELECT_LIST[0]}
          control={control}
        />

        <Select
          name="operacao_s"
          isDisabled={hasPisCofins}
          data={[...BLANK_SELECT_LIST, ...OUTS_TYPES_LIST]}
          label="SAÍDA"
          control={control}
          defaultValue={BLANK_SELECT_LIST[0]}
        />
      </div>

      <div className="row">
        <Select
          isDisabled={hasPisCofins}
          data={[...BLANK_SELECT_LIST, ...STATES_LIST]}
          defaultValue={BLANK_SELECT_LIST[0]}
          label="UF ENTRADA"
          name="state_entry"
          control={control}
        />

        <Select
          isDisabled={hasPisCofins}
          data={[...BLANK_SELECT_LIST, ...STATES_LIST]}
          defaultValue={BLANK_SELECT_LIST[0]}
          label="UF SAÍDA"
          name="state_out"
          control={control}
        />
      </div>

      <S.ContainerPlus>
        <div className="row">
          <Select
            data={[...BLANK_SELECT_LIST, ...COMPANIES_TYPES_LIST]}
            label="TIPO EMPRESA ORIGEM"
            name="type_organization"
            defaultValue={BLANK_SELECT_LIST[0]}
            control={control}
          />

          <Select
            data={[...BLANK_SELECT_LIST, ...ACTIVITIES_LIST]}
            label="ATIVIDADE"
            name="atividade"
            defaultValue={BLANK_SELECT_LIST[0]}
            control={control}
          />
        </div>
        <S.Action>
          <Tooltip
            body="Adicionar cenário ao filtro"
            position="top"
            zIndex={1000}
          >
            <ButtonAction onClick={handleAddBtn}>
              <Icon color={colors.lightGreen} name="adicionar" size={32} />
            </ButtonAction>
          </Tooltip>
        </S.Action>
      </S.ContainerPlus>

      <S.RowTagsName>
        {tagsNames()?.map(cestV => (
          <Tag label={cestV} key={cestV} type="simple" />
        ))}
        {tagsNames()?.length > 0 && (
          <S.ActionRemove>
            <Tooltip body="Remover cenários" position="top" zIndex={1000}>
              <ButtonAction onClick={handleRemoveBtn}>
                <Icon color="#000" name="fechar1" size={18} />
              </ButtonAction>
            </Tooltip>
          </S.ActionRemove>
        )}
      </S.RowTagsName>
    </Card>
  );
};
