import React, { useState, useRef, useEffect, useCallback } from "react";
import { MdCheckCircle, MdLoop } from "react-icons/md";
import { format } from "date-fns";
import { Box, Typography, LinearProgress } from "@material-ui/core";
import { Tooltip } from "@mix/mixfiscal-designsystem";

import templatesApi from "../../../services/templatesApi";

import Icon from "../../IconExporter";
import Dialog from "../../UI/Dialog";
import Accordion from "../../UI/Accordion";
import {
  FileItem,
  ReloadBtn,
  UpdateBtn,
  IconAlert,
  IconUpload,
  LoadingIcon,
  LabelUpload,
  ContainerBtn,
  FilesContainer,
  LoadingFilesIcon,
  DropZoneContainer,
  FileErrorNotExistent,
  FilesCategoryContainer,
  FilesDatabaseContainer,
  FilesCategoryContainerInfo,
  FilesCategoryContainerHeader,
  FilesCategoryContainerInfoSearch,
} from "./styles";

function LinearProgressWithLabel({ value }) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" value={value} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">
          {`${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

function UploadDialog({
  isOpen,
  handleUpload,
  handleClose,
  title,
  loadingFilesStatus,
  uploadPercentage,
}) {
  const filesRef = useRef(null);
  const [filesCategory, setFilesCategory] = useState([]);
  const [isLoadingFiles, setIsLoadingFiles] = useState(false);

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [confirmDialogData, setConfirmDialogData] = useState(null);
  const [limit, setLimit] = useState(20);

  const getFilesFromDatabase = useCallback(() => {
    setIsLoadingFiles(true);
    templatesApi
      .get(`/files/all?limit=${limit}&page=1`)
      .then(res => {
        setFilesCategory(res.data.data);
        setIsLoadingFiles(false);
      })
      .catch(err => {
        console.log(err);
        setIsLoadingFiles(false);
      });
  }, [limit]);

  const handleDeleteFile = id => {
    templatesApi
      .delete(`/files/file/${id}`)
      .then(() => {
        setFilesCategory(prevState =>
          prevState.map(category => {
            return {
              ...category,
              files: category.files.filter(file => file._id !== id),
            };
          }),
        );
        setIsConfirmDialogOpen(false);
        setConfirmDialogData(null);
      })
      .catch(err => console.log(err));
  };

  useEffect(() => {
    getFilesFromDatabase();
  }, [getFilesFromDatabase]);

  const getTotalFiles = () => {
    if (filesCategory.length > 0) {
      return filesCategory.reduce((acc, cur) => {
        acc += cur.files.length;
        return acc;
      }, 0);
    }
  };

  const statusEnum = {
    pending: "Pendente",
    valid: "Arquivo validado com sucesso",
    notValid: "Arquivo inválido",
    finished: "Finalizado",
  };

  return (
    <>
      <Dialog isOpen={isOpen} handleClose={handleClose} title={title}>
        <FilesContainer>
          <DropZoneContainer>
            {loadingFilesStatus === "uploading" && (
              <label>
                <LoadingIcon
                  size={32}
                  color="#0e7c34"
                  isLoading={loadingFilesStatus === "uploading"}
                />
              </label>
            )}
            {loadingFilesStatus === "success" && (
              <label>
                <MdCheckCircle size={32} color="#0e7c34" />
              </label>
            )}
            {loadingFilesStatus === "idle" && (
              <>
                <IconUpload size={60} />
                <LabelUpload htmlFor="inputFile">
                  <div>
                    Arraste aqui ou
                    <span>procure no seu computador arquivos</span>
                  </div>
                  <p>
                    formatos suportados: speds, xls, csv, zip, txt, xml, pdfs
                  </p>
                </LabelUpload>
                <input
                  id="inputFile"
                  type="file"
                  multiple
                  onChange={e => handleUpload([...e.target.files])}
                />
              </>
            )}
          </DropZoneContainer>
          {uploadPercentage > 0 && (
            <LinearProgressWithLabel value={uploadPercentage} />
          )}
          {isLoadingFiles}
          {filesCategory.length > 0 ? (
            <FilesDatabaseContainer ref={filesRef} isLoading={isLoadingFiles}>
              <ContainerBtn>
                <ReloadBtn>
                  <p>Recarregar Mais:</p>
                  <MdLoop size={24} onClick={() => setLimit(limit + 50)} />
                </ReloadBtn>
                <UpdateBtn>
                  <p>Atualizar:</p>
                  <MdLoop size={24} onClick={() => getFilesFromDatabase()} />
                </UpdateBtn>
              </ContainerBtn>
              <FilesCategoryContainer>
                <FilesCategoryContainerHeader>
                  GERENCIAMENTO DE ARQUIVO
                </FilesCategoryContainerHeader>
                <FilesCategoryContainerInfo>
                  <p>
                    VOCÊ TEM
                    <span>{getTotalFiles()}</span>
                    ARQUIVOS ENVIADOS
                  </p>
                  <FilesCategoryContainerInfoSearch>
                    <MdLoop />
                    <input type="text" />
                  </FilesCategoryContainerInfoSearch>
                </FilesCategoryContainerInfo>
                <div className="list">
                  {!isLoadingFiles && filesCategory.length > 0 ? (
                    filesCategory.map(category => {
                      return (
                        <Accordion
                          key={category.type}
                          title={category.type.toUpperCase()}
                          extraInfo={
                            category.files &&
                            `${category.files.length} de ${category.filesCount}`
                          }
                        >
                          {category.files.map(file => (
                            <FileItem key={file._id}>
                              <div>
                                <p>{file.file}</p>

                                <Tooltip
                                  zIndex={999999}
                                  title="Remover Arquivo da base de dados"
                                  position="top"
                                >
                                  <Icon
                                    name="close"
                                    size={20}
                                    color="#F44336"
                                    onClick={() => {
                                      setIsConfirmDialogOpen(true);
                                      setConfirmDialogData(file._id);
                                    }}
                                  />
                                </Tooltip>
                              </div>
                              <p className="smallTxt">
                                {`Adicionado em: ${format(
                                  new Date(file.uploadDate),
                                  "dd/MM/yyyy - hh:mm:ss",
                                )}`}
                              </p>
                              <p className="smallTxt">
                                {statusEnum[file?.status]}
                              </p>
                            </FileItem>
                          ))}
                        </Accordion>
                      );
                    })
                  ) : (
                    <LoadingFilesIcon>
                      <LoadingIcon
                        size={32}
                        color="#0e7c34"
                        isLoading={isLoadingFiles}
                      />
                    </LoadingFilesIcon>
                  )}
                </div>
              </FilesCategoryContainer>
            </FilesDatabaseContainer>
          ) : (
            <FileErrorNotExistent>
              <IconAlert size={60} color="red" />
              <h5>Não existe arquivos cadastrados</h5>
            </FileErrorNotExistent>
          )}
        </FilesContainer>
      </Dialog>
      <Dialog
        isOpen={isConfirmDialogOpen}
        handleConfirm={() => handleDeleteFile(confirmDialogData)}
        handleClose={() => setIsConfirmDialogOpen(false)}
        title="Deletar arquivo"
        zIndex="100002"
      >
        <h2>Tem certeza que deseja excluir?</h2>
      </Dialog>
    </>
  );
}

export default UploadDialog;
