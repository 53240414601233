/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useRef, useEffect } from "react";
import { Icon } from "@mix/mixfiscal-designsystem";
import { LoadPlaceholder } from "components/structure/Load";
import {
  useProductsSearch,
  useProductsConsult,
  useProductsConsultMix,
} from "useCases/productsConsult";
import { addSeconds, isAfter } from "date-fns";
import { usePermissions } from "context/PermissionsContext";
import { Dropdown } from "../Dropdown";
import { Container, ItemsContainer, LoadeContent } from "./styles";

const StateContainer = ({ show, showNoData, textNoData }) => (
  <LoadeContent>
    <LoadPlaceholder
      size={10}
      show={show}
      showNoData={showNoData}
      textNoData={textNoData}
    />
  </LoadeContent>
);

export const SearchBox = ({
  data,
  type = "complex",
  placeholder = "",
  onClick,
}) => {
  const [items, setItems] = useState(data ?? []);
  const [inputValue, setInputValue] = useState("");
  const [loadingLocal, setLoadingLocal] = useState(false);
  const [cacheTime, setCacheTime] = useState(new Date());
  const { fetch: fetchProductsCustomerConsult } = useProductsConsult();
  const { fetch: fetchProductsCustomerConsultMix } = useProductsConsultMix();
  const { data: fullDataProducts, fetch: fetchProductsConsult } =
    useProductsSearch({});

  const { companies } = usePermissions();
  const containerRef = useRef(null);
  const time = useRef(null);

  useEffect(() => {
    if (time.current) {
      clearInterval(time.current);
      setLoadingLocal(false);
    }
    time.current = setInterval(() => {
      if (inputValue !== "") {
        const itemsLocal =
          fullDataProducts?.data?.docs.map(item => item.prod_nome) || [];

        const values = itemsLocal.filter(item =>
          new RegExp(`^${inputValue}`, "igm").exec(item),
        );
        if (
          !values.length ||
          !(values.length && isAfter(cacheTime, new Date()))
        ) {
          setLoadingLocal(true);
          fetchProductsConsult(
            {
              search: inputValue,
            },
            () => setLoadingLocal(false),
          );
          setCacheTime(addSeconds(new Date(), 10));
        }
      }
      clearInterval(time.current);
    }, 2000);

    if (inputValue !== "") {
      setLoadingLocal(true);
    }
    // TODO: Retirar urgente!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue, fetchProductsConsult]);

  useEffect(() => {
    const itemsLocal = fullDataProducts?.data?.docs || [];

    if (itemsLocal.length) {
      let values = itemsLocal.filter(item =>
        new RegExp(`${inputValue}`, "igm").exec(item.prod_nome),
      );
      if (values.length === 0) {
        values = itemsLocal.filter(item =>
          new RegExp(`${inputValue}`, "igm").exec(item.ean),
        );
      }
      setItems(values);
      setLoadingLocal(false);
    }
  }, [fullDataProducts, inputValue]);

  const handleClick = item => () => {
    setInputValue(item.prod_nome);
    fetchProductsCustomerConsult(item.id);
    fetchProductsCustomerConsultMix(item.id_prod);

    onClick(item);
    containerRef.current?.click();
  };

  const makeBold = (input, wordsToBold) => {
    const newText = input.replace(
      new RegExp(`(\\b)(${wordsToBold.join("|")})(\\b)`, "ig"),
      "$1<span>$2</span>$3",
    );
    return {
      __html: newText,
    };
  };

  const getItems = () => {
    const itemsLocal = items || [];

    if (inputValue === "") {
      return (
        <StateContainer
          showNoData
          textNoData="Digite alguma descrição de produto. Ex: Coca Cola"
        />
      );
    }

    if (!itemsLocal.length && !loadingLocal) {
      return <StateContainer showNoData textNoData="Dados não encontrados" />;
    }

    if (loadingLocal) {
      return <StateContainer show />;
    }
    if (itemsLocal && itemsLocal.length > 0) {
      return (
        <ItemsContainer>
          {itemsLocal.map(item => {
            const empresaLabel = companies?.find(
              itemCnpj =>
                itemCnpj?.cnpj?.replace(/[^0-9]/g, "") ===
                item?.cnpj?.replace(/[^0-9]/g, ""),
            );

            return (
              <div className="line-container">
                <p
                  key={item}
                  onClick={handleClick(item)}
                  role="button"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={makeBold(item?.prod_nome, [
                    inputValue,
                  ])}
                />
                <div
                  title={
                    item?.tipo === "mix"
                      ? "Mix Fiscal"
                      : empresaLabel?.company_name
                  }
                  className="chip-container"
                  style={{
                    background: item?.tipo === "mix" ? "#adc86a" : "#5E93D5",
                  }}
                >
                  {item?.tipo === "mix"
                    ? "Mix Fiscal"
                    : empresaLabel?.company_name.slice(0, 5)}
                </div>
              </div>
            );
          })}
        </ItemsContainer>
      );
    }
  };

  return (
    <Dropdown
      className="dropdown-search"
      fitToParentWidth
      dropContent={getItems()}
      style={{
        with: `78%`,
      }}
    >
      <Container type={type} hasValue={!!inputValue} ref={containerRef}>
        {type === "complex" && <Icon name="busca" size={15} />}
        <input
          type="text"
          value={inputValue}
          enterKeyHint
          onChange={e => setInputValue(e.target.value)}
          placeholder={placeholder}
        />
        {type === "simple" && <Icon name="busca" size={15} />}
      </Container>
    </Dropdown>
  );
};
