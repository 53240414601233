import React, { useState } from "react";
import { Card } from "@mix/mixfiscal-designsystem";
import { useGradeFilters } from "hooks/useGrade";

import { ResultsProducts } from "components/contexts/grid-manager/ResultsProducts";

export const LawProducts = ({
  onChangeProduct,
  states = [],
  lawValues = {},
  gradeId = null,
  hasMVA = false,
  lawId = null,
  isPisCofins,
  isNewLaw = false,
}) => {
  const [totalProducts, setTotalProducts] = useState(0);

  const params = useGradeFilters({
    values: lawValues,
    gradeId,
    isPisCofins,
    lawId,
  });

  return (
    <Card
      hasElevation
      title={`Produtos Impactados (${totalProducts})`}
      bold={false}
    >
      <ResultsProducts
        isNewLaw={isNewLaw}
        params={params}
        states={states ?? []}
        setTotalItens={setTotalProducts}
        disableMVA={hasMVA}
        onChangeProduct={onChangeProduct}
      />
    </Card>
  );
};
