import React, { useState, useEffect, useMemo } from "react";
import { Pagination } from "@mix/mixfiscal-designsystem";
import { useRegisterLawsGradesContext } from "context/RegisterLawsContext";
import { useProductsGrade } from "useCases/grid";
import { hasPisCofinsTax } from "utils/context";
import { Filters } from "./Filters";
import { ListProducts } from "./ListProducts";
import * as S from "./styles";

export const getProductsGradeParams = ({
  page,
  limit,
  params = {},
  filters = {},
}) => {
  const paramsUsing = {
    ncms: params?.ncms,
    cests: params?.cests,
    page,
    limit,
    ...filters,
  };

  if (params?.ncms !== "[]" || params?.gradeId || params?.lawId) {
    paramsUsing.rmGrades = params?.rmGrades;
    if (params?.gradeId) {
      paramsUsing.gradeId = params?.gradeId;
    }

    // Pesquisa produtos pelo tipo da grade de icms
    if (
      (params?.icms || params?.gradeId || params?.lawId) &&
      !hasPisCofinsTax(params.type)
    ) {
      paramsUsing.productsByGradeIcms = 1;
    }

    // Pesquisa os produtos pelo tipo da grade piscofins
    if (hasPisCofinsTax(params?.type)) {
      paramsUsing.productsByGradePisCofins = 1;
    }

    if (params?.lawId) {
      paramsUsing.lawId = params?.lawId;
    }

    if (params?.refLawId) {
      paramsUsing.refLawId = params?.refLawId;
    }

    return paramsUsing;
  }

  return null;
};

export const ResultsProductsWithFilter = ({
  states = [],
  params = {},
  setTotalItens,
  disableMVA = false,
  onChangeProduct = () => {},
  header = null,
  typeSelectionProduct,
}) => {
  const { values } = useRegisterLawsGradesContext();
  const [limit, setLimit] = useState(15);
  const [page, setPage] = useState(1);
  const [filters, onChangeFilters] = useState({});
  const [totalPages, setTotalPages] = useState(1);

  const { data: dataProductsMix, isLoading } = useProductsGrade(
    Boolean(params?.gradeId || params?.lawId),
    getProductsGradeParams({ limit, page, filters, params }),
  );

  useEffect(() => {
    if (dataProductsMix?.totalPages) {
      setTotalPages(dataProductsMix?.totalPages);
    }

    if (setTotalItens && dataProductsMix?.totalDocs)
      setTotalItens(dataProductsMix?.totalDocs);
  }, [dataProductsMix?.totalPages, setTotalItens, dataProductsMix?.totalDocs]);

  const getProducts = useMemo(() => {
    const products = (dataProductsMix?.docs ?? []).map(gradeProduct => ({
      ...gradeProduct,
      ...gradeProduct.product,
    }));

    const productsNotIn = [
      ...(values?.productsMoveSelecteds ?? []),
      ...(values?.productsRemoved ?? []),
    ];

    return products.filter(
      item => !productsNotIn.find(row => row._id === item._id),
    );
  }, [
    dataProductsMix?.docs,
    values?.productsMoveSelecteds,
    values?.productsRemoved,
  ]);

  return (
    <S.ContainerGrid>
      <Filters onChange={onChangeFilters} />
      {header}
      <ListProducts
        isAction
        data={getProducts}
        states={states}
        disableMVA={disableMVA}
        onChangeProduct={onChangeProduct}
        limit={limit}
        isLoading={isLoading}
        typeSelectionProduct={typeSelectionProduct}
      />
      <Pagination
        handlePageChange={setPage}
        initialPage={page}
        siblingsCount={1}
        totalPages={totalPages}
        amountVariations={[15, 100, 300, 500, 600]}
        handleChangeAmount={setLimit}
      />
    </S.ContainerGrid>
  );
};
