import React, { useState } from "react";
import { IoAdd } from "react-icons/io5";
import Select from "react-select";
import { Tag } from "@mix/mixfiscal-designsystem";

import { useRegisterLaws } from "../../../hooks/useRegisterLaws";
import { CardWithLateralHeader, Button } from "../styles";

const Cnae = ({ cnaes }) => {
  const { values, setComplexValue, setValues } = useRegisterLaws();
  const [cnaeField, setCnaeField] = useState("");

  const handleNewCnae = () => {
    if (cnaeField !== "") {
      setComplexValue("cnaes", cnaeField.value);
      setCnaeField("");
    }
  };

  const deleteCnae = cnae => {
    const newCnaes = values?.cnaes?.filter(c => c !== cnae);

    setValues({ ...values, cnaes: newCnaes });
  };

  return (
    <CardWithLateralHeader marginLeft="20px">
      <header>CNAE</header>
      <main>
        <div className="input-container">
          <div className="cnae-container">
            <label>Código - Descrição</label>
            <Select
              options={cnaes}
              className="basic-single"
              classNamePrefix="select"
              placeholder="Selecione"
              styles={{
                control: styles => ({
                  ...styles,
                  width: "355px",
                  border: "1px solid #596778",
                }),
              }}
              onChange={value => {
                setCnaeField(value);
              }}
              value={cnaeField}
            />
          </div>
          <div>
            <Button type="button" onClick={handleNewCnae}>
              <IoAdd />
            </Button>
          </div>
        </div>
        <div className="tag-container">
          {values?.cnaes?.map(cnae => (
            <div className="tag-item" key={cnae}>
              <Tag
                type="advanced"
                label={cnaes.find(c => c.value === cnae)?.codigo}
                handleDelete={() => deleteCnae(cnae)}
              />
            </div>
          ))}
        </div>
      </main>
    </CardWithLateralHeader>
  );
};

export default Cnae;
