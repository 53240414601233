import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import { v4 as uuidv4 } from "uuid";
import { LoadCircle } from "components/structure";

import { Tooltip } from "@mix/mixfiscal-designsystem";
import { FiPlus } from "react-icons/fi";

import { useStores, UseStoresSearch } from "useCases";
import { useToast } from "context/ToastContext";
import { InputSearch } from "components/contexts/stores/SelectSearch/Components";
import { OptionsComponent } from "./OptionCustom";

import * as S from "../../styles";

const CustomOption = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      <OptionsComponent data={props} />
    </components.Option>
  );
};

export const GroupFormSelects = ({
  currentId,
  setCurrentId,
  control,
  setValue,
  tableData,
  setTableData,
  tableCompanyValue,
  setTableCompanyValue,
  tableStoreValue,
  setTableStoreValue,
}) => {
  const { addToast } = useToast();

  const [searchValue, setSearchValue] = useState("");
  const [debouncedTerm, setDebouncedTerm] = useState(searchValue);

  const { isLoading, data: companies, fetch: searching } = UseStoresSearch();

  useEffect(() => {
    const timer = setTimeout(() => {
      setSearchValue(debouncedTerm);
    }, 1000);
    return () => clearTimeout(timer);
  }, [debouncedTerm]);

  useEffect(() => {
    if (searchValue !== "") {
      searching({
        type: "MATRIZ",
        search: searchValue,
      });
    }
  }, [searchValue, searching]);

  const { isLoading: isLoadingStores, data: dataStores } = useStores({
    companyId: tableCompanyValue,
    page: 1,
    limit: 100,
  });

  const addTableRow = () => {
    const itemAll = dataStores?.stores?.docs.map(item => {
      return item._id;
    });
    if (currentId) {
      const newTableData = tableData.filter(row => row._id !== currentId);
      setTableData([
        ...newTableData,
        {
          _id: currentId,
          company: tableCompanyValue,
          store: tableStoreValue.length === 0 ? itemAll : tableStoreValue,
        },
      ]);
      addToast({
        title: "Atualizar Item na Tabela",
        type: "success",
        description: `Item atualizado com sucesso!`,
      });
      setValue("company");
      setValue("store");
      setCurrentId("");
      setTableCompanyValue("");
      setTableStoreValue("");
    } else if (tableCompanyValue) {
      setTableData([
        ...tableData,
        {
          _id: uuidv4(),
          company: tableCompanyValue,
          store: tableStoreValue.length === 0 ? itemAll : tableStoreValue,
        },
      ]);
      addToast({
        title: "Criar Item na Tabela",
        type: "success",
        description: `Item criado com sucesso!`,
      });
      setValue("company");
      setValue("store");
      setTableCompanyValue("");
      setTableStoreValue("");
    } else {
      addToast({
        title: "Criar Item na Tabela",
        type: "error",
        description: `
            Você precisa escolher uma Matriz, uma Filial e um Grupo de Acesso para conseguir criar!
            `,
      });
      return null;
    }
  };

  const messageBlank =
    ({ isLoadingItem, dataLength, searchLength }) =>
    () => {
      if ((isLoadingItem, !dataLength, searchLength)) {
        return <LoadCircle width="25px" height="25px" />;
      }

      if (!isLoadingItem && searchLength === 0 && !dataLength) {
        return "Não foi possivel encontrar dados com os parametros digitadosJ";
      }

      return "Digite o Razão Social ou CNPJ";
    };

  const onInputChange = value => {
    setDebouncedTerm(value);
  };

  const filterOptions = () => true;

  return (
    <>
      <S.ColumnFormItem setMargin="10px 0 0 0">
        <S.InputForm>
          <label>Matriz</label>
          <Select
            components={{
              Input: props => (
                <InputSearch
                  margin="10px 4px 0 4px !important"
                  key="input-search"
                  onInputChange={onInputChange}
                  searchValue={searchValue}
                  {...props}
                />
              ),
              Option: CustomOption,
            }}
            labelType="top"
            placeholder="Selecione"
            label="Matriz"
            name="companyId"
            noOptionsMessage={messageBlank({
              isLoadingItem: isLoading,
              dataLength: companies?.length,
              searchLength: searchValue?.length,
            })}
            onChange={selected => {
              setTableCompanyValue(
                selected?.companyId ? selected?.companyId : selected?._id,
              );
              setValue("companyId", selected._id);
            }}
            filterOption={filterOptions}
            options={
              isLoading
                ? []
                : companies?.map(company => ({
                    label:
                      company.company_name ||
                      company.tradeName ||
                      company.companyName ||
                      company.cnpj ||
                      company.company_social_name,
                    value: company,
                    ...company,
                  }))
            }
          />
        </S.InputForm>
        <S.InputForm>
          <label>Loja</label>
          <Select
            isMulti
            control={control}
            noOptionsMessage={() => "Nenhuma loja encontrada"}
            isLoading={isLoadingStores}
            name="store"
            options={dataStores?.stores?.docs.map(item => ({
              value: item._id,
              label: item.companyName,
              cnpj: item.cnpj,
            }))}
            placeholder="Selecione"
            onChange={selected => {
              const itemSelected = selected.map(item => {
                return item.value;
              });
              setTableStoreValue(itemSelected);
            }}
          />
        </S.InputForm>
        <S.CollumFormSearch>
          <S.AddButton
            type="button"
            onClick={() => {
              addTableRow();
            }}
          >
            <Tooltip title="Adicionar Relatório Personalizado" position="top">
              <FiPlus size={22} color="white" className="icon" />
            </Tooltip>
          </S.AddButton>
        </S.CollumFormSearch>
      </S.ColumnFormItem>
    </>
  );
};
