import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { MdOpenInNew } from "react-icons/md";
import { Datatable, Pagination, Checkbox } from "@mix/mixfiscal-designsystem";
import { LAWS } from "constants/routes";
import { useLaws } from "useCases/laws";

import { ListResultsAmostration } from "../ListResultsAmostration";

const ActionRender = ({ grade, openNewWindow }) => {
  const { push } = useHistory();
  const handle = () => {
    if (openNewWindow) {
      return window.open(LAWS.DETAIL(grade?._id));
    }

    return push(`${LAWS.DETAIL(grade?._id)}`);
  };

  return (
    <button
      style={
        openNewWindow
          ? {
              backgroundColor: "transparent",
              justifyContent: "center",
              display: "flex",
            }
          : {}
      }
      type="button"
      onClick={handle}
    >
      {openNewWindow ? <MdOpenInNew color="#a4a0a0" size={28} /> : "Detalhe"}
    </button>
  );
};

const ActionSelect = ({ grade, handleSelect }) => {
  const handleChange = value =>
    handleSelect({ type: value ? "add" : "remove", value: grade });

  return (
    <div>
      <Checkbox handleChangeCallback={handleChange} size={25} />
    </div>
  );
};

export const List = ({ params, ...props }) => {
  const [totalPages, setTotalPages] = useState(1);

  const [page, setPage] = useState(1);
  const { data, isLoading } = useLaws({
    page,
    limit: 50,
    ...params,
  });

  useEffect(() => {
    if (data?.laws?.totalPages) {
      setTotalPages(data?.laws?.totalPages);
    }
  }, [data?.laws]);

  return (
    <ListContent
      isLoading={isLoading}
      params={params}
      totalPages={totalPages}
      totalDocs={data?.laws?.totalDocs}
      totalGrades={data?.laws?.totalGrades}
      data={data?.laws?.docs}
      setPage={setPage}
      {...props}
    />
  );
};

const getFieldsByOperationsGrade = cenarios => {
  const operacoes = cenarios.reduce((previous, current) => {
    const values = {
      ...previous,
    };

    if (!values.states_origens) {
      values.states_origens = [];
    }

    if (!values.states_saidas) {
      values.states_saidas = [];
    }

    if (!values.origens) {
      values.origens = [];
    }

    if (!values.saidas) {
      values.saidas = [];
    }

    if (!values.atividades) {
      values.atividades = [];
    }

    if (!values.tipoEmpresas) {
      values.tipoEmpresas = [];
    }

    if (!values.origens.includes(current.origem) && current.origem) {
      values.origens.push(current.origem);
    }

    if (!values.saidas.includes(current.saida) && current.saida) {
      values.saidas.push(current.saida);
    }

    if (!values.states_origens.includes(current.ufOrigem) && current.ufOrigem) {
      values.states_origens.push(current.ufOrigem);
    }
    if (
      !values.states_saidas.includes(current.ufDestino) &&
      current.ufDestino
    ) {
      values.states_saidas.push(current.ufDestino);
    }

    if (!values.atividades.includes(current.atividade) && current.atividade) {
      values.atividades.push(current.atividade);
    }

    if (
      !values.tipoEmpresas.includes(current.tipoEmpresa) &&
      current.tipoEmpresa
    ) {
      values.tipoEmpresas.push(current.tipoEmpresa);
    }

    if (
      !values.tipoEmpresas.includes(current.tipoEmpresaSaida) &&
      current.tipoEmpresaSaida
    ) {
      values.tipoEmpresas.push(current.tipoEmpresaSaida);
    }

    return {
      ...previous,
      ...values,
    };
  }, {});

  const operacao = [];

  if (
    operacoes?.states_saidas?.length >= 2 &&
    operacoes.states_origens?.length >= 2
  ) {
    operacao.push("INTERESTADUAL");
  }

  if (operacoes?.origens?.length) {
    operacao.push(`ENTRADA (${operacoes?.origens.join(",")})`);
  }

  if (operacoes?.saidas?.length) {
    operacao.push(`SAIDA (${operacoes?.saidas.join(",")})`);
  }

  return {
    operacao: operacao?.length ? operacao.join(" + ") : "SEM OPERAÇĀO DEFINIDA",
    ...operacoes,
  };
};

const columns = [
  {
    field: "action",
    title: "",
  },
  {
    field: "uf",
    title: "Uf Origem",
  },
  {
    field: "uf_saida",
    title: "Uf Destino",
  },
  {
    field: "status",
    title: "Status",
  },
  {
    field: "atividade",
    title: "Atividade",
  },
  {
    field: "regime",
    title: "Regime",
  },
  {
    field: "qtde_grades",
    title: "Quantidade de Grades",
  },
  { field: "ncm", title: "Ncm" },
  { field: "cest", title: "Cest" },
  { field: "cst", title: "CST" },
  { field: "alq", title: "Alíquota" },
];

export const ListContent = ({
  hasSelect = false,
  handleSelect = () => {},
  setPage = () => {},
  setLimit = () => {},
  data = [],
  totalPages,
  isPisCofins = false,
  openNewWindow = false,
  isLoading = false,
  totalDocs = 0,
  totalGrades = 0,
}) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "flex-end",
          flexDirection: "column",
          paddingBottom: "1rem",
        }}
      >
        <ListResultsAmostration
          totalLeis={totalDocs}
          gradesTotal={totalGrades}
        />
      </div>
      <Datatable
        handleChangePage={setPage}
        pagination={false}
        columns={columns}
        isLoading={isLoading}
        rawData={data.map(item => {
          const extractDataSceneries = getFieldsByOperationsGrade(
            item.cenarios ?? [],
          );

          console.log({ extractDataSceneries });
          return {
            ...item,
            action: (
              <ActionRender
                grade={item}
                openNewWindow={hasSelect || openNewWindow}
                type={isPisCofins && "pis"}
              />
            ),
            cest: (item.cests ?? []).map(row => row?.codigo).join(", "),
            ncm: (item.ncms ?? []).map(row => row?.codigo).join(", "),
            operacao: extractDataSceneries?.operacao,
            uf_saida: (extractDataSceneries?.states_saidas ?? []).join(", "),
            uf: (extractDataSceneries?.states_origens ?? []).join(", "),
            status: item.status,
            qtde_grades: item.grades?.length,
            regime: extractDataSceneries?.tipoEmpresas?.join(", "),
            atividade: extractDataSceneries?.atividades?.join(", "),
            select: hasSelect && (
              <ActionSelect grade={item} handleSelect={handleSelect} />
            ),
            alq: [
              item?.atributos?.aliqCstEntrada,
              item?.atributos?.aliqCstSaida,
            ].join(", "),
            cst: [item?.atributos?.cstEntrada, item?.atributos?.cstSaida]
              .filter(v => v)
              .join(", "),
          };
        })}
      />

      <Pagination
        handlePageChange={value => setPage(value)}
        initialPage={1}
        siblingsCount={1}
        totalPages={totalPages}
        amountVariations={[50, 10, 150, 300]}
        handleChangeAmount={setLimit}
      />
    </>
  );
};
