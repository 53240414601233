import React, { useState } from "react";
import { useFieldArray } from "react-hook-form";
import { IoAdd, IoClose } from "react-icons/io5";
import Switch from "components/UI/Switch";
import { Card, Datatable } from "@mix/mixfiscal-designsystem";
import { useUsers } from "useCases/user";
import { usePermissionAccessGroups } from "useCases/permission";
import { Container, InputContainer, Button } from "./styles";

const INIT_TASK = {
  title: "",
  description: "",
  subtasks: [],
  assigned: {},
  hasSubtask: false,
  notify: false,
  deadline: new Date(),
};

const TaskForm = ({ control, setValue, getValues }) => {
  const [task, setTask] = useState(INIT_TASK);
  const { data: userData, isLoading } = useUsers({
    page: 1,
    limit: 100,
    accessGroup: task?.accessGroup,
  });
  const { data: accessGroupsData } = usePermissionAccessGroups();
  const [subtask, setSubtask] = useState({
    title: "",
    description: "",
  });
  const { fields } = useFieldArray({
    control,
    name: `tasks`,
  });

  return (
    <Container>
      <Card
        headerColor="#59AEC4"
        textColor="#FFF"
        title="TASK"
        className="flatCard"
      >
        <p className="subtitle">
          Crie uma ou várias tarefas necessárias para solução do problema.
          Configure o disparo de e-mail automático para avisar o responsável
          pela tarefa imediatamente.
        </p>

        <div className="row">
          <InputContainer>
            <label>Título da Tarefa</label>
            <input
              value={task.title}
              name="title"
              onChange={e => setTask({ ...task, title: e.target.value })}
              placeholder="Digite um título"
            />
          </InputContainer>
          <InputContainer>
            <label>Descrição da Tarefa</label>
            <input
              value={task.description}
              name="description"
              onChange={e => setTask({ ...task, description: e.target.value })}
              placeholder="Digite uma descrição"
            />
          </InputContainer>
        </div>
        <div className="row">
          <InputContainer>
            <label>Prioridade</label>
            <select name="priority">
              <option value="HIGH">Alta</option>
              <option value="MEDIUM">Média</option>
              <option value="LOW">Baixa</option>
            </select>
          </InputContainer>
          <InputContainer>
            <label>Grupo</label>
            <select
              style={{ width: "180px" }}
              value={task?.accessGroup}
              name="accessGroup"
              onChange={e => setTask({ ...task, accessGroup: e.target.value })}
            >
              <option value="">Selecione uma opção</option>
              {accessGroupsData?.accessGroups &&
                accessGroupsData?.accessGroups.map(group => (
                  <option key={group._id} value={group._id}>
                    {`${group.groupName}`}
                  </option>
                ))}
            </select>
          </InputContainer>
          <InputContainer>
            <label>Atribuir Tarefa Para</label>
            <select
              style={{ width: "255px" }}
              value={task?.assigned}
              name="assigned"
              onChange={e => setTask({ ...task, assigned: e.target.value })}
              disabled={!task?.accessGroup}
            >
              <option value="">Selecione uma opção</option>
              {!isLoading &&
                userData &&
                userData.users.docs.map(user => (
                  <option key={user._id} value={user._id}>
                    {`${user.name} - ${user.email}`}
                  </option>
                ))}
            </select>
          </InputContainer>
          <InputContainer>
            <label>Prazo</label>
            <input
              value={task.deadline}
              name="deadline"
              onChange={e => setTask({ ...task, deadline: e.target.value })}
              type="date"
            />
          </InputContainer>
        </div>
        <div className="row">
          <InputContainer style={{ flex: "none" }}>
            <div>
              <Switch
                name="subtask"
                initialValue={task.hasSubtask}
                handleChange={e =>
                  setTask({ ...task, hasSubtask: e.target.checked })
                }
              />
              <label>Sub-tarefas</label>
            </div>
          </InputContainer>

          <InputContainer style={{ flex: "none", marginLeft: 50 }}>
            <div className="switch">
              <Switch
                name="notify"
                initialValue={task.notify}
                handleChange={e => {
                  setTask({ ...task, notify: e.target.checked });
                }}
              />
              <label>Notificar(E-mail, Whatapp e notificação)</label>
            </div>
          </InputContainer>
        </div>
        {task.hasSubtask && (
          <>
            <div className="row" style={{ marginBottom: 10 }}>
              <InputContainer>
                <label>Sub-tarefa</label>
                <input
                  value={subtask.title}
                  name="subtask-title"
                  placeholder="Digite"
                  onChange={e =>
                    setSubtask({ ...subtask, title: e.target.value })
                  }
                />
              </InputContainer>
              <InputContainer>
                <label>Descrição da Sub-tarefa</label>
                <input
                  value={subtask.description}
                  name="subtask-description"
                  onChange={e =>
                    setSubtask({ ...subtask, description: e.target.value })
                  }
                  placeholder="Digite"
                />
              </InputContainer>
              <div className="button">
                <Button
                  type="button"
                  className="small-round"
                  style={{ marginBottom: 0 }}
                  onClick={e => {
                    e.preventDefault();
                    setTask({
                      ...task,
                      subtasks: [...task.subtasks, subtask],
                    });
                    setSubtask({
                      title: "",
                      description: "",
                    });
                  }}
                >
                  <IoAdd size={25} />
                </Button>
              </div>
            </div>
            <Datatable
              columns={[
                {
                  field: "title",
                  title: "SUB-TAREFA",
                },
                {
                  field: "description",
                  title: "DESCRIÇÃO DA SUB-TAREFA",
                },
              ]}
              rawData={task.subtasks}
              pagination={false}
            />
          </>
        )}
        <div className="row" style={{ justifyContent: "right" }}>
          <div className="button">
            <Button
              className="small-round"
              style={{ justifyContent: "right", marginTop: 10 }}
              onClick={e => {
                e.preventDefault();
                setValue("tasks", [...getValues().tasks, task]);
                setTask(INIT_TASK);
              }}
            >
              <IoAdd size={25} />
            </Button>
          </div>
        </div>

        <div className="row">
          <Datatable
            columns={[
              {
                field: "title",
                title: "TAREFA",
              },
              {
                field: "description",
                title: "DESCRIÇÃO TAREFA",
              },
              {
                field: "action",
                title: "Ação",
              },
            ]}
            rawData={(fields || []).map((item, index) => {
              const t = getValues().tasks;
              return {
                title: item.title,
                description: item.description,
                action: (
                  <Button
                    style={{
                      padding: "0px 7px",
                    }}
                    onClick={e => {
                      e.preventDefault();
                      t.splice(index, 1);
                      setValue("tasks", t);
                      setTask(INIT_TASK);
                    }}
                  >
                    <IoClose />
                  </Button>
                ),
              };
            })}
            pagination={false}
          />
        </div>
      </Card>
    </Container>
  );
};

export default TaskForm;
