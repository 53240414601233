import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0px 8px;
  gap: 15px;
`;

export const AlertDanger = styled.div`
  width: 100%;
  border: 1px solid red;
  border-radius: 3px;
  margin-bottom: 15px;
  margin-top: 15px;
  padding: 10px;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  color: #721c24;
`;
