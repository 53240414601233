import React from "react";
import { useToast } from "hooks/useToast";
import * as S from "./Copy.styles";

export const CopyLink = ({ link }) => {
  const { addToast } = useToast();

  const onCopy = () => {
    navigator.clipboard.writeText(link);
    addToast({
      type: "success",
      title: "Link copiado!",
      description: "Link copiado com sucesso!",
    });
  };

  return <S.Copy onClick={onCopy}>{link}</S.Copy>;
};
