/* eslint-disable camelcase */
import React, { useCallback, useEffect } from "react";

import { useProductData } from "useCases";

import { useProductsContext } from "context/ProductsContext";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxesStacked } from "@fortawesome/free-solid-svg-icons";

import { LoadCircle } from "components/structure";

import * as S from "./Tooltip.styles";

export const TooltipCustom = ({ index, product }) => {
  const {
    data: dataProduct,
    fetch: fetchGetProdutct,
    isLoading,
  } = useProductData();

  const { handleUpdateItemListNegativeId } = useProductsContext();

  const memoizedCallback = useCallback(
    (key, selected) => {
      return handleUpdateItemListNegativeId(key, [selected]);
    },
    [handleUpdateItemListNegativeId],
  );
  useEffect(() => {
    if (product?.id_prod && !product?.item && dataProduct) {
      memoizedCallback(product?._id, dataProduct);
    }
  }, [dataProduct, product, memoizedCallback, index]);

  useEffect(() => {
    if (product?.id_prod && !product?.item) {
      fetchGetProdutct(product?.id_prod);
    }
  }, [product, fetchGetProdutct]);

  return (
    <>
      <S.Title className="title">
        <FontAwesomeIcon
          icon={faBoxesStacked}
          style={{
            fontSize: "15px",
          }}
        />
        {` Produto vinculado`}
      </S.Title>
      <S.ContainerTooltip>
        {product?.item ? (
          <S.ContainerTooltipUL>
            <S.ContainerTooltipLi>{`Nome do produto: ${product?.item?.prod_nome}`}</S.ContainerTooltipLi>
            <S.ContainerTooltipLi>{`Categoria: ${product?.item?.categoria}`}</S.ContainerTooltipLi>
            <S.ContainerTooltipLi>{`Setor: ${product?.item?.categoria}`}</S.ContainerTooltipLi>
            <S.ContainerTooltipLi>{`Código de barras: ${product?.item?.prod_codigo_barras}`}</S.ContainerTooltipLi>
            <S.ContainerTooltipLi>{`Grupo: ${product?.item?.mer_grupo}`}</S.ContainerTooltipLi>
            <S.ContainerTooltipLi>{`Sub-Grupo: ${product?.item?.subgrupo}`}</S.ContainerTooltipLi>
            <S.ContainerTooltipLi>{`Departamento: ${product?.item?.mer_departamento}`}</S.ContainerTooltipLi>
            <S.ContainerTooltipLi>{`Categoria: ${product?.item?.categoria}`}</S.ContainerTooltipLi>
            <S.ContainerTooltipLi>{`Categoria: ${product?.item?.categoria}`}</S.ContainerTooltipLi>
          </S.ContainerTooltipUL>
        ) : (
          <>
            {isLoading ? (
              <S.ContainerLoading>
                <LoadCircle />
                <h4>Carregando...</h4>
              </S.ContainerLoading>
            ) : (
              <S.ContainerLoading>
                <h4>Sem vinculo de produto</h4>
              </S.ContainerLoading>
            )}
          </>
        )}
      </S.ContainerTooltip>
    </>
  );
};
