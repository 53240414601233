import React from "react";
import { useFormContext, useFieldArray } from "react-hook-form";
import { RangeSlider } from "components/form";
import Icon from "components/IconExporter/index";

import { Header, GetPermissionsValues } from "./Header";

import * as S from "./styles";

const rulesMap = {
  get: 2,
  post: 3,
  put: 4,
  delete: 5,
  all: 6,
};

export const Table = ({ modulesList }) => {
  const { control, setValue } = useFormContext();
  const { fields, update, append } = useFieldArray({
    control,
    name: "modules",
  });

  const handleChildPermission =
    ({ moduleId }) =>
    ({ target: { value } }) => {
      const index = fields.findIndex(module => module.moduleId === moduleId);
      const item = fields[index];
      const rules = GetPermissionsValues(value);

      setValue(`modules_copy.${moduleId}`, value);

      if (!item) {
        return append({
          moduleId,
          rules,
        });
      }

      item.rules = rules;

      update(index, item);
    };

  const handleUpdatePermission = ({ moduleId, permissionValue, module }) => {
    const index = fields.findIndex(m => m.moduleId === moduleId);

    const item = fields[index];
    const rules = GetPermissionsValues(permissionValue.target.value);

    module.childrens.map(children =>
      handleChildPermission({ moduleId: children.moduleId })(permissionValue),
    );

    setValue(`modules_copy.${moduleId}`, permissionValue.target.value);

    if (!item) {
      return append({
        moduleId,
        rules,
      });
    }

    item.rules = rules;

    update(index, item);
  };

  const getCurrentRuleRangeValue = rules => {
    const sortedRules = {
      get: rules?.get,
      post: rules?.post,
      put: rules?.put,
      delete: rules?.delete,
    };
    if (rules) {
      const index = Object.keys(sortedRules)
        .reverse()
        .findIndex(rule => sortedRules[rule]);

      if (index >= 0) {
        return rulesMap[
          Object.keys(rulesMap)[Object.keys(rules).length - index - 1]
        ];
      }
      return 1;
    }
  };

  const setAllModulesRules = () => {
    modulesList.map(module =>
      handleUpdatePermission({
        moduleId: module.moduleId,
        permissionValue: { target: { value: 6 } },
        module,
      }),
    );
  };

  return (
    <>
      <S.Table>
        <S.Thead>
          <S.TheadTR>
            {Header.map(item => {
              return (
                <S.TH>
                  <S.THDiv
                    onClick={() => {
                      setAllModulesRules(item.value);
                    }}
                  >
                    <p>{item.label}</p>
                  </S.THDiv>
                </S.TH>
              );
            })}
          </S.TheadTR>
        </S.Thead>
        <S.Tbody>
          {modulesList &&
            modulesList?.length > 0 &&
            modulesList?.map(module => {
              return (
                <>
                  <tr key={module._id}>
                    <td>
                      <div className="sub-folder-wrapper">
                        {module.parentId && (
                          <Icon name="subfolder" className="icon" />
                        )}
                        {module.name}
                      </div>
                    </td>
                    <td colSpan="9999">
                      {modulesList?.length > 0 && (
                        <S.RangeSlider>
                          <RangeSlider
                            control={control}
                            minValue={1}
                            maxValue={6}
                            step={1}
                            name={`modules_copy.${module.moduleId}`}
                            defaultValue={getCurrentRuleRangeValue(
                              module?.rules,
                            )}
                            renderValue={value =>
                              value ??
                              getCurrentRuleRangeValue(module?.rules) ??
                              null
                            }
                            handleChange={value =>
                              handleUpdatePermission({
                                module,
                                moduleId: module.moduleId,
                                permissionValue: value,
                              })
                            }
                          />
                        </S.RangeSlider>
                      )}
                    </td>
                  </tr>
                  {module?.childrens?.length > 0 &&
                    module.childrens.map(childModule => {
                      const childrenRangeSliderValue =
                        getCurrentRuleRangeValue(childModule?.rules) ?? null;

                      return (
                        <tr key={childModule._id}>
                          <td>
                            <div className="sub-folder-wrapper">
                              {childModule.fatherId && (
                                <Icon name="subfolder" className="icon" />
                              )}
                              {childModule.name}
                            </div>
                          </td>
                          <td colSpan="9999">
                            {modulesList?.length > 0 && (
                              <S.RangeSlider>
                                <RangeSlider
                                  control={control}
                                  minValue={1}
                                  maxValue={6}
                                  step={1}
                                  name={`modules_copy.${childModule.moduleId}`}
                                  renderValue={value =>
                                    value ??
                                    getCurrentRuleRangeValue(
                                      childModule?.rules,
                                    ) ??
                                    null
                                  }
                                  defaultValue={childrenRangeSliderValue}
                                  handleChange={handleChildPermission({
                                    module,
                                    moduleId: childModule.moduleId,
                                  })}
                                />
                              </S.RangeSlider>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </>
              );
            })}
        </S.Tbody>
      </S.Table>
    </>
  );
};
