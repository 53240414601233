/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { ResponsiveBar } from "@nivo/bar";
import { format } from "date-fns";
import MidCard from "components/contexts/dashboard/mid-card";
import { Format } from "utils/context/number";
import { useAnalyticsOportunidade } from "useCases/dashboard";

import * as S from "./styles";
import { Credit } from "./credit";

import {
  MidCardBlocOne,
  MidCardOverWiewTop,
  MidCardOverWiewBottom,
} from "../styles";

const DashDealChance = props => {
  return (
    <>
      <S.Container>
        <Credit
          title="Crédito Faltante"
          subTitle="Notas fiscais com menos crédito que o exigido pela lei"
          {...props}
        />
        <Credit
          title="Crédito a Maior"
          subTitle="Notas fiscais com mais crédito que o exigido pela lei"
          type="positiva"
          {...props}
        />
      </S.Container>
    </>
  );
};

export default DashDealChance;
