import React, { useState, useEffect } from "react";
import { Popover } from "@material-ui/core";
import { Pagination } from "@mix/mixfiscal-designsystem";
import produce from "immer";
import { MdFilterList } from "react-icons/md";

import { Container, FilterContainer } from "./styles";

// const columns = [
//   {
//     title: "Name",
//     field: "name",
//   },
//   {
//     title: "Age",
//     field: "age",
//   },
//   {
//     title: "E-mail",
//     field: "email",
//   },
//   {
//     title: "Gender",
//     field: "gender",
//   },
// ];

// const rawData2 = [
//   { id: 1, name: "John", email: "john@gmail.com", age: 12, gender: "Male" },
//   { id: 2, name: "Bren", email: "bren@gmail.com", age: 24, gender: "Male" },
//   { id: 3, name: "Marry", email: "marry@gmail.com", age: 18, gender: "Female" },
//   {
//     id: 4,
//     name: "Shohail",
//     email: "shohail@gmail.com",
//     age: 25,
//     gender: "Male",
//   },
//   { id: 5, name: "Aseka", email: "aseka@gmail.com", age: 19, gender: "Female" },
//   { id: 6, name: "Meuko", email: "meuko@gmail.com", age: 12, gender: "Female" },
// ];

// function DataTable({ columns, rawData }) {
function DataTable({ rawData, columns, selectableRows }) {
  const [data, setData] = useState([]);
  const [sortingDirection, setSortingDirection] = useState("");
  const [isSortingBy, setIsSortingBy] = useState("");
  const [anchorEl, setAnchorEl] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);

  const [amountPerPage, setAmountPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [searchValue, setSearchValue] = useState("");
  const [filteringColumn, setFilteringColumn] = useState("");

  useEffect(() => {
    setData(rawData);
  }, [rawData]);

  const compare = (baseState, key, currentDirection) =>
    produce(baseState, draft => {
      draft.sort((a, b) => {
        let comparisonValue = 0;
        let firstValue = a[key];
        let secondValue = b[key];

        if (currentDirection === "down")
          [firstValue, secondValue] = [secondValue, firstValue];

        if (typeof firstValue === "string") {
          if (firstValue > secondValue) {
            comparisonValue = 1;
            return 1;
          }
          if (firstValue < secondValue) {
            comparisonValue = -1;
            return -1;
          }
          if (firstValue === secondValue) {
            comparisonValue = 0;
            return 0;
          }
        }

        if (typeof firstValue === "number")
          comparisonValue = firstValue - secondValue;
        return comparisonValue;
      });
    });

  const handleSort = column => {
    let currentDirection = sortingDirection;

    if (isSortingBy !== column) {
      setIsSortingBy(column);
      setSortingDirection("");
      setData(rawData);
      currentDirection = "";
    }

    if (currentDirection === "") setSortingDirection("down");
    if (currentDirection === "down") setSortingDirection("up");
    if (currentDirection === "up") setSortingDirection("");

    if (currentDirection === "up") {
      setData(rawData);
    } else {
      const sortedData = compare(rawData, column, currentDirection);
      setData(sortedData);
    }
  };

  const handleOpenFilter = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setAnchorEl(null);
  };

  const handlerFilter = () => {
    if (!searchValue) {
      return setData(rawData);
    }
    const filteredData = rawData.filter(row =>
      row[filteringColumn]
        .toString()
        .toLowerCase()
        .includes(searchValue.toLocaleLowerCase()),
    );
    setData(filteredData);

    handleCloseFilter();
  };

  const handleSelectRow = id => {
    let newSelectedRows;
    if (selectedRows.includes(id)) {
      newSelectedRows = selectedRows.filter(rowId => rowId !== id);
    } else {
      newSelectedRows = [...selectedRows, id];
    }
    setSelectedRows(newSelectedRows);
  };

  return (
    <Container>
      <div className="tableContainer">
        <table>
          <thead>
            <tr>
              {selectableRows && (
                <th>
                  <input type="checkbox" />
                </th>
              )}
              {columns.map(column => (
                <th key={column.field} title={column.title}>
                  <div className="headerCell">
                    <div
                      role="button"
                      onClick={() => handleSort(column.field)}
                      title={column.title}
                    >
                      {column.title}
                    </div>
                    <MdFilterList
                      style={{ flexShrink: "0" }}
                      onClick={e => {
                        setFilteringColumn(column.field);
                        handleOpenFilter(e);
                      }}
                    />
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data &&
              data.length > 0 &&
              data
                .slice(
                  currentPage * amountPerPage - amountPerPage,
                  currentPage * amountPerPage,
                )
                .map(row => {
                  return (
                    <tr className={selectedRows.includes(row.id) && "selected"}>
                      {selectableRows && (
                        <td>
                          <input
                            type="checkbox"
                            onChange={() => handleSelectRow(row.id)}
                          />
                        </td>
                      )}
                      {columns.map(column => (
                        <td
                          key={`${row[column.field]}-${column.field}`}
                          title={row[column.field]}
                        >
                          {row[column.field]}
                        </td>
                      ))}
                    </tr>
                  );
                })}
          </tbody>
        </table>
      </div>
      {data && data.length > 0 && (
        <Pagination
          initialPage={1}
          totalPages={Math.ceil(data.length / amountPerPage)}
          siblingsCount={1}
          amountVariations={[10, 20, 30]}
          handleChangeAmount={value => {
            setAmountPerPage(
              typeof value !== "number" ? +value.replace(/\D/gi, "") : 10,
            );
          }}
          handlePageChange={value => setCurrentPage(value)}
        />
      )}
      <Popover
        id="popover-filter"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleCloseFilter}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <FilterContainer>
          <input
            type="text"
            value={searchValue}
            onChange={e => setSearchValue(e.target.value)}
          />
          <button type="button" onClick={handlerFilter}>
            Filtrar
          </button>
          <button
            type="button"
            onClick={() => {
              setSearchValue("");
              handlerFilter();
            }}
          >
            Limpar Filtro
          </button>
        </FilterContainer>
      </Popover>
    </Container>
  );
}

export default DataTable;
