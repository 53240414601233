import React from "react";
import { Handle } from "react-flow-renderer";

import { Container } from "./styles";

function Diamond({ data: { label } }) {
  return (
    <>
      <Container>
        <p>{label}</p>
      </Container>
      <Handle
        id="firstHandle"
        type="target"
        position="left"
        style={{
          width: "10px",
          height: "10px",
          background: "#7b867e",
          left: "-20px",
        }}
      />
      <Handle
        id="secondHandle"
        type="target"
        position="right"
        style={{
          width: "10px",
          height: "10px",
          background: "#7b867e",
          right: "-20px",
        }}
      />
      <Handle
        type="source"
        position="bottom"
        style={{
          width: "10px",
          height: "10px",
          background: "#0e7c34",
          bottom: "-20px",
        }}
      />
    </>
  );
}

export default Diamond;
