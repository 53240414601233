import React, { useState, useEffect, useRef } from "react";
import { Icon, Tooltip } from "@mix/mixfiscal-designsystem";

import { usePermissions } from "context/PermissionsContext";
import { hasCertificate } from "utils";
import * as SLocal from "./styles";

import { ListCertificates } from "./ListCertificate";

const CustomTooltip = () => {
  const [listCertificados, setListCertificados] = useState([]);
  const [selectedItem, setSelectedItem] = useState();

  const selectedData = hasCertificate({
    value: selectedItem && selectedItem.dias_vencidos,
    isList: false,
    date: selectedItem && selectedItem.certificate_due_date,
  });

  console.log({ selectedData });

  const { selectedStore } = usePermissions();
  const hiddenFileInput = useRef(null);
  const [fileSelected, setFileSelected] = useState({
    isFile: false,
    filename: "",
    filesize: "",
  });

  const handleClickUpload = () => {
    hiddenFileInput.current.click();
  };

  const handleChangeUpload = event => {
    const file = event.target.files[0];
    setFileSelected({
      isFile: true,
      filename: file.name,
      filesize: file.size,
    });
  };

  const handleSelected = item => {
    setSelectedItem(item.fadel);
  };

  useEffect(() => {
    if (selectedStore && selectedStore.length > 0) {
      const fadel = selectedStore.map(item => {
        return {
          name: item?.companyName,
          type: item?.type,
          fadel: item?.fadel,
        };
      });

      // Seta o primeiro certificado do cliente como default
      if (fadel.length && fadel[0]?.fadel) {
        setSelectedItem(fadel[0]?.fadel);
      }
      setListCertificados(fadel);
    }
  }, [selectedStore, setListCertificados]);

  return (
    <SLocal.ContainerTooltipCertificate>
      <SLocal.Title className="title">
        <SLocal.TitleIcon>
          <Icon size={50} name="certificado" color="#bababa" />
        </SLocal.TitleIcon>
        <SLocal.TitleText>
          <SLocal.TitleTextH5>Certificados</SLocal.TitleTextH5>
          <span>A1</span>
        </SLocal.TitleText>
      </SLocal.Title>
      <SLocal.ContainerTooltip>
        {selectedData ? (
          <>
            <h4>{selectedData.textLabel}</h4>
            <SLocal.ContainerTooltipCenter>
              <SLocal.DaysCertified active={selectedData.active}>
                <SLocal.DaysCertifiedH5>
                  {selectedData.days}
                </SLocal.DaysCertifiedH5>
                <SLocal.DaysCertifiedH6>dias</SLocal.DaysCertifiedH6>
              </SLocal.DaysCertified>
            </SLocal.ContainerTooltipCenter>
          </>
        ) : listCertificados && listCertificados.length === 0 ? (
          <h4>Nenhuma matriz ou loja foi selecionada</h4>
        ) : (
          <h4>Selecione um certificado na lista abaixo</h4>
        )}

        <SLocal.IconWrapper
          onClick={() => {
            handleClickUpload();
          }}
        >
          {!fileSelected.isFile && (
            <SLocal.IconWrapperItem>
              <SLocal.ButtonUpload>PROCURAR</SLocal.ButtonUpload>
            </SLocal.IconWrapperItem>
          )}
          <SLocal.IconWrapperItem>
            {fileSelected.isFile && <span>{fileSelected.filename}</span>}
            {!fileSelected.isFile && <span>Arraste seu certificado aqui</span>}
          </SLocal.IconWrapperItem>
        </SLocal.IconWrapper>
        <SLocal.InputUpload
          onChange={handleChangeUpload}
          type="file"
          ref={hiddenFileInput}
        />
        {listCertificados && listCertificados.length > 0 && (
          <>
            <ListCertificates
              listCertificados={listCertificados}
              setListCertificados={setListCertificados}
              handleSelected={handleSelected}
            />

            <ListCertificates
              isNotDateEnd
              listCertificados={listCertificados}
              setListCertificados={setListCertificados}
              handleSelected={handleSelected}
            />
          </>
        )}
      </SLocal.ContainerTooltip>
    </SLocal.ContainerTooltipCertificate>
  );
};

export const TooltipCertifield = () => {
  return (
    <Tooltip
      interactive
      isPopOver
      zIndex={999999}
      zIndexBackdrop={1}
      position="bottom"
      component={<CustomTooltip />}
    >
      <Icon size={23} name="certificado" color="#bababa" />
    </Tooltip>
  );
};
