import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-columns: 30% 70%;
  padding: 10px;

  .label-checkbox-tributary {
    display: flex;
    align-items: center;

    gap: 10px;
    padding: 5px 0;

    > div {
      width: 25px;
      min-width: 25px;
    }
  }

  .card-custom {
    margin: 0 0px 10px 0px;
    padding-bottom: 5px;
  }

  span {
    font-size: 12px;
  }

  h1 {
    font-weight: bold;
  }

  .row-eleito-trib {
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 5px 0;

    .label-checkbox {
      display: flex;
      gap: 8px;
      align-items: center;

      div {
        border: 2px solid #8c8b8b;
      }
    }
  }

  .row-legislation {
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-around;

    border: 2px solid gray;
    border-radius: 5px;
  }

  .row {
    width: 100%;

    display: flex;
    flex-direction: row;

    > div {
      width: 100%;
      padding: 2px;
    }

    .form-group-datepicker label {
      padding-left: 8px;
    }
  }

  .aliquota-icms {
    display: flex;
    flex-direction: column;

    h5 {
      padding-left: 10px;
      font-weight: bold;
      color: #304156;
    }
  }
`;
