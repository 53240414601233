import React, { useState, useEffect } from "react";
import { Format } from "utils/context";
import { format } from "date-fns";
import { Tag, Tooltip } from "@mix/mixfiscal-designsystem";
import DatePicker from "react-datepicker";
import { lastDayOfMonth } from "utils/date";
import { useAnalyticsOportunidade } from "useCases/dashboard";
import { useAuth } from "context/AuthContext";

import "react-datepicker/dist/react-datepicker.css";

import {
  StyledCredit,
  StyledCreditHeader,
  StyledCreditPercent,
  StyledCreditPercentText,
  StyledCreditValue,
  StyledGraphic,
  StyledSeparator,
  DatePickerContainer,
} from "./style";

const ValorBrutoTooltipBody = ({ valorBruto }) => (
  <>
    <label>Compra Bruto: </label>
    <strong style={{ fontWeight: "700" }}>{Format(Number(valorBruto))}</strong>
  </>
);

export const Credit = ({
  title,
  subTitle,
  monthDateStart,
  monthDateEnd,
  type = "negative",
}) => {
  const { tokenCompanies } = useAuth();

  const { data, fetch: fetchOportunidade } = useAnalyticsOportunidade();

  const [dealChanceDateStart, setDealChanceDateStart] =
    useState(monthDateStart);

  const [dealChanceDateEnd, setDealChanceDateEnd] = useState(monthDateEnd);

  useEffect(() => {
    setDealChanceDateStart(monthDateStart);
    setDealChanceDateEnd(monthDateEnd);
  }, [monthDateStart, monthDateEnd]);

  const ExampleCustomInput = React.forwardRef(({ onClick }, ref) => {
    const period = `${format(new Date(dealChanceDateStart), "LLL/yyyy")}`;

    return (
      <Tag label={period} handleClick={onClick} ref={ref}>
        {period}
      </Tag>
    );
  });

  const onChange = dateValue => {
    setDealChanceDateStart(dateValue);
    setDealChanceDateEnd(lastDayOfMonth(dateValue));
  };

  useEffect(() => {
    fetchOportunidade({
      dt_ini: format(dealChanceDateStart, "ddMMyyyy"),
      dt_fim: format(dealChanceDateEnd, "ddMMyyyy"),
    });
  }, [
    dealChanceDateStart,
    dealChanceDateEnd,
    fetchOportunidade,
    tokenCompanies,
  ]);

  const value =
    (type === "negative"
      ? data?.data?.diferenca_negativa
      : data?.data?.diferenca_positiva) ?? 0;
  const percent =
    (type === "negative"
      ? data?.data?.porcentagem_negativa
      : data?.data?.porcentagem_positiva) ?? 0;

  return (
    <StyledCredit>
      <StyledCreditHeader>
        <h1>{title}</h1>
        <small>{subTitle}</small>
      </StyledCreditHeader>
      <StyledCreditValue>
        <>
          <div style={{ width: 100, height: 50 }}>
            {Format(parseFloat(value))}
          </div>
          <StyledSeparator />
        </>
      </StyledCreditValue>
      <StyledCreditPercent>
        <StyledCreditPercentText>
          <span>{`${parseInt(percent, 10)}%`}</span>
          <p>Em relação a compra bruta</p>
        </StyledCreditPercentText>

        <StyledGraphic>
          <div
            className="graphic-1"
            style={{ width: `${parseInt(percent, 10)}%` }}
          />
          <Tooltip
            body={
              <ValorBrutoTooltipBody valorBruto={data?.data?.valor_nota ?? 0} />
            }
            position="top"
          >
            <div className="graphic-2" style={{ width: "100%" }} />
          </Tooltip>
        </StyledGraphic>
      </StyledCreditPercent>
      <DatePickerContainer>
        <DatePicker
          selected={monthDateStart}
          onChange={onChange}
          dateFormat="MM/yyyy"
          showMonthYearPicker
          customInput={<ExampleCustomInput />}
          locale="ptBR"
        />
      </DatePickerContainer>
    </StyledCredit>
  );
};
