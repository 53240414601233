import styled from "styled-components";

export const Container = styled.div`
  width: 175px;
  height: 125px;
  background-color: #fff;
  border-radius: 5px;
  position: relative;
  padding: 8px;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;

  p {
    width: 100%;
    color: #727e8c;
    font-size: 9px;
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
  }

  input {
    font-size: 14px;
    border-bottom: 1px solid #bababa;
  }

  button {
    padding: 4px 8px;
  }
`;
