/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import TributeCard from "components/contexts/dashboard/card-tributes";
import TributeCardInner from "components/contexts/dashboard/card-tributes/card-inner";
import TributeFooterDetail from "components/contexts/dashboard/card-tributes/tribute-footer-detail";
import { ResponsivePie } from "@nivo/pie";
import TagsListMonth from "components/contexts/dashboard/tags-list-month";
import { format } from "date-fns";
import { useAuth } from "context/AuthContext";
import {
  useAnalyticsIcms,
  useAnalyticsOportunidadeIcmsPisTotal,
} from "useCases/dashboard";
import { TooltipChart } from "../Tooltip";

function DashTributesIcms({ monthDateStart, monthDateEnd, fileType }) {
  const [tributesDateStart, setTributesDateStart] = useState(monthDateStart);
  const [tributesDateEnd, setTributesDateEnd] = useState(monthDateEnd);
  const [dataTributesIncoming, setDataTributesIncoming] = useState([]);
  const [dataTributesOutput, setDataTributesOutput] = useState([]);

  const {
    data: dataIcms,
    fetch: fetchIcms,
    // isLoading: isLoadingIcms,
  } = useAnalyticsIcms();

  const { data: dataIcmsTotal, fetch: fetchIcmsTotal } =
    useAnalyticsOportunidadeIcmsPisTotal();

  const { tokenCompanies } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [tag, setTag] = useState(fileType ?? "XML");

  const getData = async (
    dtStart,
    dtEnd,
    itemTag,
    { entries = [], outs = [] },
  ) => {
    try {
      setIsLoading(true);

      setDataTributesIncoming(
        entries.map(item => {
          return {
            id: item.aliq === 0 ? "0%" : `${item.aliq}%`,
            porcentagem: item.porcentagem,
            value: item.valor,
          };
        }),
      );

      setDataTributesOutput(
        outs.map(item => {
          return {
            id: `${item.aliq}%`,
            value: item.valor,
            porcentagem: item.porcentagem,
          };
        }),
      );
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const handleClick = (start, end) => {
    setTributesDateStart(start);
    setTributesDateEnd(end);
  };

  const handleClickType = item => {
    setTag(item);
  };

  useEffect(() => {
    if (dataIcms?.data) {
      const data = dataIcms?.data.map(item => ({
        ...item,
        aliq: Number(item.aliq),
        porcentagem: item.porcentagem,
        tipo_movimento: item.tipo_movimento,
        valor: Number(item.valor),
      }));
      const [entries, outs] = [
        data.filter(item => item.tipo_movimento === "ENTRADA"),
        data.filter(item => item.tipo_movimento === "SAIDA"),
      ];
      getData(tributesDateStart, tributesDateEnd, tag, {
        entries,
        outs,
      });
    }
  }, [dataIcms?.data]);

  useEffect(() => {
    const fields = {
      issue_date_start: format(new Date(tributesDateStart), "yyyy-MM-dd"),
      issue_date_end: format(new Date(tributesDateEnd), "yyyy-MM-dd"),
      tipo: tag,
    };

    fetchIcms(fields);
    fetchIcmsTotal(fields);
  }, [tributesDateStart, tributesDateEnd, fetchIcms, tokenCompanies, tag]);

  useEffect(() => {
    setTributesDateStart(monthDateStart);
    setTributesDateEnd(monthDateEnd);
  }, [monthDateStart, monthDateEnd]);

  return (
    <>
      <TributeCard
        title="Perfil Entrada e Saída ICMS"
        subtitle="Alíquota x Valor"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            height: "120px",
          }}
        >
          <div
            style={{
              width: "55%",
            }}
          >
            <TributeCardInner titleLeft="Entrada" isTagFooter>
              <ResponsivePie
                data={dataTributesIncoming}
                margin={{ top: 10, right: 1, bottom: 80, left: 1 }}
                innerRadius={0.7}
                activeInnerRadiusOffset={4}
                activeOuterRadiusOffset={8}
                colors={{ scheme: "accent" }}
                value="porcentagem"
                borderColor={{
                  from: "color",
                  modifiers: [["opacity", "0"]],
                }}
                enableArcLinkLabels={false}
                arcLabelsSkipAngle={12}
                arcLabelsTextColor="black"
                legends={[
                  {
                    anchor: "bottom-left",
                    direction: "row",
                    justify: false,
                    translateX: 15,
                    translateY: 70,
                    itemsSpacing: 14,
                    itemWidth: 20,
                    itemHeight: 38,
                    itemTextColor: "#999",
                    itemDirection: "top-to-bottom",
                    itemOpacity: 1,
                    symbolSize: 10,
                    symbolShape: "square",
                    effects: [
                      {
                        on: "hover",
                        style: {
                          itemTextColor: "#000",
                        },
                      },
                    ],
                  },
                ]}
                arcLabel={d => {
                  return d.id;
                }}
                tooltip={TooltipChart}
              />
              {/* <div style={{ marginTop: "60px" }}>
                <TagFooterList
                  tag={fileType}
                  tagsListStore={["Sped Fiscal", "XML"]}
                  tags={[
                    "XML",
                    {
                      dtStart: tributesDateStart,
                      dtEnd: tributesDateEnd,
                    },
                  ]}
                  onChangeDate={(start, end) =>
                    handleClick(start, end, "piscofins")
                  }
                  onChangeType={item => handleClickType(item, "piscofins")}
                />
              </div> */}
            </TributeCardInner>
          </div>
          <div
            style={{
              width: "45%",
              height: "100px",
              zIndex: 1,
            }}
          >
            <TributeCardInner titleRight="Saída">
              <ResponsivePie
                data={dataTributesOutput}
                margin={{ top: 10, right: 1, bottom: 80, left: 1 }}
                innerRadius={0.7}
                activeInnerRadiusOffset={4}
                activeOuterRadiusOffset={8}
                colors={{ scheme: "accent" }}
                borderColor={{
                  from: "color",
                  modifiers: [["opacity", "0"]],
                }}
                enableArcLinkLabels={false}
                arcLabelsSkipAngle={12}
                arcLabelsTextColor="black"
                value="porcentagem"
                legends={[
                  {
                    anchor: "bottom-left",
                    direction: "row",
                    justify: false,
                    translateX: 10,
                    translateY: 70,
                    itemsSpacing: 10,
                    itemWidth: 15,
                    itemHeight: 38,
                    itemTextColor: "#999",
                    itemDirection: "top-to-bottom",
                    itemOpacity: 1,
                    symbolSize: 10,
                    symbolShape: "square",
                    legendFormat: d => {
                      return d.value;
                    },
                  },
                ]}
                arcLabel={d => `${d.label}`}
                tooltip={TooltipChart}
              />
              <TributeFooterDetail
                title="Prévia ICMS a Pagar"
                subTitle="Período"
                value={Number(
                  dataIcmsTotal?.data?.valor_icms || 0,
                ).toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}
                percent={`${Number(
                  dataIcmsTotal?.data?.porcentagem_icms,
                ).toFixed(2)}%`}
                smallText="Em relação ao faturamento bruto"
              />
            </TributeCardInner>
            {isLoading && <CircularProgress style={{ color: "#A2C659" }} />}
          </div>
        </div>

        <div style={{ marginTop: "145px" }}>
          <TagsListMonth
            tag={fileType}
            addMonthsLength={0}
            typeList="month"
            tagsListStore={["XML", "Sped Fiscal"]}
            tags={[
              "XML",
              {
                dtStart: tributesDateStart,
                dtEnd: tributesDateEnd,
              },
            ]}
            onChangeDate={(start, end) => handleClick(start, end, "piscofins")}
            onChangeType={item => handleClickType(item, "piscofins")}
          />
        </div>
      </TributeCard>
    </>
  );
}

export default DashTributesIcms;
