import styled, { css } from "styled-components";

const customCardOption = {
  setCustomCard: active => css`
    ${active
      ? `
    border-left: 3px solid #adc86a;
    `
      : `
    border-left: 3px solid #c86a6a;
    `},
  `,
};

export const OptionsCustom = styled.div`
  width: 100%;
  padding: 0px;
  display: flex;
  padding: 2px 0 2px 8px;
  margin: 5px 0 5px 0;
  flex-direction: column;
  border-bottom: 1px solid #f3f3f3;
  ${({ active }) => customCardOption.setCustomCard(active)}
`;

export const OptionsCustomLabel = styled.div`
  display: flex;
  flex-direction: column;
  color: #000;
`;

export const OptionsCustomLabelText = styled.div`
  flex: 0.8;
  display: flex;
  flex-direction: column;
  b {
    font-weight: bold;
  }
`;

export const OptionsCustomLabelTextItem = styled.div``;

const customTextFontSize = {
  fontSize: size => css`
    font-size: ${size};
  `,
};

export const OptionsCustomLabelTextItemFontFize = styled.h1`
  ${({ fontSize }) => customTextFontSize.fontSize(fontSize)}
`;

export const OptionsCustomLabelItems = styled.div`
  width: 100%;
  flex: 0.2;
  text-align: right;
  display: flex;
  flex-direction: column;
`;

export const OptionsCustomLabelItemsBadge = styled.div`
  display: flex;
  flex-direction: row;
`;

const customBadge = {
  setType: type => css`
    ${type === `MATRIZ` &&
    `
    background-color: #adc86a;
    color:#fff
    `}
    ${type === `Matriz` &&
    `
    background-color: #adc86a;
    color:#fff
    `}
    ${type === `ativo` &&
    `
    background-color: #adc86a;
    color:#fff
    `}
    ${type === `Filial` &&
    `
    background-color: #17456B;
    color:#fff
    `}
     ${type === `FILIAL` &&
    `
    background-color: #17456B;
    color:#fff
    `}
      ${type === `inativo` &&
    `
    background-color: #c86a6a;
    color:#fff
    `}
  `,
};

export const Cnpj = styled.div`
  font-weight: bold;
  color: #000;
  font-size: 12px;
`;
export const Badge = styled.span`
  ${({ type }) => css`
    min-width: 30px;
    font-size: 12px;
    padding: 0px 10px 0px 10px;
    border-radius: 20px;
    font-weight: bold;
    margin: 0 4px 0 4px;
    ${type && customBadge.setType(type)}
  `}
`;

const dot = (color = "#acacac") => ({
  alignItems: "center",
  display: "flex",

  ":before": {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: "block",
    marginRight: 8,
    height: 10,
    width: 10,
  },
});

export const CustomStyles = {
  menu: base => ({
    ...base,
    width: "100%",
    minHeight: "40px",
    padding: "10px 0px 0px 0px",
  }),
  container: base => ({
    ...base,
    width: "100%",
    minWidth: "100%",
  }),
  option: provided => ({
    ...provided,
    padding: "0 3px",
    fontSize: 13,
    cursor: "pointer",
  }),

  control: (styles, { selectProps, isDisabled }) => {
    const extraStyles = {};

    if (selectProps.variant === "simple") {
      extraStyles.border = "none";
      extraStyles.borderBottom = "1px solid #596778";
      extraStyles.borderRadius = 0;
      extraStyles["&:hover"] = {
        borderColor: "#596778",
        boxShadow: "none",
      };
    }

    extraStyles.border = "1px solid #596778";

    if (isDisabled) {
      extraStyles.border = "1px solid #cacccf";
      extraStyles["& svg"] = {
        fill: "#cacccf",
      };
    }

    return {
      ...styles,
      width: `100%`,
      backgroundColor: "white",
      fontSize: 12,
      cursor: "pointer",
      ...extraStyles,
    };
  },
  indicatorSeparator: styles => ({
    ...styles,
    width: 0,
  }),

  valueContainer: styles => ({
    ...styles,
    with: `100%`,
  }),
  input: styles => ({ ...styles, ...dot() }),
  placeholder: styles => ({ ...styles, ...dot("#ccc") }),
  singleValue: (styles, state) => {
    const opacity = state.isDisabled ? 1 : 1;
    return {
      ...styles,
      fontSize: 14,
      opacity,
    };
  },
};

// TODO: CERTIFICATE

export const Title = styled.p`
  display: flex;
  flex-wrap: nowrap;
  border-bottom: 0;
  padding: 0px 8px 0px 8px !important;
  h5 {
    font-size: 12px;
    color: #000;
  }
`;

export const TitleText = styled.div`
  flex: 75%;
  line-height: 27px;
`;

export const ContainerTooltip = styled.div`
  align-items: center;
  display: block;
  padding: 0px 8px 6px 8px;
  h4 {
    padding-top: 3px;
    color: #949494;
  }
`;

export const DaysCertified = styled.div`
  opacity: 1;
  width: 65px;
  height: 65px;
  margin: 0 auto;
  text-align: center;
  border-radius: 100%;
  background-color: ${({ active }) => (active ? "#adc86a" : "#c86a6a")};
  vertical-align: middle;
  line-height: 17px;
  padding-top: 16px;
  margin-bottom: 10px;
  box-shadow: 0px 3px 6px #00000029;
  h5 {
    font-size: 22px;
    font-weight: bold;
    color: #fff !important;
  }
  h6 {
    font-size: 13px;
    font-weight: bold;
    color: #fff !important;
  }
`;
