import React from "react";
import { TooltipMenuContainer } from "./styles";

export const TooltipMenu = props => {
  const { ...obj } = props;
  return (
    <TooltipMenuContainer>
      <div>{obj.title}</div>
      <div>{obj.body}</div>
    </TooltipMenuContainer>
  );
};
