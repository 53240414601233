import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

export const BaseModule = ({ children }) => {
  const history = useHistory();
  const [location, setLocation] = useState(history.location);

  useEffect(() => {
    const unlisten = history.listen(_nextLocation => {
      setLocation(_nextLocation);
    });

    return () => unlisten();
  }, [history, location]);

  const [, module, ...rest] = location.pathname.split("/");
  const modulePath = `/${rest.join("/")}`;

  return children(module, modulePath, history);
};
