import styled from "styled-components";

export const List = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
  gap: 10px;
`;

export const ListItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding: 10px;
  min-height: 100px;
  border: 1px solid #256925;
  border-radius: 4px;
  position: relative;
`;

export const ListItemActions = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
`;
