import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;

  .searchBox {
    display: flex;
    align-items: center;
    border-radius: 8px;
    flex: 1;
    margin-right: 8px;
    padding: 4px 8px;
    background-color: #f2f4f4;
    align-self: stretch;

    input {
      flex: 1;
      color: #596778;
      font-size: 14px;
      background-color: inherit;

      ::placeholder {
        color: inherit;
      }
    }

    svg {
      margin-right: 8px;
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f2f4f4;
    color: #596778;
    padding: 4px 8px;
    border-radius: 8px;

    font-size: 18px;
    font-weight: bold;

    svg {
      fill: #596778;
      margin-right: 8px;
    }
  }
`;

export const SectionContainer = styled.div`
  display: flex;
`;

export const ListContainer = styled.div`
  width: 520px;
  height: 270px;
  overflow: auto;
  padding-right: 14px;

  p.text {
    width: 100%;
    font-size: 12px;
    font-weight: bold;
    color: #596778;
    background-color: #fff;
    transition: all 0.2s;
    padding: 4px 16px;
    cursor: pointer;
    border: 1px solid #e5e5e5;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:not(:last-of-type) {
      border-bottom: none;
    }
  }

  p.text:hover,
  p.text:active {
    background-color: #e5e5e5;
  }

  ::-webkit-scrollbar {
    width: 4px;
    cursor: pointer;
  }

  ::-webkit-scrollbar-track {
    background-color: #bababa;
    cursor: pointer;
  }

  ::-webkit-scrollbar-thumb {
    background: #565656;
    border-radius: 20px;
    cursor: pointer;
  }
`;

export const AsideContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 21px;

  & > div {
    flex: 1;
  }

  .selectedFile {
    background-color: #f2f4f4;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 24px 14px;
    margin-bottom: 10px;

    p {
      color: #596778;
      font-size: 18px;
      font-weight: bold;
      text-transform: uppercase;
    }

    svg {
      margin-bottom: 18px;
    }
  }

  .fileInfo {
    background-color: #f2f4f4;
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 5px 14px;

    display: flex;
    justify-content: center;
    flex-direction: column;

    p {
      color: #596778;
      font-size: 12px;
    }

    span {
      font-weight: bold;
      margin: 0 2px;
    }

    .bold {
      font-weight: bold;
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f2f4f4;
    color: #596778;
    padding: 4px 8px;
    border-radius: 8px;

    font-size: 18px;
    font-weight: bold;

    svg {
      fill: #596778;
      margin-right: 8px;
    }
  }
`;
