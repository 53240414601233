import styled from "styled-components";
import * as TextFieldStyles from "components/form/TextField/styles";

export const FieldRow = styled.div`
  flex: 1;
`;

export const ContainerFilter = styled.div`
  display: flex;
  gap: 1rem;
`;

export const FieldSearch = styled(FieldRow)`
  & > div > div {
    margin: 0;
  }

  & ${TextFieldStyles.InputField} {
    width: 100%;
    padding-left: 1rem;
  }
`;

export const Action = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  gap: 15px;
`;

export const FieldSwitch = styled.div`
  margin-top: 1rem;
`;
