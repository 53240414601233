import React from "react";
import * as S from "./Upload.styles";
import * as SForm from "../ProductForm.styles";

export const Upload = ({
  control,
  uploadFile,
  setUploadFile,
  preview,
  setPreview,
}) => {
  const handleImageUpload = event => {
    setUploadFile(event);
    setPreview(URL.createObjectURL(event));
  };
  const getDroppedImage = event => {
    setUploadFile(event);
    setPreview(URL.createObjectURL(event));
  };

  return (
    <SForm.ColumnFormItem
      padding="0px 0px 0px 0px"
      isPaddingInput="8px"
      height="100%"
    >
      <S.UploadFile>
        <S.UploadFileItem>
          <S.InputFileInput
            type="file"
            name="logo"
            control={control}
            onChange={e => handleImageUpload(e.target.files[0])}
            onDrop={e => getDroppedImage(e.dataTransfer.files[0])}
            accept="image/jpeg, image/jpg, image/png"
          />
          {preview && (
            <S.InputOverlay>
              <img src={preview} alt="" />
            </S.InputOverlay>
          )}
          <S.InputOverlay
            isPreview={preview || false}
            isOver={uploadFile || false}
          >
            <S.InputText margin={preview ? `90px 0 0 0` : `50px 0 0 0`}>
              {uploadFile ? (
                <S.ButtonText
                  color="#FD5962"
                  type="button"
                  onClick={() => {
                    setUploadFile("");
                    setPreview("");
                  }}
                >
                  REMOVER
                </S.ButtonText>
              ) : (
                <S.ButtonText
                  color="transparent"
                  type="button"
                  onClick={() => {
                    setUploadFile("");
                    setPreview("");
                  }}
                >
                  PROCURAR
                </S.ButtonText>
              )}
              {uploadFile ? (
                <S.TextLabel>
                  {/* <p>{uploadFile.name || uploadFile}</p> */}
                </S.TextLabel>
              ) : (
                <S.TextLabel>
                  <p>ou Arraste aqui</p>
                </S.TextLabel>
              )}
            </S.InputText>
            {!uploadFile && (
              <S.InputText margin={preview ? `30px 0 0 0` : `52px 0 0 0`}>
                <p>
                  Para melhor visibilidade do produto a largura e altura da
                  imagem e 1200x1200
                </p>
              </S.InputText>
            )}
          </S.InputOverlay>
        </S.UploadFileItem>
      </S.UploadFile>
    </SForm.ColumnFormItem>
  );
};
