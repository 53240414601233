import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding: 0 8px 0px 0px;

  display: flex;
  gap: 24px;

  div {
    width: 100%;
  }

  .edit-icon {
    cursor: pointer;

    path {
      fill: #565656;
      transition: all 0.1s ease-in-out;
    }

    &:hover {
      path {
        fill: #adc86a;
      }
    }
  }
`;

export const Iframe = styled.iframe`
  width: 100%;
  min-height: 400px;
`;
