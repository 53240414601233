import React from "react";

import { Container, Icon } from "./styles";

function LoadingIcon({
  width = "100%",
  height = "60px",
  marginTop = "0",
  color = "#0e7c34",
  size = 24,
  isLoading,
}) {
  return (
    <Container width={width} height={height} marginTop={marginTop}>
      <Icon color={color} size={size} isLoading={isLoading} />
    </Container>
  );
}

export default LoadingIcon;
