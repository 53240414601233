import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { Tooltip, Datatable } from "@mix/mixfiscal-designsystem";
import {
  useAnalyticsPurchaseCnpj,
  useAnalyticsSalesCnpj,
} from "useCases/dashboard";
import { useStores } from "useCases/store";
import { formatCnpj, formatCurrency } from "utils/utils";
import { LoadPrefix } from "components/structure/Load";

import * as S from "../styles";
import * as SLocal from "./styles";

const ListCnpjTotals = ({ items = [], stores = [], isLoading = true }) => {
  return (
    <SLocal.Wrapper>
      <Datatable
        isLoading={isLoading}
        columns={[
          {
            field: "nome",
            title: "Nome",
          },
          { field: "cnpj", title: "CNPJ" },
          {
            field: "total",
            title: "Valor",
          },
        ]}
        pagination={false}
        rawData={items.map(item => ({
          _id: item.cnpj,
          cnpj: formatCnpj(item.cnpj),
          nome:
            item?.store?.nome ??
            stores?.find(store => store.cnpj === item.cnpj)?.companyName ??
            stores?.find(store => store.cnpj === item.cnpj)?.tradeName,
          total: formatCurrency(item.total),
        }))}
      />
    </SLocal.Wrapper>
  );
};

const CustomTooltip = ({ type = "vendas", typeTag = "XML", dtInit, dtFim }) => {
  const {
    data: dataPurchases,
    isLoading: isLoadingPurchases,
    fetch: fetchPurchases,
  } = useAnalyticsPurchaseCnpj();
  const {
    data: dataSales,
    isLoading: isLoadingSales,
    fetch: fetchSales,
  } = useAnalyticsSalesCnpj();

  const data = type === "vendas" ? dataSales : dataPurchases;
  const isLoading = type === "vendas" ? isLoadingSales : isLoadingPurchases;

  const { data: dataStore, isLoading: isLoadingStore } = useStores(
    !isLoading
      ? {
          cnpj: JSON.stringify(data?.data?.map(item => item.cnpj)),
          page: 1,
          limit: 250,
        }
      : null,
  );

  useEffect(() => {
    const fields = {
      dt_ini: dtInit,
      dt_fim: dtFim,
      tipo: typeTag,
    };
    if (type === "vendas") {
      fetchSales(fields);
    } else {
      fetchPurchases(fields);
    }
  }, [type, typeTag, dtInit, dtFim, fetchPurchases, fetchSales]);

  return (
    <>
      <SLocal.Title className="title">
        {`${type === "vendas" ? "Vendas" : "Compras"} por CNPJ (${
          !isLoadingStore ? dataStore?.stores?.docs.length : 0
        })`}
      </SLocal.Title>
      <S.ContainerTooltip>
        <ListCnpjTotals
          stores={dataStore?.stores?.docs}
          isLoading={isLoading || isLoadingStore}
          items={data?.data}
        />
      </S.ContainerTooltip>
      <SLocal.Footer>
        <SLocal.FooterContainer>
          <SLocal.TextBold>Total</SLocal.TextBold>
          <SLocal.TextBold>
            {!isLoading ? (
              formatCurrency(
                data?.data?.reduce((p, c) => p + Number(c.total), 0) ?? 0,
              )
            ) : (
              <LoadPrefix />
            )}
          </SLocal.TextBold>
        </SLocal.FooterContainer>
      </SLocal.Footer>
    </>
  );
};

export const TooltipValues = props => (
  <>
    <div style={{ width: 30, height: 30 }}>
      <Tooltip
        isPopOver
        zIndex={999999}
        position="top"
        component={<CustomTooltip {...props} />}
      >
        <FontAwesomeIcon icon={faEye} />
      </Tooltip>
    </div>
  </>
);
