import styled from "styled-components";

export const PermissionWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  border: 1px solid #596778;
  border-radius: 5px;
  padding: 4px 16px;
`;

export const PermissionWrapperItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 10px 0 10px;
`;

export const PermissionWrapperItemLabel = styled.label`
  color: #565656;
  font-weight: bold;
  font-size: 13px;
  margin: 0 0 0 8px;
`;
