import styled from "styled-components";

export const Container = styled.div`
  width: 85px;
  height: 85px;
  background-color: #f27c2a;
  transform: rotate(45deg);
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    transform: rotate(-45deg);
    text-transform: uppercase;
    text-align: center;
  }
`;
