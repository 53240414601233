import React from "react";
import * as S from "./styles";

export const CustomTooltip = ({ dias }) => {
  const active = value => {
    if (value && value.toString().substr(0, 1) !== `-`) {
      return {
        title: `Seu certificado venceu tem:`,
        active: false,
      };
    }
    return {
      title: `Seu certificado vence em:`,
      active: true,
    };
  };
  return (
    <>
      <S.Title>
        <S.TitleText>
          <h5>Certificados A1</h5>
        </S.TitleText>
      </S.Title>
      <S.ContainerTooltip>
        <h4>{active(dias).title}</h4>
        <S.DaysCertified active={active(dias).active}>
          {dias ? (
            <h5>
              {dias.toString().substr(0, 1) === `-`
                ? dias.toString().substr(1)
                : dias}
            </h5>
          ) : (
            <h5>0</h5>
          )}

          <h6>dias</h6>
        </S.DaysCertified>
      </S.ContainerTooltip>
    </>
  );
};
