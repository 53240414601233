/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import TributeCard from "components/contexts/dashboard/card-tributes";
import TributeCardInner from "components/contexts/dashboard/card-tributes/card-inner";
import TributeFooterDetail from "components/contexts/dashboard/card-tributes/tribute-footer-detail";
import { ResponsivePie } from "@nivo/pie";
import { useAuth } from "context/AuthContext";

import TagsListMonth from "components/contexts/dashboard/tags-list-month";
import { format } from "date-fns";
import {
  useAnalyticsPisCofins,
  useAnalyticsOportunidadeIcmsPisTotal,
} from "useCases/dashboard";
import { TooltipChart } from "../Tooltip";

export const CST = {
  entry: [
    50, 51, 52, 53, 54, 55, 56, 60, 61, 62, 63, 64, 65, 66, 67, 70, 71, 72, 73,
    74, 75, 98, 99,
  ],
  entryNotPay: [70, 71, 72, 73, 74, 75, 95, 99],

  out: [1, 2, 3, 4, 5, 6, 7, 8, 9, 49],
  outNotPay: [4, 5, 6, 7, 8, 9],
};

export const hasFee = cst =>
  [...CST.entryNotPay, ...CST.outNotPay].includes(cst);

function DashTributesPisCofins(props) {
  const { monthDateStart, monthDateEnd, fileType } = props;
  const [tributesDateStart, setTributesDateStart] = useState(monthDateStart);
  const [tributesDateEnd, setTributesDateEnd] = useState(monthDateEnd);
  const { tokenCompanies } = useAuth();

  const { data: dataPiscofins, fetch: fetchPisCofins } =
    useAnalyticsPisCofins();

  const { data: dataIcmsTotal, fetch: fetchIcmsTotal } =
    useAnalyticsOportunidadeIcmsPisTotal();
  const [dataTributesIncoming, setDataTributesIncoming] = useState([]);
  const [dataTributesOutput, setDataTributesOutput] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tag, setTag] = useState(fileType ?? "XML");

  const getData = async ({ entries = [], outs = [] }) => {
    try {
      setDataTributesIncoming(
        entries.map(entry => ({
          id: `${entry.aliq}%`,
          porcentagem: entry.porcentagem,
          value: entry.valor,
        })),
      );

      setDataTributesOutput(
        outs.map(out => ({
          id: `${out.aliq}%`,
          porcentagem: out.porcentagem,
          value: out.valor,
        })),
      );
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const handleClick = (start, end) => {
    setTributesDateStart(start);
    setTributesDateEnd(end);
  };

  const handleClickType = item => setTag(item);

  useEffect(() => {
    if (tag && tributesDateEnd && dataPiscofins?.data) {
      const data = (dataPiscofins?.data ?? []).map(item => ({
        aliq: Number(item.aliq),
        porcentagem: Number(item.porcentagem),
        tipo_movimento: item.tipo_movimento,
        valor: Number(item.valor),
      }));

      const [entries, outs] = [
        data.filter(item => item.tipo_movimento === "ENTRADA"),
        data.filter(item => item.tipo_movimento === "SAIDA"),
      ];

      getData({
        entries,
        outs,
      });
    }
  }, [dataPiscofins?.data]);

  useEffect(() => {
    const fields = {
      issue_date_start: format(new Date(tributesDateStart), "yyyy-MM-dd"),
      issue_date_end: format(new Date(tributesDateEnd), "yyyy-MM-dd"),
      tipo: tag,
    };
    fetchPisCofins(fields);
    fetchIcmsTotal(fields);
  }, [tag, tributesDateStart, tributesDateEnd, fetchPisCofins, tokenCompanies]);

  useEffect(() => {
    setTributesDateStart(monthDateStart);
    setTributesDateEnd(monthDateEnd);
  }, [monthDateStart, monthDateEnd]);

  return (
    <>
      <TributeCard
        title="Perfil Entrada e Saída Pis/Cofins"
        subtitle="Alíquota x Valor"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            height: "120px",
          }}
        >
          <div
            style={{
              width: "55%",
              zIndex: 1,
            }}
          >
            <TributeCardInner titleLeft="Entrada" isTagFooter>
              <ResponsivePie
                data={dataTributesIncoming ?? []}
                margin={{ top: 5, right: 1, bottom: 80, left: 1 }}
                innerRadius={0.7}
                activeInnerRadiusOffset={4}
                activeOuterRadiusOffset={8}
                colors={{ scheme: "accent" }}
                borderColor={{
                  from: "color",
                  modifiers: [["opacity", "0"]],
                }}
                value="porcentagem"
                enableArcLinkLabels={false}
                arcLabelsSkipAngle={12}
                arcLabelsTextColor="black"
                legends={[
                  {
                    anchor: "bottom-left",
                    direction: "row",
                    justify: false,
                    translateX: 15,
                    translateY: 70,
                    itemsSpacing: 10,
                    itemWidth: 25,
                    itemHeight: 38,
                    itemTextColor: "#999",
                    itemDirection: "top-to-bottom",
                    itemOpacity: 1,
                    symbolSize: 10,
                    symbolShape: "square",
                    effects: [
                      {
                        on: "hover",
                        style: {
                          itemTextColor: "#000",
                        },
                      },
                    ],
                  },
                ]}
                arcLabel={d => d.label}
                tooltip={TooltipChart}
              />
            </TributeCardInner>
          </div>
          <div
            style={{
              width: "45%",
              height: "100px",
              zIndex: 1,
            }}
          >
            <TributeCardInner titleRight="Saída">
              <ResponsivePie
                data={dataTributesOutput}
                margin={{ top: 10, right: 1, bottom: 80, left: 1 }}
                innerRadius={0.7}
                activeInnerRadiusOffset={4}
                activeOuterRadiusOffset={8}
                colors={{ scheme: "accent" }}
                borderColor={{
                  from: "color",
                  modifiers: [["opacity", "0"]],
                }}
                enableArcLinkLabels={false}
                arcLabelsSkipAngle={12}
                arcLabelsTextColor="black"
                value="porcentagem"
                legends={[
                  {
                    anchor: "bottom-left",
                    direction: "row",
                    justify: false,
                    translateX: 10,
                    translateY: 70,
                    itemsSpacing: 10,
                    itemWidth: 25,
                    itemHeight: 38,
                    itemTextColor: "#999",
                    itemDirection: "top-to-bottom",
                    itemOpacity: 1,
                    symbolSize: 10,
                    symbolShape: "square",
                    effects: [
                      {
                        on: "hover",
                        style: {
                          itemTextColor: "#000",
                        },
                      },
                    ],
                  },
                ]}
                arcLabel={d => `${d.label}`}
                tooltip={TooltipChart}
              />
              <TributeFooterDetail
                title="Previa Pis/Cofins a Pagar"
                subTitle="Período"
                value={Number(
                  dataIcmsTotal?.data?.valor_piscofins || 0,
                ).toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}
                percent={`${Number(
                  dataIcmsTotal?.data?.porcentagem_piscofins || 0,
                ).toFixed(2)}%`}
                smallText="Em relação ao faturamento bruto"
              />
            </TributeCardInner>
            {isLoading && <CircularProgress style={{ color: "#A2C659" }} />}
          </div>
        </div>
        <div style={{ marginTop: "145px" }}>
          <TagsListMonth
            tag={fileType}
            addMonthsLength={0}
            tags={[
              "XML",
              {
                dtStart: tributesDateStart,
                dtEnd: tributesDateEnd,
              },
            ]}
            tagsListStore={["XML", "Sped Contribuições"]}
            onChangeDate={(start, end) => handleClick(start, end, "piscofins")}
            onChangeType={item => handleClickType(item, "piscofins")}
          />
        </div>
      </TributeCard>
    </>
  );
}

export default DashTributesPisCofins;
