import styled, { css } from "styled-components";

export const Container = styled.div`
  padding: 6px 10px;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 6px 10px;
  width: 100%;
`;

const marginItem = {
  setMargin: margin => css`
    margin: ${margin};
  `,
};

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.slateGray};
  font-family: ${({ theme }) => theme.font.family};
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0px;
  line-height: 24px;
  opacity: 1;
  text-align: left;
  display: flex;
  ${({ margin }) => marginItem.setMargin(margin)}
`;

export const TitleText = styled.div`
  ${({ margin }) => marginItem.setMargin(margin)}
`;

export const ContentRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 6px 10px 0;
  width: 100%;
`;
export const SectionWrapperCompanies = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
`;

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  ${({ active }) =>
    active &&
    `
      opacity: 0.4;
      pointer-events: none;
      cursor: not-allowed;
  `}
`;

export const SectionDivider = styled.div`
  border-right: ${({ theme }) => theme.border.divider};
  height: 57px;
  margin: 0 10px;
`;

export const HorizontalDivider = styled.div`
  border-bottom: ${({ theme }) => theme.border.divider};
  margin: 0 10px;
  opacity: 0.4;
  width: calc(100% - 20px);
`;

export const ActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 67px;
  justify-content: flex-start;
  margin: 0 10px;
  padding-top: 23px;

  button {
    font-size: 12px;
    height: 30px;
    line-height: 23px;
    width: 100px;
  }
`;

export const ActionRow = styled.div`
  display: flex;
  justify-content: center;
  margin: 8px 0;
  max-height: 30px;

  button {
    font-size: 12px;
    height: 30px;
    line-height: 23px;
    width: 100px;
    ${({ marginButton }) => marginItem.setMargin(marginButton)};
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
