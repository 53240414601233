import React from "react";
import { Tooltip } from "@mix/mixfiscal-designsystem";
import { useCestInformation } from "useCases/cest";

import * as S from "./styles";

const CustomTooltip = props => {
  const { color, value } = props;
  return (
    <S.ContainerTooltip color={color}>
      <div>
        <p>{` ${value} `}</p>
      </div>
    </S.ContainerTooltip>
  );
};

const CustomTooltip2 = ({ codigo }) => <CustomTooltip color value={codigo} />;

const NcmDescription = ({ ncm }) => {
  const { data, isLoading } = useCestInformation({
    codigo: ncm,
  });

  if (isLoading) {
    return <p>Carregando...</p>;
  }

  return <p>{data?.information?.descricao ?? "Sem descrição"}</p>;
};

function ProductDetail({ dataProduct, ProductTwin }) {
  const photoProduct = ProductTwin?.external?.url_photo_link;

  return (
    <>
      <S.ProductContainer>
        <div className="img">
          <img src={photoProduct} alt="" height="135px" />
        </div>
        <S.Lin1 isOrange={dataProduct?.pcli_nome}>
          {ProductTwin?.prod_nome || dataProduct?.pcli_nome}
        </S.Lin1>

        <S.Lin2>
          <div className="l2-col1">
            <p
              style={
                dataProduct === undefined
                  ? {}
                  : dataProduct?.pcli_ncm !== ProductTwin?.ncm
                  ? { color: "#EE6B09" }
                  : {}
              }
            >
              <b>NCM: &nbsp;</b>
              {dataProduct?.pcli_ncm && (
                <Tooltip
                  zIndex={999999}
                  position="top"
                  component={<CustomTooltip color value={ProductTwin?.ncm} />}
                >
                  {dataProduct?.id_prod?.ncm}
                </Tooltip>
              )}
              {dataProduct ? dataProduct?.pcli_ncm : ProductTwin?.ncm}
            </p>
          </div>

          <div className="l2-col2">
            <NcmDescription ncm={dataProduct?.pcli_ncm || ProductTwin?.ncm} />
          </div>
        </S.Lin2>

        <S.Lin3>
          <div className="l3-col1">
            <p
              style={
                dataProduct === undefined
                  ? {}
                  : dataProduct?.id_prod?.cest !== ProductTwin?.cest
                  ? { color: "#EE6B09" }
                  : {}
              }
            >
              <b>CEST: &nbsp;</b>
              {dataProduct?.id_prod?.cest && (
                <Tooltip
                  zIndex={999999}
                  position="top"
                  // eslint-disable-next-line prettier/prettier
                  component={<CustomTooltip color value={ProductTwin?.cest} />}
                >
                  {ProductTwin?.cest}
                </Tooltip>
              )}
              {dataProduct ? dataProduct?.cest : ProductTwin?.cest}
            </p>
          </div>
          <div className="l3-col2">
            <p
              style={
                // eslint-disable-next-line no-self-compare
                dataProduct?.pcli_cm_grupo !== dataProduct?.pcli_cm_grupo
                  ? { color: "#EE6B09" }
                  : {}
              }
            >
              <b>GRUPO: &nbsp;</b>
              {dataProduct?.pcli_cm_grupo && (
                <Tooltip
                  zIndex={999999}
                  position="top"
                  // eslint-disable-next-line prettier/prettier
                  component={
                    <CustomTooltip color value={ProductTwin?.mer_grupo} />
                    // eslint-disable-next-line prettier/prettier
                  }
                >
                  {dataProduct?.pcli_cm_grupo}
                </Tooltip>
              )}
              {dataProduct ? dataProduct?.mer_subgrupo : ProductTwin?.mer_grupo}
            </p>
          </div>
        </S.Lin3>

        <S.Lin4>
          <div className="l4-col1">
            <p
              style={
                dataProduct === undefined
                  ? {}
                  : dataProduct?.pcli_codigo_barras !==
                    ProductTwin?.prod_codigo_barras
                  ? { color: "#EE6B09" }
                  : {}
              }
            >
              <b>EAN: &nbsp;</b>
              {dataProduct?.pcli_codigo_barras && (
                <Tooltip
                  zIndex={999999}
                  position="top"
                  component={
                    <CustomTooltip2 codigo={ProductTwin?.prod_codigo_barras} />
                  }
                />
              )}
              {dataProduct
                ? dataProduct?.pcli_codigo_barras
                : ProductTwin?.prod_codigo_barras}
            </p>
          </div>
          <div className="l4-col2">
            <p
              style={!dataProduct?.pcli_id_cliente ? { color: "#45924f" } : {}}
            >
              <b>ID.FORNECEDOR: &nbsp;</b>
              {dataProduct?.pcli_id_cliente || "Mix"}
            </p>
          </div>
          <div className="l4-col3">
            <p
              style={
                !dataProduct?.pcli_codigo_interno ? { color: "#45924f" } : {}
              }
            >
              <b>ID.INTERNO: &nbsp;</b>
              {dataProduct?.pcli_codigo_interno || "Mix"}
            </p>
          </div>
        </S.Lin4>
      </S.ProductContainer>
    </>
  );
}
export default ProductDetail;
