import React from "react";
import { TextField } from "components/form";

import * as S from "../styles";
import * as SP from "./styles";

export const ApiKeyFormPermission = ({
  setValue,
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
  setGetRequest,
  setPutRequest,
  setPostRequest,
  setDeleteRequest,
}) => {
  return (
    <>
      <label>Permissões</label>
      <S.ColumnFormItem>
        <SP.PermissionWrapper>
          <SP.PermissionWrapperItem>
            <input
              checked={getRequest}
              name="getRequest"
              type="checkbox"
              onChange={() => {
                setGetRequest(!getRequest);
                setValue("getRequest", getRequest);
              }}
            />
            <SP.PermissionWrapperItemLabel>GET</SP.PermissionWrapperItemLabel>
          </SP.PermissionWrapperItem>
          <SP.PermissionWrapperItem>
            <input
              checked={postRequest}
              name="postRequest"
              type="checkbox"
              onChange={() => {
                setPostRequest(!postRequest);
                setValue("postRequest", postRequest);
              }}
            />
            <SP.PermissionWrapperItemLabel>POST</SP.PermissionWrapperItemLabel>
          </SP.PermissionWrapperItem>
          <SP.PermissionWrapperItem>
            <input
              checked={putRequest}
              name="putRequest"
              type="checkbox"
              onChange={() => {
                setPutRequest(!putRequest);
                setValue("putRequest", putRequest);
              }}
            />
            <SP.PermissionWrapperItemLabel>PUT</SP.PermissionWrapperItemLabel>
          </SP.PermissionWrapperItem>
          <SP.PermissionWrapperItem>
            <input
              checked={deleteRequest}
              name="deleteRequest"
              type="checkbox"
              onChange={() => {
                setDeleteRequest(!deleteRequest);
                setValue("deleteRequest", deleteRequest);
              }}
            />
            <SP.PermissionWrapperItemLabel>
              DELETE
            </SP.PermissionWrapperItemLabel>
          </SP.PermissionWrapperItem>
        </SP.PermissionWrapper>
      </S.ColumnFormItem>
      <S.ColumnFormItem>
        <TextField
          type="text"
          label="Nome da Chave API"
          placeholder="Nome da Chave API"
          name="name"
        />
      </S.ColumnFormItem>
    </>
  );
};
