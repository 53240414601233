/* eslint-disable func-names */
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = yup.object().shape(
  {
    origem: yup.mixed().when("saida", {
      is: v => !v || v?.length === 0,
      then: yup.array().min(1),
    }),
    saida: yup.mixed().when("origem", {
      is: v => !v || v?.length === 0,
      then: yup.array().min(1),
    }),
    ufOrigem: yup.mixed().when("origem", function (v) {
      if (v?.length >= 1) return this.required();
    }),
    ufDestino: yup.mixed().when("saida", function (v) {
      if (v.length >= 1) return this.required();
    }),
    tipoEmpresa: yup.mixed().when("origem", function (v) {
      if (v.length >= 1) return this.required();
    }),
    tipoEmpresaSaida: yup.mixed().when("saida", function (v) {
      if (v.length >= 1) return this.required();
    }),
    atividade: yup
      .array()
      .of(
        yup.object().shape({
          value: yup.string().required("Required"),
          label: yup.string().required("Required"),
        }),
      )
      .min(1),
  },
  ["origem", "saida"],
);

export default yupResolver(schema);
