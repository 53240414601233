import { useEffect } from "react";
import { useRegisterLaws } from "hooks/useRegisterLaws";
import { useRegisterLawsGradesContext } from "context/RegisterLawsContext";

export const useLawLocaleLoad = () => {
  const { setValues } = useRegisterLaws();
  const { values: lawsValues } = useRegisterLawsGradesContext();

  useEffect(() => {
    setValues({
      cenarios: lawsValues.cenarios,
      ncms: lawsValues.ncms,
      cests: lawsValues.cests,
      taxa: lawsValues.taxa,
    });
  }, [
    setValues,
    lawsValues?.cenarios,
    lawsValues?.ncms,
    lawsValues?.cests,
    lawsValues?.taxa,
  ]);
};
