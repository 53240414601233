import { useState, useCallback, useRef, useEffect } from "react";
import { useAPIAuthenticate, useAPINFE2 } from "hooks";
import axios from "axios";
import { getURLWithParams } from "utils/context";
import { parse, format } from "date-fns";
import { getTagTypeDashoard } from "utils/context/dashboard";
import { useAuth } from "context/AuthContext";

// TODO: Retirar os usos de hooks com cancelamento
const TAG = "XML";

const FormatDate = value => format(value, "ddMMyyyy");

const formatDates = ({ dt_ini: dtIni, dt_fim: dtFim }) => {
  const dateStart = parse(dtIni, "ddMMyyyy", new Date());
  const dateEnd = parse(dtFim, "ddMMyyyy", new Date());

  return {
    issue_date_start: format(dateStart, "yyyy-MM-dd"),
    issue_date_end: format(dateEnd, "yyyy-MM-dd"),
  };
};

const formatResponseNFEApi = res => ({
  ...res,
  data: {
    data: Number(res.data.data),
  },
});

const formatResponseNFEMonth = res => ({
  ...res,
  data: {
    data: (res?.data ?? []).map(item => {
      const property = Object.keys(item)[0];
      return { [property]: Number(item[property]) };
    }),
  },
});

export const useAnalyticsSales = () => {
  const api = useAPIAuthenticate();
  const apiNfe = useAPINFE2();
  const [data, setData] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const source = useRef(axios.CancelToken.source());

  const fetch = useCallback(
    (fields = {}) => {
      setLoading(true);

      let promise;

      if (fields.tipo.toUpperCase() === TAG) {
        promise = apiNfe
          .get(getURLWithParams("/tax-note/sales", formatDates(fields)))
          .then(formatResponseNFEApi);
      } else {
        promise = api.post("/analytics/dashboard/sales", fields, {
          cancelToken: source.current.token,
        });
      }

      if (promise) {
        promise
          .then(values => {
            setData(values);
            setLoading(false);
          })
          .catch(err => {
            console.log(err);
            setData(undefined);
            setLoading(false);
          });
      }
    },
    [api, apiNfe],
  );

  useEffect(() => {
    const currentCancel = source.current;
    return () => currentCancel.cancel();
  }, []);

  return { isLoading: loading, data, fetch };
};

export const useAnalyticsPurchases = () => {
  const api = useAPIAuthenticate();
  const [data, setData] = useState(undefined);
  const apiNfe = useAPINFE2();
  const [loading, setLoading] = useState(true);
  const source = useRef(axios.CancelToken.source());

  const fetch = useCallback(
    (fields = {}) => {
      setLoading(true);

      let promise;

      if (fields.tipo.toUpperCase() === TAG) {
        promise = apiNfe
          .get(getURLWithParams("/tax-note/purchase", formatDates(fields)))
          .then(formatResponseNFEApi);
      } else {
        promise = api.post("/analytics/dashboard/purchases", fields, {
          cancelToken: source.current.token,
        });
      }
      promise
        .then(values => {
          setData(values);
          setLoading(false);
        })
        .catch(() => {
          setData(undefined);
          setLoading(false);
        });
    },
    [api, apiNfe],
  );

  useEffect(() => {
    const currentCancel = source.current;
    return () => currentCancel.cancel();
  }, []);

  return { isLoading: loading, data, fetch };
};

export const useAnalyticsSkus = () => {
  const api = useAPIAuthenticate();
  const [data, setData] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const source = useRef(axios.CancelToken.source());

  const fetch = useCallback(
    (fields = {}) => {
      setLoading(true);
      api
        .post("/analytics/dashboard/skus", fields, {
          cancelToken: source.current.token,
        })
        .then(values => {
          setData(values);
          setLoading(false);
        })
        .catch(() => {
          setData(undefined);
          setLoading(false);
        });
    },
    [api],
  );

  useEffect(() => {
    const currentCancel = source.current;
    return () => currentCancel.cancel();
  }, []);

  return { isLoading: loading, data, fetch };
};

export const useAnalyticsPisCofins = () => {
  const apiNfe = useAPINFE2();
  const api = useAPIAuthenticate();
  const [data, setData] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const source = useRef(axios.CancelToken.source());

  const fetch = useCallback(
    (fields = {}) => {
      setLoading(true);

      let promise;

      if (fields.tipo.toUpperCase() === TAG) {
        promise = apiNfe.get(
          getURLWithParams("/tax-note/totals-by-piscofins", fields),
        );
        // .then(formatResponseNFEApi);
      } else {
        promise = api
          .post(
            "/analytics/dashboard/piscofins",
            {
              ...fields,
              tipo: getTagTypeDashoard(fields.tipo),
              dt_ini: FormatDate(
                parse(fields.issue_date_start, "yyyy-MM-dd", new Date()),
              ),
              dt_fim: FormatDate(
                parse(fields.issue_date_end, "yyyy-MM-dd", new Date()),
              ),
            },
            {
              cancelToken: source.current.token,
            },
          )
          .then(res => res?.data);
      }
      promise
        .then(res => {
          setData(res);
          setLoading(false);
        })
        .catch(() => {
          setData(undefined);
          setLoading(false);
        });
    },
    [api, apiNfe],
  );

  useEffect(() => {
    const currentCancel = source.current;
    return () => currentCancel.cancel();
  }, []);

  return { isLoading: loading, data, fetch };
};

export const useAnalyticsIcms = () => {
  const apiNfe = useAPINFE2();
  const api = useAPIAuthenticate();
  const [data, setData] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const source = useRef(axios.CancelToken.source());

  const fetch = useCallback(
    (fields = {}) => {
      setLoading(true);

      let promise;
      if (fields.tipo.toUpperCase() === TAG) {
        promise = apiNfe.get(
          getURLWithParams("/tax-note/totals-by-icms", fields, {
            cancelToken: source.current.token,
          }),
        );
      } else {
        promise = api
          .post(
            "/analytics/dashboard/icms",
            {
              ...fields,
              tipo: getTagTypeDashoard(fields.tipo),
              dt_ini: FormatDate(
                parse(fields.issue_date_start, "yyyy-MM-dd", new Date()),
              ),
              dt_fim: FormatDate(
                parse(fields.issue_date_end, "yyyy-MM-dd", new Date()),
              ),
            },
            {
              cancelToken: source.current.token,
            },
          )
          .then(res => res?.data);
      }
      promise
        .then(res => {
          setData(res);
          setLoading(false);
        })
        .catch(() => {
          setData([]);
          setLoading(false);
        });
    },
    [api, apiNfe],
  );

  useEffect(() => {
    const currentCancel = source.current;
    return () => currentCancel.cancel();
  }, []);

  return { isLoading: loading, data, fetch };
};

export const useAnalyticsOportunidade = () => {
  const api = useAPINFE2();
  const [data, setData] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const source = useRef(axios.CancelToken.source());

  const fetch = useCallback(
    fields => {
      setLoading(true);

      api
        .get(
          getURLWithParams(
            "/business-oportunity/all-supplier",
            formatDates(fields),
          ),
          {
            cancelToken: source.current.token,
          },
        )
        .then(values => {
          setData(values);
          setLoading(false);
        })
        .catch(() => {
          setData(undefined);
          setLoading(false);
        });
    },
    [api],
  );

  useEffect(() => {
    const currentCancel = source.current;
    return () => currentCancel.cancel();
  }, []);

  return { isLoading: loading, data, fetch };
};

export const useAnalyticsSalesMonths = () => {
  const api = useAPIAuthenticate();
  const apiNfe = useAPINFE2();
  const [data, setData] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const source = useRef(axios.CancelToken.source());

  const fetch = useCallback(
    (fields = {}) => {
      setLoading(true);

      let promise;

      if (fields.tipo.toUpperCase() === TAG) {
        promise = apiNfe
          .get(
            getURLWithParams("/tax-note/sales-by-month", formatDates(fields)),
            {
              cancelToken: source.current.token,
            },
          )
          .then(formatResponseNFEMonth);
      } else {
        promise = api.post("/analytics/dashboard/sales/months", fields, {
          cancelToken: source.current.token,
        });
      }

      promise
        .then(values => {
          setData(values);
          setLoading(false);
        })
        .catch(() => {
          setData(undefined);
          setLoading(false);
        });
    },
    [api, apiNfe],
  );

  useEffect(() => {
    const currentCancel = source.current;
    return () => currentCancel.cancel();
  }, []);

  return { isLoading: loading, data, fetch };
};

export const useAnalyticsPurchasesMonths = () => {
  const api = useAPIAuthenticate();
  const apiNfe = useAPINFE2();
  const [data, setData] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const source = useRef(axios.CancelToken.source());

  const fetch = useCallback(
    (fields = {}) => {
      setLoading(true);

      let promise;

      if (fields.tipo.toUpperCase() === TAG) {
        promise = apiNfe
          .get(
            getURLWithParams(
              "/tax-note/purchase-by-month",
              formatDates(fields),
            ),
            {
              cancelToken: source.current.token,
            },
          )
          .then(formatResponseNFEMonth);
      } else {
        promise = api.post("/analytics/dashboard/purchases/months", fields, {
          cancelToken: source.current.token,
        });
      }

      promise
        .then(values => {
          setData(values);
          setLoading(false);
        })
        .catch(() => {
          setData(undefined);
          setLoading(false);
        });
    },
    [api, apiNfe],
  );

  useEffect(() => {
    const currentCancel = source.current;
    return () => currentCancel.cancel();
  }, []);

  return { isLoading: loading, data, fetch };
};

export const useAnalyticsOportunidadeIcmsPisTotal = () => {
  const api = useAPINFE2();
  const [data, setData] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const source = useRef(axios.CancelToken.source());

  const fetch = useCallback(
    fields => {
      setLoading(true);
      api
        .get(getURLWithParams("/tax-note/piscofins-icms-preview", fields), {
          cancelToken: source.current.token,
        })
        .then(values => {
          setData(values);
          setLoading(false);
        })
        .catch(() => {
          setData(undefined);
          setLoading(false);
        });
    },
    [api],
  );

  useEffect(() => {
    const currentCancel = source.current;
    return () => currentCancel.cancel();
  }, []);

  return { isLoading: loading, data, fetch };
};

export const useSkus = () => {
  const api = useAPIAuthenticate();
  const [data, setData] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const source = useRef(axios.CancelToken.source());
  const { tokenCompanies } = useAuth();

  const fetch = useCallback(
    (fields = {}) => {
      setLoading(true);
      api
        .post(
          "/nfe/sku-monitoring?initial_date=2021-01&final_date=2022-01",
          fields,
          {
            token: tokenCompanies,
          },
          {
            cancelToken: source.current.token,
          },
        )
        .then(values => {
          setData(values);
          setLoading(false);
        })
        .catch(() => {
          setData(undefined);
          setLoading(false);
        });
    },
    [api, tokenCompanies],
  );

  useEffect(() => {
    const currentCancel = source.current;
    return () => currentCancel.cancel();
  }, []);

  return { isLoading: loading, data, fetch };
};

export const useAnalyticsSalesCnpj = () => {
  const apiNfe = useAPINFE2();
  const [data, setData] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const source = useRef(axios.CancelToken.source());

  const fetch = useCallback(
    (fields = {}) => {
      setLoading(true);
      if (fields.tipo.toUpperCase() === TAG) {
        apiNfe
          .get(
            getURLWithParams("/tax-note/sales-by-cnpjs", formatDates(fields)),
            {
              cancelToken: source.current.token,
            },
          )
          .then(values => {
            setData(values);
            setLoading(false);
          })
          .catch(() => {
            setData(undefined);
            setLoading(false);
          });
      }
    },
    [apiNfe],
  );

  useEffect(() => {
    const currentCancel = source.current;
    return () => currentCancel.cancel();
  }, []);

  return { isLoading: loading, data, fetch };
};

export const useAnalyticsPurchaseCnpj = () => {
  const apiNfe = useAPINFE2();
  const [data, setData] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const source = useRef(axios.CancelToken.source());

  const fetch = useCallback(
    (fields = {}) => {
      setLoading(true);
      if (fields.tipo.toUpperCase() === TAG) {
        apiNfe
          .get(
            getURLWithParams(
              "/tax-note/purchase-by-cnpjs",
              formatDates(fields),
            ),
            {
              cancelToken: source.current.token,
            },
          )
          .then(values => {
            setData(values);
            setLoading(false);
          })
          .catch(() => {
            setData(undefined);
            setLoading(false);
          });
      }
    },
    [apiNfe],
  );

  useEffect(() => {
    const currentCancel = source.current;
    return () => currentCancel.cancel();
  }, []);

  return { isLoading: loading, data, fetch };
};
