import React, { Suspense } from "react";

import "@mix/linkmix-config-params/dist/index.css";

const StoreConfigParamsComponent = React.lazy(() =>
  import("@mix/linkmix-config-params"),
);

export const StoreConfigParams = ({ token }) => (
  <Suspense fallback={<div>Carregando...</div>}>
    <StoreConfigParamsComponent token={token} width="100%" height="1000px" />
  </Suspense>
);
