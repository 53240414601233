import React, { useState, useEffect, useCallback } from "react";
import Select from "react-select";

import { useProductsContext } from "context/ProductsContext";
import { hasValueNcm } from "utils/context/laws";
import { useNcmNivel } from "useCases/ncm";
import * as S from "./styles";

export const Ncm = () => {
  const [ncmSearch, setNcmSearch] = useState(null);

  const { handleChangeField, ncmValues, setNcmValues } = useProductsContext();

  const { dataMap: sessaoData, isLoading: isLoadingSessao } =
    useNcmNivel("sessao");

  const { dataMap: capituloData, isLoadingCapitulo } = useNcmNivel(
    "capitulo",
    ncmValues?.session !== "Selecione" ? { sessao: ncmValues?.session } : null,
  );

  const posicaoNcm = hasValueNcm([ncmValues?.chapter, ncmValues?.session]);

  const { dataMap: posicaoData, isLoading: isLoadingPosicao } = useNcmNivel(
    "posicao",
    posicaoNcm ? { ncm: posicaoNcm } : null,
  );

  const subPosicaoNcm = hasValueNcm([
    ncmValues?.position,
    ncmValues?.chapter,
    ncmValues?.session,
  ]);

  const { dataMap: subPosicaoData, isLoadingSubPosicao } = useNcmNivel(
    "sub-posicao",
    subPosicaoNcm ? { ncm: subPosicaoNcm } : null,
  );
  const subItemNcm = hasValueNcm([
    ncmValues?.subPosition,
    ncmValues?.position,
    ncmValues?.chapter,
    ncmValues?.session,
  ]);

  const { dataMap: subItemsData, isLoading: isLoadingSubItem } = useNcmNivel(
    "item",
    ncmSearch ?? ncmValues?.subItem ?? subItemNcm
      ? { ncm: ncmSearch ?? ncmValues?.subItem ?? subItemNcm }
      : null,
  );

  const onSearch = value => setNcmSearch(value === "" ? null : value);

  useEffect(
    () => handleChangeField("ncm", ncmValues?.subItem),
    [ncmValues?.subItem, handleChangeField],
  );

  const onChangeItem = useCallback(
    ({ value, subItem }) => {
      setNcmValues({
        ...ncmValues,
        subItem: value,
        session: subItem.sessoes[1]?.value,
        position: subItem.posicao[1]?.value,
        chapter: subItem?.capitulos[1]?.value,
      });
    },
    [ncmValues, setNcmValues],
  );

  useEffect(() => {
    if (
      subItemsData &&
      ncmValues?.subItem &&
      !ncmValues?.session &&
      !ncmValues?.posicao &&
      !ncmValues?.chapter
    ) {
      setNcmValues({
        ...ncmValues,
        session: subItemsData[1]?.sessoes[1]?.value,
        position: subItemsData[1]?.posicao[1]?.value,
        chapter: subItemsData[1]?.capitulos[1]?.value,
      });
    }
  }, [subItemsData, ncmValues, setNcmValues]);

  return (
    <S.CardWithLateralHeader>
      <S.Header>NCM</S.Header>
      <main>
        <div className="input-container">
          <S.InputContainer maxWidth="100%">
            <label>Sessão</label>
            <Select
              label="Sessão"
              name="session"
              isLoading={isLoadingSessao}
              options={sessaoData?.map(session => ({
                value: session.value,
                label: session.label,
              }))}
              value={sessaoData
                ?.filter(item => item.value === ncmValues?.session)
                .map(session => ({
                  value: session.value,
                  label: session.label,
                }))}
              onChange={e => setNcmValues({ session: e.value })}
            />
          </S.InputContainer>
          <S.InputContainer maxWidth="100%">
            <label>Capítulo</label>
            <Select
              name="chapter"
              label="Capítulo"
              isLoading={isLoadingCapitulo}
              options={capituloData?.map(chapter => ({
                value: chapter.value,
                label: chapter.label,
              }))}
              value={capituloData
                ?.filter(item => item.value === ncmValues.chapter)
                .map(session => ({
                  value: session.value,
                  label: session.label,
                }))}
              onChange={e =>
                setNcmValues({
                  session: ncmValues.session,
                  chapter: e.value,
                })
              }
            />
          </S.InputContainer>
          <S.InputContainer maxWidth="100%">
            <label>Posição</label>
            <Select
              label="Posição"
              name="position"
              isLoading={isLoadingPosicao}
              labelType="top"
              options={posicaoData?.map(position => ({
                value: position.value,
                label: position.label,
              }))}
              value={posicaoData
                ?.filter(item => item.value === ncmValues.position)
                .map(session => ({
                  value: session.value,
                  label: session.label,
                }))}
              onChange={e =>
                setNcmValues({
                  session: ncmValues.session,
                  chapter: ncmValues.chapter,
                  position: e.value,
                })
              }
            />
          </S.InputContainer>
          <S.InputContainer maxWidth="100%">
            <label>Sub-Posição</label>
            <Select
              label="Sub-Posição"
              name="subPosition"
              isLoading={isLoadingSubPosicao}
              isDisabled={subPosicaoData?.length === 0}
              labelType="top"
              options={subPosicaoData?.map(subPosition => ({
                value: subPosition.value,
                label: subPosition.label,
              }))}
              onChange={e =>
                setNcmValues({
                  ...ncmValues,
                  subPosition: e.value,
                  item: undefined,
                  subItem: undefined,
                })
              }
            />
          </S.InputContainer>
          <S.InputContainer maxWidth="260px">
            <label>Item</label>
            <Select
              label="Item"
              name="subItem"
              isLoading={isLoadingSubItem}
              labelType="top"
              onInputChange={onSearch}
              options={subItemsData?.map(subItem => ({
                value: subItem.value,
                label: subItem.label,
                subItem,
              }))}
              value={subItemsData
                ?.filter(item => item.value === ncmValues?.subItem)
                .map(subItem => ({
                  value: subItem.value,
                  label: subItem.label,
                  subItem,
                }))}
              onChange={option => {
                onChangeItem(option);
              }}
            />
          </S.InputContainer>
        </div>
      </main>
    </S.CardWithLateralHeader>
  );
};
