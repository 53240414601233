import React, { createContext, useContext, useState } from "react";

export const UserContext = createContext({});

export const useUsersContext = () => useContext(UserContext);

export const UsersProvider = ({ children }) => {
  const [usersList, setUsersList] = useState([]);

  return (
    <UserContext.Provider
      value={{
        usersList,
        setUsersList,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
