import { useFetchAuthenticate, useAPIAuthenticate, useToast } from "hooks";

import { getURLWithParams } from "utils/context";

export const useCenarios = () => {
  // return useFetch()
};

export const useGradeIcmsAlqs = () => useFetchAuthenticate("/grade/icms/alqs");

export const useGradeIcmsById = id =>
  useFetchAuthenticate(id ? `/grade/icms/${id}` : null);

export const useGradeIcms = (params = {}) =>
  useFetchAuthenticate(getURLWithParams(`/grade/icms`, params));

export const useGradePisCofins = (params = {}) =>
  useFetchAuthenticate(getURLWithParams(`/grade/piscofins`, params));

export const useGradePiscofinsId = id =>
  useFetchAuthenticate(id ? `/grade/piscofins/${id}` : null);

export const useAnalyticsGrade = params =>
  useFetchAuthenticate(
    params ? getURLWithParams(`analytics/grade`, params) : null,
  );

export const useProductsGrade = (onlyNotExists = false, params) =>
  useFetchAuthenticate(
    params
      ? onlyNotExists
        ? getURLWithParams(`/products-grade`, params)
        : getURLWithParams(`/products`, params)
      : null,
  );

export const useCRUD = () => {
  const api = useAPIAuthenticate();
  const toast = useToast();

  return {
    async moveProducts(fields = {}) {
      try {
        const res = await api.post("/grades/products/move", fields);
        toast.addToast({
          type: "success",
          description: "Produtos movidos com sucesso!",
        });
        return res;
      } catch (err) {
        toast.addToast({
          type: "error",
          description:
            "Não foi possível mover o(s) produto(s) da grade. Error interno",
        });
        return err;
      }
    },
    async removeProducts(fields = {}) {
      return api.post("/grades/products/remove", fields);
    },
  };
};
