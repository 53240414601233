const shuffleString = (str, maxlength) => {
  // TODO: Embaralhar string
  let shuffledString = str
    .split("")
    .sort(() => {
      return 0.5 - Math.random();
    })
    .join("");
  if (maxlength > 0) {
    shuffledString = shuffledString.substr(0, maxlength);
  }
  return shuffledString;
};

export const generatePassword = (length, rules) => {
  if (!length || length === undefined) {
    // TODO: Tamanho inicial
    length = 8;
  }

  if (!rules || rules === undefined) {
    // TODO: Regras inicial
    rules = [
      { chars: "abcdefghijklmnopqrstuvwxyz", min: 3 },
      { chars: "ABCDEFGHIJKLMNOPQRSTUVWXYZ", min: 2 },
      { chars: "0123456789", min: 2 },
      { chars: "!@#$&*?|%+-_./:;=()[]{}", min: 1 },
    ];
  }

  let allChars = "";
  let allMin = 0;
  rules.forEach(rule => {
    allChars += rule.chars;
    allMin += rule.min;
  });
  if (length < allMin) {
    length = allMin;
  }
  rules.push({ chars: allChars, min: length - allMin });

  let pswd = "";
  rules.forEach(rule => {
    if (rule.min > 0) {
      pswd += shuffleString(rule.chars, rule.min);
    }
  });

  return shuffleString(pswd);
};
