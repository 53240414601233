import React from "react";
import * as S from "./AttributesContainer.styles";

export const AttributesContainer = ({ children: Children, title }) => {
  return (
    <S.Container>
      <S.ColumnAttributes>
        <S.ColumnAttributesHeader>
          <h3 style={{ color: "#fff", fontWeight: 700, padding: 0 }}>
            {title}
          </h3>
        </S.ColumnAttributesHeader>

        <S.ColumnAttributesBody>{Children}</S.ColumnAttributesBody>
      </S.ColumnAttributes>
    </S.Container>
  );
};
