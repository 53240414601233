import React, { useState } from "react";

import { useWatch } from "react-hook-form";
import { Select } from "components/form";

// eslint-disable-next-line import/no-cycle
import { LawNormGerenciador } from "components/contexts/law-grade/LawNormCard";
import { INITIAL_VALUE_SELECT } from "constants/enums";

import { useLawsNorms } from "useCases/lawNorms";

const filterOptions = () => true;

export const LawNormSelect = ({ control }) => {
  const [nome, setNome] = useState(null);
  const norma = useWatch({ control, name: "id_norm" });

  const { data } = useLawsNorms({
    page: 1,
    limit: 60,
    ...(nome ? { nome } : {}),
  });

  const onChangeSearch = value => setNome(value ?? null);

  return (
    <>
      <Select
        required
        control={control}
        isSearchable
        name="id_norm"
        label="Selecione a Lei"
        labelType="top"
        data={[
          ...[INITIAL_VALUE_SELECT],
          ...(data?.data?.docs ?? []).map(norm => ({
            value: norm._id,
            label: norm.nome,
            norm,
          })),
        ]}
        onInputChange={onChangeSearch}
        filterOption={filterOptions}
      />

      {norma?.norm?.normas && (
        <LawNormGerenciador norm={{ id_norm: norma }} disableActions />
      )}
    </>
  );
};
