import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #596778;
  padding-bottom: 40px;

  position: absolute;
  top: 0;
  z-index: 10005;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  input {
    border-bottom: 1px solid #e5e5e5;
  }
`;

export const MenuItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;

  cursor: pointer;
  padding: 16px;
  color: ${props => (props.isActive ? "#17456b" : "#fff")};
  border-right: 1px solid #ffffff19;
  background-color: ${props => (props.isActive ? "#ffffffcc" : "transparent")};

  & svg {
    fill: ${props => (props.isActive ? "#17456b" : "#fff")};
  }

  &:hover {
    color: #17456b;
    background-color: #ffffffcc;
    svg {
      fill: #17456b;
    }
  }
`;

export const Menu = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  background-color: ${props => props.bgColor && props.bgColor};

  & > div {
    display: flex;
    align-items: center;
  }

  ${props =>
    props.bgColor &&
    css`
      & ${MenuItem} {
        background-color: transparent;
      }
    `}

  .endBlock {
    margin-right: 8px;
    cursor: pointer;
  }
`;

export const HeaderActions = styled.div`
  background-color: #596778;
  display: flex;
  align-items: center;
  width: 100%;
  color: #fff;
  padding: 8px;

  svg {
    fill: #fff;
  }

  & > div:first-of-type {
    margin-right: 80px;
  }

  & div {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
  }
`;

export const SideTitle = styled.div`
  width: 100px;
  height: 100%;
  color: #fff;
  background-color: #adc86a;
  padding: 16px;
  text-transform: uppercase;

  display: ${props => (props.isShowing ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
  flex-shrink: 0;

  filter: ${props => (props.isCurrentStep ? "none" : "brightness(0.4)")};

  &::before {
    content: "${props => props.stepNumber}";
    font-size: 50px;
    font-weight: 700;
    color: #fff;
    opacity: 0.4;
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const ActionsContainer = styled.div`
  flex-shrink: 0;
  width: 100%;
  height: 100px;
  display: flex;
  margin-bottom: 16px;
  overflow: auto;
  overflow-y: hidden;
  background-color: #fff;

  ::-webkit-scrollbar {
    height: 12px;
    cursor: pointer;
  }

  ::-webkit-scrollbar-track {
    background-color: #bababa;
    cursor: pointer;
  }

  ::-webkit-scrollbar-thumb {
    background: #565656;
    border-radius: 20px;
    cursor: pointer;
  }
`;

export const Action = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: all 0.3s;
  flex-shrink: 0;
  margin-right: 16px;
  margin-top: ${props => props.stickToBottom && "auto"};

  ${props =>
    !props.noHover &&
    css`
      margin-right: 0;

      &:hover {
        background-color: #adc86a;

        .operationsContainer {
          color: #fff;
          svg {
            fill: #fff;
          }
        }
      }
    `}

  & > button {
    background-color: #5a8dee;
    border-radius: 8px;
    padding: 8px 16px;
    margin: 0 8px;
    margin-top: auto;

    color: #fff;
    font-weight: 700;
  }

  & > .operationsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;

    text-align: center;
    padding: 8px;
    border-right: 1px solid #000;
  }

  & > label {
    font-size: 12px;
    color: #304156;
    margin-bottom: 8px;
  }

  & > select {
    min-width: 220px;
    height: 36px;
    color: #727e8c;
    border: 1px solid #596778;
  }
`;

export const Actions = styled.div`
  background-color: #fff;
  flex: 1;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  padding: 16px;
`;

export const FlowContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;

  background-color: #e5e5e5;
`;
