import React, { useState } from "react";
import { IoAdd } from "react-icons/io5";
import InputMask from "react-input-mask";
import { Tag } from "@mix/mixfiscal-designsystem";

import { useRegisterLaws } from "../../../hooks/useRegisterLaws";
import { CardWithLateralHeader, InputContainer, Button } from "../styles";

const Supplier = () => {
  const { values, setComplexValue, setValues } = useRegisterLaws();
  const [value, setValue] = useState("");

  function handleNewSupplier(e) {
    e.preventDefault();

    if (value.length === 18) {
      setComplexValue("fornecedores", value);
      setValue("");
    }
  }

  function deleteSupplier(supplier) {
    const newSuppliers = values?.fornecedores?.filter(sup => sup !== supplier);

    setValues({ ...values, fornecedores: newSuppliers });
  }

  return (
    <form onSubmit={handleNewSupplier}>
      <CardWithLateralHeader marginLeft="20px">
        <header>FORNECEDOR</header>
        <main>
          <div className="input-container">
            <InputContainer maxWidth="100%">
              <label>Fornecedor</label>
              <InputMask
                type="text"
                placeholder="00.000.000/0000-00"
                mask="99.999.999/9999-99"
                value={value}
                maskChar="_"
                onChange={e => setValue(e.target.value)}
              />
            </InputContainer>
            <div>
              <Button type="submit">
                <IoAdd />
              </Button>
            </div>
          </div>
          <div className="tag-container">
            {values?.fornecedores?.map(supplier => (
              <div className="tag-item" key={supplier}>
                <Tag
                  type="advanced"
                  label={supplier}
                  handleDelete={() => deleteSupplier(supplier)}
                />
              </div>
            ))}
          </div>
        </main>
      </CardWithLateralHeader>
    </form>
  );
};

export default Supplier;
