import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  > header {
    display: flex;
    align-items: center;
    margin-left: 8px;
  }
`;

export const Content = styled.div`
  width: 100%;
  grid-template-columns: 29% 70%;
  color: #304156;
  display: flex;
  width: 100%;
  padding: 10px;
  gap: 24px;

  .row {
    display: flex;
    flex-direction: row;
    padding-top: 15px;
    gap: 5px;
  }

  .custom-select {
    width: 100%;
  }

  .container-card-tasks {
    gap: 10px;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
    padding-bottom: 15px;
    padding-right: 15px;
  }

  .container-card-drag-drop {
    section {
      transform: none;
    }
  }
`;

export const RowSpecialist = styled.div`
  width: 100%;
  margin-top: 10px;

  .container-specialist {
    width: 100%;

    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    border: 1px solid #304156;
    border-radius: 10px;
    padding: 5px 0;

    label {
      display: flex;
      gap: 6px;
    }
  }
`;

export const RowTagsName = styled.div`
  width: 100%;
  padding: 2px 0;
  border-bottom: 2px solid #e5e5e5;
  margin: 10px 0;
`;

export const RowFiltes = styled.div`
  width: 100%;

  div:first-child {
    width: 60%;
  }

  div:last-child {
    width: 40%;
    display: flex;
    justify-content: space-evenly;
  }
`;
