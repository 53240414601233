import React, { useEffect, useState } from "react";
import { Icon } from "@mix/mixfiscal-designsystem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";

import * as S from "./styles";

const Star = () => <Icon name="estrela" color="#ecd540" size={14} />;
const StarOff = () => <Icon name="estrela" color="#bbbcbd" size={14} />;

function ComercialSide({ ean0200Details, eanDetails }) {
  const [resultMargem, setResultMargem] = useState();
  const diagResult = ean0200Details?.data?.diagnostico;
  const lastBuy = Number(ean0200Details?.ultima_compra?.valor_total).toFixed(2);
  const lastSell = Number(eanDetails?.saida?.vprod).toFixed(2);
  const markup = lastSell / lastBuy - 1 * 100;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (lastBuy && lastSell) {
      const value1 =
        lastBuy - ean0200Details?.ultima_compra?.aliq_icms?.toFixed(2) * 100;
      const value2 =
        lastSell - Number(eanDetails?.saida?.aliq_icms)?.toFixed(0) * 100;
      const result = value1 - value2;
      setResultMargem(result);
    }
  }, [ean0200Details, eanDetails?.saida?.aliq_icms, lastBuy, lastSell]);

  return (
    <>
      <S.DiagnosticContainer>
        <div className="head">
          <p>DIAGNÓSTICO DO ESPECIALISTA</p>
        </div>
        <div className="l1c1">
          <p>Poder de Arrasto</p>
        </div>
        <div className="l1c2">
          {diagResult === undefined ? (
            <p>Sem Dados</p>
          ) : (
            <>
              <p>{diagResult?.poder_arrasto >= 1 ? <Star /> : <StarOff />}</p>
              <p>{diagResult?.poder_arrasto >= 2 ? <Star /> : <StarOff />}</p>
              <p>{diagResult?.poder_arrasto >= 3 ? <Star /> : <StarOff />}</p>
              <p>{diagResult?.poder_arrasto >= 4 ? <Star /> : <StarOff />}</p>
              <p>{diagResult?.poder_arrasto >= 5 ? <Star /> : <StarOff />}</p>
            </>
          )}
        </div>
        <div className="l2c1">
          <p>Venda Agregada</p>
        </div>
        <div className="l2c2">
          {diagResult === undefined ? (
            <p>Sem Dados</p>
          ) : (
            <>
              <p>
                {diagResult?.total_venda_grenada >= 1 ? <Star /> : <StarOff />}
              </p>

              <p>
                {diagResult?.total_venda_agregada >= 2 ? <Star /> : <StarOff />}
              </p>

              <p>
                {diagResult?.total_venda_agregada >= 3 ? <Star /> : <StarOff />}
              </p>
              <p>
                {diagResult?.total_venda_agregada >= 4 ? <Star /> : <StarOff />}
              </p>
              <p>
                {diagResult?.total_venda_agregada >= 5 ? <Star /> : <StarOff />}
              </p>
            </>
          )}
        </div>
        <div className="l3c1">
          <p>Total de Vendas</p>
        </div>
        <div className="l3c2">
          {diagResult === undefined ? (
            <p>Sem Dados</p>
          ) : (
            <>
              <p>{diagResult?.total_venda >= 1 ? <Star /> : <StarOff />}</p>
              <p>{diagResult?.total_venda >= 2 ? <Star /> : <StarOff />}</p>
              <p>{diagResult?.total_venda >= 3 ? <Star /> : <StarOff />}</p>
              <p>{diagResult?.total_venda >= 4 ? <Star /> : <StarOff />}</p>
              <p>{diagResult?.total_venda >= 5 ? <Star /> : <StarOff />}</p>
            </>
          )}
        </div>
        <div className="l4c1">
          <p>Margem</p>
        </div>
        <div className="l4c2">
          {diagResult === undefined ? (
            <p>Sem Dados</p>
          ) : (
            <>
              <p>{diagResult?.margem >= 1 ? <Star /> : <StarOff />}</p>
              <p>{diagResult?.margem >= 2 ? <Star /> : <StarOff />}</p>
              <p>{diagResult?.margem >= 3 ? <Star /> : <StarOff />}</p>
              <p>{diagResult?.margem >= 4 ? <Star /> : <StarOff />}</p>
              <p>{diagResult?.margem >= 5 ? <Star /> : <StarOff />}</p>
            </>
          )}
        </div>
        <div className="l5c1">
          <p>Classe</p>
        </div>
        <div className="l5c2">
          {diagResult === undefined ? (
            <p>Sem Dados</p>
          ) : (
            <>
              <p>{diagResult?.classe >= 1 ? <Star /> : <StarOff />}</p>
              <p>{diagResult?.classe >= 2 ? <Star /> : <StarOff />}</p>
              <p>{diagResult?.classe >= 3 ? <Star /> : <StarOff />}</p>
              <p>{diagResult?.classe >= 4 ? <Star /> : <StarOff />}</p>
              <p>{diagResult?.classe >= 5 ? <Star /> : <StarOff />}</p>
            </>
          )}
        </div>
        <div className="l6c1">
          <p>Impacto Faturamento</p>
        </div>
        <div className="l6c2">
          <p>0%</p>
        </div>
      </S.DiagnosticContainer>
      <S.CalculatorContainer>
        <div className="head">
          <p>CALCULADORA DE MARGEM DE LUCRO</p>
          <a
            href="https://mxf.mixfiscal.com.br/mxf/calculadora/index.php"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
          </a>
        </div>
        <div className="compra">
          <p>COMPRA</p>
        </div>
        <div className="venda">
          <p>VENDA</p>
        </div>
        <div className="v1">
          <p>{lastBuy === Number ? lastBuy : "R$0,00"}</p>
        </div>
        <div className="v2">
          <p>{lastSell || "R$0,00"}</p>
        </div>
        <div className="icms">
          <p>ICMS</p>
        </div>
        <div className="perc1">
          <p>
            {ean0200Details?.ultima_compra?.aliq_icms || "0"}
            &nbsp;%
          </p>
        </div>
        <div className="perc2">
          <p>
            {eanDetails ? eanDetails?.saida?.aliq_icms : "0"}
            &nbsp;%
          </p>
        </div>
        <div className="pis-cofins">
          <p>PIS/COFINS</p>
        </div>
        <div className="percpis1">
          <p>
            {/* Somar com Pis */}
            {ean0200Details?.ultima_compra?.aliq_cofins || "0"}
            &nbsp;%
          </p>
        </div>
        <div className="percpis2">
          <p>
            {eanDetails ? eanDetails?.saida?.aliq_piscofins : "0"}
            &nbsp;%
          </p>
        </div>
        <div className="markup">
          <p>MARKUP</p>
        </div>
        <div className="margem">
          <p>MARGEM</p>
        </div>
        <div className="markupvalue">
          <p>{markup.toFixed(3) === Number ? markup.toFixed(3) : "0%"}</p>
        </div>
        <div className="margemvalue">
          <p>
            {resultMargem?.toFixed(2) === Number
              ? resultMargem?.toFixed(2)
              : "R$0,00"}
          </p>
        </div>
        <div className="lucrobruto">
          <p>LUCRO BRUTO</p>
        </div>
        <div className="lucrobrutovalue">
          <p>
            {resultMargem?.toFixed(2) === Number
              ? resultMargem?.toFixed(2)
              : "R$0,00"}
          </p>
        </div>
      </S.CalculatorContainer>
    </>
  );
}
export default ComercialSide;
