import React from "react";

import { Badge } from "components/structure";
import { Switch } from "components/form";
import { WHATSAPP_STATUS_COMPLEX } from "constants/types";

import * as S from "./WhatsAppStatus.styles";

export const WhatsAppStatusBagde = ({ status }) => (
  <Badge type={WHATSAPP_STATUS_COMPLEX[status]?.status}>
    {WHATSAPP_STATUS_COMPLEX[status]?.text}
  </Badge>
);

export const WhatsAppStatus = ({
  status = "warning",
  statusValue,
  onChange = () => {},
}) => (
  <S.Container>
    <S.Label>Status:</S.Label>
    <Badge type={WHATSAPP_STATUS_COMPLEX[status]?.status}>
      {WHATSAPP_STATUS_COMPLEX[status]?.text}
    </Badge>
    <Switch
      name="whatsAppActive"
      onChange={onChange}
      value={statusValue}
      label=" "
    />
  </S.Container>
);
