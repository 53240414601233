import { useState, useCallback } from "react";
import {
  useFetchAuthenticate,
  usePostFetchAuthenticate,
  useAPIAuthenticate,
} from "hooks";
import { getURLWithParams } from "utils/context";

export const useCompaniesAccessGroupPermission = groupId =>
  useFetchAuthenticate(
    groupId ? `/permissions/companies/accessGroup/${groupId}` : null,
  );

export const useStoreCompanyPermission = companies =>
  usePostFetchAuthenticate(
    companies ? `/permissions/stores/company/` : null,
    companies,
  );

export const useCompanies = (params = {}) =>
  useFetchAuthenticate(getURLWithParams("/companies", params));

// TODO: SEARCH COMPANIES
export const UseCompaniesSearching = () => {
  const api = useAPIAuthenticate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(``);
  const fetch = useCallback(
    (params = {}) => {
      setLoading(true);
      setIsError(`loading`);
      const url =
        params.isSearch === true
          ? `?page=1&limit=50&search=${params.search}&hasAccessGroup=1`
          : `?hasAccessGroup=1`;
      const fetchData = async () => {
        try {
          const {
            data: { companies },
          } = await api.get(`/companies${url}`);
          setData(companies);
          setLoading(false);
        } catch (e) {
          setLoading(false);
          console.log(e.messagge);
          setIsError(``);
        }
      };
      fetchData(url);
    },
    [api],
  );

  return { isLoading: loading, data, fetch, isError };
};
