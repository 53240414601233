import React from "react";
import { Card } from "@mix/mixfiscal-designsystem";
import { useWatch } from "react-hook-form";
import { Select, TextField } from "components/form";
import { useGridManagerContext } from "context/GridManagerContext";
import { ButtonAdd, TagsAction } from "components/structure/Actions";
import { getTags } from "components/contexts/grid-manager/Filters/useCases";
import {
  BLANK_SELECT_LIST,
  FIELDS_TYPES_LIST,
  STATUS_GRADES_TYPES_LIST,
} from "constants/types";
import * as S from "./CardSearchBy.styles";

const headerColorGray = "#e5e5e5";
const textColorGray = "#304156";

const fieldsNames = {
  EAN: "prod_codigo_barras",
  PROD_DESCRICAO: "mix_nome",
  CNPJ: "cnpj",
};

export const CardSearchBy = ({ control, searchBy = null }) => {
  const { setFilterFields, filterFields, removeField } =
    useGridManagerContext();

  const gradeId = useWatch({
    control,
    name: "gradeId",
  });

  const fieldsValue = useWatch({
    control,
  });

  const handleAddFilter =
    ({ nameGetValue, name, type = "text" }) =>
    () => {
      const value =
        type === "select"
          ? fieldsValue[nameGetValue]?.value
          : fieldsValue[nameGetValue];

      const valueFilter = filterFields[name];
      const filterFieldsValues = filterFields;

      if (!Array.isArray(valueFilter)) {
        filterFieldsValues[name] = [];
      }

      const hasValue = Boolean(
        filterFieldsValues[name].filter(valueExist => valueExist === value)
          .length,
      );

      if (!hasValue) {
        filterFieldsValues[name].push(value);
        setFilterFields(filterFieldsValues);
      }
    };

  const handleDeleteAll =
    (tags = ["cnpj", "prod_codigo_barras", "mix_nome"]) =>
    () => {
      const fieldsRemove = {};
      tags.forEach(tag => {
        fieldsRemove[tag] = [];
      });
      setFilterFields(fieldsRemove);
      if (tags.indexOf("cnpj").length >= 0) removeField("cnpj");
    };

  const handleDelete = tag => {
    const values = filterFields[tag.type]?.filter(value => value !== tag.value);

    setFilterFields({
      [tag.type]: values,
    });

    if (!values.length && tag.type === "cnpj") {
      removeField("cnpj");
    }
  };

  const checkGradeIdValid = () => gradeId?.match(/^[0-9a-fA-F]{24}$/);

  return (
    <Card
      className="card-custom"
      handleCtaClick={() => {}}
      hasElevation
      headerColor={headerColorGray}
      textColor={textColorGray}
      title="Pesquisar Por"
    >
      <S.FieldContainer>
        <S.Row>
          <div className="row">
            <S.FieldRow>
              <Select
                name="search_by"
                data={[...BLANK_SELECT_LIST, ...FIELDS_TYPES_LIST]}
                defaultValue={BLANK_SELECT_LIST[0]}
                control={control}
              />
            </S.FieldRow>
            <S.FieldSearch>
              <TextField
                color="#596778"
                className="field-search"
                placeholder={
                  searchBy?.value
                    ? `Pesquisar por ${searchBy?.value ? searchBy?.label : ""}`
                    : "Pesquisar por"
                }
                name="search"
                control={control}
              />
            </S.FieldSearch>
          </div>
          <ButtonAdd
            tooltipText={`Adicionar ${
              searchBy?.value ? searchBy?.label : ""
            } ao filtro`}
            handleClick={handleAddFilter({
              nameGetValue: "search",
              name: searchBy?.value && fieldsNames[searchBy?.value],
            })}
          />
        </S.Row>
        <TagsAction
          bodyTooltip="Remover todas as tags de pesquisar por"
          tags={getTags(filterFields, [
            "cnpj",
            "prod_codigo_barras",
            "mix_nome",
          ])}
          handleDeleteAll={handleDeleteAll()}
          handleDelete={handleDelete}
        />
      </S.FieldContainer>
      <S.FieldContainer>
        <S.Row>
          <div className="row">
            <TextField
              color="#596778"
              className="field-search"
              placeholder="Pesquisar por ID"
              name="gradeId"
              control={control}
              label="ID"
              style={{ width: "100%" }}
              helperText="ID inválido"
              error={!checkGradeIdValid()}
            />
          </div>
          <ButtonAdd
            tooltipText="Adicionar ID da Grade ao filtro"
            handleClick={handleAddFilter({
              name: "gradeId",
              nameGetValue: "gradeId",
            })}
          />
        </S.Row>

        <TagsAction
          bodyTooltip="Remover todas as tags de gradeId"
          tags={getTags(filterFields, ["gradeId"])}
          handleDeleteAll={handleDeleteAll(["gradeId"])}
          handleDelete={handleDelete}
        />
      </S.FieldContainer>

      <S.FieldContainer>
        <S.Row>
          <div className="row">
            <Select
              name="status"
              label="Status Grade"
              data={[...BLANK_SELECT_LIST, ...STATUS_GRADES_TYPES_LIST]}
              defaultValue={BLANK_SELECT_LIST[0]}
              control={control}
            />
          </div>
          <ButtonAdd
            tooltipText="Adicionar Status da grade ao filtro"
            handleClick={handleAddFilter({
              name: "status",
              nameGetValue: "status",
              type: "select",
            })}
          />
        </S.Row>
        <TagsAction
          bodyTooltip="Remover todas as tags de status"
          tags={getTags(filterFields, ["status"])}
          handleDeleteAll={handleDeleteAll(["status"])}
          handleDelete={handleDelete}
        />
      </S.FieldContainer>
    </Card>
  );
};
