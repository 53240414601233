import React, { useState } from "react";
import { Datatable } from "@mix/mixfiscal-designsystem";
import * as ROUTES from "constants/routes";
import { ButtonOpenNewTag } from "components/structure";
import { useAssessores } from "useCases/assessor";

const ActionSelect = ({ _id }) => (
  <div>
    <ButtonOpenNewTag
      title="Abrir detalhe do CEST"
      toLink={ROUTES.ASSESSORES.DETAIL(_id)}
    />
  </div>
);

export const AssessoresList = () => {
  const [limit] = useState(50);
  const [page, setPage] = useState(1);
  const { data, isLoading } = useAssessores({
    page,
    limit,
  });

  const columns = [
    {
      field: "action",
      title: "",
    },
    {
      field: "_id",
      title: "Cód.",
    },
    {
      field: "assessor",
      title: "Assessor",
    },
    {
      field: "total",
      title: "Clientes",
    },
  ];

  return (
    <Datatable
      pagination
      paginationRequest
      handleChangePage={setPage}
      columns={columns}
      // amountPerPage={50}
      isLoading={isLoading}
      totalRawData={data?.wallets?.totalDocs}
      // itemsPerPage={[50, 10, 150, 300]}
      rawData={data?.wallets?.docs.map(item => ({
        ...item,
        action: <ActionSelect {...item} />,
        assessor: `${item?.user?.name} ${item?.user?.surname}`,
      }))}
    />
  );
};
