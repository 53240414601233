import styled from "styled-components";

export const Select = styled.select`
  width: 100%;
`;

export const SelectWrapper = styled.div`
  display: flex;
  width: 100%;
`;
export const ContainerSelected = styled.div`
  width: 90%;
`;
export const ContainerButton = styled.div`
  width: 10%;
  text-align: center;
  display: flex;
  align-item: center;
  margin-left: 5px;
  align-items: center;

  button {
    height: 44px;
  }
`;

export const selectControlStyles = styles => ({
  ...styles,
  border: "1px solid #596778",
  cursor: "pointer",
});

export const selectOptionStyles = (styles, state) => ({
  ...styles,
  backgroundColor: state.isFocused ? "#DEEBFF" : "unset",
  color: state.isFocused ? "#FFF" : "unset",
  cursor: "pointer",
});

export const customStyles = {
  control: selectControlStyles,
  option: selectOptionStyles,
};
