import React, { useState, useEffect } from "react";
import { Tag, Dropdown } from "@mix/mixfiscal-designsystem";
import DatePicker, { registerLocale } from "react-datepicker";
import { format, addMonths } from "date-fns";

import { lastDayOfMonth } from "utils/date";
import ptBR from "date-fns/locale/pt-BR";
import "react-datepicker/dist/react-datepicker.css";

import * as S from "./styles";

registerLocale("ptBR", ptBR);
const TagsList = ({
  tags = [],
  onChangeDate,
  onChangeType,
  tag,
  addMonthsLength = 13,
  invertDate,
  tagsListStore,
}) => {
  const [startDate, setStartDate] = useState(tags[1]?.dtStart);
  const [endDate, setEndDate] = useState(tags[1]?.dtEnd);
  const [itemList, setItemList] = useState(tag ?? tags[0]);

  useEffect(() => {
    setStartDate(tags[1]?.dtStart);
    setEndDate(tags[1]?.dtEnd);
  }, [tags]);

  const onChange = date => {
    let end = addMonths(date, addMonthsLength);
    if (addMonthsLength === 0) {
      end = lastDayOfMonth(date);
    }
    setStartDate(date);
    setEndDate(end);
    onChangeDate(date, end);
  };

  const onChangeItemList = item => {
    setItemList(item);
    onChangeType(item);
  };

  const tagsList = () => {
    const tagsDropList = tagsListStore ?? [
      "XML",
      "Sped Contribuições",
      "Sped Fiscal",
    ];
    return (
      <ul>
        {tagsDropList.map(item => {
          return (
            <li
              role="button"
              key={item}
              onClick={() => {
                onChangeItemList(item);
              }}
            >
              {item}
            </li>
          );
        })}
      </ul>
    );
  };
  const ExampleCustomInput = React.forwardRef(({ onClick }, ref) => {
    const period = invertDate
      ? `${format(new Date(endDate), "LLL/yyyy", { locale: ptBR })}`
      : `${format(new Date(startDate), "LLL/yyyy", { locale: ptBR })}`;
    return (
      <Tag label={period} handleClick={onClick} ref={ref}>
        {period}
      </Tag>
    );
  });

  return (
    <S.ContainerFooter>
      {tags?.map(item => (
        <S.TagContainer>
          {item !== "XML" ? (
            <DatePicker
              selected={invertDate ? endDate : startDate}
              onChange={onChange}
              dateFormat="MM/yyyy"
              showMonthYearPicker
              customInput={<ExampleCustomInput />}
              locale="ptBR"
            />
          ) : (
            // <Tag label={item} type="simple" />
            <S.ConstainerDropDown>
              <p>
                <Dropdown dropContent={tagsList()} fitToParentWidth={false}>
                  <span>
                    <Tag label={itemList} type="simple" />
                  </span>
                </Dropdown>
              </p>
            </S.ConstainerDropDown>
          )}
        </S.TagContainer>
      ))}
    </S.ContainerFooter>
  );
};

export default TagsList;
