import styled from "styled-components";

export const RowTagsName = styled.div`
  width: 100%;
  padding: 2px 0;
  margin: 5px 0;
  display: flex;
  flex-flow: row wrap;
  gap: 7px;
  position: relative;
`;

export const ActionRemove = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;
