import React from "react";
import { Card } from "@mix/mixfiscal-designsystem";
import { InputContainer } from "./styles";

const ActionForm = ({ register }) => {
  return (
    <Card
      headerColor="#EE6B09"
      textColor="#FFF"
      title="AÇÃO"
      className="flatCard"
    >
      <p className="subtitle">
        Descreva o resultado desejado com esse CheckLink.
      </p>
      <InputContainer>
        <label>Resultado desejado</label>
        <textarea
          {...register(`action`)}
          placeholder="Digite um resultado desejado"
        />
      </InputContainer>
    </Card>
  );
};

export default ActionForm;
