import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { MdOpenInNew } from "react-icons/md";
import { Datatable, Pagination, Checkbox } from "@mix/mixfiscal-designsystem";
import { GRID_LAWS, LAWS } from "constants/routes";
import { useGradeIcms, useGradePisCofins } from "useCases/grid";

import {
  getOperacaoLabel,
  hasGradeEntrada,
  getAlqs,
  getAlqLabel,
} from "utils/context/grid-manager";

const ActionRender = ({ grade, openNewWindow, type = "icms" }) => {
  const { push } = useHistory();
  const handle = () => {
    if (openNewWindow) {
      return window.open(
        `${
          grade?.id_law
            ? LAWS.DETAIL(grade?.id_law)
            : GRID_LAWS.DETAIL(grade._id)
        }?type=${type}`,
      );
    }

    return push(
      `${
        grade?.id_law ? LAWS.DETAIL(grade?.id_law) : GRID_LAWS.DETAIL(grade._id)
      }?type=${type}`,
    );
  };

  return (
    <button
      style={
        openNewWindow
          ? {
              backgroundColor: "transparent",
              justifyContent: "center",
              display: "flex",
            }
          : {}
      }
      type="button"
      onClick={handle}
    >
      {openNewWindow ? <MdOpenInNew color="#a4a0a0" size={28} /> : "Detalhe"}
    </button>
  );
};

const ActionSelect = ({ grade, handleSelect }) => {
  const handleChange = value =>
    handleSelect({ type: value ? "add" : "remove", value: grade });

  return (
    <div>
      <Checkbox handleChangeCallback={handleChange} size={25} />
    </div>
  );
};

export const List = ({ params, ...props }) => {
  const [totalPages, setTotalPages] = useState(1);

  const [page, setPage] = useState(1);
  const { data, isLoading } = useGradeIcms({
    page,
    limit: 50,
    ...params,
  });

  useEffect(() => {
    if (data?.gridIcms?.totalPages) {
      setTotalPages(data?.gridIcms?.totalPages);
    }
  }, [data?.gridIcms]);

  return (
    <ListContent
      isLoading={isLoading}
      params={params}
      totalPages={totalPages}
      data={data?.gridIcms?.docs}
      setPage={setPage}
      {...props}
    />
  );
};

export const ListPisCofins = ({ params, ...props }) => {
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(50);

  const [page, setPage] = useState(1);
  const { data } = useGradePisCofins({
    page,
    limit,
    ...params,
  });

  useEffect(() => {
    if (data?.gradePisCofins?.totalPages) {
      setTotalPages(data?.gradePisCofins?.totalPages);
    }
  }, [data?.gradePisCofins]);

  return (
    <ListContent
      isPisCofins
      params={params}
      totalPages={totalPages}
      data={data?.gradePisCofins?.docs}
      setPage={setPage}
      setLimit={setLimit}
      {...props}
    />
  );
};

export const ListContent = ({
  hasSelect = false,
  handleSelect = () => {},
  setPage = () => {},
  setLimit = () => {},
  data = [],
  totalPages,
  isPisCofins = false,
  openNewWindow = false,
  isLoading = false,
}) => {
  const columns = useMemo(() => {
    let columnsData = [
      {
        field: "action",
        title: "",
      },
    ];

    if (!isPisCofins) {
      columnsData.push({
        field: "operacao",
        title: "Operação",
      });
      columnsData = [
        ...columnsData,
        ...[
          {
            field: "uf",
            title: "Uf Origem",
          },
          {
            field: "uf_saida",
            title: "Uf Destino",
          },
        ],
      ];
    }

    columnsData = [
      ...columnsData,
      ...[
        {
          field: "status",
          title: "Status",
        },
        {
          field: "atividade",
          title: "Atividade",
        },
        {
          field: "regime",
          title: "Regime",
        },
        {
          field: "qtd_produtos_grade",
          title: "Quantidade de Produtos",
        },
        { field: "ncm", title: "Ncm" },
        { field: "cest", title: "Cest" },
      ],
    ];

    if (hasSelect) {
      return [
        ...[{ field: "select", title: "Selecione" }],
        ...columnsData,
        ...[
          { field: "cst", title: "CST" },
          { field: "alq", title: "Alíquota" },
        ],
      ];
    }

    return [...columnsData, ...[{ field: "alq", title: "Alíquota" }]];
  }, [hasSelect, isPisCofins]);

  return (
    <>
      <Datatable
        handleChangePage={setPage}
        pagination={false}
        columns={columns}
        isLoading={isLoading}
        rawData={data.map(item => {
          return {
            ...item,
            action: (
              <ActionRender
                grade={item}
                openNewWindow={hasSelect || openNewWindow}
                type={isPisCofins && "pis"}
              />
            ),
            cest: item.cest,
            ncm: item.ncm,
            operacao: getOperacaoLabel(item.operacao),
            uf_saida:
              !hasGradeEntrada(item.operacao) && !item.uf_saida
                ? item.uf
                : item.uf_saida,
            uf: hasGradeEntrada(item.operacao) ? item.uf : "",
            status: item.status,
            qtd_produtos_grade: item.totalProdutosMix,
            select: hasSelect && (
              <ActionSelect grade={item} handleSelect={handleSelect} />
            ),
            alq: getAlqs(item)
              ?.map(alq => getAlqLabel(alq) ?? "Sem aliquota")
              .join(", "),
            cst: item.cst,
          };
        })}
      />

      <Pagination
        handlePageChange={value => setPage(value)}
        initialPage={1}
        siblingsCount={1}
        totalPages={totalPages}
        amountVariations={[50, 10, 150, 300]}
        handleChangeAmount={setLimit}
      />
    </>
  );
};
