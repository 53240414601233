/* eslint-disable no-cond-assign */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { Format } from "utils/date";

import { useBlogs } from "useCases";
import * as S from "../../styles";
import * as Sb from "./styles";

export const Blog = () => {
  const [listBlogs, setListBlogs] = useState([]);

  const { data: dataBlogs } = useBlogs();

  const getImage = str => {
    let m;
    const regex = new RegExp('src=\\"(.*?)\\"', "gm");

    while ((m = regex.exec(str)) !== null) {
      if (m.index === regex.lastIndex) {
        regex.lastInde++;
      }
      if (m[1]) {
        return m[1];
      }

      if (m[0] !== "") {
        return m[0];
      }
    }
    return m;
  };

  useEffect(() => {
    if (dataBlogs && dataBlogs.data) {
      const itemR = dataBlogs.data[0].map(item => {
        return item;
      });

      setListBlogs(itemR);
    }
  }, [dataBlogs]);

  const onClickCopy = value => {
    navigator.clipboard.writeText(value);
  };

  const onClickOpenLink = value => {
    window.open(value, "_blank");
  };

  return (
    <>
      <S.Container>
        <S.ContainerRow direction="row">
          <S.ContainerRowItem width="100%">
            <S.ContainerRowItemHeader>
              <S.ContainerRowItemHeaderTitle>
                Últimos Blogs
              </S.ContainerRowItemHeaderTitle>
              <S.ContainerRowItemHeaderTitleSub>
                Fique por dentro
              </S.ContainerRowItemHeaderTitleSub>
            </S.ContainerRowItemHeader>
            <Sb.ContainerRowItemBlogContainer>
              <Sb.ContainerRowItemBlogRowItem width="50%">
                {listBlogs &&
                  listBlogs.slice(0, 1).map((item, index) => {
                    const img = getImage(item.description);
                    return (
                      <Sb.ContainerRowItemBlogItemFull key={index}>
                        <img src={img} />
                        <Sb.ContainerRowItemBlogItemTitle>
                          {item.title}
                        </Sb.ContainerRowItemBlogItemTitle>
                        <Sb.ContainerRowItemBlogItemTitleListBtn
                          margin="5px 10px 0 0"
                          onClick={() => {
                            onClickCopy(item.link);
                          }}
                        >
                          Copiar link
                        </Sb.ContainerRowItemBlogItemTitleListBtn>
                        <Sb.ContainerRowItemBlogItemTitleListBtn
                          margin="5px 10px 0 0"
                          onClick={() => {
                            onClickOpenLink(item.link);
                          }}
                        >
                          Abrir Página
                        </Sb.ContainerRowItemBlogItemTitleListBtn>
                      </Sb.ContainerRowItemBlogItemFull>
                    );
                  })}
              </Sb.ContainerRowItemBlogRowItem>
              <Sb.ContainerRowItemBlogRowItem width="50%" direction="row">
                {listBlogs &&
                  listBlogs.map((item, index) => {
                    const img = getImage(item.description);
                    return (
                      <Sb.ContainerRowItemBlogItem key={index}>
                        <img src={img} />
                        <Sb.ContainerRowItemBlogItemTitleList
                          direction="row"
                          size="12px"
                        >
                          <Sb.ContainerRowItemBlogItemTitleListDate>
                            {Format(item.pubDate)}
                          </Sb.ContainerRowItemBlogItemTitleListDate>
                          {item.title}
                          <Sb.ContainerRowItemBlogItemTitleListBtnList margin="33px 10px 0 0">
                            <Sb.ContainerRowItemBlogItemTitleListBtn
                              onClick={() => {
                                onClickCopy(item.link);
                              }}
                            >
                              Copiar link
                            </Sb.ContainerRowItemBlogItemTitleListBtn>
                            <Sb.ContainerRowItemBlogItemTitleListBtn
                              margin="0 5px 0 0"
                              onClick={() => {
                                onClickOpenLink(item.link);
                              }}
                            >
                              Abrir Página
                            </Sb.ContainerRowItemBlogItemTitleListBtn>
                          </Sb.ContainerRowItemBlogItemTitleListBtnList>
                        </Sb.ContainerRowItemBlogItemTitleList>
                      </Sb.ContainerRowItemBlogItem>
                    );
                  })}
              </Sb.ContainerRowItemBlogRowItem>
            </Sb.ContainerRowItemBlogContainer>
          </S.ContainerRowItem>
        </S.ContainerRow>
      </S.Container>
    </>
  );
};
