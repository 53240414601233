import React, { useEffect, useMemo, useState, useCallback } from "react";
import { Datatable } from "@mix/mixfiscal-designsystem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { Format } from "utils/date";
import { Select, CurrencyField } from "components/form";
import { useRegisterLawsGradesContext } from "context/RegisterLawsContext";
import { BLANK_SELECT_LIST, BLANK_SELECT } from "constants/types";
import { GRADE_MODALS_TYPES } from "constants/Types/index";
import IconComponent from "components/IconExporter";
import { Container } from "components/structure";

import * as S from "./styles";

const BLANK_DATA = "Sem dados";

export const MVAEstado = ({ product, states, onChange, value, isDisabled }) => (
  <S.Container>
    <Select
      isDisabled={isDisabled}
      name="estado_mva"
      data={states}
      onChange={changeValue => onChange(product, { estado_mva: changeValue })}
      defaultValue={value}
    />
  </S.Container>
);

export const MVATipo = ({ product, onChange, stateValue, isDisabled }) => {
  const handleChange = changeValue =>
    onChange(product, { mva_tipo: changeValue });

  const state = stateValue?.label;
  const labelTipoMVA = product?.cat_mva ? product?.cat_mva[state] === "I" : "";

  return (
    <form style={{ minWidth: "200px", width: "100%" }}>
      <S.Container>
        <Select
          isDisabled={isDisabled || !(product?.state?.label !== "Selecione")}
          name="mva_tipo"
          onChange={handleChange}
          data={[
            BLANK_SELECT,
            {
              value: "Preço Final máximo ou tabelado",
              label: "Preço Final máximo ou tabelado",
            },
            {
              value: "MVA %)",
              label: "MVA %)",
            },
            {
              value: "Pauta - Valor",
              label: "Pauta - Valor",
            },
          ]}
          defaultValue={{
            value: labelTipoMVA ? "Preço Final máximo ou tabelado" : "MVA %)",
            label: labelTipoMVA ? "Preço Final máximo ou tabelado" : "MVA %)",
          }}
        />
      </S.Container>
    </form>
  );
};

export const MVAValor = ({ states, product, onChange, isDisabled }) => {
  const state = states[0];
  const tipoMVA = product?.cat_mva ? product?.cat_mva[state] : "";

  const handleChange = value => onChange(product, { valor_mva: value });

  const hasMVAPercent = product.mva_tipo === "MVA %)";

  return (
    <S.Container>
      <CurrencyField
        disabled={isDisabled || !(product?.state?.label !== "Selecione")}
        name="valor_mva"
        value={tipoMVA?.mva}
        onChange={handleChange}
        prefix={!hasMVAPercent ? "R$" : ""}
        suffix={hasMVAPercent ? "%" : ""}
        placeholder={hasMVAPercent ? "0,00%" : "R$0,00"}
      />
    </S.Container>
  );
};

export const getFundamentoLegal = ({ states, product }) => {
  if (states.length >= 1) {
    return (
      product?.fundamento_legal_icms &&
      product?.fundamento_legal_icms[states[0]]
    );
  }

  return ["Não foi possível identificar o fundamento legal"];
};

const RemoveAction = ({ product, type }) => {
  const { removeProductsMoveSelecteds, removeProductsAddSelecteds } =
    useRegisterLawsGradesContext();

  const onRemove = () => {
    if (GRADE_MODALS_TYPES.PRODUCTS_CONFIRMEDS_ADD === type) {
      return removeProductsAddSelecteds([product]);
    }

    if (GRADE_MODALS_TYPES.PRODUCTS_CONFIRMEDS_REMOVE === type) {
      // TODO: Falta implementar!
    }

    return removeProductsMoveSelecteds([product], true);
  };

  return (
    <FontAwesomeIcon
      onClick={onRemove}
      icon={faTrashCan}
      style={{
        marginLeft: "10px",
        fontSize: "15px",
        cursor: "pointer",
      }}
    />
  );
};

export const ProductLine = ({
  product = {},
  states,
  onChange,
  stateValue,
  disableMVA = false,
}) => {
  return {
    estado_mva: (
      <MVAEstado
        product={product}
        states={states}
        onChange={onChange}
        value={stateValue}
        isDisabled={disableMVA}
      />
    ),
    tipo_mva: (
      <MVATipo
        product={product}
        states={states}
        onChange={onChange}
        stateValue={stateValue}
        isDisabled={disableMVA}
      />
    ),
    data_importacao: product.createdAt
      ? Format(product.createdAt)
      : "--/--/---",
    valor_mva: (
      <MVAValor
        product={product}
        states={states}
        onChange={onChange}
        stateValue={stateValue}
        isDisabled={disableMVA}
      />
    ),
  };
};

const ProductDescription = ({ text }) => (
  <Container isFlex>
    {text ?? ""}
    {text !== BLANK_DATA && (
      <S.ColumnIconLink
        href={`https://google.com.br/search?q=${text}`}
        target="_blank"
        rel="noreferrer"
        title={text}
      >
        <IconComponent name="google" />
      </S.ColumnIconLink>
    )}
  </Container>
);

export const ListProducts = ({
  data = [],
  states = [],
  isAction = true,
  disableMVA = false,
  onChangeProduct = () => {},
  limit = 15,
  isLoading = false,
  isRemove = false,
  typeSelectionProduct = "move",
}) => {
  const [products, setProducts] = useState(data);

  const { setProductsMove, setProductsAdd } = useRegisterLawsGradesContext();

  const handleChange = values => {
    if (!isAction) {
      return false;
    }

    const getNotSelecteds = products.filter(product => {
      return !values.filter(value => value === product._id).length;
    });

    const selecteds = products.filter(product => {
      return values.filter(value => value === product._id).length;
    });

    const includesProducts = productsList => {
      const productsListMerge = productsList;

      selecteds.forEach(selected => {
        if (!productsList.find(product => selected._id === product._id)) {
          productsListMerge.push(selected);
        }
      });

      return productsListMerge.filter(
        productMove =>
          !getNotSelecteds.find(product => productMove._id === product._id),
      );
    };

    if (typeSelectionProduct === "add") {
      return setProductsAdd(includesProducts);
    }

    return setProductsMove(includesProducts);
  };

  const [, setProductChange] = useState({ values: {} });

  useEffect(() => setProducts(data), [data]);

  const statesList = useMemo(
    () => [
      ...BLANK_SELECT_LIST,
      ...states.map(state => ({
        value: state,
        label: state,
      })),
    ],
    [states],
  );

  const columns = useMemo(() => {
    const columnsValues = [
      { field: "description", title: "Descrição" },
      { field: "ean", title: "EAN" },
      { field: "cod_mix", title: "COD.MIX" },
      { field: "data_importacao", title: "Data da Importação" },
      { field: "cest", title: "Cest" },
    ];

    if (isAction) {
      columnsValues.push({ field: "tipo_mva", title: "Tipo MVA" });
      columnsValues.push({ field: "estado_mva", title: "Estado MVA" });
      columnsValues.push({ field: "valor_mva", title: "Valor MVA" });
    }

    columnsValues.push({
      field: "fundamento_legal",
      title: "Fundamento Legal",
    });

    if (isRemove) {
      columnsValues.push({
        field: "remove",
        title: "",
      });
    }

    return columnsValues;
  }, [isAction, isRemove]);

  const onChangeMva = useCallback(
    (product, values) => {
      setProductChange(state => {
        const localState = {
          _id: product._id,
          values: { ...state?.values, ...values },
        };

        if (localState?.values?.estado_mva?.label) {
          const catMVA = {
            [localState?.values?.estado_mva?.label]: {
              tipo: localState?.values?.mva_tipo?.label,
              mva: localState?.values?.valor_mva,
            },
          };

          setProducts(productsList =>
            productsList.map(row => {
              if (row._id === localState._id) {
                const productChange = {
                  ...row,
                  cat_mva: catMVA,
                  state: localState?.values?.estado_mva,
                  mva_tipo: localState?.values?.mva_tipo?.label,
                };

                if (onChangeProduct) onChangeProduct(productChange);

                return productChange;
              }
              return row;
            }),
          );
        }

        return localState;
      });
    },
    [setProductChange, onChangeProduct],
  );

  return (
    <S.TableListProducts>
      <Datatable
        size="12px"
        selectableRows={isAction}
        isLoading={isLoading}
        onSelectionChange={handleChange}
        pagination={false}
        columns={columns}
        amountPerPage={limit}
        rawData={products.map(product => {
          const stateValue = product?.state?.label ?? statesList[0];

          const fundamentoLegal = getFundamentoLegal({ states, product });

          return {
            id: product?.product?._id ?? product?._id,
            description: ProductDescription({
              text: product.prod_nome,
            }),
            ean: ProductDescription({
              text: product.prod_codigo_barras ?? BLANK_DATA,
            }),
            cod_mix: product?.product?._id ?? product._id,
            cest: product.cest,
            ...(isAction
              ? ProductLine({
                  product,
                  states: statesList,
                  onChange: onChangeMva,
                  stateValue,
                  disableMVA,
                })
              : {}),
            fundamento_legal: (fundamentoLegal ?? []).join(", "),
            remove: isRemove
              ? RemoveAction({ product, type: typeSelectionProduct })
              : null,
          };
        })}
      />
    </S.TableListProducts>
  );
};
