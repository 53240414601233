import styled from "styled-components";

export const Row = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  align-items: flex-end;

  input {
    border-bottom: 1px solid #304156;
    margin-top: 10%;
  }
`;
