import styled, { css } from "styled-components";

const columnFormItemModifiers = {
  setBackground: item => css`
    background: ${item} !important;
  `,
  setFlex: item => css`
    flex: ${item};
  `,
  setMargin: item => css`
    margin: ${item};
  `,
  setWidth: item => css`
    width: ${item} !important;
  `,
  setAlign: item => css`
    justify-content: ${item};
    align-items: ${item};
  `,
  setTextColor: item => css`
    color: ${item} !important;
    svg {
      color: ${item};
    }
  `,
};
export const FilterContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const FilterItemShow = styled.div`
  width: 100%;
  margin: 10px 0 20px 0;
  background-color: #ffffff;
  box-shadow: 0px 0px 8px #0000002e;
`;

export const ButtonFilter = styled.button`
  background-color: #f3f3f3;
  border-radius: 5px;
  padding: 5px 10px 5px 10px;
  margin: 0 10px 0 0px;
  width: -moz-fit-content;
  width: fit-content;
`;

export const FIlterContentItem = styled.div`
  ${({ align = "center", margin }) => css`
    display: flex;
    width: 100%;

    ${margin && columnFormItemModifiers.setMargin(margin)}
    ${align && columnFormItemModifiers.setAlign(align)}
  `}
`;

export const FIlterContent = styled.div`
  display: flex;
  width: 100%;
  padding: 10px 15px 15px 15px;
  flex-direction: column;
`;

export const FilterContentTitle = styled.div`
  width: 100%;
  padding: 10px 21px 0px 21px;
  border-bottom: 2px solid #dbdbdb;
  h2 {
    font-size: 20px;
    color: rgb(37, 105, 37);
  }
`;

export const FIlterContentField = styled.div`
  ${({ color, flex, margin }) => css`
    display: flex;
    padding: 0 5px 0 5px;
    justify-content: center;
    flex-direction: column;
    ${margin && columnFormItemModifiers.setMargin(margin)}
    ${flex && columnFormItemModifiers.setFlex(flex)}
    ${color && columnFormItemModifiers.setBackground(color)}
  `}
`;

export const FilterContentFilterFieldITem = styled.div`
    width: 100%:
`;

export const FilterContentBtnAdd = styled.button`
  ${({ color, textColor, width = "100px" }) => css`
    width: 100px;
    cursor: pointer;
    margin: 0 5px 0 5px;
    border-radius: 5px;
    width: 100px;
    ${width && columnFormItemModifiers.setWidth(width)}
    ${color && columnFormItemModifiers.setBackground(color)}
    ${textColor && columnFormItemModifiers.setTextColor(textColor)}
  `}
`;

export const FilterContainerActions = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FilterContainerActionsItem = styled.div`
  ${({ width, align }) => css`
    display: flex;
    flex-direction: row;
    padding: 0 5px 0 5px;
    justify-content: center;
    flex-direction: row;
    ${width && columnFormItemModifiers.setWidth(width)}
    ${align && columnFormItemModifiers.setAlign(align)}
  `}
`;
