import React, { Suspense } from "react";

const AppObservacoes = React.lazy(() => import("./Observacoes.component"));

export const Observacoes = ({ token, history }) => (
  <Suspense fallback={<div>Carregando...</div>}>
    <AppObservacoes
      token={token}
      width="100%"
      height="1000px"
      currentRoute="/relatorio-observacao/relatorio"
      onChangeRoute={newModulePath =>
        history && history.push(`/${module}${newModulePath}`)
      }
    />
  </Suspense>
);
