import React, { useState } from "react";
import { TextField } from "components/form";

import * as S from "./styles";

export const CardFilter = ({ isShowFilter, setSearch, setIsShowFIlter }) => {
  const [name, setName] = useState();

  const setSearchItem = () => {
    setSearch(name);
  };
  return (
    <S.CardFilterItem>
      <S.CardFilterItemShow active={isShowFilter}>
        <S.CardFilterLabel>Filtro</S.CardFilterLabel>
        <S.CardFilterContent>
          <TextField
            label=""
            placeholder="Nome"
            value={name || ``}
            onChange={e => {
              const { value } = e.target;
              setName(value);
            }}
          />
        </S.CardFilterContent>
        <S.CardFilterFooter>
          <S.ButtonFIlterError
            onClick={() => {
              setIsShowFIlter(false);
              setSearch(`all`);
            }}
          >
            Cancelar
          </S.ButtonFIlterError>
          <S.ButtonFIlter onClick={() => setSearchItem()}>
            Buscar
          </S.ButtonFIlter>
        </S.CardFilterFooter>
      </S.CardFilterItemShow>
    </S.CardFilterItem>
  );
};
