import React, { useEffect } from "react";
import { useModules } from "useCases";
import { SelectPerm } from "./Permissions";

import * as S from "../../styles";

export const PermiForm = ({
  id,
  allRuleValues,
  setAllRuleValues,
  groupModules,
  setGroupModules,
  newSetOfModules,
  control,
  newSetOfModulesGroups,
}) => {
  const { data: dataModules } = useModules();

  useEffect(() => {
    if (newSetOfModulesGroups && newSetOfModulesGroups.length > 0) {
      setGroupModules(newSetOfModulesGroups);
    }
  }, [newSetOfModulesGroups, setGroupModules, groupModules]);

  return (
    <>
      <S.ColumnFormItem>
        <SelectPerm
          id={id}
          control={control}
          newSetOfModules={newSetOfModules}
          allRuleValues={allRuleValues}
          setAllRuleValues={setAllRuleValues}
          dataModules={dataModules}
          groupModules={groupModules}
          setGroupModules={setGroupModules}
        />
      </S.ColumnFormItem>
    </>
  );
};
