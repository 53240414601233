import { useState, useEffect, useCallback } from "react";
import { useCheckLinkAPI } from "../../../hooks";
import { getURLWithParams } from "../../../utils/context";

const useCategories = () => {
  const api = useCheckLinkAPI();
  const [category, setCategory] = useState([]);
  const [categories, setCategories] = useState([]);

  const getOne = useCallback(
    async ({ id }) => {
      try {
        const { data } = await api.get(getURLWithParams("/categories", { id }));
        setCategory(data);
      } catch (e) {
        console.log(e);
      }
    },
    [api],
  );

  const getAll = useCallback(
    async ({ limit = 10, page = 0 }) => {
      try {
        const { data } = await api.get(
          getURLWithParams("/categories", { limit, page }),
        );
        setCategories(data);
      } catch (e) {
        console.log(e);
      }
    },
    [api],
  );

  const create = useCallback(
    async payload => {
      try {
        await api.post(getURLWithParams("/categories", payload));
      } catch (e) {
        console.log(e);
      }
    },
    [api],
  );

  const update = useCallback(
    async payload => {
      try {
        await api.patch(getURLWithParams("/categories", payload));
      } catch (e) {
        console.log(e);
      }
    },
    [api],
  );

  const detele = useCallback(
    async ({ id }) => {
      try {
        await api.get(getURLWithParams("/categories", { id }));
      } catch (e) {
        console.log(e);
      }
    },
    [api],
  );

  useEffect(() => getAll({}), [getAll]);

  return {
    category,
    categories,
    getOne,
    getAll,
    create,
    update,
    detele,
  };
};

export default useCategories;
