import { useState, useCallback } from "react";
import {
  useAPIAuthenticate,
  useAPILinkMix,
  useNfeAPIAuthenticate,
  useMxfAPI,
} from "hooks";
import { getURLWithParams } from "utils/context";
import { useAuth } from "context/AuthContext";

const parseQuery = object => {
  let stringQuery = "?";
  Object.entries(object).forEach((item, index) => {
    stringQuery += `${item[0]}=${item[1]}${
      index !== Object.values(object).length - 1 ? "&" : ""
    }`;
  });
  return stringQuery;
};
export const useEan0200Consult = () => {
  const api = useAPILinkMix();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  // const { tokenCompanies } = useAuth();
  const fetch = useCallback(
    (prod = {}) => {
      setLoading(true);
      const fetchData = async () => {
        try {
          const values = await api.get(
            getURLWithParams("/fornecedores/produtos", {
              ...prod,
            }),
          );
          setData(values);
          setLoading(false);
        } catch (e) {
          console.log(e.messagge);
        }
      };
      fetchData();
    },
    [api],
  );

  return { isLoading: loading, data, fetch };
};
export const useEanConsult = () => {
  const api = useNfeAPIAuthenticate();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const fetch = useCallback(
    (prod = {}) => {
      setLoading(true);
      const fetchData = async () => {
        try {
          const values = await api.get(
            `/tax-note/last-tax-note${parseQuery(prod)}`,
          );
          setData(values);
          setLoading(false);
        } catch (e) {
          console.log(e.messagge);
        }
      };
      fetchData();
    },
    [api],
  );

  return { isLoading: loading, data, fetch };
};
export const useCalculator = () => {
  const api = useMxfAPI();

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const fetch = useCallback(
    (prod = {}) => {
      setLoading(true);
      const fetchData = async () => {
        try {
          const form = new FormData();
          for (const fieldName of Object.keys(prod)) {
            form.append(fieldName, prod[fieldName]);
          }
          const values = await api.post("/calculadora/linkmix.php", form, {
            headers: {
              "Content-Type": "text/html; charset=UTF-8",
            },
          });
          setData(values);
          setLoading(false);
        } catch (e) {
          console.log(e.messagge);
        }
      };
      fetchData();
    },
    [api],
  );

  return { isLoading: loading, data, fetch };
};
export const useAnalyticsConsultProducts = () => {
  const api = useAPILinkMix();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { tokenCompanies } = useAuth();
  const fetch = useCallback(
    (prod = {}) => {
      setLoading(true);
      const fetchData = async () => {
        try {
          const values = await api.get(
            getURLWithParams("/vendas/produtos", {
              ...prod,
              token: tokenCompanies,
            }),
          );
          setData(values);
          setLoading(false);
        } catch (e) {
          console.log(e.messagge);
        }
      };
      fetchData();
    },
    [api, tokenCompanies],
  );

  return { isLoading: loading, data, fetch };
};
export const useTributesConsult = id => {
  const api = useAPIAuthenticate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const fetch = useCallback(
    (prod = {}) => {
      setLoading(true);
      const fetchData = async () => {
        try {
          const values = await api.get(
            getURLWithParams(`/product/${id}/grade-by-cenary`, {
              ...prod,
            }),
          );
          setData(values);
          setLoading(false);
        } catch (e) {
          console.log(e.messagge);
        }
      };
      fetchData();
    },
    [api, id],
  );

  return { isLoading: loading, data, fetch };
};

export const useGraphicConsult = () => {
  const api = useAPILinkMix();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { tokenCompanies } = useAuth();
  const fetch = useCallback(
    (prod = {}) => {
      setLoading(true);
      const fetchData = async () => {
        try {
          const values = await api.post("/vendas/produtos", {
            ...prod,
            token: tokenCompanies,
          });
          setData(values);
          setLoading(false);
        } catch (e) {
          console.log(e.messagge);
        }
      };
      fetchData();
    },
    [api, tokenCompanies],
  );

  return { isLoading: loading, data, fetch };
};

export const useProductsSearch = () => {
  const api = useAPIAuthenticate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const fetch = useCallback(
    (prod = {}) => {
      setLoading(true);
      const fetchData = async () => {
        try {
          const values = await api.post(`/products/search`, {
            ...prod,
          });
          const prodMix = [];
          const prodCli = [];
          values?.data?.docs?.forEach(item => {
            if (item.tipo === "mix") prodMix.push(item);
            else prodCli.push(item);
          });
          const newValues = [];
          const newValuesCli = [];
          prodMix.forEach(mix => {
            const filterCli = prodCli.filter(
              cli => cli.id_prod === mix.id_prod,
            );
            newValues.push(mix);
            newValues.push(...filterCli);
            newValuesCli.push(...filterCli);
          });
          const exceptionsCli = [];
          newValuesCli.forEach(item => {
            const findProdCli = prodCli.filter(
              itemCli => itemCli.id === item.id,
            );
            if (findProdCli.length === 0) exceptionsCli.push(item);
          });
          values.data.docs = newValues.concat(exceptionsCli);
          setData(values);
          setLoading(false);
        } catch (e) {
          console.log(e.messagge);
        }
      };
      fetchData();
    },
    [api],
  );

  return { isLoading: loading, data, fetch };
};

export const useProductsConsult = () => {
  const api = useAPIAuthenticate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const fetch = useCallback(
    (id = "") => {
      setLoading(true);
      const fetchData = async () => {
        try {
          const values = await api.get(`/customerProduct/${id}`);
          setData(values);
          setLoading(false);
        } catch (e) {
          console.log(e.messagge);
        }
      };
      fetchData();
    },
    [api],
  );

  return { isLoading: loading, data, fetch };
};

export const useProductsConsultMix = () => {
  const api = useAPIAuthenticate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const fetch = useCallback(
    (id = "") => {
      setLoading(true);
      const fetchData = async () => {
        try {
          const values = await api.get(`/product/${id}`);
          setData(values);
          setLoading(false);
        } catch (e) {
          console.log(e.messagge);
        }
      };
      fetchData();
    },
    [api],
  );

  return { isLoading: loading, data, fetch };
};
