import React from "react";
import { Card } from "@mix/mixfiscal-designsystem";
import { BodyCard } from "./styles";

const TributeCardInner = ({ children, titleLeft, titleRight }) => (
  <Card className="card-custom" hasElevation={false}>
    <BodyCard>
      <span>{titleLeft}</span>
      <span style={{ color: "green" }}>{titleRight}</span>
    </BodyCard>
    <div style={{ height: "200px" }}>{children}</div>
  </Card>
);

export default TributeCardInner;
