import React from "react";
import { useParams } from "react-router-dom";
import { Card } from "@mix/mixfiscal-designsystem";
import { useNcm, useNcmActions } from "useCases/ncm";

import { NcmForm } from "./NcmForm";
import * as S from "./NcmCrud.styles";

const getTitle = id => ({
  titleBreadcrumb: id ? "Detalhe NCM" : "Cadastrar NCMS",
  titleCard: id ? "DETALHE NCM" : "CADASTRO NCMS",
});

const NcmContent = ({ data }) => {
  const { onSave: onSaveAction } = useNcmActions();
  const onSave = fields => {
    const fieldsSend = {
      ...fields,
      ncm_ex: fields?.ncm_ex?.value === "SIM",
      capitulo: fields?.capitulo?.value,
      posicao: fields?.posicao?.value,
      sessao: fields?.sessao?.value,
      subposicao: fields?.subposicao?.value,
    };

    onSaveAction(data?._id, fieldsSend);
  };

  return <NcmForm data={data} onSave={onSave} />;
};

export const NcmCrud = () => {
  const { id } = useParams();
  const { data } = useNcm(id);

  return (
    <S.Container>
      <S.Content>
        <Card
          title={getTitle(id)?.titleCard}
          bold={false}
          titleIcon="certificado"
          hasElevation
        >
          <NcmContent data={data?.data} />
        </Card>
      </S.Content>
    </S.Container>
  );
};
