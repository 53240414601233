import React from "react";
import { Datatable } from "@mix/mixfiscal-designsystem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";

import * as S from "../styles";

export const ListTableConfigGroups = ({
  tableData,
  setTableData,
  companies,
  stores,
  accessGroups,
}) => {
  const deleteTableRow = selectedItemId =>
    setTableData(tableData.filter(row => row._id !== selectedItemId));

  const findCompanyName = companyId => {
    return companies?.find(company => company.companyId === companyId)
      ?.companyName;
  };

  const findStoreName = storeId => {
    const foundStore = stores?.stores?.docs?.find(
      store => store._id === storeId,
    );
    return foundStore?.companyName || "Sem Filial";
  };

  const findAccessGroupName = accessGroupId => {
    const foundAccessGroup = accessGroups?.docs?.find(
      accessGroup => accessGroup._id === accessGroupId,
    );
    return foundAccessGroup?.groupName;
  };

  return (
    <S.CollumFormVerticalItem setMargin="15px 0px 0px 0px">
      <Datatable
        pagination={false}
        amountVariations={[10, 20, 30, 300, 500]}
        size="12px"
        columns={[
          { field: "action", title: "" },
          { field: "accessGroup", title: "Grupo de acesso" },
          { field: "company", title: "Matriz" },
          { field: "store", title: "Loja" },
        ]}
        rawData={tableData?.map(item => ({
          action: (
            <>
              {/* <FontAwesomeIcon
                icon={faSearchPlus}
                style={{
                  fontSize: "13px",
                }}
                color="#565656"
                onClick={() =>
                  editTableRow(
                    item._id,
                    item.company,
                    item.store,
                    item.accessGroup,
                  )
                }
              /> */}
              <FontAwesomeIcon
                icon={faCircleXmark}
                style={{
                  fontSize: "13px",
                }}
                color="red"
                onClick={() => deleteTableRow(item._id)}
              />
            </>
          ),
          accessGroup: findAccessGroupName(item.accessGroup),
          company: findCompanyName(item.company),
          store: findStoreName(item.store),
        }))}
      />
    </S.CollumFormVerticalItem>
  );
};
