import styled, { css } from "styled-components";

export const UploadFile = styled.div`
  position: relative;
  height: 63px;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0px #00000033;
  padding: 2px;
  cursor: pointer;
  margin-top: 10px;
`;

export const UploadFileRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  span {
    text-align: center;
    width: 100%;
  }
`;

export const UploadFileRowItem = styled.div`
  display: flex;
  position: relative;
  ${({ width = "100%" }) => css`
    ${width && `width: ${width}`}
  `}
`;

export const InputFieldBtn = styled.button`
  background-color: #ffff;
  box-shadow: 0 1px 5px #00000033;
  margin: 0 5px 0 0;
  width: 100%;
  padding: 0 5px 0 5px;
  border-radius: 5px;
  &:hover {
    color: #fff;
    background-color: #ff5252;
  }
`;
export const InputFileItem = styled.input`
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 999;
  cursor: pointer;
`;

export const InputAbsolute = styled.div`
  width: 100%;
  position: absolute;
  height: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  border: 1px dashed #596778;
  border-radius: 5px;
  padding: 0 14px;
  cursor: pointer;

  ${({ isOver }) => css`
    ${isOver && `z-index: 20;`}
  `}

  p {
    font-size: 15px;
    color: #596778;
  }
`;

export const InputAbsoluteButton = styled.div`
  margin-right: 20px;
  padding: 4px 10px;
  background-color: white;
  color: #596778;
  box-shadow: 0 1px 5px #00000033;
  align-self: unset;
`;

export const InputAbsoluteFileDescription = styled.p`
  max-width: 500px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const customBadge = {
  setType: type => css`
    ${type === `MATRIZ` &&
    `
    background-color: #adc86a;
    color:#fff
    `}
    ${type === `Matriz` &&
    `
    background-color: #adc86a;
    color:#fff
    `}
    ${type === `ativo` &&
    `
    background-color: #adc86a;
    color:#fff
    `}
    ${type === `Filial` &&
    `
    background-color: #17456B;
    color:#fff
    `}
     ${type === `FILIAL` &&
    `
    background-color: #17456B;
    color:#fff
    `}
      ${type === `inativo` &&
    `
    background-color: #f4a300cc;
    color:#fff
    `}
  `,
};

export const Badge = styled.div`
  ${({ type }) => css`
    min-width: 100%;
    font-size: 12px;
    padding: 0px 10px 0px 3px;
    border-radius: 5px;
    font-weight: bold;
    margin: 0 4px 0 4px;
    ${type && customBadge.setType(type)}
  `}
`;
