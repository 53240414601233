import React from "react";
import { useForm } from "react-hook-form";
import { TextField, Form } from "components/form";
import { Button, Container, Alert } from "components/structure";
import { useStoreWhatsAppForm } from "useCases/store";

export const StoreWhatsAppForm = ({ idCli, number, onChangeSuccess }) => {
  const { handleSubmit, control, watch } = useForm();

  const { onSave, isLoading } = useStoreWhatsAppForm({
    id: idCli,
    whatsapp: number,
  });

  const onSubmit = data =>
    onSave({
      _id: idCli,
      ...data,
    }).then(() => onChangeSuccess(data));

  const whatsappValue = watch("whatsapp");

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Alert
        type="primary"
        title="Informativo"
        content="Cliente não tem o whatsApp cadastrado nos dados"
      />
      <TextField
        label="WhatsApp Numero"
        control={control}
        value={number}
        name="whatsapp"
      />
      <Container paddingSize="xs" justifyCenter>
        <Button
          isDisabled={!whatsappValue?.length || whatsappValue?.length < 8}
          isLoading={isLoading}
          type="submit"
          label="Salvar"
        />
      </Container>
    </Form>
  );
};
