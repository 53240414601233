import React, { useState } from "react";
import Select from "react-select";
import { TextField } from "components/form";
import { Tooltip } from "@mix/mixfiscal-designsystem";
import { generatePassword } from "utils";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCopy,
  faEye,
  faEyeSlash,
  faGear,
} from "@fortawesome/free-solid-svg-icons";
import { useApp } from "context/AppContext";
import { PermissionForm } from "./PermissionForm";

import * as S from "../styles";

export const UserForm = ({
  userAuth,
  RoleType,
  responsibility,
  setResponsibility,
  ActiveAcount,
  isActive,
  setIsActive,
  data,
  control,
  register,
  setValue,
  getValues,
}) => {
  const { handleCopy } = useApp();

  const [typePassword, setTypePassword] = useState("password");

  const handleTypePassword = () => {
    if (typePassword === "password") {
      setTypePassword("text");
    }
    if (typePassword === "text") {
      setTypePassword("password");
    }
  };

  const generatePass = () => {
    const rules = [
      { chars: "abcdefghijklmnopqrstuvwxyz", min: 4 },
      { chars: "ABCDEFGHIJKLMNOPQRSTUVWXYZ", min: 1 },
      { chars: "0123456789", min: 3 },
      { chars: "!@#$&*", min: 2 },
    ];
    const pswd = generatePassword(8, rules);

    if (typePassword === "password") {
      handleTypePassword();
    }

    setValue("password", pswd);
    setValue("confirmPassword", pswd);
  };

  return (
    <>
      <S.ColumnFormItem isPaddingInput="8px">
        <TextField
          label="Nome"
          placeholder="Nome"
          name="name"
          control={control}
        />
        <TextField
          label="Sobrenome"
          placeholder="Sobrenome"
          name="surname"
          control={control}
        />
      </S.ColumnFormItem>
      <S.ColumnFormItem setMargin="3px 0px 0px 0px" isPaddingInput="8px">
        <TextField
          type="email"
          label="E-mail"
          placeholder="E-mail"
          name="email"
          control={control}
        />
      </S.ColumnFormItem>
      <S.ColumnFormItem setMargin="3px 0px 0px 0px" isPaddingInput="8px">
        <TextField
          type="text"
          label="Número do WhatsApp (Usado na empresa)"
          placeholder="Número do WhatsApp"
          name="whatsAppNumber"
          control={control}
        />
      </S.ColumnFormItem>
      <S.ColumnFormItem setMargin="5px 0px 0px 0px">
        <TextField
          type={typePassword}
          label="Senha"
          placeholder="Senha"
          name="password"
          control={control}
          autoComplete="off"
        />
        <TextField
          type={typePassword}
          label="Confirmar Senha"
          placeholder="Confirmar Senha"
          name="confirmPassword"
          control={control}
          autoComplete="off"
        />
      </S.ColumnFormItem>
      <S.ColumnFormItem align="right" setMargin="5px 0px 0px 0px">
        <S.ButtonFuctionInput
          onClick={() => {
            generatePass();
          }}
          type="button"
        >
          <FontAwesomeIcon
            icon={faGear}
            style={{
              marginTop: "5px",
              fontSize: "12px",
            }}
            color="#848484"
          />
          <span> Gerar senha</span>
        </S.ButtonFuctionInput>
        <Tooltip body="Mostrar" position="top" zIndex={2} zIndexBackdrop={1}>
          <S.ButtonFuctionInput
            onClick={() => {
              handleTypePassword();
            }}
            type="button"
          >
            <FontAwesomeIcon
              icon={typePassword === "password" ? faEye : faEyeSlash}
              style={{
                marginTop: "5px",
                fontSize: "14px",
              }}
              color="#848484"
            />
          </S.ButtonFuctionInput>
        </Tooltip>
        <Tooltip
          body="Copiar senha"
          position="top"
          zIndex={2}
          zIndexBackdrop={1}
        >
          <S.ButtonFuctionInput
            onClick={() => {
              handleCopy(getValues("password"), "Senha");
            }}
            type="button"
          >
            <FontAwesomeIcon
              icon={faCopy}
              style={{
                marginTop: "5px",
                fontSize: "14px",
              }}
              color="#848484"
            />
          </S.ButtonFuctionInput>
        </Tooltip>
      </S.ColumnFormItem>
      <S.ColumnFormItem setMargin="5px 0 0 0">
        <S.InputForm>
          <S.Label>Cargo</S.Label>
          <Select
            labelType="top"
            name="role"
            options={RoleType.map(item => ({
              value: item.value,
              label: item.label,
            }))}
            label="Cargo"
            control={control}
            value={responsibility}
            onChange={select => {
              setValue("role", select.value);
              setResponsibility(select);
            }}
          />
        </S.InputForm>
      </S.ColumnFormItem>
      <S.ColumnFormItem setMargin="5px 0 0 0">
        <S.InputForm>
          <S.Label>Conta Ativa</S.Label>
          <Select
            labelType="top"
            name="is_active"
            options={ActiveAcount.map(item => ({
              value: item.value,
              label: item.label,
            }))}
            label="Conta Ativa"
            control={control}
            value={isActive}
            onChange={select => {
              setValue(`is_active`, select.value);
              setIsActive(select);
            }}
          />
        </S.InputForm>
      </S.ColumnFormItem>
      {userAuth.type === "root" && (
        <S.CollumFormVerticalItem setMargin="15px 0 0 0">
          <S.SwitchContainer>
            <S.SwitchContainerCheckBox>
              <div className="checkBox">
                <input
                  {...register("is_mix")}
                  type="checkbox"
                  name="is_mix"
                  defaultChecked={data?.is_mix}
                />
              </div>
            </S.SwitchContainerCheckBox>
            <S.SwitchTexWrapper>
              <p>Usuário Mix?</p>
              <span>
                (Selecionar se o usuário for um colaborador MIX FISCAL)
              </span>
            </S.SwitchTexWrapper>
          </S.SwitchContainer>
        </S.CollumFormVerticalItem>
      )}

      <S.CollumFormCheckBox>
        <PermissionForm register={register} data={data} />
      </S.CollumFormCheckBox>
    </>
  );
};
