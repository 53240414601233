import styled from "styled-components";

export const Container = styled.div`
  width: 300px;
  height: 400px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.7);

  display: flex;
  flex-direction: column;
`;

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 54px;
  padding: 16px;

  background-color: #5a8dee;
  color: #fff;

  & > div {
    display: flex;
    align-items: center;
    overflow: hidden;

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    svg {
      flex-shrink: 0;
      margin-right: 8px;
    }
  }

  & > svg {
    flex-shrink: 0;
    position: relative;
    z-index: 2;
    cursor: pointer;
  }

  .buttonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #fff;
    padding: 8px;
    cursor: pointer;
    flex-shrink: 0;

    svg {
      margin: 0;
      fill: #5a8dee;
    }
  }
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  border: 1px solid #ddd;
  overflow: auto;
  padding: 16px;
`;

export const List = styled.div`
  display: flex;

  & > div {
    width: 100%;
  }

  .searchBox {
    display: flex;
    align-items: center;
    border-radius: 8px;

    background-color: #e5e5e5;
    padding: 4px 8px;

    margin-bottom: 16px;

    input {
      color: #aaaaaa;
      background-color: inherit;

      ::placeholder {
        color: inherit;
      }
    }

    svg {
      margin-right: 8px;
    }
  }

  .fieldContainer {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    padding: 4px 0;
    border-bottom: 1px solid #e5e5e5;

    input {
      margin-right: 4px;
    }

    p {
      color: #596778;
    }
  }

  .listTitle {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2;
    cursor: pointer;
    border-bottom: 1px solid #e5e5e5;

    margin-bottom: 8px;

    p {
      text-transform: uppercase;
      margin: 0 4px;
      color: #aaa;
    }
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 16px;

  svg {
    position: relative;
    z-index: 2;
    cursor: pointer;
  }

  svg:not(:last-of-type) {
    margin-right: 8px;
  }
`;
