import React from "react";
import { Card } from "@mix/mixfiscal-designsystem";
import Switch from "components/UI/Switch";
import * as S from "./styles";

const ConfigForm = ({
  categories,
  register,
  monitoring,
  onChangeMonitoring,
}) => (
  <Card
    headerColor="#59AEC4"
    textColor="#FFF"
    title="CONFIGURAÇÃO"
    className="flatCard"
  >
    <p className="subtitle">
      Defina o nome, categorias e as configurações do CheckLink
    </p>
    <div className="row">
      <S.InputContainer>
        <label>Nome</label>
        <input
          {...register("name", { required: true })}
          placeholder="Digite um nome"
        />
      </S.InputContainer>
      <S.InputContainer>
        <label>Categoria</label>
        <select {...register(`category._id`, { required: true })}>
          <option value="">Selecione uma opção</option>
          {categories.map(category => (
            <option key={category._id} value={category._id}>
              {category.title}
            </option>
          ))}
        </select>
      </S.InputContainer>
    </div>
    <S.InputContainer>
      <label>Descrição</label>
      <input {...register("description")} placeholder="Digite uma descrição" />
    </S.InputContainer>
    <div className="row">
      <div className="switch">
        <Switch
          name="checked-monitoramento-inteligente"
          handleChange={onChangeMonitoring}
          initialValue={monitoring}
        />
        <span>Monitoramento Inteligente</span>
      </div>
    </div>
  </Card>
);

export default ConfigForm;
