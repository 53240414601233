import styled from "styled-components";

export const ContainerTooltip = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 13px;
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: -6%;
  margin-top: -6%;
  > div {
    display: flex;
    align-items: center;
    flex-direction: row;
    > p {
      margin-left: 1px;
      margin-right: 6px;
      margin-bottom: 6px;
      margin-top: 6px;
      color: ${props => (props.color ? "#45924f" : "")};
    }
  }
`;

export const Lin1 = styled.div`
  grid-area: lin1;
  font-size: 22px;
  font-family: Cairo;
  color: ${props => (props.isOrange ? "#EE6B09" : "#45924f")};
  border-top: 1px solid #596778;
  border-right: 1px solid #596778;
  padding-left: 15px;
  padding-top: 7px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Lin2 = styled.div`
  grid-area: lin2;
  display: grid;
  grid-template-areas: "l2-col1  l2-col2";
  grid-template-columns: 140px calc(100% - 140px);
  grid-template-rows: 30px;
  border-top: 1px solid #596778;
  border-right: 1px solid #596778;
  p {
    font-size: 12px;
    font-family: Cairo;
    color: ${props => (props.isOrange ? "#EE6B09" : "#596778")};
    cursor: pointer;
  }

  .l2-col1 {
    grid-area: l2-col1;
    display: flex;
    align-items: center;
    margin-left: 16px;
    border-right: 1px solid #596778;
  }
  .l2-col2 {
    grid-area: l2-col2;
    display: flex;
    align-items: center;
    margin-left: 16px;
    line-height: 13px;
  }
`;

export const Lin3 = styled.div`
  grid-area: lin3;
  display: grid;
  grid-template-areas: "l3-col1  l3-col2";
  grid-template-columns: 140px calc(100% - 140px);
  grid-template-rows: 30px;
  border-top: 1px solid #596778;
  border-right: 1px solid #596778;
  p {
    font-size: 12px;
    font-family: Cairo;
    color: #596778;
    cursor: pointer;
  }

  .l3-col1 {
    grid-area: l3-col1;
    display: flex;
    align-items: center;
    margin-left: 16px;
    border-right: 1px solid #596778;
  }
  .l3-col2 {
    grid-area: l3-col2;
    display: flex;
    align-items: center;
    margin-left: 16px;
  }
`;

export const Lin4 = styled.div`
  grid-area: lin4;
  display: grid;
  grid-template-areas: "l4-col1  l4-col2 l4-col3";
  grid-template-columns: 140px 178px 178px;
  grid-template-rows: 30px;
  border-top: 1px solid #596778;
  border-bottom: 1px solid #596778;
  border-right: 1px solid #596778;
  p {
    font-size: 12px;
    font-family: Cairo;
    color: #596778;
    cursor: pointer;
  }

  .l4-col1 {
    grid-area: l4-col1;
    display: flex;
    align-items: center;
    margin-left: 16px;
    border-right: 1px solid #596778;
  }
  .l4-col2 {
    grid-area: l4-col2;
    display: flex;
    align-items: center;
    margin-left: 16px;
    border-right: 1px solid #596778;
  }
  .l4-col3 {
    grid-area: l4-col3;
    display: flex;
    align-items: center;
    margin-left: 16px;
  }
`;

export const ProductContainer = styled.div`
  width: 70%;
  display: grid;
  margin-top: 15px;
  margin-left: 8px;
  grid-template-areas:
    "img  lin1"
    "img  lin2"
    "img  lin3"
    "img  lin4";
  grid-template-rows: 60px 1fr 30px;
  grid-template-columns: 167px 1fr;

  .img {
    grid-area: img;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #596778;
  }
`;
