import React from "react";
import { List } from "components/contexts/law-manager";
import { useLawsFiltersNew } from "hooks/useLaws";
import { useGridManagerContext } from "context/GridManagerContext";

export const ResultContainer = ({ paramsURL = {} }) => {
  const { filterFields } = useGridManagerContext();

  const cst = Object.keys(filterFields?.cst_e).filter(
    r => filterFields?.cst_e[r],
  );
  const params = useLawsFiltersNew({
    values: {
      ...paramsURL,
      cenarios: [...filterFields?.cenarios, ...(paramsURL?.cenarios || [])],
      ncms: filterFields.ncms,
      cests: filterFields.cests,
      ean: filterFields.prod_codigo_barras,
      mix_nome: filterFields.mix_nome,
      status: filterFields?.status,
      cst: cst?.length >= 1 ? cst : null,
      cnpj: filterFields.cnpj,
      cnpjs: filterFields.cnpjs,
    },
    gradeId: filterFields?.gradeId,
    lawId: filterFields?.lawId,
    hasCenary: false,
  });

  return (
    <List
      params={{
        ...params,
        hasProducts: 1,
      }}
      openNewWindow
    />
  );
};
