import React from "react";
import { Datatable } from "@mix/mixfiscal-designsystem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark, faSearchPlus } from "@fortawesome/free-solid-svg-icons";
import { useStores } from "useCases";
import { ItemStores } from "./Tooltip";
import * as ST from "./styles";
import * as S from "../../styles";

export const TableList = ({ dataCompanies, tableData, setTableData }) => {
  const { data: dataStores } = useStores({
    page: 1,
    limit: 9999,
  });

  const findCompanyName = companyId => {
    return dataCompanies?.companies.find(company => company._id === companyId)
      ?.company_name;
  };

  const findStoreName = storeId => {
    const foundStore = dataStores?.stores?.docs?.find(
      store => store._id === storeId,
    );
    return foundStore?.companyName || "Todas Filiais";
  };
  const deleteTableRow = selectedItemId =>
    setTableData(tableData.filter(row => row._id !== selectedItemId));

  const ItemTable = store => {
    return (
      <ST.StoreItem>
        <ItemStores
          findStoreName={findStoreName}
          tableData={tableData}
          setTableData={setTableData}
          store={store}
        />
      </ST.StoreItem>
    );
  };

  return (
    <S.CollumFormVerticalItem setMargin="15px 0px 0px 0px">
      <Datatable
        pagination={false}
        amountVariations={[10, 20, 30, 300, 500]}
        size="12px"
        columns={[
          { field: "action", title: "" },
          { field: "company", title: "Matriz" },
          { field: "store", title: "Lojas" },
        ]}
        rawData={tableData?.map(item => ({
          action: (
            <>
              <FontAwesomeIcon
                icon={faSearchPlus}
                style={{
                  fontSize: "13px",
                }}
                color="#565656"
              />
              <FontAwesomeIcon
                icon={faCircleXmark}
                style={{
                  fontSize: "13px",
                }}
                color="red"
                onClick={() => deleteTableRow(item._id)}
              />
            </>
          ),
          company: findCompanyName(item?.company),
          store: item?.store ? <ItemTable store={item?.store} /> : ``,
        }))}
      />
    </S.CollumFormVerticalItem>
  );
};
