import React, { useState, createContext, useContext } from "react";

const NotificationContext = createContext({});

const useNotifyPermission = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const context = useContext(NotificationContext);
  if (!context)
    throw new Error(
      "notifyPermission precisa ser utilizado dentro de um PermissionsProvider",
    );
  return context;
};

const NotificationsProvider = ({ children }) => {
  const [list, setList] = useState([]);
  const [listItemSelected, setNotificationsListSelected] = useState(null);
  const [isShowNotify, setIsShowNotify] = useState(false);

  return (
    <NotificationContext.Provider
      value={{
        isShowNotify,
        setIsShowNotify,
        list,
        setList,
        listItemSelected,
        setNotificationsListSelected,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export { useNotifyPermission, NotificationsProvider };
