/* eslint-disable react/no-danger */
import React, { useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import { useSocket } from "hooks/useSocket";
import { Format } from "utils";

import { useAssessorWhatsAppMessageRead } from "useCases";
import * as Sw from "./WhatsAppDados.styles";

export const WhatsAppMessage = ({ item }) => {
  const { socket } = useSocket();
  const refEl = useRef();
  const { ref, inView, entry } = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const { onSend } = useAssessorWhatsAppMessageRead(socket);

  useEffect(() => {
    if (inView && entry && !item.read && item._id) onSend({ id: item._id });
  }, [inView, entry, onSend, item]);

  useEffect(() => {
    if (refEl?.current) {
      ref(refEl.current);
    }
  }, [refEl, ref]);

  return (
    <Sw.ContainerRowItemContentWhatsAppMessagesItem
      ref={refEl}
      type={item.type}
      key={`whatsapp-message-${item._id}`}
    >
      <p dangerouslySetInnerHTML={{ __html: item.message }} />
      <span>{item?.createdAt ? Format(item.createdAt) : "enviando...."}</span>
    </Sw.ContainerRowItemContentWhatsAppMessagesItem>
  );
};

export const WhatsAppMessages = ({
  idCli,
  list = [],
  refContainer,
  ...props
}) => {
  // const { data } = useAssessorClientMessages({ id: idCli });
  const rows = list ?? [];

  useEffect(() => {
    if (refContainer?.current && rows?.length) {
      refContainer.current.scrollTop = refContainer.current.scrollHeight;
    }
  }, [refContainer, rows.length]);

  return rows.map((item, i) => (
    <WhatsAppMessage index={i} item={item} {...props} />
  ));
};
