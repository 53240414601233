export * from "./Types/index";

const transformListSelect = data =>
  Object.keys(data).map(item => ({
    value: item,
    label: data[item],
  }));

export const BLANK_SELECT = {
  label: "Selecione",
  value: "",
};

export const BLANK_SELECT_VALUE = {
  label: "Selecione",
  value: "Selecione",
};

export const BLANK_SELECT_LIST = [BLANK_SELECT];

export const ENTRIES_TYPES = {
  EI: "Entrada Industria",
  ED: "Entrada Distribuidor",
  ES: "Entrada Simples Nacional",
  BO: "Bonificação",
  TR: "Transferência",
};

export const YES_OR_NO = {
  SIM: "SIM",
  NÂO: "NÃO",
};

export const YES_OR_NO_LIST = transformListSelect(YES_OR_NO);

export const ENTRIES_TYPES_LIST = transformListSelect(ENTRIES_TYPES);

export const OUTS_TYPES = {
  SAC: "Saida Atacado Contribuinte",
  SAS: "Saida Atacado Simples Nacional",
  SVC: "Saida Varejo Contribuinte",
  SNC: "Saida não contribuinte",
  IND: "Industrialização",
  TRA: "Transferência",
};

export const OUTS_TYPES_LIST = transformListSelect(OUTS_TYPES);

export const COMPANIES_TYPES = {
  LR: "LR",
  LP: "LP",
  SN: "SN",
};

export const COMPANIES_TYPES_LABELS = {
  [COMPANIES_TYPES.LR]: "Lucro Real",
  [COMPANIES_TYPES.LP]: "Lucro Presumido",
  [COMPANIES_TYPES.SN]: "Simples Nacional",
};

export const COMPANIES_TYPES_LIST = transformListSelect(COMPANIES_TYPES_LABELS);

export const STATES = {
  AC: "Acre",
  AL: "Alagoas",
  AP: "Amapá",
  AM: "Amazonas",
  BA: "Bahia",
  CE: "Ceará",
  DF: "Distrito Federal",
  ES: "Espírito Santo",
  GO: "Goías",
  MA: "Maranhão",
  MT: "Mato Grosso",
  MS: "Mato Grosso do Sul",
  MG: "Minas Gerais",
  PA: "Pará",
  PB: "Paraíba",
  PR: "Paraná",
  PE: "Pernambuco",
  PI: "Piauí",
  RJ: "Rio de Janeiro",
  RN: "Rio Grande do Norte",
  RS: "Rio Grande do Sul",
  RO: "Rondônia",
  RR: "Roraíma",
  SC: "Santa Catarina",
  SP: "São Paulo",
  SE: "Sergipe",
  TO: "Tocantins",
};

export const STATES_LIST = transformListSelect(STATES);

export const NORMAS = {
  LEI: "Lei",
  LEI_COMPLEMENTAR: "Lei Complementar",
  DECRETO: "Decreto",
  ATO: "Ato",
  ATO_NORMATIVO: "Ato Normativo",
  PARECER: "Parecer",
  CONVENCAO: "Convenção",
  EMENDA: "Emenda",
  RESOLUCAO: "Resolução",
  INSTRUCAO_NORMATIVA: "Instrução Normativa",
  PORTARIA: "Portaria",
  PORTARIA_CONJUNTA: "Portaria Conjunta",
  PORTARIA_NORMATIVA: "Portaria Normativa",
  COMUNICADO: "Comunicado",
  ORIENTACAO: "Orientação",
  RESPOSTA_CONSULTA: "Resposta á consulta",
};

export const NORMAS_LIST = transformListSelect(NORMAS);

export const TRIBUTARY_SITUATION = {
  "cst_e.tributado": {
    label: "50 - Tributado",
    value: 50,
  },
  "cst_e.credito": {
    label: "70 - Sem Direito a Crédito",
    value: 70,
  },
  "cst_e.alq_zero": {
    label: "73 - Alíquota Zero",
    value: 73,
  },
  "cst_e.substituicao_tributaria": {
    label: "60 - Substituição Tributária",
    value: 60,
  },
  "cst_s.tributado": {
    label: "01 - Tributado",
    value: "01",
  },
  "cst_s.credito": {
    label: "Sem Direito a Crédito",
    value: "",
  },
  "cst_s.alq_zero": {
    label: "73 - Alíquota Zero",
    value: 73,
  },
  "cst_s.substituicao_tributaria": {
    label: "60 - Substituição Tributária",
    value: 60,
  },
  "csosn.imune_icms": {
    label: "300 - Imune de Icms",
    value: 300,
  },
  "csosn.nao_tributada_simples_nacional": {
    label: "400 - Não tributada pelo Simples Nacional",
    value: 400,
  },
  "csosn.icms_cobrado_anteriormento": {
    label: "500 - ICMS Cobrado Anteriormente por ST ou Por Antecipação",
    value: 500,
  },
};

export const TRIBUTARY_ALQ = {
  "alq_icms_e.18": {
    label: "18%",
    value: 18,
  },
  "alq_icms_e.12": {
    label: "12%",
    value: 12,
  },
  "alq_icms_e.06": {
    label: "06%",
    value: "06",
  },
  "alq_icms_e.0": {
    label: "0%",
    value: "0",
  },

  "alq_icms_s.18": {
    label: "18%",
    value: 18,
  },
  "alq_icms_s.12": {
    label: "12%",
    value: 12,
  },
  "alq_icms_s.06": {
    label: "06%",
    value: "06",
  },
  "alq_icms_s.0": {
    label: "0%",
    value: "0",
  },

  "alq_piscofins_e.18": {
    label: "18%",
    value: 18,
  },
  "alq_piscofins_e.12": {
    label: "12%",
    value: 12,
  },
  "alq_piscofins_e.06": {
    label: "06%",
    value: "06",
  },
  "alq_piscofins_e.0": {
    label: "0%",
    value: "0",
  },

  "alq_piscofins_s.18": {
    label: "18%",
    value: 18,
  },
  "alq_piscofins_s.12": {
    label: "12%",
    value: 12,
  },
  "alq_piscofins_s.06": {
    label: "06%",
    value: "06",
  },
  "alq_piscofins_s.0": {
    label: "0%",
    value: "0",
  },

  "alq_reducao_base_calc_e.18": {
    label: "18%",
    value: 18,
  },
  "alq_reducao_base_calc_e.12": {
    label: "12%",
    value: 12,
  },
  "alq_reducao_base_calc_e.06": {
    label: "06%",
    value: "06",
  },
  "alq_reducao_base_calc_e.0": {
    label: "0%",
    value: "0",
  },

  "alq_reducao_base_calc_s.18": {
    label: "18%",
    value: 18,
  },
  "alq_reducao_base_calc_s.12": {
    label: "12%",
    value: 12,
  },
  "alq_reducao_base_calc_s.06": {
    label: "06%",
    value: "06",
  },
  "alq_reducao_base_calc_s.0": {
    label: "0%",
    value: "0",
  },

  "alq_carga_tributaria_e.18": {
    label: "18%",
    value: 18,
  },
  "alq_carga_tributaria_e.12": {
    label: "12%",
    value: 12,
  },
  "alq_carga_tributaria_e.06": {
    label: "06%",
    value: "06",
  },
  "alq_carga_tributaria_e.0": {
    label: "0%",
    value: "0",
  },

  "alq_carga_tributaria_s.18": {
    label: "18%",
    value: 18,
  },
  "alq_carga_tributaria_s.12": {
    label: "12%",
    value: 12,
  },
  "alq_carga_tributaria_s.06": {
    label: "06%",
    value: "06",
  },
  "alq_carga_tributaria_s.0": {
    label: "0%",
    value: "0",
  },
};

export const ACTIVITIES = {
  Atacado: "Atacado",
  Supermercado: "Supermercado",
  Industria: "Indústria",
};

export const ACTIVITIES_LIST = transformListSelect(ACTIVITIES);
