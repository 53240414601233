import React, { useState, memo, useRef } from "react";
import { Handle, useStoreState } from "react-flow-renderer";

import { useFlow } from "../../../../context/FlowContext";

import { Container } from "./styles";

function Input({ id, data }) {
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(data?.value ?? "");

  const { handleUpdateNodeData } = useFlow();
  const { label, parentId } = data;
  const state = useRef(null);

  const Observer = memo(() => {
    const obsState = useStoreState(store => store);
    state.current = obsState;
    return null;
  });

  const handleEdit = () => {
    if (isEditing) {
      handleUpdateNodeData({
        id,
        parentId,
        data: {
          ...data,
          value: inputValue,
        },
        updatedNodesPositions: state.current,
      });
    }
    setIsEditing(!isEditing);
  };

  return (
    <>
      <Observer />
      <Container>
        <p>{label}</p>
        <input
          type="text"
          value={inputValue}
          onChange={e => setInputValue(e.target.value)}
          disabled={!isEditing}
        />
        <button type="button" onClick={() => handleEdit()}>
          {isEditing ? "Salvar" : "Editar"}
        </button>
      </Container>
      <Handle
        id="head"
        type="target"
        position="top"
        style={{
          width: "10px",
          height: "10px",
          background: "#7b867e",
          bottom: "-5px",
        }}
      />
      <Handle
        id="tail"
        type="source"
        position="bottom"
        style={{
          width: "10px",
          height: "10px",
          background: "#0e7c34",
          bottom: "-5px",
        }}
      />
    </>
  );
}

export default Input;
