import styled, { css } from "styled-components";

const badgeModifiers = {
  default: () => css`
    background-color: #5a8dee;
  `,
  danger: () => css`
    background-color: red;
  `,
  warning: () => css`
    background-color: #ffeeba;
    color: #856404;
  `,
  success: () => css`
    background-color: #256925;
  `,
};

export const Badge = styled.div`
  ${({ type }) => css`
    border-radius: 30px;
    padding: 0px 10px;
    color: #fff;
    font-weight: 400;
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;

    ${!type && badgeModifiers.default()}
    ${type === "danger" && badgeModifiers.danger()}
    ${type === "warning" && badgeModifiers.warning()}
    ${type === "success" && badgeModifiers.success()}
  `}
`;

export const BadgeNotification = styled.span`
  ${({ type }) => css`
    position: absolute;
    top: -5px;
    height: 20px;
    min-width: 20px;
    text-align: center;
    color: white;
    font-size: 11px;
    border-radius: 100px;
    text-align: center;
    right: -4px;
    ${!type && badgeModifiers.default()}
    ${type === "danger" && badgeModifiers.danger()}
    ${type === "warning" && badgeModifiers.warning()}
    ${type === "success" && badgeModifiers.success()}
  `}
`;
