import React from "react";
import { Breadcrumbs } from "@mix/mixfiscal-designsystem";
import { useHistory } from "react-router-dom";

export const Breadcrumb = ({ items = [] }) => {
  const { push } = useHistory();

  const handleClick = value => {
    const item = items.find(row => row.label === value);
    const last = items[items.length - 1];
    if (item && item !== last) push(item.url);
  };

  return (
    <Breadcrumbs
      items={items.map(row => row.label)}
      selected={items[items.length - 1].label}
      handleClick={handleClick}
    />
  );
};
