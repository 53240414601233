import styled, { css } from "styled-components";

const columnFormItemModifiers = {
  setMargin: item => css`
    margin: ${item} !important;
  `,
  setAlign: align => css`
    justify-content: ${align};
  `,
  isPaddingInput: item => css`
    input {
      padding: ${item} !important;
    }
  `,
};

export const CollumFormSearch = styled.div`
  padding: 30px 0 0 0;
`;

export const ColumnFormItem = styled.div`
  ${({ setMargin, isPaddingInput, align }) => css`
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;

    ${align && columnFormItemModifiers.setAlign(align)}
    ${setMargin && columnFormItemModifiers.setMargin(setMargin)}
    ${isPaddingInput && columnFormItemModifiers.isPaddingInput(isPaddingInput)}
  `}
`;

export const CollumFormVerticalItem = styled.div`
  width: 100%;
  ${({ setMargin }) => css`
    ${setMargin && columnFormItemModifiers.setMargin(setMargin)}
  `}
`;

export const CollumForm = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 8px 5px 8px;

  ${ColumnFormItem} {
    &:not(:last-of-type) {
      margin-bottom: 10px;
    }
  }
`;

export const CollumFormCheckBox = styled.div`
  display: flex;
  flex-direction: column;

  section {
    width: 100%;
    margin-bottom: 20px;
    flex: 0;

    p {
      font-size: 14px;
      color: #596778;
      white-space: nowrap;
    }

    & > div {
      display: flex;
      align-items: center;
      gap: 12px;
    }

    .checkBox {
      display: flex;
      align-items: center;
      gap: 15px;
    }
  }

  .divider {
    width: 1px;
    align-self: stretch;
    background-color: #0000002e;
    margin: 0 21px;
  }
`;

export const SectionCheckbox = styled.section`
  width: 100%;
  margin-bottom: 20px;
  flex: 0;
`;

export const InputForm = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Label = styled.label`
  font-size: 12px;
`;

export const ButtonFuctionInput = styled.button`
  background-color: transparent;
  border: none;
  color: #848484;
`;

export const SelectStyleStore = styled.div`
  width: 100%;
  .select-wrapper {
    width: 100%;
    display: flex;
    .select-width-80 {
      width: 95%;
    }
    button {
      margin-left: 20px;
    }
  }
`;

export const SelectWith = styled.div`
  width: ${props => (props.width ? props.width : `100%`)};
`;

export const SwitchTexWrapper = styled.div`
  display: flex;
  flex-direction: column;

  p {
    line-height: 10px;
  }

  span {
    font-size: 10px;
    color: #596778;
  }
  ${({ setMargin }) => css`
    ${setMargin && columnFormItemModifiers.setMargin(setMargin)}
  `}
`;

export const SwitchContainer = styled.div`
  display: flex;

  & {
    margin: 0;
  }

  p {
    color: #596778;
    font-size: 14px;
  }

  ${({ setMargin }) => css`
    ${setMargin && columnFormItemModifiers.setMargin(setMargin)}
  `}
`;
export const SwitchContainerCheckBox = styled.div`
  margin: 0 10px 0 0;
`;

export const AddButton = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #adc86a;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 1px 6px #00000033;
  margin-right: unset;
  margin-top: 0;
  margin-left: 10px;
`;

export const ActionFlex = styled.div`
  display: flex;
  align-item: left;
`;

export const ActionFlexItem = styled.div`
  margin: 0 5px 0 5px;
`;
