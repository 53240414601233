import React from "react";
import Select from "react-select";
import { TextField } from "components/form";
import { useStores, useCompanies } from "useCases";

import * as S from "../styles";

export const FormInvitUsers = ({
  control,
  parseGroups,
  setValue,
  companyId,
  setCompanyValue,
}) => {
  const { isLoading: isLoadingCompanies, data: dataCompanies } = useCompanies();
  const { isLoading: isLoadingStores, data: dataStores } = useStores({
    companyId,
    page: 1,
    limit: 100,
  });

  console.log(dataStores);
  return (
    <>
      <S.ColumnFormItem>
        <TextField
          label="Email"
          name="email"
          type="email"
          placeholder="exemplo: contato@mixfiscal.com.br"
          control={control}
          onChange={e => {
            const { value } = e.target;
            setValue("email", value);
          }}
        />
      </S.ColumnFormItem>
      <S.ColumnFormItem isPaddingInput="8px">
        <TextField
          label="Nome"
          placeholder="Nome"
          name="name"
          control={control}
          onChange={e => {
            const { value } = e.target;
            setValue("name", value);
          }}
        />
        <TextField
          label="Sobrenome"
          placeholder="Sobrenome"
          name="surname"
          control={control}
        />
      </S.ColumnFormItem>
      <S.ColumnFormItem>
        <S.InputForm>
          <label>Matriz</label>
          <Select
            maxMenuHeight={175}
            control={control}
            noOptionsMessage={() => "Nenhuma Matriz encontrada"}
            isLoading={isLoadingCompanies}
            name="company"
            options={dataCompanies?.companies?.map(item => ({
              value: item._id,
              label: item.company_name,
              cnpj: item.cnpj,
            }))}
            isSearching
            placeholder="Selecione Matriz"
            onChange={e => {
              const { value } = e;
              setCompanyValue(value);
              setValue(`company`, value);
            }}
          />
        </S.InputForm>
        <S.InputForm>
          <label>Filial</label>
          <Select
            maxMenuHeight={175}
            control={control}
            noOptionsMessage={() => "Nenhuma loja encontrada"}
            isLoading={isLoadingStores}
            name="storeId"
            options={dataStores?.stores.docs?.map(item => ({
              value: item._id,
              label: item.companyName,
            }))}
            isSearching
            placeholder="Selecione Matriz"
            onChange={e => {
              const { value } = e;
              setValue(`storeId`, value);
            }}
          />
        </S.InputForm>
        {/* <S.InputForm>
          <SelectStore
            width="100%"
            companyId={companyValue}
            onChange={setStoreValue}
          />
        </S.InputForm> */}
        <S.InputForm>
          <label>Grupo de Acesso</label>
          <Select
            name="accessGroupId"
            labelType="top"
            options={parseGroups}
            control={control}
            placeholder="Selecione"
            styles={{
              control: styles => ({
                ...styles,
                width: "355px",
                border: "1px solid #596778",
              }),
            }}
            onChange={e => {
              const { value } = e;
              console.log(e);
              setValue(`accessGroupId`, value);
            }}
          />
        </S.InputForm>
      </S.ColumnFormItem>
    </>
  );
};
