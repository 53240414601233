import styled from "styled-components";

export const FieldRow = styled.div`
  width: 50% !important;
`;

export const FieldSearch = styled(FieldRow)`
  & > div > div {
    margin: 0;
  }
`;

export const Row = styled.div`
  display: flex;
`;

export const FieldContainer = styled.div`
  display: block;
`;
