import React from "react";
import { TextField } from "components/form";
import Switch from "components/UI/Switch";

import * as S from "../styles";

export const TimeAndToogles = ({ data, control, hasOk, hasOk2 }) => {
  return (
    <>
      <S.ColumnFormItem>
        <TextField
          label="Horário de Entrada"
          value={data?.officeHour?.entryTime}
          placeholder="Horário de Entrada"
          name="enteringTime"
          type="time"
          control={control}
        />
        <TextField
          label="Horário de Saída"
          value={data?.officeHour?.departureTime}
          placeholder="Horário de Saída"
          name="leavingTime"
          type="time"
          control={control}
        />
      </S.ColumnFormItem>
      <S.CollumFormVerticalItem setMargin="15px 0 0 0">
        <S.SwitchContainer>
          {hasOk && (
            <Switch
              control={control}
              name="hireService"
              initialValue={data?.contractService}
            />
          )}
          {hasOk2 && <Switch control={control} name="hireService" />}
          <S.SwitchTexWrapper>
            <p>Autorização para contratar serviços</p>
            <span>(Pode haver cobranças)</span>
          </S.SwitchTexWrapper>
        </S.SwitchContainer>
        <S.SwitchContainer>
          {hasOk && (
            <Switch
              control={control}
              name="officeHour"
              initialValue={data?.officeHour?.afterHoursAccess}
            />
          )}
          {hasOk2 && <Switch control={control} name="officeHour" />}
          <p>Permitir login após horário de trabalho</p>
        </S.SwitchContainer>
        <S.SwitchContainer>
          {hasOk && (
            <Switch
              control={control}
              name="inviteThroughEmail"
              initialValue={data?.acceptInvite}
            />
          )}
          {hasOk2 && <Switch control={control} name="inviteThroughEmail" />}
          <p>Enviar convite por e-mail</p>
        </S.SwitchContainer>
      </S.CollumFormVerticalItem>
    </>
  );
};
