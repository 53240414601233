import React, { useState, useEffect, useRef } from "react";

import { Overlay } from "../Overlay";
import { Container, PopOverContainer } from "./styles";

export const Dropdown = ({
  children,
  dropContent,
  fitToParentWidth = true,
  zIndex = 2,
  className,
  onlyOutClose,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [parentPosition, setParentPosition] = useState({});
  const parentRef = useRef(null);
  useEffect(() => {
    if (parentRef.current) {
      setParentPosition({
        top: parentRef.current.getBoundingClientRect().bottom,
        left: parentRef.current.getBoundingClientRect().left,
        width: fitToParentWidth
          ? parentRef.current.getBoundingClientRect().width
          : null,
      });
    }
  }, [isOpen, fitToParentWidth]);

  return (
    <>
      {isOpen && (
        <Overlay handleClick={() => setIsOpen(false)} bgColor="transparent" />
      )}
      <Container
        className={className}
        onClick={() => setIsOpen(onlyOutClose ? true : !isOpen)}
        ref={parentRef}
      >
        {children}

        {isOpen && dropContent && (
          <PopOverContainer parentPosition={parentPosition} zIndex={zIndex}>
            {dropContent}
          </PopOverContainer>
        )}
      </Container>
    </>
  );
};
