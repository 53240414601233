import styled from "styled-components";

export const TitleCard = styled.div`
  width: 100%;
  padding: 10px 20px;
  border-bottom: 1px solid #c1c1c1;
  background: #fff;
  position: relative;

  h1,
  h2 {
    font-weight: bold;
  }

  > h1 {
    color: #1b66b0;
  }

  > h3 {
    color: #5d6b7b;
  }

  .image-businnes-man {
    position: absolute;
    top: 13px;
    right: 15px;
  }
`;

export const RowContentCard = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 2px 0;

  > .title-description {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 0;
    width: 40px;
    height: auto;
    color: #fff;
    font-weight: bold;
    text-orientation: mixed;
    writing-mode: vertical-rl;
    background: ${props => props.color};
    font-size: 14px;
  }
`;

export const SectionCards = styled.div`
  border: 1px solid #c1c1c1;
  border-radius: 5px;
  background: #fff;
  height: 100%;

  header {
    background: ${props => props.colorHeader};
  }
`;

export const ContainerDaedline = styled.div`
  width: 90%;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background: #f2f4f4;

  span {
    display: flex;
    justify-content: space-between;

    label {
      font-weight: bold;
      padding-right: 5px;
    }
  }
`;

export const ContainerTeam = styled.div`
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  background: #f2f4f4;
`;

export const ContainerImage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  gap: 3px;

  .content-image {
    img {
      width: 45px;
      border-radius: 5px;
    }
  }
`;

export const FooterCard = styled.div`
  padding: 10px;
  height: 50px;
  background: #bedeec;
  font-weight: bold;

  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;

export const ContentTextDescription = styled.div`
  width: 90%;
  padding: ${props => props.padding};
  font-weight: ${props => props.fontWeight ?? "none"};
  background: #f2f4f4;

  label {
    display: flex;
    gap: 10px;
    padding: 5px 0;
    align-items: center;
  }

  button {
    background: transparent;
  }
`;
