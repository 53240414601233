import React, { useState } from "react";
import { Select, InputContainer } from "components/form";
import { ButtonAdd } from "components/structure";
import { useCestsSegmentsItems, useCestsItems } from "useCases/cest";
import { CestSegmentInput } from "components/contexts/cests/CestSegmentInput";
import * as S from "./CestInputs.styles";

const ellipsi = value =>
  String(value).length > 50 ? `${String(value).substring(0, 50)}...` : value;

const getCestFirstNumbers = value => value.replace(/\./g, "").substr(0, 5);

export const CestInputs = ({ handleAdd, params = {} }) => {
  const [fields, setFields] = useState({});
  const { data: dataSegmentsItens } = useCestsSegmentsItems(
    fields?.segmento?.value,
    params,
  );

  const { data: dataItems } = useCestsItems({
    cest: getCestFirstNumbers(fields?.item?.value ?? ""),
    ...(params ?? {}),
  });

  const onChangeSegmento = value => {
    setFields(state => ({ ...state, segmento: value }));
  };

  const onChange = (name, value) =>
    setFields(state => ({ ...state, [name]: value }));

  const handleLocalNew = () => {
    if (handleAdd) handleAdd(fields);
    setFields({
      segmento: null,
      item: null,
      especificacao: null,
    });
  };

  return (
    <S.Container>
      <InputContainer>
        <CestSegmentInput
          params={params}
          onChange={onChangeSegmento}
          value={fields.segmento}
        />
      </InputContainer>
      <InputContainer>
        <Select
          name="item"
          label="Item"
          labelType="top"
          value={fields.item}
          onChange={e => onChange("item", e)}
          data={dataSegmentsItens?.data?.map(especification => ({
            value: especification.cest,
            label: ellipsi(
              `${especification.cest} - ${especification.description}`,
            ),
          }))}
        />
      </InputContainer>
      <InputContainer>
        <Select
          name="especificacao"
          label="Especificações"
          value={fields.especificacao}
          labelType="top"
          onChange={e => onChange("especificacao", e)}
          data={dataItems?.data?.map(especification => ({
            value: especification.cest,
            label: ellipsi(
              `${especification.cest} - ${especification.description}`,
            ),
          }))}
        />
      </InputContainer>
      {handleAdd && (
        <S.AddPlus>
          <ButtonAdd type="button" onClick={handleLocalNew} />
        </S.AddPlus>
      )}
    </S.Container>
  );
};
