/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState, useRef } from "react";
import { useHistory, Link } from "react-router-dom";
import { Icon, Dropdown } from "@mix/mixfiscal-designsystem";
import { MdArrowDropDown } from "react-icons/md";
import { useUser } from "useCases/user";
import { useAuth } from "context/AuthContext";
import { usePermissions } from "context/PermissionsContext";
import { getSessionTokenBase } from "utils/session";
import { useACL } from "context";
import { SearchBox } from "../../../SearchBox";
import { TooltipCertifield } from "./TooltipCertified";
import { TooltipNotify } from "./Notification";
import Dialog from "../../../UI/Dialog";
import ProductConsult from "../../../../pages/ProductsConsult";
import LogoImg from "../../../../assets/logo.png";

import * as S from "./styles";

function Header({
  app,
  setBusinessIntelligence,
  isUploadingDialogOpen,
  setIsUploadingDialogOpen,
}) {
  const { data: dataUser } = useUser();
  const { hasShow } = useACL("modules-fiscal-comercial");

  const { selectedStore, selectedCompanySearch, selectedCompany } =
    usePermissions();
  const { signOut, setCompaniesSessionToken, user: userAuth } = useAuth();
  const history = useHistory();
  const [isOpenProductConsult, setIsOpenProductConsult] = useState(false);

  const [productSelected, setProductSelected] = useState([]);
  const hiddenFileInput = useRef(null);
  const [fileSelected, setFileSelected] = useState({
    isFile: false,
    filename: "",
    filesize: "",
  });

  const handleChangeUpload = event => {
    const file = event.target.files[0];
    setFileSelected({
      isFile: true,
      filename: file.name,
      filesize: file.size,
    });
  };

  const dropDownItens = () => {
    return (
      <S.DropDownContainer>
        <li role="button" key="myaccount">
          <Link to="/perfil">Minha Conta</Link>
        </li>
        <li role="button" key="config">
          <Link to="/usuarios-e-permissoes">Configurações</Link>
        </li>
        <li role="button" key="exit" onClick={signOut}>
          Sair
        </li>
      </S.DropDownContainer>
    );
  };

  useEffect(() => {
    if (
      getSessionTokenBase() !== dataUser?.tokenBase &&
      dataUser?.tokenBase !== undefined
    ) {
      if (
        selectedStore === null &&
        selectedCompany === null &&
        selectedCompanySearch === null
      ) {
        setCompaniesSessionToken(dataUser?.token, dataUser?.tokenBase);
      }
      // if (getSessionCompanies() !== dataUser?.token) {
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataUser?.token]);

  const managerActionsSearch = value => {
    const itemProduct = value;

    setProductSelected(itemProduct);

    setIsOpenProductConsult(true);
  };

  return (
    <>
      <Dialog
        isOpen={isOpenProductConsult}
        handleClose={() => setIsOpenProductConsult(false)}
        title={productSelected?.pcli_nome || productSelected?.prod_nome}
      >
        <ProductConsult dataProduct={productSelected} />
      </Dialog>

      <S.Container>
        <S.Logo
          src={LogoImg}
          onClick={() => history.push("/")}
          style={{ cursor: "pointer" }}
        />
        <S.SearchBox>
          <SearchBox
            placeholder="encontre um produto por descrição, ean ou código interno…"
            onClick={managerActionsSearch}
          />
        </S.SearchBox>
        <S.ActionsContainer>
          {hasShow && (
            <S.ModulesFiscInt
              onClick={() => {
                setBusinessIntelligence(!app.appBusinessIntelligence);
              }}
              active={app.appBusinessIntelligence}
            >
              {!app.appBusinessIntelligence && (
                <Icon size={25} name="gerenciarproduto" />
              )}
              {app.appBusinessIntelligence && (
                <Icon size={25} name="gerenciamentoarquivo" />
              )}
              <span>
                {!app.appBusinessIntelligence
                  ? "Inteligência Comercial "
                  : "Inteligência Fiscal"}
              </span>
            </S.ModulesFiscInt>
          )}
          <S.IconWrapper
            role="button"
            onClick={() => {
              setIsUploadingDialogOpen(!isUploadingDialogOpen);
            }}
          >
            {!fileSelected.isFile && (
              <Icon size={23} name="importararquivos" color="#adc86a" />
            )}
            {fileSelected.isFile && <span>{fileSelected.filename}</span>}
          </S.IconWrapper>
          <S.InputUpload
            onChange={handleChangeUpload}
            type="file"
            ref={hiddenFileInput}
          />
          <TooltipCertifield />
          <TooltipNotify />

          <div>
            <Link to="/perfil">
              <img
                src="https://www.w3schools.com/howto/img_avatar.png"
                alt=""
              />
            </Link>
            <div
              className="credentials"
              style={{ cursor: "pointer" }}
              onClick={() => {
                document.querySelector(".dropdown-style").click();
              }}
            >
              <p>{userAuth?.name ?? dataUser?.user?.name}</p>
              <p />
            </div>
            <Dropdown
              className="dropdown-style"
              dropContent={dropDownItens()}
              fitToParentWidth={false}
            >
              <span>
                <MdArrowDropDown size={30} color="#bababa" />
              </span>
            </Dropdown>
          </div>
        </S.ActionsContainer>
      </S.Container>
    </>
  );
}

export default Header;
