import React, { useEffect, useState } from "react";
import MidCard from "components/contexts/dashboard/mid-card";
import { format, subMonths, subYears } from "date-fns";
import { getTagTypeDashoard, getDatesMonths } from "utils/context/dashboard";
import { useAnalyticsPurchases } from "useCases/dashboard";
import { ResponsiveBar } from "@nivo/bar";
import { useAuth } from "context/AuthContext";
import * as NumberContext from "utils/context/number";
import { LoadPlaceholder, LoadPrefix } from "components/structure/Load";

import { TooltipValues } from "../TooltipValues";
import {
  MidCardContainer,
  MidCardBlocOne,
  MidCardBlocTwo,
  LegendContainerPurchase,
} from "../styles";

const legend = [
  { title: "período atual", className: "square1" },
  { title: "mês anterior", className: "square2" },
  { title: "ano anterior", className: "square3" },
];

const BLANK_TEXT = "Sem dados para o período";

const theme = {
  labels: { text: { fontSize: 11 } },
};

const FormatDate = value => format(value, "ddMMyyyy");
function DashPurchase({ monthDateStart, monthDateEnd, fileType }) {
  const { tokenCompanies } = useAuth();

  const [blankValue, setBlankValue] = useState(200);

  const [comprasDateStart, setComprasDateStart] = useState(monthDateStart);
  const [comprasDateEnd, setComprasDateEnd] = useState(monthDateEnd);
  const [dataCompras, setDataCompras] = useState([]);
  const [tag, setTag] = useState(fileType ?? "XML");
  const [linearValues, setLinearValues] = useState([]);
  const [hasDates, setHasDates] = useState(false);
  const [dataComprasOrig, setDataComprasOrig] = useState({
    vbruto: 0,
    vbrutoperiodo: 0,
    vbrutoano: 0,
    percentDiffFromLastPeriod: 0,
    percentDiffFromLastYearPeriod: 0,
  });

  const {
    isLoading: isLoadingCurrent,
    data: dataPurchasesCurrent,
    fetch: fetchPurchasesCurrent,
  } = useAnalyticsPurchases();

  const {
    isLoading: isLoadingPurchasesPastMonth,
    data: dataPurchasesPastMonth,
    fetch: fetchPurchasesPastMonth,
  } = useAnalyticsPurchases();

  const {
    isLoading: isLoadingPurchasesPastYear,
    data: dataPurchasesPastYear,
    fetch: fetchPurchasesPastYear,
  } = useAnalyticsPurchases();

  const tagType = getTagTypeDashoard(tag);

  const setData = ({ current, pastMonth, pastYear, dtStart, dtEnd }) => {
    try {
      const getLabelPeriodo = id => {
        let periodo;
        if (id === "vbruto") {
          if (!current) {
            return BLANK_TEXT;
          }
          periodo = `${format(new Date(dtStart), "dd")} a ${format(
            new Date(dtEnd !== null ? dtEnd : dtStart),
            "dd/MM/yyyy",
          )}`;
        }

        if (id === "vbrutoperiodo") {
          if (!pastMonth) {
            return BLANK_TEXT;
          }
          periodo = `${format(new Date(dtStart), "dd")} a ${format(
            new Date(
              dtEnd !== null
                ? subMonths(new Date(dtEnd), 1)
                : subMonths(new Date(dtStart), 1),
            ),
            "dd/MM/yyyy",
          )}`;
        }
        if (id === "vbrutoano") {
          if (!pastYear) {
            return BLANK_TEXT;
          }
          periodo = `${format(new Date(dtStart), "dd")} a ${format(
            new Date(
              dtEnd !== null
                ? subYears(new Date(dtEnd), 1)
                : subYears(new Date(dtStart), 1),
            ),
            "dd/MM/yyyy",
          )}`;
        }

        return periodo;
      };
      const percentualDiff = (a, b) => {
        if (b > a) return -1 * (100 * Math.abs((a - b) / ((a + b) / 2)));
        return 100 * Math.abs((a - b) / ((a + b) / 2));
      };

      const percentDiffFromLastPeriod =
        current !== 0 ? percentualDiff(current, pastMonth) : 0;
      const percentDiffFromLastYearPeriod =
        current !== 0 ? percentualDiff(current, pastYear) : 0;

      const dComprasOrig = {
        vbruto: current,
        vbrutoperiodo: pastMonth,
        vbrutoano: pastYear,
        percentDiffFromLastPeriod,
        percentDiffFromLastYearPeriod,
      };

      const currentBlankValue =
        [current / 1000, pastMonth / 1000, pastYear / 1000].sort(
          (a, b) => b - a,
        )[0] - 0.02;

      setBlankValue(currentBlankValue);

      const dCompras = [
        {
          vbruto: current === 0 ? currentBlankValue : current / 1000,
          vbrutoperiodo: pastMonth === 0 ? currentBlankValue : pastMonth / 1000,
          vbrutoano: pastYear === 0 ? currentBlankValue : pastYear / 1000,
          dateLabelOne: getLabelPeriodo("vbruto"),
          dateLabelTwo: getLabelPeriodo("vbrutoperiodo"),
          dateLabelThree: getLabelPeriodo("vbrutoano"),
        },
      ];

      const lValue = Math.ceil(
        Math.max(current / 1000, pastMonth / 1000, pastYear / 1000),
      );

      let aLinear = [];

      if (lValue > 0 && lValue <= 600) {
        aLinear = [0, 120, 240, 360, 480, 600];
      }
      if (lValue > 600 && lValue <= 1000) {
        aLinear = [0, 600, 800, 900, 1000];
      }
      if (lValue > 1000 && lValue <= 3000) {
        aLinear = [0, 500, 1000, 1500, 2000, 3000];
      }
      if (lValue > 3000 && lValue <= 5000) {
        aLinear = [0, 1500, 3000, 4000, 5000];
      }
      if (lValue > 5000 && lValue <= 10000) {
        aLinear = [0, 2500, 5500, 8500, 10000];
      }
      if (lValue > 10000 && lValue <= 20000) {
        aLinear = [0, 5000, 10000, 150000, 20000];
      }

      setLinearValues(aLinear);
      setDataComprasOrig(dComprasOrig);
      setDataCompras(dCompras);
    } catch (e) {
      console.log(e);
    }
  };

  const handleClick = (start, end) => {
    const [startDate, endDate] = getDatesMonths(start, end);
    console.log({ start, end });
    setComprasDateStart(startDate);
    setComprasDateEnd(endDate);
  };

  const handleClickType = item => {
    setTag(item);
  };

  useEffect(() => {
    const [dateStart, dateEnd] = getDatesMonths(
      comprasDateStart,
      comprasDateEnd,
    );
    setData({
      current: dataPurchasesCurrent?.data?.data ?? 0,
      pastMonth: dataPurchasesPastMonth?.data?.data ?? 0,
      pastYear: dataPurchasesPastYear?.data?.data ?? 0,
      dtStart: dateStart,
      dtEnd: dateEnd,
    });
  }, [
    dataPurchasesCurrent?.data,
    dataPurchasesPastMonth?.data,
    dataPurchasesPastYear?.data,
    comprasDateStart,
    comprasDateEnd,
  ]);

  useEffect(() => {
    if (hasDates && comprasDateStart && comprasDateEnd) {
      const [dateStart, dateEnd] = getDatesMonths(
        comprasDateStart,
        comprasDateEnd,
      );
      fetchPurchasesCurrent({
        dt_ini: FormatDate(dateStart),
        dt_fim: FormatDate(dateEnd),
        tipo: tagType,
        only: true,
      });
      fetchPurchasesPastMonth({
        dt_ini: FormatDate(subMonths(dateStart, 1)),
        dt_fim: FormatDate(subMonths(dateEnd, 1)),
        tipo: tagType,
        only: true,
      });

      fetchPurchasesPastYear({
        dt_ini: FormatDate(subYears(dateStart, 1)),
        dt_fim: FormatDate(subYears(dateEnd, 1)),
        tipo: tagType,
        only: true,
      });
    }
  }, [
    comprasDateStart,
    comprasDateEnd,
    hasDates,
    tag,
    fetchPurchasesCurrent,
    fetchPurchasesPastMonth,
    fetchPurchasesPastYear,
    tagType,
    tokenCompanies,
  ]);

  useEffect(() => {
    const [comprasDateStartValue, monthDateEndValue] = getDatesMonths(
      monthDateStart,
      monthDateEnd,
    );
    setComprasDateStart(comprasDateStartValue);
    setComprasDateEnd(monthDateEndValue);
    if (monthDateStart && monthDateEnd) setHasDates(true);
  }, [monthDateStart, monthDateEnd]);

  return (
    <>
      <MidCard
        title="Compras"
        subtitle="Período"
        total=""
        tag={fileType}
        typeList="month"
        addMonthsLength={0}
        tagsList={[
          "XML",
          {
            dtStart: comprasDateStart,
            dtEnd: comprasDateEnd,
          },
        ]}
        handleClick={(start, end) => handleClick(start, end, "venda")}
        handleClickType={item => handleClickType(item, "venda")}
        cssClass="card-custom"
      >
        <MidCardContainer>
          <MidCardBlocOne>
            <LoadPlaceholder
              show={
                isLoadingCurrent ||
                isLoadingPurchasesPastMonth ||
                isLoadingPurchasesPastYear
              }
              showNoData={
                !(
                  isLoadingCurrent ||
                  isLoadingPurchasesPastMonth ||
                  isLoadingPurchasesPastYear
                ) && linearValues.length === 0
              }
            >
              {linearValues.length > 1 && (
                <ResponsiveBar
                  theme={theme}
                  data={dataCompras}
                  keys={["vbruto", "vbrutoperiodo", "vbrutoano"]}
                  indexBy=""
                  enableGridY={false}
                  margin={{ top: 1, right: 20, bottom: 40, left: 25 }}
                  padding={0.05}
                  labelFormat={d => {
                    return <tspan x={d === BLANK_TEXT ? 70 : 48}>{d}</tspan>;
                  }}
                  groupMode="grouped"
                  layout="horizontal"
                  valueScale={{ type: "linear" }}
                  indexScale={{ type: "band", round: false }}
                  valueFormat={{ format: " >-$", enabled: true }}
                  colors={e => {
                    const colors = {
                      vbruto: "#EDA366",
                      vbrutoperiodo: "#cce39f",
                      vbrutoano: "#10b39d",
                    };

                    if (e.value === blankValue) {
                      return "rgb(255 255 255 / 24%)";
                    }

                    return colors[e.id];
                  }}
                  borderColor={{
                    from: "color",
                    modifiers: [["darker", 1.6]],
                  }}
                  axisTop={0}
                  axisRight={0}
                  axisBottom={{
                    tickSize: 5,
                    tickPadding: 0,
                    tickRotation: 0,
                    legend: "Valor (k)",
                    legendOffset: 30,
                    legendPosition: "middle",
                    tickValues: linearValues,
                  }}
                  axisLeft={{
                    tickSize: 0,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: "Período",
                    legendPosition: "middle",
                    legendOffset: -20,
                  }}
                  labelSkipWidth={10}
                  labelSkipHeight={12}
                  label={e =>
                    e.id === "vbruto"
                      ? e.data.dateLabelOne
                      : e.id === "vbrutoperiodo"
                      ? e.data.dateLabelTwo
                      : e.data.dateLabelThree
                  }
                  labelTextColor={e => {
                    if (e.data.value === blankValue) {
                      return "#596778";
                    }
                    return "#fff";
                  }}
                  legends={[]}
                  tooltip={e => {
                    const title =
                      e.id === "vbruto"
                        ? "Valor Bruto"
                        : e.id === "vbrutoperiodo"
                        ? "Valor Bruto/Período"
                        : "Valor Bruto/Ano";
                    const value =
                      e.id === "vbruto"
                        ? e.data.vbruto
                        : e.id === "vbrutoperiodo"
                        ? e.data.vbrutoperiodo
                        : e.data.vbrutoano;
                    return (
                      <div
                        style={{
                          fontSize: "14px",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <span
                          style={{
                            width: "10px",
                            height: "10px",
                            backgroundColor: `${e.color}`,
                            marginRight: "5px",
                          }}
                        />
                        <p>
                          {`${title}: ${
                            value === blankValue
                              ? BLANK_TEXT
                              : (value * 1000).toLocaleString("pt-br", {
                                  style: "currency",
                                  currency: "BRL",
                                })
                          }`}
                        </p>
                      </div>
                    );
                  }}
                />
              )}
            </LoadPlaceholder>
            <LegendContainerPurchase>
              {legend.map(e => (
                <div>
                  <div className={e.className} />
                  <p>{e.title}</p>
                </div>
              ))}
            </LegendContainerPurchase>
          </MidCardBlocOne>
          <MidCardBlocTwo>
            {isLoadingPurchasesPastMonth ? (
              <LoadPrefix />
            ) : (
              <p>{NumberContext.Format(dataComprasOrig?.vbruto)}</p>
            )}
            {isLoadingPurchasesPastMonth ? (
              <LoadPrefix />
            ) : (
              <p>
                {`${NumberContext.Format(
                  dataComprasOrig?.vbrutoperiodo,
                )} (${NumberContext.toFix(
                  dataComprasOrig?.percentDiffFromLastPeriod,
                )}%)`}
              </p>
            )}

            {isLoadingPurchasesPastMonth ? (
              <LoadPrefix />
            ) : (
              <p>
                {`${NumberContext.Format(
                  dataComprasOrig?.vbrutoano,
                )} (${NumberContext.toFix(
                  dataComprasOrig?.percentDiffFromLastYearPeriod,
                )}%)`}
              </p>
            )}

            <div className="eye_icon_purchase">
              <TooltipValues
                type="compras"
                dtInit={FormatDate(
                  getDatesMonths(comprasDateStart, comprasDateEnd)[0],
                )}
                dtFim={FormatDate(
                  getDatesMonths(comprasDateStart, comprasDateEnd)[1],
                )}
                typeTag={tagType}
              />
            </div>
          </MidCardBlocTwo>
        </MidCardContainer>
      </MidCard>
    </>
  );
}

export default DashPurchase;
