import styled from "styled-components";
import { ButtonAdd as ButtonAddStyle } from "components/structure";

// TODO: Remover e utilizar o default do componets/structure
export const InputContainer = styled.div`
  display: flex;
  flex: ${props => (props.flex ? props.flex : 1)};
  flex-direction: column;
  margin-top: 12px;
  max-width: ${props => (props.maxWidth ? props.maxWidth : "290px")};

  & + & {
    margin-left: 12px;
  }

  label,
  input,
  select,
  textarea {
    font-size: 14px;
    color: #596778;
  }

  select {
    height: 36px;
    background-color: #fff;
  }

  input,
  select,
  textarea {
    border: 1px solid #596778;
    border-radius: 5px;
    padding: 4px 10px;
  }

  input:disabled {
    background: #e9e9e9;
  }

  label {
    margin-left: 10px;
  }
`;

export const ButtonAdd = styled(ButtonAddStyle)`
  margin: 35px 0 0 12px;
  color: #fff;
`;
