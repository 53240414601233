import React, { useState } from "react";
import moment from "moment";
import DashTickets from "../../dasboards/Graphics/Tickets";
import DashCategory from "../../dasboards/Graphics/Category";
import * as S from "./styles";

function ComercialMain({ eanDetails, dataProduct, ean0200Details }) {
  const [startDate] = useState(new Date());
  const [endDate] = useState(new Date());
  return (
    <>
      <S.SalesPurchaseDetails>
        <div className="l1c1">
          <p>Última compra</p>
        </div>
        <div className="l1c2">
          <p>NF</p>
        </div>
        <div className="l1c3">
          <p>Valor da Compra</p>
        </div>
        <div className="l1c4">
          <p>Quantidade</p>
        </div>
        <div className="l1c5">
          <p>Última Venda</p>
        </div>
        <div className="l1c6">
          <p>NF</p>
        </div>
        <div className="l1c7">
          <p>Valor da Venda</p>
        </div>
        <div className="l1c8">
          <p>Quantidade</p>
        </div>

        <div className="l2c1">
          {dataProduct && dataProduct.pcli_ncm ? (
            <p>
              {ean0200Details?.ultima_compra?.data_emissao
                ? moment(ean0200Details?.ultima_compra?.data_emissao).format(
                    "DD/MM/YYYY",
                  )
                : ""}
            </p>
          ) : (
            "-"
          )}
        </div>
        <div className="l2c2">
          {dataProduct && dataProduct.pcli_ncm ? (
            <p>{ean0200Details?.ultima_compra?.nota_fiscal || ""}</p>
          ) : (
            "-"
          )}
        </div>
        <div className="l2c3">
          {dataProduct && dataProduct.pcli_ncm ? (
            <p>
              R$
              {Number(ean0200Details?.ultima_compra?.valor_item || "").toFixed(
                2,
              )}
            </p>
          ) : (
            "-"
          )}
        </div>
        <div className="l2c4">
          {dataProduct && dataProduct.pcli_ncm ? (
            <p>{Number(ean0200Details?.ultima_compra?.qtd || "").toFixed(0)}</p>
          ) : (
            "-"
          )}
        </div>
        <div className="l2c5">
          {dataProduct && dataProduct.pcli_ncm ? (
            <p>
              {eanDetails?.saida?.data_emissao
                ? moment(eanDetails?.saida?.data_emissao).format("DD/MM/YYYY")
                : ""}
            </p>
          ) : (
            "-"
          )}
        </div>
        <div className="l2c6">
          {dataProduct && dataProduct.pcli_ncm ? (
            <p>{eanDetails?.saida?.numero_nota || ""}</p>
          ) : (
            "-"
          )}
        </div>
        <div className="l2c7">
          {dataProduct && dataProduct.pcli_ncm ? (
            <p>
              R$
              {Number(eanDetails?.saida?.vprod || "").toFixed(2)}
            </p>
          ) : (
            "-"
          )}
        </div>
        <div className="l2c8">
          {dataProduct && dataProduct.pcli_ncm ? (
            <p>{Number(eanDetails?.saida?.qcom || "").toFixed(0)}</p>
          ) : (
            "-"
          )}
        </div>
      </S.SalesPurchaseDetails>

      <S.DashboardsContainer>
        <div className="dashColOne">
          <DashTickets
            monthDateStart={startDate}
            monthDateEnd={endDate}
            title="Total de Tickets"
            typeDash="tickets"
          />
        </div>
        <div className="dashColTwo">
          <DashCategory
            monthDateStart={startDate}
            monthDateEnd={endDate}
            title="Venda na Categoria"
            typeDash="category"
          />
        </div>
      </S.DashboardsContainer>
    </>
  );
}
export default ComercialMain;
