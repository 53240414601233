import React from "react";
import * as S from "./Alert.styles";

const Container = ({ content }) => {
  if (content && React.isValidElement(content)) {
    return content;
  }

  return <p>{content}</p>;
};

export const Alert = ({
  type = "danger" ||
    "primary" ||
    "success" ||
    "warning" ||
    "light" ||
    "dark" ||
    "primary",
  title,
  content,
  subContent,
}) => (
  <S.Alert type={type}>
    <h4 style={{ fontWeight: "bold" }}>{title}</h4>
    {content && <Container content={content} />}
    {subContent && <Container content={subContent} />}
  </S.Alert>
);
