import React from "react";
import { Tooltip } from "@mix/mixfiscal-designsystem";
import { useRegisterLaws } from "hooks/useRegisterLaws";
import { Card, Container } from "components/structure";
import {
  Select,
  InputContainer as InputContainerItem,
  TextField,
} from "components/form";
import * as Types from "constants/types";
import {
  useLawsCbenefCase,
  useLawsFecpCase,
  useLawsNaturezaReceitaCase,
} from "useCases/lawsAttributes";

import Mocks from "pages/Laws/mocks";
import { AttributesContainerEntrada } from "components/contexts/law-grade/LawAttributes/AttributesContainerEntrada";
import { AttributesContainerSaida } from "components/contexts/law-grade/LawAttributes/AttributesContainerSaida";

import { AttributesContainer } from "./AttributesContainer/AttributesContainer";

const mocks = new Mocks();
const itemTypes = mocks.getTypeActivities();

export const AttributesGeral = () => {
  const { values, setSimpleValue } = useRegisterLaws();

  const { cBenefTypes } = useLawsCbenefCase(values);
  const { tooltipFecpTexts: tooltipText } = useLawsFecpCase(values);
  const { naturezaReceitaTypes } = useLawsNaturezaReceitaCase(values);

  const hasMVA = (value = values?.cstEntrada) => !["00", "20"].includes(value);

  return (
    <Container isFlex flexFullWidth gridGap>
      <Container isFlex flexColumn>
        <Tooltip
          body={tooltipText}
          position="top"
          title="Alíquotas sugeridas"
          zIndex={2}
          zIndexBackdrop={1}
        >
          <TextField
            label="Fecp"
            defaultValue={values.fecp}
            name="fecp"
            onChange={e => setSimpleValue("fecp", e.target.value)}
          />
        </Tooltip>
        <div className="checkbox-container">
          <input
            type="checkbox"
            checked={Boolean(values?.reducaoComFecp)}
            onChange={e =>
              setSimpleValue("reducaoComFecp", Boolean(e.currentTarget.checked))
            }
          />
          <label>Utilizar mesma redução com FECP somado.</label>
        </div>
        {cBenefTypes.length > 0 && (
          <>
            <Select
              label="cBenef"
              name="cbenef"
              defaultValue={cBenefTypes.find(
                cbenef => cbenef.value === values.cBenef,
              )}
              data={cBenefTypes}
              onChange={e => setSimpleValue("cBenef", e.target.value)}
            />
            <TextField
              name="aliqcBenef"
              defaultValue={values.aliqcBenef}
              label="Alíquota cBenef"
              onChange={e => setSimpleValue("aliqcBenef", e.target.value)}
            />
          </>
        )}
        {(values.taxa?.pis || values.taxa?.cofins) &&
          Types.CST_PIS_COFINS_NATUREZA_RECEITA.includes(values.cstSaida) && (
            <Select
              label="Natureza da Receita"
              name="naturezaReceita"
              defaultValue={naturezaReceitaTypes.find(
                n => n.value === values.naturezaReceita,
              )}
              data={naturezaReceitaTypes}
              onChange={e => setSimpleValue("naturezaReceita", e.target.value)}
            />
          )}
      </Container>

      {values.taxa?.icms && hasMVA() && (
        <Container flexFullWidth flexColumn maxWidth="220px">
          <Container flexFullWidth>
            <Select
              defaultValue={Types.MVAS_TYPES_LIST.find(
                type => type.value === values?.tipoMva,
              )}
              data={Types.MVAS_TYPES_LIST}
              label="Tipo MVA"
              name="tipoMva"
              onChange={e => setSimpleValue("tipoMva", e.target.value)}
            />
          </Container>
          <Container flexFullWidth>
            <TextField
              name="valorMva"
              label="Valor Mva"
              defaultValue={values.valorMva}
              onChange={e => setSimpleValue("valorMva", e.target.value)}
            />
          </Container>
        </Container>
      )}
      <Container flexFullWidth maxWidth="220px">
        <InputContainerItem>
          <Select
            defaultValue={itemTypes.find(
              type => type.value === values?.tipoItem,
            )}
            data={itemTypes}
            label="Tipo Item"
            name="tipoItem"
            onChange={e => setSimpleValue("tipoItem", e.target.value)}
          />
        </InputContainerItem>
      </Container>
    </Container>
  );
};

export const LawAttributes = () => (
  <Card type="lateral" title="ATRIBUTOS">
    <Container isFlex flexColumn gridGap>
      <Container isFlex gridGap>
        <AttributesContainer title="Entrada">
          <AttributesContainerEntrada />
        </AttributesContainer>
        <AttributesContainer title="Saida">
          <AttributesContainerSaida />
        </AttributesContainer>
      </Container>
      <AttributesContainer title="Condições Gerais Tributos">
        <AttributesGeral />
      </AttributesContainer>
    </Container>
  </Card>
);
