import styled from "styled-components";

export const Container = styled.div`
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #596778;
  position: relative;
  padding: 16px;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;

  p {
    width: 100%;
    color: #727e8c;
    font-size: 9px;
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
