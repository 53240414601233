import { useState, useEffect } from "react";
import { useFetchAuthenticate, useAPIAuthenticateToast } from "hooks";
import { getURLWithParams } from "utils/context";
import { useSocket } from "hooks/useSocket";
import { useSocketEvent } from "socket.io-react-hook";
import { useAuth } from "context/AuthContext";
import { useAssessorPainel } from "context/AssessorContext";

export const useAssessores = (params = {}) =>
  useFetchAuthenticate(getURLWithParams("/assessores", params));

export const useAssessor = id =>
  useFetchAuthenticate(id ? getURLWithParams(`/user/wallet/${id}`) : null);

export const useAssessorClientMessages = (params = {}) =>
  useFetchAuthenticate(
    getURLWithParams(`/assessores/client/${params.id}`, params),
  );

export const useAssessorAction = () => {
  const { isLoading, onSend } = useAPIAuthenticateToast();

  const onRemove = id =>
    onSend({
      endpoint: `/assessores/${id}`,
      messageSuccess: `Assessor Removido com sucesso!`,
      messageFail: "Falha ao tentar deletar um Assessor",
      type: "DELETE",
    });

  const onSave = async (id, data = {}) =>
    onSend({
      endpoint: id ? `/assessores/${id}` : "cest",
      messageSuccess: `Assessor salvo com sucesso!`,
      messageFail: "Falha ao tentar salvar assessor!",
      data,
      type: id ? "PUT" : "POST",
    });

  return {
    onRemove,
    isLoading,
    onSave,
  };
};

export const useAssessorClientAction = () => {
  const { isLoading, onSend } = useAPIAuthenticateToast();

  const onRemove = ({ idUser, idCli }) =>
    onSend({
      endpoint: `/assessores/${idUser}/${idCli}`,
      messageSuccess: `Cliente removido do assessor com sucesso!`,
      messageFail: "Falha ao tentar remover Cliente do assessor!",
      type: "DELETE",
    });

  const onAdd = (idUser, data) =>
    onSend({
      endpoint: `/assessores/${idUser}`,
      messageSuccess: `Cliente vinculado ao assessor com sucesso!`,
      messageFail: "Falha ao tentar vincular Cliente no assessor!",
      data,
    });

  return {
    onRemove,
    isLoading,
    onAdd,
  };
};

export const useSocketNotificationAssessor = () => {
  const [notifications, setNotifications] = useState([]);
  const { socket } = useSocket();
  const { user: userLocal } = useAuth();

  const { lastMessage } = useSocketEvent(
    socket,
    `user-whatsapp-notification-${userLocal._id}`,
  );

  useEffect(() => {
    if (lastMessage) {
      try {
        const clients = JSON.parse(lastMessage);
        setNotifications(state => {
          const list = state;
          clients.forEach(client => {
            const clientRes = list.find(cli => cli.store === client.store);
            const indexOf = list.indexOf(clientRes);
            if (!clientRes) {
              return list.push(client);
            }

            if (clientRes.totalMessages !== client.totalMessages) {
              clientRes.totalMessages = client.totalMessages;
            }

            list[indexOf] = clientRes;
          });

          return [...list];
        });
      } catch (err) {
        console.log(err);
      }
    }
  }, [lastMessage]);

  return {
    socket,
    notifications,
  };
};

export const useAssessorWhatsAppMessageRead = socket => ({
  onSend: ({ id }) =>
    socket?.emit(
      "user-whatsapp-message-read",
      JSON.stringify({
        messageId: id,
      }),
    ),
});

/**
 * Socket com as escultas dos  eventos que sao emitidos no backend
 * @param {*} param0.idCli
 * @param {*} param0.socket
 */
export const useAssessorWhatsappMessages = ({ idCli, lastMessage }) => {
  const { socket } = useSocket();
  const { newMessage, loadMessages } = useAssessorPainel();
  const { data } = useAssessorClientMessages({
    id: idCli,
    lastMessageId: lastMessage?._id,
  });

  useEffect(() => {
    const eventFunc = value => {
      if (value) {
        try {
          const { id_cli: idCliRes, ...rest } = JSON.parse(value);
          newMessage(idCliRes, {
            _id: rest.id,
            ...rest,
            type: "client",
            createdAt: new Date(),
          });
        } catch (err) {
          console.log(err);
        }
      }
    };

    socket.on(`client-${idCli}`, eventFunc);

    return () => socket.removeListener(`client-${idCli}`, eventFunc);
  }, [idCli, socket, newMessage]);

  useEffect(() => {
    loadMessages(idCli, data?.messages?.docs ?? []);
  }, [lastMessage, loadMessages, data?.messages?.docs, idCli]);
};
