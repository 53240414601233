import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { MENUS_MAIN, MENUS_SUBS, MENUS_MAIN_COMERCIAL } from "constants/menu";
import { useACL } from "context";

import { MenuPrimary } from "./MenuPrimary";
import { SubMenu } from "./SubMenu";

import { SkeletonItem } from "./Skeleton";

export const MenuList = ({ active, activeTheme, setIsUploadingDialogOpen }) => {
  const { modules, modulesAggregates } = useACL();
  const [selectedMenu, setSelectedMenu] = useState("");
  const [isSecondaryActive, setIsSecondaryActive] = useState(false);
  const [secondaryMenu, setSecondaryMenu] = useState([]);
  const [loading] = useState(false);

  const [newListMenuMain, setNewListMenuMain] = useState([]);
  const [newListMenuSecudary, setNewListMenuSecudary] = useState();

  const [isLoading, setIsLoading] = useState(true);

  const history = useHistory();

  const handleMenuSelection = async menu => {
    setSelectedMenu(menu.title);

    if (menu.hasSecondary) {
      setIsSecondaryActive(true);
    } else {
      setIsSecondaryActive(false);
    }
    if (menu.linkTo) {
      return history.push(menu.linkTo);
    }

    if (menu.name === "upload") {
      setIsUploadingDialogOpen(true);
    }

    modulesAggregates.filter(module => {
      if (module?.slug === menu?.slug) {
        const subMenu = module?.childrens
          .map(item => {
            console.log(item);
            return item?.rules?.get
              ? MENUS_SUBS.find(menuSub =>
                  menuSub.slug === item.slug && menuSub.parent === menu?.name
                    ? menuSub
                    : null,
                )
              : false;
          })
          .filter(v => v);
        setSecondaryMenu(subMenu);
      }
      return null;
    });
    return null;
  };

  useEffect(() => {
    if (!isSecondaryActive) {
      setSecondaryMenu([]);
    }
  }, [isSecondaryActive]);

  useEffect(() => {
    if (modules?.length) {
      const list = modules?.filter(module => module?.rules?.get);

      const itemListMain = list.map(module => {
        return module?.slug
          ? MENUS_MAIN?.find(menu => (menu.slug === module.slug ? menu : ""))
          : "";
      });

      const itemListCommercial = list?.map(module => {
        return module?.slug
          ? MENUS_MAIN_COMERCIAL?.find(menu =>
              menu.slug === module.slug ? menu : "",
            )
          : "";
      });
      setIsLoading(false);
      setNewListMenuMain(itemListMain);
      setNewListMenuSecudary(itemListCommercial);
    }
  }, [modules, setIsLoading]);

  if (isLoading) {
    return (
      <>
        <SkeletonItem active={active} />
      </>
    );
  }

  return (
    <>
      <MenuPrimary
        active={active}
        setSelectedMenu={setSelectedMenu}
        isSecondaryActive={isSecondaryActive}
        setSecondaryMenu={setSecondaryMenu}
        setIsSecondaryActive={setIsSecondaryActive}
        setIsUploadingDialogOpen={setIsUploadingDialogOpen}
        activeTheme={activeTheme}
        selectedMenu={selectedMenu}
        menuSubs={secondaryMenu}
        menuPrimary={newListMenuMain}
        menuSecundary={newListMenuSecudary}
        handleMenuSelection={handleMenuSelection}
      />
      <SubMenu
        isSecondaryActive={isSecondaryActive}
        loading={loading}
        selectedMenu={selectedMenu}
        secondaryMenu={secondaryMenu}
        setIsSecondaryActive={setIsSecondaryActive}
      />
    </>
  );
};
