import React from "react";
import { Controller } from "react-hook-form";
import DatePickerLocal, { registerLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";

import * as S from "./styles";

registerLocale("ptBR", ptBR);

export const DatePicker = ({
  control,
  name,
  label,
  helperText,
  error,
  ...rest
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <S.InputFieldContainer>
          <S.InputFieldLabel error={value && error}>{label}</S.InputFieldLabel>
          <S.InputField>
            <DatePickerLocal
              selected={value}
              onChange={onChange}
              locale="ptBR"
              dateFormat="dd/MM/yyyy"
              {...rest}
            />
          </S.InputField>
          <S.InputFieldHelperText error={value && error}>
            {helperText}
          </S.InputFieldHelperText>
        </S.InputFieldContainer>
      )}
    />
  );
};
