import styled, { css } from "styled-components";

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  input {
    border-bottom: 1px solid #e5e5e5;
  }

  .input-container {
    display: flex;
  }
`;

export const ButtonAction = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    padding: ${theme.spacing.xs};
  `}
`;
