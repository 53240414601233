import React from "react";
import { Tag } from "@mix/mixfiscal-designsystem";
import * as S from "./Tags.styles";

export const Tags = ({ data, handleDelete, labelRender, name }) => (
  <S.Tags>
    {(data ?? []).map((item, i) => (
      <S.TagItem key={`tag-${name}-${i}`}>
        <Tag
          type="advanced"
          label={labelRender ? labelRender(item) : item}
          handleDelete={() => handleDelete(item)}
        />
      </S.TagItem>
    ))}
  </S.Tags>
);
