import { useFetchAuthenticate, useAPIAuthenticateToast } from "hooks";
import { getURLWithParams } from "utils/context";
import { useToast } from "context/ToastContext";

export const useInviteCheck = (id, params = {}) =>
  useFetchAuthenticate(getURLWithParams(`/invitation/check/${id}`, params));

export const useInviteAccept = (id, params = {}) =>
  useFetchAuthenticate(
    getURLWithParams(`/invitation/acceptInvitation/${id}`, params),
  );

export const useSendInvit = () => {
  const { isLoading, onSend } = useAPIAuthenticateToast();

  const { addToast } = useToast();

  const onSave = data => {
    console.log(data);
    if (
      !data.email ||
      !data.companyId ||
      !data.storeId ||
      !data.accessGroupId
    ) {
      return addToast({
        title: "Usuário",
        type: "error",
        description: `Preencher campos do formulario`,
      });
    }
    const formItem = {
      companyId: data.companyId,
      storeId: data.storeId,
      accessGroupId: data.accessGroupId,
    };
    onSend({
      endpoint: `/user/sendInvite/${data.email}`,
      messageSuccess: `Convite enviado com sucesso!`,
      messageFail: "Falha ao tentar enviar convite!",
      data: formItem,
      type: "POST",
    });
  };
  return {
    onSave,
    isLoading,
  };
};
