import React from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "context/AuthContext";
import { OportunidadeNegociacao as OportunidadeNegociacaoComponent } from "components/contexts/reports/OportunidadeNegociacao/OportunidadeNegociacao";

export const OportunidadeNegociacao = () => {
  const { tokenCompanies } = useAuth();
  const history = useHistory();
  return (
    <OportunidadeNegociacaoComponent token={tokenCompanies} history={history} />
  );
};
