import styled, { css } from "styled-components";

export const UploadFile = styled.div`
  width: 100%;
  border-radius: 5px;
  box-shadow: 0px #00000033;
  padding: 2px;
  cursor: pointer;
  height: 100%;
  margin: 10px 11px 10px 1px;
  border: 1px dashed #596778;
  background: url("https://via.placeholder.com/320/f3f4f4/808080?text=1200x1200")
    no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
`;

export const UploadFileItem = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;
export const InputFileInput = styled.input`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 10;
  cursor: pointer;
`;

export const InputOverlay = styled.div`
  width: 100%;
  position: absolute;
  height: 100%;
  align-items: center;
  border-radius: 5px;
  padding: 0 14px;
  cursor: pointer;
  justify-content: center;
  text-align: center;
  transition: all 275ms;

  p {
    font-size: 13px;
    color: #596778;
  }
  ${({ isOver, isPreview }) => css`
    ${isOver && `z-index: 20;`}
    ${isPreview &&
    `
    opacity: 0;
     &:hover {
        opacity: .9;
        background: #000;
        p {
            color: #fff !important;
        }
     }
    `}
  `}

  & img {
    width: 100%;
    height: 100%;
  }
`;

export const TextLabel = styled.div`
  font-size: 13px;
`;
export const InputText = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  ${({ margin }) => css`
    ${margin && `margin: ${margin}`}
  `}
`;

export const ButtonText = styled.button`
  ${({ color }) => css`
    border-radius: 2px;
    padding: 0 5px 0 5px;
    background: ${color || "#fff"};
    font-size: 13px;
  `}
`;
