import styled, { css } from "styled-components";
import { Container, Badge } from "components/structure";
import * as S from "components/structure/Pagination/Pagination.styles";

export const List = styled.div`
  border-radius: 8px;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 23%);
`;

export const ListItem = styled(Container)`
  display: flex;
  &:last-of-type {
    border: 0px;
  }
`;

export const CardHeader = styled(Container)`
  ${Badge} {
    font-size: 12px;
    cursor: pointer;
  }
`;

export const CardContent = styled(Container)`
  ${S.Pagination} {
    display: flex;
    justify-content: center;
  }
`;

export const ListItemHr = styled.hr`
  width: 95%;
  align-self: center;
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  padding: 0;
`;

const listItemContentModifiers = {
  isSelected: () => css`
    background-color: #c4c4c470;
  `,
};

export const ListItemContent = styled.div`
  ${({ isSelected }) => css`
    display: flex;
    flex-direction: column;
    cursor: pointer;
    transition: all 0.3s;

    &:last-of-type ${ListItemHr} {
      display: none;
    }
    &:hover {
      background-color: #c4c4c470;
    }

    ${isSelected && listItemContentModifiers.isSelected()}
  `}
`;

export const ListItemIcon = styled.div`
  display: flex;
  padding-right: 8px;
  align-items: center;
`;

export const ListItemImageContent = styled.div`
  position: relative;
`;

export const ListItemImage = styled.img`
  width: 50px;
  border-radius: 50%;
`;

export const ListItemContainer = styled.div`
  flex: 1;
`;

export const ListItemActions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
`;

export const ListItemHeader = styled.div``;

export const ListItemHeaderSub = styled.div`
  font-size: 12px;
  color: grey;
`;

export const ListItemDate = styled.div`
  font-size: 11px;
  color: grey;
`;
