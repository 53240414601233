import React from "react";
import { useParams } from "react-router-dom";
import { Card } from "@mix/mixfiscal-designsystem";
import { useCest, useCestActions } from "useCases/cest";

import { CestForm } from "components/contexts/cests/CestForm";
import * as S from "./CestCrud.styles";

const getTitle = id => ({
  titleBreadcrumb: id ? "Detalhe CEST" : "Cadastrar CEST",
  titleCard: id ? "DETALHE CEST" : "CADASTRO CEST",
});

const NcmContent = ({ data }) => {
  const { onSave: onSaveAction } = useCestActions();
  const onSave = fields => {
    const fieldsSend = {
      ...fields,
      segmento: fields?.segmento?.value,
    };

    onSaveAction(data?._id, fieldsSend);
  };

  return <CestForm data={data} onSave={onSave} />;
};

export const CestCrud = () => {
  const { id } = useParams();
  const { data } = useCest(id);

  return (
    <S.Container>
      <S.Content>
        <Card
          title={getTitle(id)?.titleCard}
          bold={false}
          titleIcon="certificado"
          hasElevation
        >
          <NcmContent data={data?.data} />
        </Card>
      </S.Content>
    </S.Container>
  );
};
