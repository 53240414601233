const formatMonth = e => {
  const monthNamelist = [
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez",
  ];

  const date = `${Object.keys(e)[0]}`;
  const year = Number(date.substring(4, 6));
  const month = Number(date.substring(0, 2)) - 1;

  return `${monthNamelist[month] ?? month}/${year}`;
};

const getNotation = number => {
  if (Math.abs(Number(number)) >= 1e9) {
    return { value: 1e9, label: "b" };
  }

  if (Math.abs(Number(number)) >= 1e6) {
    return { value: 1e6, label: "mm" };
  }

  if (Math.abs(Number(number)) >= 1e3) {
    return { value: 1e3, label: "m" };
  }
  return { value: 1, label: "" };
};

const getPurchases = (values, date) => {
  const value = values.find(item => item.mes === date);

  if (value) return value;

  return {
    mes: date,
    compras: 0,
  };
};

export const getData = ({ sales, purchases }) => {
  try {
    const arraySales = sales.map(e => {
      return {
        mes: formatMonth(e, "vendas"),
        vendas: Object.values(e)[0],
      };
    });

    const arrayPurchases = purchases.map(e => {
      return {
        mes: formatMonth(e, "purchase"),
        compras: Object.values(e)[0],
      };
    });

    const arraySalesPurchases = arraySales.map(item => ({
      ...item,
      ...getPurchases(arrayPurchases, item.mes),
    }));

    const salesPurchasesOrig = arraySalesPurchases.map(e => ({
      mes: e.mes,
      compras: e.compras,
      vendas: e.vendas,
    }));

    const valuesSalesPurchaseOrig = salesPurchasesOrig.map(e =>
      Math.max(e.compras, e.vendas),
    );

    const findMaxOrig = Math.round(Math.max(...valuesSalesPurchaseOrig));
    const notation = getNotation(findMaxOrig);

    const salesPurchasesFormatedValue = arraySalesPurchases.map(e => ({
      mes: e.mes,
      compras: e.compras / notation.value,
      vendas: e.vendas / notation.value,
      valueOrigCompras: e.compras,
      valueOrigVendas: e.vendas,
    }));

    const valuesSalesPurchase = salesPurchasesFormatedValue.map(e =>
      Math.max(e.compras, e.vendas),
    );

    const findMax = Math.max(...valuesSalesPurchase);

    const arLinear = [0];
    let valueScale;
    if (findMax > 0) {
      for (let index = 25; index < 101; index += 25) {
        valueScale =
          notation.label !== ""
            ? ((findMax * index) / 100).toFixed(2)
            : Math.round((findMax * index) / 100);
        arLinear.push(valueScale);
      }
    }

    return {
      linearValues: arLinear,
      salesPurchasesFormatedValue,
      scaleData: notation.label,
    };
  } catch (e) {
    console.log(e);
  }
};
