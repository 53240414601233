import React from "react";

import CustomNode from "../CustomNode";

function NodeStructure(props) {
  const { id, data } = props;
  return <CustomNode id={id} data={data} />;
}

export default React.memo(NodeStructure);
