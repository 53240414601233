import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { FormInvitUsers } from "components/contexts/users-and-permissions/Invite";

import Card from "components/UI/Card";
import { useAccessGroups, useSendInvit } from "useCases";
import { Button } from "components/structure";

import * as S from "./styles";

export const InvitUsers = () => {
  const { handleSubmit, control, setValue } = useForm();
  const [parseGroups, setParseGroups] = useState({});
  const [companyValue, setCompanyValue] = useState();
  const [selectedGroups, setSelectedGroups] = useState({});
  const [accessGroupValue, setAccessGroupValue] = useState("");

  const { data: dataGroups } = useAccessGroups({ page: 1, limit: 9999 });

  const { onSave: onSendConvit, isLoading } = useSendInvit();

  const sendInvite = async fields => {
    const formObj = {
      ...fields,
      companyId: fields.company,
      storeId: fields.storeId,
      accessGroupId: fields.accessGroupId,
    };
    onSendConvit(formObj);
  };

  useEffect(() => {
    let dataAcessGroups;
    if (dataGroups && dataGroups?.accessGroups?.docs?.length > 0) {
      dataAcessGroups = dataGroups?.accessGroups?.docs?.map(accessGroup => ({
        label: accessGroup.groupName,
        value: accessGroup._id,
      }));
      setParseGroups(dataAcessGroups);
    }
  }, [dataGroups]);

  return (
    <S.Container>
      <S.Section>
        <Card title="CONVIDAR USUARIO" headerColor="#256925" iconName="email">
          <S.Form onSubmit={handleSubmit(sendInvite)}>
            <S.TopPart>
              <S.CollumFormFull>
                <FormInvitUsers
                  control={control}
                  setCompanyValue={setCompanyValue}
                  parseGroups={parseGroups}
                  setSelectedGroups={setSelectedGroups}
                  setValue={setValue}
                  companyId={companyValue}
                  selectedGroups={selectedGroups}
                  accessGroupValue={accessGroupValue}
                  setAccessGroupValue={setAccessGroupValue}
                  sendInvite={sendInvite}
                />
              </S.CollumFormFull>
            </S.TopPart>
            <S.Footer>
              <Button isLoading={isLoading} type="submit">
                Enviar convite
              </Button>
            </S.Footer>
          </S.Form>
        </Card>
      </S.Section>
    </S.Container>
  );
};

export default InvitUsers;
