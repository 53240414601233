/* eslint-disable react/jsx-one-expression-per-line */
import React from "react";
import { ButtonOpenNewTag } from "components/structure";

const LawExcerpt = ({ label, value }) => (
  <div style={{ display: "flex" }}>
    <b>{label}</b>
    {label === "Url" ? (
      <>
        {`: ${value}`}
        <ButtonOpenNewTag
          title="Abrir detalhe da Lei/Grade"
          onClick={() => window.open(value)}
        />
      </>
    ) : (
      `: ${value}`
    )}
    <br />
  </div>
);

export const LawNormFormat = ({ norm = {} }) => {
  return (
    <>
      {norm.tipoNorma} n°{norm.descricao} de{" "}
      {norm.dataPublicacao
        ? norm.dataPublicacao.split("T")[0].split("-").reverse().join("/")
        : "-"}
      {norm.secao ? `, seção ${norm.secao}` : ""}
      {norm.artigo ? ` , art. ${norm.artigo}` : ""}
      {norm.inciso ? `, inciso ${norm.inciso}` : ""}
      {norm.alinea ? `, alínea ${norm.alinea}` : ""}
      {norm.paragrafo ? `, § ${norm.paragrafo}` : ""}
      .
      <br />
      {norm.titulo ? `Titulo: ${norm.titulo}` : ""}
      {norm.caput ? ` , caput ${norm.caput}` : ""}
      {norm.capitulo ? ` , capitulo ${norm.capitulo}` : ""}
      {norm.anexo ? ` , anexo ${norm.anexo}` : ""}
      {norm.parte ? ` , parte ${norm.parte}` : ""}
      {norm.subsecao ? ` , subseção ${norm.subsecao}` : ""}
      {norm.livro ? ` , livro ${norm.livro}` : ""}
      {norm.item ? ` , item ${norm.item}` : ""}
      .
      <br />
      .
      <br />
      {norm.nome && <LawExcerpt label="Nome (Interno)" value={norm.nome} />}
      {norm.url && <LawExcerpt label="Url" value={norm.url} />}
      {norm.monitoramento && (
        <LawExcerpt label="Monitoramento" value={norm.monitoramento} />
      )}
      {norm.vigencia && (
        <LawExcerpt
          label="Data da Vigência"
          value={norm.vigencia.split("T")[0].split("-").reverse().join("/")}
        />
      )}
    </>
  );
};
