import React from "react";
import { Icon } from "@mix/mixfiscal-designsystem";
import { useTheme } from "hooks";
import { useHistory } from "react-router-dom";
import * as S from "./styles";

const FiltersTemplates = ({ hasAdd = false, length = 4 }) => {
  const { colors } = useTheme();
  const history = useHistory();
  return (
    <S.ContainerFilter>
      <S.HeadingNewTemplates>
        {hasAdd && (
          <Icon
            color={colors.lightGreen}
            name="adicionar"
            size={24}
            handleClick={() => {
              history.push("/check-link/new");
            }}
          />
        )}
        <S.HeadingTitle disableMargin={!hasAdd}>
          Você possui
          <strong>{` ${length} `}</strong>
          Templates
        </S.HeadingTitle>
      </S.HeadingNewTemplates>
      {/* <S.Actions>
        <S.WrapperTextField>
          <S.TextField placeholder="Digite o nome do template" />
        </S.WrapperTextField>
        <S.Select>
          <Select
            data={categoriesList}
            handleChangeCallback={handleChangeCallback}
            initialValue={SELECT_CATEGORY_INITIAL_VALUE}
            type="complex"
          />
        </S.Select>
      </S.Actions> */}
    </S.ContainerFilter>
  );
};

export default FiltersTemplates;
