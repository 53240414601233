import styled, { css } from "styled-components";

export const control = (styles, { selectProps, isDisabled }) => {
  const extraStyles = {};

  if (selectProps.variant === "simple") {
    extraStyles.border = "none";
    extraStyles.borderBottom = "1px solid #596778";
    extraStyles.borderRadius = 0;
    extraStyles["&:hover"] = {
      borderColor: "#596778",
      boxShadow: "none",
    };
  }

  extraStyles.border = "1px solid #596778";

  if (isDisabled) {
    extraStyles.border = "1px solid #cacccf";
    extraStyles["& svg"] = {
      fill: "#cacccf",
    };
  }

  return {
    ...styles,
    backgroundColor: "white",
    // height: 40,
    ...extraStyles,
  };
};

export const indicatorSeparator = styles => ({
  ...styles,
  width: 0,
});

export const valueContainer = styles => ({
  ...styles,
  // height: 39,
});

export const placeholder = styles => ({
  ...styles,
  fontSize: 14,
});

export const singleValue = (styles, state) => {
  const opacity = state.isDisabled ? 0.5 : 1;
  return {
    ...styles,
    fontSize: 14,
    opacity,
  };
};

export const menu = styles => ({
  ...styles,
  zIndex: 4,
});

export const Label = styled.label`
  ${({ isDisabled }) => css`
    font-size: 14px;
    margin-left: 8px;

    color: ${isDisabled && "#cacccf"};
  `}
`;

export const SelectLoading = styled.div`
  width: 100%;
  padding: 4px 7px;
  border: 1px solid rgb(89, 103, 120);
  border-radius: 3px;
  color: rgb(89, 103, 120);
`;

export const Select = styled.div`
  ${({ type }) => css`
    display: ${type === "top" && "flex"};
    flex-direction: ${type === "top" ? "column" : "row"};

    ${Label} {
      width: 100%;
    }

    &[type="top"] > div {
      width: 100%;
    }
  `}
`;
