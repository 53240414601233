import React, { useState, useRef, useCallback, useEffect } from "react";
import { useOnClickOutside } from "hooks";

import { TextField } from "components/form";
import { formatCnpj, ischeckIfAndNumber } from "utils";

import * as S from "./styles";

const regexNumber = new RegExp("[0-9]");
const regexText = new RegExp("[A-Za-z]");
const regexTextNumber = new RegExp("^[a-z0-9]*[a-z]+[a-z0-9]*$");

export const CardFilter = ({
  isShowFilter,
  setSearch,
  setIsShowFIlter,
  close,
}) => {
  const [cnpjValue, setCnpjValue] = useState();
  const ref = useRef();

  const handleValue = value => {
    if (!value?.length) {
      setCnpjValue("");
    }

    if (regexTextNumber.test(value) || regexText.test(value)) {
      setCnpjValue(value);
    }
    if (regexNumber.test(value)) {
      if (value?.length < 15) {
        setCnpjValue(value);
      }
    }
  };

  useEffect(() => {
    if (cnpjValue === "") {
      setCnpjValue("");
    }
  }, [cnpjValue]);

  const setSearchItem = () => {
    setSearch(cnpjValue);
  };
  useOnClickOutside(ref, useCallback(close, [close]));

  return (
    <S.CardFilterItem>
      <S.CardFilterItemShow ref={ref} close={close} active={isShowFilter}>
        <S.CardFilterLabel>Filtro</S.CardFilterLabel>
        <S.CardFilterContent>
          <TextField
            label=""
            placeholder="Razão Social ou CNPJ"
            value={
              ischeckIfAndNumber(cnpjValue) ? formatCnpj(cnpjValue) : cnpjValue
            }
            onChange={e => {
              const { value } = e.target;
              handleValue(value);
            }}
          />
        </S.CardFilterContent>
        <S.CardFilterFooter>
          <S.ButtonFIlterError
            onClick={() => {
              setIsShowFIlter(false);
              setSearch("");
            }}
          >
            Cancelar
          </S.ButtonFIlterError>
          <S.ButtonFIlter onClick={() => setSearchItem()}>
            Buscar
          </S.ButtonFIlter>
        </S.CardFilterFooter>
      </S.CardFilterItemShow>
    </S.CardFilterItem>
  );
};
