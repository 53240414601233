import React, { useState, useEffect } from "react";
import { usePageContext } from "context/PageContext";

import DashSales from "./Sales";
import DashPurchase from "./Purchase";
import DashSalesPurchase from "./SalesPurchase";
import DashTributesPisCofins from "./Tributes/PisCofins";
import DashTributesIcms from "./Tributes/Icms";
// import DashSku from "./Sku";
import DashDealChance from "./DealChance";

import {
  Container,
  BlocoContainer,
  BlocoOne,
  BlocoTwo,
  BlocoOneTributacao,
  BlocoTwoTributacao,
  BlocoTwoOverView,
} from "./styles";

function Dashboard() {
  const {
    valuesPageProvider: {
      dateStart: monthDateStart,
      dateEnd: monthDateEnd,
      fileType,
    },
  } = usePageContext();

  const [startDate, setStartDate] = useState(monthDateStart);
  const [endDate, setEndDate] = useState(monthDateEnd);

  useEffect(() => {
    setStartDate(monthDateStart);
    setEndDate(monthDateEnd);
  }, [monthDateStart, monthDateEnd]);

  return (
    <Container>
      <section>
        <p>Compra e Venda</p>
        <BlocoContainer>
          <BlocoOne>
            <div>
              <DashSales
                monthDateStart={startDate}
                monthDateEnd={endDate}
                fileType={fileType}
              />
            </div>
            <div>
              <DashPurchase
                monthDateStart={startDate}
                monthDateEnd={endDate}
                fileType={fileType}
              />
            </div>
          </BlocoOne>

          <BlocoTwo>
            <DashSalesPurchase
              monthDateStart={startDate}
              monthDateEnd={endDate}
              fileType={fileType}
            />
          </BlocoTwo>
        </BlocoContainer>
      </section>

      <section>
        <p>Tributação</p>
        <BlocoContainer>
          <BlocoOneTributacao>
            <DashTributesPisCofins
              monthDateStart={startDate}
              monthDateEnd={endDate}
              fileType="XML"
            />
          </BlocoOneTributacao>
          <BlocoTwoTributacao>
            <DashTributesIcms
              monthDateStart={startDate}
              monthDateEnd={endDate}
              fileType="XML"
            />
          </BlocoTwoTributacao>
        </BlocoContainer>
      </section>

      <section style={{ zIndex: 0 }}>
        {/* <p>OverView Produtos</p> */}
        <BlocoContainer>
          {/* <BlocoOneOverView>
            <div>
              <DashSku monthDateStart={startDate} monthDateEnd={endDate} />
            </div>
          </BlocoOneOverView> */}
          <BlocoTwoOverView>
            <div>
              <DashDealChance
                monthDateStart={startDate}
                monthDateEnd={endDate}
              />
            </div>
          </BlocoTwoOverView>

          {/* <BlocoTwoOverView>
            <DashSalesSector
              monthDateStart={startDate}
              monthDateEnd={endDate}
            />
          </BlocoTwoOverView> */}
        </BlocoContainer>
      </section>
    </Container>
  );
}

export default Dashboard;
