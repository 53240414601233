import styled from "styled-components";

export const Title = styled.p`
  display: flex;
  flex-wrap: nowrap;
  border-bottom: 1px solid #b9b9b9;
  padding: 9px 8px 0px 8px !important;
  h5 {
    font-size: 26px;
    color: #1b66b0;
  }
`;

export const TooltipCard = styled.div`
  width: 400px;
  height: 350px;
`;

export const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  height: 350px;
  overflow-y: auto;
  position: relative;
`;

export const TooltipContentItem = styled.div`
  display: flex;
  border-bottom: 1px solid #b9b9b9;
`;
export const TooltipContentItemText = styled.div`
  padding: 10px;
`;

export const StoreItem = styled.div`
  display: flex;
`;
export const StoreItemName = styled.div`
  text-align: center;
  margin: 0 3px 0 0px;
  color: #256926;
  font-weight: bold;
  width: 100px !important;
  overflow: hidden;
  white-space: nowrap;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  text-overflow: ellipsis;
  background-color: #f3f3f3;
  padding: 0 5px 0 5px;
  &:hover {
    background-color: #969696;
    color: #fff;
  }
`;

export const StoreItemNameFull = styled.div`
  margin: 0 3px 0 0px;
  color: #256926;
  font-weight: bold;
  width: 200px !important;
  overflow: hidden;
  white-space: nowrap;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  text-overflow: ellipsis;
  background-color: #f3f3f3;
  padding: 0 5px 0 5px;
  text-align: center;

  span {
    font-size: 14px;
  }
`;

export const StoreItemTotal = styled.div`
  color: #256926;
  font-weight: bold;
  background-color: #f3f3f3;
  padding: 0 5px 0 5px;
`;
