import React from "react";
import { useForm } from "react-hook-form";

import { Container, Card, Tabs, Button } from "components/structure";

import { Form, Select } from "components/form";
import { RegisterLawsContextProvider } from "context/RegisterLawsContext";
import { GridManagerProvider } from "context/GridManagerContext";
import { LawNorm, LawAttributes } from "components/contexts/law-grade";
import { Filters } from "components/contexts/grid-manager";
import { useLawsBulkGradesMerge } from "useCases/lawsBulk";

import { BulkUpdateLaws } from "./BulkUpdateLaws";

import * as S from "./BulkUpdate.styles";

const modelos = [
  {
    value: "tributario",
    label: "Tributario",
  },
  {
    value: "merge-grade-laws",
    label: "Juntar Leis/Grades",
  },
];

const LawBulkUpdateTabContainer = () => {
  return (
    <>
      <LawNorm isActive />
      <LawAttributes />
    </>
  );
};

export const LawBulkUpdateWrapper = () => {
  const form = useForm();
  const { control, watch } = form;
  const { onMerge, isLoading } = useLawsBulkGradesMerge();

  const typeUpdate = watch("typeUpdate");
  const hasMergeGrades = typeUpdate?.value === "merge-grade-laws";

  const handleSubmit = data => {
    onMerge({
      ...data,
      grades: data.grades.map((v, i) => ({
        ...v,
        isMain: i === 0,
      })),
    });
  };

  return (
    <S.Container gridGap="sm" gridColumns>
      <Filters hasTributary={false} hasLegislation={false} />
      <Card type="local" title="Atualização em massa">
        <Form form={form} onSubmit={form.handleSubmit(handleSubmit)}>
          <Container paddingSize>
            <Card type="local" title="Modelo de Atualização">
              <Select
                label="Opção de atualização"
                control={control}
                name="typeUpdate"
                data={modelos}
              />
            </Card>
          </Container>
          {typeUpdate?.value === "merge-grade-laws" && <BulkUpdateLaws />}
          {typeUpdate?.value === "tributario" && (
            <Container paddingSize>
              <Card type="local" title="Alterações">
                <Tabs
                  tab={0}
                  items={[
                    { label: "3.20%" },
                    { label: "18%" },
                    { label: "19% (inativa)" },
                  ]}
                  childTab={LawBulkUpdateTabContainer}
                />
              </Card>
            </Container>
          )}

          {hasMergeGrades && (
            <Container justifyCenter paddingSize>
              <Button isLoading={isLoading} type="submit">
                Mesclar
              </Button>
            </Container>
          )}
        </Form>
      </Card>
    </S.Container>
  );
};

export const LawBulkUpdate = () => {
  return (
    <GridManagerProvider>
      <RegisterLawsContextProvider>
        <LawBulkUpdateWrapper />
      </RegisterLawsContextProvider>
    </GridManagerProvider>
  );
};
