import React, { useState, useEffect, useRef, useCallback } from "react";
import { Icon, Switch } from "@mix/mixfiscal-designsystem";
import { useNotifyPermission } from "context/NotificationContext";
import { UseGetNotifications, UseNotificationUpdate } from "useCases";
import { useToast } from "hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import * as SLocal from "./styles";
import { NotificationCard } from "./NotificationCard";
import { ContentNotify, ContentNotifySelected } from "./Content";

const NOTIFICATION_STATUS_TYPES = {
  SEEN: "seen",
  NEW: "new",
};

const TIME_DEFAULT_POOLING = 15000;

const getNotificationsNew = list =>
  list.filter(item => item.status === NOTIFICATION_STATUS_TYPES.NEW);

export const TooltipNotify = () => {
  const ref = useRef();
  const refBtnAlert = useRef();
  const {
    data: dataNotifications,
    isLoading: isLoadingNotifications,
    fetch: fetchNotifications,
  } = UseGetNotifications();
  const {
    isShowNotify,
    setIsShowNotify,
    list,
    setList,
    listItemSelected,
    setNotificationsListSelected,
  } = useNotifyPermission({});
  const { fetch } = UseNotificationUpdate();

  const [listTotal, setListTotal] = useState(0);
  const [typeCard, setTypeCard] = useState("listAll");
  const [isOpenDescription, setIsOpenDescription] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const { addToast } = useToast();

  const handleNotification = () => setIsShowNotify(!isShowNotify);

  const handleSelectNotificationUpdate = useCallback(
    item => {
      if (item?._id) {
        const fields = {
          _id: item?._id,
          data: {
            _id: item?._id,
            status: NOTIFICATION_STATUS_TYPES.SEEN,
          },
        };
        setListTotal(listTotal === 1 ? 0 : listTotal - 1);
        fetch({ fields });
      }
    },
    [fetch, setListTotal, listTotal],
  );

  useEffect(() => {
    if (dataNotifications?.length > 0) {
      const notificationsActive = getNotificationsNew(dataNotifications);

      const currentNotifications = getNotificationsNew(list);

      if (notificationsActive.length > currentNotifications?.length) {
        addToast({
          type: "notify",
          align: "top",
          title: notificationsActive[0]?.title,
          description: notificationsActive[0]?.message,
        });
      }
      setListTotal(notificationsActive.length);
      setList(dataNotifications);
    }
  }, [dataNotifications, setList, addToast, list]);

  useEffect(() => {
    if (listItemSelected === null) {
      setIsUpdate(false);
    }
    if (
      listItemSelected?.status === NOTIFICATION_STATUS_TYPES.NEW &&
      !isUpdate
    ) {
      handleSelectNotificationUpdate(listItemSelected);
      setIsUpdate(true);
    }
  }, [
    listItemSelected,
    isUpdate,
    setIsUpdate,
    setList,
    handleSelectNotificationUpdate,
  ]);

  useEffect(() => {
    const intervalId = setInterval(
      () => fetchNotifications({ pooling: true }),
      TIME_DEFAULT_POOLING,
    );

    return () => clearInterval(intervalId);
  }, [fetchNotifications]);

  useEffect(() => {
    if (!isShowNotify) {
      setIsOpenDescription(false);
      setNotificationsListSelected(null);
    }
  }, [isShowNotify, setIsOpenDescription, setNotificationsListSelected]);

  return (
    <>
      <SLocal.ButtonClick
        style={{
          margin: "7px 0 0 0 ",
        }}
        ref={refBtnAlert}
        onClick={() => handleNotification()}
      >
        <Icon size={23} name="notificacao2" color="#bababa" />
        {listTotal >= 1 && (
          <SLocal.Badge>
            {isLoadingNotifications ? (
              <FontAwesomeIcon
                spin
                icon={faSpinner}
                style={{
                  fontSize: "11px",
                }}
                color="#fff"
              />
            ) : (
              listTotal
            )}
          </SLocal.Badge>
        )}
      </SLocal.ButtonClick>
      <NotificationCard
        ref={ref}
        typeCard={typeCard}
        title={
          typeCard === `listAll` ? `Notificação` : `Configuração de Notificação`
        }
        isShowNotify={isShowNotify}
        setIsShowNotify={setIsShowNotify}
        close={() => setIsShowNotify(false)}
        setTypeCard={setTypeCard}
        btnRef={refBtnAlert}
      >
        {typeCard === `listAll` ? (
          !isOpenDescription ? (
            <ContentNotify
              isLoading={isLoadingNotifications}
              list={list}
              handleSelectNotification={handleSelectNotificationUpdate}
              setNotificationsListSelected={setNotificationsListSelected}
              itemSelected={listItemSelected}
              isOpenDescription={isOpenDescription}
              setIsOpenDescription={setIsOpenDescription}
            />
          ) : (
            <ContentNotifySelected
              itemSelected={listItemSelected}
              setNotificationsListSelected={setNotificationsListSelected}
              isOpenDescription={isOpenDescription}
              setIsOpenDescription={setIsOpenDescription}
            />
          )
        ) : (
          <SLocal.NotificationContentItem>
            <SLocal.NotificationContentItemForm>
              <Switch
                handleChangeCallback={() => {}}
                className=""
                label="Enviar notificação via Whatsapp?"
                labelContents={[]}
              />
            </SLocal.NotificationContentItemForm>
          </SLocal.NotificationContentItem>
        )}
      </NotificationCard>
    </>
  );
};
