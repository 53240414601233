import React, { useState, useCallback } from "react";
import { IoAdd } from "react-icons/io5";
import { Select } from "components/form";
import { hasValueNcm } from "utils/context/laws";
import { Card } from "components/structure";
import { useNcmNivel } from "useCases/ncm";
import { useRegisterLaws } from "hooks/useRegisterLaws";

import { useRegisterLawsGradesContext } from "context/RegisterLawsContext";

import { NcmTags } from "components/contexts/ncms/NcmsTags";
import { LawNcmAlert } from "./LawNcmAlert";

import { InputContainer, ButtonAdd } from "./LawNcm.styles";

export const Ncm = ({ hasRemoveNcmGradeContext = true }) => {
  const { values, setComplexValue, setValues } = useRegisterLaws();
  const { removeNcms } = useRegisterLawsGradesContext();
  const [ncmValues, setNcmValues] = useState({});
  const [ncmSearch, setNcmSearch] = useState(null);

  const { dataMap: sessaoData, isLoading: isLoadingSessao } =
    useNcmNivel("sessao");
  const { dataMap: capituloData, isLoading: isLoadingCapitulo } = useNcmNivel(
    "capitulo",
    ncmValues.session !== "Selecione" ? { sessao: ncmValues.session } : null,
  );

  const posicaoNcm = hasValueNcm([ncmValues.chapter, ncmValues.session]);

  const { dataMap: posicaoData, isLoading: isLoadingPosicao } = useNcmNivel(
    "posicao",
    posicaoNcm ? { ncm: posicaoNcm } : null,
  );
  const subPosicaoNcm = hasValueNcm([
    ncmValues.position,
    ncmValues.chapter,
    ncmValues.session,
  ]);

  const { dataMap: subPosicaoData, isLoading: isLoadingSubPosicao } =
    useNcmNivel("sub-posicao", subPosicaoNcm ? { ncm: subPosicaoNcm } : null);

  const subItemNcm = hasValueNcm([
    ncmValues.subPosition,
    ncmValues.position,
    ncmValues.chapter,
    ncmValues.session,
  ]);

  const { dataMap: subItemsData, isLoading: isLoadingItems } = useNcmNivel(
    "item",
    ncmSearch ?? subItemNcm ? { ncm: ncmSearch ?? subItemNcm } : null,
  );

  const hasNcm = value =>
    values?.ncms?.find(n => n.value === Number(ncmValues.subItem)) ||
    values?.ncms?.find(n => n.data.vc_codigo_sem_formatacao === value);

  const clearData = () =>
    setNcmValues({
      session: null,
      chapter: null,
      position: null,
      subPosition: null,
      subItem: null,
    });

  const handleNewNcm = () => {
    if (ncmValues.subItem && !hasNcm()) {
      const item = subItemsData?.find(e => e.value === ncmValues.subItem);
      clearData();
      return setComplexValue("ncms", {
        data: {
          vc_codigo_sem_formatacao: item.value,
        },
      });
    }

    const chapter = capituloData?.find(
      chapterItem => chapterItem.value === ncmValues.chapter,
    );

    const session = sessaoData?.find(
      sessionItem => sessionItem.value === ncmValues.session,
    );

    const position = posicaoData?.find(
      positionItem => positionItem.value === ncmValues.position,
    );

    if (ncmValues.subPosition) {
      const subPosition = subPosicaoData?.find(
        subPositionItem => subPositionItem.value === ncmValues.subPosition,
      );

      clearData();
      return setComplexValue("ncms", {
        data: {
          vc_codigo_sem_formatacao: subPosition.value,
        },
      });
    }

    if (position) {
      clearData();
      return setComplexValue("ncms", {
        data: {
          vc_codigo_sem_formatacao: position.value,
        },
      });
    }

    const codigoFormatacao = `${`${session?.data?.vc_codigo_sem_formatacao}`.padStart(
      2,
      "0",
    )}.${chapter?.data?.vc_codigo_sem_formatacao}`;

    if (ncmValues?.session && ncmValues?.chapter && !hasNcm(codigoFormatacao)) {
      clearData();
      return setComplexValue("ncms", {
        data: {
          vc_codigo_sem_formatacao: codigoFormatacao,
        },
      });
    }
  };

  const deleteNcm = ncm => {
    const newNcms = values?.ncms?.filter(
      n =>
        n?.data?.vc_codigo_sem_formatacao !==
        ncm?.data?.vc_codigo_sem_formatacao,
    );

    if (hasRemoveNcmGradeContext) removeNcms([ncm]);

    setValues({ ...values, ncms: newNcms });
  };

  const addValues = (ncms, ncmRemove) => {
    ncms.forEach(ncm =>
      setComplexValue("ncms", {
        data: {
          vc_codigo_sem_formatacao: ncm.value,
        },
      }),
    );
    if (ncmRemove) deleteNcm({ data: { vc_codigo_sem_formatacao: ncmRemove } });
  };

  const removeValues = ncmRemove =>
    deleteNcm({ data: { vc_codigo_sem_formatacao: ncmRemove } });

  const intervalNcms = values?.ncms?.filter(
    ncm => ncm.data?.vc_codigo_sem_formatacao?.length < 8,
  );

  const onSearch = value => setNcmSearch(value === "" ? null : value);

  const onChangeItem = useCallback(
    ({ value, subItem }) =>
      setNcmValues({
        ...ncmValues,
        subItem: value,
        session: subItem.sessoes[1]?.value,
        position: subItem.posicao[1]?.value,
        chapter: subItem?.capitulos[1]?.value,
      }),
    [ncmValues],
  );

  const hasIntervalNcms = Boolean(intervalNcms?.length);

  return (
    <Card type="lateral" title="NCM" height="90px">
      <div className="input-container">
        <InputContainer maxWidth="260px">
          <Select
            label="Sessão"
            labelType="top"
            isLoading={isLoadingSessao}
            data={sessaoData?.map(session => ({
              value: session.value,
              label: session.label,
            }))}
            value={sessaoData
              ?.filter(item => item.value === ncmValues?.session)
              .map(session => ({
                value: session.value,
                label: session.label,
              }))}
            onChange={e => setNcmValues({ session: e.value })}
          />
        </InputContainer>
        <InputContainer maxWidth="260px">
          <Select
            label="Capítulo"
            isLoading={isLoadingCapitulo}
            labelType="top"
            value={capituloData
              ?.filter(item => item.value === ncmValues.chapter)
              .map(session => ({
                value: session.value,
                label: session.label,
              }))}
            data={capituloData?.map(chapter => ({
              value: chapter.value,
              label: chapter.label,
            }))}
            onChange={e =>
              setNcmValues({
                session: ncmValues.session,
                chapter: e.value,
              })
            }
          />
        </InputContainer>
        <InputContainer maxWidth="260px">
          <Select
            label="Posição"
            isLoading={isLoadingPosicao}
            labelType="top"
            value={posicaoData
              ?.filter(item => item.value === ncmValues.position)
              .map(session => ({
                value: session.value,
                label: session.label,
              }))}
            data={posicaoData?.map(position => ({
              value: position.value,
              label: position.label,
            }))}
            onChange={e =>
              setNcmValues({
                session: ncmValues.session,
                chapter: ncmValues.chapter,
                position: e.value,
              })
            }
          />
        </InputContainer>
        <InputContainer maxWidth="260px">
          <Select
            label="Sub-Posição"
            isLoading={isLoadingSubPosicao}
            isDisabled={subPosicaoData?.length === 0}
            labelType="top"
            data={subPosicaoData?.map(subPosition => ({
              value: subPosition.value,
              label: subPosition.label,
            }))}
            onChange={e =>
              setNcmValues({
                ...ncmValues,
                subPosition: e.value,
                item: undefined,
                subItem: undefined,
              })
            }
          />
        </InputContainer>
        <InputContainer maxWidth="260px">
          <Select
            label="Item"
            labelType="top"
            isLoading={isLoadingItems}
            onInputChange={onSearch}
            data={subItemsData?.map(subItem => ({
              value: subItem.value,
              label: subItem.label,
              subItem,
            }))}
            value={subItemsData
              ?.filter(item => item.value === ncmValues.subItem)
              .map(session => ({
                value: session.value,
                label: session.label,
              }))}
            onChange={onChangeItem}
          />
        </InputContainer>
        <div>
          <ButtonAdd type="button" onClick={handleNewNcm}>
            <IoAdd />
          </ButtonAdd>
        </div>
      </div>
      <div className="tag-container">
        <NcmTags ncms={values?.ncms} deleteNcm={deleteNcm} />
      </div>

      {hasIntervalNcms &&
        intervalNcms.map(ncm => (
          <LawNcmAlert
            key={`alert-${ncm?.data?.vc_codigo_sem_formatacao}`}
            ncmBegin={ncm?.data?.vc_codigo_sem_formatacao}
            handleConfirm={addValues}
            handleCancel={removeValues}
          />
        ))}
    </Card>
  );
};

export const LawNcm = Ncm;
