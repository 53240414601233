import React from "react";
import Select from "react-select";

import { useProductsContext } from "context/ProductsContext";

import { useMercadologico } from "../../../../useCases";

import * as S from "./ProductForm.styles";

export const ProductFormSelect = ({ control, setValue, getValues }) => {
  const { handleChangeField } = useProductsContext({});

  const merDepartament = getValues("mer_departamento");
  const merSetor = getValues("mer_setor");
  const merGrupo = getValues("mer_grupo");
  const merSubgrupo = getValues("mer_subgrupo");

  const { data: dataDepartment } = useMercadologico({
    type: `departamento`,
  });

  const { data: dataSector } = useMercadologico({
    type: `setor`,
    value: merDepartament || "",
  });

  const { data: dataGroup } = useMercadologico({
    type: `grupo`,
    value: merSetor || "",
  });

  const { data: dataSubgroup } = useMercadologico({
    type: `sub-grupo`,
    value: merGrupo || "",
  });

  return (
    <>
      <S.ColumnFormItem isPaddingInput="8px" setMargin="0 0 0 0">
        <S.ColumnFormItemSub>
          <h4>Departamento</h4>
          <Select
            control={control}
            name="mer_departamento"
            label="Departamento"
            labelType="top"
            options={(dataDepartment?.mercadologicos?.docs || []).map(
              option => ({
                value: option.name,
                label: option.name,
              }),
            )}
            value={dataDepartment?.mercadologicos?.docs
              .filter(item => item.name === merDepartament)
              .map(option => ({
                value: option.name,
                label: option.name,
              }))}
            onChange={option => {
              setValue("mer_departamento", option.value);
              handleChangeField("mer_departamento", option.value);
            }}
          />
        </S.ColumnFormItemSub>

        <S.ColumnFormItemSub>
          <h4>Setor</h4>
          <Select
            control={control}
            name="mer_setor"
            label="Setor"
            labelType="top"
            options={(dataSector?.mercadologicos?.docs || []).map(option => ({
              value: option.name,
              label: option.name,
            }))}
            value={dataSector?.mercadologicos?.docs
              .filter(item => item.name === merSetor)
              .map(option => ({
                value: option.name,
                label: option.name,
              }))}
            onChange={option => {
              setValue("mer_setor", option.value);
              handleChangeField("mer_setor", option.value);
            }}
          />
        </S.ColumnFormItemSub>
      </S.ColumnFormItem>

      <S.ColumnFormItem isPaddingInput="8px">
        <S.ColumnFormItemSub>
          <h4>Grupo</h4>
          <Select
            control={control}
            name="mer_grupo"
            label="Grupo"
            labelType="top"
            options={(dataGroup?.mercadologicos?.docs || []).map(option => ({
              value: option.name,
              label: option.name,
            }))}
            value={dataGroup?.mercadologicos?.docs
              .filter(item => item.name === merGrupo)
              .map(option => ({
                value: option.name,
                label: option.name,
              }))}
            onChange={option => {
              setValue("mer_grupo", option.value);
              handleChangeField("mer_grupo", option.value);
            }}
          />
        </S.ColumnFormItemSub>
        <S.ColumnFormItemSub>
          <h4>Sub-grupo</h4>
          <Select
            control={control}
            name="mer_subgrupo"
            label="Sub-grupo"
            labelType="top"
            options={(dataSubgroup?.mercadologicos?.docs || []).map(option => ({
              value: option.name,
              label: option.name,
            }))}
            value={dataSubgroup?.mercadologicos?.docs
              .filter(item => item.name === merSubgrupo)
              .map(option => ({
                value: option.name,
                label: option.name,
              }))}
            onChange={option => {
              setValue("mer_subgrupo", option.value);
              handleChangeField("mer_subgrupo", option.value);
            }}
          />
        </S.ColumnFormItemSub>
      </S.ColumnFormItem>
    </>
  );
};
