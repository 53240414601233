import styled from "styled-components";

import * as CreditStyles from "./credit/style";

export const Container = styled.div`
  display: flex;

  ${CreditStyles.StyledCredit} {
    width: 50%;

    &:first-of-type {
      margin-right: 15px;
    }
  }
`;
