export const Header = [
  {
    value: 0,
    label: "MÓDULOS",
  },
  {
    value: 1,
    label: "NENHUM",
  },
  {
    value: 2,
    label: "LEITURA",
  },
  {
    value: 3,
    label: "GRAVAÇÃO",
  },
  {
    value: 4,
    label: "EDIÇÃO",
  },
  {
    value: 5,
    label: "EXCLUIR",
  },
  {
    value: 6,
    label: "TODOS",
  },
];

export const GetPermissionsValues = value => {
  const numberValue = +value;
  switch (numberValue) {
    case 1:
      return {
        get: false,
        post: false,
        put: false,
        delete: false,
      };
    case 2:
      return {
        get: true,
        post: false,
        put: false,
        delete: false,
      };
    case 3:
      return {
        get: true,
        post: true,
        put: false,
        delete: false,
      };
    case 4:
      return {
        get: true,
        post: true,
        put: true,
        delete: false,
      };
    case 5:
    case 6:
      return {
        get: true,
        post: true,
        put: true,
        delete: true,
      };
    default:
      return {
        get: false,
        post: false,
        put: false,
        delete: false,
      };
  }
};
