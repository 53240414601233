import React, { useState } from "react";
import { MdKeyboardArrowUp } from "react-icons/md";

import {
  Container,
  AccordionHeader,
  AccordionBody,
  IconContainer,
} from "./styles";

function Accordion({ title, extraInfo, children }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Container>
      <AccordionHeader onClick={() => setIsOpen(!isOpen)} isOpen={isOpen}>
        <p>{title}</p>
        <div>
          {extraInfo && <p>{extraInfo}</p>}
          <IconContainer isOpen={isOpen}>
            <MdKeyboardArrowUp size={24} />
          </IconContainer>
        </div>
      </AccordionHeader>
      <AccordionBody isOpen={isOpen}>{children}</AccordionBody>
    </Container>
  );
}

export default Accordion;
