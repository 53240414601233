import styled from "styled-components";

export const DiagnosticContainer = styled.div`
  display: grid;
  width: 270px;
  margin-left: 30px;
  grid-template-areas:
    "head head"
    "l1c1 l1c2"
    "l2c1 l2c2"
    "l3c1 l3c2"
    "l4c1 l4c2"
    "l5c1 l5c2"
    "l6c1 l6c2";
  grid-template-columns: 160px 110px;
  grid-template-rows: 30px 30px 30px 30px 30px 30px 30px;

  p {
    font-family: Cairo;
    font-size: 12px;
  }

  .head {
    grid-area: head;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #596778;
    background-color: #adc86a;
    p {
      color: #fff;
      font-weight: bold;
    }
  }
  .l1c1 {
    grid-area: l1c1;
    display: flex;
    align-items: center;
    border-left: 1px solid #596778;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    p {
      color: #596778;
      margin-left: 12px;
    }
  }

  .l1c2 {
    grid-area: l1c2;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    padding-left: 5px;
    p {
      justify-content: space-between;
      color: #596778;
      margin-left: 5px;
      font-size: 16px;
    }
  }
  .l2c1 {
    grid-area: l2c1;
    display: flex;
    align-items: center;
    border-left: 1px solid #596778;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    p {
      color: #596778;
      margin-left: 12px;
    }
  }
  .l2c2 {
    grid-area: l2c2;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    padding-left: 5px;
    p {
      justify-content: space-between;
      color: #596778;
      margin-left: 5px;
      font-size: 16px;
    }
  }
  .l3c1 {
    grid-area: l3c1;
    display: flex;
    align-items: center;
    border-left: 1px solid #596778;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    p {
      color: #596778;
      margin-left: 12px;
    }
  }
  .l3c2 {
    grid-area: l3c2;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    padding-left: 5px;
    p {
      justify-content: space-between;
      color: #596778;
      margin-left: 5px;
      font-size: 16px;
    }
  }
  .l4c1 {
    grid-area: l4c1;
    display: flex;
    align-items: center;
    border-left: 1px solid #596778;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    p {
      color: #596778;
      margin-left: 12px;
    }
  }
  .l4c2 {
    grid-area: l4c2;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    padding-left: 5px;
    p {
      justify-content: space-between;
      color: #596778;
      margin-left: 5px;
      font-size: 16px;
    }
  }

  .l5c1 {
    grid-area: l5c1;
    display: flex;
    align-items: center;
    border-left: 1px solid #596778;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    p {
      color: #596778;
      margin-left: 12px;
    }
  }
  .l5c2 {
    grid-area: l5c2;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    padding-left: 5px;
    p {
      justify-content: space-between;
      color: #596778;
      margin-left: 5px;
      font-size: 16px;
    }
  }

  .l6c1 {
    grid-area: l6c1;
    display: flex;
    align-items: center;
    border-left: 1px solid #596778;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    p {
      color: #596778;
      margin-left: 12px;
    }
  }
  .l6c2 {
    grid-area: l6c2;
    display: flex;
    align-items: center;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    p {
      color: #596778;
      margin-left: 12px;
    }
  }
`;
export const CalculatorContainer = styled.div`
  display: grid;
  width: 270px;
  margin-left: 30px;
  margin-top: 9px;
  grid-template-areas:
    "head head"
    "compra venda"
    "v1 v2"
    "icms icms"
    "perc1 perc2"
    "piscofins piscofins"
    "percpis1 percpis2"
    "markup margem"
    "markupvalue margemvalue"
    "lucrobruto lucrobrutovalue";

  grid-template-columns: 135px 135px;
  grid-template-rows: 30px 23px 30px 23px 23px 23px 23px 23px 30px 23px;
  p {
    font-family: Cairo;
    font-size: 12px;
  }
  input {
    width: 50px;
    height: 20px;
  }
  .head {
    grid-area: head;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #596778;
    background-color: #adc86a;
    p {
      color: #fff;
      font-weight: bold;
    }
    .fa-arrow-up-right-from-square {
      color: white;
      margin-left: 10px;
      margin-bottom: 2px;
      cursor: pointer;
    }
  }
  .compra {
    grid-area: compra;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #596778;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    background-color: #fbdeba;
    p {
      color: #ee6b09;
      font-weight: bold;
    }
  }
  .venda {
    grid-area: venda;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    background-color: #e6eed2;
    p {
      color: #45924f;
      font-weight: bold;
    }
  }

  .v1 {
    grid-area: v1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #596778;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    background-color: #fbdeba;
    p {
      line-height: 10px;
      padding: 5px;
      border-radius: 5px;
      color: #ee6b09;
      font-weight: bold;
      border: 1px solid #ee6b09;
    }
    input {
      background-color: #fbdeba;
      color: #ee6b09;
      font-weight: bold;
      font-size: 12px;
      margin-right: -10px;
      margin-top: -1px;
      margin-bottom: -1px;
    }
    .v1-p1 {
      line-height: 10px;
      padding: 1px;
      border-radius: 5px;
      border: 1px solid #ee6b09;
    }
  }
  .v2 {
    grid-area: v2;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    background-color: #e6eed2;
    p {
      line-height: 10px;
      padding: 5px;
      border-radius: 5px;
      color: #45924f;
      font-weight: bold;
      border: 1px solid #45924f;
    }
    input {
      background-color: #e6eed2;
      color: #45924f;
      font-weight: bold;
      font-size: 12px;
    }
  }
  .icms {
    grid-area: icms;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #596778;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    p {
      line-height: 10px;
      padding: 5px;
      border-radius: 5px;
      color: #596778;
      font-weight: bold;
    }
  }
  .perc1 {
    grid-area: perc1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #596778;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    /* background-color: #fbdeba; */
    p {
      line-height: 10px;
      padding: 5px;
      border-radius: 5px;
      color: #ee6b09;
      font-weight: bold;
    }
  }
  .perc2 {
    grid-area: perc2;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    /* background-color: #e6eed2; */
    p {
      line-height: 10px;
      padding: 5px;
      border-radius: 5px;
      color: #45924f;
      font-weight: bold;
      /* border: 1px solid #45924f; */
    }
  }
  .pis-cofins {
    grid-area: piscofins;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #596778;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    p {
      line-height: 10px;
      padding: 5px;
      border-radius: 5px;
      color: #596778;
      font-weight: bold;
    }
  }

  .percpis1 {
    grid-area: percpis1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #596778;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    /* background-color: #fbdeba; */
    p {
      line-height: 10px;
      padding: 5px;
      border-radius: 5px;
      color: #ee6b09;
      font-weight: bold;
    }
  }
  .percpis2 {
    grid-area: percpis2;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    /* background-color: #e6eed2; */
    p {
      line-height: 10px;
      padding: 5px;
      border-radius: 5px;
      color: #45924f;
      font-weight: bold;
      /* border: 1px solid #45924f; */
    }
  }
  .markup {
    grid-area: markup;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #596778;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    background: #e5e5e5 0% 0% no-repeat padding-box;
    p {
      color: #596778;
      font-weight: bold;
    }
  }
  .margem {
    grid-area: margem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    background: #e5e5e5 0% 0% no-repeat padding-box;
    p {
      color: #596778;
      font-weight: bold;
    }
  }
  .markupvalue {
    grid-area: markupvalue;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #596778;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    background: #e5e5e5 0% 0% no-repeat padding-box;
    p {
      line-height: 10px;
      padding: 5px;
      border-radius: 5px;
      color: #596778;
      font-weight: bold;
      border: 1px solid #596778;
    }
    input {
      font-weight: bold;
      font-size: 12px;
      color: #596778;
      margin-right: -10px;
      margin-top: -1px;
      margin-bottom: -1px;
    }
  }
  .margemvalue {
    grid-area: margemvalue;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    background: #e5e5e5 0% 0% no-repeat padding-box;
    p {
      line-height: 10px;
      padding: 5px;
      border-radius: 5px;
      color: #596778;
      font-weight: bold;
      border: 1px solid #596778;
    }
    input {
      font-weight: bold;
      font-size: 12px;
      color: #596778;
      margin-right: -9px;
      margin-left: 1px;
      margin-top: -1px;
      margin-bottom: -1px;
      background: #e5e5e5 0% 0% no-repeat padding-box;
    }
    .margemvalue-p1 {
      line-height: 10px;
      padding: 1px;
      border-radius: 5px;
      border: 1px solid #596778;
    }
  }

  .lucrobruto {
    grid-area: lucrobruto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 6px;
    border-left: 1px solid #596778;
    border-bottom: 1px solid #596778;
    background-color: #e6eed2;
    p {
      color: #45924f;
      font-weight: bold;
    }
  }
  .lucrobrutovalue {
    grid-area: lucrobrutovalue;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    background-color: #e6eed2;
    p {
      color: #45924f;
      font-weight: bold;
    }
  }
`;
