import React, { Suspense, useState } from "react";

import "@hdn/mixfiscal-nfe-frontend/dist/index.css";
import { useAuth } from "context/AuthContext";
import Dialog from "components/UI/Dialog";
import { usePermissions } from "context/PermissionsContext";
import { List } from "components/contexts/association";

import { Card } from "@mix/mixfiscal-designsystem";
import { BaseModule } from "../NotasFiscais/BaseModule";

const AppAssociation = React.lazy(() => import("./Association.module"));

export const Association = () => {
  const { tokenCompanies } = useAuth();
  const permissions = usePermissions();
  const [modal, setModal] = useState(false);
  const [modalOptions, setModalOptions] = useState(null);

  if (!tokenCompanies) {
    return <div>Carregando...</div>;
  }

  const onHandleDetail = item => {
    setModal(true);
    setModalOptions(item);
  };

  const handleClose = () => {
    setModal(false);
    setModalOptions(null);
  };

  console.log(modalOptions?.id_cli?.cnpj);
  return (
    <>
      <Dialog fullScreen isOpen={modal} handleClose={handleClose}>
        <BaseModule>
          {history => (
            <Suspense fallback={<div>Carregando...</div>}>
              <AppAssociation
                {...permissions}
                token={tokenCompanies}
                cnpj={modalOptions?.id_cli?.cnpj}
                width="100%"
                height="1000px"
                currentRoute="/association/association"
                onChangeRoute={() => history.push(`/association/association`)}
              />
            </Suspense>
          )}
        </BaseModule>
      </Dialog>
      <Card headerColor="#256925" textColor="#FFF" title="Associação">
        <List onHandleDetail={onHandleDetail} />
      </Card>
    </>
  );
};

export default Association;
