import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 75vh;
`;

export const Content = styled.div`
  padding: 0px 10px 10px 10px;

  .input-container {
    display: flex;
    flex-flow: row wrap;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
  }

  .max-width-400 {
    gap: 14px;
    max-width: 400px;
  }
`;
