import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  span {
    color: ${({ theme }) => theme.colors.slateGray};
    cursor: pointer;
    display: block;
    font-weight: bold;
    margin: -1px 4px 0px 4px;
    text-decoration: underline;

    div {
      display: block;
    }
  }
`;

export const Dropdown = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 5px;
  box-shadow: ${({ theme }) => theme.shadows.default};
  height: ${({ open }) => (open ? 240 : 39)}px;
  margin-top: 20px;
  opacity: 1;
  position: absolute;
  right: 0;
  width: ${({ width }) => width || 167}px;
`;

export const InputContainer = styled.div`
  padding: 6px 8px;
  width: 100%;
`;

export const InputWrapper = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.slateGray};
  border-radius: 5px;
  display: flex;
  height: 27px;
  padding: 0 8px;
  width: 100%;
`;

export const InputSearch = styled.input`
  color: ${({ theme }) => theme.colors.slateGray};
  font-family: ${({ theme }) => theme.font.family};
  font-size: 14px;
  line-height: 14px;
`;

export const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const SelectAllWrapper = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.gray200};
  display: flex;
`;

export const SelectAllOption = styled.label`
  background-color: ${({ theme }) => theme.colors.gray200};
  color: ${({ theme }) => theme.colors.slateGray};
  font-size: 10px;
  height: 20px;
  width: 100%;
`;

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 150px;
  max-height: 150px;
  overflow-y: scroll;
  position: relative;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  height: ${({ height }) => height || 30}px;
  justify-content: center;
`;

export const SelectOption = styled.label`
  color: ${({ theme }) => theme.colors.slateGray};
  font-family: ${({ theme }) => theme.font.family};
  font-size: 10px;
  height: 30px;
  width: 100%;
`;

export const FooterWrapper = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.gray200};
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  bottom: 0;
  display: flex;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 5px;
  padding-right: 5px;
  label {
    font-size: 10px;
    font-weight: bold;
  }
  position: absolute;
  width: 100%;
`;

export const LabelFooter = styled.label`
  color: ${({ theme }) => theme.colors.slateGray};
  font-family: ${({ theme }) => theme.font.family};
  font-size: 11px;
  line-height: 12px;
`;
