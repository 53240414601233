import styled, { css } from "styled-components";

const modificador = {
  setBackground: item => css`
    background-color: ${item};
  `,
  setType: type => css`
    ${type === `equals` &&
    `
    background-color: #c86a6a;
    color:#fff
    `}
    ${type === `contains` &&
    `
    background-color: #d58f00;
    color:#000
    font-weight: bold;
    `}
  `,
};
export const NotItem = styled.div`
  width: 100%;
`;
export const ListCardItem = styled.div`
  margin: 15px 0 0 0;
`;

export const Button = styled.button`
  ${({ color }) => css`
    width: 40px;
    height: 40px;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 1px 6px #00000033;
    margin-right: unset;
    margin-top: 0;
    margin-left: 10px;
    color: #fff;
    ${color && modificador.setBackground(color)}
  `}
`;

export const ActionBtn = styled.div`
  display: flex;
  flex-direction: row;
`;

export const BadgeType = styled.div`
  min-width: 100px;
  border-radius: 50px;
  margin: 8px 0 0 0;
  text-align: center;
  text-transform: uppercase;

  ${({ type }) => css`
    ${type && modificador.setType(type)}
  `}
`;

export const DisplayActionFlex = styled.div`
  display: flex;
  flex-direction: column;
`;
