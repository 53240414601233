import React, { Suspense } from "react";
import "@hdn/mixfiscal-nfe-frontend/dist/index.css";
import { useAuth } from "context/AuthContext";
import { usePermissions } from "context/PermissionsContext";
import { BaseModule } from "./BaseModule";

const AppNotaFiscal = React.lazy(() => import("./Invoices"));

const NotasFiscais = () => {
  const { tokenCompanies } = useAuth();
  const permissions = usePermissions();

  if (!tokenCompanies) {
    return <div>Carregando...</div>;
  }

  return (
    <BaseModule>
      {(module, modulePath, history) => (
        <Suspense fallback={<div>Carregando...</div>}>
          <AppNotaFiscal
            {...permissions}
            token={tokenCompanies}
            width="100%"
            height="1000px"
            currentRoute={modulePath}
            onChangeRoute={newModulePath =>
              history.push(`/${module}${newModulePath}`)
            }
          />
        </Suspense>
      )}
    </BaseModule>
  );
};

export default NotasFiscais;
