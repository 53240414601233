import React, { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import { Card, Alert } from "components/structure";
import { Checkbox, TextField } from "components/form";

import * as S from "./LawRegimeSpecial.styles";

export const LawRegimeSpecial = ({ regimeName: regimeNameValue, setValue }) => {
  const [regimeSpecial, setRegimeSpecial] = useState(Boolean(regimeNameValue));
  const [regimeName, setRegimeName] = useState(regimeNameValue);
  const [regimeNameDebounce] = useDebounce(regimeName, 1000);

  useEffect(() => {
    if (regimeNameValue) {
      setRegimeSpecial(Boolean(regimeNameValue));
      setRegimeName(regimeNameValue);
    }
  }, [regimeNameValue]);

  useEffect(() => {
    if (regimeNameValue !== regimeNameDebounce) {
      setValue("regime_name", regimeNameDebounce);
    }
  }, [regimeNameDebounce, setValue, regimeNameValue]);

  const onChange = event => setRegimeName(event.target.value);

  const onChangeRegimeSpecial = () => {
    const value = !regimeSpecial;
    if (!value) {
      setRegimeName("");
    }
    setRegimeSpecial(value);
  };

  return (
    <Card type="lateral" title="Regime Especial">
      <S.Container>
        <S.ContainerName>
          <TextField
            name="regime_name"
            label="Nome do Regime Especial"
            disabled={!regimeSpecial}
            onChange={onChange}
            value={regimeName}
          />
        </S.ContainerName>
        <S.ContainerCheck>
          <Checkbox
            name="regime_special"
            label="Essa Lei/Grade faz parte de um Regime Especial ?"
            onChange={onChangeRegimeSpecial}
            checked={regimeSpecial}
          />
        </S.ContainerCheck>
      </S.Container>

      {regimeSpecial && (
        <Alert
          type="warning"
          title="Informativo"
          content="Por favor cuidado ao selecionar uma Grade/Lei como Regime especial."
        />
      )}
    </Card>
  );
};
