import { Tag } from "@mix/mixfiscal-designsystem";
import React, { useState } from "react";
import { IoAdd } from "react-icons/io5";

import { useForm } from "react-hook-form";
import { ButtonAdd, Button } from "components/structure";
// eslint-disable-next-line import/no-cycle
import { LawNormSelect } from "components/contexts/law-grade/LawNorm/LawNormSelect";

import { LAW_NORMS_LABELS } from "constants/Types/Law.types";

import * as S from "./LawNormForm.styles";
import Validation from "./LawNormForm.validation";

export const NormForm = ({
  normsValue,
  handleNewNorm,
  isEditing = false,
  values = {},
  handleCancel = () => {},
  hasNormName = true,
}) => {
  const [indeterminateValidity, setIndeterminateValidity] = useState(false);
  const { register, handleSubmit, reset, getValues, setValue, control } =
    useForm({
      resolver: Validation,
      mode: "onChange",
      defaultValues: values,
    });

  const tipoNorma = register("tipoNorma");
  const validadeIndeterminada = register("validadeIndeterminada");

  const handleNewNormLocal = norm => {
    // CASO seja um Gerenciador de Normas mas não tenha nenhuma selecionada ele nao deixa adicionar no formulario
    if (
      norm.tipoNorma === LAW_NORMS_LABELS.GERENCIADOR_LEIS &&
      norm.id_norm?.value === ""
    ) {
      return false;
    }

    handleNewNorm(norm);
    if (!isEditing) reset({});
    setValue("tipoNorma", "Selecione");
  };

  const handleNormsChange = ({ tipoNorma: tipoNormaValue }) =>
    reset({ tipoNorma: tipoNormaValue });

  const normLabel = getValues("tipoNorma");
  const hasNormGerenciador = normLabel === LAW_NORMS_LABELS.GERENCIADOR_LEIS;

  const hasNorm = normLabel !== "Selecione" && !hasNormGerenciador;

  return (
    <form onSubmit={handleSubmit(handleNewNormLocal)} className="flex-column">
      <div className="input-container max-width-400">
        <S.InputContainer>
          <label>Normas</label>
          <select
            placeholder="Selecione a norma"
            value={normLabel}
            onChange={e => handleNormsChange({ tipoNorma: e.target.value })}
            ref={tipoNorma.ref}
          >
            <option value="Selecione">Selecione</option>
            {normsValue.map(norm => (
              <option key={norm.label} value={norm.label}>
                {norm.label}
              </option>
            ))}
          </select>
        </S.InputContainer>
        {hasNorm && (
          <div className="tag-margin">
            <Tag type="simple" label={normLabel} />
          </div>
        )}
      </div>
      {hasNorm && hasNormName && (
        <div className="input-container">
          <div className="input-container">
            <S.InputContainer maxWidth="none">
              <label>Nome (Interno)</label>
              <input type="text" {...register("nome")} />
            </S.InputContainer>
          </div>
        </div>
      )}

      {hasNorm && (
        <div className="input-container">
          <S.InputContainer flex={0.6}>
            <label>{normLabel}</label>
            <input type="text" {...register("descricao")} />
          </S.InputContainer>
          <S.InputContainer flex={0.6}>
            <label>Data Publicação</label>
            <input type="date" {...register("dataPublicacao")} />
          </S.InputContainer>
          <S.InputContainer flex={0.6}>
            <label>Vigência</label>
            <input type="date" {...register("vigencia")} />
          </S.InputContainer>
          <S.InputContainer flex={0.6}>
            <label>Validade</label>
            <input
              type="date"
              {...register("validade")}
              disabled={indeterminateValidity}
            />
          </S.InputContainer>
          <div className="checkbox-container">
            <input
              type="checkbox"
              checked={indeterminateValidity}
              onChange={e => {
                setIndeterminateValidity(Boolean(e.currentTarget.checked));
                validadeIndeterminada.onChange(e);
              }}
              ref={validadeIndeterminada.ref}
            />
            <label>Validade indeterminada</label>
          </div>
        </div>
      )}
      {[
        "Lei",
        "Lei Complementar",
        "Decreto",
        "Convenção",
        "Resolução",
        "Instrucao Normativa",
      ].includes(normLabel ?? "") && <hr />}

      <div className="input-container">
        {[
          "Lei",
          "Lei Complementar",
          "Decreto",
          "Convenção",
          "Resolução",
          "Instrucao Normativa",
        ].includes(normLabel ?? "") && (
          <S.InputContainer maxWidth="195px">
            <label>Seção</label>
            <input type="text" {...register("secao")} />
          </S.InputContainer>
        )}
        {[
          "Lei",
          "Lei Complementar",
          "Decreto",
          "Convenção",
          "Emenda",
          "Resolução",
          "Instrucao Normativa",
          "Portaria",
          "Portaria Conjunta",
          "Portaria Normativa",
        ].includes(normLabel ?? "") && (
          <S.InputContainer maxWidth="195px">
            <label>Artigo</label>
            <input type="text" {...register("artigo")} />
          </S.InputContainer>
        )}
        {[
          "Lei",
          "Lei Complementar",
          "Decreto",
          "Convenção",
          "Emenda",
          "Resolução",
          "Instrucao Normativa",
          "Portaria",
          "Portaria Conjunta",
          "Portaria Normativa",
        ].includes(normLabel ?? "") && (
          <S.InputContainer maxWidth="195px">
            <label>Incíso</label>
            <input type="text" {...register("inciso")} />
          </S.InputContainer>
        )}
        {[
          "Lei",
          "Lei Complementar",
          "Decreto",
          "Convenção",
          "Emenda",
          "Resolução",
          "Instrucao Normativa",
          "Portaria",
          "Portaria Conjunta",
          "Portaria Normativa",
        ].includes(normLabel ?? "") && (
          <S.InputContainer maxWidth="195px">
            <label>Alínea</label>
            <input type="text" {...register("alinea")} />
          </S.InputContainer>
        )}
        {[
          "Lei",
          "Lei Complementar",
          "Decreto",
          "Convenção",
          "Emenda",
          "Resolução",
          "Instrucao Normativa",
          "Portaria",
          "Portaria Conjunta",
          "Portaria Normativa",
        ].includes(normLabel ?? "") && (
          <S.InputContainer maxWidth="195px">
            <label>Parágrafo</label>
            <input type="text" {...register("paragrafo")} />
          </S.InputContainer>
        )}
        {[
          "Lei",
          "Lei Complementar",
          "Decreto",
          "Convenção",
          "Resolução",
          "Instrucao Normativa",
        ].includes(normLabel ?? "") && (
          <S.InputContainer maxWidth="195px">
            <label>Título</label>
            <input type="text" {...register("titulo")} />
          </S.InputContainer>
        )}
        {[
          "Lei",
          "Lei Complementar",
          "Decreto",
          "Convenção",
          "Emenda",
          "Resolução",
          "Instrucao Normativa",
          "Portaria",
          "Portaria Conjunta",
          "Portaria Normativa",
        ].includes(normLabel ?? "") && (
          <S.InputContainer maxWidth="195px">
            <label>Caput</label>
            <input type="text" {...register("caput")} />
          </S.InputContainer>
        )}
      </div>
      <div className="input-container">
        {[
          "Lei",
          "Lei Complementar",
          "Decreto",
          "Convenção",
          "Resolução",
          "Instrucao Normativa",
        ].includes(normLabel ?? "") && (
          <S.InputContainer maxWidth="195px">
            <label>Capítulo</label>
            <input type="text" {...register("capitulo")} />
          </S.InputContainer>
        )}
        {[
          "Lei",
          "Lei Complementar",
          "Decreto",
          "Convenção",
          "Resolução",
          "Instrucao Normativa",
          "Portaria Conjunta",
          "Portaria",
        ].includes(normLabel ?? "") && (
          <S.InputContainer maxWidth="195px">
            <label>Anexo</label>
            <input type="text" {...register("anexo")} />
          </S.InputContainer>
        )}
        {[
          "Lei",
          "Lei Complementar",
          "Decreto",
          "Convenção",
          "Resolução",
          "Instrucao Normativa",
        ].includes(normLabel ?? "") && (
          <S.InputContainer maxWidth="195px">
            <label>Parte</label>
            <input type="text" {...register("parte")} />
          </S.InputContainer>
        )}
        {[
          "Lei",
          "Lei Complementar",
          "Decreto",
          "Convenção",
          "Resolução",
          "Instrucao Normativa",
        ].includes(normLabel ?? "") && (
          <S.InputContainer maxWidth="195px">
            <label>Subseção</label>
            <input type="text" {...register("subsecao")} />
          </S.InputContainer>
        )}
        {[
          "Lei",
          "Lei Complementar",
          "Decreto",
          "Convenção",
          "Resolução",
          "Instrucao Normativa",
        ].includes(normLabel ?? "") && (
          <S.InputContainer maxWidth="195px">
            <label>Livro</label>
            <input type="text" {...register("livro")} />
          </S.InputContainer>
        )}
        {[
          "Lei",
          "Lei Complementar",
          "Decreto",
          "Convenção",
          "Resolução",
          "Instrucao Normativa",
          "Porta",
        ].includes(normLabel ?? "") && (
          <S.InputContainer maxWidth="195px">
            <label>Item</label>
            <input type="text" {...register("item")} />
          </S.InputContainer>
        )}

        {["Parecer"].includes(normLabel ?? "") && (
          <S.InputContainer maxWidth="195px">
            <label>Preâmbulo</label>
            <input type="text" {...register("preambulo")} />
          </S.InputContainer>
        )}

        {["Parecer"].includes(normLabel ?? "") && (
          <S.InputContainer maxWidth="195px">
            <label>Ementa</label>
            <input type="text" {...register("ementa")} />
          </S.InputContainer>
        )}

        {["Solução de consulta"].includes(normLabel ?? "") && (
          <S.InputContainer maxWidth="195px">
            <label>Assunto</label>
            <input type="text" {...register("assunto")} />
          </S.InputContainer>
        )}

        {["Solução de consulta"].includes(normLabel ?? "") && (
          <S.InputContainer maxWidth="195px">
            <label>Dispositivos Legais</label>
            <input type="text" {...register("dispositivosLegais")} />
          </S.InputContainer>
        )}

        {["Parecer", "Solução de consulta"].includes(normLabel ?? "") && (
          <S.InputContainer maxWidth="195px">
            <label>Relatório</label>
            <input type="text" {...register("relatorio")} />
          </S.InputContainer>
        )}

        {["Solução de consulta"].includes(normLabel ?? "") && (
          <S.InputContainer maxWidth="195px">
            <label>Fundamento</label>
            <input type="text" {...register("fundamento")} />
          </S.InputContainer>
        )}

        {["Parecer"].includes(normLabel ?? "") && (
          <S.InputContainer maxWidth="195px">
            <label>Fundamentação</label>
            <input type="text" {...register("fundamentacao")} />
          </S.InputContainer>
        )}

        {["Parecer"].includes(normLabel ?? "") && (
          <S.InputContainer maxWidth="195px">
            <label>Dispositivo</label>
            <input type="text" {...register("dispositivo")} />
          </S.InputContainer>
        )}

        {["Solução de consulta"].includes(normLabel ?? "") && (
          <S.InputContainer maxWidth="195px">
            <label>Conclusão</label>
            <input type="text" {...register("conclusao")} />
          </S.InputContainer>
        )}

        {["Resposta à consulta"].includes(normLabel ?? "") && (
          <S.InputContainer maxWidth="195px">
            <label>Relato</label>
            <input type="text" {...register("relato")} />
          </S.InputContainer>
        )}
      </div>

      {hasNormGerenciador && (
        <div className="input-container">
          <S.InputContainer maxWidth="none">
            <LawNormSelect control={control} />
          </S.InputContainer>
          <div>
            <ButtonAdd color="#fff" type="submit">
              <IoAdd />
            </ButtonAdd>
          </div>
        </div>
      )}

      {hasNorm && (
        <>
          <div className="input-container">
            <S.InputContainer maxWidth="none">
              <label>URL</label>
              <input type="text" {...register("url")} />
            </S.InputContainer>
          </div>

          {isEditing ? (
            <>
              <div className="input-container">
                <S.InputContainer maxWidth="none">
                  <label>Monitoramento</label>
                  <textarea rows={5} {...register("monitoramento")} />
                </S.InputContainer>
              </div>
              <S.ContainerCenter>
                <S.ContainerAction>
                  <Button
                    color="secondary"
                    type="button"
                    onClick={handleCancel}
                  >
                    Cancelar
                  </Button>
                </S.ContainerAction>
                <Button type="submit">Salvar</Button>
              </S.ContainerCenter>
            </>
          ) : (
            <div className="input-container">
              <S.InputContainer maxWidth="none">
                <label>Monitoramento</label>
                <textarea rows={5} {...register("monitoramento")} />
              </S.InputContainer>
              <div>
                <ButtonAdd color="#fff" type="submit">
                  <IoAdd />
                </ButtonAdd>
              </div>
            </div>
          )}
        </>
      )}
    </form>
  );
};
