import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
  background: radial-gradient(
    circle,
    rgba(71, 113, 158, 1) 0%,
    rgba(30, 50, 80, 1) 100%
  );
`;

export const Window = styled.div`
  display: flex;
  align-items: center;

  width: 700px;
  height: 500px;
  background-color: #0e1c30;
  padding: 40px;
`;

export const RegisterContainer = styled.div`
  width: 250px;
  height: 100%;
  color: #fff;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  & > svg {
    height: 24px;
  }

  .register {
    width: 180px;
    display: flex;
    flex-direction: column;

    span {
      font-size: 12px;
      margin: 8px 0;
    }

    button {
      border: 1px solid #5a8dee;
      border-radius: 5px;
      padding: 8px 16px;
      background-color: transparent;
      color: #fff;
    }
  }

  .talk-to-us {
    display: flex;
    align-items: center;

    svg {
      margin-right: 8px;
    }
  }
`;

export const FormContainer = styled.form`
  flex: 1;
  height: 140%;
  background-color: #fff;
  padding: 24px;

  form {
    display: flex;
    flex-direction: column;

    .input-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;

      &:not(:last-of-type) {
        margin-bottom: 15px;
      }
    }

    input {
      color: #8c8b8b;
      border-bottom: 1px solid #707070;
      width: 100%;
      padding-right: 24px;
    }

    .icon {
      position: absolute;
      right: 0;
    }

    p {
      color: #8c8b8b;
      font-size: 12px;
      align-self: flex-end;
    }

    .title {
      font-size: 20px;
      font-weight: 700;
      color: #5a8dee;
      align-self: flex-start;
      margin-bottom: 16px;
    }

    button {
      margin-top: 16px;
    }
  }

  .socials {
    display: flex;
    align-items: center;
    justify-content: space-between;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px 24px;
      background-color: blue;
      border-radius: 5px;
      cursor: pointer;
    }

    div:nth-child(1) {
      margin: 0 auto;
      background-color: #d64131;
    }
    div:nth-child(2) {
      background-color: #007bb6;
    }
    div:nth-child(3) {
      background-color: #3b5a9a;
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    color: #fff;
    padding: 8px 16px;
    border-radius: 5px;
    background-color: #5a8dee;
    text-transform: uppercase;
    cursor: pointer;

    svg {
      margin-right: 8px;
    }
  }

  .qrCode {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 150px;
    }
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #bababa;
  margin: 16px 0;

  p {
    color: #8c8b8b;
    font-size: 14px;
    margin-top: -3.5px;
    background-color: #fff;
    padding: 0 8px;
  }
`;
