import styled from "styled-components";

// position: absolute;
// width: 35%;
// padding: 16px;
// z-index: 10000000;
// bottom: 0;
// right: 0;
// filter: ${props => (props.isCurrentStep ? "none" : "brightness(0.4)")};

export const Container = styled.div`
  position: relative;
  z-index: 10000000;
  display: block;
  height: 0;

  display: ${props => (props.isHidden ? "none" : "block")};
`;
