import React, { useState, useCallback, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Datatable, Tooltip, Pagination } from "@mix/mixfiscal-designsystem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useACL } from "context";
import { useOnClickOutside } from "hooks";

import Dialog from "components/UI/Dialog";

import {
  faPenToSquare,
  faSpinner,
  faTrashCan,
  faCertificate,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";

import colors from "styles/tokens/colors";

import { useStoresPagination, useStoreRemove } from "useCases";
import Card from "components/UI/Card";
import { CardHeader } from "components/contexts/users-and-permissions";
import { CardFilter } from "./Filter";
import { CustomTooltip } from "./Tooltip";

import * as S from "../../styles";

export const ListStores = ({ baseUrl, selectedCompany }) => {
  const ref = useRef();

  const { hasEdit, hasCreate, hasRemove } = useACL("lojas");

  const history = useHistory();
  const [search, setSearch] = useState("");
  const [isShowDialogConfirm, setIsShowDialogConfirm] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [isShowFilter, setIsShowFIlter] = useState(false);
  const [limitStores, setLimitStores] = useState(100);

  const [pageStores, setPagesStores] = useState(1);

  const { onRemove: onRemoveStore } = useStoreRemove();

  const { isLoading: isLoadingStore, data: dataStores } = useStoresPagination({
    page: pageStores,
    limit: limitStores,
    search,
    companyId:
      selectedCompany && selectedCompany.length > 0
        ? selectedCompany[0]._id
        : "",
  });

  const handleEditView = ({ id, url }) =>
    history.push(`${baseUrl}/${url}/${id}`);

  const handleActionConfirm = useCallback(
    () => onRemoveStore(selectedId),
    [onRemoveStore, selectedId],
  );
  const handleActionPopupCancel = () => {
    setIsShowDialogConfirm(false);
    setSelectedId();
  };

  const storeButton = store => {
    const onclick = () => {
      if (hasEdit) {
        handleEditView({
          id: store._id,
          url: "lojas",
        });
      }
    };
    const onRemove = () => {
      if (hasRemove) {
        setIsShowDialogConfirm(true);
        setSelectedId(store._id);
      }
    };
    return (
      <>
        {hasEdit && (
          <FontAwesomeIcon
            onClick={onclick}
            icon={faPenToSquare}
            style={{
              marginLeft: "10px",
              fontSize: "15px",
            }}
          />
        )}
        {hasRemove && (
          <FontAwesomeIcon
            onClick={onRemove}
            icon={faTrashCan}
            style={{
              marginLeft: "10px",
              fontSize: "15px",
            }}
          />
        )}
      </>
    );
  };

  const bad = store => {
    const active = value => {
      if (value && value.toString().substr(0, 1) !== `-`) {
        return {
          active: `inativo`,
        };
      }
      return {
        active: `ativo`,
      };
    };

    return (
      <S.BadgeContainer>
        <S.Badge type={store.type}>{store.type}</S.Badge>
        <Tooltip
          body={`O Status da sua "${store.type || `"sem tipo"`}" ${
            store.isActive === true ? `ATIVO` : `INATIVA`
          }`}
          position="top"
          title={`Status da ${store.type || `"sem tipo"`}`}
          zIndex={99999}
        >
          <S.Badge type={store.isActive ? `ativo` : `inativo`}>
            {store.isActive === true ? `ATIVO` : `INATIVA`}
          </S.Badge>
        </Tooltip>
        {store?.fadel && store?.certificate && (
          <Tooltip
            component={<CustomTooltip dias={store?.fadel?.dias_vencidos} />}
            position="top"
            title="Certificado"
            zIndex={99999}
          >
            <S.Badge type={active(store?.fadel?.dias_vencidos).active}>
              <FontAwesomeIcon
                icon={
                  active(store?.fadel?.dias_vencidos).active === "inativo"
                    ? faExclamationCircle
                    : faCertificate
                }
                style={{
                  marginTop: "5px",
                  fontSize: "12px",
                }}
                color="#fff"
              />
            </S.Badge>
          </Tooltip>
        )}
      </S.BadgeContainer>
    );
  };

  useOnClickOutside(ref, () => setIsShowFIlter(false));
  return (
    <>
      <Dialog
        isOpen={isShowDialogConfirm}
        title="Alerta"
        handleConfirm={handleActionConfirm}
        zIndex="100002"
        handleCancel={handleActionPopupCancel}
        handleClose={handleActionPopupCancel}
      >
        <h3 style={{ textAlign: "center", marginTop: 10 }}>
          Deseja realmente remover essa loja ?
        </h3>
      </Dialog>
      <Card title="LOJAS" headerColor="#256925" iconName="erp">
        <CardHeader
          url={`${baseUrl}/lojas/new`}
          title="Selecione uma loja para editar."
          totalItems={dataStores?.stores?.totalDocs}
          limitItems={limitStores}
          permission={{ post: hasCreate }}
          labelButtonAdd="CRIAR NOVA LOJA"
          labelFilterBtn="FILTRO"
          isShowItem={isShowFilter}
          setIsShowItem={setIsShowFIlter}
        />
        <CardFilter
          close={() => setIsShowFIlter(false)}
          isShowFilter={isShowFilter}
          setIsShowFIlter={setIsShowFIlter}
          setSearch={setSearch}
        />
        <S.CardSubItemPagination>
          <Pagination
            handlePageChange={value => setPagesStores(value)}
            initialPage={1}
            siblingsCount={1}
            totalPages={dataStores?.stores?.totalPages}
            amountVariations={[10, 20, 30, 300, 500]}
            handleChangeAmount={setLimitStores}
          />
        </S.CardSubItemPagination>
        <div className="div-overflow storestest">
          {isLoadingStore ? (
            <S.Isloading>
              <FontAwesomeIcon
                spin
                icon={faSpinner}
                style={{
                  fontSize: "40px",
                }}
                color={colors.slateGray}
              />
              <h3>Carregando...</h3>
            </S.Isloading>
          ) : (
            ``
          )}
          {dataStores?.stores?.totalDocs > 0 && (
            <Datatable
              handleChangePage={() => setPagesStores()}
              pagination={false}
              size="12px"
              columns={[
                { field: "action", title: "" },
                { field: "companyName", title: "LOJA" },
                { field: "cnpj", title: "CNPJ" },
                { field: "badges", title: "" },
              ]}
              rawData={dataStores?.stores?.docs.map(store => ({
                action: storeButton(store),
                companyName: store.companyName ? store.companyName : "",
                cnpj: store.cnpj ? store.cnpj : "",
                badges: bad(store),
              }))}
            />
          )}
        </div>
      </Card>
    </>
  );
};
