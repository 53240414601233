import React from "react";
import { Loading } from "@mix/mixfiscal-designsystem";
import { useHistory } from "react-router-dom";

import * as S from "../../styles";

export const SubMenu = ({
  isSecondaryActive,
  loading,
  selectedMenu,
  secondaryMenu,
  setIsSecondaryActive,
}) => {
  const history = useHistory();

  return (
    <>
      <S.SecondaryContainer isActive={isSecondaryActive}>
        {loading && <Loading autoplay loop width={100} height={100} />}
        {selectedMenu && secondaryMenu && secondaryMenu.length > 0 && (
          <>
            <S.Title onClick={() => setIsSecondaryActive(false)}>
              {selectedMenu}
            </S.Title>
            {secondaryMenu
              .filter(item => item?.disabled === undefined || !item?.disabled)
              .map(menuItem =>
                menuItem && menuItem.title ? (
                  <S.ItemContainer
                    key={menuItem.link}
                    onClick={() => {
                      if (menuItem.isBlank) {
                        window.open(menuItem.link, "_blank");
                      }
                      history.push(menuItem.link);
                    }}
                  >
                    {menuItem.icon}
                    <p>{menuItem.title}</p>
                  </S.ItemContainer>
                ) : (
                  ""
                ),
              )}
          </>
        )}

        {isSecondaryActive && !loading && secondaryMenu?.length === 0 && (
          <>
            <S.Title onClick={() => setIsSecondaryActive(false)} />
            <S.NotFoundContainer>
              <S.NotFoundIcon />
              <p>Nada Encontrado :(</p>
            </S.NotFoundContainer>
          </>
        )}
      </S.SecondaryContainer>
    </>
  );
};
