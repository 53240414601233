import { useEffect, useState } from "react";
import {
  useAPIAuthenticate,
  useFetchAuthenticate,
  useAPIAuthenticateToast,
} from "hooks";
import { getURLWithParams } from "utils/context";
import { BLANK_SELECT_VALUE } from "constants/types";

function ncmMapToSelect(items) {
  const itemsFormatted = items
    .filter(item => {
      return !RegExp("^Ex 0", "igm").test(item.vc_descricao);
    })
    .map(item => ({
      label: `${item.vc_codigo} - ${item.vc_descricao}`,
      value: item.sr_id,
      data: {
        vc_codigo:
          typeof item.vc_codigo === "string"
            ? String(item.vc_codigo).split(".").reverse()[0].substr(-2, 2)
            : item.vc_codigo,
        vc_codigo_sem_formatacao: item.vc_codigo,
        id_relacionado: item.it_id_relacionado,
      },
      sessoes: Array.isArray(item?.sessoes)
        ? ncmMapToSelect(item?.sessoes)
        : null,
      capitulo: item?.capitulo,
      posicao: Array.isArray(item?.posicao)
        ? ncmMapToSelect(item?.posicao)
        : null,
      capitulos: Array.isArray(item?.capitulos)
        ? ncmMapToSelect(item?.capitulos)
        : null,
    }));

  return [...[BLANK_SELECT_VALUE], ...itemsFormatted];
}

const ncmExMapToSelect = items => {
  const rows = items.map(item => {
    const [, numEx] = item.vc_descricao.split(" ");
    return {
      label: `${item.codigo} - ${item.descricao}`,
      value: numEx,
    };
  });
  return [...[BLANK_SELECT_VALUE], ...rows];
};

export const useNCMNivelOld = (nivel, code = "") => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(undefined);
  const [dataMap, setDataMap] = useState(undefined);

  const api = useAPIAuthenticate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(getURLWithParams("/ncm", { nivel, code }));
        setData(res);
        setDataMap(ncmMapToSelect(res?.data?.data));
      } catch (error) {
        console.log({ error });
      }
    };

    if (nivel) fetchData();
  }, [nivel, code, api]);

  return {
    isLoading,
    setIsLoading,
    data,
    dataMap,
  };
};

export const useNcmNivel = (nivel, params = null) => {
  const [dataMap, setDataMap] = useState(undefined);

  const { data, isLoading } = useFetchAuthenticate(
    nivel &&
      getURLWithParams("/ncm/nivel", {
        limit: params?.limit ?? 250,
        page: params?.page ?? 1,
        nivel,
        sessao: params?.sessao,
        ncm: params?.ncm,
        removeStatus: params?.removeStatus,
        capitulo: params?.capitulo,
      }),
  );

  useEffect(() => {
    setDataMap(ncmMapToSelect(data?.data?.docs ?? []));
  }, [data]);

  return {
    isLoading,
    setIsLoading: () => {},
    data: data?.data?.docs ?? [],
    dataRes: data,
    dataMap,
  };
};

/**
 * Retorna um detalhe do NCM da API
 * @param {String} id
 * @returns
 */
export const useNcm = id => useFetchAuthenticate(id ? `/ncm/${id}` : null);

/**
 * Hooks com as ações que podem ser disparadas por um formulário ou remoção de ncm
 * @returns
 */
export const useNcmActions = () => {
  const { isLoading, onSend: onSendApi } = useAPIAuthenticateToast();

  const onSave = async (id, data = {}) =>
    onSendApi({
      endpoint: id ? `/ncm/${id}` : "ncm",
      messageSuccess: `Ncm salvo com sucesso!`,
      messageFail: "Falha ao tentar salvar Ncm!",
      data,
      type: id ? "PUT" : "POST",
    });

  const onRemoveGrade = async id =>
    onSendApi({
      endpoint: `/ncm/${id}`,
      messageSuccess: `Lei/Grade REMOVIDO com sucesso!`,
      messageFail: "FALHA AO REMOVER GRADE/LEI!",
      type: "DELETE",
    });

  return {
    isLoading,
    onRemoveGrade,
    onSave,
  };
};

/**
 * TODO: Estou utilizando dessa forma pois precisa migrar a API para aceitar multiplos parametros de ncm
 * @param {*} param0
 * @returns
 */
export const useNcmEx = ({ ncms = [] }) => {
  const [ncmExMap, setNcmExMap] = useState([]);

  const { data, isLoading } = useFetchAuthenticate(
    getURLWithParams("/ncm/nivel", {
      nivel: "excecao",
      ncm: JSON.stringify(
        ncms?.map(ncm => ncm?.data?.vc_codigo_sem_formatacao) ?? [],
      ),
    }),
  );

  useEffect(() => {
    setNcmExMap(ncmExMapToSelect(data?.data?.docs ?? []));
  }, [data]);

  return {
    data,
    ncmExMap,
    isLoading,
  };
};
