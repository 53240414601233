import React, { useState, useEffect } from "react";
import { SelectStore } from "components/contexts/users-and-permissions/SelectStore";
import { LoadCircle } from "components/structure";

import Select, { components } from "react-select";
import { InputSearch } from "components/contexts/stores/SelectSearch/Components";
import { OptionsComponent } from "./OptionCustom";
import * as S from "../styles";

const CustomOption = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      <OptionsComponent data={props} />
    </components.Option>
  );
};
export const ConfigGroupsForm = ({
  searching,
  isLoading,
  data,
  parseGroups,
  setSelectedGroups,
  setTableAccessGroupValue,
  selectedGroups,
  setTableCompanyValue,
  setTableCompanyValueCompanyId,
  setSelectedCompany,
  selectedCompany,
  tableCompanyValue,
  tableStoreValue,
  setTableStoreValue,
  addTableRow,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [debouncedTerm, setDebouncedTerm] = useState(searchValue);

  useEffect(() => {
    const timer = setTimeout(() => {
      setSearchValue(debouncedTerm);
    }, 1000);
    return () => clearTimeout(timer);
  }, [debouncedTerm]);

  useEffect(() => {
    if (searchValue !== "") {
      searching({
        type: "MATRIZ",
        search: searchValue,
      });
    }
  }, [searchValue, searching]);

  const messageBlank =
    ({ isLoadingItem, dataLength, searchLength }) =>
    () => {
      if (isLoadingItem) {
        return <LoadCircle width="25px" height="25px" />;
      }

      if (!isLoadingItem && searchLength === 0 && !dataLength) {
        return "Digite o Razão Social ou CNPJ";
      }

      return "Não foi possivel encontrar dados com os parametros digitados";
    };

  const onInputChange = value => {
    setDebouncedTerm(value);
  };

  const filterOptions = () => true;
  return (
    <>
      <S.ColumnFormItem>
        <S.InputForm>
          <S.Label>Grupo de Acesso</S.Label>
          <Select
            options={parseGroups}
            label="Grupo de Acesso"
            placeholder="Selecione"
            styles={{
              control: styles => ({
                ...styles,
                width: "355px",
                border: "1px solid #596778",
              }),
            }}
            onChange={selected => {
              setTableAccessGroupValue(selected.value);
              setSelectedGroups(selected);
            }}
            value={selectedGroups}
          />
        </S.InputForm>
        <S.InputForm>
          <S.Label>Matriz</S.Label>
          <Select
            components={{
              Input: props => (
                <InputSearch
                  margin="10px 4px 0 4px"
                  key="input-search"
                  onInputChange={onInputChange}
                  searchValue={searchValue}
                  {...props}
                />
              ),
              Option: CustomOption,
            }}
            labelType="top"
            placeholder="Selecione"
            label="Matriz"
            styles={{
              control: styles => ({
                ...styles,
                width: "355px",
                border: "1px solid #596778",
              }),
            }}
            noOptionsMessage={messageBlank({
              isLoadingItem: isLoading,
              dataLength: data?.length,
              searchLength: searchValue?.length,
            })}
            onChange={selected => {
              setTableCompanyValue(
                selected?.companyId ? selected?.companyId : selected?._id,
              );
              setTableCompanyValueCompanyId(selected?.companyId);
              setSelectedCompany({
                label: selected?.companyName,
                value: selected?.companyId,
              });
            }}
            value={selectedCompany}
            filterOption={filterOptions}
            options={
              isLoading
                ? []
                : data?.map(company => ({
                    label:
                      company.company_name ||
                      company.tradeName ||
                      company.companyName ||
                      company.cnpj ||
                      company.company_social_name,
                    value: company,
                    ...company,
                  }))
            }
          />
          {/* )} */}
        </S.InputForm>
      </S.ColumnFormItem>
      <S.SelectStyleStore>
        <SelectStore
          selectedCompany={selectedCompany}
          companyId={tableCompanyValue}
          value={tableStoreValue}
          onChange={setTableStoreValue}
          handleAdd={addTableRow}
        />
      </S.SelectStyleStore>
    </>
  );
};
