import React from "react";
import { Card } from "@mix/mixfiscal-designsystem";
import TagsListMonth from "components/contexts/dashboard/tags-list-month";
import TagsList from "components/contexts/dashboard/tags-list";
import Hr from "components/contexts/dashboard/hr";

import { BodyCard, HeaderCard } from "./styles";

const SalesBuyCard = ({
  children,
  title = "Compras",
  subTitle,
  total = "",
  tagsList = [],
  handleClick,
  handleClickType,
  typeList = "",
  tag,
  invertDate = false,
}) => (
  <Card className="card-custom">
    <HeaderCard>
      <h1>{title}</h1>
      <h4>{subTitle}</h4>
    </HeaderCard>
    <Hr />
    <BodyCard>
      <span>{total}</span>
    </BodyCard>
    <div style={{ height: "100%" }}>{children}</div>

    {typeList === "" ? (
      <TagsList
        tag={tag}
        tags={tagsList}
        onChangeDate={handleClick}
        onChangeType={handleClickType}
      />
    ) : (
      <TagsListMonth
        tag={tag}
        tags={tagsList}
        onChangeDate={handleClick}
        onChangeType={handleClickType}
        invertDate={invertDate}
      />
    )}
  </Card>
);

export default SalesBuyCard;
