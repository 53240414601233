import styled from "styled-components";

export const Title = styled.div`
  width: 100%;
  text-align: center;
  padding: 0px 0px 10px 0px;
`;

export const TitleText = styled.h2`
  color: #7b7b7b;
  font-weight: bold;
`;

export const ContentFormSelect = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const ContentSelect = styled.div`
  width: 100%;
`;

export const DividerH = styled.div`
  width: 100%;
  height: 1px;
  align-self: stretch;
  background-color: #0000002e;
  margin: 21px 0 21px 0;
`;
