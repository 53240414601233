import styled, { css } from "styled-components";
import * as S from "../../styles";

const setItem = {
  setMargin: margin => css`
    margin: ${margin};
  `,
  setCursor: cursor => css`
    cursor: ${cursor};
  `,
  setAlignItem: align => css`
    text-align: ${align};
  `,
  setPadding: padding => css`
    margin: ${padding};
  `,
  setWidth: width => css`
    width: ${width};
  `,
  setHeigth: heigth => css`
    height: ${heigth} !important;
    overflow: scroll;
  `,
  setDirection: diretion => css`
    flex-direction: ${diretion};
  `,

  setTypeCardWhatsApp: type => css`
    ${type === "internal" &&
    css`
      background: #d9f6ca;
      color: #000;
      margin-left: auto;
      margin-right: 3%;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      box-shadow: 0px 3px 6px rgb(0 0 0 / 23%);
      text-align: right;
      &:before {
        margin-right: -13px !important;
        right: 0;
        border-color: #e7fcd8 transparent transparent transparent !important;
      }
    `}
    ${type === "notification" &&
    css`
      background: #d9f6ca;
      color: #000;
      margin-left: auto;
      margin-right: 3%;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      box-shadow: 0px 3px 6px rgb(0 0 0 / 23%);
      text-align: right;
      &:before {
        margin-right: -13px !important;
        right: 0;
        border-color: #e7fcd8 transparent transparent transparent !important;
      }
    `}
    ${type === "client" &&
    css`
      background: #fff;
      color: #000;
      margin-right: auto;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
      box-shadow: 0px 3px 6px rgb(0 0 0 / 23%);
      margin-left: 3%;
      text-align: left;
      &:before {
        left: -13px;
        right: auto;
        border-color: #fff transparent transparent transparent !important;
      }
    `}
  `,
};

export const ContainerRowItemContentWhatsApp = styled.div`
  padding: 15px 0 0 0;
  display: flex;
  border-radius: 5px;
  flex-direction: column;
  position: relative;
  margin: auto;
  overflow: hidden;
  width: 100%;
  height: 100%;

  ${({ padding }) => setItem.setPadding(padding)}
`;

export const ContainerRowItemContentWhatsAppMessages = styled.div`
  overflow: hidden;
  max-height: 400px;
  height: 100%;
  scrollbar-color: transparent transparent;
  transition: scrollbar-color 0.25s ease-in-out;

  overflow-y: auto;
  scrollbar-color: initial;
  &::-webkit-scrollbar {
    width: 4px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: #565656;
    border-radius: 20px;
    cursor: pointer;
  }

  ${S.ContainerRowItem} {
    width: 90%;
    padding: 10px;
    margin: 0 auto;
    margin-top: 20%;
  }
`;

export const ContainerRowItemContentWhatsAppFooterForm = styled.form`
  display: flex;
  flex-direction: row;
  bottom: 0;
  width: 94%;

  ${({ padding }) => setItem.setPadding(padding)}
`;

export const ContainerRowItemContentWhatsAppFooterInput = styled.div`
  background-color: #f3f3f3;
  width: 85%;
  border-radius: 50px;
  min-height: 45px;
  padding: 7px 10px 10px 15px;
  display: flex;
  justify-content: center;
  overflow: hidden;

  textarea {
    background: transparent;
    border: 0px;
    width: 100%;
    min-height: 30px;
    &::-webkit-scrollbar {
      width: 4px;
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background: #565656;
      border-radius: 20px;
      cursor: pointer;
    }
  }
`;

export const ContainerRowItemContentWhatsAppFooterBtn = styled.div`
  width: 15%;
  text-align: right;
`;

export const BtnSend = styled.button`
  background-color: #375e5f;
  width: 45px;
  border-radius: 60px;
  text-align: center;
  height: 45px;
  padding: 8px;
  float: right;
  margin-right: 4px;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 23%);
  &[disabled] {
    opacity: 0.6;
  }
`;

export const ContainerRowItemContentWhatsAppMessagesItem = styled.div`
  position: relative;
  max-width: 75%;
  padding: 7px 15px;
  margin-bottom: 5px;
  z-index: 999999;
  line-height: 13px;
  span {
    font-size: 11px;
  }

  &:before {
    content: " ";
    position: absolute;
    width: 0;
    height: 0;
    top: 0px;
    bottom: auto;
    border: 19px solid;
  }

  p {
    font-size: 14px;
    word-wrap: break-word;
  }
  ${({ type }) => setItem.setTypeCardWhatsApp(type)}
`;

export const ContainerRowItemContentWhatsAppInfo = styled.div`
  font-weight: bold;
  padding: 0 0 0 10px;
  background-color: #557b7c;
  span {
    color: #fff !important;
  }
`;

export const ContainerRowItemHeaderWhatsapp = styled.div`
  width: 100%;
  border-bottom: none;
  padding: 2px 0px 6px 15px;
  line-height: 30px;
  background-color: #375e5f;
`;

export const ContainerRowItemHeaderWhatsappTitle = styled.div`
  display: flex;
  flex-direction: row;
`;
export const ContainerRowItemHeaderWhatsappTitleItem = styled.div`
  ${({ align = "left" }) => setItem.setAlignItem(align)}
  ${({ margin }) => setItem.setMargin(margin)}
  ${({ cursor }) => setItem.setCursor(cursor)}
  width: 100%;
  h3 {
    color: #fff;
    font-size: 20px;
    font-weight: bold;
  }
  h5 {
    color: #fff;
    font-size: 15px;
    font-weight: bold;
  }
`;
export const ContainerRowItemHeaderWhatsappTitleSub = styled.h4`
  color: #fff;
`;
export const ContainerRowItemWhatsap = styled.div`
  ${({ direction, width = "50%", heigth: height, margin }) => css`
    background-color: #fff;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 23%);
    position: relative;
    ${setItem.setDirection(direction)}
    ${setItem.setWidth(width)}
    ${height && setItem.setHeigth(height)}
    ${setItem.setMargin(margin)}
    padding:0;
    border-radius: 5px;
    background: url("https://camo.githubusercontent.com/854a93c27d64274c4f8f5a0b6ec36ee1d053cfcd934eac6c63bed9eaef9764bd/68747470733a2f2f7765622e77686174736170702e636f6d2f696d672f62672d636861742d74696c652d6461726b5f61346265353132653731393562366237333364393131306234303866303735642e706e67")
      no-repeat center;
    background-size: cover;
    background-color: #d5d5d5;
    display: flex;
    flex-direction: column;
  `};
`;

export const breatheAnimation = css`
  opacity: 1;
`;
export const BtnSendFooterUl = styled.ul`
  position: absolute;
  margin-bottom: 50px;
  list-style: none;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  transition: all 0.3s;

  ${({ visible }) => {
    return css`
      visibility: ${visible ? "visible" : "hidden"};
    `;
  }}
`;

export const BtnSendFooterLi = styled.li`
  background-color: ${({ color }) => color || "#375e5f"};
  width: 45px;
  border-radius: 60px;
  text-align: center;
  height: 45px;
  padding: 8px 0 0 0;
  margin: 0 4px 5px 0;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 23%);
  svg {
    color: ${({ svgColor }) => svgColor || "#fff"};
  }
`;
