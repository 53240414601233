import styled from "styled-components";

export const StyledCredit = styled.div`
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-shadow: 0px 3px 6px #00000029;
  margin-bottom: 20px;
`;

export const StyledCreditHeader = styled.div`
  border-bottom: 1px solid #ccc;
  padding: 5px 10px;
  box-sizing: border-box;

  h1 {
    color: #1b66b0;
    font-size: 1.17em;
    font-weight: 500;
  }
  small {
    font-size: 15px;
    color: #596778;
    letter-spacing: 0px;
    margin-top: -5px;
    display: block;
  }
`;

export const StyledCreditValue = styled.div`
  color: #596778;
  font-size: 28px;
  padding: 10px 20px;
  padding: 10px 10px 0px 10px;
  box-sizing: border-box;

  .loading {
    margin: 20px 20px 20px 35%;
  }
`;

export const StyledCreditPercent = styled.div`
  display: grid;
  grid-template-columns: min-content auto;

  span {
    color: #596778;
    font-size: 28px;
  }

  p {
    color: #596778;
    font-size: 12px;
    line-height: 12px;
  }
`;

export const StyledSeparator = styled.div`
  width: 100%;
  height: 1px;
  background-color: #ccc;
  margin-top: 10px;
`;

export const StyledCreditPercentText = styled.div`
  width: 90px;
  padding: 10px;
`;

export const StyledGraphic = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 10px;
  box-sizing: border-box;

  div.graphic-1 {
    height: 18px;
    background-color: #d95d5b;
  }
  div.graphic-2 {
    height: 18px;
    background-color: #a2c659;
  }
`;

export const DatePickerContainer = styled.div`
  padding: 16px;
`;

export const ContainerLoading = styled.div``;
