import { LawNorms } from "constants/Types/Law.types";

/* eslint-disable class-methods-use-this */
export default class Mocks {
  getOrigin() {
    return [
      { code: "EI", description: "Entrada Industria" },
      { code: "ED", description: "Entrada Distribuidor" },
      { code: "ES", description: "Entrada Simples Nacional" },
      { code: "BO", description: "Bonificação" },
      { code: "TR", description: "Transferência" },
      { code: "TODAS", description: "Todas" },
    ];
  }

  getExitType() {
    return [
      { code: "SAC", description: "Saida Atacado Contribuinte" },
      { code: "SAS", description: "Saida Atacado Simples Nacional" },
      { code: "SVC", description: "Saida Varejo Contribuinte" },
      { code: "SNC", description: "Saida não contribuinte" },
      { code: "IND", description: "Industrialização" },
      { code: "TRA", description: "Transferência" },
      { code: "TODAS", description: "Todas" },
    ];
  }

  getCompanyType() {
    return [
      { code: "LR", description: "Lucro Real" },
      { code: "LP", description: "Lucro Presumido" },
      { code: "SN", description: "Simples Nacional" },
      { code: "PF", description: "Pessoa Física" },
      { code: "TODOS", description: "TODOS" },
    ];
  }

  getNorms() {
    return LawNorms;
  }

  getCst() {
    return {
      icms: {
        entrada: [
          { label: "Tributada integralmente.", value: "00" },
          {
            label:
              "Tributada e com cobrança do ICMS por substituição tributária.",
            value: "10",
          },
          { label: "Com redução de Base de Cálculo.", value: "20" },
          {
            label:
              "Isenta ou não tributada e com cobrança do ICMS por substituição tributária.",
            value: "30",
          },
          { label: "Isenta.", value: "40" },
          { label: "Não tributada.", value: "41" },
          { label: "Com suspensão.", value: "50" },
          { label: "Com diferimento.", value: "51" },
          {
            label: "ICMS cobrado anteriormente por substituição tributária.",
            value: "60",
          },
          {
            label:
              "Com redução da Base de Cálculo e cobrança do ICMS por substituição tributária.",
            value: "70",
          },
          { label: "Outras.", value: "90" },
        ],
        saida: [
          { label: "Tributada integralmente.", value: "00" },
          {
            label:
              "Tributada e com cobrança do ICMS por substituição tributária.",
            value: "10",
          },
          { label: "Com redução de Base de Cálculo.", value: "20" },
          {
            label:
              "Isenta ou não tributada e com cobrança do ICMS por substituição tributária.",
            value: "30",
          },
          { label: "Isenta.", value: "40" },
          { label: "Não tributada.", value: "41" },
          { label: "Com suspensão.", value: "50" },
          { label: "Com diferimento.", value: "51" },
          {
            label: "ICMS cobrado anteriormente por substituição tributária.",
            value: "60",
          },
          {
            label:
              "Com redução da Base de Cálculo e cobrança do ICMS por substituição tributária.",
            value: "70",
          },
          { label: "Outras.", value: "90" },
        ],
      },
      pis: {
        entrada: [
          {
            label:
              "Operação com Direito a Crédito - Vinculada Exclusivamente a Receita Tributada no Mercado Interno",
            value: "50",
          },
          {
            label:
              "Operação com Direito a Crédito - Vinculada Exclusivamente a Receita Não-Tributada no Mercado Interno",
            value: "51",
          },
          {
            label:
              "Operação com Direito a Crédito - Vinculada Exclusivamente a Receita de Exportação",
            value: "52",
          },
          {
            label:
              "Operação com Direito a Crédito - Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno",
            value: "53",
          },
          {
            label:
              "Operação com Direito a Crédito - Vinculada a Receitas Tributadas no Mercado Interno e de Exportação",
            value: "54",
          },
          {
            label:
              "Operação com Direito a Crédito - Vinculada a Receitas Não Tributadas no Mercado Interno e de Exportação",
            value: "55",
          },
          {
            label:
              "Operação com Direito a Crédito - Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno e de Exportação",
            value: "56",
          },
          {
            label:
              "Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita Tributada no Mercado Interno",
            value: "60",
          },
          {
            label:
              "Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita Não-Tributada no Mercado Interno",
            value: "61",
          },
          {
            label:
              "Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita de Exportação",
            value: "62",
          },
          {
            label:
              "Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno",
            value: "63",
          },
          {
            label:
              "Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas no Mercado Interno e de Exportação",
            value: "64",
          },
          {
            label:
              "Crédito Presumido - Operação de Aquisição Vinculada a Receitas Não-Tributadas no Mercado Interno e de Exportação",
            value: "65",
          },
          {
            label:
              "Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno e de Exportação",
            value: "66",
          },
          { label: "Crédito Presumido - Outras Operações", value: "67" },
          { label: "Operação de Aquisição sem Direito a Crédito", value: "70" },
          { label: "Operação de Aquisição com Isenção", value: "71" },
          { label: "Operação de Aquisição com Suspensão", value: "72" },
          { label: "Operação de Aquisição a Alíquota Zero", value: "73" },
          {
            label: "Operação de Aquisição sem Incidência da Contribuição",
            value: "74",
          },
          {
            label: "Operação de Aquisição por Substituição Tributária",
            value: "75",
          },
          { label: "Outras Operações de Entrada", value: "98" },
          { label: "Outras Operações", value: "99" },
        ],
        saida: [
          { label: "Operação Tributável com Alíquota Básica", value: "01" },
          {
            label: "Operação Tributável com Alíquota Diferenciada",
            value: "02",
          },
          {
            label:
              "Operação Tributável com Alíquota por Unidade de Medida de Produto",
            value: "03",
          },
          {
            label: "Operação Tributável Monofásica - Revenda a Alíquota Zero",
            value: "04",
          },
          {
            label: "Operação Tributável por Substituição Tributária",
            value: "05",
          },
          { label: "Operação Tributável a Alíquota Zero", value: "06" },
          { label: "Operação Isenta da Contribuição", value: "07" },
          { label: "Operação sem Incidência da Contribuição", value: "08" },
          { label: "Operação com Suspensão da Contribuição", value: "09" },
          { label: "Outras Operações de Saída", value: "49" },
        ],
      },
    };
  }

  getUF() {
    return [
      { description: "Acre", code: "AC" },
      { description: "Alagoas", code: "AL" },
      { description: "Amapá", code: "AP" },
      { description: "Amazonas", code: "AM" },
      { description: "Bahia", code: "BA" },
      { description: "Ceará", code: "CE" },
      { description: "Distrito Federal", code: "DF" },
      { description: "Espírito Santo", code: "ES" },
      { description: "Goiás", code: "GO" },
      { description: "Maranhão", code: "MA" },
      { description: "Mato Grosso", code: "MT" },
      { description: "Mato Grosso do Sul", code: "MS" },
      { description: "Minas Gerais", code: "MG" },
      { description: "Pará", code: "PA" },
      { description: "Paraíba", code: "PB" },
      { description: "Paraná", code: "PR" },
      { description: "Pernambuco", code: "PE" },
      { description: "Piauí", code: "PI" },
      { description: "Rio de Janeiro", code: "RJ" },
      { description: "Rio Grande do Norte", code: "RN" },
      { description: "Rio Grande do Sul", code: "RS" },
      { description: "Rondônia", code: "RO" },
      { description: "Roraima", code: "RR" },
      { description: "Santa Catarina", code: "SC" },
      { description: "São Paulo", code: "SP" },
      { description: "Sergipe", code: "SE" },
      { description: "Tocantins", code: "TO" },
    ];
  }

  getTypeActivities() {
    return [
      { value: "00", label: "Atividades Industriais, Comerciais e Serviços" },
      { value: "01", label: "Mercadoria para Revenda" },
      { value: "02", label: "Matéria-Prima" },
      { value: "03", label: "Embalagem" },
      { value: "04", label: "Produto em Processo" },
      { value: "05", label: "Produto Acabado" },
      { value: "06", label: "SubProduto" },
      { value: "07", label: "Produto Intermediário" },
      { value: "08", label: "Material de Uso e Consumo" },
      { value: "09", label: "Serviços" },
      { value: "10", label: "Outros Insumos" },
      { value: "11", label: "Outros" },
    ];
  }
}
