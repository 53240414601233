import React, { createContext, useContext, useCallback, useState } from "react";

export const StoreContext = createContext({});

export const useStoreContext = () => useContext(StoreContext);

export const defaultValues = {
  selectedSpecialTaxRegimes: {},
};

export const StoreProvider = ({ children }) => {
  const [certificate, setCertificate] = useState("");
  const [certificateAtual, setCertificateAtual] = useState();

  const [listSelectedUsersWallet, setListSelectedUsersWallet] = useState([]);

  const [specialTaxRegimes, setSpecialTaxRegimes] = useState(
    defaultValues.selectedSpecialTaxRegimes,
  );

  const addNewSpecialTaxRegime = useCallback(
    newTaxRegime =>
      setSpecialTaxRegimes(selectedSpecialTaxTegimes => ({
        ...selectedSpecialTaxTegimes,
        [newTaxRegime.id_law]: newTaxRegime,
      })),
    [setSpecialTaxRegimes],
  );

  const removeSpecialTaxRegime = useCallback(
    removedTaxRegimeId =>
      setSpecialTaxRegimes(selectedSpecialTaxTegimes =>
        Object.values(selectedSpecialTaxTegimes)
          .filter(
            specialTaxTegime => specialTaxTegime.id !== removedTaxRegimeId,
          )
          .reduce((a, v) => ({ ...a, [v.id_law]: v }), {}),
      ),
    [setSpecialTaxRegimes],
  );

  const loadSpecialTaxRegimes = useCallback(
    taxRegimes =>
      (taxRegimes || []).forEach(taxRegime => {
        if (taxRegime?.id_law?._id) {
          addNewSpecialTaxRegime({
            id_law: taxRegime.id_law._id,
            label: taxRegime.id_law.regime_name,
          });
        }
      }),
    [addNewSpecialTaxRegime],
  );

  return (
    <StoreContext.Provider
      value={{
        certificate,
        setCertificate,
        specialTaxRegimes,
        addNewSpecialTaxRegime,
        removeSpecialTaxRegime,
        loadSpecialTaxRegimes,
        listSelectedUsersWallet,
        setListSelectedUsersWallet,
        certificateAtual,
        setCertificateAtual,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
