import React from "react";

import { Space, ButtonOpenNewTag } from "components/structure";
import * as ROUTES from "constants/routes";

import * as S from "./LawsDescontinued.styles";

const getUrl = idLaw => {
  const domain = `${window.location.protocol}//${window.location.host}`;

  return `${domain}${ROUTES.LAWS.DETAIL(idLaw)}`;
};

export const LawsDescontinued = ({ idLaw }) => {
  return (
    <S.Container>
      <S.Header>
        <h3>Novo link de acesso a essa Lei/Grade</h3>
        <S.HeaderText>
          <label>{getUrl(idLaw)}</label>
          <ButtonOpenNewTag
            title="Abrir detalhe do NCM"
            toLink={ROUTES.LAWS.DETAIL(idLaw)}
          />
        </S.HeaderText>
      </S.Header>
      <S.Content>
        <S.SpaceWrapper>
          <Space />
        </S.SpaceWrapper>
      </S.Content>
    </S.Container>
  );
};
