import React, { useEffect, useState } from "react";
import { components } from "react-select";

import { regexCheckCnpjOrText } from "utils";

import * as S from "../styles";

export const InputSearch = ({
  margin,
  searchValue,
  onInputChange,
  ...rest
}) => {
  const [isCompleteCnpj, setIsCompleteCnpj] = useState({
    show: false,
    label: "",
  });
  const [maxCaracter, setMaxCaracter] = useState(100);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (rest && rest?.value?.length >= 4) {
        const check = regexCheckCnpjOrText(rest?.value);
        if (check.type === "number") {
          if (rest?.value.length < 14) {
            setIsCompleteCnpj({
              show: true,
              label: "Inferme o CNPJ completo",
              type: "inativo",
            });
            setMaxCaracter(14);
          }
          if (rest?.value.length === 14) {
            onInputChange(rest?.value);
            setIsCompleteCnpj({
              show: false,
              label: "",
            });
            setMaxCaracter(14);
          }
        }
        if (check.type === "text") {
          onInputChange(rest?.value);
          setIsCompleteCnpj({
            show: false,
            label: "",
          });
          setMaxCaracter(100);
        }
        if (check.type === "numbertext") {
          onInputChange(rest?.value);
          setIsCompleteCnpj({
            show: true,
            label: "Letras e Números",
            type: "warning",
          });
          setMaxCaracter(100);
        }
      }
      if (!rest?.value) {
        setIsCompleteCnpj({
          show: false,
          label: "",
          type: "",
        });
        onInputChange("");
        setMaxCaracter(100);
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [rest, onInputChange, setMaxCaracter]);

  if (rest?.isHidden) {
    return <components.Input {...rest} />;
  }

  return (
    <div style={{ border: `1px dotted none` }}>
      <components.Input
        {...rest}
        maxlength={maxCaracter}
        value={regexCheckCnpjOrText(rest?.value).value}
      />
      {isCompleteCnpj.show && (
        <S.BadgeSelect margin={margin} type={isCompleteCnpj.type}>
          {isCompleteCnpj.label}
        </S.BadgeSelect>
      )}
    </div>
  );
};
