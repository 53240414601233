import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  transition: all 0.4s ease;
  transform-origin: center;
  transform: ${props => (props.isOpen ? "rotateZ(0deg)" : "rotateZ(-180deg)")};
`;

export const AccordionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  padding: 16px;

  background-color: ${props => (props.isOpen ? "#596778" : "#e5e5e5")};

  border-bottom: ${props => (props.isOpen ? "none" : "1px solid #596778")};

  color: ${props => (props.isOpen ? "#fff" : "#8C8B8B")};

  & > div {
    display: flex;
    align-items: center;

    p {
      margin-right: 8px;
    }
  }
`;

export const AccordionBody = styled.div`
  transition: all 0.3s;
  overflow: hidden;
  transform-origin: top;
  width: 100%;
  max-height: ${props => (props.isOpen ? "100000px" : "0px")};
  transform: ${props => (props.isOpen ? "scaleY(1)" : "scaleY(0)")};

  border-bottom: ${props => props.isOpen && "2px solid #596778"};
`;
