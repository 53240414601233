import styled from "styled-components";

export const Container = styled.div`
  width: 300px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.7);

  display: flex;
  flex-direction: column;
`;

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 54px;
  padding: 16px;

  background-color: #f6b000;
  color: #fff;

  & > div {
    display: flex;
    align-items: center;
    overflow: hidden;

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    svg {
      flex-shrink: 0;
      margin-right: 8px;
    }
  }

  & > svg {
    flex-shrink: 0;
    position: relative;
    z-index: 2;
    cursor: pointer;
  }

  .buttonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #fff;
    padding: 8px;
    cursor: pointer;
    flex-shrink: 0;

    svg {
      margin: 0;
      fill: #f6b000;
    }
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;

  & > div {
    display: flex;
    align-items: center;

    input {
      margin-right: 8px;
    }
  }

  svg {
    position: relative;
    z-index: 2;
    cursor: pointer;
  }

  svg:not(:last-of-type) {
    margin-right: 8px;
  }
`;
