import React from "react";
import * as Sstore from "./styles";

export const StoreLogo = ({
  control,
  logo,
  setLogo,
  actualLogo,
  setActualLogo,
}) => {
  const handleImageUpload = event => {
    setLogo(event);
  };
  const getDroppedImage = event => {
    setLogo(event);
  };
  return (
    <>
      <Sstore.UploadFile>
        <label>Logotipo</label>
        <Sstore.UploadFileRow>
          <Sstore.InputFileItem
            type="file"
            name="logo"
            control={control}
            onChange={e => handleImageUpload(e.target.files[0])}
            onDrop={e => getDroppedImage(e.dataTransfer.files[0])}
            accept="image/jpeg, image/jpg, image/png"
          />
          <Sstore.InputAbsolute isOver={logo || false}>
            {logo || actualLogo ? (
              <button
                type="button"
                onClick={() => {
                  setLogo("");
                  setActualLogo("");
                }}
              >
                REMOVER
              </button>
            ) : (
              <Sstore.InputAbsoluteButton type="button">
                PROCURAR
              </Sstore.InputAbsoluteButton>
            )}
            {logo || actualLogo ? (
              <Sstore.InputAbsoluteFileDescription>
                {logo.name || actualLogo.name || logo || actualLogo}
              </Sstore.InputAbsoluteFileDescription>
            ) : (
              <Sstore.InputAbsoluteFileDescription>
                ou Arraste aqui
              </Sstore.InputAbsoluteFileDescription>
            )}
          </Sstore.InputAbsolute>
        </Sstore.UploadFileRow>
      </Sstore.UploadFile>
    </>
  );
};
