import colors from "./tokens/colors";
import spacing from "./tokens/spacing";
import shadows from "./tokens/shadows";

export default {
  colors,
  spacing,
  shadows,
  sizes: {},
  font: {
    family: "Cairo",
  },
  border: {
    default: "1px solid #c1c1c1",
    divider: "1px solid #bfc0c0",
  },
};
