import styled, { css } from "styled-components";
import { setItem } from "../../styles";

export const ContainerRowItemBlogContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: collumn;
  padding: 10px 0 0 0;
`;

export const ContainerRowItemBlogRowItem = styled.div`
  ${({ direction, width = "50%", heigth = "517px", margin }) => css`
    background-color: #fff;
    ${setItem.setDirection(direction)}
    ${setItem.setWidth(width)}
    ${setItem.setHeigth(heigth)}
    ${setItem.setMargin(margin)}
    padding: 0px 3px 15px 3px;
    border-radius: 5px;
    overflow: scroll;
  `};
`;

export const Divider = styled.div`
  width: 2px;
  align-self: stretch;
  background-color: #d3d3d3;
  margin: 0 36px;
  height: 500px;
`;

export const ContainerRowItemBlogItemFull = styled.div`
  width: 100%;
  text-align: center;
  img {
    width: 400px;
  }
`;

export const ContainerRowItemBlogItem = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction || `row`};
  border-radius: 10px;
  margin: 0 0 5px 0;
  flex: 1 0 21%;
  &:hover {
    background-color: #d3d3d3;
  }
  img {
    width: 130px;
  }
`;

export const ContainerRowItemBlogItemTitle = styled.h3`
  font-size: 20px;
`;

export const ContainerRowItemBlogItemTitleList = styled.div`
  ${({ direction }) => css`
    width: 100%;
    font-size: 15px;
    padding: 10px 0 0 10px;
    overflow-x: hidden;
    ${setItem.setDirection(direction)}
  `};
`;

export const ContainerRowItemBlogItemTitleListDate = styled.div`
  background: #39672e;
  width: 188px;
  text-align: center;
  border-radius: 50px;
  font-size: 13px;
  color: #fff;
`;
export const ContainerRowItemBlogItemTitleListBtnList = styled.div`
  ${({ margin }) => css`
    ${setItem.setMargin(margin)}
  `};
`;

export const ContainerRowItemBlogItemTitleListBtn = styled.span`
  ${({ margin }) => css`
    background: #fff;
    border: 1px solid #39672e;
    width: 80px;
    text-align: center;
    border-radius: 50px;
    font-size: 13px;
    color: #39672e;
    float: right;
    cursor: pointer;
    background-color: #fff;
    ${setItem.setMargin(margin)}
  `};
`;
export const ContainerRowItemBlogItemTitleListBadge = styled.div`
  background-color: #fff;
  color: #446635;
  font-size: 13px;
  min-width: 169px;
  border: 1px solid #446635;
  border-radius: 50px;
  padding: 2px 0 0 0;
  margin: 0 5px 0 0px;
  text-align: center;
`;

export const ContainerRowItemBlogItemTitleListBad = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-x: auto;
  padding: 10px 0 15px 0;
`;
