import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;

  > header {
    display: flex;
    align-items: center;
    margin-left: 8px;
  }
`;

export const Content = styled.div`
  padding: 0px 10px 10px 10px;

  strong {
    font-weight: 700;
  }

  form {
    display: flex;
    width: 100%;
  }

  .flex {
    display: flex;
  }

  .flex-column {
    display: flex;
    flex-direction: column;
  }

  .flex-tax {
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #596778;
    max-width: 250px;
    width: 250px;
    border-radius: 4px;
    padding: 8px 12px;

    li {
      display: flex;
      align-items: center;
    }

    input {
      width: 20px;
      height: 20px;
      margin-right: 12px;
    }

    label {
      font-size: 14px;
      color: #596778;
    }
  }

  .flatCard {
    margin: 20px 0;

    > header {
      border-radius: 0;
      padding: 4px 10px;
      p {
        font-size: 12px;
      }
    }

    section {
      border-radius: 0;
    }

    hr {
      margin-top: 20px;
    }
  }

  .card-subtitle {
    font-size: 18px;
    line-height: 22px;
    color: #596778;
  }

  .input-container {
    display: flex;
    flex-flow: row wrap;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
  }

  .scenery-container {
    display: flex;

    .tax-tag {
      margin: 8px 8px 0 0;
    }
  }

  .max-width-400 {
    gap: 14px;
    max-width: 400px;
  }

  .tag-margin {
    margin-top: 37px;
  }

  .checkbox-container {
    display: flex;
    flex: 0.6;
    align-items: center;
    margin: 35px 10px 0 10px;

    label {
      margin-left: 8px;
    }
  }

  .cnae-container {
    label {
      margin-left: 10px;
      font-size: 14px;
      color: #596778;
    }
  }

  .tag-container {
    display: flex;
    flex-flow: row wrap;

    .tag-item {
      margin: 8px 0 0 4px;
    }
  }

  .submit {
  }
`;

export const Button = styled.button`
  margin: 26px 0 0 12px;
  background-color: #adc86a;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  padding: 8px;
  border-radius: 5px;
  align-self: flex-start;
  margin-right: auto;
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

export const InputContainer = styled.div`
  display: flex;
  flex: ${props => (props.flex ? props.flex : 1)};
  flex-direction: column;
  margin-top: 12px;
  max-width: ${props => (props.maxWidth ? props.maxWidth : "290px")};

  & + & {
    margin-left: 12px;
  }

  label,
  input,
  select,
  textarea {
    font-size: 14px;
    color: #596778;
  }

  select {
    height: 36px;
    background-color: #fff;
  }

  input,
  select,
  textarea {
    border: 1px solid #596778;
    border-radius: 5px;
    padding: 4px 10px;
  }

  input:disabled {
    background: #e9e9e9;
  }

  label {
    margin-left: 10px;
  }
`;

export const StyledNorms = styled.div`
  ul {
    list-style: none;
  }
`;

export const CardWithLateralHeader = styled.div`
  display: flex;
  align-items: stretch;
  width: 100%;

  margin-top: 20px;
  margin-left: ${props => (props.marginLeft ? props.marginLeft : "")};

  min-height: ${props => (props.height ? props.height : "125px")};

  header {
    display: flex;
    flex-basis: 100%;
    flex: 1;
    justify-content: center;
    align-self: stretch;
    align-items: center;
    writing-mode: vertical-lr;
    transform: rotate(-180deg);

    font-size: 15px;
    color: #fff;
    font-weight: 700;
    width: 50px;

    border: 1px solid #1b66b0;
    border-radius: 4px;
    background-color: #1b66b0;

    button {
      display: flex;
      background: none;
      margin: 5px;

      svg {
        color: #fff;
      }
    }
  }

  main {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;

    padding: 8px;
    border: 1px solid #1b66b0;
    border-radius: 4px;
  }
`;

export const FooterActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 16px;
`;

export const ContainerRow = styled.div`
  margin-bottom: 1rem;
`;
