import React from "react";

import { OptionsComponent } from "components/contexts/stores/SelectSearch/Tooltip";
import { BtnRemove } from "components/contexts/shared";
import { useAssessorClientAction } from "useCases/assessor";

import * as S from "./AssessoresClients.styles";

export const AssessoresClients = ({ data = [], idUser, isDisabled }) => (
  <S.List>
    {data.map((store, i) => (
      <S.ListItem key={`${store?.id_store._id}-${i}`}>
        <S.ListItemActions>
          <BtnRemove
            isDisabled={isDisabled}
            id={{ idUser, idCli: store?.id_store._id }}
            hook={useAssessorClientAction}
            module="Cliente Assessor"
          />
        </S.ListItemActions>
        <OptionsComponent data={store?.id_store ?? {}} />
      </S.ListItem>
    ))}
  </S.List>
);
