import styled from "styled-components";

export const Container = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 2px;
  padding-left: 0px;
  padding-right: 0px;
  user-select: none;
  :hover {
    background-color: ${({ withHover, theme }) =>
      withHover && theme.colors.gray50};
  }
`;

export const Selection = styled.div`
  align-items: center;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: center;
  margin: 0 2px 0 4px;
  width: 24px;

  :hover {
    background-color: ${({ theme }) => theme.colors.gray200};
    border-radius: 12px;
  }
`;

export const Checkbox = styled.div`
  border: 2px solid ${({ theme }) => theme.colors.gray500};
  border-radius: 2px;
  height: 14px;
  opacity: 1;
  width: 14px;
`;

export const CheckboxSelected = styled.div`
  border: 2px solid ${({ theme }) => theme.colors.checkbox.selected};
  background-color: ${({ theme }) => theme.colors.checkbox.selected};
  border-radius: 2px;
  height: 14px;
  opacity: 1;
  width: 14px;
  display: flex;
  padding: 0px;
`;

export const Label = styled.label`
  color: ${({ theme }) => theme.colors.slateGray};
  cursor: pointer;
  font-size: 12px;
  padding: 0 0 1px 3px;
  width: 136px;
  line-height: 13px;
`;

export const Bold = styled.label`
  color: ${({ theme }) => theme.colors.slateGray};
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
`;
