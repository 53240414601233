import React from "react";
import { Card as CardDesignerSystem } from "@mix/mixfiscal-designsystem";
import { Card as CardLocal } from "./CardLocal";
import * as S from "./Card.styles";

export const CardLateral = ({ title, children, ...props }) => (
  <S.CardWithLateralHeader {...props}>
    <S.CardLateralHeader>{title}</S.CardLateralHeader>
    <S.CardLateralMain>{children}</S.CardLateralMain>
  </S.CardWithLateralHeader>
);

export const Card = ({ type = "default", ...props }) => {
  if (type === "lateral") {
    return <CardLateral {...props} />;
  }

  if (type === "local") {
    return <CardLocal {...props} />;
  }

  return <CardDesignerSystem {...props} />;
};
