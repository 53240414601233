import React from "react";
// import { Card } from "@mix/mixfiscal-designsystem";
import { AssessoresList as AssessoresListComponent } from "components/contexts/assessores";
import { Button, Card } from "components/structure";
import { ASSESSORES } from "constants/routes";

import * as S from "./styles";

export const AssessoresList = () => {
  return (
    <>
      <S.Header />
      <S.Container>
        <S.ContainerResult>
          <Card type="local" textColor="#fff" title="RESULTADOS">
            <S.ContainerHeader>
              <S.ContainerHeaderAction>
                <Button label="Novo " toLink={ASSESSORES.CREATE} />
              </S.ContainerHeaderAction>
            </S.ContainerHeader>
            <AssessoresListComponent />
          </Card>
        </S.ContainerResult>
      </S.Container>
    </>
  );
};
