import React, { useState, useCallback } from "react";
import { Card } from "@mix/mixfiscal-designsystem";
import { useApp } from "context/AppContext";
import TemplatesCard from "../../componentes/TemplatesCard";
import TaskDetails from "../../componentes/TaskDetails";
import TaskFilters from "../../componentes/TaskFilters";
import userTasks from "../../hooks/useTasks";
import { Container, Content, RowFiltes } from "./styles";

const CheckLinkTasks = () => {
  const { app } = useApp();

  const { tasks: templates, getAll, update } = userTasks();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [selectedTaskIndex, setSelectedTaskIndex] = useState(null);

  const handleSave = async ({ _id, tasks }) => {
    setIsOpen(false);

    await update(_id, { _id, tasks });
    await getAll({});

    setSelectedTemplate({});
    setSelectedTaskIndex(null);
  };

  const handleSubmit = useCallback(
    async filters => {
      await getAll({ filters });
    },
    [getAll],
  );

  return (
    <Container>
      <Content>
        <section>
          <TaskFilters app={app} onSubmit={handleSubmit} />
        </section>
        <section
          style={{ position: "relative", overflow: "auto", width: "100%" }}
        >
          <Card
            className="container-card-drag-drop"
            headerColor={!app.appBusinessIntelligence ? "#256925" : "#17456B"}
            textColor="#FFF"
            title="TAREFAS"
            titleIcon="divergenciafiscal"
          >
            <RowFiltes className="row">
              <div>
                Exibindo
                <b>{` ${templates.length} `}</b>
                tarefas
              </div>
              <div>
                {/* <label>
                  Visualizar somente
                  <b>minhas tarefas</b>
                </label>
                <label>
                  Visualizar
                  <b>kanban</b>
                </label> */}
              </div>
            </RowFiltes>

            <div className="container-card-tasks">
              {templates.length ? (
                templates.map((template, index) => (
                  <TemplatesCard
                    key={index}
                    template={template}
                    onClick={(t, taskIndex) => {
                      setSelectedTemplate(t);
                      setSelectedTaskIndex(taskIndex);
                      setIsOpen(true);
                    }}
                  />
                ))
              ) : (
                <h2>Não existem tarefas</h2>
              )}
            </div>
          </Card>

          <TaskDetails
            isOpen={isOpen}
            template={selectedTemplate}
            taskIndex={selectedTaskIndex}
            onClose={() => {
              setSelectedTemplate({});
              setSelectedTaskIndex(null);
              setIsOpen(false);
            }}
            onSave={handleSave}
          />
        </section>
      </Content>
    </Container>
  );
};

export default CheckLinkTasks;
