import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useTributesConsult } from "useCases/productsConsult";
import * as S from "./styles";
import { origem, ufEntrada, saida, ufSaida, regime } from "./options";

function TributeMain({ dataProduct, dataTribute, dataProductId }) {
  const getUfEntrada = base => {
    const ufE = base?.data?.icmsCompra?.uf.toUpperCase();
    const resultUfCompra = ufEntrada.find(item => item.value === ufE);
    return resultUfCompra;
  };
  const getUfSaida = base => {
    const ufS = base?.data?.icmsVenda?.uf.toUpperCase();
    const resultUfSaida = ufSaida.find(item => item.value === ufS);
    return resultUfSaida;
  };

  const getSaida = base => {
    const sai = base?.data?.icmsVenda?.operacao.toUpperCase();
    const resultSaida = saida.find(item => item.value === sai);
    return resultSaida;
  };

  const getOrigem = base => {
    const orig = base?.data?.icmsCompra?.operacao.toUpperCase();
    const resultOrigem = origem.find(item => item.value === orig);
    return resultOrigem;
  };

  const getRegime = base => {
    const reg = base?.data?.pisCofins?.regime.toUpperCase();
    const resultRegime = regime.find(item => item.value === reg);
    return resultRegime;
  };

  const [, setSelectedCenario] = useState({});
  const [selectedUfEntrada, setSelectedUfEntrada] = useState(
    getUfEntrada(dataTribute),
  );
  const [selectedSaida, setSelectedSaida] = useState(getSaida(dataTribute));
  const [selectedUfSaida, setSelectedUfSaida] = useState(
    getUfSaida(dataTribute),
  );
  const [selectedEmpresa, setSelectedEmpresa] = useState(
    getRegime(dataTribute),
  );
  const [selectedOrigem, setSelectedOrigem] = useState(getOrigem(dataTribute));

  const { data: tributeConsult, fetch: fetchTributeConsult } =
    useTributesConsult(dataProductId?.id_prod);

  const icmsCompra =
    dataTribute?.data.icmsCompra || tributeConsult?.data.icmsCompra;
  const pisCofins =
    dataTribute?.data.pisCofins || tributeConsult?.data.pisCofins;
  const icmsVenda =
    dataTribute?.data.icmsVenda || tributeConsult?.data.icmsVenda;

  const handleNewCenario = () => {
    fetchTributeConsult({
      origem: selectedOrigem?.value,
      ufEntrada: selectedUfEntrada?.value,
      ufSaida: selectedUfSaida?.value,
      saida: selectedSaida?.value,
      regimeTributario: selectedEmpresa?.value,
    });
    setSelectedCenario();
  };

  useEffect(() => {
    if (tributeConsult?.data) {
      setSelectedUfEntrada(getUfEntrada(tributeConsult));
      setSelectedSaida(getSaida(tributeConsult));
      setSelectedUfSaida(getUfSaida(tributeConsult));
      setSelectedEmpresa(getRegime(tributeConsult));
      setSelectedOrigem(getOrigem(tributeConsult));
    }
  }, [tributeConsult]);

  return (
    <>
      <S.Scenario>
        <div className="l1c1">
          <p>CENÁRIO</p>
        </div>
        <div className="l1c2">
          <p>Origem</p>
        </div>
        <div className="l2c2">
          <p>
            <Select
              defaultValue={selectedOrigem}
              options={origem}
              className="select-providers"
              name="operacao_e"
              data="data"
              placeholder="Selecione"
              onChange={setSelectedOrigem}
            />
          </p>
        </div>
        <div className="l1c3">
          <p>Uf Entrada</p>
        </div>
        <div className="l2c3">
          <p>
            <Select
              defaultValue={selectedUfEntrada}
              options={ufEntrada}
              className="select-providers"
              name="operacao_e"
              data="data"
              placeholder="Selecione"
              onChange={setSelectedUfEntrada}
            />
          </p>
        </div>
        <div className="l1c4">
          <p>Saída</p>
        </div>
        <div className="l2c4">
          <p>
            <Select
              defaultValue={selectedSaida}
              options={saida}
              className="select-providers"
              name="operacao_e"
              data="data"
              placeholder="Selecione"
              onChange={setSelectedSaida}
            />
          </p>
        </div>
        <div className="l1c5">
          <p>Uf Saída</p>
        </div>
        <div className="l2c5">
          <p>
            <Select
              defaultValue={selectedUfSaida}
              options={ufSaida}
              className="select-providers"
              name="operacao_e"
              data="data"
              placeholder="Selecione"
              onChange={setSelectedUfSaida}
            />
          </p>
        </div>
        <div className="l1c6">
          <p>Regime Tributário</p>
        </div>
        <div className="l2c6">
          <p>
            <Select
              defaultValue={selectedEmpresa}
              options={regime}
              className="select-providers"
              name="operacao_e"
              data="data"
              placeholder="Selecione"
              onChange={setSelectedEmpresa}
            />
          </p>
        </div>
        <div className="l1c7">
          <button type="button" onClick={handleNewCenario}>
            LOCALIZAR!
          </button>
        </div>
      </S.Scenario>
      <S.CompraVendaContainer>
        <div className="icms">
          <p>ICMS-COMPRA</p>
        </div>
        <div className="cstcp">
          <p>CST</p>
        </div>
        <div className="aliqcp">
          <p>Alíquota</p>
        </div>
        <div className="valcstcp">
          <p>{dataProduct === undefined ? "-" : icmsCompra?.cst}</p>
        </div>
        <div className="valaliqcp">
          <p>{dataProduct === undefined ? "-" : icmsCompra?.alq[0]}</p>
        </div>

        <div className="piscofins">
          <p>PIS/COFINS COMPRA</p>
        </div>
        <div className="cstpis">
          <p>CST</p>
        </div>
        <div className="aliqpis">
          <p>Alíquota</p>
        </div>
        <div className="valcstpis">
          <p>
            {/* Deve efetuar somar do pis e cofins? */}
            {dataProduct === undefined ? "-" : pisCofins?.piscofins_cst_e}
          </p>
        </div>
        <div className="valaliqpis">
          <p>{dataProduct === undefined ? "-" : pisCofins?.cofins_alq_e[0]}</p>
        </div>

        <div className="icmsvd">
          <p>ICMS - VENDA</p>
        </div>
        <div className="cstvd">
          <p>CST</p>
        </div>
        <div className="aliqvd">
          <p>Alíquota</p>
        </div>
        <div className="valcstvd">
          <p>{dataProduct === undefined ? "-" : icmsVenda?.cst}</p>
        </div>
        <div className="valaliqvd">
          <p>{dataProduct === undefined ? "-" : icmsVenda?.alq[0]}</p>
        </div>

        <div className="pisvd">
          <p>PIS/COFINS VENDA</p>
        </div>
        <div className="cstpisvd">
          <p>CST</p>
        </div>
        <div className="aliqpisvd">
          <p>Alíquota</p>
        </div>
        <div className="valcstpisvd">
          <p>{dataProduct === undefined ? "-" : pisCofins?.piscofins_cst_s}</p>
        </div>
        <div className="valaliqpisvd">
          <p>{dataProduct === undefined ? "-" : pisCofins?.cofins_alq_e[0]}</p>
        </div>
      </S.CompraVendaContainer>
      <S.ConstitutionContainer>
        <div className="l1c1">
          <p>CONSTITUIÇÃO E NORMAS</p>
        </div>
        <div className="l1c2">
          <p>
            Lei ICMS&nbsp;
            <b>Compra</b>
          </p>
        </div>
        <div className="l1c3">
          <p>
            Lei PIS/COFINS&nbsp;
            <b>Compra</b>
          </p>
        </div>
        <div className="l2c2">
          <p>
            {" "}
            {dataProduct === undefined ? "-" : icmsCompra?.fundamento_legal[0]}
          </p>
        </div>
        <div className="l2c3">
          <p>
            {dataProduct === undefined ? "-" : pisCofins?.fundamento_legal[0]}
          </p>
        </div>

        <div className="l3c2">
          <p>
            Lei ICMS&nbsp;
            <b>Venda</b>
          </p>
        </div>
        <div className="l3c3">
          <p>
            Lei PIS/COFINS&nbsp;
            <b>Venda</b>
          </p>
        </div>
        <div className="l4c2">
          <p>
            {" "}
            {dataProduct === undefined ? "-" : icmsVenda?.fundamento_legal[0]}
          </p>
        </div>
        <div className="l4c3">
          <p>
            {dataProduct === undefined ? "-" : pisCofins?.fundamento_legal[0]}
          </p>
        </div>
      </S.ConstitutionContainer>
    </>
  );
}
export default TributeMain;
