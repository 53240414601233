const stripNumber = value => {
  if (!value) return 0;
  const rawValue = value.toString().replace(/[a-zA-Z$\s]/gi, "");

  if (+rawValue) {
    return +(+rawValue).toFixed(2);
  }

  const integer = rawValue
    .toString()
    .replace(/(?:[,|.]\d{0,2}$)/gi, "")
    .replace(/\D/gi, "")
    .padStart(2, "0");
  const cents = rawValue
    .toString()
    .replace(/((.*)(?=[,|.]\d{0,2}$))/gi, "")
    .replace(/,/gi, ".");

  const validatedCents = cents.includes(".") ? cents.padEnd(3, "0") : "";

  return +`${integer}${validatedCents}`;
};

export const add = (x, y) => {
  const formatedX = stripNumber(x);
  const formatedY = stripNumber(y);
  return formatedX + formatedY;
};

export const subtract = (x, y) => {
  const formatedX = stripNumber(x);
  const formatedY = stripNumber(y);
  return formatedX - formatedY;
};

export const multiply = (x, y) => {
  const formatedX = stripNumber(x);
  const formatedY = stripNumber(y);
  return formatedX * formatedY;
};

export const divide = (x, y) => {
  const formatedX = stripNumber(x);
  const formatedY = stripNumber(y);
  return formatedX / formatedY;
};

export const average = (x, y) => {
  const formatedX = stripNumber(x);
  const formatedY = stripNumber(y);
  return (formatedX + formatedY) / 2;
};

export const roundDown = x => {
  const formated = stripNumber(x);
  return Math.floor(formated);
};

export const roundUp = x => {
  const formated = stripNumber(x);
  return Math.ceil(formated);
};

export const compare = (x, y) => {
  if (!x || !y) return null;
  if (
    (typeof x === "string" || typeof x !== "number") &&
    (typeof y === "string" || typeof y === "number")
  ) {
    return x.toString() === y.toString();
  }
  if (typeof x === "boolean" && typeof y === "boolean") {
    return x === y;
  }
  return false;
};

export const findAndReplace = (x, y) => {
  if (x) {
    const stringValue = x.toString();
    const { findValue, replaceValue } = y;
    const regex = new RegExp(findValue, "gi");
    return stringValue.replace(regex, replaceValue);
  }
  return null;
};

export const sumAll = apiRows => {
  const sum = apiRows.reduce((acc, cur) => {
    if (typeof cur === "string" || typeof cur === "number") {
      const formated = stripNumber(cur);
      const newValue = Number.isNaN(+formated) ? 0 : +stripNumber(cur);
      return acc + newValue;
    }
    return acc;
  }, 0);

  return sum.toFixed(2);
};

export const averageAll = apiRows => {
  const sum = apiRows.reduce((acc, cur) => {
    if (typeof cur === "string" || typeof cur === "number") {
      const formated = stripNumber(cur);
      const newValue = Number.isNaN(+formated) ? 0 : +stripNumber(cur);
      return acc + newValue;
    }
    return acc;
  }, 0);

  return (sum / apiRows.length).toFixed(2);
};
