/* eslint-disable no-unreachable */
import React, {
  useState,
  createContext,
  useCallback,
  useContext,
  useRef,
  useEffect,
} from "react";
import {
  addEdge,
  useStoreState,
  isNode,
  getIncomers,
} from "react-flow-renderer";
import { v4 as uuidv4 } from "uuid";
import { produce } from "immer";

import * as operationMethods from "../components/NodeBasedDragAndDrop/Operations";
import { runRecursion } from "../utils/recursion";
import { useAuth } from "./AuthContext";
// import authenticationApi from "../services/authenticationApi";
import templatesApi from "../services/templatesApi";

const FlowContext = createContext({});

const useFlow = () => {
  const context = useContext(FlowContext);
  if (!context)
    throw new Error("useFlow precisa ser utilizado dentro de um FlowProvider");
  return context;
};

const FlowProvider = ({ children }) => {
  const [elements, setElements] = useState([]);
  const [previewData, setPreviewData] = useState([]);
  const [selectedFlow, setSelectedFlow] = useState(null);

  const [currentAdvancedOperationId, setCurrentAdvancedOperationId] =
    useState("");

  const [selectedElementToPreview, setSelectedElementToPreview] = useState({
    label: "",
    elementId: "",
    fileId: "",
    selectedColumns: [],
  });
  const [isPreviewingData, setIsPreviewingData] = useState(false);
  // const [generalPreviewGuide, setGeneralPreviewGuide] = useState(null);
  const [isUpdatingElements, setIsUpdatingElements] = useState(false);

  const [isAttachingFileToNode, setIsAttachingFileToNode] = useState(false);
  const [selectedCallToActionElementId, setSelectedCallToActionElementId] =
    useState(null);

  const currentState = useRef(null);
  const { user: { _id } = {} } = useAuth();

  const Observer = () => {
    const nodes = useStoreState(state => [...state.nodes, ...state.edges]);
    currentState.current = nodes;
    return null;
  };

  const handleUpdateElementsPosition = () => {
    const clonedElements = JSON.parse(JSON.stringify(currentState.current));

    const updatedElements = clonedElements.map(updatedElement => {
      if (updatedElement.position) {
        updatedElement.position = updatedElement?.__rf?.position;
      }
      delete updatedElement.__rf;
      return updatedElement;
    });
    setElements(updatedElements);
    return updatedElements;
  };

  const handleUpdateSelectedField = ({ id, fieldIndex }) => {
    const updatedState = produce(elements, draft => {
      const elementIndex = draft.findIndex(element => element.id === id);

      if (fieldIndex === "all") {
        elements?.[elementIndex].data.fields.forEach((_, index) => {
          draft[elementIndex].data.fields[index].isSelected = true;
        });
      } else if (fieldIndex === "none") {
        elements?.[elementIndex].data.fields.forEach((_, index) => {
          draft[elementIndex].data.fields[index].isSelected = false;
        });
      } else if (draft[elementIndex].data.fields?.[fieldIndex]) {
        draft[elementIndex].data.fields[fieldIndex].isSelected =
          !draft[elementIndex].data.fields[fieldIndex].isSelected;
      }
    });

    setElements(updatedState);
  };

  const handleAddNode = ({
    label,
    nodeType = "custom",
    dataType,
    fields,
    register,
    selectedTemplateFileId,
    selectedFileColumnsIndex,
  }) => {
    const newElementId = uuidv4();
    setElements(prevState => {
      return [
        ...(prevState ?? []),
        {
          id: newElementId,
          type: nodeType,
          data: {
            label,
            dataType,
            fields,
            register,
            selectedTemplateFileId,
            selectedFileColumnsIndex,
          },
          position: { x: 0, y: 0 },
        },
      ];
    });
  };

  const handleRemoveNode = id => {
    const filteredElements = elements.filter(element => {
      if (element.id.includes("reactflow__edge")) {
        return !(element.source === id || element.target === id);
      }
      return element.id !== id;
    });
    setElements(filteredElements);
  };

  const handleConnectNode = params => {
    if (
      params.targetHandle === "firstHandle" ||
      params.targetHandle === "secondHandle"
    ) {
      const updatedState = produce(elements, draft => {
        const targetIndex = elements.findIndex(
          element => element.id === params.target,
        );
        draft[targetIndex].data[`${params.targetHandle}Source`] = params.source;
      });
      return setElements(() => addEdge(params, updatedState));
    }
    setElements(prevState => addEdge(params, prevState));
  };

  const handleClearOperationNode = ({ currentElements, handleId, nodeId }) => {
    return produce(currentElements, draft => {
      const elementIndex = draft.findIndex(
        draftElement => draftElement.id === nodeId,
      );
      draft[elementIndex].data[`${handleId}Source`] = [];
    });
  };

  const handleRemoveConnection = (_, { nodeId, handleId, handleType }) => {
    let hasToClearOperationNode = false;
    const filteredElements = elements.filter(el => {
      if (handleType === "target") {
        if (el.target === nodeId) {
          if (el.targetHandle === handleId) {
            hasToClearOperationNode = true;
          }
          return el.targetHandle !== handleId;
        }
      }
      return el;
    });

    if (hasToClearOperationNode) {
      const filteredAndClearedElements = handleClearOperationNode({
        currentElements: filteredElements,
        handleId,
        nodeId,
      });
      return setElements(filteredAndClearedElements);
    }
    return setElements(filteredElements);
  };

  const handleSaveFlow = (flowName, type) => {
    const updatedState = handleUpdateElementsPosition();

    if (selectedFlow) {
      templatesApi
        .put(`/flows/flow/${selectedFlow?._id}`, {
          name: flowName,
          body: updatedState,
          isDraft: type === "draft",
          userId: _id,
        })
        .then(({ data: { item } }) => {
          setSelectedFlow(item);
        });
    } else {
      templatesApi
        .post("/flows/flow", {
          name: flowName,
          body: updatedState,
          isDraft: type === "draft",
          userId: _id,
        })
        .then(({ data: { item } }) => {
          setSelectedFlow(item);
        });
    }
  };

  const handleLoadFlow = loadedFlow => {
    setElements(() => loadedFlow?.body);
    setSelectedFlow(loadedFlow);
  };

  const handleOpenSinglePreview = async ({
    id,
    selectedColumns,
    dataType,
    label,
    register,
    selectedTemplateFileId,
    selectedFileColumnsIndex,
  }) => {
    setIsPreviewingData(true);
    let fieldData;

    if (dataType === "advancedOperations") {
      return null;
    }

    if (dataType === "txt" || dataType === "xml") {
      const requestFileType = dataType === "txt" ? "sped" : "xml";
      const {
        data: { data: fieldDataResponse },
      } = await templatesApi.get(
        `/files/type/${requestFileType}/field/${register}/limit/5`,
      );
      fieldData = fieldDataResponse;
    } else {
      const { data: fieldDataResponse } = await templatesApi.get(
        `/files/file/${selectedTemplateFileId}`,
      );
      fieldData = fieldDataResponse.item.body[0].data;
      fieldData.splice(selectedFileColumnsIndex, 1);
    }

    const structuredData =
      fieldData.length > 0 && fieldData.map(row => Object.values(row));

    setSelectedElementToPreview({
      id,
      label,
      dataType,
      register,
      selectedColumns,
    });
    setPreviewData(structuredData);
  };

  const handleCloseSinglePreview = () => {
    setIsPreviewingData(false);
    setSelectedElementToPreview({});
    setPreviewData([]);
  };

  const handleSaveGeneralPreviewDragColumns = () => {
    // const itemsMock = [
    //   { id: uuid(), content: "First Task" },
    //   { id: uuid(), content: "Second Task" },
    //   { id: uuid(), content: "Third Task" },
    // ];
    // const columnsMock = {
    //   [uuid()]: {
    //     name: "Todo",
    //     items: itemsMock,
    //   },
    //   [uuid()]: {
    //     name: "In Progress",
    //     items: [],
    //   },
    // };
    // const copiedElements = JSON.parse(JSON.stringify(elements));
    // Object.keys(columns).map(column => {
    //   column.items.forEach(item => {
    //     console.log({ item });
    //   });
    // });
  };

  const handleAddPreviewGuide = (updatedElements, fetchedData) => {
    // TODO: must add the edgeds to this updatedElements array
    // setElements(updatedElements);

    // setGeneralPreviewGuide();

    const guide = {};

    updatedElements.forEach((element, index) => {
      if (element.type === "custom") {
        guide[element.id] = fetchedData[index];
      } else if (element.type === "advancedOperations") {
        let firstElementIndex;
        let secondElementIndex;

        if (element.data.firstHandleSource) {
          firstElementIndex = updatedElements.findIndex(
            updatedElement =>
              updatedElement.id === element.data.firstHandleSource,
          );
        }

        if (element.data.secondHandleSource) {
          secondElementIndex = updatedElements.findIndex(
            updatedElement =>
              updatedElement.id === element.data.secondHandleSource,
          );
        }
        const values = [];
        values.push(fetchedData[firstElementIndex] || []);
        values.push(fetchedData[secondElementIndex] || []);
        guide[element.id] = values;

        // JSON.parse(element.data.flow).forEach(flowElement => {
        //   console.log(flowElement);
        // });
      }

      // console.log(guide);
    });
  };

  const handleClearNodes = useCallback(() => {
    setElements([]);
  }, []);

  const getElementsById = (...ids) => {
    if (ids.length === 0) return;
    const requestedElements = [];
    ids.forEach(id => {
      if (!id) return;
      const element = elements.find(el => el.id === id);
      requestedElements.push(JSON.parse(JSON.stringify(element)));
    });
    return requestedElements;
  };

  const getAdvancedOperationsFlow = id => {
    if (!id) return;
    const element = JSON.parse(
      JSON.stringify(elements.find(el => el.id === id)),
    );
    if (element.data.flow) {
      return JSON.parse(element.data.flow);
    }
    return [
      {
        id: "createZone",
        type: "createZone",
        draggable: false,
        position: { x: 0, y: 0 },
      },
      {
        id: "start",
        type: "circle",
        data: {
          label: "INÍCIO",
        },
        position: { x: 0, y: 0 },
      },
      {
        id: "end",
        type: "circle",
        data: {
          label: "FIM",
        },
        position: { x: 800, y: 500 },
      },
    ];
  };

  const getStepsFromAdvancedOperationFlow = id => {
    if (!id) return;
    const element = JSON.parse(
      JSON.stringify(elements.find(el => el.id === id)),
    );
    if (element?.data?.steps) {
      return JSON.parse(JSON.stringify(element?.data?.steps));
    }
  };

  const handleSetCurrentAdvancedOperationId = id => {
    if (id) {
      setCurrentAdvancedOperationId(id);
    } else {
      setCurrentAdvancedOperationId("");
    }
  };

  const handleAttachFileToNode = ({ nodeId, fileId }) => {
    const updatedElements = JSON.parse(JSON.stringify(elements)).map(
      element => {
        if (element.id === nodeId) {
          const updatedElement = JSON.parse(JSON.stringify(element));
          updatedElement.data.selectedDataFileId = fileId;
          return updatedElement;
        }
        return element;
      },
    );
    setElements(updatedElements);
  };

  const getFileData = async nodeId => {
    const token = localStorage.getItem("@mixfiscal:authenticatorToken");
    const [element] = getElementsById(nodeId);
    if (!element) return;

    const fileId = element.data.selectedDataFileId;
    if (!fileId) return;

    // console.log({ element });
    // console.log({ register: element.data.register });
    const urlToRequest = element.data.register
      ? `/files/file/${fileId}/${element.data.register}`
      : `/files/file/${fileId}`;

    // console.log({ urlToRequest });

    const { data } = await templatesApi.get(urlToRequest, {
      headers: {
        Authorization: token,
      },
    });

    // console.log({ data });

    if (data.item.body && data.item.body.length > 0) {
      return data.item.body[0].data;
    }
  };

  const getSelectedFieldsFromElement = nodeId => {
    const [element] = getElementsById(nodeId);
    if (!element) return;
    const selectedColumnsIndex = [];
    element?.data?.fields?.forEach(
      (field, index) => field.isSelected && selectedColumnsIndex.push(index),
    );

    // getFileData(nodeId); // temporario
    return selectedColumnsIndex;
  };

  const addTemplateToAdvancedOperations = async ({ node, template }) => {
    const element = JSON.parse(JSON.stringify(node));
    element.data.template = template;
    const updatedElements = elements.filter(el => el.id !== node.id);
    setElements([...updatedElements, element]);
  };

  const setAdvancedOperationsFlow = (id, serializedFlow, template, steps) => {
    if (!id || !serializedFlow) return;
    const [element] = getElementsById(id);

    if (element.data.flow !== serializedFlow) {
      element.data.flow = serializedFlow;
      element.data.steps = steps;

      const updatedElements = [
        ...JSON.parse(JSON.stringify(elements.filter(el => el.id !== id))),
        element,
      ];
      setElements(updatedElements);
    }

    if (template) {
      addTemplateToAdvancedOperations({ node: element, template });
    }
  };

  const updateAdvancedOperationsName = ({ nodeId, name, currentFlow }) => {
    const [element] = JSON.parse(
      JSON.stringify(currentState.current.filter(el => el.id === nodeId)),
    );
    element.data.flow = JSON.stringify(currentFlow);
    element.data.name = name;
    setElements(prevState => [
      ...prevState.filter(el => el.id !== nodeId),
      element,
    ]);
  };

  const getAllFieldElementsForAdvancedOperations = () => {
    return JSON.parse(
      JSON.stringify(elements.filter(element => element.type === "custom")),
    );
  };

  // TODO: Validar usabilidade e remover
  const handleSetDataToNodeFromAdvancedOperation = () =>
    // {
    // id,
    // nodeId,
    // findValue,
    // replaceValue,
    // },
    {
      // const element = JSON.parse(
      //   JSON.stringify(elements.find(el => el.id === id)),
      // );
      // if (!element.data.flow) return;
      // const updatedElement = JSON.parse(element.data.flow).map(el => {
      //   if (el.id === nodeId) {
      //     const clonedNode = JSON.parse(JSON.stringify(el));
      //     clonedNode.data.findValue = findValue;
      //     clonedNode.data.replaceValue = replaceValue;
      //     return clonedNode;
      //   }
      //   return el;
      // });
      // console.log({ updatedElement });
    };

  // TODO: Validar usabilidade e remover
  // const getDataFromNodeFromAdvancedOperation = ({ id, nodeId }) => {
  //   const element = JSON.parse(
  //     JSON.stringify(elements.find(el => el.id === id)),
  //   );

  //   // console.log({ nodeId, element });
  // };
  // console.log(getDataFromNodeFromAdvancedOperation);

  const getColumnSum = values => {
    return values.reduce((acc, cur) => {
      if (!cur) return acc;
      const value = +cur ?? 0;
      let total = acc;
      total += value;
      return total;
    }, 0);
  };

  // operationMethods

  const getDataFromSingleNode = async ({
    fileId,
    columnIndex,
    dataType,
    register,
  }) => {
    const token = localStorage.getItem("@mixfiscal:authenticatorToken");
    if (dataType === "txt" || dataType === "xml") {
      const url = `/files/file/${fileId}/${register}`;

      const { data: responseFileContent } = await templatesApi.get(url, {
        headers: {
          Authorization: token,
        },
      });

      const fileData = responseFileContent.item.body[0].data.map(row => {
        return row[columnIndex];
      });
      return fileData;
    }

    const { data: responseFileContent } = await templatesApi.get(
      `/files/file/${fileId}`,
      {
        headers: {
          Authorization: token,
        },
      },
    );

    // console.log({ othersData: responseFileContent });
    const fileData = responseFileContent?.item?.body?.[0]?.data?.map(row => {
      return row[columnIndex];
    });
    return fileData;
  };

  const getDataFromSingleNode2 = async ({
    fileId,
    // columnIndex,
    dataType,
    register,
  }) => {
    const token = localStorage.getItem("@mixfiscal:authenticatorToken");
    if (dataType === "txt" || dataType === "xml") {
      // console.log({ register });
      const url = `/files/file/${fileId}/${register}`;
      // console.log({ url });
      const { data: responseFileContent } = await templatesApi.get(url, {
        headers: {
          Authorization: token,
        },
      });

      // console.log({ spedData: responseFileContent });
      const fileData = responseFileContent.item.body[0].data.map(row => {
        return row;
      });
      return fileData;
    }

    const { data: responseFileContent } = await templatesApi.get(
      `/files/file/${fileId}`,
      {
        headers: {
          Authorization: token,
        },
      },
    );

    // console.log({ othersData: responseFileContent });
    const fileData = responseFileContent.item.body[0].data.map(row => {
      return row;
    });
    return fileData;
  };

  const getTemplateFromAdvancedOperationNode = async serializedFlow => {
    const flowElements = JSON.parse(serializedFlow);

    const formatedElements = [];
    flowElements.forEach(async element => {
      if (!isNode(element)) return;

      if (element.id === "start") {
        const formatedElement = {};
        formatedElement.id = element.id;
        formatedElement.type = "start";
        formatedElements.push(formatedElement);
      }

      if (element.id === "end") {
        const formatedElement = {};
        const parentId = getIncomers(element, flowElements).map(
          incomer => incomer.id,
        );
        formatedElement.id = element.id;
        formatedElement.type = "end";
        formatedElement.parentId = parentId;
        formatedElements.push(formatedElement);
      }

      if (element.type === "rectangle") {
        // console.log({ advancedNode: element });
        const formatedElement = {};
        const parentId = getIncomers(element, flowElements).map(
          incomer => incomer.id,
        );
        formatedElement.id = element.id;
        formatedElement.dataType = element.data.dataType;
        formatedElement.apiFileId =
          element.data.element.data.selectedDataFileId;
        formatedElement.apiColumnIndex = element.data.fieldIndex;
        formatedElement.register = element.data.element.data.register;
        formatedElement.columnLabel = element.data.field;
        formatedElement.value = null;
        formatedElement.type = "data";
        formatedElement.parentId = parentId;
        formatedElements.push(formatedElement);

        if (element.data?.label?.includes("if")) {
          if (element.data?.label === "ifYes") {
            formatedElement.type = "ifYes";
          }
          if (element.data?.label === "ifNo") {
            formatedElement.type = "ifNo";
          }
        }
      }

      if (element.type === "inputValue") {
        // console.log({ element });
        const formatedElement = {};
        const parentId = getIncomers(element, flowElements).map(
          incomer => incomer.id,
        );
        formatedElement.id = element.id;
        formatedElement.dataType = "inputValue";
        formatedElement.apiFileId = null;
        formatedElement.apiColumnIndex = null;
        formatedElement.register = null;
        formatedElement.columnLabel = element.data.label;
        formatedElement.value = element.data.value;
        formatedElement.type = "data";
        formatedElement.parentId = parentId;
        formatedElements.push(formatedElement);
      }

      if (element.type === "runUntil") {
        console.log({ element });
        const formatedElement = {};
        const parentId = getIncomers(element, flowElements).map(
          incomer => incomer.id,
        );
        formatedElement.id = element.id;
        formatedElement.dataType = "runUntil";
        formatedElement.apiFileId = null;
        formatedElement.apiColumnIndex = null;
        formatedElement.register = null;
        formatedElement.columnLabel = element.data.label;
        formatedElement.value = element.data.finalValue;
        formatedElement.type = "data";
        formatedElement.parentId = parentId;
        formatedElements.push(formatedElement);
      }

      if (element.type === "allValues") {
        // console.log({ all: element });
        const formatedElement = {};
        const parentId = getIncomers(element, flowElements).map(
          incomer => incomer.id,
        );
        formatedElement.id = element.id;
        formatedElement.dataType = element.data.dataType;
        formatedElement.apiFileId =
          element.data.element.data.selectedDataFileId;
        formatedElement.apiColumnIndex = element.data.fieldIndex;
        formatedElement.register = element.data.element.data.register;
        formatedElement.columnLabel = element.data.field;
        formatedElement.value = null;
        formatedElement.type = "data";
        formatedElement.parentId = parentId;
        formatedElement.nodeType = "allValues";
        formatedElements.push(formatedElement);
      }

      if (element.type === "allAverage") {
        // console.log({ average: element });
        const formatedElement = {};
        const parentId = getIncomers(element, flowElements).map(
          incomer => incomer.id,
        );
        formatedElement.id = element.id;
        formatedElement.dataType = element.data.dataType;
        formatedElement.apiFileId =
          element.data.element.data.selectedDataFileId;
        formatedElement.apiColumnIndex = element.data.fieldIndex;
        formatedElement.register = element.data.element.data.register;
        formatedElement.columnLabel = element.data.field;
        formatedElement.value = null;
        formatedElement.type = "data";
        formatedElement.parentId = parentId;
        formatedElement.nodeType = "allAverage";
        formatedElements.push(formatedElement);
      }

      if (element.type === "diamond" || element.type === "diamondOneValue") {
        const formatedElement = {};
        const parentId = getIncomers(element, flowElements).map(
          incomer => incomer.id,
        );
        formatedElement.id = element.id;
        formatedElement.functionType = element.data.operationType;
        formatedElement.functionPropsAmount = element.data.argsAmount;
        formatedElement.type =
          element.data.argsAmount === 1 ? "function_1" : "function";
        formatedElement.operationType = element.data.operationType;
        formatedElement.parentId = parentId;
        formatedElement.value = null;
        formatedElements.push(formatedElement);
      }

      if (element.type === "findAndReplace") {
        const formatedElement = {};
        const parentId = getIncomers(element, flowElements).map(
          incomer => incomer.id,
        );
        formatedElement.id = element.id;
        formatedElement.functionType = element.data.operationType;
        formatedElement.functionPropsAmount = element.data.argsAmount;
        formatedElement.type = "find_replace";
        formatedElement.operationType = element.data.operationType;
        formatedElement.parentId = parentId;
        formatedElement.value = null;
        formatedElement.findValue = element.data.findValue;
        formatedElement.replaceValue = element.data.replaceValue;
        formatedElements.push(formatedElement);
      }
    });
    // console.log({ formatedElements });
    return formatedElements;
  };

  const getPreviewDataFromNode = async groupOfElements => {
    const generalPreviewStructure = [];
    const finalProcessedData = [];
    let index = 0;

    for await (const element of groupOfElements) {
      index += 1;

      if (element.type === "advancedOperations") {
        const template = await getTemplateFromAdvancedOperationNode(
          element.data.flow,
        );

        let biggestDataSet = 0;
        const apiDataHolder = {};

        for await (const el of template) {
          if (el.type.includes("function") || el.type === "find_replace") {
            // include the operation function to the templates functions
            el.value = operationMethods[el.operationType];
          }

          // console.log({ els: el });
          if (el.type === "data") {
            if (el.dataType === "inputValue" || el.dataType === "runUntil") {
              const data = el.value;
              console.log(el?.value?.length);
              console.log(biggestDataSet);
              apiDataHolder[el.id] = { id: el.id, data };
              if (biggestDataSet < 1) {
                biggestDataSet = 1;
              }
              if (
                el.dataType === "runUntil" &&
                biggestDataSet < el?.value?.length
              ) {
                biggestDataSet = el?.value?.length;
              }
            } else if (el?.nodeType === "allValues") {
              // console.log({ all2: el });
              const data = await getDataFromSingleNode({
                fileId: el.apiFileId,
                columnIndex: el.apiColumnIndex,
                dataType: el.dataType,
                register: el.register ?? null,
              });

              apiDataHolder[el.id] = { id: el.id, data };
              if (biggestDataSet < 1) {
                biggestDataSet = 1;
              }
            } else if (el?.nodeType === "allAverage") {
              const data = await getDataFromSingleNode({
                fileId: el.apiFileId,
                columnIndex: el.apiColumnIndex,
                dataType: el.dataType,
                register: el.register ?? null,
              });

              apiDataHolder[el.id] = { id: el.id, data };
              if (biggestDataSet < 1) {
                biggestDataSet = 1;
              }
            } else {
              // this else statement is for all the normal api data nodes.
              // include an array with the selected column data for each row of the database file.
              const data = await getDataFromSingleNode({
                fileId: el.apiFileId,
                columnIndex: el.apiColumnIndex,
                dataType: el.dataType,
                register: el.register ?? null,
              });

              apiDataHolder[el.id] = { id: el.id, data };

              if (data?.length > biggestDataSet) {
                biggestDataSet = data?.length;
              }
            }
          }
        }

        /**
         * now it's time to iterate each element by the biggest array size.
         * then each data element will get its value that we fetched before.
         * then we will run the recursion with the current values. (aka row 1 of each database file, row 2 of each database file...)
         */
        // console.log({ apiDataHolder });

        const processedData = [];
        for (let i = 0; i < biggestDataSet; i += 1) {
          const wiredTemplate = template.map(templateEl => {
            if (templateEl.type === "data") {
              if (templateEl.dataType === "inputValue") {
                const value = apiDataHolder[templateEl.id].data;
                const wiredTemplateEl = JSON.parse(JSON.stringify(templateEl));
                wiredTemplateEl.value = value;
                return wiredTemplateEl;
              }
              if (templateEl?.nodeType === "allValues") {
                const value =
                  operationMethods.sumAll(apiDataHolder[templateEl.id].data) ??
                  null;
                const wiredTemplateEl = JSON.parse(JSON.stringify(templateEl));
                wiredTemplateEl.value = value;
                return wiredTemplateEl;
              }

              if (templateEl?.nodeType === "allAverage") {
                const value =
                  operationMethods.averageAll(
                    apiDataHolder[templateEl.id].data,
                  ) ?? null;
                const wiredTemplateEl = JSON.parse(JSON.stringify(templateEl));
                wiredTemplateEl.value = value;
                return wiredTemplateEl;
              }
              // this else statement is for all the normal api data nodes.
              const value = apiDataHolder[templateEl.id]?.data?.[i] ?? null;
              const wiredTemplateEl = JSON.parse(JSON.stringify(templateEl));
              wiredTemplateEl.value = value;
              return wiredTemplateEl;
            }
            return templateEl;
          });

          // console.log({ wiredTemplate });
          // console.log({ recursionResult: runRecursion(wiredTemplate) });
          processedData.push(runRecursion(wiredTemplate));
          // console.log({ processedData });
        }
        finalProcessedData.push(processedData);

        /**
         * generating the general preview structure data
         */
        const generalPreviewElement = {
          id: uuidv4(),
          label: element.data.label,
          description: element.data.name ?? null,
          elementData: processedData,
        };

        const previewStruct = generalPreviewStructure[element.id];
        generalPreviewStructure[element.id] = {
          name: index,
          items: previewStruct
            ? [...previewStruct.items, generalPreviewElement]
            : [generalPreviewElement],
        };
      }

      if (element.type === "custom") {
        // the nodes must have a selected column!
        const { fields } = element.data;
        const selectedFields = [];
        fields.forEach((field, fieldIndex) => {
          if (field.isSelected) {
            selectedFields.push({
              fieldDescription: field.description,
              fileId: element.data.selectedDataFileId,
              columnIndex: fieldIndex,
              dataType: element.data.dataType,
              register: element.data.register ?? null,
            });
          }
        });

        let tempElFileId = "";
        let tempElApiData = null;
        for await (const el of selectedFields) {
          // console.log({ el, selectedFields });

          if (tempElFileId === el.fileId) {
            finalProcessedData.push(
              tempElApiData.map(row => row[el.columnIndex]),
            );
          } else {
            tempElFileId = el.fileId;
            const fetchedData = await getDataFromSingleNode2({
              fileId: el.fileId,
              columnIndex: el.columnIndex,
              dataType: el.dataType,
              register: el.register ?? null,
            });
            tempElApiData = fetchedData;
            finalProcessedData.push(
              tempElApiData.map(row => row[el.columnIndex]),
            );
          }

          /**
           * generating the general preview structure data
           */
          const generalPreviewElement = {
            id: uuidv4(),
            label: element.data.label,
            description: el.fieldDescription,
            elementData: tempElApiData.map(row => row[el.columnIndex]),
          };

          const previewStruct = generalPreviewStructure[element.id];
          generalPreviewStructure[element.id] = {
            name: index,
            items: previewStruct
              ? [...previewStruct.items, generalPreviewElement]
              : [generalPreviewElement],
          };
        }
      }

      if (element.type === "operation") {
        // console.log({ element, groupOfElements });
        const node1 = groupOfElements.find(
          el => el.id === element.data.selectedNode1,
        );

        const node2 = groupOfElements.find(
          el => el.id === element.data.selectedNode2,
        );

        const selectedFields = [];
        if (node1) {
          node1.data.fields.forEach((field, fieldIndex) => {
            if (field._id === element.data.selectedNode1Field) {
              selectedFields.push({
                fieldDescription: field.description,
                fileId: node1.data.selectedDataFileId,
                columnIndex: fieldIndex,
                dataType: node1.data.dataType,
                register: node1.data.register ?? null,
              });
            }
          });
        }

        if (node2) {
          node2.data.fields.forEach((field, fieldIndex) => {
            if (field._id === element.data.selectedNode2Field) {
              selectedFields.push({
                fieldDescription: field.description,
                fileId: node2.data.selectedDataFileId,
                columnIndex: fieldIndex,
                dataType: node2.data.dataType,
                register: node2.data.register ?? null,
              });
            }
          });
        }

        // console.log({ selectedFields });

        const selectedFieldsDataFetched = [];
        for await (const el of selectedFields) {
          const fetchedData = await getDataFromSingleNode({
            fileId: el.fileId,
            columnIndex: el.columnIndex,
            dataType: el.dataType,
            register: el.register ?? null,
          });
          selectedFieldsDataFetched.push(fetchedData);

          // console.log({ fetchedData });
        }

        const biggestDataSet = selectedFieldsDataFetched.reduce((acc, cur) => {
          const currentSize = cur.length;
          if (currentSize > acc) return currentSize;
          return acc;
        }, 0);

        const result = [];

        for (let i = 0; i < biggestDataSet; i += 1) {
          const x = node1 ? selectedFieldsDataFetched[0] : null;
          if (element.data.operationType === "findAndReplace") {
            const y = {
              findValue: element.data.selectedNode2,
              replaceValue: element.data.selectedNode2Field,
            };
            result.push(
              operationMethods[element.data.operationType](x?.[i], y),
            );
          } else {
            const y = node2 ? selectedFieldsDataFetched[1] : null;
            result.push(
              operationMethods[element.data.operationType](x?.[i], y?.[i]),
            );
          }
        }

        finalProcessedData.push(result);

        /**
         * generating the general preview structure data
         */
        const generalPreviewElement = {
          id: uuidv4(),
          label: element.data.label,
          description: element.data.name ?? null,
          elementData: result,
        };

        const previewStruct = generalPreviewStructure[element.id];
        generalPreviewStructure[element.id] = {
          name: index,
          items: previewStruct
            ? [...previewStruct.items, generalPreviewElement]
            : [generalPreviewElement],
        };
      }
    }

    return { finalProcessedData, generalPreviewStructure };
  };

  const handleUpdateAdvancedFlowData = ({ id, data, parentId, state }) => {
    const [element] = getElementsById(parentId);
    const updatedFlow = state?.map(el => {
      console.log({ el });
      if (el.id === id) {
        const updatedElement = JSON.parse(JSON.stringify(el));
        updatedElement.data = data;
        // updatedElement.position = el?.__rf?.position;
        return updatedElement;
      }
      const updatedPositionElement = JSON.parse(JSON.stringify(el));
      // updatedPositionElement.position = el?.__rf?.position;
      return updatedPositionElement;
    });

    element.data.flow = JSON.stringify(updatedFlow);
    // setAdvancedOperationsFlow(parentId, element.data.flow); // VERIFICAR SE AQUI QUE ESTÁ BAGUNÇANDO O POSITION
    const remainingElements = elements.filter(el => el.id !== element.id);
    setElements([...remainingElements, element]);
  };

  const handleUpdateNodeData = ({
    id,
    data,
    parentId,
    updatedNodesPositions,
  }) => {
    setIsUpdatingElements(true);
    if (parentId) {
      const [element] = getElementsById(parentId);
      const parentFlowElements = JSON.parse(element.data.flow);
      const updatedFlow = parentFlowElements.map(el => {
        const updatedPosition = updatedNodesPositions?.nodes?.find(
          nodeUpdated => nodeUpdated.id === el.id,
        )?.__rf?.position;
        if (el.id === id) {
          const updatedElement = JSON.parse(JSON.stringify(el));
          updatedElement.data = data;
          updatedElement.position = updatedPosition;
          return updatedElement;
        }
        const updatedElement = JSON.parse(JSON.stringify(el));
        updatedElement.position = updatedPosition;
        return updatedElement;
      });

      element.data.flow = JSON.stringify(updatedFlow);
      const remainingElements = elements.filter(el => el.id !== element.id);
      setElements(() => [...remainingElements, element]);
    } else {
      const updatedElements = JSON.parse(
        JSON.stringify(
          elements.map(el => {
            if (el.id === id) {
              const updatedElement = JSON.parse(JSON.stringify(el));
              // console.log({ data });
              updatedElement.data = data;
              return updatedElement;
            }
            return el;
          }),
        ),
      );
      setElements(updatedElements);
    }
  };

  useEffect(() => {
    if (isUpdatingElements) {
      setIsUpdatingElements(false);
    }
  }, [elements, isUpdatingElements]);

  return (
    <FlowContext.Provider
      value={{
        elements,
        isPreviewingData,

        isAttachingFileToNode,
        setIsAttachingFileToNode,
        handleAttachFileToNode,
        selectedCallToActionElementId,
        setSelectedCallToActionElementId,
        getSelectedFieldsFromElement,
        getFileData,
        addTemplateToAdvancedOperations,
        getAllFieldElementsForAdvancedOperations,
        getColumnSum,

        previewData,
        selectedElementToPreview,
        handleAddPreviewGuide,
        Observer,
        handleUpdateElementsPosition,
        handleAddNode,
        handleRemoveNode,
        handleClearNodes,
        handleConnectNode,
        handleRemoveConnection,
        handleOpenSinglePreview,
        handleCloseSinglePreview,
        handleSaveGeneralPreviewDragColumns,
        handleUpdateSelectedField,
        handleSaveFlow,
        handleLoadFlow,
        getElementsById,
        setAdvancedOperationsFlow,
        getAdvancedOperationsFlow,
        getStepsFromAdvancedOperationFlow,
        updateAdvancedOperationsName,

        currentAdvancedOperationId,
        handleSetCurrentAdvancedOperationId,
        handleSetDataToNodeFromAdvancedOperation,

        getDataFromSingleNode,
        getTemplateFromAdvancedOperationNode,
        getPreviewDataFromNode,

        handleUpdateNodeData,
        selectedFlow,
        isUpdatingElements,
        handleUpdateAdvancedFlowData,
      }}
    >
      {children}
    </FlowContext.Provider>
  );
};

export { useFlow, FlowProvider };
