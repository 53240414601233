import React, { Suspense } from "react";

import { usePermissions } from "context/PermissionsContext";
import "@mix/fornecedores-front/dist/index.css";

import { useAuth } from "context/AuthContext";
import * as S from "./styles";

const Supplier = React.lazy(() => import("@mix/fornecedores-front"));

const FornecedoresPage = () => {
  const permissions = usePermissions();
  const { tokenCompanies } = useAuth();

  if (!tokenCompanies) {
    return <div>Carregando...</div>;
  }

  return (
    <S.Container>
      <Suspense fallback={<div>Carregando...</div>}>
        <Supplier {...permissions} token={tokenCompanies} page="speed" />
      </Suspense>
    </S.Container>
  );
};

export default FornecedoresPage;
