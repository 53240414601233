import { transformListSelect } from "utils/utils";

export const PISCOFINS = "piscofins";
export const ICMS = "icms";

export const CST_ICMS_REDUCAO_BASE = ["20", "51", "60", "70", "90"];

export const ENTRADA = "ENTRADA";
export const SAIDA = "SAIDA";

export const CST_ICMS_ALIQ = [
  "00",
  "10",
  "20",
  "51",
  "70",
  "90",
  "101",
  "103",
  "201",
  "202",
  "203",
  "300",
  "400",
  "500",
  "900",
];

export const CST_ICMS_ALIQ_ISENTO = ["30", "40", "41", "50"];

export const CST_PIS_COFINS_NATUREZA_RECEITA = [
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
];

/**
 * CST de entrada que podem ser tributados
 */
export const CST_ENTRADA_TRIBUTADO = ["20", "51", "70", "90"];

export const CST_ENTRADA_NAO_TRIBUTADO = [
  "00",
  "10",
  "51",
  "20",
  "70",
  "90",
  "101",
  "103",
  "201",
  "202",
  "203",
  "300",
  "400",
];

export const STATUS_GRADES_TYPES = {
  ATIVA: "Ativa",
  ARQUIVADA: "Arquivada",
  CONFLITO: "Com conflito",
  PRE_GRADE: "Pre Grade",
  INATIVA: "Inativa",
  SEM_PRODUTOS: "Sem Produtos",
};

export const STATUS_GRADES = {
  CONFLITO: "conflito",
  ATIVA: "ativa",
  PRE_GRADE: "pre-grade",
  INATIVA: "inativa",
  ARQUIVADA: "arquivada",
};

export const GRADE_MODALS_TYPES = {
  PRODUCTS_ADD: "add",
  PRODUCTS_MOVE: "move",
  PRODUCTS_DELETE: "delete",
  PRODUCTS_CONFIRMEDS_REMOVE: "confimeds_remove",
  PRODUCTS_CONFIRMEDS_ADD: "confimeds_add",
  PRODUCTS_CONFIRMEDS_MOVE: "confimeds_move",
};

export const STATUS_GRADES_TYPES_LIST =
  transformListSelect(STATUS_GRADES_TYPES);
