import React, { useState } from "react";
import { Card } from "@mix/mixfiscal-designsystem";
import useModules from "../../hooks/useModules";
import useFields from "../../hooks/useFields";
import ORIGINS from "../../constants/origins";
import { InputContainer } from "./styles";

const MODULES_ABLE_TO_ORIGINS = ["Vendas", "Compras", "Integração"];

const MonitoringForm = ({ show, register, fieldsConditions }) => {
  const { modules } = useModules();
  const { fields } = useFields();
  const [origins] = useState(Object.keys(ORIGINS));

  return (
    show &&
    fieldsConditions.map((item, index) => {
      const fieldsData = fields.filter(f => f.module === item.field.module);
      const moduleSelected = modules.find(
        m => m._id === fieldsConditions[index].field.module,
      );
      const hasOrigins = MODULES_ABLE_TO_ORIGINS.includes(moduleSelected?.name);

      return (
        <Card
          key={index}
          headerColor="#59AEC4"
          textColor="#FFF"
          title="MONITORAMENTO"
          className="flatCard"
        >
          <p className="subtitle">Selecione o módulo para o monitoramento.</p>
          <div className="row">
            <InputContainer>
              <label>Módulo</label>
              <select
                {...register(`conditions.${index}.field.module`, {
                  required: true,
                })}
              >
                <option value="">Selecione uma opção</option>
                {modules.map(module => (
                  <option key={module._id} value={module._id}>
                    {module.name}
                  </option>
                ))}
              </select>
            </InputContainer>
            <InputContainer>
              <label>Campo</label>
              <select
                {...register(`conditions.${index}.field._id`, {
                  required: true,
                  disabled: !item.field.module,
                })}
              >
                <option value="">Selecione uma opção</option>
                {fieldsData.map(field => (
                  <option key={field._id} value={field._id}>
                    {field.name}
                  </option>
                ))}
              </select>
            </InputContainer>
            <InputContainer>
              <label>Origem</label>
              <select
                {...register(`conditions.${index}.origin`, {
                  required: true,
                  disabled: !hasOrigins,
                })}
              >
                <option value="">Selecione uma opção</option>
                {origins.map(i => (
                  <option key={i} value={i}>
                    {ORIGINS[i]}
                  </option>
                ))}
              </select>
            </InputContainer>
            {/* <div className="button">
                <Button
                  className="round"
                  onClick={() => console.log("click")}
                >
                  <IoAdd />
                </Button>
              </div> */}
          </div>

          <p className="subtitle">
            Indique as condições para os campos monitorados e descreva o
            problema gerado por essa condição.
          </p>
          <div className="row">
            <div className="radio">
              <input
                type="radio"
                {...register(`conditions.${index}.condition`, {
                  required: true,
                })}
                value="GREATER_THAN"
              />
              <span>Maior</span>
            </div>
            <div className="radio">
              <input
                type="radio"
                {...register(`conditions.${index}.condition`, {
                  required: true,
                })}
                value="LESS_THAN"
              />
              <span>Menor</span>
            </div>
            <div className="radio">
              <input
                type="radio"
                {...register(`conditions.${index}.condition`, {
                  required: true,
                })}
                value="EQUAL_OR_GREATER_THAN"
              />
              <span>Igual ou maior</span>
            </div>
            <div className="radio">
              <input
                type="radio"
                {...register(`conditions.${index}.condition`, {
                  required: true,
                })}
                value="EQUAL_OR_LESS_THAN"
              />
              <span>Igual ou menor</span>
            </div>
            <div className="radio">
              <input
                type="radio"
                {...register(`conditions.${index}.condition`, {
                  required: true,
                })}
                value="NOT_EQUAL"
              />
              <span>Diferente</span>
            </div>
            <div className="radio">
              <input
                type="radio"
                {...register(`conditions.${index}.condition`, {
                  required: true,
                })}
                value="BETWEEN"
              />
              <span>Entre</span>
            </div>
          </div>
          <div className="row">
            <InputContainer>
              <label>Valor</label>
              <input
                {...register(`conditions.${index}.value`, {
                  required: true,
                })}
                onKeyPress={event => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                placeholder="Digite um valor"
              />
            </InputContainer>
            <InputContainer>
              <label>Problema</label>
              <input
                {...register(`conditions.${index}.problem`, {
                  required: true,
                })}
                placeholder="Digite um problema"
              />
            </InputContainer>
          </div>
        </Card>
      );
    })
  );
};

export default MonitoringForm;
