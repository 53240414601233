import React from "react";
import { Slider } from "@material-ui/core";
import { Controller } from "react-hook-form";

import * as S from "./styles";

export const RangeSlider = ({
  minValue,
  maxValue,
  step,
  marks,
  displayLabel = "off",
  control,
  name,
  defaultValue,
  renderValue,
  handleChange = () => {},
}) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => {
        return (
          <S.Container>
            <Slider
              valueLabelDisplay={displayLabel}
              marks={marks && marks}
              min={minValue}
              max={maxValue}
              step={step}
              onChange={(_, v) => {
                onChange({ target: { name, value: v } });
                if (handleChange) {
                  handleChange({ target: { name, v, value: v } });
                }
              }}
              value={renderValue ? renderValue(value) : value}
            />
          </S.Container>
        );
      }}
    />
  );
};
