import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Card } from "@mix/mixfiscal-designsystem";
import { useApp } from "context/AppContext";
import CardTemplate from "../../componentes/CardTemplate";
import FiltersTemplates from "../../componentes/FiltersTemplates";
import useCheckLinks from "../../hooks/useCheckLinks";
import * as S from "./styles";

const CheckLinkDashboard = () => {
  const history = useHistory();
  const { app } = useApp();
  const { checkLinks, checkLinksTotal, getAll, detele } = useCheckLinks();

  useEffect(() => {
    getAll();
  }, [getAll]);

  const hanldeEdit = id => {
    history.push(`/check-link/${id}`);
  };

  const hanldeDuplicate = id => {
    history.push("/check-link/new", { id });
  };

  const hanldeDelete = id => {
    detele(id);
  };

  console.log(`app`, app.appBusinessIntelligence);

  return (
    <S.Container>
      <Card
        className="container-card-drag-drop"
        headerColor={!app.appBusinessIntelligence ? "#256925" : "#17456B"}
        textColor="#FFF"
        iconName="eae dude2"
        title="Meus Templates de CheckLink"
      >
        <FiltersTemplates hasAdd length={checkLinksTotal} />

        <S.Cards>
          {checkLinks.length ? (
            checkLinks.map((item, index) => (
              <CardTemplate
                key={index}
                id={item._id}
                title={item.name}
                subTitle={item.category.title}
                createdAt={item.createdAt}
                updatedAt={item.updatedAt}
                onEdit={hanldeEdit}
                onDuplicate={hanldeDuplicate}
                onDelete={hanldeDelete}
              />
            ))
          ) : (
            <h2>Não existem checklinks</h2>
          )}
        </S.Cards>
      </Card>
    </S.Container>
  );
};

export default CheckLinkDashboard;
