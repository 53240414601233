import React from "react";
import { ReactComponent as sum } from "../../assets/icons/ico-funcao-soma.svg";
import { ReactComponent as subtract } from "../../assets/icons/ico-funcao-subtracao.svg";
import { ReactComponent as roundup } from "../../assets/icons/ico-funcao-arredondar-mais.svg";
import { ReactComponent as rounddown } from "../../assets/icons/ico-funcao-arredondar-menos.svg";
import { ReactComponent as compare } from "../../assets/icons/ico-funcao-comparar.svg";
import { ReactComponent as divide } from "../../assets/icons/ico-funcao-divisao.svg";
import { ReactComponent as average } from "../../assets/icons/ico-funcao-media.svg";
import { ReactComponent as multiply } from "../../assets/icons/ico-funcao-multiplicacao.svg";
import { ReactComponent as replace } from "../../assets/icons/ico-funcao-substituir.svg";
import { ReactComponent as back } from "../../assets/icons/ico-voltar.svg";
import { ReactComponent as forward } from "../../assets/icons/ico-avancar.svg";
import { ReactComponent as search } from "../../assets/icons/ico-busca.svg";
import { ReactComponent as close } from "../../assets/icons/ico-close.svg";
import { ReactComponent as close2 } from "../../assets/icons/ico-close2.svg";
import { ReactComponent as check } from "../../assets/icons/ico-check.svg";
import { ReactComponent as person } from "../../assets/icons/ico-pessoa.svg";
import { ReactComponent as locker } from "../../assets/icons/ico-criptografia.svg";
import { ReactComponent as arrowdown } from "../../assets/icons/ico-arrow-down.svg";
import { ReactComponent as arrowup } from "../../assets/icons/ico-arrow-up.svg";
import { ReactComponent as arrowright } from "../../assets/icons/ico-arrow-right.svg";
import { ReactComponent as arrowleft } from "../../assets/icons/ico-arrow-left.svg";

import { ReactComponent as warning } from "../../assets/icons/ico-atencao.svg";
import { ReactComponent as star } from "../../assets/icons/ico-estrela.svg";
import { ReactComponent as like } from "../../assets/icons/ico-like.svg";
import { ReactComponent as link } from "../../assets/icons/ico-link.svg";
import { ReactComponent as notification } from "../../assets/icons/ico-notificacao.svg";
import { ReactComponent as ticket } from "../../assets/icons/ico-ticket.svg";
import { ReactComponent as email } from "../../assets/icons/ico-email.svg";
import { ReactComponent as network } from "../../assets/icons/ico-network.svg";
import { ReactComponent as qrcode } from "../../assets/icons/ico-qr-code.svg";
import { ReactComponent as analysis } from "../../assets/icons/ico-analise.svg";
import { ReactComponent as edit } from "../../assets/icons/ico-editar.svg";
import { ReactComponent as subfolder } from "../../assets/icons/ico-subpasta.svg";

import { ReactComponent as sped } from "../../assets/icons/ico-sped.svg";
import { ReactComponent as flow } from "../../assets/icons/ico-fluxo.svg";
import { ReactComponent as operations } from "../../assets/icons/ico-operacoes.svg";
import { ReactComponent as pdf } from "../../assets/icons/ico-pdf.svg";
import { ReactComponent as csv } from "../../assets/icons/ico-csv.svg";
import { ReactComponent as excel } from "../../assets/icons/ico-ms-excel.svg";
import { ReactComponent as xml } from "../../assets/icons/ico-xml.svg";
import { ReactComponent as google } from "../../assets/icons/ico-google.svg";

const dict = {
  sum,
  subtract,
  roundup,
  rounddown,
  compare,
  divide,
  average,
  multiply,
  replace,
  back,
  forward,
  search,
  sped,
  flow,
  operations,
  pdf,
  csv,
  excel,
  xml,
  close,
  close2,
  check,
  person,
  locker,
  warning,
  star,
  like,
  link,
  notification,
  ticket,
  email,
  network,
  qrcode,
  analysis,
  edit,
  arrowdown,
  arrowup,
  arrowright,
  arrowleft,
  subfolder,
  google,
};

const IconComponent = ({
  name,
  color = "#fff",
  size = 24,
  onClick,
  className,
}) => {
  if (!name) {
    return null;
  }
  const Icon = dict[name.toLowerCase()];

  return Icon ? (
    <Icon
      fill={color}
      width={`${size}px`}
      height={`${size}px`}
      onClick={onClick}
      className={className}
    />
  ) : null;
};

export default IconComponent;
