import styled, { css } from "styled-components";

const columnFormItemModifiers = {
  setMargin: item => css`
    margin: ${item} !important;
  `,
};

export const StyledGridOverlay = styled.div`
  ${({ setMargin }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    background: #fff;

    ${setMargin && columnFormItemModifiers.setMargin(setMargin)}
  `}
`;

export const Filter = styled.div`
  display: none;
`;

export const Btn = styled.button`
  height: 32px;
  border-radius: 0;
  background: #fff;
  padding: 0px 10px 3px 10px !important;
  margin: 3px 0px 5px 0px !important;
  svg {
    color: #5a8dee;
  }
  &:hover {
    color: #fff;
    border: 1px solid #256925 !important;
    background-color: #256925 !important;
    svg {
      color: #fff;
    }
  }
`;

export const DataGridCustom = styled.div`
  height: 750px;
  padding: 0 0 20px 0px;
  margin: 0 0 20px 0;
  width: 100%;

  & .MuiDataGrid-root .MuiDataGrid-row.Mui-selected {
    background-color: rgb(71 98 255 / 58%);
    color: #fff;
    &:hover {
      background-color: #7488fb;
      color: #fff;
    }
  }
`;
