import styled, { css } from "styled-components";
import * as S from "components/form/Switch/Switch.styles";

export const setItem = {
  setMargin: margin => css`
    margin: ${margin};
  `,
  setAlignItem: align => css`
    text-align: ${align};
  `,
  setPadding: padding => css`
    margin: ${padding};
  `,
  setWidth: width => css`
    width: ${width};
  `,
  setHeigth: heigth => css`
    height: ${heigth} !important;
    overflow: scroll;
  `,
  setDirection: diretion => css`
    flex-direction: ${diretion};
  `,
};

export const Container = styled.div`
  margin: 10px 0 10px 0;
`;

export const ContainerHeader = styled.div``;

export const ContainerHeaderTitle = styled.h2`
  color: #39672e;
  font-weight: bold;
`;

export const ContainerRow = styled.div`
  display: flex;
  margin: 0 10px 40px 0;
  flex-direction: ${({ direction }) => direction || `row`};
  min-height: 500px;
`;

export const ContainerRowItem = styled.div`
  ${({ direction, width = "50%", heigth: height, margin }) => css`
    background-color: #fff;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 23%);
    ${setItem.setDirection(direction)}
    ${width && setItem.setWidth(width)}
    ${height && setItem.setHeigth(height)}
    ${setItem.setMargin(margin)}
    padding: 15px 3px 15px 3px;
    border-radius: 5px;
  `};
`;

export const ContainerRowItemHeader = styled.div`
  width: 100%;
  border-bottom: 1px solid #bbbbbb;
  padding: 0 0 0 5px;
  line-height: 30px;

  h4 {
    color: #596778;
  }
`;

export const ContainerHeaderClientAction = styled.div`
  flex: 30%;
  display: flex;
  justify-content: flex-end;
`;

export const ContainerHeaderClientTitle = styled.div`
  display: flex;
  label {
    font-size: 13px;
  }

  ${S.InputFieldContainer} {
    width: 160px;
    label:first-of-type {
      transform: scale(0.7);
    }
    label:last-of-type {
      padding-left: 5px;
    }
  }
`;

export const ActionBtn = styled.button`
  background: #256925;
  border-radius: 5px;
  padding: 0 5px 0 5px;
  color: #fff;
  font-weight: bold;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 23%);
`;

export const ContainerRowItemHeaderTitle = styled.div`
  display: flex;
  flex-direction: row;
`;
export const ContainerRowItemHeaderTitleItem = styled.div`
  ${({ align = "left" }) => setItem.setAlignItem(align)}
  ${({ margin }) => setItem.setMargin(margin)}
width: 100%;
  h3 {
    color: #17456b;
    font-size: 20px;
    flex: 80%;
  }
`;
export const ContainerRowItemHeaderTitleSub = styled.h4`
  color: #596778;
  flex: 1;
`;

const totalTypeCard = {
  setType: type => css`
    ${type === "success" &&
    css`
      background-color: #aac468;
    `};
    ${type === "info" &&
    css`
      background-color: #6bb2ae;
    `};
    ${type === "warning" &&
    css`
      background-color: #dd6f6a;
    `};
  `,
};

export const ContainerRowItemListTotal = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0 0 2px 0;
  ${({ type }) => totalTypeCard.setType(type)}
  transition: all 0.3s;

  &:hover {
    box-shadow: 0px 3px 6px rgb(0 0 0 / 23%);
  }
`;

export const ContainerRowItemListTotalNumber = styled.div`
  color: #fff;
  width: 50%;
  font-size: 20px;
  text-align: left;
  font-weight: bold;
  padding: 0 0 0 10px;
`;

export const ContainerRowItemListTotalText = styled.div`
  font-size: 15px;
  width: 50%;
  padding: 6px 10px 0 0px;
  text-align: right;
  color: #fff;
  font-weight: bold;
`;

export const ContainerRowItemContent = styled.div`
  height: max-content;
  /* ${({ heigth }) => setItem.setHeigth(heigth)} */
  ${({ padding }) => setItem.setPadding(padding)}
`;

export const ContainerRowItemInfo = styled.div``;

export const customStyles = {
  rows: {
    style: {
      minHeight: "40px !important",
    },
  },
  headCells: {
    style: {
      minWidth: "20px",
      minHeight: "30px",
      paddingLeft: "2px",
      paddingRight: "2px",
    },
  },
  cells: {
    style: {
      width: "30px",
      paddingLeft: "2px",
      paddingRight: "2px",
    },
  },
};

export const TableAction = styled.div`
  display: flex;
`;

export const TableActionBtn = styled.button`
  margin: 0 3px 0 3px;
  border-radius: 50px;
  width: 34px;
  padding: 0 10px 0 10px;
  ${({ type }) => totalTypeCard.setType(type)}
`;

export const ContainerRowItemNull = styled.div`
  padding: 180px 0 0 0;
  display: flex;
  width: 100%;
  height: 100%;
  align-self: center;
  justify-content: center;
  h4 {
    font-size: 22px;
  }
`;
