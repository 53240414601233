import React, { useEffect, useState } from "react";
import MidCard from "components/contexts/dashboard/mid-card";
import { useAnalyticsSales, useAnalyticsSalesCnpj } from "useCases/dashboard";
import { ResponsiveBar } from "@nivo/bar";
import { format, subMonths, subYears } from "date-fns";
// TODO: Alterar nome format para ContextNumber.format
import { Format } from "utils/context/number";
import {
  LoadPlaceholder,
  LoadPrefix as Loading,
} from "components/structure/Load";
import { getTagTypeDashoard, getDatesMonths } from "utils/context/dashboard";
import { useAuth } from "context/AuthContext";
import { usePermissions } from "context/PermissionsContext";

import { TooltipValues } from "../TooltipValues";

import {
  MidCardContainer,
  MidCardBlocOne,
  MidCardBlocTwo,
  LegendContainerSale,
} from "../styles";

const FormatDate = value => format(value, "ddMMyyyy");

const legend = [
  { title: "período atual", className: "square1" },
  { title: "mês anterior", className: "square2" },
  { title: "ano anterior", className: "square3" },
];

const BLANK_TEXT = "Sem dados para o período";

function DashSales({ monthDateStart, monthDateEnd, fileType = "XML" }) {
  const { setIsLoadingDashBoards } = usePermissions();

  const {
    data: dataSalesCurrent,
    fetch: fetchSales,
    isLoading: isLoadingSale,
  } = useAnalyticsSales();

  const {
    data: dataSalesPastMonth,
    fetch: fetchSalesPastMonth,
    isLoading: isLoadingSalePastMonth,
  } = useAnalyticsSales();

  const {
    data: dataSalesPastYear,
    fetch: fetchSalesPastYear,
    isLoading: isLoadingSalePastYear,
  } = useAnalyticsSales();

  const { fetch: fetchSalesCnpj } = useAnalyticsSalesCnpj();

  const [blankValue, setBlankValue] = useState(200);
  const { tokenCompanies } = useAuth();
  const [vendasDateStart, setVendasDateStart] = useState(monthDateStart);
  const [vendasDateEnd, setVendasDateEnd] = useState(monthDateEnd);
  const [dataVendas, setDataVendas] = useState([]);
  const [tag, setTag] = useState(fileType);
  const [linearValues, setLinearValues] = useState([]);
  const [hasDates, setHasDates] = useState(false);
  const [dataVendasOrig, setDataVendasOrig] = useState({
    vbruto: 0,
    vbrutoperiodo: 0,
    vbrutoano: 0,
    percentDiffFromLastPeriod: 0,
    percentDiffFromLastYearPeriod: 0,
  });

  const tagType = getTagTypeDashoard(tag);

  const setData = ({ current, pastMonth, pastYear, dtStart, dtEnd }) => {
    try {
      const getLabelPeriodo = id => {
        let periodo;
        if (id === "vbruto") {
          if (!current) {
            return BLANK_TEXT;
          }
          periodo = `${format(new Date(dtStart), "dd")} a ${format(
            new Date(dtEnd !== null ? dtEnd : dtStart),
            "dd/MM/yyyy",
          )}`;
        }

        if (id === "vbrutoperiodo") {
          if (!pastMonth) {
            return BLANK_TEXT;
          }
          periodo = `${format(new Date(dtStart), "dd")} a ${format(
            new Date(
              dtEnd !== null
                ? subMonths(new Date(dtEnd), 1)
                : subMonths(new Date(dtStart), 1),
            ),
            "dd/MM/yyyy",
          )}`;
        }
        if (id === "vbrutoano") {
          if (!pastYear) {
            return BLANK_TEXT;
          }
          periodo = `${format(new Date(dtStart), "dd")} a ${format(
            new Date(
              dtEnd !== null
                ? subYears(new Date(dtEnd), 1)
                : subYears(new Date(dtStart), 1),
            ),
            "dd/MM/yyyy",
          )}`;
        }

        return periodo;
      };

      const percentualDiff = (a, b) => {
        if (b > a) return -1 * (100 * Math.abs((a - b) / ((a + b) / 2)));
        return 100 * Math.abs((a - b) / ((a + b) / 2)).toFixed(2);
      };

      const percentDiffFromLastPeriod =
        current !== 0 ? percentualDiff(current, pastMonth) : 0;
      const percentDiffFromLastYearPeriod =
        current !== 0 ? percentualDiff(current, pastYear) : 0;

      const dVendasOrig = {
        vbruto: current,
        vbrutoperiodo: pastMonth,
        vbrutoano: pastYear,
        percentDiffFromLastPeriod,
        percentDiffFromLastYearPeriod,
      };

      const currentBlankValue =
        [current / 1000, pastMonth / 1000, pastYear / 1000].sort(
          (a, b) => b - a,
        )[0] - 0.02;

      setBlankValue(currentBlankValue);

      const dVendas = [
        {
          vbruto: current === 0 ? currentBlankValue : current / 1000,
          vbrutoperiodo: pastMonth === 0 ? currentBlankValue : pastMonth / 1000,
          vbrutoano: pastYear === 0 ? currentBlankValue : pastYear / 1000,
          dateLabelOne: getLabelPeriodo("vbruto"),
          dateLabelTwo: getLabelPeriodo("vbrutoperiodo"),
          dateLabelThree: getLabelPeriodo("vbrutoano"),
        },
      ];

      const lValue = Math.ceil(
        Math.max(current / 1000, pastMonth / 1000, pastYear / 1000),
      );

      let aLinear = [];

      // Todo: This part will be refactored

      if (lValue > 0 && lValue <= 600) {
        aLinear = [0, 120, 240, 360, 480, 600];
      }
      if (lValue > 600 && lValue <= 1000) {
        aLinear = [0, 600, 800, 900, 1000];
      }
      if (lValue > 1000 && lValue <= 3000) {
        aLinear = [0, 500, 1000, 1500, 2000, 3000];
      }
      if (lValue > 3000 && lValue <= 5000) {
        aLinear = [0, 1500, 3000, 4000, 5000];
      }
      if (lValue > 5000 && lValue <= 10000) {
        aLinear = [0, 2500, 5500, 8500, 10000];
      }
      if (lValue > 10000 && lValue <= 20000) {
        aLinear = [0, 5000, 10000, 150000, 20000];
      }

      setLinearValues(aLinear);
      setDataVendasOrig(dVendasOrig);
      setDataVendas(dVendas);
    } catch (e) {
      console.log(e);
    }
  };

  const handleClick = (start, end) => {
    const [dateStart, dateEnd] = getDatesMonths(start, end);
    setVendasDateStart(dateStart);
    setVendasDateEnd(dateEnd);
  };

  const handleClickType = item => {
    setTag(item);
  };

  useEffect(() => {
    if (vendasDateStart && vendasDateEnd) {
      const [dateStart, dateEnd] = getDatesMonths(
        vendasDateStart,
        vendasDateEnd,
      );
      setData({
        current: dataSalesCurrent?.data?.data ?? 0,
        pastMonth: dataSalesPastMonth?.data?.data ?? 0,
        pastYear: dataSalesPastYear?.data?.data ?? 0,
        dtStart: dateStart,
        dtEnd: dateEnd,
      });
    }
  }, [
    dataSalesCurrent?.data,
    dataSalesPastMonth?.data,
    dataSalesPastYear?.data,
    vendasDateEnd,
    vendasDateStart,
  ]);

  useEffect(() => {
    if (hasDates && vendasDateEnd && vendasDateStart) {
      const [dateStart, dateEnd] = getDatesMonths(
        vendasDateStart,
        vendasDateEnd,
      );

      fetchSales({
        dt_ini: FormatDate(dateStart),
        dt_fim: FormatDate(dateEnd),
        tipo: tagType,
        only: true,
      });
      fetchSalesPastMonth({
        dt_ini: FormatDate(subMonths(dateStart, 1)),
        dt_fim: FormatDate(subMonths(dateEnd, 1)),
        tipo: tagType,
        only: true,
      });

      fetchSalesPastYear({
        dt_ini: FormatDate(subYears(dateStart, 1)),
        dt_fim: FormatDate(subYears(dateEnd, 1)),
        tipo: tagType,
        only: true,
      });

      fetchSalesCnpj({
        dt_ini: FormatDate(dateStart),
        dt_fim: FormatDate(dateEnd),
        tipo: tagType,
      });
    }
  }, [
    vendasDateEnd,
    vendasDateStart,
    hasDates,
    tag,
    fetchSales,
    fetchSalesPastMonth,
    fetchSalesPastYear,
    tagType,
    tokenCompanies,
    fetchSalesCnpj,
  ]);

  useEffect(() => {
    const [monthDateStartSale, monthDateEndSale] = getDatesMonths(
      monthDateStart,
      monthDateEnd,
    );
    setVendasDateStart(monthDateStartSale);
    setVendasDateEnd(monthDateEndSale);
    if (monthDateEnd && monthDateEnd) setHasDates(true);
  }, [monthDateStart, monthDateEnd]);

  useEffect(() => {
    setIsLoadingDashBoards(isLoadingSale);
  }, [isLoadingSale, setIsLoadingDashBoards]);

  return (
    <>
      <MidCard
        title="Vendas"
        tag={fileType}
        addMonthsLength={0}
        typeList="month"
        subtitle="Período"
        total=""
        tagsList={[
          "XML",
          {
            dtStart: vendasDateStart,
            dtEnd: vendasDateEnd,
          },
        ]}
        handleClick={(start, end) => handleClick(start, end, "venda")}
        handleClickType={item => handleClickType(item)}
        cssClass="card-custom"
      >
        <MidCardContainer>
          <MidCardBlocOne>
            <LoadPlaceholder
              show={
                isLoadingSale || isLoadingSalePastMonth || isLoadingSalePastYear
              }
              showNoData={
                !(
                  isLoadingSale ||
                  isLoadingSalePastMonth ||
                  isLoadingSalePastYear
                ) && linearValues.length === 0
              }
            >
              {linearValues.length > 1 && (
                <ResponsiveBar
                  data={dataVendas}
                  keys={["vbruto", "vbrutoperiodo", "vbrutoano"]}
                  indexBy=""
                  margin={{ top: 1, right: 20, bottom: 45, left: 25 }}
                  padding={0.05}
                  enableGridY={false}
                  groupMode="grouped"
                  labelFormat={d => {
                    return <tspan x={d === BLANK_TEXT ? 70 : 48}>{d}</tspan>;
                  }}
                  layout="horizontal"
                  valueScale={{ type: "linear" }}
                  indexScale={{ type: "band", round: false }}
                  valueFormat={{ format: " >-$", enabled: true }}
                  colors={e => {
                    const colors = {
                      vbruto: "#A2C659",
                      vbrutoperiodo: "#cce39f",
                      vbrutoano: "#10b39d",
                    };

                    if (e.value === blankValue) {
                      return "rgb(255 255 255 / 24%)";
                    }

                    return colors[e.id];
                  }}
                  borderColor={{
                    from: "color",
                    modifiers: [["darker", 1.6]],
                  }}
                  axisTop={0}
                  axisRight={0}
                  axisBottom={{
                    tickSize: 5,
                    tickPadding: 0,
                    tickRotation: 0,
                    legend: "Valor (k)",
                    legendOffset: 30,
                    legendPosition: "middle",
                    tickValues: linearValues,
                  }}
                  axisLeft={{
                    tickSize: 0,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: "Período",
                    legendPosition: "middle",
                    legendOffset: -20,
                  }}
                  labelSkipWidth={10}
                  labelSkipHeight={12}
                  labelTextColor={e => {
                    if (e.data.value === blankValue) {
                      return "#596778";
                    }
                    return "#fff";
                  }}
                  label={e =>
                    e.id === "vbruto"
                      ? e.data.dateLabelOne
                      : e.id === "vbrutoperiodo"
                      ? e.data.dateLabelTwo
                      : e.data.dateLabelThree
                  }
                  tooltip={e => {
                    const title =
                      e.id === "vbruto"
                        ? "Valor Bruto"
                        : e.id === "vbrutoperiodo"
                        ? "Valor Bruto/Período"
                        : "Valor Bruto/Ano";
                    const value =
                      e.id === "vbruto"
                        ? e.data.vbruto
                        : e.id === "vbrutoperiodo"
                        ? e.data.vbrutoperiodo
                        : e.data.vbrutoano;
                    return (
                      <div
                        style={{
                          fontSize: "14px",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <span
                          style={{
                            width: "10px",
                            height: "10px",
                            backgroundColor: `${e.color}`,
                            marginRight: "5px",
                          }}
                        />
                        <p>
                          {`${title}: ${
                            value === blankValue
                              ? BLANK_TEXT
                              : (value * 1000).toLocaleString("pt-br", {
                                  style: "currency",
                                  currency: "BRL",
                                })
                          }`}
                        </p>
                      </div>
                    );
                  }}
                />
              )}
            </LoadPlaceholder>
            <LegendContainerSale>
              {legend.map(e => (
                <div>
                  <div className={e.className} />
                  <p>{e.title}</p>
                </div>
              ))}
            </LegendContainerSale>
          </MidCardBlocOne>

          <MidCardBlocTwo>
            {isLoadingSalePastMonth ? (
              <Loading />
            ) : (
              <p>{Format(dataVendasOrig?.vbruto)}</p>
            )}

            {isLoadingSalePastMonth ? (
              <Loading />
            ) : (
              <p>
                {`${Format(dataVendasOrig?.vbrutoperiodo)} (${
                  dataVendasOrig?.percentDiffFromLastPeriod
                    ? dataVendasOrig?.percentDiffFromLastPeriod?.toFixed(2)
                    : 0
                }%)`}
              </p>
            )}

            {isLoadingSalePastYear ? (
              <Loading />
            ) : (
              <p>
                {`${Format(dataVendasOrig?.vbrutoano)} (${
                  dataVendasOrig?.percentDiffFromLastYearPeriod
                    ? dataVendasOrig?.percentDiffFromLastYearPeriod?.toFixed(2)
                    : 0
                }%)`}
              </p>
            )}
            <div className="eye_icon_sales">
              <TooltipValues
                type="vendas"
                dtInit={FormatDate(
                  getDatesMonths(vendasDateStart, vendasDateEnd)[0],
                )}
                dtFim={FormatDate(
                  getDatesMonths(vendasDateStart, vendasDateEnd)[1],
                )}
                typeTag={tagType}
              />
            </div>
          </MidCardBlocTwo>
        </MidCardContainer>
      </MidCard>
    </>
  );
}

export default DashSales;
