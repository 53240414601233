import React from "react";
import styled, { css, keyframes } from "styled-components";
import {
  FaCircleNotch,
  FaExclamationCircle,
  FaCloudUploadAlt,
} from "react-icons/fa";

const rotate = keyframes`
  0%{
    transform: rotate(0deg);
  }

  100%{
    transform: rotate(360deg);
  }
`;

export const FilesContainer = styled.div`
  min-width: 500px;
`;

export const DropZoneContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f1f1f1;
  border: 2px dashed #707070;
  position: relative;
  transition: all 0.3s;
  width: 100%;
  height: 115px;

  &:hover {
    opacity: 0.8;
    background-color: #e2e2e2;
    box-shadow: 0px 2px 4px rgb(0 0 0 / 64%);

    svg {
      color: #000;
    }
    label {
      color: #000;
      span {
        color: #e70808;
      }
      p {
        color: #000;
      }
    }
  }

  label {
    transition: all 0.3s;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    span {
      display: inline-block;
      margin-left: 4px;
      color: #ee6b09;
    }

    p {
      color: #8c8b8b;
    }
  }

  input {
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: relative;
    z-index: 2;
    opacity: 0;
  }
`;

export const IconUpload = styled(({ ...rest }) => (
  <FaCloudUploadAlt {...rest} />
))`
  ${css`
    margin-left: 5%;
    color: #6a6a6a;
    &:hover {
      color: #000;
    }
  `}
`;

export const LabelUpload = styled.label`
  margin-left: 10%;
`;

export const LoadingIcon = styled(({ isLoading, ...rest }) => (
  <FaCircleNotch {...rest} />
))`
  ${props =>
    props.isLoading &&
    css`
      display: block;
      animation: ${rotate} 2s linear infinite;
    `}
`;

export const FileItem = styled.div`
  max-width: 500px;
  border-bottom: 1px solid #e5e5e5;
  padding: 8px;

  & > div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & svg {
      position: relative;
      flex-shrink: 0;
      z-index: 2;
      cursor: pointer;
    }

    & p {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  p {
    color: #8c8b8b;
  }

  .smallTxt {
    color: #ccc;
    font-size: 12px;
  }
`;

export const ContainerBtn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ReloadBtn = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;

  p {
    margin-right: 8px;
  }

  svg {
    cursor: pointer;
  }
`;
export const UpdateBtn = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  p {
    margin-right: 8px;
  }

  svg {
    cursor: pointer;
  }
`;

export const FilesDatabaseContainer = styled.div`
  width: 100%;
  max-height: 400px;

  padding: 16px 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${props => (props.isLoading ? "center" : "flex-start")};

  & > div:not(:last-of-type) {
    margin-bottom: 8px;
  }
`;

export const FilesCategoryContainer = styled.div`
  width: 100%;
  background-color: #e5e5e5;
  border-radius: 5px;
  overflow: auto;

  .list ${FileItem}:nth-child(odd) {
    background-color: #fff;
  }
  .list ${FileItem}:nth-child(even) {
    background-color: #f2f4f4;
  }
`;

export const FilesCategoryContainerHeader = styled.header`
  padding: 16px 8px;
  background-color: #596778;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  color: #fff;
  margin-bottom: 8px;
`;

export const FilesCategoryContainerInfo = styled.div`
  padding: 0 16px;
  p {
    color: #596778;
    font-size: 12px;
    margin-bottom: 4px;

    span {
      margin: 0 3px;
      font-weight: bold;
    }
  }
`;

export const FilesCategoryContainerInfoSearch = styled.div`
  background-color: #f2f4f4;
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 5px;
  margin-bottom: 24px;

  input {
    color: #aaaaaa;
    font-size: 12px;
    background-color: transparent;
  }

  svg {
    fill: #adc86a;
    margin-right: 8px;
  }
`;

export const LoadingFilesIcon = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FileErrorNotExistent = styled.div`
  width: 100%;
  height: 150px;
  padding-top: 20px;
  text-align: center;
  div {
    width: 100%;
  }
  h5 {
    font-size: 28px;
  }
`;

export const IconAlert = styled(({ ...rest }) => (
  <FaExclamationCircle {...rest} />
))``;
