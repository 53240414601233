import styled, { css } from "styled-components";

const colorStyles = {
  normal: {
    bg: "#5A8DEE",
    itemsColor: "#fff",
    border: "none",
  },
  secondary: {
    bg: "#475F7B",
    itemsColor: "#fff",
    border: "none",
  },
  success: {
    bg: "#ADC86A",
    itemsColor: "#fff",
    border: "none",
  },
  error: {
    bg: "#FF3333",
    itemsColor: "#fff",
    border: "none",
  },
  alert: {
    bg: "#F6B000",
    itemsColor: "#fff",
    border: "none",
  },
  info: {
    bg: "#BEDEEC",
    itemsColor: "#596778",
    border: "none",
  },
  alternative: {
    bg: "#fff",
    itemsColor: "#5A8DEE",
    border: "1px solid #5A8DEE",
  },
  notify: {
    bg: "#5A8DEE",
    itemsColor: "#fff",
    border: "1px solid #5A8DEE",
  },
};

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 30%;
  position: fixed;
  margin-right: 10px;
  ${props =>
    props.align === `normal` &&
    css`
      bottom: 0;
      right: 0;
      margin-bottom: 5%;
    `};
  ${props =>
    props.align === `top` &&
    css`
      margin-top: 8%;
      top: 0;
      right: 0;
    `};

  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
  padding: 16px;
  border-radius: 5px;
  background-color: ${props => colorStyles[props.type].bg};
  border: ${props => colorStyles[props.type].border};

  p {
    font-size: 15px;
    color: ${props => colorStyles[props.type].itemsColor};
  }

  svg {
    margin-right: 8px;
    fill: ${props => colorStyles[props.type].itemsColor};
  }

  .close {
    margin: 0;
    margin-left: auto;
  }
`;

export const ContainerDescription = styled.div`
  width: 332px;
  h3 {
    font-size: 15px;
    color: ${props => colorStyles[props.type].itemsColor};
  }
  p {
    font-size: 13px;
    color: ${props => colorStyles[props.type].itemsColor};
    overflow: hidden;
    white-space: nowrap;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    text-overflow: ellipsis;
  }
`;
