import styled from "styled-components";

export const Background = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
`;

export const BreadCrumbContainer = styled.div`
  align-items: center;
  svg {
    fill: ${({ activeTheme, theme }) =>
      activeTheme ? theme.colors.navyBlue : theme.colors.card.header.green};
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ButtonContainer = styled.button`
  background-color: ${({ activeTheme, theme }) =>
    activeTheme ? theme.colors.navyBlue : theme.colors.lightGreen};
  border-radius: 20px;
  box-shadow: ${({ theme }) => theme.shadows.default};
  font-size: 16px;
  height: 40px;
  transition: 0.5s;
  width: 120px;
  p {
    color: #fff;
    text-transform: capitalize;
  }
  &:hover {
    background-color: ${({ activeTheme, theme }) =>
      activeTheme ? theme.colors.navyBlueHover : theme.colors.lightGreenHover};
  }
`;

export const ButtonContainerWhite = styled.button`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 5px;
  box-shadow: ${({ theme }) => theme.shadows.default};
  font-size: 12px;
  font-weight: bold;
  height: 35px;
  transition: 0.4s;
  width: 80px;
  p {
    color: #596778;
    text-transform: capitalize;
  }
  &:hover {
    background-color: #596778;
    p {
      color: #fff;
      font-weight: bold;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  height: 100%;
  margin: 0 auto;
  max-width: 1920px;
  max-width: 100%;

  overflow: hidden;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const HeaderContainer = styled.div`
  background: #fff;
  border-bottom: 1px solid #ddd;
  width: 100%;
`;

export const Main = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: calc(100% - 75px);
`;

export const Wrapper = styled.div`
  padding: 0px 15px 0px 15px;
  box-sizing: content-box;
  margin-right: -15px;
  overflow: scroll;
`;

export const RouteContent = styled.div`
  flex: 1;
  height: 100%;
  min-width: 0px;
  overflow-y: scroll;
  width: 100%;
`;

export const ContentHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 10px 8px 20px 0px;
  width: 100%;
`;

export const WrapperPermissions = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const MenuHambItem = styled.div`
  background: #fff;
  height: 2px;
  transition: all 150ms;
  width: 100%;
`;

export const MenuHamb = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 27px;
  justify-content: space-between;
  left: -2px;
  margin: 20px auto 10px;
  padding: 5px;
  position: relative;
  width: 37px;
`;

export const MenuContainer = styled.div`
  background: ${({ activeTheme, theme }) =>
    activeTheme ? theme.colors.navyBlue : theme.colors.card.header.greenDarken};
  min-width: 75px;
  padding-bottom: 30px;
  position: relative;
  transition: all 250ms;
  z-index: 99;

  &.active {
    margin: 0;
    min-width: 150px;
    padding-bottom: 50px;
    transition: initial;
    width: 150px;

    ${MenuHamb} {
      left: 5px;
      position: relative;

      ${MenuHambItem} {
        opacity: 0;
        visiblity: hidden;

        &:first-child,
        &:last-child {
          opacity: 1;
          visibility: visible;
        }

        &:first-child {
          transform: rotate(45deg) translateY(10px);
        }

        &:last-child {
          transform: rotate(-45deg) translateY(-11px);
        }
      }
    }
  }
`;

export const ContainerFlag = styled.button`
  background: ${({ active, theme }) =>
    active ? theme.colors.card.header.greenDarken : theme.colors.navyBlue};
  border-radius: 5px 0 0 5px;
  color: #fff;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  height: 240px;
  margin-top: -120px;
  opacity: 0.5;
  padding: 3px;
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateX(18px);
  transition: all 250ms;
  white-space: nowrap;
  width: 40px;
  z-index: 999999;
  span {
    display: block;
    height: ${({ active }) => (active ? "90px" : "190px")};
    left: 10px;
    opacity: 0;
    position: relative;
    top: ${({ active }) => (active ? "80px" : "22px")};
    transform: rotate(-90deg);
    transition: all 250ms;
  }

  &:after {
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 8px solid
      ${({ active, theme }) =>
        active ? theme.colors.card.header.greenDarken : theme.colors.navyBlue};
    bottom: 0px;
    content: "";
    position: absolute;
    right: 150px;
    top: -6px;
    transform: rotate(90deg);
    z-index: -1;
  }

  &:hover {
    opacity: 1;
    transform: translateX(0);

    span {
      left: 7px;
      opacity: 1;
    }
  }
`;
