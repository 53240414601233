import { transformListSelect } from "utils/utils";

export const LAW_NORMS_LABELS = {
  LEI: "Lei",
  LEI_COMPLEMENTAR: "Lei Complementar",
  DECRETO: "Decreto",
  ATO: "Ato",
  ATO_NORMATIVO: "Ato Normativo",
  BOLETIM_INFORMATIVO: "Boletim Informativo",
  PARECER: "Parecer",
  CONVENCAO: "Convenção",
  EMENDA: "Emenda",
  SOLUCAO_CONSULTA: "Solução de consulta",
  RESOLUCAO: "Resolução",
  INSTRUCAO_NORMATIVA: "Instrução Normativa",
  PORTARIA: "Portaria",
  PORTARIA_CONJUTA: "Portaria Conjuta",
  PORTARIA_NORMATIVA: "Portaria Normativa",
  NOTA_EXPLICATIVA: "Nota explicativa",
  COMUNICADO: "Comunicado",
  ORIENTACAO: "Orientação",
  RESPOSTA_A_CONSULTA: "Resposta à consulta",
  GERENCIADOR_LEIS: "Gerenciador de Leis",
};

export const LawNorms = [
  { value: "1", label: LAW_NORMS_LABELS.LEI },
  { value: "2", label: LAW_NORMS_LABELS.LEI_COMPLEMENTAR },
  { value: "3", label: LAW_NORMS_LABELS.DECRETO },
  { value: "4", label: LAW_NORMS_LABELS.ATO },
  { value: "5", label: LAW_NORMS_LABELS.ATO_NORMATIVO },
  { value: "19", label: LAW_NORMS_LABELS.BOLETIM_INFORMATIVO },
  { value: "6", label: LAW_NORMS_LABELS.PARECER },
  { value: "7", label: LAW_NORMS_LABELS.CONVENCAO },
  { value: "8", label: LAW_NORMS_LABELS.EMENDA },
  { value: "9", label: LAW_NORMS_LABELS.SOLUCAO_CONSULTA },
  { value: "10", label: LAW_NORMS_LABELS.RESOLUCAO },
  { value: "11", label: LAW_NORMS_LABELS.INSTRUCAO_NORMATIVA },
  { value: "12", label: LAW_NORMS_LABELS.PORTARIA },
  { value: "13", label: LAW_NORMS_LABELS.PORTARIA_CONJUTA },
  { value: "14", label: LAW_NORMS_LABELS.PORTARIA_NORMATIVA },
  { value: "15", label: LAW_NORMS_LABELS.NOTA_EXPLICATIVA },
  { value: "16", label: LAW_NORMS_LABELS.COMUNICADO },
  { value: "17", label: LAW_NORMS_LABELS.ORIENTACAO },
  { value: "18", label: LAW_NORMS_LABELS.RESPOSTA_A_CONSULTA },
  { value: "19", label: LAW_NORMS_LABELS.GERENCIADOR_LEIS },
];

export const LAW_STATUS = {
  CLONE: "clone",
  ACTIVE: "active",
  INACTIVE: "inactive",
  DRAFT: "draft",
  DELETED: "deleted",
};

export const LawStatesCbenef = ["PR", "RJ", "RS", "DF"];

export const MVAS_TYPES = {
  Selecione: "Selecione",
  "Preço Final máximo ou tabelado": "Preço Final máximo ou tabelado",
  "MVA %)": "MVA %)",
  "Pauta - Valor": "Pauta - Valor",
};

export const MVAS_TYPES_LIST = transformListSelect(MVAS_TYPES);
