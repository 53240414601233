import React, { useState, useEffect, useRef, useCallback } from "react";
import { Icon } from "@mix/mixfiscal-designsystem";
import { v4 as uuidv4 } from "uuid";

import templatesApi from "../../../../../services/templatesApi";
import authenticationApi from "../../../../../services/authenticationApi";

import { useFlow } from "../../../../../context/FlowContext";
import Tabs from "../../../../UI/Tabs";
import LoadingIcon from "../../../../UI/LoadingIcon";
import {
  Container,
  Header,
  SectionContainer,
  ListContainer,
  AsideContainer,
} from "./styles";

const iconNameByFileType = {
  txt: "sped",
  xml: "xml",
  xls: "excel",
  csv: "csv",
  pdf: "pdf",
};

function SelectFile({
  selectedFileType,
  xmlTemplates,
  handleCloseDialog,
  handleBack,
}) {
  const [templates, setTemplates] = useState([]);
  const [files, setFiles] = useState([]);
  const [amountDatabaseFiles, setAmountDatabaseFiles] = useState(0);
  const [fileContent, setFileContent] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [spedType, setSpedType] = useState("FISCAL");
  const [spedTemplates, setSpedTemplates] = useState([]);

  const selectedFile = useRef(null);
  const filesRef = useRef(null);
  const fileContentRef = useRef(null);

  const { handleAddNode } = useFlow();

  const handleCreateSpedNode = ({ fields, register, nodeName }) => {
    const fieldsWithIsSelected = fields.map(field => {
      return { ...field, isSelected: false };
    });

    handleAddNode({
      ...selectedFileType,
      fields: fieldsWithIsSelected,
      register,
      label: nodeName,
    });
    handleCloseDialog();
  };

  const handleCreateNode = (columnFields, columnsIndex) => {
    const fields = columnFields.map(column => {
      return {
        _id: uuidv4(),
        description: column,
        isSelected: false,
      };
    });
    handleAddNode({
      ...selectedFileType,
      fields,
      selectedTemplateFileId: selectedFile.current.id,
      selectedFileColumnsIndex: columnsIndex,
    });
    handleCloseDialog();
  };

  const handleFilter = useCallback(() => {
    if (selectedFileType.dataType === "txt") {
      if (searchValue === "") {
        setTemplates(spedTemplates);
      } else {
        const filteredTemplates = spedTemplates.filter(template => {
          return template.register
            .toLowerCase()
            .includes(searchValue.toLowerCase());
        });
        setTemplates(filteredTemplates);
      }
    } else if (selectedFileType.dataType === "xml") {
      if (searchValue === "") {
        setTemplates(xmlTemplates);
      } else {
        const filteredTemplates = xmlTemplates.filter(template => {
          return template?.register
            ?.toLowerCase()
            ?.includes(searchValue.toLowerCase());
        });
        setTemplates(filteredTemplates);
      }
    } else if (files.length > 0) {
      if (searchValue === "") {
        setFiles(filesRef.current);
      } else {
        const filteredFiles = filesRef.current.filter(file => {
          return file.fileName
            .toLowerCase()
            .includes(searchValue.toLowerCase());
        });
        setFiles(filteredFiles);
      }
    } else if (fileContentRef.current && files.length === 0) {
      if (searchValue === "") {
        setFileContent(fileContentRef.current);
      } else {
        const filteredFileContent = fileContentRef.current.filter(content => {
          return content
            .join(" | ")
            .toLowerCase()
            .includes(searchValue.toLowerCase());
        });
        setFileContent(filteredFileContent);
      }
    }
  }, [
    selectedFileType.dataType,
    files.length,
    searchValue,
    spedTemplates,
    xmlTemplates,
  ]);

  const getFileContent = async fileId => {
    const token = localStorage.getItem("@mixfiscal:authenticatorToken");
    selectedFile.current = { id: fileId };
    setFiles([]);
    const { data: responseFileContent } = await templatesApi.get(
      `/files/file/${fileId}`,
      {
        headers: {
          Authorization: token,
        },
      },
    );

    if (
      responseFileContent.item.body &&
      responseFileContent.item.body.length > 0
    ) {
      setFileContent(responseFileContent.item.body[0].data);
      fileContentRef.current = responseFileContent.item.body[0].data;
    }
  };

  async function getSpedData(type) {
    const token = localStorage.getItem("@mixfiscal:authenticatorToken");
    const spedTemplatesResponse = await authenticationApi.get(
      `/spedmodels?limit=9999&page=1&type=${type}&version=001`,
      {
        headers: {
          Authorization: token,
        },
      },
    );
    setSpedTemplates(spedTemplatesResponse.data.spedmodels.docs);
  }

  useEffect(() => {
    getSpedData(spedType.toLocaleLowerCase());
  }, [spedType]);

  useEffect(() => {
    handleFilter();
  }, [handleFilter, searchValue]);

  useEffect(() => {
    if (selectedFileType === "txt" && spedTemplates) {
      setTemplates(spedTemplates);
    }

    if (selectedFileType === "xml" && xmlTemplates) {
      setTemplates(xmlTemplates);
    }
  }, [selectedFileType, spedTemplates, templates, xmlTemplates]);

  useEffect(() => {
    const getFiles = async () => {
      const token = localStorage.getItem("@mixfiscal:authenticatorToken");
      const fileType =
        selectedFileType.dataType === "txt"
          ? "sped"
          : selectedFileType.dataType;
      const {
        data: { data: responseFiles },
      } = await templatesApi.get(`/files/type/${fileType}`, {
        headers: {
          Authorization: token,
        },
      });
      setAmountDatabaseFiles(responseFiles.length);
      setFiles(responseFiles);
      filesRef.current = responseFiles;
    };
    getFiles();
  }, [selectedFileType]);

  return (
    <Container>
      <Header>
        <div className="searchBox">
          <Icon name="busca" size={14} color="#adc86a" />
          <input
            type="text"
            value={searchValue}
            onChange={e => setSearchValue(e.target.value)}
          />
        </div>
        <button type="button" onClick={handleBack}>
          <Icon name="voltar" size={16} />
          VOLTAR
        </button>
      </Header>
      <SectionContainer>
        <ListContainer>
          {selectedFileType.dataType === "txt" && (
            <Tabs
              items={["FISCAL", "CONTRIBUICOES"]}
              type="alternative"
              handleClickCallback={value => setSpedType(value)}
            />
          )}
          {(selectedFileType.dataType === "txt" ||
            selectedFileType.dataType === "xml") &&
            templates.length > 0 &&
            templates.map(({ _id, register, fields, type, name }) => {
              const nodeName =
                type === "fiscal"
                  ? `Fiscal - ${register}`
                  : `Contribuições - ${register}`;
              if (
                type === spedType.toLocaleLowerCase() ||
                selectedFileType.dataType === "xml"
              ) {
                return (
                  <div
                    key={_id}
                    onClick={() =>
                      handleCreateSpedNode({ nodeName, fields, register })
                    }
                    role="button"
                  >
                    <p className="text">{name}</p>
                  </div>
                );
              }

              return null;
            })}
          {fileContent.length === 0 &&
            selectedFileType?.dataType !== "txt" &&
            selectedFileType?.dataType !== "xml" &&
            (files?.length > 0 ? (
              files.map(file => (
                <div
                  key={file._id}
                  onClick={() => getFileContent(file._id)}
                  role="button"
                >
                  <p className="text">{file.fileName}</p>
                </div>
              ))
            ) : (
              <LoadingIcon
                isLoading={files?.length === 0}
                color="#0e7c34"
                size={32}
              />
            ))}
          {fileContent.length > 0 &&
            fileContent.map((content, index) => (
              <div
                key={content}
                onClick={() => handleCreateNode(content, index)}
                role="button"
              >
                <p className="text">{content.join(" | ")}</p>
              </div>
            ))}
        </ListContainer>
        <AsideContainer>
          <div className="selectedFile">
            <Icon
              name={iconNameByFileType[selectedFileType.dataType]}
              color="000"
            />
            <p className="text">{selectedFileType?.label}</p>
          </div>
          <div className="fileInfo">
            <p className="text">
              Você possui
              <span>{amountDatabaseFiles}</span>
              arquivos
              <span>{selectedFileType.label}</span>
            </p>
            <p className="text">Último arquivo enviado:</p>
            <p className="text bold">28/01/2020</p>
          </div>
          <button type="button">
            <Icon name="back" />
            GERENCIAR ARQUIVOS
          </button>
        </AsideContainer>
      </SectionContainer>
    </Container>
  );
}

export default SelectFile;
