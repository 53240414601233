import React from "react";
import styled, { css, keyframes } from "styled-components";
import { FaCircleNotch } from "react-icons/fa";

const rotate = keyframes`
  0%{
    transform: rotate(0deg);
  }

  100%{
    transform: rotate(360deg);
  }
`;

export const Container = styled.div`
  width: ${props => props.width};
  height: ${props => props.height};
  margin-top: ${props => props.marginTop};

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Icon = styled(({ isLoading, ...rest }) => (
  <FaCircleNotch {...rest} />
))`
  ${props =>
    props.isLoading &&
    css`
      display: block;
      animation: ${rotate} 2s linear infinite;
    `}
`;
