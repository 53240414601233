import React from "react";
import { Card } from "@mix/mixfiscal-designsystem";
import { Button } from "components/structure";
import { NcmsCollectionsProvider } from "context/NcmsCollections";
import { ResultContainer } from "pages/Classificacao/Ncms/Result";
import { NCMS } from "constants/routes";
import * as S from "./styles";

export const NcmsCollectionsList = () => {
  return (
    <NcmsCollectionsProvider>
      <S.Header />
      <S.Container>
        <S.ContainerResult>
          <Card headerColor="#5A8DEE" textColor="#fff" title="RESULTADOS">
            <S.ContainerHeader>
              <S.ContainerHeaderAction>
                <Button label="Novo NCM" toLink={NCMS.CREATE} />
              </S.ContainerHeaderAction>
            </S.ContainerHeader>
            <ResultContainer />
          </Card>
        </S.ContainerResult>
      </S.Container>
    </NcmsCollectionsProvider>
  );
};
