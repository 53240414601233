import React, { useState, useEffect } from "react";
import { Button } from "@mix/mixfiscal-designsystem";
import {
  useRegisterLawsGradesContext,
  RegisterLawsContextProvider,
} from "context/RegisterLawsContext";
import { hasPisCofinsTax } from "utils/context";
import { GRADE_MODALS_TYPES } from "constants/Types/index";
import { MoveProductsModal } from "../MoveProductsModal";
import { RemoveProductsModal } from "../RemoveProductsModal";
import { MovedProductsModal } from "../MovedProductsModal";
import { AddProductsModal } from "../AddProductsModal";
import { ResultsProductsWithFilter } from "./ListProductsWithFilter";
import * as S from "./styles";

export const getProductsGradeParams = ({
  page,
  limit,
  params = {},
  filters = {},
}) => {
  const paramsUsing = {
    ncms: params?.ncms,
    cests: params?.cests,
    page,
    limit,
    ...filters,
  };

  if (params?.ncms !== "[]" || params?.gradeId || params?.lawId) {
    paramsUsing.rmGrades = params?.rmGrades;
    if (params?.gradeId) {
      paramsUsing.gradeId = params?.gradeId;
    }

    // Pesquisa produtos pelo tipo da grade de icms
    if (
      (params?.icms || params?.gradeId || params?.lawId) &&
      !hasPisCofinsTax(params.type)
    ) {
      paramsUsing.productsByGradeIcms = 1;
    }

    // Pesquisa os produtos pelo tipo da grade piscofins
    if (hasPisCofinsTax(params.type)) {
      paramsUsing.productsByGradePisCofins = 1;
    }

    if (params?.lawId) {
      paramsUsing.lawId = params?.lawId;
    }

    return paramsUsing;
  }

  return null;
};

const Header = ({ params, isNewLaw }) => {
  const {
    values,
    removeProducts,
    clearGradeMove,
    clearProduct,
    addProductsMoveSelecteds,
    values: valuesLawsGrades,
    addProductsAddSelecteds,
    setProductsClear,
  } = useRegisterLawsGradesContext();

  const [modalOpen, setModalOpen] = useState(null);

  const handleModalClose = () => setModalOpen(null);

  const handleConfirm = type => () => {
    if (type === "remove") {
      removeProducts();
    }
    handleModalClose();
  };

  const handleModal =
    (type = null) =>
    () =>
      setModalOpen(type);

  const confirmMoveProducts = (grades = []) => {
    handleModalClose(false);
    const products = [];

    for (const grade of grades) {
      for (const product of values.productsMove) {
        products.push({
          ...product,
          gradeId: grade._id,
          gradeMove: grade,
        });
      }
    }

    clearGradeMove();
    clearProduct();
    addProductsMoveSelecteds(products);
  };

  const confirmAddProducts = () => {
    handleModalClose();
    addProductsAddSelecteds(
      values.productsAdd.map(product => ({
        ...product,
        product,
      })),
    );
    setProductsClear();
  };

  useEffect(() => {
    if (isNewLaw) {
      addProductsAddSelecteds(
        values.productsMove.map(product => ({
          ...product,
          product,
          gradeId: "",
        })),
      );
    }
  }, [
    isNewLaw,
    values?.productsMove,
    values?.productsMove?.length,
    addProductsAddSelecteds,
  ]);

  return (
    <>
      <RegisterLawsContextProvider>
        <MoveProductsModal
          isOpen={modalOpen === GRADE_MODALS_TYPES.PRODUCTS_MOVE}
          handleClose={handleModalClose}
          handleConfirm={confirmMoveProducts}
          lawsValues={valuesLawsGrades}
          productsSelecteds={values}
        />
        <RemoveProductsModal
          isOpen={modalOpen === GRADE_MODALS_TYPES.PRODUCTS_DELETE}
          handleClose={handleModalClose}
          handleConfirm={handleConfirm("remove")}
          productsSelecteds={values}
        />
        <MovedProductsModal
          isOpen={[
            GRADE_MODALS_TYPES.PRODUCTS_CONFIRMEDS_REMOVE,
            GRADE_MODALS_TYPES.PRODUCTS_CONFIRMEDS_MOVE,
            GRADE_MODALS_TYPES.PRODUCTS_CONFIRMEDS_ADD,
          ].includes(modalOpen)}
          handleClose={handleModalClose}
          // TODO: Adicionar confirmar ao mover produtos
          handleConfirm={handleModalClose}
          type={modalOpen}
          products={
            modalOpen === GRADE_MODALS_TYPES.PRODUCTS_CONFIRMEDS_MOVE
              ? values.productsMoveSelecteds
              : values.productsAddSelecteds
          }
          isNewLaw={isNewLaw}
        />
        {!isNewLaw && (
          <AddProductsModal
            isOpen={modalOpen === GRADE_MODALS_TYPES.PRODUCTS_ADD}
            handleClose={handleModalClose}
            handleConfirm={confirmAddProducts}
            productsSelecteds={values}
            refLawId={params?.lawId}
          />
        )}
      </RegisterLawsContextProvider>
      <S.HeaderResultProducts>
        <S.ActionsProducts>
          {values.productsMove?.length > 0 && !isNewLaw && (
            <>
              <Button
                label={`Mover produtos (${values.productsMove.length})`}
                handleClick={handleModal(GRADE_MODALS_TYPES.PRODUCTS_MOVE)}
              />
              <Button
                label={`Remover produtos (${values.productsMove.length})`}
                handleClick={handleModal(GRADE_MODALS_TYPES.PRODUCTS_DELETE)}
              />
            </>
          )}
          {params?.lawId && (
            <Button
              label="Adicionar produtos"
              handleClick={handleModal(GRADE_MODALS_TYPES.PRODUCTS_ADD)}
            />
          )}
        </S.ActionsProducts>
        <S.ActionsProducts>
          {values?.productsMoveSelecteds?.length > 0 && (
            <Button
              label={`${
                isNewLaw ? "Produtos selecionados" : "Produtos movidos"
              } (${values.productsMoveSelecteds.length})`}
              handleClick={handleModal(
                GRADE_MODALS_TYPES.PRODUCTS_CONFIRMEDS_MOVE,
              )}
            />
          )}
          {values?.productsAddSelecteds?.length > 0 && (
            <Button
              label={`Produtos selecionados (${values.productsAddSelecteds.length})`}
              handleClick={handleModal(
                GRADE_MODALS_TYPES.PRODUCTS_CONFIRMEDS_ADD,
              )}
            />
          )}
        </S.ActionsProducts>
      </S.HeaderResultProducts>
    </>
  );
};

export const ResultsProducts = props => (
  <ResultsProductsWithFilter header={<Header {...props} />} {...props} />
);
