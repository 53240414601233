import React, { Suspense } from "react";
import "@hdn/mixfiscal-diagnostico-speds-contribuicoes/dist/index.css";
import { useUser } from "useCases/user";
import { usePermissions } from "context/PermissionsContext";
import * as S from "./styles";

const WrapperSpedDiagnostic = React.lazy(() =>
  import("@hdn/mixfiscal-diagnostico-speds-contribuicoes"),
);

export const SpedDiagnostic = () => {
  const permissions = usePermissions();
  const { data } = useUser();
  return (
    <S.Container>
      <Suspense fallback={<div>Carregando...</div>}>
        <WrapperSpedDiagnostic
          {...permissions}
          token={data?.token}
          page="speed"
        />
      </Suspense>
    </S.Container>
  );
};
