import styled from "styled-components";

export const Container = styled.div`
  /* position: absolute;
  bottom: 0;
  z-index: 10000; */

  /* width: 100%;
  height: 75%; */
  background-color: #fff;

  display: flex;
  min-width: 1000px;
  flex-direction: column;
`;

export const Header = styled.div`
  width: 100%;
  height: 60px;
  background-color: #5a8dee;
  padding: 16px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  & > div {
    display: flex;
    align-items: center;
  }

  svg {
    margin-right: 8px;
  }

  p {
    color: #fff;
    text-transform: uppercase;
  }
`;

export const CloseButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #fff;
  padding: 8px;
  cursor: pointer;

  svg {
    fill: #5a8dee;
    margin: 0;
  }
`;

export const Content = styled.div`
  flex: 1;
  padding: 16px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  overflow: auto;

  ::-webkit-scrollbar {
    height: 12px;
    cursor: pointer;
  }

  ::-webkit-scrollbar-track {
    background-color: #bababa;
    cursor: pointer;
  }

  ::-webkit-scrollbar-thumb {
    background: #565656;
    border-radius: 20px;
    cursor: pointer;
  }
`;

export const Card = styled.div`
  width: 225px;
  height: 275px;
  border: 1px solid #bababa;
  flex-shrink: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: ${props => (props.snapshot.isDragging ? "#ddd" : "#eee")};

  .header {
    background-color: ${props => (props.isOperations ? "#ff8507" : "#e5e5e5")};
    padding: 8px;
    color: ${props => (props.isOperations ? "#fff" : "#596778")};

    h1 {
      font-size: 16px;
      text-transform: uppercase;
    }

    h2 {
      font-size: 16px;
      font-weight: 700;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .list {
    flex: 1;
    overflow: auto;
    background-color: ${props => (props.isOperations ? "#ff8507" : "#e5e5e5")};

    p {
      padding: 4px 8px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      border-bottom: 1px solid #e5e5e5;
      color: ${props => (props.isOperations ? "#fff" : "#596778")};
    }

    p:not(:last-of-type) {
      padding-bottom: 0px;
    }
  }
`;

export const AddOutputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  width: 100%;

  input {
    border-bottom: 1px solid #e5e5e5;
    margin-right: 16px;
    width: 300px;
  }

  .icon {
    width: 40px;
    height: 40px;
    padding: 8px;
    border-radius: 50%;
    background-color: #eee;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s;
    cursor: pointer;

    &:hover {
      background-color: #ccc;
    }
  }
`;

export const DroppableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const DroppableArea = styled.div`
  width: 100%;
  min-height: 350px;
`;

export const DraggableContainer = styled.div`
  background-color: ${props =>
    props.snapshot.isDraggingOver ? "lightblue" : "#fff"};
  width: 100%;
  min-height: 350px;
  display: flex;
  overflow: auto;
  ::-webkit-scrollbar {
    height: 12px;
    cursor: pointer;
  }

  ::-webkit-scrollbar-track {
    background-color: #bababa;
    cursor: pointer;
  }

  ::-webkit-scrollbar-thumb {
    background: #565656;
    border-radius: 20px;
    cursor: pointer;
  }
`;

export const DraggableItem = styled.div`
  user-select: none;
  color: white;
  padding: 8px 0;

  &:not(:last-of-type) {
    margin-right: 16px;
  }
`;
