import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import MyTemplates from "./Modules/MyTemplates";
import { useToast } from "../../context/ToastContext";
import { useFlow } from "../../context/FlowContext";
import templatesApi from "../../services/templatesApi";

import { Container, BlocoContainer, BlocoOne } from "./styles";

function Dashboard() {
  const history = useHistory();
  const { handleLoadFlow } = useFlow();

  const [flows, setFlows] = useState([]);
  const { addToast } = useToast();

  const handleFlowSelection = flow => {
    handleLoadFlow(flow);
    history.push("/editor");
  };

  const handleRemoveFlow = id => {
    templatesApi
      .delete(`/flows/flow/${id}`)
      .then(() => {
        setFlows(prevState => prevState.filter(flow => flow._id !== id));
        addToast({
          type: "success",
          title: "Sucesso",
          description: "Flow removido com sucesso.",
        });
      })
      .catch(err => console.log({ err }));
  };

  useEffect(() => {
    templatesApi
      .get("/flows")
      .then(res => setFlows(res.data.data))
      .catch(err => console.log(err));
  }, []);

  return (
    <Container>
      <section>
        <BlocoContainer>
          <BlocoOne>
            <MyTemplates
              flows={flows}
              handleFlowSelection={handleFlowSelection}
              handleRemoveFlow={handleRemoveFlow}
            />
          </BlocoOne>
        </BlocoContainer>
      </section>
    </Container>
  );
}

export default Dashboard;
