import React, { useEffect, useState } from "react";
import Select from "react-select";

import authenticationApi from "../../../../../services/authenticationApi";

import { CardWithLateralHeader, InputContainer, Header } from "./styles";

export const NcmEx = ({ ncms, setValue }) => {
  const [ncmEx, setNcmEx] = useState([]);

  useEffect(() => {
    async function getNcmEx() {
      const exs = [];
      if (ncms)
        for (const ncm of ncms) {
          try {
            const { data } = await authenticationApi.get(
              `/ncm?nivel=Subitem&ncm=${ncm.data.vc_codigo_sem_formatacao}`,
            );

            const regexEx = new RegExp(/Ex [0-9]{2}/g);

            // eslint-disable-next-line no-unused-expressions
            data.data?.forEach(ex => {
              if (regexEx.test(ex.vc_descricao)) {
                const [, numEx] = ex.vc_descricao.split(" ");
                exs.push({
                  label: `${ncm.data.vc_codigo_sem_formatacao} EX - ${numEx}`,
                  value: numEx,
                });
              }
            });
          } catch (e) {
            console.log("NcmEx error", e);
            // chamar toast
          }
        }
      setNcmEx(exs);
    }

    getNcmEx();
  }, [ncms, setNcmEx]);

  return (
    <CardWithLateralHeader>
      <Header>EXCEÇÃO NCM</Header>
      <main>
        <div className="input-container">
          <InputContainer maxWidth="100%">
            <label>Exceção NCM</label>
            <Select
              name="data"
              label="Exceção NCM"
              labelType="top"
              options={(ncmEx || []).map(ex => ({
                value: ex.value,
                label: ex.label,
              }))}
              onChange={option => setValue(`data`, option.value)}
            />
          </InputContainer>
        </div>
      </main>
    </CardWithLateralHeader>
  );
};

export default NcmEx;
