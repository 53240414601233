import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Datatable, Pagination } from "@mix/mixfiscal-designsystem";
import IconComponent from "components/IconExporter/index";
import { useACL } from "context";

import { useAccessGroups } from "useCases";
import Card from "components/UI/Card";

import * as S from "../../styles";

export const ListGroups = ({ baseUrl, selectedCompany }) => {
  // FIXME: Mudar para o SLUG (wesley)
  const { hasEdit, hasCreate } = useACL("access-group");
  const history = useHistory();
  const [limit, setLimit] = useState(100);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  const { isLoading: isLoadingGroups, data: dataGroups } = useAccessGroups({
    page,
    limit,
    companyId: selectedCompany
      ? selectedCompany.length > 0
        ? selectedCompany[0]._id
        : ""
      : "",
  });

  useEffect(() => {
    if (dataGroups?.users?.docs.length > 0) {
      setTotalItems(dataGroups?.users?.totalDocs);
      setTotalPages(dataGroups?.users?.totalPages);
    }
  }, [dataGroups, setTotalPages, setTotalItems]);

  const groupButton = group => {
    const onclick = () => {
      if (hasEdit) {
        history.push(`/usuarios-e-configs/grupos-de-acesso/${group._id}`);
      }
    };
    return (
      <div role="button" onClick={onclick}>
        <IconComponent name="edit" className="edit-icon" />
      </div>
    );
  };

  return (
    <>
      <Card title="GRUPOS DE ACESSO" headerColor="#256925" iconName="erp">
        <S.CardSubHeader>
          <S.CardSubHeaderDescription>
            <p className="title">
              Selecione um grupo de acesso para editar ou crie um novo grupo de
              acesso.
            </p>
            {totalItems ? (
              <p>{`Mostrando ${limit} de ${totalItems} resultado(s)`}</p>
            ) : (
              ``
            )}
          </S.CardSubHeaderDescription>

          <S.CardSubHeaderAction>
            {hasCreate && (
              <S.CardSubHeaderActionButton
                onClick={() => history.push(`${baseUrl}/grupos-de-acesso/new`)}
              >
                CRIAR GRUPO DE ACESSO
              </S.CardSubHeaderActionButton>
            )}
          </S.CardSubHeaderAction>
        </S.CardSubHeader>
        <S.CardSubItemPagination>
          <Pagination
            handlePageChange={setPages}
            initialPage={page}
            siblingsCount={1}
            totalPages={totalPages}
            amountVariations={[10, 20, 30, 300, 500]}
            handleChangeAmount={setLimit}
          />
        </S.CardSubItemPagination>
        <div className="div-overflow storestest">
          <Datatable
            pagination={false}
            amountVariations={[10, 20, 30, 300, 500]}
            isLoading={isLoadingGroups}
            size="12px"
            columns={[
              { field: "action", title: "" },
              { field: "groupName", title: "GRUPOS DE ACESSO" },
            ]}
            rawData={dataGroups?.accessGroups.docs.map(group => ({
              action: hasEdit && groupButton(group),
              groupName: group.groupName ? group.groupName : "",
            }))}
          />
        </div>
      </Card>
    </>
  );
};

export default ListGroups;
