import React from "react";
import { Tooltip, Datatable, Button } from "@mix/mixfiscal-designsystem";

import { Alert } from "components/structure";
import { useNcmNivel } from "useCases/ncm";

import * as S from "./LawNcmAlert.styles";

const LawNcmsPreview = ({ ncms }) => (
  <S.ListNcms>
    <Datatable
      columns={[
        {
          field: "value",
          title: "NCM",
        },
        { field: "label", title: "Descrição" },
      ]}
      pagination={false}
      rawData={ncms.filter(ncm => ncm.value !== "Selecione")}
    />
  </S.ListNcms>
);

export const LawReplicateAlertContent = ({
  ncmBegin,
  ncmEnd,
  totalNcms = 0,
  ncms = [],
  handleConfirm,
  handleCancel,
}) => (
  <>
    <p>
      {`Ncm incompleto: `}
      <b>{ncmBegin}</b>
      {` com um total de itens `}
      <Tooltip
        isPopOver
        title="Adicionar cenário ao filtro"
        position="top"
        zIndex={1000}
        component={<LawNcmsPreview ncms={ncms} />}
      >
        <b>{totalNcms}</b>
      </Tooltip>
    </p>
    <p>{`Adicionado um intervalo de NCM's que começa com ${ncmBegin} e termina com ${ncmEnd}`}</p>

    <div style={{ display: "flex", justifyContent: "center" }}>
      <Button
        label="Adicionar"
        handleClick={() => handleConfirm(ncms, ncmBegin)}
      />
      <div style={{ marginLeft: 15 }}>
        <Button
          label="Cancelar"
          color="red"
          handleClick={() => handleCancel(ncmBegin)}
        />
      </div>
    </div>
  </>
);

export const LawReplicateAlertContentWrapper = ({
  ncmBegin,
  handleConfirm,
  handleCancel,
}) => {
  const { dataMap: subItemsData } = useNcmNivel("item", { ncm: ncmBegin });

  const ncms = subItemsData?.filter(ncm => ncm.value !== "Selecione") ?? [];
  const totalNcms = ncms?.length;
  const ncmEnd = totalNcms && subItemsData[totalNcms - 1]?.value;

  return (
    <LawReplicateAlertContent
      ncmBegin={ncmBegin}
      totalNcms={totalNcms}
      ncmEnd={ncmEnd}
      ncms={ncms}
      handleConfirm={handleConfirm}
      handleCancel={handleCancel}
    />
  );
};

export const LawNcmAlert = props => {
  return (
    <Alert
      type="info"
      title="Alerta"
      content={<LawReplicateAlertContentWrapper {...props} />}
    />
  );
};
