import styled, { css } from "styled-components";

const modifiersAlert = {
  danger: () => css`
    border-color: red;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  `,

  info: () => css`
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb;
  `,
  success: () => css`
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  `,

  warning: () => css`
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
  `,

  light: () => css`
    color: #818182;
    background-color: #fefefe;
    border-color: #fdfdfe;
  `,

  dark: () => css`
    color: #1b1e21;
    background-color: #d6d8d9;
    border-color: #c6c8ca;
  `,
  primary: () => css`
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff;
  `,
};

export const Alert = styled.div`
  ${({ type }) => css`
    width: 100%;
    border-radius: 3px;
    margin-bottom: 15px;
    margin-top: 15px;
    padding: 10px;
    color: #721c24;
    border: 1px solid;

    ${type === "danger" && modifiersAlert.danger({ type })}
    ${type === "info" && modifiersAlert.info({ type })}
    ${type === "success" && modifiersAlert.success({ type })}
    ${type === "warning" && modifiersAlert.warning({ type })}
    ${type === "light" && modifiersAlert.light({ type })}
    ${type === "dark" && modifiersAlert.dark({ type })}
    ${type === "primary" && modifiersAlert.primary({ type })}
  `}
`;
