import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useParams } from "react-router-dom";
// import { isNode } from "react-flow-renderer";
// import { IoIosUndo } from "react-icons/io";
// import { BiCommentDetail, BiStar } from "react-icons/bi";
import { GiSaveArrow } from "react-icons/gi";
import { VscExpandAll } from "react-icons/vsc";
// import { IoText } from "react-icons/io5";
// import { MdLink, MdShare } from "react-icons/md";
// import { useFlow } from "../../context/FlowContext";
import { useToast } from "../../context/ToastContext";
import authenticationApi from "../../services/authenticationApi";

import DataTable from "../../components/DataTable";
import { Container } from "./styles";

function Report() {
  const [isEditing, setIsEditing] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  // const [inputValue, setInputValue] = useState("");

  const [columns, setColumns] = useState([]);
  const [datatableData, setDatatableData] = useState([]);
  const [data, setData] = useState([]);

  const location = useLocation();
  const { id } = useParams();
  const { addToast } = useToast();

  console.log({ setColumns, setDatatableData });

  const fetchData = useCallback(async reports => {
    console.log({ reports });
    if (reports && reports.length > 0) {
      setTitle(reports[0].name);

      const datatableFormatedData = reports.map(report => {
        return report.payload;
      });

      setData(datatableFormatedData);
    }
  }, []);

  const handleSaveReport = async () => {
    const token = localStorage.getItem("@mixfiscal:authenticatorToken");
    try {
      if (id) {
        await authenticationApi.put(
          `/report/${id}`,
          {
            name: title,
            content: JSON.stringify({ rows: datatableData, columns }),
          },
          {
            headers: {
              Authorization: token,
            },
          },
        );
        addToast({
          title: "Relatório",
          type: "success",
          description: "Relatório salvo com sucesso.",
        });
      } else {
        await authenticationApi.post(
          `/report`,
          {
            name: title,
            content: JSON.stringify(data),
          },
          {
            headers: {
              Authorization: token,
            },
          },
        );
        addToast({
          title: "Relatório",
          type: "success",
          description: "Relatório criado com sucesso.",
        });
      }
    } catch (err) {
      console.log({ err });
    }
  };

  const getReportData = async reportId => {
    const token = localStorage.getItem("@mixfiscal:authenticatorToken");
    const {
      data: { reports: report },
    } = await authenticationApi.get(`/report/${reportId}`, {
      headers: {
        Authorization: token,
      },
    });

    if (report) {
      setTitle(report.name);
      setData(JSON.parse(report.content));
    }
  };

  useEffect(() => {
    if (id) {
      try {
        getReportData(id);
      } catch (err) {
        console.log({ err });
      }
    } else {
      const { reports } = location?.state ?? {};
      fetchData(reports);
    }
  }, [fetchData, id, location?.state]);

  return (
    <Container>
      <header>
        <div className="instruction-bar">
          <div className="page-title">
            <p>Configuração do Relatório</p>
          </div>
          {/* <div className="tip-wrapper">
              <IoIosUndo className="icon" size={18} />
              <p>Desfazer (Ctrl+Z)</p>
            </div>
            <div className="tip-wrapper">
              <BiCommentDetail className="icon" size={18} />
              <p>Comentário (Ctrl+C)</p>
            </div> */}
          <div role="button" className="tip-wrapper" onClick={handleSaveReport}>
            <GiSaveArrow className="icon" size={18} />
            <p>Salvar (Ctrl+S)</p>
          </div>
          <div className="expand-wrapper">
            <VscExpandAll className="icon" size={24} />
          </div>
        </div>
        {/* <div className="tool-bar">
            <ul className="tool-menu">
              <li className="tool-item">
                <MdLink size={28} />
                <span>Título</span>
              </li>
              <li className="tool-item">
                <IoText size={28} />
                <span>Texto</span>
              </li>
              <li className="tool-item active">
                <VscGraph size={28} />
                <span>Gráficos</span>
              </li>
              <li className="tool-item">
                <MdLink size={28} />
                <span>Pré Visualizar</span>
              </li>
              <li className="tool-item">
                <MdShare size={28} />
                <span>Publicar</span>
              </li>
            </ul>
            <div className="api-key-wrapper">
              <BiStar size={20} />
              <p>
                <span>Chave API Pública:</span>
                573123024RASDASDAXASDA-ASDASD-XASDJF8
              </p>
            </div>
          </div> */}
      </header>
      <div className="board-wrapper">
        <section className="column1">
          <div className="double-board">
            <div className="board-row medium-size">
              {isEditing === "title" || !!title ? (
                <input
                  type="text"
                  value={title}
                  onChange={e => setTitle(e.target.value)}
                />
              ) : (
                <h2 role="button" onClick={() => setIsEditing("title")}>
                  INSIRA SEU TÍTULO
                </h2>
              )}
            </div>
            <div className="board-row medium-size" />
          </div>
          <div className="board-row small-size">
            {isEditing === "description" ? (
              <input
                type="text"
                value={description}
                onChange={e => setDescription(e.target.value)}
              />
            ) : (
              <p role="button" onClick={() => setIsEditing("description")}>
                Descrição do relatório
              </p>
            )}
          </div>
          {/* <div className="board-row small-size">
              <p>Gráfico</p>
            </div> */}
          <div className="board-row large-size flex-column datatable-container">
            {data &&
              data.length > 0 &&
              data.map(dataTableValues => {
                return (
                  <DataTable
                    key={Math.random()}
                    rawData={dataTableValues.rows}
                    columns={dataTableValues.columns}
                  />
                );
              })}
          </div>
          {/* <div className="double-board">
              <div className="board-row large-size">
                <p>Gráfico</p>
              </div>
              <div className="board-row large-size">
                <p>Texto</p>
              </div>
            </div> */}
        </section>
        <section className="column2">
          <div className="graph-chart">
            <div className="graph-title">
              <p>Gráficos</p>
            </div>
            <div className="graph-row" />
            <div className="graph-row" />
            <div className="graph-row-group">
              <div className="graph-row smaller" />
              <div className="graph-row smaller" />
              <div className="graph-row smaller" />
            </div>
            <div className="graph-row" />
            <div className="graph-row-group">
              <div className="graph-row smaller" />
              <div className="graph-row" />
            </div>
          </div>
        </section>
      </div>
    </Container>
  );
}

export default Report;
