import React from "react";
import { Tooltip } from "@mix/mixfiscal-designsystem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCertificate,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import { formatCnpj } from "utils";

import * as S from "./styles";

export const CustomTooltip = ({ dias }) => {
  const active = value => {
    if (value && value.toString().substr(0, 1) !== `-`) {
      return {
        title: `Seu certificado venceu tem:`,
        active: false,
      };
    }
    return {
      title: `Seu certificado vence em:`,
      active: true,
    };
  };
  return (
    <>
      <S.Title>
        <S.TitleText>
          <h5>Certificados A1</h5>
        </S.TitleText>
      </S.Title>
      <S.ContainerTooltip>
        <h4>{active(dias).title}</h4>
        <S.DaysCertified active={active(dias).active}>
          <h5>
            {dias.toString().substr(0, 1) === `-`
              ? dias.toString().substr(1)
              : dias}
          </h5>
          <h6>dias</h6>
        </S.DaysCertified>
      </S.ContainerTooltip>
    </>
  );
};

export const OptionsComponent = ({ data: { data } }) => {
  const active = value => {
    if (value && value.toString().substr(0, 1) !== `-`) {
      return {
        active: `inativo`,
      };
    }
    return {
      active: `ativo`,
    };
  };

  return (
    <S.OptionsCustom active={data.isActive}>
      <S.OptionsCustomLabel>
        <S.OptionsCustomLabelText>
          <S.OptionsCustomLabelTextItem fontSize="12px">
            {data.companyName || data.legalNature || data.tradeName}
          </S.OptionsCustomLabelTextItem>
          {data.taxRegime && (
            <S.OptionsCustomLabelTextItem fontSize="10px">
              {data.taxRegime}
            </S.OptionsCustomLabelTextItem>
          )}
        </S.OptionsCustomLabelText>
        <S.OptionsCustomLabelItems>
          <S.Cnpj>{formatCnpj(data.cnpj)}</S.Cnpj>
          <S.OptionsCustomLabelItemsBadge>
            <S.Badge type={data.type}>{data.type}</S.Badge>
            <Tooltip
              body={`O Status da sua "${data.type}" ${
                data.isActive === true ? `ATIVO` : `INATIVA`
              }`}
              position="bottom"
              title={`Status da ${data.type}`}
              zIndex={99999}
            >
              <S.Badge type={data.isActive ? `ativo` : `inativo`}>
                {data.isActive === true ? `ATIVO` : `INATIVA`}
              </S.Badge>
            </Tooltip>
            {data?.fadel && data?.certificate ? (
              <S.Badge type={active(data?.fadel?.dias_vencidos).active}>
                <Tooltip
                  component={
                    <CustomTooltip dias={data?.fadel?.dias_vencidos} />
                  }
                  position="left"
                  title="Certificado"
                  zIndex={99999}
                >
                  <FontAwesomeIcon
                    icon={
                      active(data?.fadel?.dias_vencidos).active === "inativo"
                        ? faExclamationCircle
                        : faCertificate
                    }
                    style={{
                      marginTop: "5px",
                      fontSize: "12px",
                    }}
                    color="#fff"
                  />
                </Tooltip>
              </S.Badge>
            ) : (
              ``
            )}
          </S.OptionsCustomLabelItemsBadge>
        </S.OptionsCustomLabelItems>
      </S.OptionsCustomLabel>
    </S.OptionsCustom>
  );
};
