export const useLawsFilters = (values = {}) => {
  const params = {};

  if (values?.ufSaida) {
    params.ufSaida = values.ufSaida;
  }
  if (values?.ufOrigem) {
    params.ufOrigem = values.ufOrigem;
  }
  if (values?.tipoEmpresa) {
    params.tipoEmpresa = values.tipoEmpresa;
  }
  if (values?.saida) {
    params.saida = values.saida;
  }
  if (values?.origem) {
    params.origem = values.origem;
  }

  return params;
};

const convertValuesToStringOrValue = value =>
  Array.isArray(value) ? JSON.stringify(value) : value;

const hasOutOperation = cenarios =>
  !cenarios.origem && cenarios.ufDestino && cenarios.saida;

const replaceValue = value => value?.replace(/\./g, "");

const getCenaryFormatted = cenarios => {
  const filter = {};
  if (cenarios.origem) {
    filter.origem = cenarios.origem;
  }

  if (cenarios.ufDestino && cenarios.saida) {
    filter.uf_destino = cenarios.ufDestino;
  }

  if (cenarios.ufOrigem) {
    filter.ufOrigem = cenarios.ufOrigem;
  }

  if (cenarios.atividade) {
    filter.atividade = cenarios.atividade;
  }

  if (cenarios.saida && cenarios.ufDestino) {
    filter.saida = cenarios.saida;
  }

  if (cenarios.tipoEmpresa) {
    filter.tipoEmpresa = cenarios.tipoEmpresa;
  }

  return filter;
};

export const useLawsFiltersNew = ({
  values,
  gradeId,
  lawId,
  hasCenary = true,
  isPisCofins = false,
  refLawId,
}) => {
  const filter = {};

  const prodCodigoBarras = values?.prod_codigo_barras || values?.ean;
  if (prodCodigoBarras) {
    filter.prod_codigo_barras = prodCodigoBarras;
  }

  if (values?.mix_nome) {
    filter.mix_nome = values?.mix_nome;
  }

  if (values?.cnpj) {
    if (Array.isArray(values?.cnpj)) {
      filter.cnpjs = JSON.stringify(values?.cnpj);
    } else {
      filter.cnpj = values?.cnpj;
    }
  }

  if (gradeId) {
    filter.lawId = convertValuesToStringOrValue(gradeId);
  }

  if (lawId) {
    filter.lawId = convertValuesToStringOrValue(lawId);
  }

  if (refLawId) {
    filter.refLawId = convertValuesToStringOrValue(refLawId);
  }

  if (values?.status) {
    if (Array.isArray(values?.status) && values?.status.length) {
      filter.status = JSON.stringify(values?.status);
    } else {
      filter.status = values?.status;
    }
  }

  if (values?.cst) {
    filter.cst = convertValuesToStringOrValue(values?.cst);
  }

  const hasData = !prodCodigoBarras && !values?.mix_nome && values?.status;

  if (!values?.cenarios?.length && hasCenary && hasData) return null;

  filter.cenarios = JSON.stringify(
    (values?.cenarios ?? []).map(cenary => getCenaryFormatted(cenary)),
  );

  filter.ncms = JSON.stringify(
    (values?.ncms ?? [])
      .filter(ncm => ncm?.code ?? ncm.data.vc_codigo_sem_formatacao?.toString())
      .map(ncm => ({
        code: replaceValue(
          ncm?.code ?? ncm.data.vc_codigo_sem_formatacao?.toString(),
        ),
      })),
  );

  filter.cests = JSON.stringify(
    (values?.cests ?? []).map(cest => ({
      code: replaceValue(cest?.code ?? cest.data.vc_codigo),
    })),
  );

  if (prodCodigoBarras) {
    filter.prod_codigo_barras = prodCodigoBarras;
  }

  if (isPisCofins || values?.taxa?.pis || values?.taxa?.cofins) {
    filter.type = "piscofins";
  }

  return filter;
};
