import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 5px 10px;

  border: ${props => props.type === "complex" && "1px solid #596778"};
  border-bottom: ${props => props.type === "simple" && "1px solid #596778"};
  background-color: ${props =>
    props.type === "simple" ? "transparent" : "#F2F4F4"};
  border-radius: ${props => props.type === "complex" && "40px"};

  &:hover {
    border: ${props => props.type === "complex" && "1px solid #596778"};
    border-bottom: ${props => props.type === "simple" && "2px solid #596778"};
  }

  border-bottom: ${props =>
    props.type === "simple" && props.hasValue && "2px solid #596778"};

  svg {
    fill: #b0ca70;
  }

  input {
    height: 38px;
    flex: 1;
    background-color: transparent;
    border: none;
    font-size: 13px;
    color: #596778;
    text-transform: lowercase;
    display: block;
  }

  & > div::nth-child(1) {
    z-index: 11;
  }
`;

export const ItemsContainer = styled.div`
  p {
    font-size: 14px;
    padding: 9px 16px;
    cursor: pointer;
    text-transform: lowercase;
    display: block;
    color: #aaaaaa;
    opacity: 1;
    font-weight: 600;
    line-height: 17px;

    span {
      color: #596778;
      font-weight: bold;
    }

    :hover {
      color: #adc86a !important;
      span {
        color: #adc86a !important;
        font-weight: bold;
      }
    }
  }

  p:first-letter {
    text-transform: uppercase;
  }

  .line-container {
    padding-right: 15px;
    width: 335px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:nth-child(odd) {
      background-color: #e5e5e5;
    }
    &:nth-child(even) {
      background-color: #ffffff;
    }
  }

  .chip-container {
    border: 1px solid #e5e5e5;
    border-radius: 20px;
    width: 80px;
    height: 30px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #ffff;
    overflow: hidden;
    background-color: #adc86a;
    cursor: pointer;
  }
`;

export const LoadeContent = styled.div`
  width: 100%;

  h3 {
    font-size: 14px;
    font-weight: 400;
    -webkit-text-stroke-width: 0.2px;
    -webkit-text-stroke-color: #a19e9e;
    color: #adc86a;
  }
`;
