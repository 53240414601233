import React from "react";
import Select from "react-select";
import { TextField } from "components/form";

import { useStoreContext } from "context/StoreContext";

import {
  ListSpecialTaxRegimes,
  SelectSpecialTaxRegime,
} from "components/contexts/stores";
import { StoreLogo } from "./StoreLogo";
import { StoreCertificado } from "./StoreCertificado";

import * as S from "../styles";

const selectedTime = [
  {
    label: "Sim",
    value: true,
  },
  {
    label: "Não",
    value: false,
  },
];

const StatusType = [
  {
    value: true,
    label: `Sim`,
  },
  {
    value: false,
    label: `Não`,
  },
];
export const StoreConfig = ({
  id,
  control,
  data,
  logo,
  setLogo,
  actualLogo,
  setActualLogo,
  setValue,
  differentTimeWeekend,
  setDifferentTimeWeekend,
  isActive,
  setIsActive,
}) => {
  const { specialTaxRegimes, addNewSpecialTaxRegime } = useStoreContext();

  const handleValue = () => {
    setValue("certificate", "");
  };

  return (
    <>
      <S.ColumnFormItem>
        <TextField
          label="Horário de Abertura"
          name="openingTime"
          type="time"
          control={control}
        />
        <TextField
          label="Horário de Fechamento"
          name="closingTime"
          type="time"
          control={control}
        />
      </S.ColumnFormItem>
      <S.ColumnFormItem setMargin="10px 0 0 0">
        <S.InputForm>
          <label>Horário diferente aos finais de semana</label>
          <Select
            name="differentTimeWeekend"
            options={selectedTime.map(item => ({
              label: item.label,
              value: item.value,
            }))}
            placeholder="Selecionar"
            value={selectedTime.filter(
              item => item.value === differentTimeWeekend,
            )}
            control={control}
            onChange={() => {
              setDifferentTimeWeekend(!differentTimeWeekend);
              setValue("differentTimeWeekend", data?.differentTimeWeekend);
            }}
          />
        </S.InputForm>
      </S.ColumnFormItem>
      <S.ColumnFormItem setMargin="10px 0 0 0">
        <TextField
          label="Horário de Abertura"
          name="weekendOpeningTime"
          type="time"
          control={control}
        />
        <TextField
          label="Horário de Fechamento"
          name="weekendClosingTime"
          type="time"
          control={control}
        />
      </S.ColumnFormItem>
      <S.ColumnFormItem setMargin="10px 0 0 0">
        <TextField
          label="Funcionários"
          name="employees"
          type="number"
          control={control}
        />
        <TextField
          label="Tamanho da Loja (m²)"
          name="storeSize"
          type="text"
          control={control}
        />
      </S.ColumnFormItem>
      <S.ColumnFormItem setMargin="5px 0 10px 0">
        <StoreLogo
          control={control}
          logo={logo}
          actualLogo={actualLogo}
          setActualLogo={setActualLogo}
          setLogo={setLogo}
        />
      </S.ColumnFormItem>
      <S.ColumnFormItem setMargin="20px 0 10px 0">
        <StoreCertificado
          data={data}
          control={control}
          handleValue={handleValue}
          setValue={setValue}
        />
      </S.ColumnFormItem>
      <S.ColumnFormItem setMargin="30px 0 0 0">
        <TextField
          label="Senha do Certificado"
          name="certificatePassword"
          type="password"
          control={control}
        />
      </S.ColumnFormItem>
      {id && (
        <S.ColumnFormItem setMargin="10px 0 0 0">
          <S.InputForm>
            <label>Status</label>
            <Select
              name="isActive"
              options={StatusType.map(item => ({
                value: item.value,
                label: item.label,
              }))}
              placeholder="Status"
              value={StatusType.filter(item => item.value === isActive)}
              control={control}
              onChange={e => {
                setIsActive(e.value);
                setValue("isActive", data?.isActive);
              }}
            />
          </S.InputForm>
        </S.ColumnFormItem>
      )}

      <S.ColumnFormItem setMargin="10px 0 10px 0">
        <S.InputForm>
          <label>Regime Especial</label>
          <SelectSpecialTaxRegime handleAdd={addNewSpecialTaxRegime} />
        </S.InputForm>
      </S.ColumnFormItem>
      <S.ColumnFormItem setMargin="10px 0 10px 0">
        <S.InputForm>
          <ListSpecialTaxRegimes data={specialTaxRegimes} />
        </S.InputForm>
      </S.ColumnFormItem>
    </>
  );
};
