import styled from "styled-components";

const colorStyles = {
  default: {
    selectedItemColor: "#1B66B0",
    selectedItemBg: "transparent",
  },
  alternative: {
    selectedItemColor: "#fff",
    selectedItemBg: "#1B66B0",
  },
  slim: {
    selectedItemColor: "#fff",
    selectedItemBg: "#5A8DEE",
  },
};

export const Container = styled.div`
  background-color: ${props => (props.type === "slim" ? "#fff" : "#F2F4F4")};
  display: inline-flex;
  align-items: center;

  border-radius: ${props => props.type === "slim" && "5px"};
  border: ${props => (props.type === "slim" ? "1px solid #BABABA" : "none")};
`;

export const ItemContainer = styled.div`
  cursor: pointer;
  padding: 8px;
  background-color: ${props =>
    props.isSelected && colorStyles[props.type].selectedItemBg};
  border-bottom: ${props => {
    switch (props.type) {
      case "default":
        return `${
          props.isSelected
            ? `1px solid ${colorStyles[props.type].selectedItemColor}`
            : "1px solid transparent"
        }`;
      case "alternative":
        return `1px solid #1B66B0`;
      case "slim":
        return "none";
      default:
        return "";
    }
  }};

  p {
    font-size: 14px;
    color: ${props =>
      props.isSelected ? colorStyles[props.type].selectedItemColor : "#596778"};
    font-weight: ${props => props.isSelected && "bold"};
  }
`;
