import styled from "styled-components";
import * as CardLocalStyle from "components/structure/Card/CardLocal/CardLocal.styles";

export const CardWithLateralHeader = styled.div`
  display: flex;
  align-items: stretch;
  width: 100%;

  margin-top: 20px;
  margin-left: ${props => (props.marginLeft ? props.marginLeft : "")};

  min-height: ${props => (props.height ? props.height : "125px")};

  header {
    display: flex;
    flex-basis: 100%;
    flex: 1;
    justify-content: center;
    align-self: stretch;
    align-items: center;
    writing-mode: vertical-lr;
    transform: rotate(-180deg);

    font-size: 15px;
    color: #fff;
    font-weight: 700;
    width: 50px;

    border: 1px solid #1b66b0;
    border-radius: 4px;
    background-color: #1b66b0;

    button {
      display: flex;
      background: none;
      margin: 5px;

      svg {
        color: #fff;
      }
    }
  }

  main {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;

    padding: 8px;
    border: 1px solid #1b66b0;
    border-radius: 4px;
  }
`;

export const CardContext = styled.div`
  position: relative;
`;

export const CardContextActions = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

export const CardContainer = styled.div`
  margin-top: 20px;

  ${CardLocalStyle.HeaderContent} p {
    color: #304156;
    font-weight: 700;
  }
`;
