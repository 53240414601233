import React, { useState } from "react";
// import { Icon } from "@mix/mixfiscal-designsystem";

import { useFlow } from "../../../../context/FlowContext";
import Dialog from "../../../UI/Dialog";
import SelectFile from "./SelectFile";
import Icon from "../../../IconExporter";
import { Container, ItemContainer } from "./styles";

function AddNodeDialog({
  title,
  isOpen,
  handleClose,
  spedTemplates,
  xmlTemplates,
}) {
  const [selectedFileType, setSelectedFileType] = useState(null);
  const { handleAddNode } = useFlow();

  const getFiles = async ({ label, nodeType, dataType }) => {
    setSelectedFileType({ label, nodeType, dataType });

    if (nodeType === "operation" || nodeType === "advancedOperations") {
      handleAddNode({
        label,
        nodeType,
      });
      return handleClose();
    }
  };

  return (
    <Dialog title={title} isOpen={isOpen} handleClose={handleClose}>
      {!selectedFileType && (
        <Container>
          <ItemContainer
            onClick={() =>
              getFiles({
                label: "SPED",
                nodeType: "custom",
                dataType: "txt",
              })
            }
          >
            <Icon size={30} name="sped" />
            <p>SPED</p>
          </ItemContainer>
          <ItemContainer
            onClick={() =>
              getFiles({
                label: "NFE - NFCE - SAT",
                nodeType: "custom",
                dataType: "xml",
              })
            }
          >
            <Icon size={30} name="xml" />
            <p>Documentos Fiscais XML</p>
          </ItemContainer>
          <ItemContainer
            onClick={() =>
              getFiles({
                label: "EXCEL",
                nodeType: "custom",
                dataType: "xls",
              })
            }
          >
            <Icon size={30} name="excel" />
            <p>EXCEL</p>
          </ItemContainer>
          <ItemContainer
            onClick={() =>
              getFiles({
                label: "CSV",
                nodeType: "custom",
                dataType: "csv",
              })
            }
          >
            <Icon size={30} name="csv" />
            <p>CSV</p>
          </ItemContainer>
          <ItemContainer
            onClick={() =>
              getFiles({
                label: "PDF",
                nodeType: "custom",
                dataType: "pdf",
              })
            }
          >
            <Icon size={30} name="pdf" />
            <p>PDF</p>
          </ItemContainer>
          <ItemContainer
            onClick={() =>
              getFiles({
                label: "Operações",
                nodeType: "operation",
                dataType: "",
              })
            }
          >
            <Icon size={30} name="operations" />
            <p>Operações</p>
          </ItemContainer>
          <ItemContainer
            onClick={() =>
              getFiles({
                label: "Operações Avançadas",
                nodeType: "advancedOperations",
                dataType: "",
              })
            }
          >
            <Icon size={30} name="flow" />
            <p>Operações Avançadas</p>
          </ItemContainer>
        </Container>
      )}
      {selectedFileType && (
        <SelectFile
          selectedFileType={selectedFileType}
          spedTemplates={spedTemplates}
          xmlTemplates={xmlTemplates}
          handleBack={() => setSelectedFileType(null)}
          handleCloseDialog={handleClose}
        />
      )}
    </Dialog>
  );
}

export default AddNodeDialog;
