import React from "react";
import { Handle } from "react-flow-renderer";

import { Container } from "./styles";

function AllValues({ data: { element, field } }) {
  return (
    <>
      <Container>
        <p>{element?.data?.label}</p>
        <p>{field}</p>
      </Container>
      <Handle
        id="head"
        type="target"
        position="top"
        style={{
          width: "10px",
          height: "10px",
          background: "#7b867e",
          bottom: "-5px",
        }}
      />
      <Handle
        id="tail"
        type="source"
        position="bottom"
        style={{
          width: "10px",
          height: "10px",
          background: "#0e7c34",
          bottom: "-5px",
        }}
      />
    </>
  );
}

export default AllValues;
