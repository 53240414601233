import React from "react";
import ReactQrCode from "react-qr-code";
import { LoadPlaceholder } from "components/structure/Load";

import * as S from "./QrCode.styles";

export const QrCode = ({ text, isLoading }) => {
  return (
    <S.Container>
      <LoadPlaceholder show={isLoading}>
        <ReactQrCode value={text} />
      </LoadPlaceholder>
    </S.Container>
  );
};
