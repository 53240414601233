import React, { createContext, useState, useContext } from "react";
import { defaultValues } from "components/contexts/grid-manager/Filters/validations";

export const GridManagerContext = createContext({
  setUpdate: () => {},
  setFilterFields: () => {},
  filterFields: defaultValues,

  gradeIds: [],
  status: [],
});

export const useGridManagerContext = () => {
  const context = useContext(GridManagerContext);
  if (!context)
    throw new Error(
      "useGridManager precisa ser utilizado dentro de um GridManagerProvider",
    );
  return context;
};

export const GridManagerProvider = ({ children }) => {
  const [gridManager, setGridManager] = useState({});
  const [filterFields, setFilterStateFields] = useState(defaultValues);

  const setUpdate = (options = {}) => {
    setGridManager(prevState => ({ ...prevState, ...options }));
  };

  const setFilterFields = (options = {}) => {
    setFilterStateFields({ ...filterFields, ...options });
  };

  const removeField = name => {
    setFilterStateFields(state => {
      const fields = state;
      delete fields[name];

      return fields;
    });
  };

  return (
    <GridManagerContext.Provider
      value={{
        gridManager,
        filterFields,
        setUpdate,
        setFilterFields,
        removeField,
        setGridManager,
      }}
    >
      {children}
    </GridManagerContext.Provider>
  );
};
