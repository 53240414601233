import React, { useState, useMemo } from "react";
import { useHistory } from "react-router-dom";

import { Card, Tabs } from "@mix/mixfiscal-designsystem";
import { Button } from "components/structure";
import { useRegisterLawsGradesContext } from "context/RegisterLawsContext";
import { LAWS } from "constants/routes";

// TODO: Migrar para o structure
import Dialog from "components/UI/Dialog";
import { useRegisterLaws } from "hooks/useRegisterLaws";
import { useLawsCnaes, useLawsActions } from "useCases/laws";
import { getAlqLabel } from "utils/context/grid-manager";

import {
  LawReplicate,
  LawRegimeSpecial,
  LawNcm,
  LawGradeActions,
  LawNorm,
  LawLogs,
  LawCest,
  LawNcmEx,
  LawAttributes,
  LawProducts,
  LawScenery,
  LawSceneriesAggregate,
} from "components/contexts/law-grade";

import Tax from "./Tax";
import Supplier from "./Supplier";
import Cnae from "./Cnae";
import CfopEntrada from "./CfopEntrada";
import CfopSaida from "./CfopSaida";

import { useWatch } from "./Laws.hooks";
import * as S from "./styles";
import Mocks from "./mocks";

const lawsCeneRepository = new Mocks();

const norms = lawsCeneRepository.getNorms();
const getEstados = (list = []) =>
  list.map(item => item.ufOrigem || item.ufSaida);

const getTitle = id => ({
  titleBreadcrumb: id ? "Detalhe Lei" : "Cadastrar Nova Lei",
  titleCard: id ? "DETALHE LEI" : "CADASTRO DE LEIS",
});

const LawsContent = ({
  onSave,
  isLoading = false,
  isNew = false,
  onChangeProduct = () => {},
}) => {
  const { push } = useHistory();
  const [dialogConfirm, setDialogConfirm] = useState(false);
  const { values, setSimpleValue } = useRegisterLaws();
  const { dataFormatted: cnaes } = useLawsCnaes();
  const {
    onSendArchive,
    onSendRemove,
    onSendActive,
    onSendClone,
    onSendInactive,
    onSendRecovery,
  } = useLawsActions(values?._id);

  useWatch();

  const { titleCard } = getTitle(values?._id);

  const alqsValues = useMemo(() => {
    const alqsEntrada = values?.aliqEntrada + (values?.aliqEntradaCofins || 0);
    const alqsSaida = values?.aliqSaida + (values?.aliqSaidaCofins || 0);

    return [
      {
        label: `Aliq (${getAlqLabel({
          entrada: alqsEntrada,
          saida: alqsSaida,
        })})`,
      },
    ];
  }, [
    values?.aliqEntrada,
    values?.aliqSaida,
    values?.aliqEntradaCofins,
    values?.aliqSaidaCofins,
  ]);

  const {
    values: {
      productsMoveSelecteds,
      productsAddSelecteds,
      productsRemoved,
      productsChangeds,
    },
    clearProductsMoveSelecteds,
    clearProductsAddSelecteds,
    setProductsClear,
  } = useRegisterLawsGradesContext();

  const states = getEstados(values?.cenarios ?? []);

  const cenariosAgg = useMemo(() => {
    if (!values?.gradeIcms?.cenariosAgg) {
      return [];
    }

    return values?.gradeIcms?.cenariosAgg.filter(
      scenary => scenary._id !== values?.gradeIcms?._id,
    );
  }, [values?.gradeIcms?.cenariosAgg, values?.gradeIcms?._id]);

  const disableMVA = (value = values?.cstEntrada) =>
    !["00", "20"].includes(value);

  const handleDialogConfirm = () => setDialogConfirm(!dialogConfirm);

  const handleSubmit = () => {
    const fields = {
      taxa: values.taxa,
      regime_name: values.regime_name,
      cenarios: values.cenarios,
      normas: values.normas,
      ncms: values.ncms?.map(ncm => {
        return {
          codigo: ncm.data.vc_codigo_sem_formatacao,
          linkmix_id: ncm.value,
        };
      }),
      excecoesNcm: values.ncmEx,
      fornecedores: values.fornecedores,
      cnaes: values.cnaes,
      cests: values.cests?.map(cest => {
        return { codigo: cest.data.vc_codigo, linkmix_id: cest.value };
      }),
      cfopsEntrada: values.cfopsEntrada,
      cfopsSaida: values.cfopsSaida,
      atributos: {
        fecp: values.fecp,
        reducaoComFecp: values.reducaoComFecp,
        cBenef: values.cBenef,
        aliqcBenef: values.aliqcBenef,
        naturezaReceita: values.naturezaReceita,
        cstEntrada: values.cstEntrada,
        cstSaida: values.cstSaida,
        aliqCstEntrada: values.aliqEntrada,
        aliqCstSaida: values.aliqSaida,
        reducaoBaseCalc: values.reducaoBaseCalc,
        tipoMva: values.tipoMva,
        valorMva: values.valorMva,
        tipoItem: values.tipoItem,
        aliqEntradaCofins: values.aliqEntradaCofins,
        aliqSaidaCofins: values.aliqSaidaCofins,
      },
      productsChanges: productsChangeds,
      productsRemoveds: productsRemoved,
      productsMoveds: isNew ? [] : productsMoveSelecteds,
      productsAdds: productsAddSelecteds,
      id_laws: values?.id_laws,
      sceneries: cenariosAgg,
    };

    onSave(fields, true).then(res => {
      clearProductsMoveSelecteds();
      clearProductsAddSelecteds();
      setProductsClear();

      if (isNew && res?.law?._id) {
        return push(LAWS.DETAIL(res?.law?._id));
      }
    });
  };

  const handleConfirmSubmit = () => {
    handleDialogConfirm();
    handleSubmit();
  };

  const onChangeStatus = ({ type, response }) => {
    if (type === "clone") {
      return setSimpleValue("clone", response?.data);
    }

    return setSimpleValue("status", type);
  };

  const hasTax = values.taxa?.pis || values.taxa?.cofins || values.taxa?.icms;

  return (
    <S.Container>
      {values?._id && (
        <S.Content>
          <LawGradeActions
            law={values}
            grade={{ id_law: values?._id }}
            onSendRemove={onSendRemove}
            onSendArchive={onSendArchive}
            onSendActive={onSendActive}
            onSendClone={onSendClone}
            onSendInactive={onSendInactive}
            onChange={onChangeStatus}
            onSendRecovery={onSendRecovery}
          />
        </S.Content>
      )}

      <Dialog
        isOpen={dialogConfirm}
        title="Alerta"
        handleConfirm={handleConfirmSubmit}
        zIndex="100002"
        handleCancel={handleDialogConfirm}
        handleClose={handleDialogConfirm}
      >
        <h3 style={{ textAlign: "center", marginTop: 10, padding: "0px 10px" }}>
          Essa ação vai atualizar os produtos da GRADE (XXX).
        </h3>
        <h3 style={{ marginTop: 10, textAlign: "center" }}>
          Deseja continuar ?
        </h3>
      </Dialog>
      <S.Content>
        {!isLoading && (
          <div style={{ width: "100%" }}>
            <Tabs
              items={alqsValues.map(alq => alq.label) || []}
              type="slim"
              initialValue={alqsValues[0]?.label}
            />
          </div>
        )}

        <Card
          title={titleCard}
          bold={false}
          titleIcon="certificado"
          hasElevation
          className="header-disable"
        >
          <Tax lawId={values?._id} />
          {hasTax && (
            <Card
              headerColor="#E5E5E5"
              textColor="#304156"
              title="CENÁRIO E NORMAS"
              className="flatCard"
            >
              <LawScenery scenery={values?.scenery} />
              <br />
              <LawNorm normsValue={norms} />
            </Card>
          )}

          {cenariosAgg?.length >= 1 && (
            <Card
              title="Cenarios Mesclados"
              headerColor="#E5E5E5"
              textColor="#304156"
              className="flatCard"
            >
              <LawSceneriesAggregate sceneries={cenariosAgg} />
            </Card>
          )}
          <Card
            headerColor="#E5E5E5"
            textColor="#304156"
            title="ESPECIFICAÇÕES"
            className="flatCard"
          >
            {values?.id_laws && (
              <S.ContainerRow>
                <LawRegimeSpecial
                  regimeName={values?.regime_name}
                  setValue={setSimpleValue}
                />
              </S.ContainerRow>
            )}

            <LawNcm />
            <div className="flex">
              <LawNcmEx />
              <Supplier />
              <Cnae cnaes={cnaes} />
            </div>
            <LawCest />
            <div className="flex">
              <CfopEntrada />
              <CfopSaida />
            </div>
            <LawAttributes />
          </Card>

          {values?.id_laws && (
            <S.ContainerRow>
              <LawReplicate
                lawsIds={values?.id_laws}
                ncms={values?.ncms}
                cenarios={values?.cenarios}
                cests={values?.cests}
                setValue={setSimpleValue}
                lawId={values?._id}
              />
            </S.ContainerRow>
          )}

          {values?._id && (
            <S.ContainerRow>
              <LawLogs logs={values?.logs} />
            </S.ContainerRow>
          )}

          <S.FooterActions>
            <Button
              isLoading={isLoading}
              label={isLoading ? "Salvando..." : "Salvar"}
              onClick={values?.id_law ? handleDialogConfirm : handleSubmit}
            />
          </S.FooterActions>
          {hasTax && values?.ncms?.length >= 1 && (
            <LawProducts
              isNewLaw={isNew}
              lawId={values?._id}
              gradeId={null}
              states={states}
              lawValues={values}
              onChangeProduct={onChangeProduct}
              hasMVA={disableMVA()}
            />
          )}
        </Card>
      </S.Content>
    </S.Container>
  );
};

export default LawsContent;
