import { useState, useCallback } from "react";
import jsonp from "jsonp";
import {
  useFetchAuthenticate,
  useAPIAuthenticate,
  useToast,
  useAPIAuthenticateToast,
} from "hooks";
import { getURLWithParams } from "utils/context";
import {
  ischeckIfAndNumber,
  formatActivities,
  formatSocialName,
  formatCnpj,
} from "utils/utils";

export const useStores = (params = {}) =>
  useFetchAuthenticate(params ? getURLWithParams("/stores", params) : null);

export const useWalletStore = id =>
  useFetchAuthenticate(id ? getURLWithParams(`/store/wallets/${id}`) : null);

export const useWalletStoreRemove = () => {
  const { isLoading, onSend } = useAPIAuthenticateToast();

  const onRemove = id =>
    onSend({
      endpoint: `/store/wallet/${id}`,
      messageSuccess: "Assessor foi removido com sucesso!",
      messageFail: "Assessor não foi removido",
      type: "DELETE",
    });

  return {
    onRemove,
    isLoading,
  };
};

const check = search => {
  if (search) {
    if (ischeckIfAndNumber(search)) {
      return `&cnpj=${search}`;
    }
    return `&search=${search}`;
  }
  return "";
};

export const useStoresPagination = (params = {}) =>
  useFetchAuthenticate(
    params
      ? `/stores?page=${params.page}&limit=${params.limit}${check(
          params.search,
        )}`
      : null,
  );

export const useStore = id =>
  useFetchAuthenticate(id ? getURLWithParams(`/store/${id}`) : null);

export const useStoreCreateWallet = () => {
  const api = useAPIAuthenticate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(``);

  const { addToast } = useToast();

  const fetch = useCallback(
    (value = {}) => {
      setLoading(true);
      const fetchData = async () => {
        try {
          const res = await api.post(`/store/wallet`, value);

          if (res.message) {
            addToast({
              title: "Usuário",
              type: "warning",
              description: res.message,
            });
          }
          setData(res.wallet);
          addToast({
            title: "Usuário",
            type: "success",
            description: "Assessor cadastrado com sucesso!",
          });
        } catch (e) {
          setIsError(e);
          setLoading(false);
          console.log(e.messagge);
          setIsError(``);
        }
      };
      fetchData();
    },
    [api, addToast],
  );
  return {
    data,
    fetch,
    isError,
    isLoading: loading,
  };
};

export const useStoreRemove = () => {
  const { isLoading, onSend } = useAPIAuthenticateToast();

  const onRemove = id =>
    onSend({
      endpoint: `/store/${id}`,
      messageSuccess: `LOJA DELETADA`,
      messageFail: "Loja foi deletada com sucesso!",
      type: "DELETE",
    });

  return {
    onRemove,
    isLoading,
  };
};

const getUrl = ({ search, type = "" }) => {
  const isCheckValueNumber = ischeckIfAndNumber(search);

  const isType = `&type=${type}`;

  if (!isCheckValueNumber) {
    return `?page=1&limit=50&search=${search}${type ? isType : ``}`;
  }

  return `?page=1&limit=50&cnpj=${search}${type ? isType : ``}`;
};

export const UseStoresSearch = () => {
  const api = useAPIAuthenticate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const fetch = useCallback(
    (params = {}) => {
      setLoading(true);

      const url = getUrl(params);

      const fetchData = async () => {
        try {
          const {
            data: { stores },
          } = await api.get(`/stores${url}`);
          setData(stores.docs);
          setLoading(false);
        } catch (e) {
          console.log(e.messagge);
        }
      };
      fetchData(url);
    },
    [api],
  );

  return { isLoading: loading, data, fetch };
};

export const UseStoresAll = () => {
  const api = useAPIAuthenticate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(``);
  const fetch = useCallback(() => {
    setLoading(true);
    setIsError(`loading`);
    const url = `?page=all&limit=50`;
    const fetchData = async () => {
      try {
        const {
          data: { stores },
        } = await api.get(`/stores${url}`);
        setData(stores.docs);
        setLoading(false);
        setIsError(``);
      } catch (e) {
        console.log(e.messagge);
        setIsError(`not-exist`);
      }
    };
    fetchData();
  }, [api]);

  return { isLoading: loading, data, fetch, isError };
};

export const UseGetCnpjData = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast({});
  const fetch = useCallback(
    (params = {}) => {
      setLoading(true);
      if (!params.cnpj) {
        return addToast({
          title: "CNPJ em branco",
          type: "error",
          description: "O campo CNPJ não pode ficar em branco.",
        });
      }
      jsonp(
        `https://www.receitaws.com.br/v1/cnpj/${params.cnpj}`,
        null,
        (err, cnpjData) => {
          if (err) {
            setLoading(false);
            return addToast({
              title: "CNPJ",
              type: "error",
              description: `Ocorreu o seguinte erro: ${err.message}`,
            });
          }
          if (cnpjData.status === "ERROR") {
            setLoading(false);
            return addToast({
              title: "CNPJ",
              type: "error",
              description: cnpjData.message,
            });
          }

          if (!cnpjData && err && cnpjData.status === `ERROR`) {
            setLoading(false);
            return addToast({
              title: "CNPJ Não existente.",
              type: "error",
              description: `O CNPJ: ${formatCnpj(params.cnpj)}`,
            });
          }
          setData(prevState => {
            return {
              ...prevState,
              companyName: cnpjData.nome,
              tradeName: cnpjData.fantasia,
              address: cnpjData.logradouro,
              number: cnpjData.numero,
              complement: cnpjData.complemento || "-",
              zipcode: cnpjData.cep,
              district: cnpjData.bairro,
              neighborhood: cnpjData.bairro,
              city: cnpjData.municipio,
              state: cnpjData.uf,
              legalNature: cnpjData.natureza_juridica,
              openDate: cnpjData.abertura,
              lastUpdate: cnpjData.ultima_atualizacao,
              phone: cnpjData.telefone,
              namePartnerOne: formatSocialName(cnpjData.qsa, 0),
              namePartnerTwo: formatSocialName(cnpjData.qsa, 1),
              mainActivity: formatActivities(cnpjData.atividade_principal),
              secondaryActivity: formatActivities(
                cnpjData.atividades_secundarias,
              ),
              logo: cnpjData.logo,
              certificate: cnpjData.certificate,
              companyType: cnpjData.tipo,
            };
          });
          setLoading(false);
        },
      );
    },
    [addToast],
  );
  return { isLoading: loading, data, fetch };
};

export const useStoreWhatsAppForm = item => {
  const { isLoading, onSend } = useAPIAuthenticateToast();

  const onSave = data =>
    onSend({
      endpoint: item.id ? `/store/${item.id}` : "store",
      messageSuccess: `WhatsApp da Loja  ${
        item.whatsapp ? "atualizado" : "criada"
      } com sucesso!`,
      messageFail: "Falha ao tentar salvar whatsApp!",
      data,
      type: item.id ? "PUT" : "POST",
    });

  return {
    onSave,
    isLoading,
  };
};
