import React, { useState, useEffect } from "react";
import { MdAdd } from "react-icons/md";

import { Tooltip } from "@mix/mixfiscal-designsystem";
import Card from "components/UI/Card";
import { usePermissions } from "context/PermissionsContext";
import TemplateCard from "../../TemplateCard";

function MyTemplates({ flows, handleFlowSelection, handleRemoveFlow }) {
  const [currentPermission, setCurrentPermission] = useState(null);
  const { selectedGroup } = usePermissions();
  const moduleId = "6084a079fc9e45004158051f";

  useEffect(() => {
    if (selectedGroup?.modules?.length > 0) {
      const modulePermission = selectedGroup?.modules?.find(
        module => module.moduleId === moduleId,
      );
      if (modulePermission.rules) {
        setCurrentPermission(modulePermission.rules);
      }
    }
  }, [selectedGroup]);

  if (!currentPermission) return null;

  return (
    currentPermission?.get && (
      <Card title="meus templates">
        <div className="headerActions">
          <div>
            {(currentPermission?.post || currentPermission?.put) && (
              <Tooltip title="Adicionar Template" position="top">
                <button type="button" onClick={() => handleFlowSelection()}>
                  <MdAdd />
                </button>
              </Tooltip>
            )}
            <p>
              Você possui&nbsp;
              <span>{flows ? flows.length : 0}</span>
              &nbsp;templates
            </p>
          </div>
          {/* <div>
      <input placeholder="Digite o nome do template" />
      <select>
        <option>Categoria</option>
      </select>
    </div> */}
        </div>
        <div className="myTemplatesContainer">
          {flows.length > 0 &&
            flows.map(flow => (
              <TemplateCard
                key={flow._id}
                color="#17456B"
                title={flow.name}
                flowData={flow}
                handleEdit={() => handleFlowSelection(flow)}
                handleRemove={() => handleRemoveFlow(flow._id)}
                hasPermissionToEdit={
                  currentPermission?.post || currentPermission?.put
                }
              />
            ))}
        </div>
      </Card>
    )
  );
}

export default MyTemplates;
