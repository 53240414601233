import styled from "styled-components";
import colors from "styles/tokens/colors";

export const Wrapper = styled.div`
  table {
    thead {
      th {
        padding: 5px 8px !important;
      }
      .headerCell {
        [role="button"] {
          font-size: 0.9rem;
        }
        svg {
          display: none;
        }
      }
    }
    tbody tr td {
      font-size: 0.9rem;
    }
  }
`;

export const InputUpload = styled.input`
  display: none;
`;

export const Title = styled.p`
  display: flex;
  flex-wrap: nowrap;
  border-bottom: 0;
  padding: 9px 8px 0px 8px !important;
  h5 {
    font-size: 22px;
    color: #1b66b0;
  }
`;

export const TitleIcon = styled.div`
  flex: 25%;
`;
export const TitleText = styled.div`
  flex: 75%;
  line-height: 27px;
`;

export const TitleTextH5 = styled.h5``;

export const Footer = styled.div`
  border-top: 1px solid #ccc;
  justify-content: space-between;
  display: flex;
`;

export const FooterContainer = styled.div`
  justify-content: space-between;
  display: flex;
  padding: 0px 10px;
  width: 100%;
`;

export const ContainerTooltip = styled.div`
  align-items: center;
  display: block;
  padding: 0px 8px 6px 8px;
  h4 {
    padding-top: 3px;
    color: #596778;
  }
`;

export const IconWrapper = styled.div`
  width: 100%;
  display: flex;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  border: 1px dashed #596778;
  padding: 4px 5px;
  cursor: pointer;
  max-width: 320px;
  display: none;
`;

export const IconWrapperItem = styled.div`
  margin: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  span {
    font-size: 14px;
    color: #596778;
`;

export const ListPfx = styled.div`
  width: 100%;
  margin-top: 0px;
  border-radius: 5px;
  padding: 0 0 0 0;
  border-radius: 0px;
  border: 1px solid #596778;
`;
export const ListPfxHeader = styled.div`
  width: 100%;
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  align-items: flex-start;
  border-bottom: 1px solid #596778;
`;
export const TitleListHeader = styled.div`
  flex: 90%;
`;
export const IconListHeader = styled.div`
  cursor: pointer;
  svg {
    padding-top: 7px;
  }
`;

export const ListPfxContent = styled.div`
  width: 100%;
  overflow-x: auto;
  max-height: 300px;
`;

export const ListPfxContentTitle = styled.h5`
  margin: 5px 0 0 0;
  font-size: 15px;
`;

export const ListPfxContentItems = styled.div`
  width: 100%;
  display: felx;
  padding-left: 10px;
  padding-right: 10px;
  align-items: flex-start;
  background-color: #f4f4f4;
  transition: all 0.3s;
  &:hover {
    background-color: #fff;
  }
  cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};
`;
export const ListPfxName = styled.div`
  flex: 55%;
  h3 {
    font-size: 17px;
    overflow: hidden;
    white-space: nowrap;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    text-overflow: ellipsis;
  }
`;
export const ListPfxNameDais = styled.div`
  flex: 30%;
  h3 {
    font-size: 17px;
    overflow: hidden;
    white-space: nowrap;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    text-overflow: ellipsis;
  }
`;
export const ListPfxButton = styled.div`
  cursor: pointer;
  flex: 15%;
  svg {
    padding-top: 7px;
  }
`;

export const ContainerTooltipCenter = styled.div`
  position: relative;
  align-items: center;
`;

export const ButtonUpload = styled.button`
  padding: 1px 8px;
  border-radius: 10px;
  border: 1px solid #596778;
  background-color: transparent;
`;
export const DaysCertified = styled.div`
  opacity: 1;
  width: 130px;
  height: 130px;
  margin: 0 auto;
  text-align: center;
  border-radius: 100%;
  background-color: ${({ active }) => (active ? "#adc86a" : "#c86a6a")};
  vertical-align: middle;
  line-height: 23px;
  padding-top: 40px;
  margin-bottom: 10px;
  box-shadow: 0px 3px 6px #00000029;
`;
export const DaysCertifiedH5 = styled.h5`
  font-size: 35px;
  font-weight: bold;
  color: ${colors.white};
`;

export const DaysCertifiedH6 = styled.h6`
  font-size: 20px;
  font-weight: bold;
  color: ${colors.white};
`;

export const ContainerTooltipCertificate = styled.div`
  width: 350px;
`;
