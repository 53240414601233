import React, { useState, useEffect } from "react";
// import { IoFilterSharp } from "react-icons/io5";
// import { FaStar, FaFacebookSquare } from "react-icons/fa";

import Card from "../../components/UI/Card";
import { Container } from "./styles";

import { useToast } from "../../context/ToastContext";
import authenticationApi from "../../services/authenticationApi";

function Profile() {
  const [user, setUser] = useState(null);
  const { addToast } = useToast();

  const handleSubmit = async e => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const obj = Object.fromEntries(formData.entries());
    if (obj.password !== obj.confirmPassword) {
      addToast({
        title: "Usuário",
        type: "error",
        description: `Os campos de senha não coincidem`,
      });
      return null;
    }
    console.log(obj);

    try {
      const token = localStorage.getItem("@mixfiscal:authenticatorToken");
      const { data } = await authenticationApi.put(
        "/user",
        {
          user: obj.name,
          name: obj.name,
          surname: obj.surname,
          email: obj.email,
          isActive: obj.isActive,
          password: obj.password,
        },
        {
          headers: {
            Authorization: token,
          },
        },
      );
      setUser(data.user);
      addToast({
        title: "Perfil",
        type: "success",
        description: `Perfil atualizado com sucesso!`,
      });
    } catch (err) {
      addToast({
        title: "Perfil",
        type: "error",
        description: `Ocorreu o seguinte erro: ${err.message}`,
      });
    }
  };

  const getMeInformation = async () => {
    const token = localStorage.getItem("@mixfiscal:authenticatorToken");
    try {
      const { data } = await authenticationApi.get("/user", {
        headers: {
          Authorization: token,
        },
      });
      setUser(data.user);
    } catch (e) {
      // chamar toast
    }
  };

  useEffect(() => {
    getMeInformation();
  }, []);

  console.log(user);

  return (
    <Container>
      {/* <section className="column1">
          <div className="menu-wrapper">
            <ul className="menu">
              <li className="menu-option active">Dados Pessoais</li>
              <li className="menu-option">Dados de Cobrança</li>
              <li className="menu-option">Menu 3</li>
              <li className="menu-option">Menu 4</li>
            </ul>
          </div>
          <Card iconName="eae dude" title="Plano" headerColor="#ADC86A">
            <table>
              <tr className="table-head">
                <th className="table-head-item">
                  <div className="table-head-content-wrapper">
                    <p>PLANO</p>
                    <IoFilterSharp size={16} color="#707070" />
                  </div>
                </th>
                <th className="table-head-item">
                  <div className="table-head-content-wrapper">
                    <p>ESPAÇO</p>
                    <IoFilterSharp size={16} color="#707070" />
                  </div>
                </th>
                <th className="table-head-item">
                  <div className="table-head-content-wrapper">
                    <p>VALIDADE</p>
                    <IoFilterSharp size={16} color="#707070" />
                  </div>
                </th>
              </tr>
              <tr className="table-content">
                <td className="table-content-item">
                  <p>Contador Ouro</p>
                </td>
                <td className="table-content-item">
                  <p>500Gb</p>
                </td>
                <td className="table-content-item">
                  <p>28/02/2022</p>
                </td>
              </tr>
            </table>
            <button className="change-button" type="button">
              MUDAR DE PLANO
            </button>
          </Card>
        </section> */}
      <section className="column2">
        <Card iconName="eae dude2" title="Dados Pessoais">
          <form className="full-container" onSubmit={e => handleSubmit(e)}>
            <div className="top-part">
              <div className="customer-info">
                <div className="input-wrapper">
                  <span>Usuário</span>
                  <input type="text" name="user" defaultValue={user?.name} />
                </div>
                <div className="input-group">
                  <div className="input-wrapper">
                    <span>Nome</span>
                    <input type="text" name="name" defaultValue={user?.name} />
                  </div>
                  <div className="input-wrapper">
                    <span>Sobrenome</span>
                    <input
                      type="text"
                      name="surname"
                      defaultValue={user?.surname}
                    />
                  </div>
                </div>
                <div className="input-wrapper">
                  <span>E-mail</span>
                  <input type="email" name="email" defaultValue={user?.email} />
                </div>
                <div className="input-group">
                  <div className="input-wrapper">
                    <span>Senha</span>
                    <input
                      type="password"
                      name="password"
                      defaultValue={user?.password}
                    />
                  </div>
                  <div className="input-wrapper">
                    <span>Confirmar Senha</span>
                    <input
                      type="password"
                      name="confirmPassword"
                      defaultValue={user?.password}
                    />
                  </div>
                </div>
                <div className="input-wrapper">
                  <span>Conta Ativa</span>
                  <select name="isActive" id="active-account">
                    <option value selected={user?.isActive}>
                      Sim
                    </option>
                    <option value={false} selected={!user?.isActive}>
                      Não
                    </option>
                  </select>
                </div>
              </div>
              {/* <div className="social-info">
                  <div className="user-id">
                    <div className="profile-picture">
                      <p>Foto Perfil</p>
                      <img
                        src="https://cdn.dribbble.com/users/2364329/screenshots/10481283/media/f013d5235bfcf1753d56cad154f11a67.jpg?compress=1&resize=400x300"
                        alt="Profile"
                      />
                    </div>
                    <div className="user-rating">
                      <p>Classificação</p>
                      <div className="rating-stars">
                        <FaStar className="star" size={20} color="#F6B000" />
                        <FaStar className="star" size={20} color="#F6B000" />
                        <FaStar className="star" size={20} color="#F6B000" />
                        <FaStar className="star" size={20} color="#F6B000" />
                        <FaStar className="star" size={20} color="#E5E5E5" />
                      </div>
                    </div>
                  </div>
                  <div className="user-social-media">
                    <div className="associated-account">
                      <p>Contas Associadas</p>
                      <p>Não há nenhuma conta associada, associe agora!</p>
                    </div>
                    <div className="social-media-group">
                      <div className="social-media-row">
                        <div className="social-media-item">
                          <FaFacebookSquare
                            className="social-media-icon"
                            size={34}
                            color="#4D6FA9"
                          />
                          <p className="social-media-name">FACEBOOK</p>
                        </div>
                        <div className="social-media-item">
                          <FaFacebookSquare
                            className="social-media-icon"
                            size={34}
                            color="#4D6FA9"
                          />
                          <p className="social-media-name">FACEBOOK</p>
                        </div>
                      </div>
                      <div className="social-media-row">
                        <div className="social-media-item">
                          <FaFacebookSquare
                            className="social-media-icon"
                            size={34}
                            color="#4D6FA9"
                          />
                          <p className="social-media-name">FACEBOOK</p>
                        </div>
                        <div className="social-media-item">
                          <FaFacebookSquare
                            className="social-media-icon"
                            size={34}
                            color="#4D6FA9"
                          />
                          <p className="social-media-name">FACEBOOK</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              {/* <div className="token-info">
                  <div className="token-content">
                    <span>Seu Token Privado</span>
                    <input type="text" value="0230x32E923Sdyt98476Xa3" />
                  </div>
                </div> */}
            </div>
            <div className="bottom-part">
              <button className="save-button" type="submit">
                SALVAR
              </button>
            </div>
          </form>
        </Card>
      </section>
    </Container>
  );
}

export default Profile;
