import { useFetchAuthenticate, useAPIAuthenticateToast } from "hooks";
import { getURLWithParams } from "utils/context";

export const useCestsSegments = (params = {}) =>
  useFetchAuthenticate(getURLWithParams("/cest/segmentos", params));

export const useCestsSegmentsItems = (id, params = {}) =>
  useFetchAuthenticate(
    id ? getURLWithParams(`/cest/segmentos/${id}/items`, params) : null,
  );

export const useCestsItems = (params = {}) =>
  useFetchAuthenticate(
    params?.cest ? getURLWithParams(`/cest/items`, params) : null,
  );

export const useCestsList = (params = {}) =>
  useFetchAuthenticate(getURLWithParams(`/cest/items`, params));

export const useCestInformation = (params = {}) =>
  useFetchAuthenticate(
    params?.codigo ? getURLWithParams(`/cest/information`, params) : null,
  );

/**
 * Hook para pesquisa de Cest
 * @param {*} id
 * @returns
 */
export const useCest = id => useFetchAuthenticate(id ? `/cest/${id}` : null);

/**
 * Hooks com as ações que podem ser disparadas por um formulário ou remoção de ncm
 * @returns
 */
export const useCestActions = () => {
  const { isLoading, onSend: onSendApi } = useAPIAuthenticateToast();

  const onSave = async (id, data = {}) =>
    onSendApi({
      endpoint: id ? `/cest/${id}` : "cest",
      messageSuccess: `Cest salvo com sucesso!`,
      messageFail: "Falha ao tentar salvar Cest!",
      data,
      type: id ? "PUT" : "POST",
    });

  const onRemove = async id =>
    onSendApi({
      endpoint: `/cest/${id}`,
      messageSuccess: `Cest REMOVIDO com sucesso!`,
      messageFail: "FALHA AO TENTAR REMOVER CEST!",
      type: "DELETE",
    });

  return {
    isLoading,
    onRemove,
    onSave,
  };
};
