import styled, { css } from "styled-components";

const columnFormItemModifiers = {
  setWidth: item => css`
    width: ${item} !important;
  `,
  setColor: item => css`
    border: 1px dashed ${item} !important;
  `,
  setBg: item => css`
    background-color: ${item} !important;
  `,
};

export const Title = styled.p`
  display: flex;
  flex-wrap: nowrap;
  border-bottom: 0;
  padding: 10px 8px 10px 8px !important;
  h5 {
    font-size: 12px;
    color: #000;
  }
`;

export const TitleText = styled.div`
  padding: 5px 0 5px 0;
  flex: 75%;
  line-height: 27px;
  h5 {
    font-size: 23px;
  }
`;

export const ContainerTooltip = styled.div`
  align-items: center;
  display: block;
  padding: 0px 8px 6px 8px;
  height: 250px;
  overflow-x: auto;
  h4 {
    padding-top: 3px;
    color: #949494;
  }
`;

export const ContainerName = styled.div`
  display: flex;
  width: 100%;
  background: #efefef;
  border: 1px dashed #dacccc;
  padding: 10px 20px 10px 20px;
`;

export const LoadingItem = styled.div`
  width: 100%;
`;

export const ContainerEanValidBody = styled.div`
  width: 100%;
`;

export const ButtonSetDados = styled.button`
  background: none;
  border-radius: 5px;
  padding: 0 5px 0 5px;
`;

export const ButtonGrupos = styled.button`
  color: #fff;
  background-color: #256925;
  border-radius: 5px;
  padding: 0 5px 0 5px;
`;

export const ContainerEanValid = styled.div`
  ${({ color }) => css`
    min-height: 140px;
    text-align: center;
    padding: 5px 10px 5px 10px;
    border-radius: 5px;
    ${color && columnFormItemModifiers.setColor(color)};
  `}
`;

export const ContainerItem = styled.div`
  ${({ width }) => css`
    ${width && columnFormItemModifiers.setWidth(width)}
  `}
`;

export const ContainerNameTitle = styled.h1`
  color: #605f5f;
`;

export const ContainerNameDescriptionUl = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  flex-flow: row wrap;
  align-content: space-between;
  justify-content: space-between;
`;
export const ContainerNameDescriptionLi = styled.li`
  ${({ color }) => css`
    cursor: pointer;
    color: #fff;
    font-size: 13px;
    font-weight: bold;
    padding: 0 15px 0 15px;
    border-radius: 50px;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 5px 10px 0;
    ${color && columnFormItemModifiers.setBg(color)};
  `}
`;
