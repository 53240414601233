import { useContext, useCallback } from "react";
import { RegisterLawsContext } from "../context/RegisterLawsContext";

export function useRegisterLaws() {
  const { values, setValues } = useContext(RegisterLawsContext);

  const setSimpleValue = useCallback(
    (key, value) => {
      setValues({ ...values, [key]: value });
    },
    [setValues, values],
  );

  const setComplexValue = useCallback(
    (key, value) => {
      if (Array.isArray(value)) {
        setValues({ ...values, [key]: value });
      } else {
        let newValues = values[key];

        if (newValues?.length > 0) {
          newValues.push(value);
        } else {
          newValues = [value];
        }

        setValues({ ...values, [key]: newValues });
      }
    },
    [setValues, values],
  );

  const setMergeValues = useCallback(
    mergeValues => {
      setValues(state => ({ ...state, ...mergeValues }));
    },
    [setValues],
  );

  return { values, setValues, setSimpleValue, setComplexValue, setMergeValues };
}
