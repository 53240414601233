import React from "react";
import { Datatable } from "@mix/mixfiscal-designsystem";
import * as ROUTES from "constants/routes";
import { ButtonOpenNewTag } from "components/structure";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faXmark } from "@fortawesome/free-solid-svg-icons";

import * as S from "../styles";

const Action = ({ _id }) => (
  <S.ActionFlex>
    <S.ActionFlexItem>
      <ButtonOpenNewTag
        title="Abrir detalhe do CEST"
        toLink={ROUTES.CESTS.DETAIL(_id)}
      />
    </S.ActionFlexItem>
    {/* <S.ActionFlexItem>
      <FontAwesomeIcon
        icon={faXmark}
        size="lg"
        style={{
          fontSize: "1.3rem",
        }}
        color="#a4a0a0"
      />
    </S.ActionFlexItem> */}
  </S.ActionFlex>
);

export const TableReports = ({ tableData, reports }) => {
  const columns = [
    {
      field: "action",
      title: "",
    },
    {
      field: "name",
      title: "Relatórios",
    },
  ];

  const findReportName = reportId => {
    const foundReport = reports?.docs?.find(report => report._id === reportId);
    return foundReport?.name;
  };

  return (
    <>
      <Datatable
        pagination={false}
        columns={columns}
        amountPerPage={50}
        itemsPerPage={[50, 10, 150, 300]}
        rawData={tableData.map(item => ({
          action: <Action {...item} />,
          name: findReportName(item.name),
        }))}
      />
    </>
  );
};
