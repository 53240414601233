/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useWatch as useWatchReactHookForm } from "react-hook-form";
import { useGridManagerContext } from "context/GridManagerContext";
import { formatCnpj } from "utils";

export const fieldsNamesLabels = {
  prod_codigo_barras: "EAN",
  mix_nome: "Produto Mix",
  cnpj: "CNPJ",
  gradeId: "Grade ID",
  status: "Status",
};

export const useWatch = ({ watch, control }) => {
  const { setFilterFields } = useGridManagerContext();
  const caractNaoTributadas = useWatchReactHookForm({
    control,
    name: "caract_nao_tributadas",
  });

  const alqIcmsE = useWatchReactHookForm({
    control,
    name: "alq_icms_e",
  });
  const alqIcmsS = useWatchReactHookForm({
    control,
    name: "alq_icms_s",
  });
  const alqCargaTributariaE = useWatchReactHookForm({
    control,
    name: "alq_carga_tributaria_e",
  });
  const alqCargaTributariaS = useWatchReactHookForm({
    control,
    name: "alq_carga_tributaria_s",
  });
  const cstE = useWatchReactHookForm({
    control,
    name: "cst_e",
  });
  const cstS = useWatchReactHookForm({
    control,
    name: "cst_s",
  });

  const alqPiscofinsE = useWatchReactHookForm({
    control,
    name: "alq_piscofins_e",
  });
  const alqPiscofinsS = useWatchReactHookForm({
    control,
    name: "alq_piscofins_s",
  });
  const alqReducaoBaseCalcE = useWatchReactHookForm({
    control,
    name: "alq_reducao_base_calc_e",
  });
  const alqReducaoBaseCalcS = useWatchReactHookForm({
    control,
    name: "alq_reducao_base_calc_s",
  });
  const efeitoTributarioSku = useWatchReactHookForm({
    control,
    name: "efeito_tributario_sku",
  });
  const ncmTable = useWatchReactHookForm({
    control,
    name: "ncm_table",
  });
  const operacaoE = useWatchReactHookForm({
    control,
    name: "operacao_e",
  });
  const operacaoS = useWatchReactHookForm({
    control,
    name: "operacao_s",
  });
  const stateEntry = useWatchReactHookForm({
    control,
    name: "state_entry",
  });
  const stateOut = useWatchReactHookForm({
    control,
    name: "state_out",
  });
  const typeOrganization = useWatchReactHookForm({
    control,
    name: "type_organization",
  });

  const types = useWatchReactHookForm({
    control,
    name: "types",
  });
  const csosn = useWatchReactHookForm({
    control,
    name: "csosn",
  });
  const norma = useWatchReactHookForm({
    control,
    name: "norma",
  });
  const cnae = useWatchReactHookForm({
    control,
    name: "cnae",
  });

  const atividade = useWatchReactHookForm({
    control,
    name: "atividade",
  });

  const {
    ncm_cest: ncmCest,
    ncm_tipi: ncmTipi,
    validate_date: validateDate,
    vigency_date: vigencyDate,
    publish_date: publishDate,
    ean,
  } = watch();

  useEffect(() => {
    setFilterFields({
      norma,
      cnae,
      types,
      ncm_cest: ncmCest,
      ncm_tipi: ncmTipi,
      operacao_e: operacaoE,
      operacao_s: operacaoS,
      ncm_table: ncmTable,
      publish_date: publishDate,
      validate_date: validateDate,
      vigency_date: vigencyDate,
      cst_e: cstE,
      cst_s: cstS,
      alq_icms_e: alqIcmsE,
      alq_icms_s: alqIcmsS,
      alq_piscofins_e: alqPiscofinsE,
      alq_piscofins_s: alqPiscofinsS,
      alq_reducao_base_calc_e: alqReducaoBaseCalcE,
      alq_reducao_base_calc_s: alqReducaoBaseCalcS,
      efeito_tributario_sku: efeitoTributarioSku,
      state_entry: stateEntry,
      state_out: stateOut,
      type_organization: typeOrganization,
      caract_nao_tributadas: caractNaoTributadas,
      alq_carga_tributaria_e: alqCargaTributariaE,
      alq_carga_tributaria_s: alqCargaTributariaS,
      atividade,
      ean,
    });
  }, [
    norma,
    ncmTipi,
    ncmCest,
    cnae,
    publishDate,
    validateDate,
    vigencyDate,
    operacaoE,
    operacaoS,
    stateEntry,
    stateOut,
    typeOrganization,
    ncmTable,
    types,
    cstE,
    cstS,
    csosn,
    alqIcmsE,
    alqIcmsS,
    alqCargaTributariaE,
    alqCargaTributariaS,
    alqPiscofinsE,
    alqPiscofinsS,
    alqReducaoBaseCalcE,
    efeitoTributarioSku,
    caractNaoTributadas,
    alqReducaoBaseCalcS,
    ean,
    atividade,
  ]);
};

const getTag = ({ label, type, value }) => ({
  type,
  value,
  label: `${fieldsNamesLabels[type]}: ${label}`,
});

export const getTags = (filterFields, types = ["all"]) => {
  const tags = {
    cnpj: (filterFields?.cnpj || []).map(cnpj => ({
      label: formatCnpj(cnpj),
      type: "cnpj",
      value: cnpj,
    })),
    prod_codigo_barras: (filterFields?.prod_codigo_barras || []).map(ean => ({
      label: ean,
      type: "prod_codigo_barras",
      value: ean,
    })),
    mix_nome: (filterFields?.mix_nome || []).map(name => ({
      label: name,
      type: "mix_nome",
      value: name,
    })),
    gradeId: (filterFields?.gradeId || []).map(name => ({
      label: name,
      type: "gradeId",
      value: name,
    })),
    status: (filterFields?.status || []).map(status => ({
      label: status,
      type: "status",
      value: status,
    })),
  };

  if (types.indexOf("all") >= 0) {
    return [
      ...tags.cnpj,
      ...tags.prod_codigo_barras,
      ...tags.mix_nome,
      ...tags.gradeId,
      ...tags.status,
    ].map(getTag);
  }

  const res = [];

  for (const tag of Object.keys(tags)) {
    if (types.indexOf(tag) >= 0) {
      tags[tag].map(arg => res.push(getTag(arg)));
    }
  }

  return res;
};
