import { useState, useCallback } from "react";
import {
  useFetchAuthenticate,
  useAPIAuthenticate,
  useAPIAuthenticateToast,
  useToast,
} from "hooks";

import { getURLWithParams } from "utils/context";

export const useProduct = id =>
  useFetchAuthenticate(id ? `/product/${id}` : null);

export const useProductData = () => {
  const api = useAPIAuthenticate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const fetch = useCallback(
    (id = "") => {
      setLoading(true);
      const fetchData = async () => {
        try {
          const {
            data: { product },
          } = await api.get(`/product/mix/${id}`);
          setData(product);
          setLoading(false);
        } catch (e) {
          setLoading(false);
          console.log(e.messagge);
        }
      };
      fetchData();
    },
    [api],
  );

  return { isLoading: loading, data, fetch };
};

export const useProductPostOrPut = item => {
  const api = useAPIAuthenticate();
  const [isLoading, setIsLoading] = useState();
  const { addToast } = useToast();

  const onSave = async dataFields => {
    try {
      setIsLoading(true);
      if (!item?.id) {
        const { data } = await api.post("/products/mix", dataFields);
        if (data.response) {
          addToast({
            type: "success",
            title: "SUCESSO",
            description: `Produto  criado com sucesso!`,
          });
        }
        setIsLoading(false);
        return data;
      }
      const { data } = await api.put(`/product/mix/${item.id}`, dataFields);

      if (data.response) {
        addToast({
          type: "success",
          title: "SUCESSO",
          description: `Produto atualizada com sucesso!`,
        });
      }
      setIsLoading(false);
      return data;
    } catch (err) {
      console.log({ err });
      if (err?.response?.data?.type === "product_mix_fiscal_exists") {
        setIsLoading(false);
        return addToast({
          type: "error",
          title: err?.response?.data?.detail,
          description: err?.response?.data?.message,
        });
      }
      addToast({
        type: "error",
        title: "FALHA",
        description: "Falha ao cadastrar produtos",
      });
      setIsLoading(false);
    }
  };

  return {
    onSave,
    isLoading,
  };
};

export const useProductRemove = () => {
  const { isLoading, onSend } = useAPIAuthenticateToast();

  const onRemove = id => {
    onSend({
      endpoint: `/product/mix/${id}`,
      messageSuccess: `Produto removido com sucesso!`,
      messageFail: "Falha ao tentar remover produto!",
      type: "DELETE",
    });
  };

  return {
    onRemove,
    isLoading,
  };
};

export const useProductsMix = (params = {}) =>
  useFetchAuthenticate(!params ? null : getURLWithParams(`/products`, params));

export const useMercadologico = (params = {}) =>
  useFetchAuthenticate(getURLWithParams(`/mercadologico`, params));

export const useProductsMixSearching = () => {
  const api = useAPIAuthenticate();
  const [dataItems, setData] = useState(null);
  const [totalDocs, setTotalDocs] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const fetch = useCallback(
    (params = {}) => {
      setLoading(true);
      const fetchData = async () => {
        try {
          console.log({ params });
          const { data } = await api.post("/products/searching", {
            ...params,
          });
          setData(data?.docs);
          setTotalDocs(data?.total);
          setTotalPages(data?.totalPages);
          setLoading(false);
        } catch (e) {
          console.log(e.messagge);
        }
      };
      fetchData();
    },
    [api],
  );

  return { isLoading: loading, data: dataItems, totalPages, totalDocs, fetch };
};
