import React, { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAuth } from "context/AuthContext";
import { Breadcrumb } from "components/structure";
import { MENUS_MAIN, MENUS_SUBS } from "constants/menu";
// TODO: Adicionado inteligencia comercial apenas temporariamente aqui
import { BusinessIntelligence } from "components/contexts/dashboard/business-intelligence";
import { useApp } from "context/AppContext";
import { PagesContext } from "context/PageContext";
import Permissions from "../Permissions";
import { Menu } from "./components/Menu";
import Header from "./components/Header";

import * as S from "./styles";

const getMenus = ({ currentPath, pathname }) => {
  const menuItem = [
    ...MENUS_SUBS,
    ...MENUS_MAIN.filter(menu => menu.linkTo).map(menu => ({
      ...menu,
      link: menu.linkTo,
    })),
  ].find(menu => menu.link === pathname || menu.link === currentPath);

  return menuItem;
};

const getCurrentPathWithoutLastPart = location => {
  const pathRgx = /\//g;
  const childroutecount = ((location.pathname || "").match(pathRgx) || [])
    .length;
  return childroutecount > 1
    ? location.pathname.slice(0, location.pathname.lastIndexOf("/"))
    : location.pathname;
};

function Layout({ children }) {
  const { tokenCompanies } = useAuth();

  const { app, setBusinessIntelligence } = useApp();
  const [isUploadingDialogOpen, setIsUploadingDialogOpen] = useState(false);
  const [isActiveMenu, setIsActiveMenu] = useState(false);
  const location = useLocation();
  const contextDash = useContext(PagesContext);
  const currentPath = getCurrentPathWithoutLastPart(location);

  const handleClick = () => {
    const today = new Date();
    const lastDayMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const dateStart = new Date(today.getFullYear(), today.getMonth(), 1);
    const dateEnd = new Date(
      `${today.getFullYear}-${today.getMonth}-${lastDayMonth}`,
    );
    contextDash.setValuesPageProvider({
      dateStart,
      dateEnd,
    });
  };

  const handleClickMenu = () => {
    setIsActiveMenu(!isActiveMenu);
  };

  const moduleSubBreadcrumb =
    getMenus({
      pathname: location.pathname,
    }) ??
    getMenus({
      currentPath,
      pathname: location.pathname,
    });

  const moduleBreadcrumb = MENUS_MAIN.find(
    menu => menu.name === moduleSubBreadcrumb?.parent,
  );

  const handleClickBreadcrumb = item => {
    console.log({ item });
  };

  const breadcrumbList = [
    {
      url: moduleBreadcrumb?.link,
      label: moduleBreadcrumb?.title,
    },
    {
      url: moduleSubBreadcrumb?.link,
      label: moduleSubBreadcrumb?.title,
    },
  ].filter(item => item.label);

  return (
    <S.Background>
      <S.Container>
        <S.MenuContainer
          activeTheme={app.appBusinessIntelligence}
          active={isActiveMenu}
          className={isActiveMenu ? "active" : ""}
        >
          <S.MenuHamb onClick={handleClickMenu}>
            <S.MenuHambItem />
            <S.MenuHambItem />
            <S.MenuHambItem />
          </S.MenuHamb>
          <Menu
            isUploadingDialogOpen={isUploadingDialogOpen}
            setIsUploadingDialogOpen={setIsUploadingDialogOpen}
            active={isActiveMenu}
            activeTheme={app.appBusinessIntelligence}
          />
        </S.MenuContainer>
        <S.Main>
          <S.HeaderContainer>
            <Header
              app={app}
              setBusinessIntelligence={setBusinessIntelligence}
              isUploadingDialogOpen={isUploadingDialogOpen}
              setIsUploadingDialogOpen={setIsUploadingDialogOpen}
            />
          </S.HeaderContainer>
          <S.Wrapper>
            <S.RouteContent>
              <S.ContentHeader>
                <S.BreadCrumbContainer
                  activeTheme={app.appBusinessIntelligence}
                >
                  {Boolean(breadcrumbList.length) && (
                    <Breadcrumb
                      handleClick={handleClickBreadcrumb}
                      items={breadcrumbList}
                    />
                  )}
                </S.BreadCrumbContainer>

                <S.WrapperPermissions>
                  {location.pathname === "/" && (
                    <S.ButtonContainerWhite
                      activeTheme={app.appBusinessIntelligence}
                      onClick={() => handleClick()}
                    >
                      <p> Mês Atual</p>
                    </S.ButtonContainerWhite>
                  )}
                  <Permissions activeTheme={app.appBusinessIntelligence} />
                </S.WrapperPermissions>
              </S.ContentHeader>

              {app.appBusinessIntelligence ? (
                location.pathname !== `/` ? (
                  children
                ) : (
                  <BusinessIntelligence token={tokenCompanies} />
                )
              ) : (
                children
              )}
            </S.RouteContent>
          </S.Wrapper>
        </S.Main>
      </S.Container>
    </S.Background>
  );
}

export default Layout;
