import React from "react";
import { Icon } from "@mix/mixfiscal-designsystem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faListCheck,
  faHandHoldingDollar,
  faCloudArrowUp,
  faLink,
  faCommentDots,
  faCartShopping,
} from "@fortawesome/free-solid-svg-icons";
import { MENU_MAIN_TYPES_COMERCIAL, MENU_MAIN_TYPES } from "../main";

export const MENUS_MAIN_COMERCIAL = [
  {
    name: MENU_MAIN_TYPES_COMERCIAL.DASHBOARD_COMERCIAL, // Nome do menu
    title: "Dashboard", // Titulo do mainMenu
    hasSubMenu: false, // Se o menu possui um submenu
    linkTo: "/", // Link para ser enviado quando o menu for clicado
    icon: <Icon name="dashboard" size={30} color="#fff" />, // Icone que vai ser utilizado no menu
    slug: "dashboard",
    position: 1,
  },
  {
    name: MENU_MAIN_TYPES_COMERCIAL.FORNECEDORES, // Nome do menu
    title: "Inteligência de Fornecedores", // Titulo do mainMenu
    hasSubMenu: true, // Se o menu possui um submenu
    slug: "supplier-intelligence",
    position: 2,
    icon: (
      <FontAwesomeIcon
        icon={faHandHoldingDollar}
        style={{
          fontSize: "1.5rem",
        }}
        color="#fff"
      />
    ),
  },
  {
    name: MENU_MAIN_TYPES.CHECKLINK, // Nome do menu
    title: "CHECKLINK", // Titulo do mainMenu
    hasSubMenu: true, // Se o menu possui um submenu
    slug: "check-link",
    position: 3,
    icon: (
      <FontAwesomeIcon
        icon={faListCheck}
        size="lg"
        style={{
          fontSize: "1.5rem",
        }}
        color="#fff"
      />
    ),
  },

  {
    name: MENU_MAIN_TYPES.UPLOAD,
    title: "Arquivos",
    hasSubMenu: false,
    position: 4,
    slug: "upload",
    icon: (
      <FontAwesomeIcon
        icon={faCloudArrowUp}
        size="lg"
        style={{
          fontSize: "1.5rem",
        }}
        color="#fff"
      />
    ),
  },
  {
    name: MENU_MAIN_TYPES.INTEGRACOES,
    title: "Integrações",
    hasSubMenu: false,
    position: 5,
    slug: "integration",
    icon: (
      <FontAwesomeIcon
        icon={faLink}
        size="lg"
        style={{
          fontSize: "1.5rem",
        }}
        color="#fff"
      />
    ),
  },
  {
    name: MENU_MAIN_TYPES.MARKETPLACE,
    title: "Marketplace",
    hasSubMenu: false,
    position: 6,
    slug: "marketplace",
    icon: (
      <FontAwesomeIcon
        icon={faCartShopping}
        size="lg"
        style={{
          fontSize: "1.5rem",
        }}
        color="#fff"
      />
    ),
    linkTo: "/404",
  },
  {
    name: MENU_MAIN_TYPES.SUPORTE,
    title: "Suporte",
    hasSubMenu: true,
    position: 7,
    slug: "suport",
    icon: (
      <FontAwesomeIcon
        icon={faCommentDots}
        size="lg"
        style={{
          fontSize: "1.5rem",
        }}
        color="#fff"
      />
    ),
  },
];
