import React from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "context/AuthContext";
import { Observacoes } from "components/contexts/reports/Observacoes/Observacoes";

export const ObservacoesReport = () => {
  const { tokenCompanies } = useAuth();
  const history = useHistory();
  return <Observacoes token={tokenCompanies} history={history} />;
};
