/* eslint-disable func-names */
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = yup.object().shape({
  tipoNorma: yup.string().required(),
  nome: yup.string(),
  descricao: yup.string(),
  dataPublicacao: yup.string(),
  vigencia: yup.string(),
  validadeIndeterminada: yup.string(),
  validade: yup.string(),
  secao: yup.string(),
  artigo: yup.string(),
  inciso: yup.string(),
  alinea: yup.string(),
  paragrafo: yup.string(),
  titulo: yup.string(),
  caput: yup.string(),
  capitulo: yup.string(),
  anexo: yup.string(),
  parte: yup.string(),
  subsecao: yup.string(),
  livro: yup.string(),
  item: yup.string(),
  preambulo: yup.string(),
  ementa: yup.string(),
  relatorio: yup.string(),
  fundamentacao: yup.string(),
  dispositivo: yup.string(),
  assunto: yup.string(),
  dispositivosLegais: yup.string(),
  fundamento: yup.string(),
  conclusao: yup.string(),
  relato: yup.string(),
  url: yup.string(),
  monitoramento: yup.string(),
});

export default yupResolver(schema);
