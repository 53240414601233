import React from "react";
import { Icon } from "@mix/mixfiscal-designsystem";
import { Controller } from "react-hook-form";
import ReactSelect, { components } from "react-select";
import * as S from "./styles";

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon name="dropdown" color="#8C8B8B" size={12} />
    </components.DropdownIndicator>
  );
};

const NoOptionsMessage = props => {
  return (
    <components.NoOptionsMessage {...props}>
      <span style={{ fontSize: "1rem" }}>Sem dados</span>
    </components.NoOptionsMessage>
  );
};

const MultiValue = ({ index, getValue, ...props }) => (
  <components.MultiValue {...props} />
);

export const Select = ({
  control,
  label,
  name,
  defaultValue,
  data,
  isDisabled,
  labelType,
  placeholder = "Selecione",
  isLoading,
  components: componentProps = {},
  isSearchable,
  ...rest
}) => {
  if (!control) {
    return (
      <S.Select type={labelType}>
        {label && <S.Label isDisabled={isDisabled}>{label}</S.Label>}
        <ReactSelect
          isSearchable={isSearchable}
          onMenuOpen
          styles={{
            control: S.control,
            indicatorSeparator: S.indicatorSeparator,
            valueContainer: S.valueContainer,
            placeholder: S.placeholder,
            singleValue: S.singleValue,
            menu: S.menu,
          }}
          components={{
            DropdownIndicator,
            NoOptionsMessage,
            MultiValue,
            ...componentProps,
          }}
          options={data}
          placeholder={placeholder}
          isDisabled={isDisabled}
          labelType={labelType}
          {...rest}
        />
      </S.Select>
    );
  }
  return (
    <S.Select type={labelType}>
      {label && <S.Label isDisabled={isDisabled}>{label}</S.Label>}
      {isLoading ? (
        <S.SelectLoading>
          <span>Carregando...</span>
        </S.SelectLoading>
      ) : (
        <Controller
          control={control}
          name={name}
          defaultValue={defaultValue}
          render={({ field }) => (
            <ReactSelect
              isSearchable={isSearchable}
              onMenuOpen
              styles={{
                control: S.control,
                indicatorSeparator: S.indicatorSeparator,
                valueContainer: S.valueContainer,
                placeholder: S.placeholder,
                singleValue: S.singleValue,
                menu: S.menu,
              }}
              components={{ DropdownIndicator, NoOptionsMessage }}
              options={data}
              placeholder={placeholder}
              isDisabled={isDisabled}
              {...field}
              {...rest}
            />
          )}
        />
      )}
    </S.Select>
  );
};
