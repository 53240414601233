import { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useRegisterLaws } from "hooks/useRegisterLaws";
import * as contextLaws from "utils/context/laws";
import { hasGradeEntrada, hasPisCofinsTax } from "utils/context/grid-manager";

const loadAliqs = ({ values: valuesLocal, grade }) => {
  valuesLocal.tipoMva = grade.tipo_MVA;
  valuesLocal.valorMva = grade.valor_MVA;

  const [pisAlqEntrada] = grade.pis_alq_e;
  const [pisAlqSaida] = grade.pis_alq_s;
  const [cofinsAlqEntrada] = grade.cofins_alq_e;
  const [cofinsAlqSaida] = grade.cofins_alq_s;

  valuesLocal.aliqEntrada = pisAlqEntrada;
  valuesLocal.aliqSaida = pisAlqSaida;
  valuesLocal.aliqEntradaCofins = cofinsAlqEntrada;
  valuesLocal.aliqSaidaCofins = cofinsAlqSaida;
  valuesLocal.cstEntrada = grade.piscofins_cst_e;
  valuesLocal.cstSaida = grade.piscofins_cst_s;
};

const pisCofinsGetTotal = ({ entrada = [], saida = [] }) => {
  const list =
    entrada.length >= saida.length
      ? entrada.map(alq => ({ alq, type: "entrada" }))
      : saida.map(alq => ({ alq, type: "saida" }));

  return list.map((item, i) => {
    if (item.type === "entrada") {
      return { entrada: item.alq, saida: saida[i] };
    }

    return {
      saida: item.alq,
      entrada: entrada[i],
    };
  });
};

export const useLoadAliqs = ({ grade = {} }) => {
  // Carregando aliquotas do pis/cofins
  const pisCofinsAliqs = useMemo(() => {
    const pisList = pisCofinsGetTotal({
      entrada: grade?.pis_alq_s || [],
      saida: grade?.pis_alq_e || [],
    });
    const cofinsList = pisCofinsGetTotal({
      entrada: grade?.cofins_alq_e || [],
      saida: grade?.cofins_alq_s || [],
    });

    return [
      [...pisList, ...cofinsList].reduce(
        (previous, current) => {
          return {
            entrada: current.entrada + previous.entrada,
            saida: current.saida + previous.saida,
          };
        },
        { entrada: 0, saida: 0 },
      ),
    ];
  }, [
    grade?.pis_alq_s,
    grade?.pis_alq_e,
    grade?.cofins_alq_e,
    grade?.cofins_alq_s,
  ]);

  // Carregando aliquotas do icms
  const icmsAliqs = useMemo(() => {
    const alqsSaida = grade?.alq_saida || [];
    const alqsEntrada = grade?.alq || [];

    const list =
      alqsEntrada.length >= alqsSaida.length
        ? alqsEntrada.map(alq => ({ alq, type: "entrada" }))
        : alqsSaida.map(alq => ({ alq, type: "saida" }));

    return list.map((item, i) => {
      if (item.type === "entrada") {
        return { entrada: item.alq, saida: alqsSaida[i] };
      }

      return {
        saida: item.alq,
        entrada: alqsEntrada[i],
      };
    });
  }, [grade?.alq, grade?.alq_saida]);

  return { pisCofinsAliqs, icmsAliqs };
};

export const useLoadGradeData = ({ data, alq, isLoading }) => {
  const { setValues, values, setMergeValues } = useRegisterLaws();
  const location = useLocation();
  const paramsSearch = new URLSearchParams(location.search);
  const taxType = paramsSearch.get("type");

  useEffect(() => {
    if (taxType) {
      values.taxa = hasPisCofinsTax(taxType)
        ? { pis: true, cofins: true }
        : { icms: true };
      setValues(values);
    }
  }, [values, taxType, setValues]);

  useEffect(() => {
    if (data?.gradeIcms && data?.gradeIcms?.alq) {
      try {
        const valuesLocal = {};

        const { uf, uf_saida: ufSaida, operacao, ...grade } = data.gradeIcms;

        valuesLocal.scenery = contextLaws.attributesBuilderToScenary({
          values: data.gradeIcms,
        });

        // Load cenarios que foram mergeados em uma grade/lei
        const sceneries = data?.gradeIcms?.cenariosAgg
          .filter(scenary => scenary._id !== data?.gradeIcms?._id)
          .map(scenery =>
            contextLaws.attributesBuilderToScenary({ values: scenery }),
          );

        valuesLocal.isLoading = isLoading;

        valuesLocal.cenarios = [...[valuesLocal.scenery], ...(sceneries ?? [])];
        valuesLocal.ncms = data?.law?.ncms
          ? contextLaws.ncmBuilderToLinkmix(data?.law?.ncms)
          : [
              {
                data: { vc_codigo_sem_formatacao: grade.ncm },
              },
            ];

        if (grade.cest && grade.cest !== "" && !data?.law) {
          valuesLocal.cests = [
            {
              data: { vc_codigo: grade.cest },
            },
          ];
        }

        if (data?.law) {
          valuesLocal.cests = contextLaws.cestsBuilderToLinkmix(data.law.cests);
        }

        const isGradeSaida = !hasGradeEntrada(operacao);

        valuesLocal.tipoMva = grade.tipo_MVA;
        valuesLocal.valorMva = grade.valor_MVA;

        const [alq1] = grade.alq;
        const [alqSaida] = grade.alq_saida;

        // TODO: Retirar essa checagem de grade de saida daqui
        valuesLocal.aliqEntrada =
          !grade.id_law && isGradeSaida ? null : alq || alq1;
        valuesLocal.aliqSaida =
          !grade.id_law && isGradeSaida ? alq || alq1 : alqSaida;
        valuesLocal.gradesIcmsAlq = grade.alq;
        valuesLocal.cstEntrada = grade.cst;
        valuesLocal.cstSaida =
          !grade.id_law && isGradeSaida ? grade.cst : grade.cst_saida;

        valuesLocal.reducaoBaseCalc = grade.rbc;

        valuesLocal.gradeIcms = data.gradeIcms;
        valuesLocal.index = data?.index;

        setMergeValues(valuesLocal);
      } catch (err) {
        console.log(err);
      }
    }
  }, [data?.gradeIcms, setMergeValues, alq, data?.law, data?.index, isLoading]);

  /**
   * Loading data for grade_piscofins
   */
  useEffect(() => {
    if (data?.gradePisCofins) {
      try {
        const valuesLocal = {};

        const { operacao, ...grade } = data.gradePisCofins;

        valuesLocal.scenery = contextLaws.attributesBuilderToScenary({
          values: {
            ...data.gradePisCofins,
            uf_saida: "FEDERAL",
            uf: "FEDERAL",
          },
        });

        valuesLocal.isLoading = isLoading;

        valuesLocal.cenarios = [valuesLocal.scenery];
        valuesLocal.ncms = data?.law?.ncms
          ? contextLaws.ncmBuilderToLinkmix(data?.law?.ncms)
          : [
              {
                data: { vc_codigo_sem_formatacao: grade.ncm },
              },
            ];

        if (grade.cest && grade.cest !== "" && !data?.law) {
          valuesLocal.cests = [
            {
              data: { vc_codigo: grade.cest },
            },
          ];
        }

        if (data?.law) {
          valuesLocal.cests = contextLaws.cestsBuilderToLinkmix(data.law.cests);
        }

        loadAliqs({ values: valuesLocal, grade });

        valuesLocal.gradePisCofins = data.gradePisCofins;

        valuesLocal.index = data?.index;

        setMergeValues(valuesLocal);
      } catch (err) {
        console.log({ err });
      }
    }
  }, [data?.gradePisCofins, setMergeValues, isLoading, data?.index, data?.law]);

  return {
    values,
  };
};
