import styled from "styled-components";

export const Wrapper = styled.div`
  position: absolute;
  z-index: 99;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

export const Backgroud = styled.div`
  position: absolute;
  z-index: 99;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--white) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 0.5;
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
`;

export const Container = styled.div`
  width: 450px;
  min-height: 700px;
  background: var(--white) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: -10px 10px 8px #00000029;
  border: 1px solid #707070;
  opacity: 1;
  position: absolute;
  z-index: 999;
  right: 10px;
  top: 75px;
`;

export const Close = styled.button`
  background: #ffffff;
  position: absolute;
  top: -15px;
  left: -15px;
  border-radius: 100%;
  color: #5e93d5;
  box-shadow: -10px 10px 8px #00000029;
  height: 32px;
  width: 32px;
  font-size: 21px;
`;

export const Title = styled.div`
  height: 50px;
  background: var(--heavenly) 0% 0% no-repeat padding-box;
  background: #5e93d5 0% 0% no-repeat padding-box;
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) var(--unnamed-font-size-26) /
    var(--unnamed-line-spacing-30) var(--unnamed-font-family-cairo);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--white);
  text-align: left;
  font: normal normal normal 26px/30px Cairo;
  letter-spacing: 0px;
  color: #ffffff;
  padding: 10px 20px;
  text-transform: uppercase;
`;

export const SubTitle = styled.div`
  height: 50px;
  background: var(--turquoise) 0% 0% no-repeat padding-box;
  background: #52b4af 0% 0% no-repeat padding-box;
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) var(--unnamed-font-size-18) /
    var(--unnamed-line-spacing-33) var(--unnamed-font-family-cairo);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--white);
  text-align: left;
  font: normal normal normal 18px/33px Cairo;
  letter-spacing: 0px;
  color: #ffffff;
  padding: 10px 20px;
  text-transform: uppercase;
`;

export const TextAreaContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 20px;
  & + & {
    margin-left: 12px;
  }
  label,
  input,
  select,
  textarea {
    font-size: 14px;
    color: #596778;
  }
  select {
    height: 36px;
    background-color: #fff;
  }
  input,
  select,
  textarea {
    border: 1px solid #596778;
    border-radius: 5px;
    padding: 6px 10px;
  }
  div {
    display: flex;
    align-items: center;
  }
`;

export const TitleSession = styled.div`
  width: 100%;
  padding: 10px 20px;
  background: #fff;
  position: relative;

  > h1 {
    color: #1b66b0;
  }
`;

export const SubTasks = styled.div`
  width: 100%;
  padding: 0px 30px;
  font-weight: ${props => props.fontWeight ?? "none"};

  label {
    display: flex;
    gap: 10px;
    padding: 5px 0;
    align-items: center;
  }
`;

export const TimerWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 0px 20px;
  gap: 10px;
  align-items: center;

  svg {
    font-size: 32px;
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 0px 20px;
  gap: 10px;
  align-items: center;

  button {
    flex: 1;
  }
`;

export const Button = styled.button`
  background-color: #5a8dee;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  padding: 8px;
  border-radius: 5px;
  align-self: flex-start;

  &:disabled {
    background: #ccc;
    cursor: unset;
  }
`;
