import styled from "styled-components";

export const Container = styled.div`
  width: 400px;
`;

export const Table = styled.table`
  background-color: #c1dcc1;
  border-collapse: collapse;
  font-size: 1rem;
  width: 100%;
  cursor: pointer;
`;

export const Td = styled.td`
  border: 1px solid;
  text-align: center;
  font-size: 0.9rem;
`;

export const Th = styled.th`
  border: 1px solid;
  padding: 5px;
  font-weight: 400;
  font-size: 0.9rem;
`;
