import { CircularProgress } from "@material-ui/core";
import React from "react";
import { useTheme } from "styled-components";

import * as S from "./styles";

export const CircularLoader = ({ size }) => {
  const theme = useTheme();
  return (
    <S.Container>
      <CircularProgress
        style={{
          color: theme.colors.primary,
          width: size,
          height: size,
        }}
      />
    </S.Container>
  );
};

export default CircularLoader;
