import styled, { css } from "styled-components";

export const CurrencyField = styled.div`
  & > input {
    border: 1px solid #596778;
    padding: 4px;
    border-radius: 4px;

    &:hover {
      border-color: #2684ff;
      box-shadow: 0 0 0 1px #2684ff;
    }
  }
`;

const inputFieldModifiers = {
  disabled: () => css`
    background-color: rgb(179 179 179 / 24%);
    border-color: rgb(179 179 179 / 24%);
  `,
};

export const InputField = styled.div`
  ${({ error, disabled }) => css`
    /* transition: all 0.2s ease 0s;
    padding: 6px 8px;
    border-radius: 5px;
    background-color: rgb(255, 255, 255);
    font-size: 14px;
    width: 100%;

    color: ${error ? "#f48fb1" : "rgb(89, 103, 120)"};
    border: ${error ? "1px solid #f48fb1" : "1px solid rgb(89, 103, 120)"};

    ${disabled && inputFieldModifiers.disabled()}

    &:hover, &:active, &:focus {
      border-color: #2684ff;
      box-shadow: 0 0 0 1px #2684ff;
    } */
  `}
`;

export const InputFieldContainer = styled.div`
  width: 100%;
`;

export const InputFieldLabel = styled.label`
  ${({ error }) => css`
    color: ${error ? "#f48fb1" : ""};
    font-size: 14px;
    margin-left: 8px;
    display: block;
    width: 100%;
  `}
`;

export const InputFieldHelperText = styled.label`
  ${({ error }) => css`
    display: ${error ? "block" : "none"};
    color: ${error ? "#f48fb1" : ""};
    font-size: 12px;
    margin-left: 8px;
  `}
`;
