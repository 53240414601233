import styled from "styled-components";

export const CardLateralHeader = styled.header`
  display: flex;
  flex-basis: 100%;
  flex: 1;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  writing-mode: vertical-lr;
  transform: rotate(-180deg);

  font-size: 15px;
  color: #fff;
  font-weight: 700;
  width: 50px;

  border: 1px solid #1b66b0;
  border-radius: 4px;
  background-color: #1b66b0;
`;

export const CardLateralMain = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

  padding: 8px;
  border: 1px solid #1b66b0;
  border-radius: 4px;
`;

export const CardWithLateralHeader = styled.div`
  display: flex;
  align-items: stretch;
  width: 100%;
  justify-content: center;

  margin-top: 20px;
  margin-left: ${props => (props.marginLeft ? props.marginLeft : "")};

  min-height: ${props => (props.height ? props.height : "125px")};

  ${CardLateralMain}, ${CardLateralHeader} {
    border: 1px solid #1b66b0;
  }

  ${CardLateralHeader} {
    background-color: #1b66b0;
    justify-content: center;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0px;
  min-height: 0px;

  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 8px #0000002e;
`;

export const Header = styled.div`
  width: 100%;
  padding: 8px 16px;
  background-color: ${props => props.color};
  display: flex;
  align-items: center;
  min-width: 0px;
  min-height: 0px;
  flex-shrink: 0;

  p {
    color: #fff;
    font-size: 15px;
    text-transform: uppercase;
  }

  svg {
    fill: #fff;
    margin-right: 8px;
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  min-height: 0px;
  padding: 16px;

  display: flex;
  flex-direction: column;
`;
