import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormContext } from "react-hook-form";
import { TextAreaAdvanced } from "components/form";
import { useAssessorPainel } from "context/AssessorContext";

import { useUserWhatsappSendMsg } from "useCases";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";

import * as Sw from "./WhatsAppDados.styles";

export const WhatsAppFormSend = ({
  handleSubmit,
  selectedClient,
  showBtns,
  setshowBtns,
  isSendMsg,
  user,
  control,
  isDisabled = false,
}) => {
  const { newMessage } = useAssessorPainel();
  const { setValue } = useFormContext();
  const { fetch: sendMSg, succesSend } = useUserWhatsappSendMsg();

  const onSubmit = data => {
    sendMSg({
      phone: `55${selectedClient?.id_store?.whatsapp}`,
      message: data.message,
      clientId: user.email,
    });
    if (succesSend) {
      const sendMessage = {
        createdAt: new Date(),
        message: data.message,
        user_id: user._id,
        type: "internal",
      };

      newMessage(selectedClient.id_store?._id, sendMessage);

      setValue("message");
    }
  };

  return (
    <>
      <Sw.ContainerRowItemContentWhatsAppFooterForm
        onSubmit={handleSubmit(onSubmit)}
        padding="20px 10px 20px 14px"
      >
        <Sw.ContainerRowItemContentWhatsAppFooterInput>
          <TextAreaAdvanced
            isDisabled={isDisabled}
            control={control}
            name="message"
            placeholder="Mensagem"
          />
        </Sw.ContainerRowItemContentWhatsAppFooterInput>
        <Sw.ContainerRowItemContentWhatsAppFooterBtn>
          <Sw.BtnSend
            disabled={isDisabled}
            type={!showBtns && !isSendMsg ? "button" : "submit"}
            onClick={() => setshowBtns(!!(!showBtns && !isSendMsg))}
          >
            <FontAwesomeIcon color="#fff" size="lg" icon={faPaperPlane} />
          </Sw.BtnSend>
        </Sw.ContainerRowItemContentWhatsAppFooterBtn>
      </Sw.ContainerRowItemContentWhatsAppFooterForm>
    </>
  );
};
