import styled from "styled-components";
import colors from "styles/tokens/colors";

export const Wrapper = styled.div`
  table {
    thead {
      th {
        padding: 5px 8px !important;
      }
      .headerCell {
        [role="button"] {
          font-size: 0.9rem;
        }
        svg {
          display: none;
        }
      }
    }
    tbody tr td {
      font-size: 0.9rem;
    }
  }
`;

export const ButtonClick = styled.button`
  background-color: transparent;
  position: relative;
`;

export const InputUpload = styled.input`
  display: none;
`;

export const Title = styled.p`
  display: flex;
  flex-wrap: nowrap;
  border-bottom: 0;
  padding: 9px 8px 0px 8px !important;
  h5 {
    font-size: 22px;
    color: #1b66b0;
  }
`;

export const TitleIcon = styled.div`
  flex: 25%;
`;
export const TitleText = styled.div`
  flex: 75%;
  margin-left: 5px;
  line-height: 27px;
  h5 {
    font-size: 13px;
  }
`;

export const Footer = styled.div`
  border-top: 1px solid #ccc;
  justify-content: space-between;
  display: flex;
`;

export const FooterContainer = styled.div`
  justify-content: space-between;
  display: flex;
  padding: 0px 10px;
  width: 100%;
`;

export const ContainerTooltip = styled.div`
  align-items: center;
  display: block;
  padding: 0px 8px 6px 8px;
  h4 {
    padding-top: 3px;
    color: #596778;
  }
`;

export const IconWrapper = styled.div`
  width: 100%;
  display: flex;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  border: 1px dashed #596778;
  padding: 4px 5px;
  cursor: pointer;
  max-width: 320px;
  display: none;
  div {
    margin: 5px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    span {
      font-size: 14px;
      color: #596778;
  }
`;
export const ListPfx = styled.div`
  width: 100%;
  display: table;
  margin-top: 0px;
  border-radius: 5px;
  padding: 0 0 0 0;
  border-radius: 0px;
  border: 1px solid #596778;
`;
export const ListPfxHeader = styled.div`
  width: 100%;
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  align-items: flex-start;
  border-bottom: 1px solid #596778;
`;
export const TitleListHeader = styled.div`
  flex: 90%;
`;
export const IconListHeader = styled.div`
  cursor: pointer;
  svg {
    padding-top: 7px;
  }
`;

export const ListPfxContent = styled.div`
  width: 100%;
  display: table;
`;

export const ListPfxContentItems = styled.div`
  width: 100%;
  display: felx;
  padding-left: 10px;
  padding-right: 10px;
  align-items: flex-start;
  background-color: #f4f4f4;
  transition: all 0.3s;
  cursor: pointer;
  &:hover {
    background-color: #fff;
  }
`;
export const ListPfxName = styled.div`
  flex: 90%;
  h3 {
    font-size: 17px;
    overflow: hidden;
    white-space: nowrap;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    text-overflow: ellipsis;
  }
`;
export const ListPfxButton = styled.div`
  cursor: pointer;
  flex: 10%;
  svg {
    padding-top: 7px;
  }
`;

export const ContainerTooltipCenter = styled.div`
  position: relative;
  align-items: center;
`;

export const ButtonUpload = styled.button`
  padding: 1px 8px;
  border-radius: 10px;
  border: 1px solid #596778;
  background-color: transparent;
`;
export const DaysCertified = styled.div`
  opacity: 1;
  width: 130px;
  height: 130px;
  margin: 0 auto;
  text-align: center;
  border-radius: 100%;
  background-color: ${({ active }) => (active ? "#adc86a" : "#c86a6a")};
  vertical-align: middle;
  line-height: 23px;
  padding-top: 40px;
  margin-bottom: 10px;
  box-shadow: 0px 3px 6px #00000029;
  h5 {
    font-size: 35px;
    font-weight: bold;
    color: ${colors.white};
  }
  h6 {
    font-size: 20px;
    font-weight: bold;
    color: ${colors.white};
  }
`;

export const NotificationContainer = styled.div`
  display: block !important;
  margin-right: 17px;
  top: 0px;
  right: 0;
  width: 300px;
  border: 1px solid rgb(190 190 190 / 50%);
  position: fixed;
  margin-top: 96px;
  min-height: 100px;
  z-index: 99999999998;
  background-color: #fff;
  box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(167 167 167 / 23%);
`;

export const NotificationHeader = styled.div`
  display: flex;
  padding: 10px 0px 5px 0px;
  border-bottom: 1px solid rgb(190 190 190 / 50%);
`;

export const NotifyHeaderTitle = styled.div`
  width: 90%;
  padding-left: 10px;
  display: flex;
  h4 {
    font-weight: bold;
    color: ${colors.slateGray};
  }
`;
export const NotifyHeaderIcon = styled.div`
  margin-right: 4px;
`;
export const NotifyHeaderFunction = styled.div`
  width: 10%;
  margin-right: 10px;

  border-radius: 50px;
  transition: all 250ms;
  &:hover {
    background: rgb(227 227 227);
  }
  text-align: center;
  svg {
    margin-top: 5px;
  }
`;

export const NotificationContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const NotificationContentItem = styled.div`
  display: flex;
  width: 100%;
  cursor: pointer;
`;
export const NotificationContentItemOpen = styled.div`
  display: block !important;
  width: 100%;
  padding: 0px 10px 10px 10px;
`;

export const NotifyHeaderContentItemHeader = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px 0px 0px 0px;
  h4 {
    width: 95%;
  }
`;
export const NotifyHeaderContentItemHeaderIcon = styled.div`
  width: 5%;
`;
export const NotificationContentItemDescription = styled.div`
  width: 90%;
  padding: 10px 0px 10px 5px;
  line-height: 17px;
  transition: all 150ms;
  &:hover {
    background: rgb(227 227 227);
  }
  h3 {
    overflow: hidden;
    white-space: nowrap;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    text-overflow: ellipsis;
    font-size: 13px;
    font-weight: bold;
    color: ${colors.slateGray};
  }
  p {
    font-size: 10px;
    overflow: hidden;
    white-space: nowrap;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    text-overflow: ellipsis;
  }
`;
export const NotificationContentItemForm = styled.div`
  width: 90%;
  line-height: 17px;
  padding: 10px;
  label {
    font-size: 13px;
    font-weight: bold;
    color: ${colors.slateGray};
  }
`;
export const NotificationContentItemDescriptionFull = styled.div`
  width: 90%;
  line-height: 17px;
  h3 {
    font-size: 13px;
    font-weight: bold;
    color: ${colors.slateGray};
  }
  p {
    font-size: 10px;
  }
`;

export const NotificationContentItemAction = styled.div`
  width: 10%;
  text-align: center;
  transition: all 150ms;
  &:hover {
    background: rgb(227 227 227);
  }
  svg {
    margin-top: 20px;
  }
  padding: 10px 0px 10px 0px;
`;

export const Badge = styled.span`
  position: absolute;
  top: 0;
  height: 20px;
  margin-top: -10px;
  min-width: 20px;
  text-align: center;
  padding: 0px 2px 0px -1px;
  color: white;
  font-size: 11px;
  border-radius: 100px;
  text-align: center;
  margin-left: -10px;
  background: ${({ active }) =>
    active ? `rgb(235 45 45 / 88%)` : `rgb(117 193 117)`};
`;

export const SpanDate = styled.span`
  color: ${colors.slateGray};
  font-size: 10px;
  border-radius: 100px;
  padding-left: 0;
  padding-right: 0;
  font-weight: bold;
  svg {
    margin-right: 5px;
  }
`;

export const NotificationContentItemFooter = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px 0px 0px 0px;
`;

export const NotificationContentItemFooterAction = styled.div`
  width: 100%;
  text-align: ${({ align }) => align};
  align-items: right;
`;

export const SendButtonNotify = styled.a`
  font-size: 14px;
  color: #fff;
  width: 120px;
  border-radius: 100px;
  padding: 0px 10px 0px 10px;
  background: ${({ active }) =>
    !active ? `rgb(235 45 45 / 68%)` : `rgb(88 157 88)`};
  svg {
    margin-top: 5px;
    margin-left: 8px;
  }
`;

export const NotificationContentItemOpenAlert = styled.div`
  width: 100%;
  margin-top: 10px;
  display: block !important;
  padding: 0px 10px 10px 10px;
  text-align: center;
`;

export const NotificationContentItemDescriptionFullAlert = styled.div`
  width: 90%;
  line-height: 17px;
  h4 {
    font-size: 14px;
    font-weight: bold;
    color: ${colors.slateGray};
  }
  p {
    opacity: 0.6;
    font-size: 13px;
  }
`;
