import React from "react";
import { Tag, Tooltip } from "@mix/mixfiscal-designsystem";
import { useNcmNivel } from "useCases/ncm";
import { Format } from "utils/date";

import * as S from "./NcmTag.styles";

const LawNcmTagTooltipContainer = ({ data = {} }) => (
  <>
    <S.Title className="title">{`NCM - ${data?.codigo}`}</S.Title>
    <S.ContainerTooltip>
      <S.Field>
        <S.FieldLabel>NCM Atual:</S.FieldLabel>
        <S.FieldValue>{data?.codigo}</S.FieldValue>
      </S.Field>
      <S.Field>
        <S.FieldLabel>NCM Antigo:</S.FieldLabel>
        <S.FieldValue>
          {data?.parent_id?.codigo ?? "Sem NCM antigo"}
        </S.FieldValue>
      </S.Field>
      <S.Field>
        <S.FieldLabel>Data Inicio da vigência:</S.FieldLabel>
        <S.FieldValue>
          {data?.parent_id?.data_inicio
            ? Format(data?.parent_id?.data_inicio)
            : "Sem data de inicio"}
        </S.FieldValue>
      </S.Field>
      <S.Field>
        <S.FieldLabel>Data Final da vigência:</S.FieldLabel>
        <S.FieldValue>
          {data?.parent_id?.data_fim
            ? Format(data?.parent_id?.data_fim)
            : "Indeterminada"}
        </S.FieldValue>
      </S.Field>
      <S.Field>
        <S.FieldLabel>Observação:</S.FieldLabel>
        <S.FieldValue>Sem Observação</S.FieldValue>
      </S.Field>
    </S.ContainerTooltip>
  </>
);

const LawNcmTagTooltipContainerVencido = ({ data = {} }) => (
  <>
    <S.Title className="title">{`NCM - ${data?.codigo}`}</S.Title>
    <S.ContainerTooltip>
      <S.Field>
        <S.FieldLabel>NCM novo:</S.FieldLabel>
        <S.FieldValue>
          {`${data?.suggestion.codigo} (vencimento ${
            data?.suggestion?.data_fim
              ? Format(data?.suggestion?.data_fim)
              : "indeterminado"
          })`}
        </S.FieldValue>
      </S.Field>
      <S.Field>
        <S.FieldLabel>NCM atual:</S.FieldLabel>
        <S.FieldValue>{data?.codigo}</S.FieldValue>
      </S.Field>
      <S.Field>
        <S.FieldLabel>Data Inicio da vigência:</S.FieldLabel>
        <S.FieldValue>
          {data?.data_inicio ? Format(data?.data_fim) : "Sem data de inicio"}
        </S.FieldValue>
      </S.Field>
      <S.Field>
        <S.FieldLabel>Data Final da vigência:</S.FieldLabel>
        <S.FieldValue>
          {data?.data_fim ? Format(data?.data_fim) : "Indeterminada"}
        </S.FieldValue>
      </S.Field>
      <S.Field>
        <S.FieldLabel>Observação:</S.FieldLabel>
        <S.FieldValue>Sem Observação</S.FieldValue>
      </S.Field>
    </S.ContainerTooltip>
  </>
);

export const LawNcmTag = ({ ncm, deleteNcm = () => {} }) => {
  const { data } = useNcmNivel("item", {
    removeStatus: 1,
    ncm: ncm?.data.vc_codigo_sem_formatacao,
  });

  const isVencido = data[0]?.status === "vencido";

  return (
    <Tooltip
      isPopOver
      title="Ncm"
      position="top"
      zIndex={1000}
      component={
        isVencido ? (
          <LawNcmTagTooltipContainerVencido ncm={ncm} data={data[0] ?? {}} />
        ) : (
          <LawNcmTagTooltipContainer ncm={ncm} data={data[0] ?? {}} />
        )
      }
    >
      <S.TagWrapper isVencida={isVencido}>
        <Tag
          type="advanced"
          label={data[0]?.codigo ?? ncm.data.vc_codigo_sem_formatacao}
          handleDelete={() => deleteNcm(ncm)}
        />
      </S.TagWrapper>
    </Tooltip>
  );
};

export const NcmTags = ({ ncms, deleteNcm = () => {} }) => (
  <S.TagContainer>
    {(ncms ?? []).map(ncm => (
      <div
        className="tag-item"
        key={`tag-item-${ncm.data.vc_codigo_sem_formatacao}`}
      >
        <LawNcmTag ncm={ncm} deleteNcm={deleteNcm} />
      </div>
    ))}
  </S.TagContainer>
);
