import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
`;

export const InputContainer = styled.div`
  flex: 1;
  padding: 0px 10px;
`;

export const AddPlus = styled.div`
  display: flex;
  align-items: flex-end;
`;
