import React, { useState, memo, useEffect } from "react";

function Select({
  connectedNodes,
  operations,
  index,
  parentRef,
  handleDelete,
  dataNodes,
}) {
  const [inputValue, setInputValue] = React.useState(
    parentRef?.current?.[index]?.inputValue ?? "",
  );
  const [selectedNodeIndex, setSelectedNodeIndex] = React.useState(
    parentRef?.current?.[index]?.selectedNodeIndex ?? 0,
  );
  const [selectedColumnIndex, setSelectedColumnIndex] = React.useState(
    parentRef?.current?.[index]?.selectedColumnIndex ?? 0,
  );
  const [selectedValueColumnIndex, setSelectedValueColumnIndex] =
    React.useState(parentRef?.current?.[index]?.selectedValueColumnIndex ?? 0);
  const [selectedOperation, setSelectedOperation] = React.useState(
    parentRef?.current?.[index + 1]?.selectedOperation ?? "end",
  );

  const [filterBy, setFilterBy] = React.useState(
    parentRef?.current?.[index]?.filterBy ?? "partial",
  );

  const [filterByValue, setFilterByValue] = React.useState(
    parentRef?.current?.[index]?.filterByValue ?? "",
  );

  const [selectedNodeToCompare, setSelectedNodeToCompare] = React.useState(
    parentRef?.current?.[index]?.selectedNodeToCompare ?? 0,
  );
  const [selectedColumnToCompare, setSelectedColumnToCompare] = React.useState(
    parentRef?.current?.[index]?.selectedColumnToCompare ?? 0,
  );

  const [cherryPickColumnValues, setCherryPickColumnValues] = React.useState(
    parentRef?.current?.[index]?.cherryPickColumnValues ?? [],
  );

  const [isUsingCompareColumnAsValue, setIsUsingCompareColumnAsValue] =
    useState(parentRef?.current?.[index]?.isUsingCompareColumnAsValue ?? false);

  const [selectedColumnToCompareAsValue, setSelectedColumnToCompareAsValue] =
    React.useState(
      parentRef?.current?.[index]?.selectedColumnToCompareAsValue ?? 0,
    );

  useEffect(() => {
    const value = {
      inputValue,
      selectedNodeIndex,
      selectedColumnIndex,
      selectedValueColumnIndex,
      filterBy,
      filterByValue,
      selectedNodeToCompare,
      selectedColumnToCompare,
      isUsingCompareColumnAsValue,
      selectedColumnToCompareAsValue,
      cherryPickColumnValues,
    };
    if (parentRef?.current) {
      if (parentRef.current[index]) {
        parentRef.current[index] = { ...parentRef.current[index], ...value };
      } else {
        parentRef.current[index] = value;
      }
      if (parentRef.current[index + 1]) {
        parentRef.current[index + 1] = {
          ...parentRef.current[index + 1],
          selectedOperation,
        };
      } else {
        parentRef.current[index + 1] = { selectedOperation };
      }
    }
  }, [
    inputValue,
    selectedNodeIndex,
    selectedColumnIndex,
    selectedValueColumnIndex,
    selectedOperation,
    selectedNodeToCompare,
    selectedColumnToCompare,
    isUsingCompareColumnAsValue,
    selectedColumnToCompareAsValue,
    filterBy,
    filterByValue,
    index,
    parentRef,
    cherryPickColumnValues,
  ]);

  const handleCherryPickSelection = value => {
    const tempSelections = new Set(cherryPickColumnValues);
    if (tempSelections.has(value)) {
      tempSelections.delete(value);
    } else {
      tempSelections.add(value);
    }
    setCherryPickColumnValues([...tempSelections]);
  };

  return (
    <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <label>Bloco</label>

          <button type="button" onClick={handleDelete}>
            Excluir
          </button>
        </div>
        <select
          style={{ maxWidth: "200px" }}
          value={selectedNodeIndex}
          onChange={e => {
            setSelectedNodeIndex(e.target.value);
            setSelectedColumnIndex(0);
          }}
        >
          {connectedNodes?.length > 0 &&
            connectedNodes.map((connectedNode, cnNodeIndex) => {
              return (
                <option key={connectedNode?.id} value={cnNodeIndex}>
                  {connectedNode?.type === "inputValue"
                    ? connectedNode?.data?.value
                    : connectedNode?.data?.field}
                </option>
              );
            })}
        </select>
        <label>Coluna Seletora</label>
        <select
          style={{ maxWidth: "200px" }}
          value={selectedColumnIndex}
          onChange={e => setSelectedColumnIndex(e.target.value)}
        >
          {connectedNodes?.length > 0 &&
            connectedNodes[selectedNodeIndex]?.data?.element?.data?.fields?.map(
              (field, fieldIndex) => {
                return (
                  <option key={field?._id} value={fieldIndex}>
                    {field?.description}
                  </option>
                );
              },
            )}
        </select>
        <label>Coluna Valor</label>
        <select
          style={{ maxWidth: "200px" }}
          value={selectedValueColumnIndex}
          onChange={e => setSelectedValueColumnIndex(e.target.value)}
        >
          {connectedNodes?.length > 0 &&
            connectedNodes[selectedNodeIndex]?.data?.element?.data?.fields?.map(
              (field, fieldIndex) => {
                return (
                  <option key={field?._id} value={fieldIndex}>
                    {field?.description}
                  </option>
                );
              },
            )}
        </select>
        <label>Filtrar por:</label>
        <select
          value={filterBy}
          onChange={e => setFilterBy(e.target.value)}
          style={{ maxWidth: "200px" }}
        >
          <option value="partial">parcial</option>
          {/* <option value="total">total</option> */}
          <option value="value">valor</option>
          <option value="cherryPick">escolher</option>
          <option value="noFilter">sem filtro</option>
        </select>
        {filterBy === "cherryPick" && (
          <>
            <label>Escolha os campos que quer filtrar</label>

            {dataNodes[Object.keys(dataNodes)[selectedNodeIndex]]
              ?.reduce((acc, cur) => {
                const newAcc = new Set(acc);
                if (newAcc.has(cur[selectedColumnIndex])) {
                  return acc;
                }
                newAcc.add(cur[selectedColumnIndex]);
                return [...newAcc];
              }, [])
              .map(uniqueValue => {
                return (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                    }}
                  >
                    <input
                      key={uniqueValue}
                      type="checkbox"
                      value={uniqueValue}
                      checked={cherryPickColumnValues.includes(uniqueValue)}
                      onChange={e => handleCherryPickSelection(e.target.value)}
                    />
                    <label>{uniqueValue}</label>
                  </div>
                );
              })}
          </>
        )}
        {filterBy === "value" ? (
          <>
            <label>Bloco de Comparação</label>
            <select
              style={{ maxWidth: "200px" }}
              value={selectedNodeToCompare}
              onChange={e => {
                setSelectedNodeToCompare(e.target.value);
                setSelectedColumnToCompare(0);
              }}
            >
              {connectedNodes?.length > 0 &&
                connectedNodes.map((connectedNode, cnNodeIndex) => {
                  return (
                    <option key={connectedNode?.id} value={cnNodeIndex}>
                      {connectedNode?.type === "inputValue"
                        ? connectedNode?.data?.value
                        : connectedNode?.data?.field}
                    </option>
                  );
                })}
            </select>
            <label>Coluna para Comparar</label>
            <select
              style={{ maxWidth: "200px" }}
              value={selectedColumnToCompare}
              onChange={e => setSelectedColumnToCompare(e.target.value)}
            >
              {connectedNodes?.length > 0 &&
                connectedNodes[
                  selectedNodeToCompare
                ]?.data?.element?.data?.fields?.map((field, fieldIndex) => {
                  return (
                    <option key={field?._id} value={fieldIndex}>
                      {field?.description}
                    </option>
                  );
                })}
            </select>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              <input
                type="checkbox"
                checked={isUsingCompareColumnAsValue}
                onChange={e => setIsUsingCompareColumnAsValue(e.target.checked)}
              />
              <p>Usar bloco de comparação como Valor</p>
            </div>
            {isUsingCompareColumnAsValue && (
              <>
                <label>Coluna para Valor</label>
                <select
                  style={{ maxWidth: "200px" }}
                  value={selectedColumnToCompareAsValue}
                  onChange={e =>
                    setSelectedColumnToCompareAsValue(e.target.value)
                  }
                >
                  {connectedNodes?.length > 0 &&
                    connectedNodes[
                      selectedNodeToCompare
                    ]?.data?.element?.data?.fields?.map((field, fieldIndex) => {
                      return (
                        <option key={field?._id} value={fieldIndex}>
                          {field?.description}
                        </option>
                      );
                    })}
                </select>
              </>
            )}
          </>
        ) : (
          <input
            value={filterByValue}
            onChange={e => setFilterByValue(e.target.value)}
            style={{ borderBottom: "1px solid #ccc" }}
          />
        )}
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <select
          style={{ maxWidth: "200px" }}
          value={selectedOperation}
          onChange={e => setSelectedOperation(e.target.value)}
        >
          {operations?.map(operation => (
            <option key={operation} value={operation}>
              {operation}
            </option>
          ))}
        </select>
        {selectedOperation === "input" && (
          <input
            style={{ borderBottom: "1px solid #ccc" }}
            value={inputValue}
            onChange={e => setInputValue(e.target.value)}
          />
        )}
      </div>
    </div>
  );
}

export default memo(Select);
