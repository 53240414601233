import React from "react";
import Select from "react-select";
import { TextField } from "components/form";
import { useAccessGroups } from "useCases";

import * as S from "../../styles";

export const GroupForm = ({
  errors,
  control,
  setGroupModules,
  setNewSetOfModules,
  selectedCompany,
}) => {
  const { isLoading: isLoadingGroups, data: dataGroups } = useAccessGroups({
    page: 1,
    limit: 1000,
    companyId: selectedCompany
      ? selectedCompany.length > 0
        ? selectedCompany[0]._id
        : ""
      : "",
  });

  const copyPermissionsFrom = groupIdToCopyFrom => {
    const selectedGroup = dataGroups?.accessGroups?.docs?.find(
      group => group._id === groupIdToCopyFrom,
    );

    setGroupModules(selectedGroup.modules);
    setNewSetOfModules(selectedGroup.modules);
  };
  return (
    <>
      <S.ColumnFormItem>
        <S.InputForm>
          <TextField
            required
            label="Nome do grupo de acesso"
            placeholder="Nome de grupo de acesso"
            name="name"
            maxLength="14"
            control={control}
          />
          {errors.name && errors.name.type === "required" && (
            <span>O campo nome e obrigatorio</span>
          )}
        </S.InputForm>
        <S.InputForm>
          <S.Label>Copiar permissões de</S.Label>
          <Select
            control={control}
            noOptionsMessage={() => "Nenhuma loja encontrada"}
            isLoading={isLoadingGroups}
            name="accessGroups"
            options={
              dataGroups?.accessGroups?.docs &&
              dataGroups?.accessGroups?.docs.map(item => ({
                value: item._id,
                label: item.groupName,
              }))
            }
            placeholder="Selecione"
            onChange={e => {
              copyPermissionsFrom(e.value);
              // setTableStoreValue(e);
            }}
          />
        </S.InputForm>
      </S.ColumnFormItem>
    </>
  );
};
