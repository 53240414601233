import React, { useEffect, useState } from "react";
import { Card, Button as Bt } from "@mix/mixfiscal-designsystem";
import { Breadcrumb, LoadPlaceholder } from "components/structure";
import { useRegisterLawsGradesContext } from "context/RegisterLawsContext";

// TODO: Migrar para o structure
import Dialog from "components/UI/Dialog";
import { useRegisterLaws } from "hooks/useRegisterLaws";

import authenticationApi from "services/authenticationApi";
import { History } from "components/contexts/grid-manager/History";
import {
  LawReplicate,
  LawRegimeSpecial,
  LawAttributes,
  LawNorm as Norms,
  LawNcm as Ncm,
  LawNcmEx as NcmEx,
  LawCest as Cest,
  LawProducts,
  LawScenery,
} from "components/contexts/law-grade";

import Tax from "./Tax";
import Supplier from "./Supplier";
import Cnae from "./Cnae";
import CfopEntrada from "./CfopEntrada";
import CfopSaida from "./CfopSaida";

import { useWatch } from "./Laws.hooks";
import * as S from "./styles";
import Mocks from "./mocks";

const lawsCeneRepository = new Mocks();

const norms = lawsCeneRepository.getNorms();
const getEstados = (list = []) =>
  list.map(item => item.ufOrigem || item.ufSaida);

const getTitle = id => ({
  titleBreadcrumb: id ? "Detalhe Lei" : "Cadastrar Nova Lei",
  titleCard: id ? "DETALHE LEI" : "CADASTRO DE LEIS",
});

const LawsContent = ({
  onSave,
  hasBreadcrum = true,
  isLoading = false,
  onChangeProduct = () => {},
}) => {
  const [dialogConfirm, setDialogConfirm] = useState(false);
  const [cnaes, setCnaes] = useState([]);
  const { values, setSimpleValue } = useRegisterLaws();

  useWatch();

  const {
    values: { productsMoveSelecteds, productsRemoved, productsChangeds },
  } = useRegisterLawsGradesContext();

  const states = getEstados(values?.cenarios ?? []);

  const getCnaes = async () => {
    const token = localStorage.getItem("@mixfiscal:authenticatorToken");
    try {
      const { data } = await authenticationApi.get("/laws/cnae", {
        headers: {
          Authorization: token,
        },
      });
      setCnaes(
        data.cnaes.map(cnae => {
          return {
            value: cnae._id,
            codigo: cnae.cnae,
            label: `${cnae.cnae} - ${cnae.activity}`,
          };
        }),
      );
    } catch (e) {
      console.log(e);
      // chamar toast
    }
  };

  useEffect(() => {
    getCnaes();
  }, []);

  const disableMVA = (value = values?.cstEntrada) =>
    !["00", "20"].includes(value);

  const handleDialogConfirm = () => setDialogConfirm(!dialogConfirm);

  const handleSubmit = () => {
    const fields = {
      taxa: values.taxa,
      regime_name: values.regime_name,
      cenarios: values.cenarios,
      normas: values.normas,
      ncms: values.ncms?.map(ncm => {
        return {
          codigo: ncm.data.vc_codigo_sem_formatacao,
          linkmix_id: ncm.value,
        };
      }),
      excecoesNcm: values.ncmEx,
      fornecedores: values.fornecedores,
      cnaes: values.cnaes,
      cests: values.cests?.map(cest => {
        return { codigo: cest.data.vc_codigo, linkmix_id: cest.value };
      }),
      cfopsEntrada: values.cfopsEntrada,
      cfopsSaida: values.cfopsSaida,
      atributos: {
        fecp: values.fecp,
        reducaoComFecp: values.reducaoComFecp,
        cBenef: values.cBenef,
        aliqcBenef: values.aliqcBenef,
        naturezaReceita: values.naturezaReceita,
        cstEntrada: values.cstEntrada,
        cstSaida: values.cstSaida,
        aliqCstEntrada: values.aliqEntrada,
        aliqCstSaida: values.aliqSaida,
        reducaoBaseCalc: values.reducaoBaseCalc,
        tipoMva: values.tipoMva,
        valorMva: values.valorMva,
        tipoItem: values.tipoItem,
        aliqEntradaCofins: values.aliqEntradaCofins,
        aliqSaidaCofins: values.aliqSaidaCofins,
      },
      grade: values?.gradeIcms || values?.gradePisCofins,
      productsChanges: productsChangeds,
      productsRemoveds: productsRemoved,
      productsMoveds: productsMoveSelecteds,
      id_laws: values?.id_laws,
    };

    onSave(fields);
  };

  const handleConfirmSubmit = () => {
    handleDialogConfirm();
    handleSubmit();
  };

  const { titleBreadcrumb, titleCard } = getTitle(values?._id);
  const hasTax = values.taxa?.pis || values.taxa?.cofins || values.taxa?.icms;

  return (
    <S.Container>
      <Dialog
        isOpen={dialogConfirm}
        title="Alerta"
        handleConfirm={handleConfirmSubmit}
        zIndex="100002"
        handleCancel={handleDialogConfirm}
        handleClose={handleDialogConfirm}
      >
        <h3 style={{ textAlign: "center", marginTop: 10, padding: "0px 10px" }}>
          Essa ação vai atualizar os produtos da GRADE (XXX).
        </h3>
        <h3 style={{ marginTop: 10, textAlign: "center" }}>
          Deseja continuar ?
        </h3>
      </Dialog>
      {hasBreadcrum && (
        <header>
          <Breadcrumb
            items={[
              { url: "/grid-manager", label: "Classificação Fiscal" },
              { url: ``, label: titleBreadcrumb },
            ]}
          />
        </header>
      )}
      <S.Content>
        <Card
          title={titleCard}
          bold={false}
          titleIcon="certificado"
          hasElevation
        >
          <Tax
            gradeId={values?.gradeIcms?._id ?? values?.gradePisCofins?._id}
          />
          {hasTax && (
            <Card
              headerColor="#E5E5E5"
              textColor="#304156"
              title="CENÁRIO E NORMAS"
              className="flatCard"
            >
              <LawScenery scenery={values?.scenery} />
              <br />
              <Norms normsValue={norms} cnaes={cnaes} />
            </Card>
          )}
          <Card
            headerColor="#E5E5E5"
            textColor="#304156"
            title="ESPECIFICAÇÕES"
            className="flatCard"
          >
            {values?.id_laws && (
              <S.ContainerRow>
                <LawRegimeSpecial
                  regimeName={values?.regime_name}
                  setValue={setSimpleValue}
                />
              </S.ContainerRow>
            )}

            <Ncm />
            <div className="flex">
              <NcmEx />
              <Supplier />
              <Cnae cnaes={cnaes} />
            </div>
            <Cest />
            <div className="flex">
              <CfopEntrada />
              <CfopSaida />
            </div>
            <LawAttributes />
          </Card>

          {values?.id_laws && (
            <S.ContainerRow>
              <LawReplicate
                lawsIds={values?.id_laws}
                ncms={values?.ncms}
                cenarios={values?.cenarios}
                cests={values?.cests}
                setValue={setSimpleValue}
              />
            </S.ContainerRow>
          )}

          <S.ContainerRow>
            <History grade={values?.gradeIcms ?? values?.gradePisCofins} />
          </S.ContainerRow>

          <S.FooterActions>
            <div>
              <LoadPlaceholder show={isLoading}>
                <Bt
                  label={isLoading ? "Salvando..." : "Salvar"}
                  handleClick={
                    values?.id_law ? handleDialogConfirm : handleSubmit
                  }
                />
              </LoadPlaceholder>
            </div>
          </S.FooterActions>
          {!values?.gradeIcms?._id && values?.gradePisCofins?._id && hasTax && (
            <LawProducts
              gradeId={null}
              states={states}
              lawValues={values}
              onChangeProduct={onChangeProduct}
              hasMVA={disableMVA()}
            />
          )}
        </Card>
      </S.Content>
    </S.Container>
  );
};

export default LawsContent;
