import React, { useState, useRef, useCallback } from "react";

import * as DS from "@mix/mixfiscal-designsystem";
import { usePermissions } from "context/PermissionsContext";
import {
  SelectSearchPermission,
  CustomTooltipMultiples,
} from "components/contexts/stores";
import * as CS from "../../../../structure";
import * as S from "./styles";
import { AdvancedSearchSelect } from "../select";
// import { Checkbox } from "../checkbox";
import { useOnClickOutside } from "../../../../../hooks";
import {
  regions,
  states,
  cities,
  taxRegimes,
  status,
  storeSizes,
  pdvQty,
  revenues,
  purchases,
} from "./mocks";

const agg = values => {
  return Object.values(
    values.reduce((previous, current) => {
      if (!previous[current?.company?._id]) {
        previous[current?.company?._id] = {
          company: current?.company,
          stores: [],
        };
      }

      previous[current?.company?._id].stores.push(current);
      return previous;
    }, {}),
  );
};

export const CardAdvancedSearch = ({ close }) => {
  const ref = useRef();

  const [disabledSearchItems, setDisabledSearchItems] = useState(false);

  const {
    stores,
    setStores,
    listItemsMatriz,
    listItemsSelected,
    selectedCompanySearch,
    handleSelectCompaniesSearch,
  } = usePermissions();

  const handleSelected = () => {
    if (listItemsSelected && listItemsSelected?.length > 0) {
      const selected = agg(listItemsSelected).map(item => {
        return {
          cnpj: item.company.cnpj,
          company_name: item.company.company_name,
          createdAt: item.company.createdAt,
          updatedAt: item.company.updatedAt,
          label: item.company.company_name,
          _id: item.company._id,
          companyId: !!item.company._id,
          stores: item.stores,
        };
      });

      handleSelectCompaniesSearch(selected);
      close();
    }
  };

  useOnClickOutside(ref, useCallback(close, [close]));

  const handleCancelSearch = () => {};

  return (
    <CS.CardSuspended ref={ref} close={close}>
      <S.Header>
        <S.Title>BUSCA AVANÇADA</S.Title>
      </S.Header>
      <S.ContentRow>
        <S.SectionWrapperCompanies>
          <SelectSearchPermission
            listItemsSelected={listItemsSelected}
            label="Matriz"
            stores={stores}
            setStores={setStores}
            setDisabledSearchItems={setDisabledSearchItems}
            selectedCompanySearch={selectedCompanySearch}
            handleSelectCompaniesSearch={handleSelectCompaniesSearch}
          />
        </S.SectionWrapperCompanies>
      </S.ContentRow>
      <S.ContentRow>
        <S.SectionWrapper active={disabledSearchItems}>
          <AdvancedSearchSelect label="Região" data={regions} />
          <AdvancedSearchSelect label="UF" data={states} />
          <AdvancedSearchSelect label="Cidade" data={cities} />
        </S.SectionWrapper>
        <S.SectionDivider />
        <S.SectionWrapper active={disabledSearchItems}>
          <AdvancedSearchSelect label="Tamanho da Loja" data={storeSizes} />
          <AdvancedSearchSelect label="PDV's" data={pdvQty} />
        </S.SectionWrapper>
      </S.ContentRow>
      <S.ContentRow>
        <S.SectionWrapper active={disabledSearchItems}>
          <AdvancedSearchSelect label="Faturamento" data={revenues} />
          <AdvancedSearchSelect label="Compra" data={purchases} />
        </S.SectionWrapper>
        <S.SectionDivider />
        <S.SectionWrapper active={disabledSearchItems}>
          <AdvancedSearchSelect label="Regime Tributário" data={taxRegimes} />
        </S.SectionWrapper>
        <S.SectionDivider />
        <S.SectionWrapper active={disabledSearchItems}>
          <AdvancedSearchSelect label="Status" data={status} />
        </S.SectionWrapper>
        <S.SectionWrapper active={disabledSearchItems}>
          <S.ActionWrapper>
            <DS.Button label="Buscar" />
          </S.ActionWrapper>
        </S.SectionWrapper>
      </S.ContentRow>
      <S.HorizontalDivider />
      <S.ContentRow>
        {listItemsMatriz || listItemsSelected ? (
          <S.Title>
            {listItemsMatriz.length > 0 && (
              <S.TitleText margin="0 15px 0 0">{`${listItemsMatriz?.length} MATRIZES ENCONTRADAS`}</S.TitleText>
            )}
            {listItemsSelected && listItemsSelected.length > 0 ? (
              <DS.Tooltip
                isPopOver
                component={
                  <CustomTooltipMultiples selected={listItemsSelected} />
                }
                position="bottom-start"
                title="Selecionados"
                zIndex={9999999}
              >
                <S.TitleText>{`${listItemsSelected?.length} MATRIZES SELECIONADAS`}</S.TitleText>
              </DS.Tooltip>
            ) : (
              ""
            )}
          </S.Title>
        ) : (
          ""
        )}

        {/* <S.CheckboxContainer>
          <Checkbox
            name="select-all"
            label="Selecionar tudo"
            checked={storeSelection === "all"}
            onClick={() => setStoreSelection("all")}
          />
          <Checkbox
            name="only-company"
            label="Somente matrizes"
            checked={storeSelection === "company"}
            onClick={() => setStoreSelection("company")}
          />
          <Checkbox
            name="only-stores"
            label="Somente filiais"
            checked={storeSelection === "store"}
            onClick={() => setStoreSelection("store")}
          />
        </S.CheckboxContainer> */}
      </S.ContentRow>
      <S.ActionRow marginButton="0 10px 0 0">
        {(listItemsMatriz && listItemsMatriz.length > 0) ||
        (listItemsSelected && listItemsSelected.length > 0) ? (
          <DS.Button
            color="red"
            handleClick={() => {
              close();
              handleCancelSearch();
            }}
            label="cancelar"
          />
        ) : (
          ""
        )}
        <DS.Button
          handleClick={() => {
            handleSelected();
          }}
          label="Salvar"
        />
      </S.ActionRow>
    </CS.CardSuspended>
  );
};
