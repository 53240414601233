import React, { useEffect, useState, useMemo } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Tabs } from "@mix/mixfiscal-designsystem";
import {
  RegisterLawsContextProvider,
  RegisterLawsGradesContextProvider,
  useRegisterLawsGradesContext,
} from "context/RegisterLawsContext";
import { useApp } from "context/AppContext";
import { useLaw, useLawsGradeArchive, useLawsGradeRemove } from "useCases/laws";
import { useRegisterLaws } from "hooks/useRegisterLaws";
import { useGradePiscofinsId, useGradeIcmsById } from "useCases/grid";
import { LawGradeActions, LawProducts } from "components/contexts/law-grade";

import {
  hasPisCofinsTax,
  attributesBuilderToScreen,
  getAlqLabel,
} from "utils/context";

import { useLoadGradeData, useLoadAliqs } from "./useCases";

import * as S from "./LawsGrade.styles";

import { LawsDescontinued } from "../LawsDescontinued";
import LawsGrade from "../LawGradeSupport";

const LawsWrapperGrade = ({
  data = {},
  onSave,
  isLoading = false,
  alq = 0,
}) => {
  useLoadGradeData({ data, alq, isLoading });

  const { values: valuesLawsGrades, changeProducts } =
    useRegisterLawsGradesContext();

  return (
    <LawsGrade
      hasBreadcrum={false}
      onSave={onSave}
      productsChanges={valuesLawsGrades.productsChangeds}
      productsRemoveds={valuesLawsGrades.productsRemoved}
      onChangeProduct={changeProducts}
      isLoading={isLoading}
    />
  );
};

const LawsWrapper = ({ data = {}, ...props }) => {
  const { setMergeValues } = useRegisterLaws();
  useEffect(() => {
    if (data?.law) {
      const { ncms, cests, ...law } = data.law;

      setMergeValues({
        ...law,
        ...attributesBuilderToScreen(data?.law?.atributos ?? {}),
      });
    }
  }, [setMergeValues, data?.law]);

  return <LawsWrapperGrade data={data} {...props} />;
};

const GradeLaws = ({ grade, isLoadingGrade = false, isPisCofins = false }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const { data: dataLaw, onSave, isLoading } = useLaw(grade?.id_law);
  const { onSend } = useLawsGradeArchive(grade?._id);
  const { onSend: onSendRemove } = useLawsGradeRemove(grade?._id);

  const { icmsAliqs, pisCofinsAliqs } = useLoadAliqs({ grade });

  const alqsValues = useMemo(() => {
    const alqs = isPisCofins ? pisCofinsAliqs : icmsAliqs;

    return [
      ...(alqs ?? []).map(alq => ({
        alq,
        label: `Aliq (${getAlqLabel({
          entrada: alq.entrada,
          saida: alq.saida,
        })})`,
      })),
      ...[{ alq: 0, label: "Nova Grade" }],
    ];
  }, [pisCofinsAliqs, icmsAliqs, isPisCofins]);

  useEffect(() => {
    const aliqs = isPisCofins ? pisCofinsAliqs : icmsAliqs;
    const values = aliqs.map(alq => ({
      alq,
      label: `Aliq (${getAlqLabel({
        entrada: alq.entrada,
        saida: alq.saida,
      })})`,
    }));

    if (aliqs) {
      setTabIndex(values[0]?.label);
    }
  }, [pisCofinsAliqs, icmsAliqs, isPisCofins]);

  if (grade?.id_law) {
    return <LawsDescontinued data={dataLaw} idLaw={grade?.id_law} />;
  }

  return (
    <>
      <S.Container>
        <LawGradeActions
          grade={grade}
          onSend={onSend}
          onSendRemove={onSendRemove}
        />
        <div>
          <Tabs
            items={alqsValues.map(alq => alq.label) || []}
            type="slim"
            handleClickCallback={item => setTabIndex(item)}
            initialValue={tabIndex}
          />
        </div>
      </S.Container>

      <RegisterLawsGradesContextProvider>
        <>
          {!isLoading && (
            <>
              {alqsValues.map((alq, index) => {
                return (
                  alq.label === tabIndex && (
                    <RegisterLawsContextProvider>
                      <LawsWrapper
                        data={{
                          [isPisCofins ? "gradePisCofins" : "gradeIcms"]: grade,
                          law: dataLaw?.law,
                          index,
                        }}
                        alq={alq.alq.entrada || alq.alq.saida}
                        onSave={onSave}
                        isLoading={isLoading || isLoadingGrade}
                      />
                    </RegisterLawsContextProvider>
                  )
                );
              })}
              <div style={{ padding: 10 }}>
                <LawProducts gradeId={grade?._id} isPisCofins={isPisCofins} />
              </div>
            </>
          )}
        </>
      </RegisterLawsGradesContextProvider>
    </>
  );
};

const LawsIcms = ({ id }) => {
  const { data, isLoading } = useGradeIcmsById(id);
  return <GradeLaws grade={data?.gradeIcms} isLoadingGrade={isLoading} />;
};

const LawsPisCofins = ({ id }) => {
  const { data, isLoading } = useGradePiscofinsId(id);
  return (
    <GradeLaws
      grade={data?.gradePisCofins}
      isLoadingGrade={isLoading}
      isPisCofins
    />
  );
};

export const LawGradeDetail = () => {
  const location = useLocation();
  const params = useParams();

  const { setDisableBusinessIntelligence } = useApp();
  const paramsSearch = new URLSearchParams(location.search);
  const taxType = paramsSearch.get("type");

  useEffect(() => {
    setDisableBusinessIntelligence(true);
    return () => setDisableBusinessIntelligence(false);
  }, [setDisableBusinessIntelligence]);

  return hasPisCofinsTax(taxType) ? (
    <LawsPisCofins id={params.id} />
  ) : (
    <LawsIcms id={params.id} />
  );
};
