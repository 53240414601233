import { useState, useCallback } from "react";
import { useFetchAuthenticate, useAPIAuthenticate } from "hooks";
import { useAuth } from "context/AuthContext";
import { AUTH_TOKEN_SESSION_COMPANIES, getSessionRaw } from "utils/session";

export const usePermissionAccessGroups = () =>
  useFetchAuthenticate("/permissions/accessGroups");

// Local aonde e gerado o token da aplicacao para o usuario!
export const usePermissionStoresToken = () => {
  const { setCompaniesSessionToken } = useAuth();
  const [token, setToken] = useState(
    getSessionRaw(AUTH_TOKEN_SESSION_COMPANIES) ?? null,
  );
  const [isLoading, setIsLoading] = useState(false);
  const api = useAPIAuthenticate();

  const onSend = useCallback(
    stores => {
      setIsLoading(true);
      api
        .post("user/token", {
          stores,
        })
        .then(res => {
          setToken(res?.data?.token);
          return setCompaniesSessionToken(res?.data?.token);
        })
        .catch(error => console.log(error))
        .finally(() => {
          setIsLoading(false);
        });
    },
    [api, setCompaniesSessionToken],
  );

  return {
    token,
    isLoading,
    onSend,
  };
};
