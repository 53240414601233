import { format } from "date-fns";

export const getTagTypeDashoard = tag =>
  tag === "Sped Contribuições"
    ? "contribuicoes"
    : tag === "Sped Fiscal"
    ? "fiscal"
    : "xml";

export const getDatesMonths = (vendasDateStart, vendasDateEnd) => {
  const [dateStart, dateEnd] = [
    new Date(vendasDateStart),
    new Date(vendasDateEnd),
  ];

  const current = new Date();

  if (format(current, "MM") === format(dateEnd, "MM"))
    return [dateStart, current];

  return [dateStart, dateEnd];
};
