import { isEqual } from "lodash";
import React, {
  useState,
  createContext,
  useEffect,
  useCallback,
  useContext,
} from "react";
import {
  usePermissionAccessGroups,
  useCompaniesAccessGroupPermission,
  useModules,
  useStoreCompanyPermission,
  usePermissionStoresToken,
} from "useCases";
import {
  AUTH_SESSION_GROUP_ACESSS,
  AUTH_SESSION_COMPANIES,
  AUTH_SESSION_STORES,
  setSession,
  getSession,
} from "utils/session";

const PermissionsContext = createContext({});

const usePermissions = () => {
  const context = useContext(PermissionsContext);
  if (!context)
    throw new Error(
      "usePermissions precisa ser utilizado dentro de um PermissionsProvider",
    );
  return context;
};

const getStores = companies => {
  return companies.reduce(
    (previous, currentValue) => [...previous, ...(currentValue?.stores ?? [])],
    [],
  );
};

const PermissionsProvider = ({ children }) => {
  const [listItemsMatriz, setListItemMatriz] = useState([]);
  const [listItemsSelected, setListItemsSelected] = useState([]);

  const { onSend } = usePermissionStoresToken();

  const [tokenCardHeaderSelected, setTokenCardHeaderSelected] = useState();

  const [isLoadingDashBoards, setIsLoadingDashBoards] = useState(false);

  const { data: dataAccessGroup, revalidate: accessGroupRevalidate } =
    usePermissionAccessGroups();
  const { data: dataModules } = useModules();
  const [modules, setModules] = useState([]);

  const [isBtnClickSearchStoreCompany, setIsBtnClickSearchStoreCompany] =
    useState(false);

  // TODO: VARIABLE GROUPS
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(
    getSession(AUTH_SESSION_GROUP_ACESSS),
  );

  // TODO: COMPANIES SELECTED AND LIST
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(
    getSession(AUTH_SESSION_COMPANIES),
  );

  const [stores, setStores] = useState(getSession(AUTH_SESSION_STORES, []));
  const [selectedStore, setSelectedStore] = useState(
    getSession(AUTH_SESSION_STORES, null),
  );

  // TODO: COMPANIES SEARCH
  const [companiesSearch, setCompaniesSearch] = useState([]);
  const [selectedCompanySearch, setSelectedCompanySearch] = useState(null);
  const [isSelectedFirstAcessGroup, setIsSelectedFirstAcessGroup] =
    useState(false);

  const [currentGroup, setCurrentGroup] = useState(null);

  const {
    data: dataCompaniesAcessGroup,
    revalidate: accessGroupCompaniesRevalidate,
  } = useCompaniesAccessGroupPermission(selectedGroup?._id);

  const dataStoreCompanyPermission = useStoreCompanyPermission(
    selectedCompanySearch ?? selectedCompany,
  );

  const fetchGroups = useCallback(async () => {
    await accessGroupRevalidate();
    return dataAccessGroup?.accessGroups;
  }, [accessGroupRevalidate, dataAccessGroup?.accessGroups]);

  useEffect(() => {
    const groupsSort = dataAccessGroup?.accessGroups?.sort((a, b) =>
      a.groupName > b.groupName ? 1 : b.groupName > a.groupName ? -1 : 0,
    );
    setGroups(groupsSort);
    if (dataAccessGroup?.accessGroups?.length && selectedGroup === null) {
      setSelectedGroup(groupsSort[0]);
    }
  }, [dataAccessGroup?.accessGroups, setSelectedGroup, selectedGroup]);

  useEffect(() => setModules(dataModules?.modules), [dataModules?.modules]);

  useEffect(() => {
    setCompanies(dataCompaniesAcessGroup?.companies);
  }, [dataCompaniesAcessGroup?.companies]);

  const [selectedAllCompany, setSelectedAllCompany] = useState(false);
  const [selectedAllStore, setSelectedAllStore] = useState(false);
  const [selectedAllCompaniesSearch, setSelectedAllCompaniesSearch] =
    useState(false);

  useEffect(() => {
    setSelectedAllCompany(isEqual(companies, selectedCompany));
  }, [companies, selectedCompany]);

  useEffect(() => {
    setSelectedAllStore(isEqual(stores, selectedStore));
  }, [stores, selectedStore]);

  const handleChangeStores = useCallback(
    list => {
      // TODO: Local aonde vai ser gerado o token!
      onSend(list);
      setSelectedStore(list);
    },
    [setSelectedStore, onSend],
  );

  const handleChangePermission = useCallback(
    ({ type, item }) => {
      switch (type) {
        case "group":
          setSelectedGroup(JSON.parse(JSON.stringify(item)));
          break;
        case "company":
          setSelectedCompany(item);
          break;
        case "store":
          handleChangeStores(item);
          break;
        case "company-search":
          setSelectedCompanySearch(item);
          break;
        default:
          break;
      }
    },
    [handleChangeStores],
  );

  const cleanupSelected = useCallback(
    type => {
      handleChangePermission({
        type,
        item: [],
      });
    },
    [handleChangePermission],
  );

  // TODO: HANDLE SELECTED GROUP
  const handleSelectGroup = useCallback(
    (i, group) => {
      handleChangePermission({
        type: "group",
        item: group,
      });
      cleanupSelected("company");
      cleanupSelected("store");
    },
    [cleanupSelected, handleChangePermission],
  );

  // TODO: HANDLE SELECTED ALL COMPANY
  const handleCheckAllCompany = useCallback(() => {
    // selected stores cleanup
    if (selectedAllCompany) {
      cleanupSelected("store");
    }

    handleChangePermission({
      type: "company",
      item: () => {
        let cloneCompany = companies;
        cloneCompany = cloneCompany.map(item => {
          item.checked = !selectedAllCompany;
          return item;
        });
        setCompanies(cloneCompany);

        const storeList = getStores(
          cloneCompany.filter(item => item.checked),
        ).map(v => ({
          ...v,
          checked: true,
        }));
        setStores(storeList);

        // TODO: REMOVER! pois
        handleChangePermission({
          type: "store",
          item: storeList,
        });

        return cloneCompany.filter(item => item.checked);
      },
    });
  }, [companies, selectedAllCompany, cleanupSelected, handleChangePermission]);

  // TODO: HANDLE SELECTED ALL STORES
  const handleCheckAllStore = useCallback(() => {
    let cloneStores = stores;

    cloneStores = cloneStores.map(item => {
      item.checked = !selectedAllStore;
      return item;
    });

    const selectedStores = cloneStores.filter(item => item.checked);
    setStores(cloneStores);
    handleChangePermission({
      type: "store",
      item: selectedStores,
    });
  }, [stores, selectedAllStore, handleChangePermission]);

  // TODO: HANDLE SELECTED COMPANY
  const handleSelectCompany = useCallback(
    (index, company) => {
      handleChangePermission({
        type: "company",
        item: () => {
          const cloneCompany = companies;
          cloneCompany[index].checked = !company.checked;
          setCompanies(cloneCompany);

          const storeList = getStores(
            cloneCompany.filter(item => item.checked),
          ).map(v => ({
            ...v,
            checked: true,
          }));
          setStores(storeList);

          // TODO: REMOVER! pois
          handleChangePermission({
            type: "store",
            item: storeList,
          });
          return cloneCompany.filter(item => item.checked);
        },
      });
    },
    [companies, handleChangePermission],
  );

  const handleSelectStore = useCallback(
    (index, store) => {
      const cloneStore = stores;
      cloneStore[index].checked = !store.checked;
      const selectedStores = cloneStore.filter(item => item.checked);
      setStores(cloneStore);
      handleChangePermission({
        type: "store",
        item: selectedStores,
      });
    },
    [stores, handleChangePermission],
  );

  // FIXME: Seta todas as lojas no token do usuario
  useEffect(() => {
    if (stores && !selectedStore && !selectedAllStore) {
      // return stores.map((store, index) => handleSelectStore(index, store));
    }
  }, [
    stores,
    selectedStore,
    selectedAllStore,
    handleSelectStore,
    handleCheckAllStore,
  ]);
  // TODO: CHECK STORES AND UPDATE LIST
  useEffect(() => {
    const resultStores = dataStoreCompanyPermission?.data?.stores;
    if (resultStores) setStores(resultStores);
  }, [
    stores,
    dataStoreCompanyPermission,
    selectedAllStore,
    handleCheckAllStore,
  ]);

  useEffect(() => {
    setSession(AUTH_SESSION_COMPANIES, selectedCompany);
    setSession(AUTH_SESSION_STORES, selectedStore);
    return setSession(AUTH_SESSION_GROUP_ACESSS, selectedGroup);
  }, [selectedGroup, selectedCompany, selectedStore]);

  // TODO: HANDLE SELECTED COMPANIES SEARCH
  const handleSelectCompaniesSearch = useCallback(
    company => {
      handleChangePermission({
        type: "company-search",
        item: () => {
          const storeList = getStores(company).map(v => ({
            ...v,
            checked: true,
          }));

          setStores(storeList);

          // TODO: REMOVER! pois
          handleChangePermission({
            type: "store",
            item: storeList,
          });
          return company;
        },
      });
      handleChangePermission({ type: "company", item: company });
    },
    [handleChangePermission],
  );

  const handleCheckCompany = (e, company) => {
    const { name } = e.target;
    let cloneCompany = companies;

    if (name === "allCompany") {
      cloneCompany = cloneCompany.map(item => {
        item.checked = company;
        return item;
      });
    } else {
      const index = name.split("_")[1];
      if (company.checked) {
        cloneCompany[index].checked = !cloneCompany[index].checked;
      } else {
        cloneCompany[index].checked = true;
      }
    }
    const selectedCompanies = cloneCompany.filter(item => item.checked);
    setCompanies(cloneCompany);
    handleChangePermission({
      type: "company",
      item: selectedCompanies,
    });
  };

  const handleCheckStore = (e, store) => {
    const { name } = e.target;
    let cloneStores = stores;

    if (name === "allStore") {
      cloneStores = cloneStores.map(item => {
        item.checked = store;
        return item;
      });
    } else {
      const index = name.split("_")[1];
      if (store.checked) {
        cloneStores[index].checked = !cloneStores[index].checked;
      } else {
        cloneStores[index].checked = true;
      }
    }
    const selectedStores = cloneStores.filter(item => item.checked);
    setStores(cloneStores);
    handleChangePermission({
      type: "store",
      item: selectedStores,
    });
  };

  useEffect(() => {
    if (currentGroup?._id) {
      handleChangePermission({
        type: "group",
        item: dataAccessGroup?.accessGroups.find(
          newGroup => newGroup?._id === currentGroup?._id,
        ),
      });
    }
  }, [
    dataAccessGroup?.accessGroups,
    currentGroup?._id,
    handleChangePermission,
  ]);

  const refetchPermissions = useCallback(
    async group => {
      setSelectedCompany(null);
      setSelectedStore(null);
      accessGroupRevalidate();
      accessGroupCompaniesRevalidate();
      setCurrentGroup(group);
    },
    [accessGroupRevalidate, accessGroupCompaniesRevalidate],
  );
  const refetchPermissionsAll = useCallback(
    async group => {
      setSelectedCompany(null);
      setSelectedStore(null);
      accessGroupRevalidate();
      accessGroupCompaniesRevalidate();
      setCurrentGroup(group);

      setSelectedCompanySearch(null);
      setListItemsSelected([]);
      setListItemMatriz([]);
    },
    [
      accessGroupRevalidate,
      accessGroupCompaniesRevalidate,
      setSelectedCompanySearch,
      setListItemsSelected,
      setListItemMatriz,
    ],
  );

  const refetchPermissionsAdv = useCallback(() => {
    setSelectedCompanySearch(null);
    setListItemsSelected([]);
    setListItemMatriz([]);
  }, [setSelectedCompanySearch, setListItemsSelected, setListItemMatriz]);

  return (
    <PermissionsContext.Provider
      value={{
        groups,
        selectedGroup,
        companies,
        selectedCompany,
        setCompanies,
        stores,
        setStores,
        selectedStore,
        selectedAllCompany,
        selectedAllStore,
        handleChangePermission,
        handleCheckStore,
        handleCheckCompany,
        handleSelectGroup,
        handleSelectCompany,
        handleSelectStore,
        handleCheckAllCompany,
        handleCheckAllStore,
        fetchGroups,
        refetchPermissions,
        refetchPermissionsAll,
        refetchPermissionsAdv,

        companiesSearch,
        setCompaniesSearch,
        selectedCompanySearch,
        setSelectedCompanySearch,
        handleSelectCompaniesSearch,
        selectedAllCompaniesSearch,
        setSelectedAllCompaniesSearch,
        isSelectedFirstAcessGroup,
        setIsSelectedFirstAcessGroup,
        isBtnClickSearchStoreCompany,
        setIsBtnClickSearchStoreCompany,
        dataStoreCompanyPermission,
        isLoadingDashBoards,
        setIsLoadingDashBoards,
        listItemsSelected,
        setListItemsSelected,
        listItemsMatriz,
        setListItemMatriz,
        tokenCardHeaderSelected,
        setTokenCardHeaderSelected,
        modules,
        setModules,
      }}
    >
      {children}
    </PermissionsContext.Provider>
  );
};

export { usePermissions, PermissionsProvider };
