import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircle,
  faChevronDown,
  faChevronUp,
  faUser,
  faUsers,
  faNoteSticky,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";

import Collapse from "@material-ui/core/Collapse";
import { useApp } from "context/AppContext";

import { Format } from "utils/date";

import { formatCnpj } from "utils";

import { FaWhatsapp } from "react-icons/fa";

import { ListDados } from "./mock";
import * as S from "../../styles";
import * as Sd from "./styles";

export const DadosClients = ({ selectedClient }) => {
  const history = useHistory();

  const [statusNote, setStatusNote] = useState({});
  const { handleCopy } = useApp({});

  const checkStatusNote = (value, date) => {
    if (value === "note-warning-sale-sync") {
      return setStatusNote({
        type: "saida",
        status: "warning",
        label: "Ultima sincronização de notas de saida",
        date: date || null,
      });
    }
    if (value === "note-warning-entry-sync") {
      return setStatusNote({
        type: "entrada",
        status: "warning",
        label: "Ultima sincronização de nota de entrada",
        date: date || null,
      });
    }

    if (value === "blank_data") {
      return setStatusNote({
        type: "blank_data",
        status: "inativo",
        label: "Sem dados de notas de entrada ou saida",
        date: date || null,
      });
    }

    return setStatusNote({
      type: "active",
      status: "ativo",
      label: "Dados atualizado recentemente",
      date: date || null,
    });
  };

  const [selected, setSelected] = useState({});

  useEffect(() => {
    if (selectedClient?.nfe) {
      return checkStatusNote(
        selectedClient?.nfe?.status,
        selectedClient?.nfe?.updated_nfe,
      );
    }
    if (!selectedClient?.nfe) {
      return checkStatusNote("blank_data", null);
    }
  }, [selectedClient]);

  if (!selectedClient) {
    return (
      <>
        <S.ContainerRowItem margin="0 30px 0 30px">
          <S.ContainerRowItemNull>
            <h4>Nenhuma empresa selecionada</h4>
          </S.ContainerRowItemNull>
        </S.ContainerRowItem>
      </>
    );
  }

  return (
    <>
      <S.ContainerRowItem margin="0 30px 0 30px">
        <S.ContainerRowItemHeader>
          <S.ContainerRowItemHeaderTitle>
            <S.ContainerRowItemHeaderTitleItem>
              {selectedClient.id_store.companyName ||
                selectedClient.id_store.tradeName ||
                selectedClient.id_store.namePartnerOne ||
                selectedClient.id_store.namePartnerTwo ||
                selectedClient.id_store.legalNature}
            </S.ContainerRowItemHeaderTitleItem>
            <S.ContainerRowItemHeaderTitleItem
              align="right"
              margin="0 10px 0 0"
            >
              <S.ActionBtn
                onClick={() => {
                  history.push(
                    `/usuarios-e-configs/lojas/${selectedClient?.id_store?._id}`,
                  );
                }}
              >
                Abrir detalhe
              </S.ActionBtn>
            </S.ContainerRowItemHeaderTitleItem>
          </S.ContainerRowItemHeaderTitle>
          <S.ContainerRowItemHeaderTitleSub>
            {formatCnpj(selectedClient.id_store.cnpj)}
          </S.ContainerRowItemHeaderTitleSub>
        </S.ContainerRowItemHeader>
        <S.ContainerRowItemContent>
          <Sd.ContainerRowItemContentListDescription>
            <Sd.UlList
              onClick={() => {
                if (selected === "notas") {
                  return setSelected(null);
                }
                setSelected("notas");
              }}
            >
              {selectedClient?.nfe?.status !== "blank_data" ? (
                <Sd.UlListItem index="notas">
                  <Sd.UlListItemHeader>
                    <Sd.UlListItemHeaderDescription>
                      <FontAwesomeIcon icon={faNoteSticky} />
                      Notas Fiscais
                    </Sd.UlListItemHeaderDescription>
                    <Sd.UlListItemHeaderIcon>
                      <FontAwesomeIcon
                        icon={
                          selected === "notas" ? faChevronUp : faChevronDown
                        }
                      />
                    </Sd.UlListItemHeaderIcon>
                  </Sd.UlListItemHeader>
                  <Collapse id="notas" in={selected === "notas"}>
                    <Sd.UlListSub>
                      {selectedClient?.nfe?.first_entry_nfe ? (
                        <Sd.UlListItemSub>
                          <FontAwesomeIcon icon={faCircle} />
                          {`Primeiro nota de entrada: ${Format(
                            selectedClient?.nfe?.first_entry_nfe?.date,
                          )}, total R$: ${
                            selectedClient?.nfe?.first_entry_nfe?.sum
                          }`}
                        </Sd.UlListItemSub>
                      ) : (
                        ""
                      )}

                      {selectedClient?.nfe?.last_entry_nfe_last ? (
                        <Sd.UlListItemSub>
                          <FontAwesomeIcon icon={faCircle} />
                          {`Última nota de entrada: ${Format(
                            selectedClient?.nfe?.last_entry_nfe_last?.date,
                          )}, total R$: ${
                            selectedClient?.nfe?.last_entry_nfe_last?.sum
                          }`}
                        </Sd.UlListItemSub>
                      ) : (
                        ""
                      )}

                      {selectedClient?.nfe?.sum_entries ? (
                        <Sd.UlListItemSub>
                          <FontAwesomeIcon icon={faCircle} />
                          {`Total do valor de notas de entrada R$: ${selectedClient?.nfe?.sum_entries}`}
                        </Sd.UlListItemSub>
                      ) : (
                        ""
                      )}
                      {statusNote && statusNote.type === "entrada" ? (
                        <Sd.UlListItemSubStatus status={statusNote.status}>
                          {`${statusNote?.label}: ${Format(statusNote?.date)}`}
                        </Sd.UlListItemSubStatus>
                      ) : (
                        ""
                      )}
                      <Sd.UlListItemSubMargin />
                      {selectedClient?.nfe?.first_sale_nfe ? (
                        <Sd.UlListItemSub>
                          <FontAwesomeIcon icon={faCircle} />
                          {`Primeira nota de Saida: ${Format(
                            selectedClient?.nfe?.first_sale_nfe?.date,
                          )}, total R$: ${
                            selectedClient?.nfe?.first_sale_nfe?.sum
                          }`}
                        </Sd.UlListItemSub>
                      ) : (
                        ""
                      )}

                      {selectedClient?.nfe?.last_sale_nfe_last ? (
                        <Sd.UlListItemSub>
                          <FontAwesomeIcon icon={faCircle} />
                          {`Última nota de saída: ${Format(
                            selectedClient?.nfe?.last_sale_nfe_last?.date,
                          )}, total R$: ${
                            selectedClient?.nfe?.last_sale_nfe_last?.sum
                          }`}
                        </Sd.UlListItemSub>
                      ) : (
                        ""
                      )}
                      {selectedClient?.nfe?.sum_sales ? (
                        <Sd.UlListItemSub>
                          <FontAwesomeIcon icon={faCircle} />
                          {`Total do valor de notas de saída R$: ${selectedClient?.nfe?.sum_sales}`}
                        </Sd.UlListItemSub>
                      ) : (
                        ""
                      )}
                      {statusNote && statusNote.type === "saida" ? (
                        <Sd.UlListItemSubStatus status={statusNote.status}>
                          {`${statusNote?.label}: ${Format(statusNote?.date)}`}
                        </Sd.UlListItemSubStatus>
                      ) : (
                        ""
                      )}
                    </Sd.UlListSub>
                  </Collapse>
                </Sd.UlListItem>
              ) : (
                <Sd.UlListItem index="notas">
                  <Sd.UlListItemHeader>
                    <Sd.UlListItemHeaderDescription>
                      <FontAwesomeIcon icon={faNoteSticky} />
                      Notas Fiscais
                    </Sd.UlListItemHeaderDescription>
                    <Sd.UlListItemHeaderIcon>
                      <FontAwesomeIcon
                        icon={
                          selected === "notas" ? faChevronUp : faChevronDown
                        }
                      />
                    </Sd.UlListItemHeaderIcon>
                  </Sd.UlListItemHeader>
                  <Collapse id="notas" in={selected === "notas"}>
                    <Sd.UlListSub>
                      <Sd.UlListItemSubStatus status="inativo">
                        Sem dados de notas de entrada ou saida
                      </Sd.UlListItemSubStatus>
                    </Sd.UlListSub>
                  </Collapse>
                </Sd.UlListItem>
              )}
            </Sd.UlList>

            {ListDados.map((item, index) => {
              return (
                <Sd.UlList
                  onClick={() => {
                    if (selected === index) {
                      return setSelected(null);
                    }
                    setSelected(index);
                  }}
                >
                  <Sd.UlListItem index={index}>
                    <Sd.UlListItemHeader>
                      <Sd.UlListItemHeaderDescription>
                        {item.icon}
                        {item.title}
                      </Sd.UlListItemHeaderDescription>
                      <Sd.UlListItemHeaderIcon>
                        <FontAwesomeIcon
                          icon={
                            selected === index ? faChevronUp : faChevronDown
                          }
                        />
                      </Sd.UlListItemHeaderIcon>
                    </Sd.UlListItemHeader>
                    {item.dados.length > 0 && (
                      <Collapse id={index} in={selected === index}>
                        <Sd.UlListSub>
                          {item.dados.map(itemDados => {
                            if (itemDados?.isNewLine) {
                              return <Sd.UlListItemSubMargin />;
                            }
                            return (
                              <Sd.UlListItemSub>
                                <FontAwesomeIcon icon={faCircle} />
                                {itemDados.title}
                              </Sd.UlListItemSub>
                            );
                          })}
                        </Sd.UlListSub>
                      </Collapse>
                    )}
                  </Sd.UlListItem>
                </Sd.UlList>
              );
            })}
            <Sd.UlList>
              <Sd.UlListItem>
                <FontAwesomeIcon
                  size="12px"
                  style={{
                    margin: "0 5px 0 10px",
                  }}
                  color="#17456b"
                  icon={faUsers}
                />
                Proprietário
                <Sd.UlListSub>
                  <Sd.UlListItemSub>
                    <FontAwesomeIcon icon={faUser} />
                    {selectedClient?.id_store?.namePartnerOne}
                  </Sd.UlListItemSub>
                  <Sd.UlListItemSub>
                    <FontAwesomeIcon icon={faUser} />
                    {selectedClient?.id_store?.namePartnerTwo}
                  </Sd.UlListItemSub>
                  <Sd.UlListItemSub>
                    <FontAwesomeIcon icon={faUser} />
                    {selectedClient?.id_store?.namePartnerTwo}
                  </Sd.UlListItemSub>
                  <Sd.UlListItemSub>
                    <FontAwesomeIcon icon={faLocationDot} />
                    {`
                 Endereço: ${selectedClient?.id_store?.address},
                ${
                  selectedClient?.id_store?.complement &&
                  selectedClient?.id_store?.complement !== "-"
                    ? `Complemento: ${selectedClient?.id_store?.complement},`
                    : ``
                }
                 Número: ${selectedClient?.id_store?.number} - 
                 ${selectedClient?.id_store?.district}, 
                 ${selectedClient?.id_store?.city} - ${
                      selectedClient?.id_store?.state
                    },
                 ${selectedClient?.id_store?.zipcode},  
                 `}
                  </Sd.UlListItemSub>
                </Sd.UlListSub>
              </Sd.UlListItem>
            </Sd.UlList>
            <Sd.UlList>
              <Sd.UlListItem>
                <FaWhatsapp
                  color="green"
                  style={{
                    fontSize: "12px",
                  }}
                />
                Contato whatsapp
                <Sd.UlListSub>
                  <Sd.UlListItemSub>
                    {selectedClient?.id_store?.whatsapp_name || "Sem nome"}
                  </Sd.UlListItemSub>
                  <Sd.UlListItemSub>
                    {`Função : ${selectedClient?.id_store?.in_store_function}`}
                  </Sd.UlListItemSub>
                  <Sd.UlListItemSub
                    onClick={() =>
                      handleCopy(selectedClient?.id_store?.whatsapp, "Telefone")
                    }
                  >
                    {`Telefone: ${
                      selectedClient?.id_store?.whatsapp || "Sem whatsapp"
                    }`}
                  </Sd.UlListItemSub>
                </Sd.UlListSub>
              </Sd.UlListItem>
            </Sd.UlList>
          </Sd.ContainerRowItemContentListDescription>
        </S.ContainerRowItemContent>
      </S.ContainerRowItem>
    </>
  );
};
