import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useProductsContext } from "context/ProductsContext";
import { Tooltip } from "@mix/mixfiscal-designsystem";

import { ellipsi } from "../../../../../utils";
import {
  useCestsSegments,
  useCestsSegmentsItems,
  useCestsItems,
} from "../../../../../useCases/cest";
import { useGradeFilters } from "../../../../../hooks/useGrade";
import { Alert } from "../../../../structure";

import * as CestStyles from "./styles";

const getCestFirstNumbers = value => value.replace(/\./g, "").substr(0, 5);

export const Cest = ({ values }) => {
  const [segList, setSegList] = useState([]);
  const [especificationItems, setEspecification] = useState([]);
  const [setItems, setSegItems] = useState([]);
  const [cestValues, setCestValues] = useState({});
  const [actualCest, setActualCest] = useState({});
  const params = useGradeFilters({ values, hasCenary: false });
  const { handleChangeField } = useProductsContext();

  const { data: dataSegments, isLoading: isLoadingSeg } = useCestsSegments({
    ncms: params.ncms,
  });

  const { data: dataSegmentsItens, isLoading: isLoadingSegItems } =
    useCestsSegmentsItems(cestValues.segment, {
      ncms: params.ncms,
    });
  const { data: dataItems, isLoadingItems } = useCestsItems({
    cest: getCestFirstNumbers(cestValues?.item ?? ""),
    ...params,
  });

  const { data: dataItemsActualCest } = useCestsItems({
    cest: values?.cest || "",
    ...params,
  });

  useEffect(() => {
    if (dataItemsActualCest && dataItemsActualCest?.data?.length > 0) {
      setActualCest(dataItemsActualCest?.data[0]);
    }
  }, [dataItemsActualCest, setActualCest]);

  useEffect(() => {
    if (dataSegments && dataSegments?.segments?.length > 0) {
      setSegList([
        {
          _id: "selecione",
          name: "Selecione",
        },
        ...dataSegments?.segments,
      ]);
    }
  }, [dataSegments, setSegList]);

  useEffect(() => {
    if (dataSegmentsItens && dataSegmentsItens?.data?.length > 0) {
      setSegItems([
        {
          cest: "",
          label: "Selecione",
        },
        ...dataSegmentsItens?.data,
      ]);
    }
  }, [dataSegmentsItens, setSegItems]);

  useEffect(() => {
    if (dataItems && dataItems?.data?.length > 0) {
      setEspecification([
        {
          cest: "",
          label: "Selecione",
        },
        ...dataItems?.data,
      ]);
    }
  }, [dataItems, setEspecification]);

  useEffect(() => {
    if (cestValues?.segment === "selecione") {
      setSegList([
        {
          _id: "selecione",
          name: "Selecione",
        },
        ...dataSegments?.segments,
      ]);
      setSegItems([
        {
          cest: "",
          label: "Selecione o Segmento",
        },
      ]);
      setEspecification([
        {
          cest: "",
          label: "Selecione o Item",
        },
      ]);
      handleChangeField("cest", "");
    }
  }, [
    cestValues,
    dataSegments,
    setSegList,
    handleChangeField,
    setEspecification,
  ]);

  useEffect(() => {
    if (cestValues?.especification) {
      handleChangeField("cest", cestValues?.especification);
    }
  }, [cestValues?.especification, handleChangeField]);

  return (
    <CestStyles.CardWithLateralHeader>
      <CestStyles.Header>CEST</CestStyles.Header>
      <main>
        <div className="input-container">
          <CestStyles.InputContainer maxWidth="100%">
            <label>Segmento</label>
            <Select
              isLoading={isLoadingSeg}
              name="segment"
              label="Segmento"
              placeholder="Selecione o Segmento"
              options={(segList || []).map(segment => ({
                value: segment._id,
                label: ellipsi(segment.name),
              }))}
              onChange={option => setCestValues({ segment: option.value })}
            />
          </CestStyles.InputContainer>
          <CestStyles.InputContainer maxWidth="100%">
            <label>Item</label>

            <Select
              name="item"
              label="Item"
              isLoading={isLoadingSegItems}
              placeholder="Selecione o Item"
              options={(setItems || []).map(option => ({
                value: option.cest,
                label:
                  option?.label ||
                  ellipsi(`${option.cest} - ${option.description}`),
              }))}
              onChange={option =>
                setCestValues({
                  segment: cestValues.segment,
                  item: option.value,
                })
              }
            />
          </CestStyles.InputContainer>
          <CestStyles.InputContainer maxWidth="100%">
            <label>Especificação</label>
            <Select
              name="especification"
              label="Especificação"
              placeholder="Selecione a Especificação"
              isLoading={isLoadingItems}
              options={(especificationItems || []).map(option => ({
                value: option.cest,
                label:
                  option?.label ||
                  ellipsi(`${option.cest} - ${option.description}`),
              }))}
              onChange={option =>
                setCestValues({ ...cestValues, especification: option.value })
              }
              values={(especificationItems || [])
                .filter(item => item?.cest === values?.cest)
                .map(option => ({
                  value: option.cest,
                  label:
                    option?.label ||
                    ellipsi(`${option.cest} - ${option.description}`),
                }))}
            />
          </CestStyles.InputContainer>
        </div>
        {actualCest && actualCest?.cest && actualCest?.description ? (
          <Tooltip
            body="Cest atual vinculado"
            position="bottom"
            zIndex={2}
            zIndexBackdrop={1}
          >
            <CestStyles.CestResult>
              {`${actualCest?.cest} - ${actualCest?.description}`}
            </CestStyles.CestResult>
          </Tooltip>
        ) : (
          ""
        )}

        {!dataSegments?.segments?.length && !isLoadingSeg && (
          <CestStyles.Messages>
            <Alert
              type="warning"
              title="Informativo"
              content="Não possuí cests ativos para os NCM's selecionados"
            />
          </CestStyles.Messages>
        )}
      </main>
    </CestStyles.CardWithLateralHeader>
  );
};

export default Cest;
