import React from "react";
import { Card, Tag } from "@mix/mixfiscal-designsystem";
import { Checkbox as CustomCheckbox } from "components/form";
import { LoadPlaceholder } from "components/structure/Load";
import { useGridManagerContext } from "context/GridManagerContext";
import { getTagsWithLabel, getListSelecteds } from "utils/context";
import { useGradeIcmsAlqs } from "useCases/grid";

import * as S from "../styles";

const headerColorGray = "#e5e5e5";
const textColorGray = "#304156";

export const CardAliquotTributary = ({ register }) => {
  const {
    filterFields: {
      alq_icms_e: alqIcmsE,
      alq_icms_s: alqIcmsS,
      alq_piscofins_e: alqPiscofinsE,
      alq_piscofins_s: alqPiscofinsS,
      alq_carga_tributaria_e: alqCargaTributariaE,
      alq_carga_tributaria_s: alqCargaTributariaS,
      alq_reducao_base_calc_s: alqReducaoBaseCalcS,
      alq_reducao_base_calc_e: alqReducaoBaseCalcE,
    },
  } = useGridManagerContext();

  const { data, isLoading } = useGradeIcmsAlqs();

  const getTagName = getListSelecteds(
    [
      ...getTagsWithLabel(alqIcmsE, "alq_icms_e"),
      ...getTagsWithLabel(alqIcmsS, "alq_icms_s"),
      ...getTagsWithLabel(alqPiscofinsE, "alq_piscofins_e"),
      ...getTagsWithLabel(alqPiscofinsS, "alq_pis_cofins_s"),
      ...getTagsWithLabel(alqCargaTributariaE, "alq_carga_tributaria_e"),
      ...getTagsWithLabel(alqCargaTributariaS, "alq_carga_tributaria_s"),
      ...getTagsWithLabel(alqReducaoBaseCalcS, "alq_reducao_base_calc_s"),
      ...getTagsWithLabel(alqReducaoBaseCalcE, "alq_reducao_base_calc_e"),
    ].map(item => item.label),
  );

  const comprasAlqs =
    data?.docs?.filter(row => row.operacao === "compra") || [];
  const vendasAlqs = data?.docs?.filter(row => row.operacao === "venda") || [];

  return (
    <Card
      className="card-custom"
      handleCtaClick={() => {}}
      hasElevation
      headerColor={headerColorGray}
      textColor={textColorGray}
      title="ALÍQUOTA E CARGA TRIBUTÁRIA"
    >
      <div className="row aliquota-icms">
        <div className="row">
          <h5>ALÍQUOTA ICMS</h5>
        </div>

        <div className="row">
          <S.CardCustomCst type="purchase">
            <h1>{`COMPRA (${comprasAlqs.length})`}</h1>
            <LoadPlaceholder show={isLoading}>
              {comprasAlqs.map(alq => (
                <S.CheckBox key={`alq-${alq.alq}`}>
                  <CustomCheckbox
                    ref={register}
                    name={`alq_icms_e.${alq.alq}`}
                    value={alq.alq}
                    label={`${alq.alq}%`}
                  />
                </S.CheckBox>
              ))}
            </LoadPlaceholder>
          </S.CardCustomCst>

          <S.CardCustomCst type="sale">
            <h1>{`VENDA (${vendasAlqs.length})`}</h1>
            <LoadPlaceholder show={isLoading}>
              {vendasAlqs?.map(alq => (
                <S.CheckBox>
                  <CustomCheckbox
                    ref={register}
                    name={`alq_icms_s.${alq.alq}`}
                    value={alq.alq}
                    label={`${alq.alq}%`}
                  />
                </S.CheckBox>
              ))}
            </LoadPlaceholder>
          </S.CardCustomCst>
        </div>
      </div>

      <div className="row aliquota-icms">
        <div className="row">
          <h5>ALÍQUOTA PIS/CONFIS</h5>
        </div>
        <div className="row">
          <S.CardCustomCst type="purchase">
            <h1>COMPRA</h1>

            <S.CheckBox>
              <CustomCheckbox
                ref={register}
                name="alq_piscofins_e.18"
                value="1"
                label="18%"
              />
            </S.CheckBox>
            <S.CheckBox>
              <CustomCheckbox
                ref={register}
                name="alq_piscofins_e.12"
                value="1"
                label="12%"
              />
            </S.CheckBox>

            <S.CheckBox>
              <CustomCheckbox
                ref={register}
                name="alq_piscofins_e.06"
                value="1"
                label="06%"
              />
            </S.CheckBox>

            <S.CheckBox>
              <CustomCheckbox
                ref={register}
                name="alq_piscofins_e.0"
                value="1"
                label="0%"
              />
            </S.CheckBox>
          </S.CardCustomCst>

          <S.CardCustomCst type="sale">
            <h1>VENDA</h1>

            <S.CheckBox>
              <CustomCheckbox
                ref={register}
                name="alq_piscofins_s.18"
                value="1"
                label="18%"
              />
            </S.CheckBox>

            <S.CheckBox>
              <CustomCheckbox
                ref={register}
                name="alq_piscofins_s.12"
                value="1"
                label="12%"
              />
            </S.CheckBox>

            <S.CheckBox>
              <CustomCheckbox
                ref={register}
                name="alq_piscofins_s.06"
                value="1"
                label="06%"
              />
            </S.CheckBox>

            <S.CheckBox>
              <CustomCheckbox
                ref={register}
                name="alq_piscofins_s.0"
                value="1"
                label="0%"
              />
            </S.CheckBox>
          </S.CardCustomCst>
        </div>
      </div>

      <div className="row aliquota-icms">
        <div className="row">
          <h5>REDUÇÃO DA BASE DE CÁLCULO</h5>
        </div>
        <div className="row">
          <S.CardCustomCst type="purchase">
            <h1>COMPRA</h1>
            <S.CheckBox>
              <CustomCheckbox
                ref={register}
                name="alq_reducao_base_calc_e.18"
                value="1"
                label="18%"
              />
            </S.CheckBox>
            <S.CheckBox>
              <CustomCheckbox
                ref={register}
                name="alq_reducao_base_calc_e.12"
                value="1"
                label="12%"
              />
            </S.CheckBox>

            <S.CheckBox>
              <CustomCheckbox
                ref={register}
                name="alq_reducao_base_calc_e.06"
                value="1"
                label="06%"
              />
            </S.CheckBox>

            <S.CheckBox>
              <CustomCheckbox
                ref={register}
                name="alq_reducao_base_calc_e.0"
                value="1"
                label="0%"
              />
            </S.CheckBox>
          </S.CardCustomCst>

          <S.CardCustomCst type="sale">
            <h1>VENDA</h1>
            <S.CheckBox>
              <CustomCheckbox
                ref={register}
                name="alq_reducao_base_calc_s.18"
                value="1"
                label="18%"
              />
            </S.CheckBox>
            <S.CheckBox>
              <CustomCheckbox
                ref={register}
                name="alq_reducao_base_calc_s.12"
                value="1"
                label="12%"
              />
            </S.CheckBox>

            <S.CheckBox>
              <CustomCheckbox
                ref={register}
                name="alq_reducao_base_calc_s.06"
                value="1"
                label="06%"
              />
            </S.CheckBox>

            <S.CheckBox>
              <CustomCheckbox
                ref={register}
                name="alq_reducao_base_calc_s.0"
                value="1"
                label="0%"
              />
            </S.CheckBox>
          </S.CardCustomCst>
        </div>
      </div>

      <div className="row aliquota-icms">
        <div className="row">
          <h5>CARGA TRIBUTÁRIA</h5>
        </div>
        <div className="row">
          <S.CardCustomCst type="purchase">
            <h1>COMPRA</h1>

            <S.CheckBox>
              <CustomCheckbox
                ref={register}
                name="alq_carga_tributaria_e.18"
                value="1"
                label="18%"
              />
            </S.CheckBox>
            <S.CheckBox>
              <CustomCheckbox
                ref={register}
                name="alq_carga_tributaria_e.12"
                value="1"
                label="12%"
              />
            </S.CheckBox>

            <S.CheckBox>
              <CustomCheckbox
                ref={register}
                name="alq_carga_tributaria_e.06"
                value="1"
                label="06%"
              />
            </S.CheckBox>

            <S.CheckBox>
              <CustomCheckbox
                ref={register}
                name="alq_carga_tributaria_e.0"
                value="1"
                label="0%"
              />
            </S.CheckBox>
          </S.CardCustomCst>

          <S.CardCustomCst type="sale">
            <h1>VENDA</h1>

            <S.CheckBox>
              <CustomCheckbox
                ref={register}
                name="alq_carga_tributaria_s.18"
                value="1"
                label="18%"
              />
            </S.CheckBox>
            <S.CheckBox>
              <CustomCheckbox
                ref={register}
                name="alq_carga_tributaria_s.12"
                value="1"
                label="12%"
              />
            </S.CheckBox>

            <S.CheckBox>
              <CustomCheckbox
                ref={register}
                name="alq_carga_tributaria_s.06"
                value="1"
                label="06%"
              />
            </S.CheckBox>

            <S.CheckBox>
              <CustomCheckbox
                ref={register}
                name="alq_carga_tributaria_s.0"
                value="1"
                label="0%"
              />
            </S.CheckBox>
          </S.CardCustomCst>
        </div>
      </div>

      {getTagName && (
        <div
          className="row"
          style={{
            display: "flex",
            alignItems: "center",
            padding: "8px",
          }}
        >
          <S.RowTagsName>
            <Tag label={getTagName} type="simple" />
          </S.RowTagsName>
          {/* <Icon color="#dcdcdc" name="mover" size={24} /> */}
        </div>
      )}
    </Card>
  );
};
