import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  gap: 24px;
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;
export const TopPart = styled.div`
  margin-bottom: 100px;
`;

const customForLine = {
  setMargin: margin => css`
    margin: ${margin};
  `,
};

export const FormLine = styled.div`
  ${({ margin }) => css`
    display: flex;
    ${margin && customForLine.setMargin(margin)}
  `}
`;

export const Divider = styled.div`
  width: 60%;
  background-color: #e0e0e0;
  height: 2px;
  align-self: center;
  margin: 10px 0 10px 0;
  border-radius: 10px;
`;

export const ColumnFormItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const CollumForm = styled.div`
  ${({ width = "100%", height }) => css`
    display: flex;
    width: ${width};
    height: ${height || "auto"};
    flex-direction: column;
    padding: 5px 8px 5px 8px;

    ${ColumnFormItem} {
      &:not(:last-of-type) {
        margin-bottom: 10px;
      }
    }
  `}
`;

export const Footer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 38px;
  justify-content: center;
`;
