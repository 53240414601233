import React, { useEffect, useState } from "react";
import { useWhatsAppActions, useUserGet } from "useCases";
import { WhatsAppForm } from "components/contexts/user/WhatsAppForm";
import { WhatsAppInfo } from "./WhatsAppInfo";

export const WhatsApp = ({ user }) => {
  const [whatsAppActive, setWhatsAppActive] = useState(false);
  const { data: dataUser, fetch: fetchUser } = useUserGet({ id: user?._id });
  const { onSendActiveWhatsApp, onSendRemoveWhatsApp } = useWhatsAppActions();

  const onChangeWhatsApp = value => {
    if (value) {
      return onSendActiveWhatsApp({
        email: dataUser?.email || user.email,
      }).then(() => {
        setTimeout(() => {
          fetchUser({ id: user._id });
        }, 15000);
      });
    }

    return onSendRemoveWhatsApp({ email: dataUser?.email || user.email }).then(
      () => {
        setTimeout(() => {
          fetchUser({ id: user._id });
        }, 15000);
      },
    );
  };

  useEffect(() => {
    setWhatsAppActive(dataUser?.whatsAppActive);
  }, [dataUser?.whatsAppActive]);

  return (
    <>
      {!whatsAppActive && (
        <WhatsAppForm
          onChange={e => {
            const { value } = e.target;
            onChangeWhatsApp(value);
            setWhatsAppActive(value);
          }}
          value={whatsAppActive}
        />
      )}
      {whatsAppActive && (
        <WhatsAppInfo
          clientId={dataUser?.email ?? user?.email}
          data={dataUser?.whatsApp ?? user?.whatsApp}
          value={whatsAppActive}
          onChangeField={({ target: { value } }) => setWhatsAppActive(value)}
          onChangeStatus={onChangeWhatsApp}
        />
      )}
    </>
  );
};
