import React, { createContext, useCallback, useContext, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import ToastContainer from "components/ToastContainer";

export const ToastContext = createContext({});

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) throw Error("useToast must be used within a ToastProvider");
  return context;
};

export const ToastProvider = ({ children }) => {
  const [messages, setMessages] = useState([]);

  const addToast = useCallback(({ align, type, title, description }) => {
    const id = uuidv4();
    const toast = {
      id,
      type,
      align,
      title,
      description,
    };
    setMessages(prevState => [...prevState, toast]);
  }, []);

  const removeToast = useCallback(id => {
    setMessages(prevState => prevState.filter(message => message.id !== id));
  }, []);

  return (
    <ToastContext.Provider value={{ addToast }}>
      {children}
      <ToastContainer messages={messages} removeToast={removeToast} />
    </ToastContext.Provider>
  );
};
