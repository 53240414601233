import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Card, Button, Container } from "components/structure";
import { Form } from "components/form";
import { BtnRemove } from "components/contexts/shared";
import {
  AssessoresForm,
  AssessoresClients,
  AssessoresClientesModal,
} from "components/contexts/assessores";
import { useAssessorAction, useAssessor } from "useCases";
import * as S from "./AssessoresCrud.styles";

export const AssessoresCrud = () => {
  const { id } = useParams();
  const { data } = useAssessor(id);
  const { onSave } = useAssessorAction();

  const { control, getValues, reset, handleSubmit } = useForm({
    defaultValues: {},
  });

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data, reset]);

  const onSubmit = () => {
    onSave(id, getValues());
  };

  const userValue = getValues("user");

  return (
    <Card
      type="local"
      headerColor="#256925"
      title={id ? "EDITAR LOJA" : "CRIAR NOVO ASSESSOR"}
      iconName="person"
      action={
        id && <BtnRemove id={id} hook={useAssessorAction} module="Assessor" />
      }
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <AssessoresForm userValue={userValue} control={control} />
        <Container justifyEnd paddingSize="xs">
          <Button goBack type="button" color="gray" style={{ marginRight: 15 }}>
            VOLTAR
          </Button>
          <Button type="submit" label="SALVAR" />
        </Container>
        <S.StoresContainer>
          <Card
            type="local"
            headerColor="#256925"
            title={`Lojas (${data?.wallet?.docs?.length})`}
            iconName="person"
            action={<AssessoresClientesModal id={id} />}
          >
            <AssessoresClients data={data?.wallet?.docs ?? []} idUser={id} />
          </Card>
        </S.StoresContainer>
      </Form>
    </Card>
  );
};
