import React, { useState, useEffect } from "react";
import { hasCertificate } from "utils";
import * as SLocal from "./styles";

export const ListCertificates = ({
  listCertificados,
  handleSelected,
  isNotDateEnd = false,
}) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (listCertificados) {
      const checkList = listCertificados.filter(item =>
        isNotDateEnd
          ? !item?.fadel?.certificate_due_date
          : item?.fadel?.certificate_due_date,
      );

      if (checkList.length > 0) {
        setShow(true);
      }
    }
  }, [listCertificados, isNotDateEnd, setShow]);

  return (
    show && (
      <>
        <SLocal.ListPfxContentTitle>Sem Certificado</SLocal.ListPfxContentTitle>
        <SLocal.ListPfx>
          <SLocal.ListPfxHeader>
            <SLocal.TitleListHeader>
              <h3>Dias</h3>
            </SLocal.TitleListHeader>
            <SLocal.TitleListHeader>
              <h3>Certificados</h3>
            </SLocal.TitleListHeader>
          </SLocal.ListPfxHeader>
          <SLocal.ListPfxContent>
            {listCertificados &&
              listCertificados
                .filter(item =>
                  isNotDateEnd
                    ? !item?.fadel?.certificate_due_date
                    : item?.fadel?.certificate_due_date,
                )
                .sort((itemA, itemB) => {
                  return (
                    new Date(itemA.fadel?.certificate_due_date) -
                    new Date(itemB.fadel?.certificate_due_date)
                  );
                })
                .map((item, index) => {
                  const { name, type, fadel } = item;
                  const hasDaysCertificate = hasCertificate({
                    value: fadel?.dias_vencidos,
                    isList: true,
                    date: fadel?.certificate_due_date,
                  });
                  return (
                    <SLocal.ListPfxContentItems
                      isDisabled={
                        !(fadel?.dias_vencidos || fadel?.dias_vencidos === 0)
                      }
                      onClick={() => {
                        if (
                          fadel?.dias_vencidos ||
                          fadel?.dias_vencidos === 0
                        ) {
                          handleSelected(item);
                        }
                      }}
                      key={index}
                    >
                      <SLocal.ListPfxNameDais>
                        {hasDaysCertificate.days}
                      </SLocal.ListPfxNameDais>
                      <SLocal.ListPfxName>
                        <h3>{name}</h3>
                      </SLocal.ListPfxName>
                      <SLocal.ListPfxButton>{type}</SLocal.ListPfxButton>
                    </SLocal.ListPfxContentItems>
                  );
                })}
          </SLocal.ListPfxContent>
        </SLocal.ListPfx>
      </>
    )
  );
};
