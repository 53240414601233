import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px;

  display: flex;
  gap: 24px;

  .column1 {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    max-width: 392px;

    .menu-wrapper {
      width: 100%;
      padding: 15px;
      background-color: white;
      border-radius: 5px;
      margin-bottom: 20px;

      .menu {
        list-style: none;

        .active {
          background-color: #f2f4f4;
        }

        .menu-option {
          border: 1px solid #bababa;
          padding: 8px 16px;
          font-size: 13px;
          color: #565656;
          transition: all 0.2s ease-in-out;
          cursor: pointer;

          &:hover {
            background-color: #f2f4f4;
          }
        }
      }
    }

    table {
      border-collapse: collapse;

      .table-head {
        border: 1px solid #bababa;

        .table-head-item {
          padding-top: 8px;
          padding-bottom: 8px;

          &:first-of-type {
            padding-left: 16px;
            padding-right: 24px;
          }

          &:last-of-type {
            padding-right: 16px;
            padding-left: 24px;
          }

          .table-head-content-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;

            p {
              color: #565656;
              font-weight: bold;
              font-size: 13px;
            }
          }
        }
      }

      .table-content {
        border: 1px solid #bababa;

        .table-content-item {
          padding-top: 8px;
          padding-bottom: 8px;

          &:first-of-type {
            padding-left: 16px;
            padding-right: 24px;
          }

          &:last-of-type {
            padding-right: 16px;
            padding-left: 24px;
          }

          p {
            color: #565656;
            font-size: 13px;
          }
        }
      }
    }

    .change-button {
      width: 100%;
      max-width: 145px;
      padding: 8px 12px;
      font-weight: bold;
      font-size: 15px;
      background-color: #5a8dee;
      color: #fff;
      outline: none;
      text-align: center;
      border: 0;
      border-radius: 5px;
      margin-top: 14px;
      cursor: pointer;
    }
  }

  .column2 {
    width: 100%;
    height: 100%;

    .full-container {
      width: 100%;
      height: 100%;

      .top-part {
        display: flex;
        justify-content: space-between;

        .customer-info {
          width: 100%;

          .input-group {
            display: flex;

            & > div:first-of-type {
              margin-right: 10px;
            }

            .input-wrapper {
              width: 100%;
            }
          }

          .input-wrapper {
            display: flex;
            flex-direction: column;

            span {
              padding-left: 10px;
              font-size: 14px;
              color: #596778;
            }

            input {
              width: 100%;
              outline: none;
              border: 1px solid #596778;
              border-radius: 5px;
              padding: 6px 12px;
              margin-bottom: 27px;
              color: #596778;
              font-size: 14px;
            }

            select {
              outline: none;
              border: 1px solid #596778;
              border-radius: 5px;
              width: 100%;
              padding: 6px 12px;
              max-width: 149px;
              margin-bottom: 27px;
            }
          }
        }

        .social-info {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          border-left: 1px solid #e5e5e5;
          border-right: 1px solid #e5e5e5;
          margin-left: 37px;
          margin-right: 24px;
          padding: 0 36px;

          .user-id {
            display: flex;
            width: 100%;
            justify-content: space-between;

            .profile-picture {
              p {
                color: #596778;
                font-size: 14px;
              }

              img {
                width: 96px;
                height: 96px;
                object-fit: cover;
                border-radius: 5px;
              }
            }

            .user-rating {
              p {
                color: #596778;
                font-size: 14px;
              }

              .rating-stars {
                .star {
                  margin-right: 5px;

                  &:last-of-type {
                    margin-right: 0;
                  }
                }
              }
            }
          }

          .user-social-media {
            width: 100%;

            .associated-account {
              margin-top: 28px;
              margin-bottom: 15px;

              p {
                color: #596778;
                font-size: 14px;
              }
            }

            .social-media-group {
              display: flex;
              flex-direction: column;

              .social-media-row {
                display: flex;
                margin-bottom: 15px;

                &:last-of-type {
                  margin-bottom: 0;
                }

                .social-media-item {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  border: 1px solid #4d6fa9;
                  padding-right: 8px;

                  margin-right: 16px;

                  &:last-of-type {
                    margin-right: 0;
                  }

                  .social-media-icon {
                    margin-right: 6px;
                  }

                  .social-media-name {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 14px;
                    font-weight: bold;
                    color: #1b66b0;
                  }
                }
              }
            }
          }
        }

        .token-info {
          display: flex;
          width: 100%;

          .token-content {
            width: 100%;
            display: flex;
            flex-direction: column;

            span {
              color: #596778;
              font-size: 14px;
              padding-left: 10px;
            }

            input {
              width: 100%;
              outline: none;
              border: 1px solid #596778;
              border-radius: 5px;
              padding: 6px 12px;
              margin-bottom: 27px;
              color: #596778;
              font-size: 14px;
            }
          }
        }
      }

      .bottom-part {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 30px;

        .save-button {
          width: 100%;
          max-width: 109px;
          padding: 5px;
          font-weight: bold;
          font-size: 15px;
          background-color: #5a8dee;
          color: #fff;
          outline: none;
          text-align: center;
          border: 0;
          border-radius: 5px;
          cursor: pointer;
        }
      }
    }
  }
`;
