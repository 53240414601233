import styled from "styled-components";

export const Tags = styled.div`
  display: flex;
  flex-flow: row wrap;
`;

export const TagItem = styled.div`
  margin: 8px 0 0 4px;
`;
