import styled, { css } from "styled-components";

export const Container = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-gap: ${theme.spacing.md};

    .visualizandoComo {
      display: none;
    }

    .breadCrumbs {
      display: none;
    }
  `}
`;
