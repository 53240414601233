import styled from "styled-components";

export const InputContainer = styled.div`
  display: flex;
  flex: ${props => (props.flex ? props.flex : 1)};
  flex-direction: column;
  margin-top: 12px;
  max-width: ${props => (props.maxWidth ? props.maxWidth : "290px")};

  & + & {
    margin-left: 12px;
  }

  label,
  input,
  select,
  textarea {
    font-size: 14px;
    color: #596778;
  }

  select {
    height: 36px;
    background-color: #fff;
  }

  input,
  select,
  textarea {
    border: 1px solid #596778;
    border-radius: 5px;
    padding: 4px 10px;
  }

  input:disabled {
    background: #e9e9e9;
  }

  label {
    margin-left: 10px;
  }
`;

export const StyledNorms = styled.div`
  ul {
    list-style: none;
  }
`;

export const ContainerCenter = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 8px;
`;

export const ContainerAction = styled.div`
  margin-right: 15px;
`;
