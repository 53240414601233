import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  MdDelete,
  MdClose,
  // MdRemoveRedEye,
  MdInsertChart,
  MdEdit,
} from "react-icons/md";
import { Tooltip } from "@mix/mixfiscal-designsystem";

import { useFlow } from "../../../../../context/FlowContext";

import { Container, HeaderContainer, ActionsContainer } from "./styles";

function AdvancedOperationNode({ id, data }) {
  const { label, isSelected } = data;
  const [selectedFields, setSelectedFields] = useState([]);

  const {
    handleRemoveNode,
    handleUpdateSelectedField,
    handleUpdateElementsPosition,
    handleSetCurrentAdvancedOperationId,
  } = useFlow();

  const history = useHistory();

  const handleOpenAdvancedOperationDialog = () => {
    handleSetCurrentAdvancedOperationId(id);
    handleUpdateElementsPosition();
    history.push("/editor-avancado");
  };

  const handleFieldSelection = (field, index) => {
    const tempSet = new Set(selectedFields);

    if (tempSet.has(field.name)) {
      tempSet.delete(field.name);
    } else {
      tempSet.add(field.name);
    }
    setSelectedFields([...tempSet]);
    handleUpdateSelectedField({
      id,
      fieldIndex: index,
      isSelected: !(selectedFields.length > 0),
    });
  };

  useEffect(() => {
    console.log(isSelected);
    if (isSelected) {
      setSelectedFields([data.name]);
    }
  }, [data.name, isSelected]);

  return (
    <Container className="nowheel">
      <HeaderContainer>
        <div>
          <MdInsertChart size={24} color="#fff" />
          <p>{label}</p>
        </div>
        <div
          className="buttonContainer"
          role="button"
          onClick={() => handleRemoveNode(id)}
        >
          <MdClose size={20} />
        </div>
      </HeaderContainer>
      <ActionsContainer>
        <div>
          <input
            type="checkbox"
            checked={
              selectedFields.length > 0 && selectedFields.includes(data.name)
            }
            onChange={() => handleFieldSelection(data, 0)}
          />
        </div>
        <div>{data.name && <p>{data.name}</p>}</div>
        <div>
          <Tooltip zIndex={999999} title="Editar Operação" position="top">
            <MdEdit
              size={24}
              color="#596778"
              onClick={handleOpenAdvancedOperationDialog}
            />
          </Tooltip>
          <Tooltip zIndex={999999} title="Remover Operação" position="top">
            <MdDelete
              size={24}
              color="#F44336"
              onClick={() => handleRemoveNode(id)}
            />
          </Tooltip>
        </div>
      </ActionsContainer>
      {/* <Handle
        id="firstHandle"
        type="target"
        position="top"
        style={{
          width: "30px",
          height: "30px",
          background: "#555",
          top: "-15px",
        }}
      />
      <Handle
        id="secondHandle"
        type="target"
        position="bottom"
        style={{
          width: "30px",
          height: "30px",
          background: "#555",
          bottom: "-15px",
        }}
      /> */}
    </Container>
  );
}

export default AdvancedOperationNode;
