import React from "react";

import { Tooltip } from "@mix/mixfiscal-designsystem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCertificate,
  faExclamationCircle,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";

import { useStoreContext } from "context/StoreContext";

import { CustomTooltip } from "./Tooltip";

import * as Sstore from "./styles";

export const StoreCertificado = ({ data, control, handleValue }) => {
  const { certificate, setCertificate } = useStoreContext();

  const handleCertificateUpload = event => {
    setCertificate(event);
  };

  const getDroppedCertificate = event => {
    setCertificate(event);
  };
  const active = value => {
    if (value && value.toString().substr(0, 1) !== `-`) {
      return {
        active: `inativo`,
      };
    }
    return {
      active: `ativo`,
    };
  };

  return (
    <>
      <Sstore.UploadFile>
        <label>Certificado A1</label>
        <Sstore.UploadFileRow>
          {certificate ? (
            <Sstore.UploadFileRowItem width="15%">
              <Sstore.InputFieldBtn
                type="button"
                onClick={() => {
                  handleValue();
                  setCertificate("");
                }}
              >
                {" "}
                REMOVER
              </Sstore.InputFieldBtn>
            </Sstore.UploadFileRowItem>
          ) : (
            ""
          )}
          <Sstore.UploadFileRowItem
            width={certificate && data?.fadel ? "75%" : "100%"}
          >
            <Sstore.InputFileItem
              type="file"
              name="certificate"
              accept=".pfx"
              control={control}
              onChange={e => handleCertificateUpload(e.target.files[0])}
              onDrop={e => getDroppedCertificate(e.dataTransfer.files[0])}
            />

            <Sstore.InputAbsolute isOver={certificate || false}>
              {!!certificate && (
                <Sstore.InputAbsoluteButton type="button">
                  PROCURAR
                </Sstore.InputAbsoluteButton>
              )}
              {certificate ? (
                <Sstore.InputAbsoluteFileDescription>
                  {certificate.name || certificate}
                </Sstore.InputAbsoluteFileDescription>
              ) : (
                <Sstore.InputAbsoluteFileDescription>
                  Clique ou Arraste aqui
                </Sstore.InputAbsoluteFileDescription>
              )}
            </Sstore.InputAbsolute>
          </Sstore.UploadFileRowItem>
          {certificate && data?.fadel ? (
            <Sstore.UploadFileRowItem width="10%">
              <Sstore.UploadFileRow>
                <Tooltip
                  isPopOver
                  component={<CustomTooltip data={data} />}
                  position="left-start"
                  title="Certificado"
                  zIndex={99999}
                >
                  <Sstore.Badge
                    type={active(data?.fadel?.dias_vencidos).active}
                  >
                    <FontAwesomeIcon
                      icon={
                        active(data?.fadel?.dias_vencidos).active === "inativo"
                          ? faExclamationCircle
                          : faCertificate
                      }
                      style={{
                        margin: "10px 0 0 5px",
                        fontSize: "12px",
                      }}
                      color="#fff"
                    />
                    <p>Certificado</p>
                  </Sstore.Badge>
                </Tooltip>
              </Sstore.UploadFileRow>
            </Sstore.UploadFileRowItem>
          ) : (
            <>
              {certificate ? (
                <Sstore.UploadFileRowItem width="10%">
                  <Sstore.UploadFileRow>
                    <Tooltip
                      position="left-start"
                      title="Válidando"
                      body="válidando dados com fadel."
                      zIndex={99999}
                    >
                      <Sstore.Badge type="inativo">
                        <FontAwesomeIcon
                          spin
                          icon={faSpinner}
                          style={{
                            margin: "10px 0 0 5px",
                            fontSize: "12px",
                          }}
                          color="#fff"
                        />
                        <p>Certificado</p>
                      </Sstore.Badge>
                    </Tooltip>
                  </Sstore.UploadFileRow>
                </Sstore.UploadFileRowItem>
              ) : (
                ``
              )}
            </>
          )}
        </Sstore.UploadFileRow>
      </Sstore.UploadFile>
    </>
  );
};
