import React from "react";
import moment from "moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faCircleChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import * as S from "./styles";

export const CustomTooltip = ({ data }) => {
  const active = value => {
    if (value && value.toString().substr(0, 1) !== `-`) {
      return {
        title: `Seu certificado venceu tem:`,
        active: false,
      };
    }
    return {
      title: `Seu certificado vence em:`,
      active: true,
    };
  };
  return (
    <>
      <S.Title>
        <S.TitleText>
          <h5>Certificados A1</h5>
        </S.TitleText>
      </S.Title>
      <S.ContainerTooltip>
        <h4>{active(data?.fadel?.dias_vencidos).title}</h4>
        <S.DaysCertified active={active(data?.fadel?.dias_vencidos).active}>
          {data?.fadel?.dias_vencidos ? (
            <h5>
              {data?.fadel?.dias_vencidos.toString().substr(0, 1) === `-`
                ? data?.fadel?.dias_vencidos.toString().substr(1)
                : `-${data?.fadel?.dias_vencidos}`}
            </h5>
          ) : (
            <h5>0</h5>
          )}

          <h6>dias</h6>
        </S.DaysCertified>
        <S.ContainerTooltipListUl>
          <S.ContainerTooltipListUlItem>
            <FontAwesomeIcon
              icon={faCalendar}
              style={{
                marginTop: "10px",
                fontSize: "12px",
              }}
              color="#adc86a"
            />
            {` Vencimento: ${moment(data?.fadel?.certificate_due_date).format(
              `DD/MM/YYYY h:mm`,
            )}`}
          </S.ContainerTooltipListUlItem>
          <S.ContainerTooltipListUlItem>
            <FontAwesomeIcon
              icon={faCircleChevronRight}
              style={{
                marginTop: "10px",
                fontSize: "12px",
              }}
              color="#adc86a"
            />
            {` Último NSU consultado: ${data?.fadel?.last_nsu}`}
          </S.ContainerTooltipListUlItem>
          <S.ContainerTooltipListUlItem>
            <FontAwesomeIcon
              icon={faCircleChevronRight}
              style={{
                marginTop: "10px",
                fontSize: "12px",
              }}
              color="#adc86a"
            />
            {` Máximo NSU consultado: ${data?.fadel?.max_nsu}`}
          </S.ContainerTooltipListUlItem>
        </S.ContainerTooltipListUl>
      </S.ContainerTooltip>
    </>
  );
};
