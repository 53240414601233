import styled, { css } from "styled-components";

export const ButtonAction = styled.button`
  ${({ theme }) => css`
    background-color: transparent;
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing.xs};
    transition: ease-in-out 0.3s;
    align-items: center;
    border-radius: 50%;

    &:hover:not([disabled]) {
      background-color: rgb(230 230 230);
    }

    &:disabled {
      opacity: 0.5;
      cursor: initial;
    }
  `}
`;

const buttonModifiers = {
  primary: ({ theme }) =>
    css`
      background-color: ${theme.colors.blueInfo};
      color: #fff;
    `,
  secondary: ({ theme }) => css`
    background-color: ${theme.colors.red};
    color: #fff;
  `,

  gray: ({ theme, scale = "50" }) => css`
    background-color: ${theme.colors[`gray${scale}`] ?? theme.colors.gray50};
    color: ${`gray${scale}` === "gray500" ? "#fff" : "#5d5454"};

    &:hover {
      color: #000;
    }
  `,

  lightGreen: ({ theme }) => css`
    background-color: ${theme.colors.lightGreen};
    color: #fff;

    &:hover {
      color: ${theme.colors.lightGreenHover};
    }
  `,

  darkGreen: ({ theme }) => css`
    background-color: ${theme.colors.darkGreen};
    color: #fff;
  `,

  sizeX2: () => css`
    height: auto;
    min-width: auto;
    padding: 0px 10px;
  `,
};

export const Button = styled.button`
  ${({ color, size, ...props }) => css`
    min-width: 109px;
    height: 38px;
    border-radius: 5px;
    transition: all 0.2s;

    text-transform: uppercase;
    font-size: 15px;
    font-weight: bold;
    padding: ${props.theme.spacing.sm};

    display: flex;
    align-items: center;
    justify-content: center;

    ${!color && buttonModifiers.primary(props)}
    ${color === "secondary" && buttonModifiers.secondary(props)}
    ${color === "gray" && buttonModifiers.gray(props)}
    ${color === "lightGreen" && buttonModifiers.lightGreen(props)}
    ${color === "darkGreen" && buttonModifiers.darkGreen(props)}

    ${size === "x2" && buttonModifiers.sizeX2(props)}
  `}

  ${props =>
    props.variant === "outline" &&
    css`
      background-color: #fff;
      color: ${props.color};
      border: 1px solid ${props.color};
    `}

  ${props =>
    props.variant === "round" &&
    css`
      min-width: 40px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
    `}

  &:hover, &:active {
    color: #fff;
  }

  ${props =>
    props.isDisabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;

export const ButtonContainerIcon = styled.div`
  padding-right: 3px;
  display: flex;
`;

export const ButtonAdd = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #adc86a;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 1px 6px #00000033;
  margin-right: unset;
  margin-top: 0;
  margin-left: 10px;
  color: ${({ color }) => color ?? "#fff"};
`;

export const ButtonRemove = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #8d8f88;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 1px 6px #00000033;
  margin-right: unset;
  margin-top: 0;
  margin-left: 10px;
`;

export const ButtonOpenNewTag = styled.button`
  background-color: transparent;
  justify-content: center;
  display: flex;
  margin-left: 10px;
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-right: 5px;
  margin-left: -5px;
`;
