import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  gap: 24px;
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const TopPart = styled.div`
  display: flex;
`;

export const ColumnFormItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const CollumForm = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 8px 5px 8px;
  flex: 1;

  ${ColumnFormItem} {
    &:not(:last-of-type) {
      margin-bottom: 10px;
    }
  }
`;

export const CollumFormCheckBox = styled.div`
  display: flex;
  flex-direction: column;

  section {
    width: 100%;
    margin-bottom: 20px;
    flex: 0;

    p {
      font-size: 14px;
      color: #596778;
      white-space: nowrap;
    }

    & > div {
      display: flex;
      align-items: center;
      gap: 12px;
    }

    .checkBox {
      display: flex;
      align-items: center;
      gap: 15px;
    }
  }

  .divider {
    width: 1px;
    align-self: stretch;
    background-color: #0000002e;
    margin: 0 21px;
  }
`;

export const SectionCheckbox = styled.section`
  width: 100%;
  margin-bottom: 20px;
  flex: 0;
`;

export const InputForm = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const SelectStyleStore = styled.div`
  width: 100%;
  .select-wrapper {
    width: 100%;
    display: flex;
    .select-width-80 {
      width: 95%;
    }
    button {
      margin-left: 20px;
    }
  }
`;

export const SelectWrapper = styled.div`
  width: 100%;
  display: flex;
`;

export const SelectWith = styled.div`
  width: ${props => (props.width ? props.width : `100%`)};
`;

export const SwitchContainer = styled.div`
  display: flex;
  & {
    margin: 0;
  }

  p {
    color: #596778;
    font-size: 14px;
  }

  .switch-text-wrapper {
    display: flex;
    flex-direction: column;

    p {
      line-height: 10px;
    }

    span {
      font-size: 10px;
      color: #596778;
    }
  }
`;

export const Divider = styled.div`
  width: 1px;
  background-color: #e0e0e0;
  height: 80%;
  align-self: center;
  margin: 0px 10px;
`;

export const Footer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 38px;
  justify-content: center;
`;
