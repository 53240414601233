import React, { useEffect, useState, useMemo } from "react";
import { Card, Select, Icon, DatePicker } from "@mix/mixfiscal-designsystem";
import { TagsAction } from "components/structure/Actions";
import useFields from "../../hooks/useFields";
import useCategories from "../../hooks/useCategories";
import * as S from "./styles";

const TaskFilters = ({ app, onSubmit }) => {
  const { fields } = useFields();
  const { categories } = useCategories();
  const [filters, setFilters] = useState({});
  const [fieldsData, setFieldsData] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);
  const [selectedField, setSelectedField] = useState({});

  useEffect(() => {
    const data = fields.map(f => ({ label: f.name, value: f._id }));
    setFieldsData(data);
  }, [fields]);

  useEffect(() => {
    const data = categories.map(f => ({ label: f.title, value: f._id }));
    setCategoriesData(data);
  }, [categories]);

  useMemo(() => {
    onSubmit(filters);
  }, [filters, onSubmit]);

  return (
    <Card
      headerColor={!app.appBusinessIntelligence ? "#256925" : "#17456B"}
      textColor="#FFF"
      title="FILTROS"
      titleIcon="filtro1"
    >
      <Card
        headerColor={!app.appBusinessIntelligence ? "#51b251" : "#2c6ca1"}
        textColor="#ffff"
        title="TAREFAS"
      >
        <S.Row>
          <Select
            color="#000"
            className="custom-select"
            data={fieldsData}
            handleChangeCallback={({ label, value }) => {
              setSelectedField({
                ...selectedField,
                id: value,
                label,
              });
            }}
            initialValue={{
              label: "Selecione",
              value: "",
            }}
            label="Buscar por"
            type="simple"
          />

          <input
            value={selectedField.value}
            onChange={e => {
              setSelectedField({
                ...selectedField,
                value: e.target.value,
              });
            }}
            type="text"
          />

          <div>
            <Icon
              color="#adc86a"
              name="adicionar"
              size={24}
              handleClick={() => {
                setFilters({
                  ...filters,
                  conditions: [
                    ...(filters.conditions || []),
                    {
                      field: selectedField.id,
                      label: `${selectedField.label}: ${selectedField.value}`,
                      value: selectedField.value,
                    },
                  ],
                });
              }}
            />
          </div>
        </S.Row>

        <S.Row>
          <TagsAction
            bodyTooltip="Remover todas as tags de pesquisar por"
            tags={filters?.conditions}
            handleDelete={c => {
              const index = filters?.conditions.findIndex(
                i => i.field === c.field,
              );
              const conditions = filters?.conditions;
              conditions.splice(index, 1);

              setFilters({
                ...filters,
                conditions,
              });
            }}
          />
        </S.Row>

        <div className="row">
          <DatePicker
            modelType="advanced"
            type="date"
            handleChangeCallback={value => {
              setFilters({
                ...filters,
                createdAt: {
                  ...filters.createdAt,
                  $gte: value,
                },
              });
            }}
          />
          <DatePicker
            modelType="advanced"
            type="date"
            handleChangeCallback={value => {
              setFilters({
                ...filters,
                createdAt: {
                  ...filters.createdAt,
                  $lt: value,
                },
              });
            }}
          />
        </div>

        <div className="row">
          <Select
            color="#000"
            className="custom-select"
            data={categoriesData}
            handleChangeCallback={({ value }) => {
              setFilters({
                ...filters,
                category: value,
              });
            }}
            initialValue={{
              label: "Selecione",
              value: "",
            }}
            label="Categoria"
            type="complex"
          />
        </div>
      </Card>
    </Card>
  );
};

export default TaskFilters;
