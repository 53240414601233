import React, { useEffect, useState, useRef } from "react";

import { useForm, FormProvider } from "react-hook-form";
import { WhatsApp } from "components/contexts/user";
import { StoreWhatsAppForm } from "components/contexts/stores";
import { useAssessorPainel } from "context/AssessorContext";
import { formatCurrency } from "utils/utils";
import { useApp } from "context/AppContext";

import * as S from "../../styles";
import * as Sw from "./WhatsAppDados.styles";

import { WhatsAppFormSend } from "./Form";
import { WhatsAppMessages } from "./WhasAppMessage";

export const WhatsAppDados = ({ selectedClient, user }) => {
  const { handleCopy } = useApp({});

  const { setWhatsAppNumber } = useAssessorPainel();
  const formMethods = useForm({
    defaultValues: {
      message: "",
    },
  });

  const refMessages = useRef(null);

  const { handleSubmit, reset, control } = formMethods;

  const [listMsg, setListMsg] = useState([]);
  const [isSendMsg, setIsSendMSg] = useState(false);
  const [showBtns, setshowBtns] = useState(false);

  useEffect(() => {
    if (user) {
      reset({
        ...user,
        message: "",
      });
    }
  }, [user, reset]);

  const client = selectedClient?.id_store;
  const hasClient = Boolean(client);

  return (
    <>
      <FormProvider {...formMethods}>
        <Sw.ContainerRowItemWhatsap key={selectedClient?.id_store?._id}>
          <Sw.ContainerRowItemHeaderWhatsapp>
            <Sw.ContainerRowItemHeaderWhatsappTitle>
              <Sw.ContainerRowItemHeaderWhatsappTitleItem>
                <h3>{hasClient ? "WhatsApp do Cliente" : "WhatsApp"}</h3>
              </Sw.ContainerRowItemHeaderWhatsappTitleItem>
              <Sw.ContainerRowItemHeaderWhatsappTitleItem
                cursor="pointer"
                margin="0 10px 0 0"
                align="right"
                onClick={() => {
                  handleCopy(user?.whatsAppNumber, "Telefone");
                }}
              >
                <h5>{`Telefone: ${user?.whatsAppNumber || "sem telefone"}`}</h5>
              </Sw.ContainerRowItemHeaderWhatsappTitleItem>
            </Sw.ContainerRowItemHeaderWhatsappTitle>
            <Sw.ContainerRowItemHeaderWhatsappTitleSub>
              {selectedClient?.id_store?.companyName ||
                selectedClient?.id_store?.tradeName ||
                selectedClient?.id_store?.namePartnerOne ||
                selectedClient?.id_store?.namePartnerTwo ||
                selectedClient?.id_store?.legalNature}
            </Sw.ContainerRowItemHeaderWhatsappTitleSub>
          </Sw.ContainerRowItemHeaderWhatsapp>
          <Sw.ContainerRowItemContentWhatsAppInfo>
            <span>
              {!hasClient
                ? "Selecione um cliente Ex: Arezzo"
                : `Faturamento atual: ${formatCurrency(
                    selectedClient?.id_store?.totalSales,
                  )} (${selectedClient?.id_store?.totalSalesPercent?.toFixed(
                    2,
                  )}%)`}
            </span>
          </Sw.ContainerRowItemContentWhatsAppInfo>
          <Sw.ContainerRowItemContentWhatsApp>
            <Sw.ContainerRowItemContentWhatsAppMessages ref={refMessages}>
              {!selectedClient?.id_store && (
                <S.ContainerRowItem>
                  <WhatsApp user={user} />
                </S.ContainerRowItem>
              )}
              {selectedClient?.id_store && (
                <WhatsAppMessages
                  list={selectedClient?.id_store.messages}
                  idCli={selectedClient?.id_store._id}
                  refContainer={refMessages}
                />
              )}
              {!client?.whatsapp && client && (
                <S.ContainerRowItem>
                  <StoreWhatsAppForm
                    idCli={client._id}
                    number={client.whatsapp}
                    onChangeSuccess={({ whatsapp }) =>
                      setWhatsAppNumber(client._id, whatsapp)
                    }
                  />
                </S.ContainerRowItem>
              )}
            </Sw.ContainerRowItemContentWhatsAppMessages>

            <WhatsAppFormSend
              isDisabled={!hasClient}
              handleSubmit={handleSubmit}
              selectedClient={selectedClient}
              listMsg={listMsg}
              setListMsg={setListMsg}
              showBtns={showBtns}
              setshowBtns={setshowBtns}
              isSendMsg={isSendMsg}
              setIsSendMSg={setIsSendMSg}
              user={user}
              control={control}
            />
          </Sw.ContainerRowItemContentWhatsApp>
        </Sw.ContainerRowItemWhatsap>
      </FormProvider>
    </>
  );
};
