import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import { useParams } from "react-router-dom";
import {
  useReports,
  useApikeyForm,
  useGetApi,
  useApiKeyRemove,
} from "useCases";
import { useACL } from "context";
import { convertKey } from "utils";
import { BtnRemove } from "components/contexts/shared";
import { Button } from "components/structure";

import {
  ApiKeyFormCustomReport,
  ApiKeyFormPermission,
} from "components/contexts/users-and-permissions/ApiKeys";

import Card from "components/UI/Card";

import * as S from "./styles";

export const CreateEditApiKeysContainer = ({ id }) => {
  const { hasEdit, hasCreate, hasRemove } = useACL("api-keys");
  const [tableNameValue, setTableNameValue] = useState("");

  const { handleSubmit, control, reset, setValue } = useForm();

  const [getRequest, setGetRequest] = useState(false);
  const [postRequest, setPostRequest] = useState(false);
  const [putRequest, setPutRequest] = useState(false);
  const [deleteRequest, setDeleteRequest] = useState(false);
  const [currentId, setCurrentId] = useState("");
  const [tableData, setTableData] = useState([]);
  const [isPublicOn, setIsPublicOn] = useState(false);
  const [key, setKey] = useState(null);

  const { data: dataApiKey } = useGetApi(id);

  const { onSave: onSaveApiKey, isLoading: isLoadingSend } = useApikeyForm({
    id,
  });

  const { isLoading: isLoadingReports, data: dataReports } = useReports({
    page: 1,
    limit: 9999,
  });

  const onSubmit = async fields => {
    const newTableData = tableData.map(tableItem => tableItem.name);
    const FormData = {
      name: fields.name,
      getRequest,
      postRequest,
      putRequest,
      deleteRequest,
      customReports: newTableData,
      isPublic: isPublicOn,
    };

    if (id) {
      FormData.key = fields.key;
    }
    onSaveApiKey(FormData);
  };

  useEffect(() => {
    if (dataApiKey && dataApiKey?.apiKeys) {
      reset({
        ...dataApiKey?.apiKeys,
      });
      setKey(dataApiKey?.apiKeys?.key);
      setValue("key", convertKey(dataApiKey?.apiKeys?.key));
      setGetRequest(dataApiKey?.apiKeys?.getRequest);
      setPostRequest(dataApiKey?.apiKeys?.postRequest);
      setPutRequest(dataApiKey?.apiKeys?.putRequest);
      setDeleteRequest(dataApiKey?.apiKeys?.deleteRequest);
      setTableData(
        dataApiKey?.apiKeys?.customReports?.map(report => {
          return { _id: uuidv4(), name: report };
        }),
      );
      setIsPublicOn(dataApiKey?.apiKeys?.isPublic);
    }
  }, [
    setKey,
    setValue,
    dataApiKey,
    reset,
    setGetRequest,
    setPutRequest,
    setPostRequest,
    setDeleteRequest,
  ]);

  return (
    <S.Container>
      <S.Section>
        <Card
          headerColor="#256925"
          title={id ? "EDITAR CHAVE API" : "CRIAR CHAVE API"}
          iconName="locker"
          action={
            id && (
              <BtnRemove
                isDisabled={!hasRemove}
                id={id}
                hook={useApiKeyRemove}
                module="API Key"
              />
            )
          }
        >
          <S.Form className="forms" onSubmit={handleSubmit(onSubmit)}>
            <S.TopPart className="top-part">
              <S.CollumForm>
                <ApiKeyFormCustomReport
                  control={control}
                  id={id}
                  keyValue={key}
                  setKey={setKey}
                  setValue={setValue}
                  isLoadingReports={isLoadingReports}
                  tableData={tableData}
                  setTableData={setTableData}
                  currentId={currentId}
                  setCurrentId={setCurrentId}
                  tableNameValue={tableNameValue}
                  setTableNameValue={setTableNameValue}
                  reports={dataReports?.reports}
                />
              </S.CollumForm>
              <S.Divider />
              <S.CollumForm>
                <ApiKeyFormPermission
                  control={control}
                  id={id}
                  key={key}
                  setKey={setKey}
                  setValue={setValue}
                  isLoadingReports={isLoadingReports}
                  tableData={tableData}
                  setTableData={setTableData}
                  currentId={currentId}
                  setCurrentId={setCurrentId}
                  tableNameValue={tableNameValue}
                  setTableNameValue={setTableNameValue}
                  reports={dataReports?.reports}
                  getRequest={getRequest}
                  postRequest={postRequest}
                  putRequest={putRequest}
                  deleteRequest={deleteRequest}
                  setGetRequest={setGetRequest}
                  setPutRequest={setPutRequest}
                  setPostRequest={setPostRequest}
                  setDeleteRequest={setDeleteRequest}
                />
              </S.CollumForm>
            </S.TopPart>
            <S.Footer>
              <Button
                goBack
                type="button"
                color="gray"
                style={{ marginRight: 15 }}
              >
                VOLTAR
              </Button>
              <Button
                isDisabled={!hasCreate || !hasEdit}
                isLoading={isLoadingSend}
                type="submit"
              >
                {id ? "SALVAR" : "CRIAR"}
              </Button>
            </S.Footer>
          </S.Form>
        </Card>
      </S.Section>
    </S.Container>
  );
};

export const CreateEditApiKeys = () => {
  const { id } = useParams();

  return <CreateEditApiKeysContainer id={id !== "new" && id} />;
};
