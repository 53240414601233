import React, { useEffect } from "react";
import Select from "react-select";
import { v4 as uuidv4 } from "uuid";
import { Tooltip } from "@mix/mixfiscal-designsystem";
import { FiPlus } from "react-icons/fi";
import { GrUpdate, GrCopy } from "react-icons/gr";
import { useToast } from "context/ToastContext";
import { TextField } from "components/form";
import { useApiKeyNewKey } from "useCases";
import { convertKey } from "utils";
import * as S from "../styles";

import { TableReports } from "./Table";

export const ApiKeyFormCustomReport = ({
  id,
  keyValue,
  isLoadingReports,
  tableData,
  setTableData,
  currentId,
  setCurrentId,
  reports,
  control,
  tableNameValue,
  setTableNameValue,
  setValue,
}) => {
  const {
    isLoading: isLoadingNewKey,
    data: dataNewKey,
    onSave: onSaveNewKey,
  } = useApiKeyNewKey({ id });
  const { addToast } = useToast();

  const addTableRow = () => {
    if (currentId) {
      const newTableData = tableData.filter(row => row._id !== currentId);
      setTableData([
        ...newTableData,
        {
          _id: currentId,
          name: tableNameValue,
        },
      ]);
      addToast({
        title: "Atualizar Item na Tabela",
        type: "success",
        description: `Item atualizado com sucesso!`,
      });
      setCurrentId("");
      setTableNameValue("");
    } else if (tableNameValue !== "") {
      setTableData([
        ...tableData,
        {
          _id: uuidv4(),
          name: tableNameValue,
        },
      ]);
      addToast({
        title: "Criar Item na Tabela",
        type: "success",
        description: `Item criado com sucesso!`,
      });
      setTableNameValue("");
    } else {
      addToast({
        title: "Criar Item na Tabela",
        type: "error",
        description: `
            Você precisa escolher um Relatório Personalizado para conseguir criar!
            `,
      });
      return null;
    }
  };

  const copyKey = item => {
    if (!id) {
      addToast({
        title: "Api Key",
        type: "error",
        description: `O campo de Api Key está vazio.`,
      });
      return;
    }
    navigator.clipboard.writeText(item);
    addToast({
      title: "Api Key",
      type: "success",
      description: "Api Key copiada com sucesso!",
    });
  };

  const renewKey = async () => {
    onSaveNewKey();
  };

  useEffect(() => {
    if (dataNewKey && !isLoadingNewKey) {
      setValue("key", convertKey(dataNewKey));
    }
  }, [dataNewKey, isLoadingNewKey, setValue]);

  return (
    <>
      <S.ColumnFormItem setMargin="10px 0 0 0">
        <S.InputForm>
          <label>Relatório Personalizado</label>
          <Select
            control={control}
            noOptionsMessage={() => "No Categories Found"}
            isLoading={isLoadingReports}
            name="tableName"
            options={reports?.docs?.map(item => ({
              value: item._id,
              label: item.name,
            }))}
            placeholder="Selecione"
            onChange={e => {
              const { value } = e;
              setTableNameValue(value);
              setValue("tableName", value);
            }}
          />
        </S.InputForm>
        <S.CollumFormSearch>
          <S.AddButton
            type="button"
            onClick={() => {
              addTableRow();
            }}
          >
            <Tooltip title="Adicionar Relatório Personalizado" position="top">
              <FiPlus size={22} color="white" className="icon" />
            </Tooltip>
          </S.AddButton>
        </S.CollumFormSearch>
      </S.ColumnFormItem>
      {isLoadingReports ? (
        <S.ColumnFormItem>Carregando Relatórios....</S.ColumnFormItem>
      ) : (
        <S.ColumnFormItem setMargin="20px 0 0 0">
          <TableReports reports={reports} tableData={tableData} />
        </S.ColumnFormItem>
      )}
      <S.ColumnFormItem>
        <TextField
          label="Chave API"
          placeholder="********************************"
          name="key"
          disabled={!id}
          control={control}
        />
        <S.CollumFormSearch>
          <GrCopy
            size={24}
            className="icon"
            onClick={() => copyKey(keyValue)}
          />
          <GrUpdate size={24} className="icon" onClick={renewKey} />
        </S.CollumFormSearch>
      </S.ColumnFormItem>
    </>
  );
};
