import React, { useState } from "react";
import { Handle } from "react-flow-renderer";

import { useFlow } from "../../../../../context/FlowContext";
import { Container } from "./styles";

function FindAndReplace({
  id,
  data: { label, parentId, findValue, replaceValue, ...rest },
}) {
  const [findInputValue, setFindInputValue] = useState(findValue ?? "");
  const [replaceInputValue, setReplaceInputValue] = useState(
    replaceValue ?? "",
  );
  const [isEditing, setIsEditing] = useState(false);

  const { handleUpdateNodeData } = useFlow();

  const handleUpdate = () => {
    const newData = {
      label,
      parentId,
      ...rest,
      findValue: findInputValue,
      replaceValue: replaceInputValue,
    };
    console.log(newData);

    handleUpdateNodeData({ id, data: newData, parentId });
    setIsEditing(false);
  };

  return (
    <>
      <Container isEditing={isEditing}>
        <p>{label}</p>
        <label>Procurar:</label>
        <input
          value={findInputValue}
          onChange={e => setFindInputValue(e.target.value)}
          readOnly={!isEditing}
        />
        <label>Substituir:</label>
        <input
          value={replaceInputValue}
          onChange={e => setReplaceInputValue(e.target.value)}
          readOnly={!isEditing}
        />
        {isEditing ? (
          <button type="button" onClick={handleUpdate}>
            Salvar
          </button>
        ) : (
          <button type="button" onClick={() => setIsEditing(true)}>
            Editar
          </button>
        )}
      </Container>
      <Handle
        id="firstHandle"
        type="target"
        position="top"
        style={{
          width: "10px",
          height: "10px",
          background: "#7b867e",
          top: "-5px",
        }}
      />
      {/* <Handle
        id="secondHandle"
        type="target"
        position="right"
        style={{
          width: "10px",
          height: "10px",
          background: "#7b867e",
          right: "-20px",
        }}
      /> */}
      <Handle
        type="source"
        position="bottom"
        style={{
          width: "10px",
          height: "10px",
          background: "#0e7c34",
          bottom: "-5px",
        }}
      />
    </>
  );
}

export default FindAndReplace;
