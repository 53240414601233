import { useState } from "react";
import {
  useFetchAuthenticate,
  useAPIAuthenticate,
  useToast,
  useAPIAuthenticateToast,
} from "hooks";
import { getURLWithParams } from "utils/context";

export const useLaws = (params = {}) =>
  useFetchAuthenticate(getURLWithParams(`/laws`, params));

const cnaesFormat = cnaes =>
  cnaes?.map(cnae => ({
    value: cnae._id,
    codigo: cnae.cnae,
    label: `${cnae.cnae} - ${cnae.activity}`,
  }));

export const useLawsCnaes = (params = {}) => {
  const { data, isLoading } = useFetchAuthenticate(
    getURLWithParams(`/laws/cnae`, params),
  );

  return {
    data,
    isLoading,
    dataFormatted: cnaesFormat(data?.cnaes ?? []),
  };
};

const getEndpoint = (id, isNew = false) => {
  if (isNew && id) {
    return isNew && id ? `/laws/update-new/${id}` : `/laws-new`;
  }

  return id ? `/laws/${id}` : "/laws";
};

export const useLaw = id => {
  const [isLoading, setIsLoading] = useState(false);
  const api = useAPIAuthenticate();
  const law = useFetchAuthenticate(id && `/laws/${id}`);
  const { addToast } = useToast();

  const onSave = async (values = {}, isNew = false) => {
    try {
      setIsLoading(true);

      const { data } = await (id
        ? api.put(getEndpoint(id, isNew), values)
        : api.post(getEndpoint(false, isNew), values));

      if (data.response) {
        addToast({
          type: "success",
          title: "SUCESSO",
          description: `NORMA ${id ? "ATUALIZADA" : "CADASTRADA"} COM SUCESSO!`,
        });
      }
      setIsLoading(false);

      return data;
    } catch (e) {
      addToast({
        type: "error",
        title: "FALHA",
        description: "FALHA AO CADASTRAR NORMA!",
      });
      setIsLoading(false);
    }
  };

  return {
    ...law,
    onSave,
    isLoading,
  };
};

export const useLawsGradeArchive = id => {
  const { onSend: onSendApi, isLoading } = useAPIAuthenticateToast();

  const onSend = async () =>
    onSendApi({
      endpoint: `${id}/laws/grade/archive`,
      messageSuccess: `Lei/Grade ARQUIVADA COM SUCESSO!`,
      messageFail: "FALHA AO ARQUIVA LEI/GRADE!",
    });

  return {
    isLoading,
    onSend,
  };
};

export const useLawsActions = id => {
  const { onSend: onSendApi, isLoading } = useAPIAuthenticateToast();

  const onSendArchive = () =>
    onSendApi({
      endpoint: `${id}/laws/archive`,
      messageSuccess: `Lei/Grade ARQUIVADA COM SUCESSO!`,
      messageFail: "FALHA AO ARQUIVA LEI/GRADE!",
    });

  const onSendRemove = () =>
    onSendApi({
      type: "DELETE",
      endpoint: `/laws/${id}`,
      messageSuccess: `Lei/Grade REMOVIDA COM SUCESSO!`,
      messageFail: "FALHA AO REMOVER LEI/GRADE!",
    });

  const onSendClone = () =>
    onSendApi({
      endpoint: `${id}/laws/clone`,
      messageSuccess: `Lei/Grade CLONADA COM SUCESSO!`,
      messageFail: "FALHA AO TENTAR CLONAR LEI/GRADE!",
    });

  const onSendActive = () =>
    onSendApi({
      endpoint: `${id}/laws/active`,
      messageSuccess: `Lei/Grade ATIVADA COM SUCESSO!`,
      messageFail: "FALHA AO TENTAR ATIVAR LEI/GRADE!",
    });

  const onSendInactive = () =>
    onSendApi({
      endpoint: `${id}/laws/inactive`,
      messageSuccess: `Lei/Grade INATIVADA COM SUCESSO!`,
      messageFail: "FALHA AO TENTAR INATIVAR LEI/GRADE!",
    });

  const onSendRecovery = () =>
    onSendApi({
      endpoint: `/laws/recovery/${id}`,
      messageSuccess: `Lei/Grade RESTAURADA COM SUCESSO!`,
      messageFail: "FALHA AO TENTAR RESTAURADA LEI/GRADE!",
    });

  return {
    isLoading,
    onSendArchive,
    onSendRemove,
    onSendClone,
    onSendActive,
    onSendInactive,
    onSendRecovery,
  };
};

export const useLawsGradeRemove = id => {
  const { onSend: onSendApi, isLoading } = useAPIAuthenticateToast();

  const onSend = async () =>
    onSendApi({
      endpoint: `${id}/laws/grade/remove`,
      messageSuccess: `Lei/Grade REMOVIDO com sucesso!`,
      messageFail: "FALHA AO REMOVER GRADE/LEI!",
    });

  return {
    isLoading,
    onSend,
  };
};

export const useLawsGradeAction = id => {
  const { isLoading, onSend: onSendApi } = useAPIAuthenticateToast();

  const onArchiveGrade = async () =>
    onSendApi({
      endpoint: `${id}/laws/grade/archive`,
      messageSuccess: `Lei/Grade ARQUIVADA COM SUCESSO!`,
      messageFail: "FALHA AO ARQUIVA LEI/GRADE!",
    });
  const onActiveGrade = async () =>
    onSendApi({
      endpoint: `${id}/laws/grade/archive`,
      messageSuccess: `Lei/Grade ARQUIVADA COM SUCESSO!`,
      messageFail: "FALHA AO ARQUIVA LEI/GRADE!",
    });

  const onRemoveGrade = async () =>
    onSendApi({
      endpoint: `${id}/laws/grade/remove`,
      messageSuccess: `Lei/Grade REMOVIDO com sucesso!`,
      messageFail: "FALHA AO REMOVER GRADE/LEI!",
    });

  const onSyncLawsGradesProducts = async (data = {}) =>
    onSendApi({
      endpoint: "/laws/grade-sync",
      messageSuccess: "Leis/Grades sincronização de produtos",
      messageFail: "FALHA ao tentar sincronizar produtos",
      data,
    });

  return {
    isLoading,
    onArchiveGrade,
    onActiveGrade,
    onRemoveGrade,
    onSyncLawsGradesProducts,
  };
};
