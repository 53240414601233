import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 735px;
  gap: 20px;
`;

export const ItemContainer = styled.div`
  cursor: pointer;
  background-color: #f2f4f4;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  flex-shrink: 0;
  width: 160px;

  svg {
    margin-bottom: 8px;
  }

  p {
    color: #596778;
    font-weight: bold;
    text-align: center;
  }
`;
