import styled, { css } from "styled-components";

const columnFormItemModifiers = {
  setColor: item => css`
    color: ${item} !important;
  `,
};

export const Button = styled.button`
  ${({ color }) => css`
    color: #fff;
    width: 20px;
    height: 20px;
    margin-top: 0;
    margin-right: unset;
    margin-left: 10px;
    border-radius: 50%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    background-color: transparent;
    ${color && columnFormItemModifiers.setColor(color)}
  `}
`;
