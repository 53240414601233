import axios from "axios";

const templatesApi = axios.create({
  baseURL:
    process.env.REACT_APP_TEMPLATE ||
    "https://templates-builder.mixfiscal.com.br/api",
});

templatesApi.interceptors.request.use(
  config => {
    config.headers.Authorization = window.localStorage.getItem(
      "@mixfiscal:templateToken",
    );
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

export default templatesApi;
