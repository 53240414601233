import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faBookOpenReader } from "@fortawesome/free-solid-svg-icons";
import { useNotifyPermission } from "context/NotificationContext";
import Icon from "../../IconExporter";
import { Container, ContainerDescription } from "./styles";
// export type AlertProps = {
//   text: string;
//   type?: "normal" | "secondary" | "success" | "error" | "alert" | "info" | "alternative";
// }
const Alert = ({
  align = `normal`,
  title = "",
  text = "asdasdas",
  type = "normal",
  handleClose,
}) => {
  const iconDictonary = {
    alert: "warning",
    normal: "star",
    alternative: "star",
    success: "like",
    secondary: "link",
    error: "notification",
    info: "ticket",
    notify: "notify",
  };

  const { isShowNotify, setIsShowNotify } = useNotifyPermission({});
  const handleOpenNotification = () => {
    setIsShowNotify(!isShowNotify);
    handleClose();
  };
  return (
    <Container
      onClick={() => {
        if (iconDictonary[type] === `notify`) {
          handleOpenNotification();
        }
      }}
      align={align}
      type={type}
      style={{
        cursor: iconDictonary[type] === `notify` ? `pointer` : ``,
      }}
    >
      {iconDictonary[type] === `notify` ? (
        <FontAwesomeIcon
          icon={faBell}
          size="lg"
          style={{
            fontSize: "20px",
          }}
          color="#fff"
        />
      ) : (
        <Icon name={iconDictonary[type]} size={18} />
      )}

      {title ? (
        <ContainerDescription type={type}>
          <h3>{title}</h3>
          <p>{text}</p>
        </ContainerDescription>
      ) : (
        <p>{text}</p>
      )}
      {iconDictonary[type] === `notify` ? (
        <FontAwesomeIcon
          icon={faBookOpenReader}
          size="lg"
          style={{
            cursor: `pointer`,
            fontSize: "22px",
          }}
          color="#fff"
          onClick={() => handleOpenNotification()}
        />
      ) : (
        <Icon
          name="close2"
          size={18}
          color="#000"
          className="close"
          onClick={handleClose}
        />
      )}
    </Container>
  );
};

export default Alert;
