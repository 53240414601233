import React from "react";
import { Icon } from "@mix/mixfiscal-designsystem";
import { GoChecklist } from "react-icons/go";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHandHoldingDollar,
  faBarcode,
  faClipboardList,
  faReceipt,
  faCubes,
  faChalkboardUser,
  faGavel,
  faFileWaveform,
  faFileLines,
  faChartLine,
  faUserGroup,
  faSliders,
  faDiagramProject,
  faMobile,
  faPlug,
  faComments,
  faCodeBranch,
  faStore,
  faKey,
  faUser,
  faPeopleRoof,
} from "@fortawesome/free-solid-svg-icons";
import { MENU_MAIN_TYPES, MENU_PATHS, MENU_MAIN_TYPES_COMERCIAL } from "./main";

// Menus secundarios
export const MENUS_SUBS = [
  {
    parent: MENU_MAIN_TYPES.CHECKLINK,
    link: `/${MENU_PATHS.CHECKLINK}`,
    title: "Templates",
    slug: "my-templates",
    icon: (
      <FontAwesomeIcon
        icon={faCubes}
        size="lg"
        style={{
          fontSize: "1.5rem",
        }}
        color="#fff"
      />
    ),
  },
  {
    parent: MENU_MAIN_TYPES.DIAGNOSTICO_FISCAL,
    link: `/${MENU_PATHS.DIAGNOSTICO_FISCAL}/pis`,
    title: "Diagnóstico",
    slug: "diagnosis-pis-cofins",
    icon: (
      <FontAwesomeIcon
        icon={faCubes}
        size="lg"
        style={{
          fontSize: "1.5rem",
        }}
        color="#fff"
      />
    ),
  },
  {
    parent: MENU_MAIN_TYPES.DIAGNOSTICO_FISCAL,
    link: `/${MENU_PATHS.DIAGNOSTICO_FISCAL}/xml-relatorio`,
    title: "Relatorio XML",
    slug: "xml-reports",
    icon: (
      <FontAwesomeIcon
        icon={faFileWaveform}
        size="lg"
        style={{
          fontSize: "1.5rem",
        }}
        color="#fff"
      />
    ),
  },
  {
    parent: MENU_MAIN_TYPES.CHECKLINK,
    link: `/${MENU_PATHS.CHECKLINK}/tasks`,
    title: "Tarefas",
    slug: "tasks",
    icon: (
      <FontAwesomeIcon
        icon={faChalkboardUser}
        size="lg"
        style={{
          fontSize: "1.5rem",
        }}
        color="#fff"
      />
    ),
  },
  // TODO: LINKS PRODUTOS
  {
    parent: MENU_MAIN_TYPES.GERENCIAMENTO_PRODUTOS,
    link: `/${MENU_PATHS.GERENCIAMENTO_PRODUTOS}`,
    title: "Cadastro de Produtos",
    slug: "insert-product",
    icon: (
      <FontAwesomeIcon
        icon={faBarcode}
        size="lg"
        style={{
          fontSize: "1.5rem",
        }}
        color="#fff"
      />
    ),
  },
  {
    parent: MENU_MAIN_TYPES.GERENCIAMENTO_PRODUTOS,
    link: `/${MENU_PATHS.GERENCIAMENTO_PRODUTOS}/relatorios`,
    title: "Relatório de Produtos",
    slug: "reports-product",
    icon: (
      <FontAwesomeIcon
        icon={faClipboardList}
        size="lg"
        style={{
          fontSize: "1.5rem",
        }}
        color="#fff"
      />
    ),
  },
  // TODO: GRADE E RELATORIO DE GRADE
  {
    parent: MENU_MAIN_TYPES.CLASSIFICACAO_FISCAL,
    link: `/${MENU_PATHS.GRADE}`,
    title: "Grade tributária",
    slug: "tax-grid",
    icon: (
      <FontAwesomeIcon
        icon={faGavel}
        size="lg"
        style={{
          fontSize: "1.5rem",
        }}
        color="#fff"
      />
    ),
  },
  {
    parent: MENU_MAIN_TYPES.CLASSIFICACAO_FISCAL,
    link: `/relatorio/observacoes`,
    title: "Relatório Observação",
    slug: "observation-report",
    icon: (
      <FontAwesomeIcon
        icon={faFileWaveform}
        size="lg"
        style={{
          fontSize: "1.5rem",
        }}
        color="#fff"
      />
    ),
  },
  {
    parent: MENU_MAIN_TYPES.CLASSIFICACAO_FISCAL,
    link: `/${MENU_PATHS.CLASSIFICACAO_FISCAL}/ncms`,
    title: "Gerenciamento NCM's",
    slug: "ncms-management",
    icon: (
      <FontAwesomeIcon
        icon={faClipboardList}
        size="lg"
        style={{
          fontSize: "1.5rem",
        }}
        color="#fff"
      />
    ),
  },
  {
    parent: MENU_MAIN_TYPES.CLASSIFICACAO_FISCAL,
    link: `/${MENU_PATHS.CLASSIFICACAO_FISCAL}/norms`,
    title: "Gerenciamento de Normas/Leis",
    slug: "laws-norms-management",
    icon: (
      <FontAwesomeIcon
        icon={faClipboardList}
        size="lg"
        style={{
          fontSize: "1.5rem",
        }}
        color="#fff"
      />
    ),
  },
  {
    parent: MENU_MAIN_TYPES.CLASSIFICACAO_FISCAL,
    link: `/${MENU_PATHS.CLASSIFICACAO_FISCAL}/cests`,
    title: "Gerenciamento CEST's",
    slug: "cests-management",
    icon: (
      <FontAwesomeIcon
        icon={faClipboardList}
        size="lg"
        style={{
          fontSize: "1.5rem",
        }}
        color="#fff"
      />
    ),
  },
  {
    parent: MENU_MAIN_TYPES.CLASSIFICACAO_FISCAL,
    link: `/${MENU_PATHS.LEIS}/grid`,
    title: "Lei",
    disabled: true,
  },
  {
    parent: MENU_MAIN_TYPES.PAGINAS_INTELIGENTES,
    link: `/${MENU_PATHS.PAGINAS_INTELIGENTES}`,
    title: "Páginas",
    slug: "pages",
    icon: (
      <FontAwesomeIcon
        icon={faFileLines}
        size="lg"
        style={{
          fontSize: "1.5rem",
        }}
        color="#fff"
      />
    ),
  },
  {
    parent: MENU_MAIN_TYPES.MONITORAMENTO_DOCUMENTOS_FISCAIS,
    link: `/${MENU_PATHS.NFE}/nota-fiscal/lista-de-notas`,
    title: "Movimento",
    slug: "movement",
    icon: (
      <FontAwesomeIcon
        icon={faReceipt}
        size="lg"
        style={{
          fontSize: "1.5rem",
        }}
        color="#fff"
      />
    ),
  },
  {
    parent: MENU_MAIN_TYPES.MONITORAMENTO_DOCUMENTOS_FISCAIS,
    link: `/${MENU_PATHS.NFE}/nota-fiscal/entradas`,
    title: "Notas para entrada",
    slug: "notes-for-entry",
    icon: (
      <FontAwesomeIcon
        icon={faCodeBranch}
        size="lg"
        style={{
          fontSize: "1.5rem",
        }}
        color="#fff"
      />
    ),
  },
  {
    parent: MENU_MAIN_TYPES.DIAGNOSTICO,
    link: `/diagnosticos`,
    title: "Pis/Cofins",
    icon: (
      <FontAwesomeIcon
        icon={faCodeBranch}
        size="lg"
        style={{
          fontSize: "1.5rem",
        }}
        color="#fff"
      />
    ),
  },
  {
    parent: MENU_MAIN_TYPES.CLASSIFICACAO_FISCAL,
    link: `/${MENU_PATHS.LEIS}/gerenciar-leis`,
    title: "Lei",
    disabled: true,
  },
  // Ainda nao ativo
  {
    link: "/404",
    parent: MENU_MAIN_TYPES.ATRIBUTOS_GRADE,
    title: "Ncm - Tabela Tipi",
  },
  {
    link: "/404",
    parent: MENU_MAIN_TYPES.ATRIBUTOS_GRADE,
    title: "Código Situação Tributária",
  },
  {
    link: "/404",
    parent: MENU_MAIN_TYPES.ATRIBUTOS_GRADE,
    title: "Cenário Compra e Venda",
  },
  {
    link: "/404",
    parent: MENU_MAIN_TYPES.ATRIBUTOS_GRADE,
    title: "Legislação",
  },
  {
    link: "/404",
    parent: MENU_MAIN_TYPES.ATRIBUTOS_GRADE,
    title: "Alíquotas e Carga Tributária",
  },
  {
    link: "/404",
    parent: MENU_MAIN_TYPES.ATRIBUTOS_GRADE,
    title: "Efeito tributário sobre sku",
  },
  {
    link: "/404",
    parent: MENU_MAIN_TYPES.ATRIBUTOS_GRADE,
    title: "Características não tributáveis",
  },
  {
    link: "/404",
    parent: MENU_MAIN_TYPES.ATRIBUTOS_GRADE,
    title: "Resultados Grade Tributária",
  },

  // TODO: CONFIGURAÇÕES LOJA/USUARIOS/GRUPOS DE ACESSO E CHAVES DE API
  {
    parent: MENU_MAIN_TYPES.USUARIOS_PERMISSIOES,
    link: `/${MENU_PATHS.USUARIOS_PERMISSIOES}`,
    title: "Usuarios",
    slug: "users",
    icon: (
      <FontAwesomeIcon
        icon={faUserGroup}
        size="lg"
        style={{
          fontSize: "1.5rem",
        }}
        color="#fff"
      />
    ),
  },
  {
    parent: MENU_MAIN_TYPES.USUARIOS_PERMISSIOES,
    link: `/${MENU_PATHS.USUARIOS_PERMISSIOES}/lojas`,
    title: "Lojas",
    slug: "lojas",
    icon: (
      <FontAwesomeIcon
        icon={faStore}
        size="lg"
        style={{
          fontSize: "1.5rem",
        }}
        color="#fff"
      />
    ),
  },
  {
    parent: MENU_MAIN_TYPES.USUARIOS_PERMISSIOES,
    link: `/${MENU_PATHS.USUARIOS_PERMISSIOES}/config-params`,
    title: "Configurador de parametro",
    slug: "parameter-configurator",
    icon: (
      <FontAwesomeIcon
        icon={faSliders}
        size="lg"
        style={{
          fontSize: "1.5rem",
        }}
        color="#fff"
      />
    ),
  },
  {
    parent: MENU_MAIN_TYPES.USUARIOS_PERMISSIOES,
    link: `/${MENU_PATHS.USUARIOS_PERMISSIOES}/chaves-api`,
    title: "Chaves api",
    slug: "api-keys",
    icon: (
      <FontAwesomeIcon
        icon={faKey}
        size="lg"
        style={{
          fontSize: "1.5rem",
        }}
        color="#fff"
      />
    ),
  },
  {
    parent: MENU_MAIN_TYPES.USUARIOS_PERMISSIOES,
    link: `/${MENU_PATHS.USUARIOS_PERMISSIOES}/grupos-de-acesso`,
    title: "Grupos de acesso",
    slug: "access-group",
    icon: (
      <FontAwesomeIcon
        icon={faUser}
        size="lg"
        style={{
          fontSize: "1.5rem",
        }}
        color="#fff"
      />
    ),
  },
  {
    parent: MENU_MAIN_TYPES.USUARIOS_PERMISSIOES,
    link: `/${MENU_PATHS.USUARIOS_PERMISSIOES}/convidar-usuario`,
    title: "Convidar Usuário",
    slug: "invite-users",
    icon: (
      <FontAwesomeIcon
        icon={faUser}
        size="lg"
        style={{
          fontSize: "1.5rem",
        }}
        color="#fff"
      />
    ),
  },
  {
    parent: MENU_MAIN_TYPES.USUARIOS_PERMISSIOES,
    link: `/assessores`,
    title: "Assessores",
    slug: "assessores",
    icon: (
      <FontAwesomeIcon
        icon={faPeopleRoof}
        size="lg"
        style={{
          fontSize: "1.5rem",
        }}
        color="#fff"
      />
    ),
  },
  {
    parent: MENU_MAIN_TYPES.REPORT,
    link: "/relatorio/oportunidade-negociacao",
    title: "Oportunidade Negociação",
    slug: 'trading-opportunity"',
    icon: (
      <FontAwesomeIcon
        icon={faHandHoldingDollar}
        size="lg"
        style={{
          fontSize: "1.5rem",
        }}
        color="#fff"
      />
    ),
  },
  {
    parent: MENU_MAIN_TYPES.REPORT,
    link: "/relatorio/abc",
    title: "Relatório ABC",
    slug: "abc-reports",
    icon: (
      <FontAwesomeIcon
        icon={faChartLine}
        size="lg"
        style={{
          fontSize: "1.5rem",
        }}
        color="#fff"
      />
    ),
  },
  {
    parent: MENU_MAIN_TYPES.SUPORTE,
    link: "/robo",
    title: "Aplicativo MIXFISCAL",
    slug: "app-mixfiscal",
    icon: (
      <FontAwesomeIcon
        icon={faDiagramProject}
        size="lg"
        style={{
          fontSize: "1.5rem",
        }}
        color="#fff"
      />
    ),
  },
  {
    parent: MENU_MAIN_TYPES.SUPORTE,
    link: "/404",
    title: "Aplicativo Mobile",
    slug: "app-mobile",
    icon: (
      <FontAwesomeIcon
        icon={faMobile}
        size="lg"
        style={{
          fontSize: "1.5rem",
        }}
        color="#fff"
      />
    ),
  },
  {
    parent: MENU_MAIN_TYPES.SUPORTE,
    link: "/404",
    title: "Status do serviço",
    slug: "service-status",
    icon: (
      <FontAwesomeIcon
        icon={faPlug}
        size="lg"
        style={{
          fontSize: "1.5rem",
        }}
        color="#fff"
      />
    ),
  },
  {
    parent: MENU_MAIN_TYPES.SUPORTE,
    link: "/404",
    title: "Precisa de Ajuda?",
    slug: "help-my",
    icon: (
      <FontAwesomeIcon
        icon={faComments}
        size="lg"
        style={{
          fontSize: "1.5rem",
        }}
        color="#fff"
      />
    ),
  },
  // TODO: INTELIGENCIA COMERCIAL
  {
    parent: MENU_MAIN_TYPES_COMERCIAL.DASHBOARD,
    link: `/`,
    title: "Dashboard",
    icon: <GoChecklist size={30} color="#fff" />,
  },
  {
    parent: MENU_MAIN_TYPES_COMERCIAL.FORNECEDORES,
    link: `/fornecedores`,
    title: "Fornecedores",
    slug: "suppliers",
    icon: <Icon name="fornecedor" size={30} color="#fff" />, //
  },
  {
    parent: MENU_MAIN_TYPES_COMERCIAL.CHECKLINK_COMERCIAL,
    link: `/${MENU_PATHS.CHECKLINK}`,
    title: "Templates",
    slug: "my-templates",
    icon: (
      <FontAwesomeIcon
        icon={faCubes}
        size="lg"
        style={{
          fontSize: "1.5rem",
        }}
        color="#fff"
      />
    ),
  },
  {
    parent: MENU_MAIN_TYPES.CHECKLINK,
    link: `/${MENU_PATHS.CHECKLINK}/tasks`,
    title: "Tarefas",
    slug: "tasks",
    icon: (
      <FontAwesomeIcon
        icon={faChalkboardUser}
        size="lg"
        style={{
          fontSize: "1.5rem",
        }}
        color="#fff"
      />
    ),
  },
];
