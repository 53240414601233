import React from "react";
import { Icon, Tag, Tooltip } from "@mix/mixfiscal-designsystem";
import { ButtonAction } from "components/structure";

import * as S from "./TagsAction.styles";

export const TagsAction = ({
  tags = [],
  bodyTooltip,
  handleDeleteAll = () => {},
  handleDelete = () => {},
}) => {
  const handleDeleteLocal = tag => () => handleDelete(tag);

  if (!tags?.length) {
    return null;
  }

  return (
    <S.RowTagsName>
      {tags?.map(tag => (
        <Tag
          label={tag?.label}
          key={tag?.label}
          handleDelete={handleDeleteLocal(tag)}
          type="advanced"
        />
      ))}
      {tags?.length > 0 && (
        <S.ActionRemove>
          <Tooltip body={bodyTooltip} position="top" zIndex={1000}>
            <ButtonAction onClick={handleDeleteAll}>
              <Icon color="#000" name="fechar1" size={18} />
            </ButtonAction>
          </Tooltip>
        </S.ActionRemove>
      )}
    </S.RowTagsName>
  );
};
