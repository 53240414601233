import React, { useState, useEffect } from "react";

import { MdAdd } from "react-icons/md";
import {
  Button,
  DialogConfirm,
  ButtonContainerIcon,
} from "components/structure";

export const BtnAdd = ({
  id,
  hook,
  textBtn,
  FormContent,
  isDisabled = false,
}) => {
  const { onAdd, isLoading } = hook();
  const [data, setData] = useState({});

  const [dialogConfirm, setDialogConfirm] = useState(false);

  const handleConfirm = () => {
    onAdd(id, data);
    setDialogConfirm(false);
  };

  const handleClickConfirm = () => setDialogConfirm(true);

  const handleActionCancel = () => setDialogConfirm(null);

  useEffect(
    () => () => {
      setData({});
    },
    [],
  );

  return (
    <>
      <DialogConfirm
        title={`Novo ${textBtn}`}
        isOpen={Boolean(dialogConfirm)}
        handleConfirm={handleConfirm}
        handleCancel={handleActionCancel}
        content={FormContent && <FormContent data={data} setData={setData} />}
      />
      <Button
        type="button"
        isDisabled={isDisabled}
        isLoading={isLoading}
        size="x2"
        onClick={handleClickConfirm}
      >
        <ButtonContainerIcon>
          <MdAdd />
        </ButtonContainerIcon>
        {`Novo ${textBtn}`}
      </Button>
    </>
  );
};
