import React from "react";

import { Container, CustomButton } from "./styles";

function Button({ children, type = "primary", icon: Icon, onClick }) {
  return (
    <Container type={type} onClick={onClick}>
      {Icon && <Icon />}
      <CustomButton type="button" buttonType={type}>
        {children}
      </CustomButton>
    </Container>
  );
}

export default Button;
