import React from "react";
import Card from "components/UI/Card";

import * as S from "./styles";

export const StatusService = () => {
  return (
    <S.Container>
      <Card title="Status de serviço" headerColor="#256925" iconName="erp">
        <S.Iframe
          src="http://status.mixfiscal.com.br/status/server-old"
          title="Iframe Example"
        />
      </Card>
    </S.Container>
  );
};
