import styled, { css } from "styled-components";

const columnFormItemModifiers = {
  setMargin: item => css`
    margin: ${item} !important;
  `,
  setAlign: align => css`
    justify-content: ${align};
  `,
  isPaddingInput: item => css`
    input {
      padding: ${item} !important;
    }
  `,
};

export const CollumFormSearch = styled.div`
  padding: 30px 0 0 0;
`;

export const ColumnFormItem = styled.div`
  ${({ setMargin, isPaddingInput, align }) => css`
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;

    ${align && columnFormItemModifiers.setAlign(align)}
    ${setMargin && columnFormItemModifiers.setMargin(setMargin)}
      ${isPaddingInput &&
    columnFormItemModifiers.isPaddingInput(isPaddingInput)}
  `}
`;
