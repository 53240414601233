import React, { useEffect, useState } from "react";
import { useProductsContext } from "context/ProductsContext";
import { TextField, Checkbox } from "components/form";

import { MainMameView } from "./MainName";

import * as S from "../ProductForm.styles";

export const ProductFormMainName = ({
  productId,
  control,
  setValue,
  getValues,
}) => {
  const [eanValue, setEanValue] = useState("");

  const {
    setFormData,
    handleChangeField,
    prodNomeDescriptions,
    setProdNomeDescriptions,
  } = useProductsContext({});

  const nameIn = getValues("name_in");
  const marcaIn = getValues("marca_in");
  const modificadorIn = getValues("modificador_in");
  const embalagemIn = getValues("embalagem_in");
  const valueIn = getValues("valume_in");
  const unidIn = getValues("unid_in");

  const handleVi = (value, value1, value2, value3, value4, value5) =>
    [value, value1, value2, value3, value4, value5]
      .filter(item => item && item !== "")
      .join(" ");

  useEffect(() => {
    if (!nameIn) {
      setProdNomeDescriptions("");
      setFormData(fields => ({ ...fields, prod_nome: "" }));
    }
  }, [
    setFormData,
    nameIn,
    marcaIn,
    modificadorIn,
    valueIn,
    unidIn,
    setProdNomeDescriptions,
  ]);

  useEffect(() => {
    if (nameIn) {
      const field = handleVi(
        nameIn?.trim(),
        marcaIn?.trim(),
        modificadorIn?.trim(),
        embalagemIn,
        valueIn?.trim(),
        unidIn?.trim(),
      );
      setProdNomeDescriptions(field.trim());
      setFormData(fields => ({ ...fields, prod_nome: field.trim() }));
    }
  }, [
    nameIn,
    marcaIn,
    setFormData,
    embalagemIn,
    setProdNomeDescriptions,
    modificadorIn,
    valueIn,
    unidIn,
  ]);

  return (
    <>
      <S.ColumnFormItem>
        <MainMameView
          type="prod_nome"
          productId={productId}
          value={prodNomeDescriptions}
          eanValue={eanValue}
          setValue={setValue}
        />
      </S.ColumnFormItem>
      <S.ColumnFormItem isPaddingInput="8px">
        <TextField
          name="name_in"
          label="Produto *"
          control={control}
          onChange={e => {
            handleChangeField("name_in", e.target.value);
            setValue("name_in", e.target.value);
          }}
        />
        <TextField
          type="text"
          control={control}
          name="modificador_in"
          label="Modificador"
          helperText="Modificador"
          onChange={e => {
            handleChangeField("modificador_in", e.target.value);
            setValue("modificador_in", e.target.value);
          }}
        />
        <TextField
          type="text"
          control={control}
          name="marca_in"
          label="Marca"
          helperText="Marca"
          onChange={e => {
            handleChangeField("marca_in", e.target.value);
            setValue("marca_in", e.target.value);
          }}
        />

        <TextField
          type="text"
          control={control}
          name="embalagem_in"
          label="Embalagem"
          helperText="Embalagem"
          onChange={e => {
            handleChangeField("embalagem_in", e.target.value);
            setValue("embalagem_in", e.target.value);
          }}
        />
      </S.ColumnFormItem>
      <S.ColumnFormItem isPaddingInput="8px">
        <TextField
          type="text"
          control={control}
          name="valume_in"
          label="Volume"
          helperText="Volume"
          onChange={e => {
            handleChangeField("valume_in", e.target.value);
            setValue("valume_in", e.target.value);
          }}
        />
        <TextField
          type="text"
          control={control}
          name="unid_in"
          label="Unidade"
          helperText="Unidade"
          onChange={e => {
            handleChangeField("unid_in", e.target.value);
            setValue("unid_in", e.target.value);
          }}
        />

        <TextField
          type="text"
          control={control}
          name="unidade_medida_in"
          label="Unidade de medida "
          helperText="Unidade de medida "
          onChange={e => {
            handleChangeField("unidade_medida_in", e.target.value);
            setValue("unidade_medida_in", e.target.value);
          }}
        />
        <TextField
          type="text"
          control={control}
          name="prod_codigo_barras"
          label="EAN (Código de barras)"
          helperText="EAN"
          maxlength="13"
          onChange={e => {
            handleChangeField("prod_codigo_barras", e.target.value);
            setValue("prod_codigo_barras", e.target.value);
            setEanValue(e.target.value);
          }}
        />
      </S.ColumnFormItem>

      <S.ColumnFormItem setMargin="10px 0 0 0">
        <Checkbox name="isIgnoreBlankFields" label="Ignorar campos em branco" />
      </S.ColumnFormItem>
      {/* <DialogProductsSearching
        show={isModal?.show}
        setIsShow={() =>
          setIsShow({
            show: false,
            list: [],
          })
        }
        list={isModal?.list}
      /> */}
    </>
  );
};
