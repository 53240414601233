import React, { createContext, useState, useContext, useCallback } from "react";
import { generateIdScenery } from "utils/context/laws";
import { getUniqsBy } from "utils";

export const RegisterLawsContext = createContext({});

export const RegisterLawsContextProvider = ({ children }) => {
  const [values, setValues] = useState({});

  return (
    <RegisterLawsContext.Provider
      value={{
        values,
        setValues,
      }}
    >
      {children}
    </RegisterLawsContext.Provider>
  );
};

const RegisterLawsGradesContextDefaultValues = {
  productsMove: [],
  productsRemoved: [],
  productsChangeds: [],
  gradesMove: [],
  productsMoveSelecteds: [],
  productsAddSelecteds: [],
  productsAdd: [],
  ncms: [],
  cests: [],
  cenarios: [],
  taxa: {
    pis: false,
    cofins: false,
    icms: false,
  },
};

export const RegisterLawsGradesContex = createContext(
  RegisterLawsGradesContextDefaultValues,
);

const useNcmHandlers = ({ setValues }) => {
  const addNcms = useCallback(
    rows =>
      setValues(state => {
        const itens = [...rows, ...state.ncms];
        const ncms = itens.reduce(
          (acc, cur) => [
            ...acc.filter(
              obj =>
                obj?.data?.vc_codigo_sem_formatacao !==
                cur?.data?.vc_codigo_sem_formatacao,
            ),
            cur,
          ],
          [],
        );
        return {
          ...state,
          ncms,
        };
      }),
    [setValues],
  );

  const removeNcms = useCallback(
    ncms =>
      setValues(state => {
        return {
          ...state,
          ncms: state.ncms.filter(ncm =>
            ncms.find(
              item =>
                ncm?.data?.vc_codigo_sem_formatacao !==
                item?.data?.vc_codigo_sem_formatacao,
            ),
          ),
        };
      }),
    [setValues],
  );

  return {
    addNcms,
    removeNcms,
  };
};

const useSceneriesHandlers = ({ setValues }) => {
  const addSceneries = useCallback(
    sceneries =>
      setValues(state => {
        const itens = [...sceneries, ...state.cenarios];
        const cenarios = itens.reduce(
          (acc, cur) => [
            ...acc.filter(
              obj => generateIdScenery(obj) !== generateIdScenery(cur),
            ),
            cur,
          ],
          [],
        );
        return {
          ...state,
          cenarios,
        };
      }),
    [setValues],
  );

  const removeSceneries = useCallback(
    sceneries =>
      setValues(state => {
        return {
          ...state,
          cenarios: state.cenarios.filter(scenery =>
            sceneries.find(
              item => generateIdScenery(scenery) !== generateIdScenery(item),
            ),
          ),
        };
      }),
    [setValues],
  );

  return {
    addSceneries,
    removeSceneries,
  };
};

const useCestHandlers = ({ setValues }) => {
  const addCests = useCallback(
    rows =>
      setValues(state => {
        const itens = [...rows, ...state.cests];
        const cests = itens.reduce(
          (acc, cur) => [
            ...acc.filter(obj => obj?.data?.vc_codigo !== cur?.data?.vc_codigo),
            cur,
          ],
          [],
        );
        return {
          ...state,
          cests,
        };
      }),
    [setValues],
  );

  const removeCests = useCallback(
    cests =>
      setValues(state => {
        return {
          ...state,
          cests: state.cests.filter(ncm =>
            cests.find(item => ncm?.data?.vc_codigo !== item?.data?.vc_codigo),
          ),
        };
      }),
    [setValues],
  );

  return {
    addCests,
    removeCests,
  };
};

const getId = (values = []) => values.join("-");

const useProductsMoveSelecteds = ({ setValues }) => {
  const addProductsMoveSelecteds = useCallback(
    rows =>
      setValues(state => {
        const itens = [...rows, ...state.productsMoveSelecteds];
        const productsMoveSelecteds = itens.reduce(
          (acc, cur) => [
            ...acc.filter(
              obj =>
                getId([obj?.product?._id, obj?.gradeId]) !==
                getId([cur?.product?._id, cur?.gradeId]),
            ),
            cur,
          ],
          [],
        );

        return {
          ...state,
          productsMoveSelecteds,
        };
      }),
    [setValues],
  );

  const removeProductsMoveSelecteds = useCallback(
    (productsMoveSelecteds, onlyProductId = false) =>
      setValues(state => {
        return {
          ...state,
          productsMoveSelecteds: state.productsMoveSelecteds.filter(ncm => {
            // TODO: Precisa ser melhorado!
            return productsMoveSelecteds.find(item =>
              onlyProductId
                ? ncm?.product?._id !== item?.product?._id
                : ncm?.product?._id !== item?.product?._id &&
                  ncm?.gradeId !== item?.gradeId,
            );
          }),
        };
      }),
    [setValues],
  );

  const clearProductsMoveSelecteds = useCallback(
    () => setValues(state => ({ ...state, productsMoveSelecteds: [] })),
    [setValues],
  );

  return {
    addProductsMoveSelecteds,
    removeProductsMoveSelecteds,
    clearProductsMoveSelecteds,
  };
};

const useProductsAddSelecteds = ({ setValues }) => {
  const addProductsAddSelecteds = useCallback(
    rows =>
      setValues(state => {
        const itens = [...rows, ...state.productsAddSelecteds];
        const productsAddSelecteds = itens.reduce(
          (acc, cur) => [
            ...acc.filter(
              obj => getId([obj?.product?._id]) !== getId([cur?.product?._id]),
            ),
            cur,
          ],
          [],
        );

        return {
          ...state,
          productsAddSelecteds,
        };
      }),
    [setValues],
  );

  const removeProductsAddSelecteds = useCallback(
    productsMoveSelecteds =>
      setValues(state => {
        return {
          ...state,
          productsAddSelecteds: state.productsAddSelecteds.filter(ncm =>
            productsMoveSelecteds.find(
              item => ncm?.product?._id !== item?.product?._id,
            ),
          ),
        };
      }),
    [setValues],
  );

  const clearProductsAddSelecteds = useCallback(
    () => setValues(state => ({ ...state, productsAddSelecteds: [] })),
    [setValues],
  );

  const setProductsAdd = useCallback(
    cb =>
      setValues(state => ({
        ...state,
        productsAdd: cb(state.productsAdd, state),
      })),
    [setValues],
  );

  const setProductsClear = useCallback(
    () => setValues(state => ({ ...state, productsAdd: [] })),
    [setValues],
  );

  return {
    addProductsAddSelecteds,
    removeProductsAddSelecteds,
    clearProductsAddSelecteds,
    setProductsAdd,
    setProductsClear,
  };
};

export const RegisterLawsGradesContextProvider = ({ children }) => {
  const [values, setValues] = useState(RegisterLawsGradesContextDefaultValues);

  const { addNcms, removeNcms } = useNcmHandlers({ setValues });
  const { addSceneries, removeSceneries } = useSceneriesHandlers({ setValues });
  const { addCests, removeCests } = useCestHandlers({ setValues });

  const {
    addProductsMoveSelecteds,
    removeProductsMoveSelecteds,
    clearProductsMoveSelecteds,
  } = useProductsMoveSelecteds({
    setValues,
  });

  const {
    addProductsAddSelecteds,
    clearProductsAddSelecteds,
    removeProductsAddSelecteds,
    setProductsAdd,
    setProductsClear,
  } = useProductsAddSelecteds({ setValues });

  const addProductMove = product => {
    setValues(state => ({
      ...state,
      productsMove: [...state.productsMove, ...[product]],
    }));
  };

  const setProductsMove = cb =>
    setValues(state => ({
      ...state,
      productsMove: cb(state.productsMove, state),
    }));

  const removeProductMove = product => {
    setValues(state => ({
      ...state,
      productsMove: state.productsMove.filter(prodItem => prodItem !== product),
    }));
  };

  const addGradeMove = grade => {
    setValues(state => ({
      ...state,
      gradesMove: getUniqsBy([
        ...state.gradesMove,
        ...(Array.isArray(grade) ? grade : [grade]),
      ]),
    }));
  };

  const removeGradeMove = grade => {
    setValues(state => ({
      ...state,
      gradesMove: state.gradesMove.filter(gradeItem => gradeItem !== grade),
    }));
  };

  const clearGradeMove = () =>
    setValues(state => ({
      ...state,
      gradesMove: [],
    }));

  const clearProduct = () =>
    setValues(state => ({ ...state, productsMove: [] }));

  const removeProducts = () =>
    setValues(state => ({
      ...state,
      productsRemoved: state.productsMove,
      productsMove: [],
    }));

  // Add products changes fields
  const changeProducts = useCallback(
    product => {
      setValues(state => {
        const productsChangedsItens = state.productsChangeds || [];
        const hasProduct = productsChangedsItens.find(
          row => row._id === product._id,
        );

        if (!hasProduct) {
          productsChangedsItens.push(product);
          return {
            ...state,
            productsChangeds: productsChangedsItens,
          };
        }

        return {
          ...state,
          productsChangeds: productsChangedsItens.map(productRow => {
            if (productRow._id === product._id) {
              return {
                ...productRow,
                ...product,
              };
            }

            return productRow;
          }),
        };
      });
    },
    [setValues],
  );

  const getProductsRemoved = () => values.productsRemoved || [];

  const setTax = useCallback(
    tax => setValues(state => ({ ...state, taxa: { ...state.taxa, ...tax } })),
    [setValues],
  );

  return (
    <RegisterLawsGradesContex.Provider
      value={{
        values,
        setValues,
        addProductMove,
        removeProductMove,
        clearProduct,
        removeProducts,
        getProductsRemoved,
        changeProducts,
        addGradeMove,
        removeGradeMove,
        clearGradeMove,
        setProductsMove,
        setProductsAdd,
        addNcms,
        removeNcms,
        addSceneries,
        removeSceneries,
        addCests,
        removeCests,
        setTax,
        addProductsMoveSelecteds,
        removeProductsMoveSelecteds,
        clearProductsMoveSelecteds,

        addProductsAddSelecteds,
        removeProductsAddSelecteds,
        clearProductsAddSelecteds,
        setProductsClear,
      }}
    >
      {children}
    </RegisterLawsGradesContex.Provider>
  );
};

export const useRegisterLawsGradesContext = () => {
  const context = useContext(RegisterLawsGradesContex);
  if (!context)
    throw new Error(
      "useRegisterLawsGradesContext precisa ser utilizado dentro de um PagesProvider",
    );
  return context;
};
