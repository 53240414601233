import React, { useState, useEffect } from "react";
import {
  Container,
  Badge,
  BadgeNotification,
  Pagination,
} from "components/structure";
import { TextField } from "components/form";
import { Format, regexCheckCnpjOrText } from "utils";

import { useSocketNotificationAssessor } from "useCases";
import { useWalletUser } from "useCases/user";

import * as S from "./StoresCard.styles";

const ListItem = ({ item, onClick, selected }) => (
  <S.ListItemContent
    onClick={() => onClick(item)}
    isSelected={item._id === selected?._id}
  >
    <S.ListItem paddingSize="xs">
      <S.ListItemIcon>
        <S.ListItemImageContent>
          {item?.id_store?.totalMessages >= 1 && (
            <BadgeNotification type="danger">
              {item?.id_store?.totalMessages}
            </BadgeNotification>
          )}
          <S.ListItemImage src="https://www.w3schools.com/howto/img_avatar.png" />
        </S.ListItemImageContent>
      </S.ListItemIcon>
      <S.ListItemContainer>
        <S.ListItemHeader>
          {item?.id_store?.companyName ?? item?.id_store?.tradeName}
        </S.ListItemHeader>
        <S.ListItemHeaderSub>
          {`CNPJ: `}
          <b>{item?.id_store?.cnpj}</b>
        </S.ListItemHeaderSub>
        <S.ListItemHeaderSub>
          {item?.id_store?.lastMessage?.message}
        </S.ListItemHeaderSub>
      </S.ListItemContainer>
      <S.ListItemActions>
        {item.hasNew && !(item.id_store?.fadel?.status === "vencido") && (
          <Badge>Novo</Badge>
        )}

        {item.id_store?.fadel?.status === "vencido" && (
          <Badge type="danger">Certificado Vencido</Badge>
        )}

        {item?.id_store?.lastMessage?.createdAt ? (
          <S.ListItemDate>
            {Format(item?.id_store?.lastMessage?.createdAt, "dd/MM/yyyy") ===
            Format(new Date(), "dd/MM/yyyy")
              ? `Hoje às ${Format(
                  item?.id_store?.lastMessage?.createdAt,
                  "hh:mm:ss",
                )}`
              : Format(item?.id_store?.lastMessage?.createdAt)}
          </S.ListItemDate>
        ) : (
          <S.ListItemDate>Sem dados</S.ListItemDate>
        )}
      </S.ListItemActions>
    </S.ListItem>
    <S.ListItemHr />
  </S.ListItemContent>
);

export const StoresCard = ({
  idUser,
  selected,
  setSelected,
  isAllCliens = false,
}) => {
  const [badge, setBadge] = useState(null);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [isTextType, setIsTextType] = useState({});
  const { data, revalidate } = useWalletUser(idUser, {
    status: badge,
    page,
    limit: 10,
    search,
    isAll: isAllCliens ? 1 : 0,
  });

  const { notifications } = useSocketNotificationAssessor();

  const handleCheckType = e => {
    const { value } = e.target;

    if (!value) {
      setSearchValue("");
      setIsTextType("");
    }

    const checkValue = regexCheckCnpjOrText(value);

    if (checkValue.type === `text`) {
      setIsTextType("");
      setSearchValue(value);
    }
    if (checkValue.type === "numbertext") {
      setSearchValue(value);
      setIsTextType({
        label: "Texto e Números inseridos",
        type: "",
      });
    }
    if (checkValue.type === `number`) {
      if (value?.length < 14) {
        setSearchValue(value);
        setIsTextType({
          label: "Inserir CNPJ completo",
          type: "danger",
        });
      }
      if (value?.length > 13) {
        setSearchValue(checkValue.value);
        setIsTextType({});
      }
    }
  };

  useEffect(() => {
    if (notifications.length) {
      setPage(1);
      revalidate();
    }
  }, [notifications, revalidate, setPage]);

  return (
    <Container>
      <S.CardHeader paddingSize="xs" gridGap="xs" justifyEnd>
        <Badge type="danger" onClick={() => setBadge("only-vencido")}>
          {`Certificados Vencidos: `}
          <b>{data?.totalVencidos}</b>
        </Badge>
        <Badge onClick={() => setBadge("new-clients")}>
          {`Novos Clientes: `}
          <b>{data?.totalNovos}</b>
        </Badge>
        <Badge type="success" onClick={() => setBadge(null)}>
          {`Total Clientes: `}
          <b>{data?.wallet?.totalDocs}</b>
        </Badge>
      </S.CardHeader>
      <Container paddingSize="xs">
        <TextField
          name="search"
          value={searchValue}
          placeholder="Pesquise por CNPJ ou Razão Social"
          onChange={e => {
            handleCheckType(e);
          }}
          onKeyPress={e => {
            if (e.key === "Enter") {
              setSearch(e.target.value);
            }
            if (e.key === "Delete") {
              setSearchValue(e.target.value);
            }
          }}
        />
        {isTextType.label && isTextType.label}
      </Container>
      <S.CardContent paddingSize="xs">
        <S.List>
          {(data?.wallet?.docs ?? []).map(row => (
            <ListItem
              selected={selected}
              onClick={setSelected}
              key={row._id}
              item={row}
            />
          ))}
          <Pagination
            count={data?.wallet?.totalDocs}
            page={page - 1}
            rowsPerPage={10}
            onPageChange={(e, value) => setPage(value + 1)}
          />
        </S.List>
      </S.CardContent>
    </Container>
  );
};
