import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import { BtnRemove } from "components/contexts/shared";
import { useAuth } from "context/AuthContext";
import { getSessionUser } from "utils/session";
import { useACL } from "context";

import {
  useUserGet,
  useStores,
  useAccessGroups,
  useUserCrud,
  UseStoresSearch,
  useUserRemove,
} from "useCases";

import Card from "components/UI/Card";
import { Button } from "components/structure";

import { useToast } from "context/ToastContext";
import {
  UserForm,
  ConfigGroupsForm,
  ListTableConfigGroups,
  TimeAndToogles,
} from "components/contexts/users-and-permissions/UserForm";
import { WhatsApp } from "components/contexts/user";
import { defaultValues } from "./validations";
import * as S from "./styles";

const RoleType = [
  {
    value: "Administrador",
    label: "Administrador",
  },
  {
    value: "Gerente",
    label: "Gerente",
  },
  {
    value: "Supervisor",
    label: "Supervisor",
  },
  {
    value: "Atendente",
    label: "Atendente",
  },
];

const ActiveAcount = [
  {
    value: true,
    label: "Sim",
  },
  {
    type: false,
    label: "Não",
  },
];

export const CreateUserContainer = ({ id }) => {
  const { hasEdit, hasCreate, hasRemove } = useACL("users");

  const userSession = getSessionUser();

  const { user: userAuth } = useAuth();

  const { handleSubmit, control, register, reset, setValue, getValues } =
    useForm({
      defaultValues,
    });

  const [isLoadUser, setIsLoadUser] = useState(true);
  const [data, setData] = useState({});

  const [responsibility, setResponsibility] = useState(null);
  const [isActive, setIsActive] = useState(null);
  const [parseCompanies, setParseCompany] = useState({});
  const [parseGroups, setParseGroups] = useState({});
  const [tableData, setTableData] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState({});
  const [selectedCompany, setSelectedCompany] = useState({});
  const [tableCompanyValue, setTableCompanyValue] = useState("");
  const [tableCompanyValueCompanyId, setTableCompanyValueCompanyId] =
    useState("");
  const [tableStoreValue, setTableStoreValue] = useState("");
  const [tableAccessGroupValue, setTableAccessGroupValue] = useState("");
  const [currentId, setCurrentId] = useState("");

  const { addToast } = useToast();

  const { data: dataUser, fetch: fetchUser } = useUserGet({});

  const { data: dataCompaniesItem } = useStores({
    page: 1,
    limit: 9999,
  });

  const { isLoading, data: companies, fetch: searching } = UseStoresSearch();

  const { fetch: fetchUserCrud } = useUserCrud({ id });

  const { data: dataGroups, revalidate: AccessGroupsRevalidate } =
    useAccessGroups({ page: 1, limit: 9999 });

  const { data: stores, revalidate: storesRevalidate } = useStores({
    page: 1,
    limit: 9999,
    companyId: selectedCompany
      ? selectedCompany.length > 0
        ? selectedCompany[0].value
        : ""
      : "",
  });

  useEffect(() => {
    let dataAcessGroups;
    if (dataGroups && dataGroups?.accessGroups?.docs?.length > 0) {
      dataAcessGroups = dataGroups?.accessGroups?.docs?.map(accessGroup => ({
        label: accessGroup.groupName,
        value: accessGroup._id,
      }));
      setParseGroups(dataAcessGroups);
    }
  }, [dataGroups]);

  useEffect(() => {
    let dataCompanies;
    if (companies && companies?.length > 0) {
      dataCompanies = companies.map(item => ({
        label: item.company_name,
        value: item._id,
      }));
      setParseCompany(dataCompanies);
    }
    console.log({ companies });
    if (companies && !companies?.length) {
      setParseCompany([]);
    }
  }, [companies]);

  const handleSubmit2 = async field => {
    fetchUserCrud(field, tableData);
  };

  const addTableRow = () => {
    if (currentId) {
      const newTableData = tableData.filter(row => row._id !== currentId);
      setTableData([
        ...newTableData,
        {
          _id: currentId,
          company: tableCompanyValueCompanyId,
          store: tableStoreValue !== "" ? tableStoreValue : null,
          accessGroup: tableAccessGroupValue,
        },
      ]);
      addToast({
        title: "Atualizar Item na Tabela",
        type: "success",
        description: `Item atualizado com sucesso!`,
      });
      setSelectedCompany("");
      setCurrentId("");
      setTableCompanyValue("");
      setTableStoreValue("");
      setTableAccessGroupValue("");
    } else if (
      tableCompanyValue &&
      // tableStoreValue &&
      tableAccessGroupValue !== ""
    ) {
      setTableData([
        ...tableData,
        {
          _id: uuidv4(),
          company: tableCompanyValueCompanyId,
          store: tableStoreValue !== "" ? tableStoreValue : null,
          accessGroup: tableAccessGroupValue,
        },
      ]);
      addToast({
        title: "Criar Item na Tabela",
        type: "success",
        description: `Item criado com sucesso!`,
      });
      setSelectedCompany("");
      setTableCompanyValue("");
      setTableStoreValue("");
      setTableAccessGroupValue("");
    } else {
      addToast({
        title: "Criar Item na Tabela",
        type: "error",
        description: `
            Você precisa escolher uma Matriz, uma Filial e um Grupo de Acesso para conseguir criar!
            `,
      });
      return null;
    }
  };

  useEffect(() => {
    if (isLoadUser && id) {
      fetchUser({
        id,
      });
      AccessGroupsRevalidate();
      storesRevalidate();
      setIsLoadUser(false);
    }
    if (id && !isLoadUser) {
      setTimeout(
        () =>
          fetchUser({
            id,
          }),
        3000,
      );
    }
  }, [
    id,
    AccessGroupsRevalidate,
    fetchUser,
    storesRevalidate,
    setIsLoadUser,
    isLoadUser,
  ]);

  useEffect(() => {
    if (dataUser && dataUser?._id) {
      setData(dataUser);
      setIsActive(
        ActiveAcount.filter(item =>
          item.value === dataUser?.isActive ? item.value : null,
        ),
      );
      setResponsibility(
        RoleType.filter(item =>
          item.value === dataUser?.type ? item.value : null,
        ),
      );
      reset(dataUser);
      setTableData(dataUser?.groupPermissions);
    }
  }, [dataUser, setData, setTableData, reset, setIsActive, setResponsibility]);

  useEffect(() => {
    if (
      userAuth.type === "root" &&
      RoleType.find(item => item.value !== "root")
    ) {
      RoleType.push({
        value: "root",
        label: "Root",
      });
    }
  }, [userAuth]);

  const hasOk = data && Object.keys(data).length > 0;
  const hasOk2 = data && Object.keys(data).length === 0;

  return (
    <S.Container>
      <S.Section>
        <Card
          headerColor="#256925"
          title={id ? "EDITAR USUÁRIO" : "CRIAR NOVO USUÁRIO"}
          iconName="person"
          action={
            id && (
              <BtnRemove
                id={id}
                isDisabled={!hasRemove}
                hook={useUserRemove}
                module="Usuário"
              />
            )
          }
        >
          <S.Form onSubmit={handleSubmit(handleSubmit2)}>
            <S.TopPart>
              <S.CollumForm>
                <UserForm
                  getValues={getValues}
                  userAuth={userAuth}
                  setValue={setValue}
                  RoleType={RoleType}
                  responsibility={responsibility}
                  setResponsibility={setResponsibility}
                  ActiveAcount={ActiveAcount}
                  register={register}
                  isActive={isActive}
                  setIsActive={setIsActive}
                  control={control}
                  data={data}
                />
              </S.CollumForm>
              <S.Divider />
              <S.CollumForm>
                {id && userSession?.is_mix ? (
                  <Card title="Integração com WhatsApp" headerColor="#256925">
                    <WhatsApp user={dataUser} />
                  </Card>
                ) : (
                  ``
                )}
                <ConfigGroupsForm
                  searching={searching}
                  isLoading={isLoading}
                  data={companies}
                  parseGroups={parseGroups}
                  setSelectedGroups={setSelectedGroups}
                  setTableAccessGroupValue={setTableAccessGroupValue}
                  selectedGroups={selectedGroups}
                  parseCompanies={parseCompanies}
                  setTableCompanyValueCompanyId={setTableCompanyValueCompanyId}
                  setTableCompanyValue={setTableCompanyValue}
                  setSelectedCompany={setSelectedCompany}
                  selectedCompany={selectedCompany}
                  tableCompanyValue={tableCompanyValue}
                  tableStoreValue={tableStoreValue}
                  setTableStoreValue={setTableStoreValue}
                  addTableRow={addTableRow}
                />
                <S.ColumnFormItem>
                  <ListTableConfigGroups
                    tableData={tableData}
                    setTableData={setTableData}
                    setCurrentId={setCurrentId}
                    setTableCompanyValue={setTableCompanyValue}
                    setTableStoreValue={setTableStoreValue}
                    setTableAccessGroupValue={setTableAccessGroupValue}
                    companies={dataCompaniesItem?.stores?.docs}
                    stores={stores}
                    accessGroups={dataGroups?.accessGroups}
                  />
                </S.ColumnFormItem>
                <TimeAndToogles data={data} hasOk={hasOk} hasOk2={hasOk2} />
              </S.CollumForm>
            </S.TopPart>
            <S.Footer>
              <Button
                type="button"
                color="gray"
                goBack
                style={{ marginRight: 15 }}
              >
                VOLTAR
              </Button>
              <Button isDisabled={!hasCreate || !hasEdit} type="submit">
                {id ? "SALVAR" : "CRIAR"}
              </Button>
            </S.Footer>
          </S.Form>
        </Card>
      </S.Section>
    </S.Container>
  );
};

export const CreateUser = () => {
  const { id } = useParams();

  return <CreateUserContainer id={id === "new" ? null : id} />;
};
