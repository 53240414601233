import React, { useState } from "react";
import { Datatable } from "@mix/mixfiscal-designsystem";
import * as ROUTES from "constants/routes";
import { ButtonOpenNewTag } from "components/structure";
import { useCestsList } from "useCases/cest";

const ActionSelect = ({ _id }) => (
  <div>
    <ButtonOpenNewTag
      title="Abrir detalhe do CEST"
      toLink={ROUTES.CESTS.DETAIL(_id)}
    />
  </div>
);

export const List = () => {
  const [limit] = useState(50);
  const [page, setPage] = useState(1);
  const { data, isLoading } = useCestsList({
    page,
    limit,
  });

  const columns = [
    {
      field: "cest",
      title: "Cód.",
    },
    {
      field: "description",
      title: "Descrição",
    },
    {
      field: "segmento",
      title: "Segmento",
    },
    {
      field: "anexo",
      title: "Anexo",
    },
    {
      field: "item",
      title: "item",
    },
    {
      field: "action",
      title: "",
    },
  ];

  return (
    <Datatable
      pagination
      paginationRequest
      handleChangePage={setPage}
      columns={columns}
      amountPerPage={50}
      isLoading={isLoading}
      totalRawData={data?.data?.totalDocs}
      itemsPerPage={[50, 10, 150, 300]}
      rawData={data?.data?.docs.map(item => ({
        ...item,
        action: <ActionSelect {...item} />,
      }))}
    />
  );
};
