import React, { useState } from "react";
import { Datatable } from "@mix/mixfiscal-designsystem";
import * as ROUTES from "constants/routes";
import { ButtonOpenNewTag } from "components/structure";
import { useNcmNivel } from "useCases/ncm";

const ActionSelect = ({ _id }) => (
  <div>
    <ButtonOpenNewTag
      title="Abrir detalhe do NCM"
      toLink={ROUTES.NCMS.DETAIL(_id)}
    />
  </div>
);

export const List = ({ params }) => {
  const [limit] = useState(50);
  const [page, setPage] = useState(1);
  const columns = [
    {
      field: "codigo",
      title: "Cód.",
    },
    {
      field: "vc_descricao",
      title: "Descrição",
    },
    {
      field: "sessao",
      title: "Sessão",
    },
    {
      field: "valid",
      title: "Válido",
    },
    {
      field: "alq",
      title: "Alíquota",
    },
    {
      field: "action",
      title: "",
    },
  ];

  const { data, dataRes, isLoading } = useNcmNivel("item", {
    page,
    limit,
    ...params,
  });

  return (
    <Datatable
      pagination
      paginationRequest
      isLoading={isLoading}
      handleChangePage={setPage}
      columns={columns}
      amountPerPage={50}
      totalRawData={dataRes?.data?.totalDocs}
      itemsPerPage={[50, 10, 150, 300]}
      rawData={data.map(item => ({
        ...item,
        sessao: `${item.sessao} - ${item.descricao_sessao}`,
        valid: item.valid ?? "Sem válidade definida!",
        action: <ActionSelect {...item} />,
      }))}
    />
  );
};
