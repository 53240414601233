import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useFormContext, useFieldArray } from "react-hook-form";
import { getPermissionsByModules, getModulesOnList } from "utils/context/acl";

import { Table } from "./Table/table";

import * as SForm from "../../../styles";

import * as S from "./styles";

export const SelectPerm = ({ id, dataModules, groupModules }) => {
  const { control } = useFormContext();
  const { fields, replace } = useFieldArray({
    control,
    name: "modules",
  });

  const [selectAll] = useState({ name: "Selecionar todos", value: "all" });
  const [list, setList] = useState([]);
  const [newList, setNewList] = useState(false);
  const [listAdding, setListAdding] = useState(true);
  const [isItemAdd, setIsItemAdd] = useState(false);

  const handleSelectAll = selected => {
    setNewList(true);
    setIsItemAdd(true);
    if (selected.length === 0) {
      setNewList(false);
      setIsItemAdd(false);
    }

    if (selected.find(item => item.value?.value === "all")) {
      setList([selectAll]);
    }

    console.log({
      permissions: getModulesOnList(selected.map(item => item.value)).map(
        r => ({
          moduleId: r?.moduleId,
          rules: r?.rules ?? {},
          _id: r?._id,
        }),
      ),
    });

    return replace(
      getModulesOnList(selected.map(item => item.value)).map(r => ({
        moduleId: r?.moduleId,
        rules: r?.rules ?? {},
        _id: r?._id,
      })),
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const func = () => {
    const nullList = groupModules.map(item => item.moduleId);

    const checkExistents = dataModules?.modules.map(item =>
      !nullList.includes(item._id)
        ? (item &&
            item.childrens.find(
              itemChildren => !nullList.includes(itemChildren._id),
            )) ||
          item.fatherId === ""
          ? item
          : null
        : null,
    );

    const checkExistentsUpda = dataModules?.modules.map(item =>
      nullList.includes(item._id)
        ? (item &&
            item.childrens.find(
              itemChildren => !nullList.includes(itemChildren._id),
            )) ||
          item.fatherId === ""
          ? null
          : item
        : null,
    );

    if (checkExistents) {
      setList([
        selectAll,
        ...checkExistents.filter(item => item !== "" && item !== null),
      ]);
      const existentsModules = checkExistentsUpda.filter(
        item => item !== "" && item !== null,
      );
      if (
        existentsModules &&
        existentsModules.length > 0 &&
        !isItemAdd &&
        listAdding
      ) {
        // setModulesList(existentsModules);
        setListAdding(false);
      }
    } else {
      setList([selectAll, ...dataModules?.modules]);
    }
  };

  useEffect(() => {
    if (dataModules && !newList) {
      func();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataModules, groupModules, newList, isItemAdd]);

  return (
    <>
      <S.ContentFormSelect>
        <S.Title>
          <S.TitleText>Selecionar Modulo Permissão</S.TitleText>
        </S.Title>
        <S.ContentSelect>
          <SForm.InputForm>
            <Select
              isMulti
              labelType="top"
              placeholder="Selecione Permissões"
              label="Matriz"
              name="companyId"
              value={getPermissionsByModules(dataModules?.modules, fields).map(
                module => ({
                  label: module.name,
                  value: module,
                }),
              )}
              onChange={selected => {
                handleSelectAll(selected);
              }}
              options={(list ?? []).map(module => ({
                label: module.name,
                value: { ...module, moduleId: module._id },
              }))}
            />
          </SForm.InputForm>
        </S.ContentSelect>
        {fields?.length > 0 && (
          <>
            <S.DividerH />

            <S.Title>
              <S.TitleText>Modulos de permissões selecionados</S.TitleText>
            </S.Title>
            <S.ContentSelect>
              <Table
                id={id}
                modulesList={getPermissionsByModules(
                  dataModules?.modules,
                  fields,
                )}
              />
            </S.ContentSelect>
          </>
        )}
      </S.ContentFormSelect>
    </>
  );
};
