import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Card, Button } from "@mix/mixfiscal-designsystem";
import { Filters } from "components/contexts/grid-manager";
import { getURLParams, getFieldJsonParse } from "utils/context";
import { LAWS } from "constants/routes";
import { GridManagerProvider } from "context/GridManagerContext";

import { ResultContainer } from "./Result";
import { ResultTags } from "./ResultTags";
import * as S from "./styles";

export const LawsManager = () => {
  const { push } = useHistory();
  const urlParams = getURLParams();
  const [params, setParams] = useState({
    cenarios: getFieldJsonParse(urlParams.get("cenarios")),
    ncms: getFieldJsonParse(urlParams.get("ncms")),
    cests: getFieldJsonParse(urlParams.get("cests")),
  });

  useEffect(() => {
    const ean = urlParams.get("ean");
    if (ean && ean !== params.ean) {
      setParams({
        ...params,
        ean: urlParams.get("ean"),
      });
    }
  }, [urlParams, params]);

  return (
    <GridManagerProvider>
      <S.Header />
      <S.Container>
        <Filters params={params} hasLegislation hasTributary />
        <S.ContainerResult>
          <Card headerColor="#5A8DEE" textColor="#FFF" title="RESULTADOS">
            <S.ContainerHeader>
              <S.ContainerHeaderAction>
                <Button
                  label="NOVA GRADE"
                  handleClick={() => push(LAWS.CREATE_NEW)}
                />
              </S.ContainerHeaderAction>
              <ResultTags />
            </S.ContainerHeader>
            <ResultContainer paramsURL={params} />
          </Card>
        </S.ContainerResult>
      </S.Container>
    </GridManagerProvider>
  );
};

export default LawsManager;
