import { format, toDate } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";

export const Format = (date, mask = "dd/MM/yyyy - hh:mm:ss") =>
  format(new Date(date), mask, { locale: ptBR });

export const lastDayOfMonth = dirtyDate => {
  const date = toDate(dirtyDate);
  const month = date.getMonth();
  date.setFullYear(date.getFullYear(), month + 1, 0);
  date.setHours(0, 0, 0, 0);
  return date;
};
