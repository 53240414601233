import React, { createContext, useState, useContext } from "react";
import { startOfMonth } from "date-fns";
import { lastDayOfMonth } from "utils/date";

export const PagesContext = createContext({
  isActiveTheme: false,
});

export const PagesProvider = ({ children }) => {
  const [valuesPageProvider, setValuesPageProvider] = useState({
    dateStart: startOfMonth(new Date()),
    dateEnd: lastDayOfMonth(new Date()),
    fileType: "XML",
  });
  return (
    <PagesContext.Provider
      value={{
        valuesPageProvider,
        setValuesPageProvider,
      }}
    >
      {children}
    </PagesContext.Provider>
  );
};

export const usePageContext = () => {
  const context = useContext(PagesContext);
  if (!context)
    throw new Error(
      "usePageContext precisa ser utilizado dentro de um PagesProvider",
    );
  return context;
};
