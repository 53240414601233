import React, { useState, useCallback } from "react";
import { Card } from "@mix/mixfiscal-designsystem";
import { ListProducts } from "components/contexts/grid-manager/ResultsProducts/ListProducts";
import { Button, Dialog } from "components/structure";
import IconComponent from "components/IconExporter";
import { useProductsGrade } from "useCases/grid";
import { useLawsGradeAction } from "useCases/laws";

import * as S from "./LawProductsDiffModal.styles";

const TYPES_ACTION_DIFF = {
  FILL: "fill",
  MERGE: "merge",
  REMOVE: "remove",
};

const TYPES_ACTION_ARROW = {
  TOP: "top",
  DOWN: "down",
};

const TYPES_ACTION_DIFF_LABELS = {
  [TYPES_ACTION_DIFF.FILL]: "Preencher",
  [TYPES_ACTION_DIFF.MERGE]: "Juntar",
  [TYPES_ACTION_DIFF.REMOVE]: "Remover",
};

const LawProductsList = ({ lawId, notInLawId }) => {
  const { data } = useProductsGrade(true, {
    lawId,
    notInLawId,
    productsByGradeIcms: 1,
    limit: 250,
    page: 1,
  });

  return (
    <ListProducts
      data={data?.docs?.map(item => ({ ...item, ...item.product }))}
      isAction={false}
    />
  );
};

export const LawProductsDiffModal = ({
  isOpen = false,
  handleClose,
  lawId,
  notInLawId,
}) => {
  const [type, setType] = useState(TYPES_ACTION_DIFF.MERGE);
  const [arrowDirection, setArrowDirection] = useState();
  const { onSyncLawsGradesProducts } = useLawsGradeAction();

  const onChangeType = useCallback(() => {
    setType(state => {
      if (state === TYPES_ACTION_DIFF.FILL) {
        return TYPES_ACTION_DIFF.MERGE;
      }

      if (state === TYPES_ACTION_DIFF.MERGE) {
        return TYPES_ACTION_DIFF.REMOVE;
      }

      return TYPES_ACTION_DIFF.FILL;
    });
  }, []);

  const onChangeArrow = useCallback(() => {
    setArrowDirection(
      arrowDirection === TYPES_ACTION_ARROW.TOP
        ? TYPES_ACTION_ARROW.DOWN
        : TYPES_ACTION_ARROW.TOP,
    );
  }, [setArrowDirection, arrowDirection]);

  const handleConfirmLocal =
    ({ lawId: lawIdValue, notInLawId: notInLawIdValue }) =>
    () => {
      onSyncLawsGradesProducts({
        lawId: lawIdValue,
        notInLawId: notInLawIdValue,
        typeArrow: arrowDirection,
        typeAction: type,
      });
    };

  return (
    <Dialog
      isOpen={isOpen}
      title="Diferença de produtos entre as 2 Leis/Grades"
      handleClose={handleClose}
      handleConfirm={handleConfirmLocal({ lawId, notInLawId })}
      handleCancel={handleClose}
    >
      <S.ModalContent>
        <Card
          headerColor="#E5E5E5"
          textColor="#304156"
          title={`Produtos (${lawId})`}
          className="flatCard"
        >
          <LawProductsList lawId={lawId} notInLawId={notInLawId} />
        </Card>
        <S.ButtonAction>
          <Button type="button" onClick={onChangeType}>
            {TYPES_ACTION_DIFF_LABELS[type]}
          </Button>
          {TYPES_ACTION_DIFF.MERGE !== type && (
            <Button type="button" onClick={onChangeArrow}>
              <IconComponent
                name={
                  arrowDirection === TYPES_ACTION_ARROW.TOP
                    ? "arrowup"
                    : "arrowdown"
                }
                color="#fff"
              />
            </Button>
          )}
        </S.ButtonAction>

        <Card
          headerColor="#E5E5E5"
          textColor="#304156"
          title={`Produtos (${notInLawId})`}
          className="flatCard"
        >
          <LawProductsList lawId={notInLawId} notInLawId={lawId} />
        </Card>
      </S.ModalContent>
    </Dialog>
  );
};
