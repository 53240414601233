import { cloneDeep } from "lodash";

/* eslint-disable */
const ar1 = [
  { id: 0, type: "start" },
  { id: 1, parentId: [0], type: "data", value: 1 },
  { id: 2, parentId: [1], type: "function_1", value: x => x + 1 },
  { id: 3, parentId: [0], type: "data", value: 2 },
  { id: 4, parentId: [3], type: "function_1", value: x => x + 2 },
  { id: 5, parentId: [2, 4], type: "function", value: (x, y) => x + y },
  { id: "last", parentId: [5], type: "end" },
];
const out1 = 6;

const ar2 = [
  { id: 0, type: "start" },
  { id: 1, parentId: [0], type: "data", value: 1 },
  { id: 2, parentId: [0], type: "data", value: 2 },
  { id: 3, parentId: [1, 2], type: "function", value: (x, y) => x + y },
  { id: 4, parentId: [0], type: "data", value: 2 },
  { id: 5, parentId: [0], type: "data", value: 2 },
  { id: 6, parentId: [4, 5], type: "function", value: (x, y) => x + y },
  { id: 7, parentId: [3, 6], type: "function", value: (x, y) => x + y },
  { id: "last", parentId: [5, 7], type: "end" },
];
const out2 = 7;

const ar3 = [
  { id: 0, type: "start" },
  { id: 1, parentId: [0], type: "data", value: 1 },
  { id: 2, parentId: [0], type: "data", value: 2 },
  { id: 3, parentId: [1, 2], type: "function", value: (x, y) => x + y },
  { id: 4, parentId: [0], type: "data", value: 2 },
  { id: 5, parentId: [0], type: "data", value: 2 },
  { id: 6, parentId: [4, 5], type: "function", value: (x, y) => x + y },
  { id: 7, parentId: [3, 6], type: "function", value: (x, y) => x + y },
  { id: 8, parentId: [5, 7], type: "compare", value: (x, y) => x === y },
  { id: 9, parentId: [8], type: "ifYes" },
  { id: 10, parentId: [8], type: "ifNo" },
  { id: 11, parentId: [9], type: "data", value: 10000 },
  { id: 12, parentId: [0], type: "data", value: 5000 },
  { id: 13, parentId: [11, 12], type: "function", value: (x, y) => x + y },
  { id: 14, parentId: [10], type: "data", value: 3000 },
  { id: 15, parentId: [0], type: "data", value: 1000 },
  { id: 16, parentId: [14, 15], type: "function", value: (x, y) => x + y },
  { id: "last", parentId: [13, 16], type: "end" },
];
const out3 = 4000;

const ar4 = [
  { id: "start", type: "start"},
  { id: "end", type: "end", parentId: ["7fcdedda-46cb-4ac4-b902-6fd3dc3e9df1"]},
  {
    apiColumnIndex: "1",
    apiFileId: "6056803d91012b00136cb3b9",
    columnLabel: "Faturamento",
    dataType: "xls",
    id: "aadb0ebe-b3ca-40e2-9a9a-a2326e321ba8",
    parentId: ["start"],
    type: "data",
    value: 3000,
  },
  {
    apiColumnIndex: "1",
    apiFileId: "6056803d91012b00136cb3ba",
    columnLabel: "Faturamento",
    dataType: "xls",
    id: "bf293ccb-c061-47b5-ae59-1a8260e7de08",
    parentId: ["start"],
    type: "data",
    value: 7000,
  },
  {
    functionPropsAmount: 2,
    functionType: "add",
    id: "7fcdedda-46cb-4ac4-b902-6fd3dc3e9df1",
    operationType: "add",
    parentId: ["aadb0ebe-b3ca-40e2-9a9a-a2326e321ba8", "bf293ccb-c061-47b5-ae59-1a8260e7de08"],
    type: "function",
    value: (x, y) => x + y,
  }

]

const getInit = array1 => {
  for (let i = 0; i < array1.length; i++) {
    if (array1[i].type === "start") return array1[i].id;
  }
};

const getChildren = (array1, id) => {
  const arrayResult = [];
  for (let i = 0; i < array1.length; i++) {
    if (array1[i].parentId && array1[i].parentId.includes(id)) {
      arrayResult.push(array1[i].id);
    }
  }
  return arrayResult;
};

const getElementById = (array1, id) => {
  for (let i = 0; i < array1.length; i++) {
    if (array1[i].id === id) {
      return array1[i];
    }
  }
};

const recursion = (array1, current, parent, currentValue) => {
  const elementCurrent = getElementById(array1, current);
  if (
    ["function", "compare"].includes(elementCurrent.type) &&
    (!elementCurrent.dependencyValues ||
      elementCurrent.dependencyValues.length === 0)
  ) {
    elementCurrent.dependencyValues = [currentValue];
    return null;
  }
  if (["ifYes", "ifNo"].includes(elementCurrent.type)) {
    const elementParent = getElementById(array1, parent);
    if (
      elementCurrent.type === "ifYes" &&
      (!elementParent ||
        !elementParent.value(
          elementParent.dependencyValues[0],
          elementParent.dependencyValues[1],
        ))
    ) {
      return null;
    }
    if (
      elementCurrent.type === "ifNo" &&
      elementParent &&
      elementParent.value(
        elementParent.dependencyValues[0],
        elementParent.dependencyValues[1],
      )
    ) {
      return null;
    }
  } else if (elementCurrent.type === "end") {
    return currentValue;
  }
  let newValue = currentValue;
  if (["function", "compare"].includes(elementCurrent.type)) {
    elementCurrent.dependencyValues.push(newValue);
  }
  if (elementCurrent.type === "data") {
    newValue = elementCurrent.value;
  } else if (elementCurrent.type === "function_1") {
    newValue = elementCurrent.value(newValue);
  } else if (elementCurrent.type === "function") {
    newValue = elementCurrent.value(
      elementCurrent.dependencyValues[0],
      elementCurrent.dependencyValues[1],
    );
  } else if (elementCurrent.type === "find_replace") {
    newValue = elementCurrent.value(newValue, elementCurrent);
  }
  const children = getChildren(array1, current);
  for (let i = 0; i < children.length; i++) {
    const resultRecursion = recursion(array1, children[i], current, newValue);
    if (resultRecursion !== null) {
      return resultRecursion;
    }
  }
  return null;
};

export const runRecursion = array1 => {
  // to not replace your object i made this : )
  const tempArray = cloneDeep(array1);
  // start recursion
  // console.log("STARTING!");
  const res1 = recursion(tempArray, getInit(array1), null, null);
  // console.log("RESULT F", res1);
  return res1;
};
// runRecursion(ar4);
/* eslint-disable */
