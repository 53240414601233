import React, { useState } from "react";
import { useAuth } from "context/AuthContext";
import {
  AssessorPainelProvider,
  useAssessorPainel,
} from "context/AssessorContext";
import { Switch } from "components/form";

import { useUserGet, useAssessorWhatsappMessages } from "useCases";
import { StoresCard } from "components/contexts/stores";

import * as S from "./styles";

import { DadosClients, WhatsAppDados, Blog } from "./components";

export const ContactClientMessages = ({ store }) => {
  useAssessorWhatsappMessages({
    idCli: store?.id_store?._id,
    lastMessage:
      store?.id_store?.messages[store?.id_store?.messages?.length - 1] ?? null,
  });
  return null;
};

export const ContactClientContainer = () => {
  const [isAllCliens, setIsAllClients] = useState(false);
  const { setStore, store: storeSelected } = useAssessorPainel();

  const { user: userLocal } = useAuth();
  const { data: user } = useUserGet({ id: userLocal._id });

  return (
    <>
      <S.Container>
        {storeSelected?.id_store?._id && (
          <ContactClientMessages store={storeSelected} />
        )}
        <S.ContainerHeader>
          <S.ContainerHeaderTitle>{`Olá, ${userLocal.name}`}</S.ContainerHeaderTitle>
        </S.ContainerHeader>

        <S.ContainerRow direction="row">
          <S.ContainerRowItem>
            <S.ContainerRowItemHeader>
              <S.ContainerHeaderClientTitle>
                <S.ContainerRowItemHeaderTitle>
                  Clientes
                </S.ContainerRowItemHeaderTitle>
                <S.ContainerHeaderClientAction>
                  <Switch
                    name="isAllClients"
                    onChange={event => setIsAllClients(event.target.value)}
                    value={isAllCliens}
                    label="Todos os Clientes"
                  />
                </S.ContainerHeaderClientAction>
              </S.ContainerHeaderClientTitle>
              <S.ContainerRowItemHeaderTitleSub>
                Meus convidados
              </S.ContainerRowItemHeaderTitleSub>
            </S.ContainerRowItemHeader>
            <StoresCard
              idUser={userLocal._id}
              selected={storeSelected}
              setSelected={setStore}
              isAllCliens={isAllCliens}
            />
          </S.ContainerRowItem>
          <DadosClients selectedClient={storeSelected} />
          <WhatsAppDados user={user} selectedClient={storeSelected} />
        </S.ContainerRow>

        <Blog />
      </S.Container>
    </>
  );
};

export const ContactClient = () => {
  return (
    <AssessorPainelProvider>
      <ContactClientContainer />
    </AssessorPainelProvider>
  );
};
