import React from "react";
import ReactDOM from "react-dom";

import Alert from "./Alert";
import { Container } from "./styles";

function ToastContainer({ messages, removeToast }) {
  const triggerTimeout = messageId => {
    setTimeout(() => {
      removeToast(messageId);
    }, 4500);
  };

  return ReactDOM.createPortal(
    <Container isHidden={messages?.length === 0}>
      {messages &&
        messages.length > 0 &&
        messages.map(message => (
          <>
            <Alert
              key={message.id}
              id={message.id}
              title={message.title}
              text={message.description}
              type={message.type}
              align={message.align}
              handleClose={() => removeToast(message.id)}
            />
            {triggerTimeout(message.id)}
          </>
        ))}
    </Container>,
    document.querySelector("#root"),
  );
}

export default ToastContainer;
