import styled from "styled-components";
import { InputContainer } from "components/form";

export const Container = styled.div`
  display: flex;

  ${InputContainer} {
    flex: 1;
  }
`;

export const AddPlus = styled.div`
  display: flex;
  align-items: flex-end;
`;
