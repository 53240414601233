import { useFetchAuthenticate, useAPIAuthenticateToast } from "hooks";
import { getURLWithParams } from "utils/context";

export const useDiagnosticoReportXML = params =>
  useFetchAuthenticate(getURLWithParams("/diagnostico/report-xml", params));

export const useDiagnosticoReportXMLActions = () => {
  const { isLoading, onSend } = useAPIAuthenticateToast();

  const onCSVGenerate = data =>
    onSend({
      endpoint: "/diagnostico/report-xml-csv",
      messageSuccess: "CSV gerado com sucesso!",
      messageFail: "Falha ao tentar gerar CSV!",
      data,
    });

  return {
    onCSVGenerate,
    isLoading,
  };
};
