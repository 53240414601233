import styled from "styled-components";

export const Results = styled.div`
  width: 100%;
  padding: 2px;

  .custom-row {
    display: flex;

    .row {
      padding: 5px 0;
    }

    .card-custom {
      width: 50%;
      margin: 5px;

      .card {
        width: 92%;
      }

      .row-dropZone {
        height: 100px;
      }
    }
  }
`;

export const TagList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  span {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px;

    div {
      margin: 8px;
    }
  }
`;

export const DescriptionResults = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 10px;

  span {
    padding-left: 10px;
    padding-right: 20px;
  }
`;

export const Column = styled.div`
  width: 50%;

  > div {
    margin: 5px;
  }

  .card {
    width: 92%;
  }
`;

export const DescriptionLegislation = styled.div`
  width: 100%;

  padding: 10px;
  line-height: 1.5;

  border-radius: 5px;
  background: #f2f4f4;
  color: #575757;
`;

export const ContainerButton = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 25px;
`;
