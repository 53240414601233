import React from "react";
import { Card } from "@mix/mixfiscal-designsystem";
import TagsList from "components/contexts/dashboard/tags-list";
import TagsListMonth from "components/contexts/dashboard/tags-list-month";
import Hr from "components/contexts/dashboard/hr";

import { BodyCard, HeaderCard } from "./styles";

const MidCard = ({
  children,
  title = "Compras",
  subtitle = "Período",
  total = "R$ 560.845,55",
  tagsList = [],
  handleClick,
  handleClickType,
  cssClass,
  hideTag = false,
  tag,
  typeList = "",
  addMonthsLength = 13,
  hideTags = false,
}) => (
  <Card className={cssClass}>
    <HeaderCard>
      <h1>{title}</h1>
      <h4>{subtitle}</h4>
    </HeaderCard>
    <Hr />
    <BodyCard>
      <span style={{ display: "flex", alignItems: "center" }}>{total}</span>
    </BodyCard>
    <div style={{ height: "165px" }}>{children}</div>
    {typeList !== "" && !hideTag ? (
      <TagsListMonth
        tag={tag}
        tags={tagsList}
        onChangeDate={handleClick}
        onChangeType={handleClickType}
        addMonthsLength={addMonthsLength}
        hideTags={hideTags}
      />
    ) : (
      <TagsList
        tag={tag}
        hideTag={hideTag}
        tags={tagsList}
        onChangeDate={handleClick}
        onChangeType={handleClickType}
        hideTags={hideTags}
      />
    )}
  </Card>
);

export default MidCard;
