import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${props => {
    switch (props.type) {
      case "primary":
        return "1px solid #15253f";
      case "danger":
        return "1px solid #3f1515";
      default:
        return "1px solid #fff";
    }
  }};
  border-radius: 16px;
  padding: 8px 16px;
  cursor: pointer;

  svg {
    margin-right: 8px;
    fill: ${props => {
      switch (props.type) {
        case "primary":
          return "#15253f";
        case "danger":
          return "#3f1515";
        default:
          return "#fff";
      }
    }};
  }
`;

export const CustomButton = styled.button`
  background-color: transparent;
  color: ${props => {
    switch (props.buttonType) {
      case "primary":
        return "#15253f";
      case "danger":
        return "#3f1515";
      default:
        return "#fff";
    }
  }};
`;

export const CustomCircleButton = styled.button`
  width: 68px;
  height: 68px;
  border-radius: 50%;
  background-color: #ff8507;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: all 0.2s ease;
  &:active {
    transform: translateY(4px);
  }
`;
