import React, { useState } from "react";
import { Switch, Card, Button } from "@mix/mixfiscal-designsystem";
import { Select, TextField } from "components/form";
import { BLANK_SELECT_LIST, FIELDS_TYPES_LIST } from "constants/types";

import * as S from "./Filters.styles";

const fieldValuesSearchBy = [
  ...BLANK_SELECT_LIST,
  ...FIELDS_TYPES_LIST.filter(row => row.value !== "CNPJ"),
  ...[{ value: "ID_PROD", label: "Código Interno do Produto Mix" }],
];

const fieldsNames = {
  EAN: "prod_codigo_barras",
  PROD_DESCRICAO: "prod_nome",
  ID_PROD: "id_prod",
};

export const Filters = ({ onChange }) => {
  const [fields, setFields] = useState({
    prod_nome: "",
  });

  const handleClick = () => {
    onChange(state => {
      const nameField = fieldsNames[fields.search_by];

      const fieldsValues = {
        search_by: fields.search_by,
      };

      delete state.prod_nome;
      delete state.prod_codigo_barras;
      delete state.id_prod;

      if (fields.prod_nome) {
        fieldsValues[nameField] = fields.prod_nome.trim();
      }

      if (fields.cest !== undefined) {
        fieldsValues.cest = fields.cest;
      }

      return {
        ...state,
        ...fieldsValues,
      };
    });
  };

  const handleRemoveFilters = () => {
    onChange(state => {
      const newState = {};
      delete state.prod_nome;
      delete state.prod_codigo_barras;
      delete state.id_prod;
      return {
        ...state,
        ...newState,
      };
    });
    setFields({
      prod_nome: "",
      search_by: BLANK_SELECT_LIST[0].value,
    });
  };

  return (
    <div style={{ marginBottom: "1rem" }}>
      <Card
        handleCtaClick={() => {}}
        hasElevation
        title="Filtro"
        titleIcon="filtro1"
      >
        <S.ContainerFilter>
          <S.FieldRow>
            <Select
              name="search_by"
              data={fieldValuesSearchBy}
              value={fieldValuesSearchBy.find(
                item => item.value === fields.search_by,
              )}
              onChange={event =>
                setFields({ ...fields, search_by: event.value })
              }
            />
          </S.FieldRow>
          <S.FieldSearch>
            <TextField
              color="#596778"
              className="field-search"
              name="search"
              value={fields.prod_nome}
              onChange={event =>
                setFields({ ...fields, prod_nome: event.target.value })
              }
            />
          </S.FieldSearch>
        </S.ContainerFilter>

        <S.FieldSwitch>
          <Switch
            className=""
            handleChangeCallback={value =>
              setFields({ ...fields, cest: value })
            }
            label="Produtos sem Cest"
            value={fields.cest}
            labelContents={[]}
          />
        </S.FieldSwitch>
        <S.Action>
          <Button label="Limpar filtros" handleClick={handleRemoveFilters} />
          <Button label="Pesquisar" handleClick={handleClick} />
        </S.Action>
      </Card>
    </div>
  );
};
