import React, { Suspense } from "react";

const AppOportunidadeNegociacao = React.lazy(() =>
  import("./OportunidadeNegociacao.component"),
);

export const OportunidadeNegociacao = ({ token, history }) => (
  <Suspense fallback={<div>Carregando...</div>}>
    <AppOportunidadeNegociacao
      token={token}
      width="100%"
      height="1000px"
      currentRoute="/oportunidade-negociacao/relatorio"
      onChangeRoute={newModulePath =>
        history && history.push(`/${module}${newModulePath}`)
      }
    />
  </Suspense>
);
