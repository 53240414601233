import styled from "styled-components";

export const Container = styled.div`
  flex: 0.3;
  margin-left: 30px;
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 19px;
  }
`;
