import styled from "styled-components";

export const Container = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
  p {
    color: ${({ theme }) => theme.colors.slateGray};
    cursor: pointer;
    font-size: 12px;
    margin: 0px;
    span {
      font-size: 12px;
      cursor: pointer;
      display: inline-block;
      font-weight: bold;
      margin: 0 4px;
      text-decoration: underline;

      div {
        display: block;
      }
    }
  }
  span {
    font-size: 12px;
  }
`;

export const TextItems = styled.div`
  span {
    color: #596778;
    cursor: pointer;
    display: block;
    font-weight: bold;
    margin: 1px 3px 0px 3px;
    text-decoration: underline;
  }
`;

export const BadgeContainer = styled.div`
  display: flex !important;
  padding: 0 0px 0 0px;
`;
