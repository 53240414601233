import { useState, useCallback } from "react";
import axios from "axios";
import { useToast } from "hooks";

export const useGetCep = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast({});
  const fetch = useCallback(
    cep => {
      setLoading(true);
      axios
        .get(`https://viacep.com.br/ws/${cep}/json/`)
        .then(response => {
          if (!response?.data?.erro) {
            return setData(response?.data);
          }
          return addToast({
            type: "error",
            title: "FALHA",
            description: `Não foi encontrado dados para o CEP inserido`,
          });
        })
        .catch(() => {
          return addToast({
            type: "error",
            title: "FALHA",
            description: `Não foi encontrado dados para o CEP inserido`,
          });
        });
    },
    [addToast],
  );
  return { isLoading: loading, data, fetch };
};
