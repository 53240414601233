import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

import { useForm, FormProvider, useFieldArray } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useACL } from "context";

import { usePermissions } from "context/PermissionsContext";
import { Button } from "components/structure";
import { BtnRemove } from "components/contexts/shared";

import Card from "components/UI/Card";
import {
  GroupForm,
  GroupFormSelects,
  PermiForm,
  TableList,
} from "components/contexts/users-and-permissions";
import {
  useAccessGroupCreateEdit,
  useAccessGroup,
  useModules,
  useAccessGroupRemove,
  useCompanies,
} from "useCases";
import * as S from "./styles";

export const CreateEditGroupContainer = ({ id }) => {
  const formMethods = useForm();
  // FIXME: Mudar para o SLUG (wesley)
  const { hasEdit, hasCreate, hasRemove } = useACL("access-group");
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = formMethods;

  useFieldArray({
    control,
    name: "modules",
  });

  const [currentId, setCurrentId] = useState("");
  const [modulesList, setModulesList] = useState({});
  const [allRuleValues, setAllRuleValues] = useState(null);
  const [groupModules, setGroupModules] = useState([]);
  const [isNewsGroups, setIsNewsGroups] = useState(false);
  const [newSetOfModulesGroups, setNewSetOfModulesGroups] = useState([]);
  const [newSetOfModules, setNewSetOfModules] = useState([]);

  const [tableData, setTableData] = useState([]);
  const [tableCompanyValue, setTableCompanyValue] = useState("");
  const [tableStoreValue, setTableStoreValue] = useState([]);
  const { selectedCompany } = usePermissions();

  const { data: dataGroup } = useAccessGroup(id);
  const { isLoading: isLoadingModules, data: dataModules } = useModules({});

  const { onSave: submitGroup, isLoading } = useAccessGroupCreateEdit(id);

  const { isLoading: isLoadingCompanies, data: dataCompanies } = useCompanies();

  useEffect(() => {
    if (dataGroup?.accessGroups) {
      reset({
        ...dataGroup?.accessGroups,
        name: dataGroup?.accessGroups?.groupName,
      });

      setGroupModules(dataGroup?.accessGroups?.modules);

      if (
        dataGroup?.accessGroups?.companies &&
        dataGroup?.accessGroups?.companies.length > 0
      ) {
        const SetCompanies = dataGroup?.accessGroups?.companies.map(item => {
          return {
            _id: uuidv4(),
            company: item.companyId,
            store:
              item.stores ??
              item.stores.map(itemStores => {
                return itemStores;
              }),
          };
        });
        setTableData([...SetCompanies]);
        setTableData(SetCompanies);
      }
    }
  }, [
    dataGroup,
    reset,
    setGroupModules,
    isNewsGroups,
    setIsNewsGroups,
    dataModules,
    id,
  ]);

  const onSave = fields => {
    const dataTable = tableData.map(item => {
      return {
        companyId: item.company,
        stores:
          item.store.length > 0
            ? item.store.map(itemStore => {
                return itemStore;
              })
            : null,
      };
    });

    const formObjs = {
      groupName: fields.name,
      modules: getValues("modules"),
      companies: dataTable,
    };
    submitGroup(formObjs);
  };

  useEffect(() => {
    if (!isLoadingModules && dataModules.modules.length > 0) {
      setModulesList(dataModules.modules);
    }
  }, [dataModules, isLoadingModules, setModulesList]);

  return (
    <S.Container>
      <S.Section>
        <Card
          headerColor="#256925"
          title={id ? "EDITAR GRUPO DE ACESSO" : "CRIAR NOVO GRUPO DE ACESSO"}
          iconName="person"
          action={
            id && (
              <BtnRemove
                isDisabled={!hasRemove}
                id={id}
                hook={useAccessGroupRemove}
              />
            )
          }
        >
          <FormProvider {...formMethods}>
            <S.Form onSubmit={handleSubmit(onSave)}>
              <S.TopPart>
                <S.CollumForm>
                  <GroupForm
                    errors={errors}
                    setGroupModules={setGroupModules}
                    setNewSetOfModules={setNewSetOfModules}
                    selectedCompany={selectedCompany}
                    control={control}
                    setValue={setValue}
                  />
                  <GroupFormSelects
                    isLoadingCompanies={isLoadingCompanies}
                    dataCompanies={dataCompanies}
                    selectedCompany={selectedCompany}
                    currentId={currentId}
                    setCurrentId={setCurrentId}
                    control={control}
                    setValue={setValue}
                    tableData={tableData}
                    setTableData={setTableData}
                    tableCompanyValue={tableCompanyValue}
                    setTableCompanyValue={setTableCompanyValue}
                    tableStoreValue={tableStoreValue}
                    setTableStoreValue={setTableStoreValue}
                  />
                  <TableList
                    newSetOfModules={newSetOfModules}
                    setNewSetOfModules={setNewSetOfModules}
                    isLoadingCompanies={isLoadingCompanies}
                    dataCompanies={dataCompanies}
                    tableData={tableData}
                    setTableData={setTableData}
                  />
                  <S.DividerH />
                  <PermiForm
                    id={id}
                    control={control}
                    selectedCompany={selectedCompany}
                    modulesList={modulesList}
                    setModulesList={setModulesList}
                    allRuleValues={allRuleValues}
                    setAllRuleValues={setAllRuleValues}
                    groupModules={groupModules}
                    setGroupModules={setGroupModules}
                    newSetOfModulesGroups={newSetOfModulesGroups}
                    setNewSetOfModulesGroups={setNewSetOfModulesGroups}
                    isLoading={isLoadingModules}
                  />
                </S.CollumForm>
              </S.TopPart>
              <S.Footer>
                <Button
                  goBack
                  type="button"
                  color="gray"
                  style={{ marginRight: 15 }}
                >
                  Voltar
                </Button>
                <Button
                  isDisabled={!hasEdit || !hasCreate}
                  isLoading={isLoading}
                  type="submit"
                >
                  SALVAR
                </Button>
              </S.Footer>
            </S.Form>
          </FormProvider>
        </Card>
      </S.Section>
    </S.Container>
  );
};

export const CreateEditGroup = () => {
  const { id } = useParams();
  return <CreateEditGroupContainer id={id === "new" ? null : id} />;
};
