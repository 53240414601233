import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";

import { useProductsContext } from "context/ProductsContext";

import { treatFilter } from "utils";

import { useProductsMixSearching } from "useCases";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faLock, faSpinner } from "@fortawesome/free-solid-svg-icons";

import * as S from "../FormMainName.styles";

export const ItemList = ({ isLoading, isLoadingLabel, value, perFilter }) => {
  const { setIsShowDialog, handleNewList, setFIlters, toogleFilter } =
    useProductsContext();

  const handleOpenModal = () => {
    setIsShowDialog({
      show: true,
      type: "agrupados",
      labelBtn: "Agrupar",
    });

    const filters = treatFilter(perFilter);

    toogleFilter();
    setFIlters([...perFilter]);
    handleNewList([...filters]);
  };

  return (
    <S.ContainerNameDescriptionLi
      color={isLoading ? "red" : "#1b66b0"}
      onClick={() => handleOpenModal()}
    >
      <div>
        {isLoading && (
          <>
            <FontAwesomeIcon
              spin
              icon={faSpinner}
              style={{
                fontSize: "15px",
              }}
            />
            {isLoadingLabel}
          </>
        )}

        <b>{` ${value}`}</b>
      </div>
    </S.ContainerNameDescriptionLi>
  );
};

export const ItemEanValid = ({ isLoading, total, item = {}, history }) => {
  return (
    <S.ContainerEanValid color={total > 0 ? "#f37979" : "#256925"}>
      <h3>Validação EAN</h3>
      <S.LoadingItem>
        {isLoading && total === 0 ? (
          <FontAwesomeIcon
            spin
            icon={faSpinner}
            style={{
              fontSize: "15px",
            }}
          />
        ) : (
          <FontAwesomeIcon
            icon={total > 0 ? faLock : faCheck}
            color={total > 0 ? "red" : "green"}
            style={{
              fontSize: "20",
            }}
          />
        )}
      </S.LoadingItem>
      <S.ContainerEanValidBody>
        {isLoading ? (
          "Válidando ean..."
        ) : (
          <>
            {total > 0 ? (
              <p>EAN Existente na base de dados</p>
            ) : (
              <>
                <p>Ean não existente na base</p>
                <p>Continue o cadastro</p>
              </>
            )}
          </>
        )}
      </S.ContainerEanValidBody>

      {total > 0 && (
        <S.ButtonGrupos
          onClick={() =>
            history.push(`/product/detail/${item?.id || item?._id}`)
          }
        >
          Ir para o detalhe
        </S.ButtonGrupos>
      )}
    </S.ContainerEanValid>
  );
};

export const MainMameView = ({
  productId,
  value,
  type,
  handleOpenModal,
  eanValue,
  setValue,
}) => {
  const [params] = useState({
    page: 1,
    limit: 100,
  });

  const history = useHistory();

  const [totalEqualsItems, setTotalEqualsItems] = useState(0);

  const [totalConteainsItems, setTotalConteainsItems] = useState(0);

  const [totalEan, setTotalEan] = useState(0);
  const [itemEanExistent, setItemEanExistent] = useState({});

  const [totalEanAndValue, setTotalEanAndValue] = useState(0);

  const [isGetConteains, setIsGetConteians] = useState(false);

  const {
    data: dataEquals,
    isLoading: isloadingEquals,
    totalDocs: totalDocsEquals,
    fetch: fetchSearching,
  } = useProductsMixSearching();

  const {
    data: dataEanAndName,
    totalDocs: totalDocsEanAndName,
    isLoading: isLoadingEanAndName,
    fetch: fetchSearchingEanAndName,
  } = useProductsMixSearching();

  const {
    data: dataConteains,
    totalDocs: totalDocsConteains,
    isLoading: isLoadingConteains,
    fetch: fetchSearchingConteains,
  } = useProductsMixSearching();

  const {
    data: dataEanItems,
    isLoading: isLoadingEan,
    fetch: fetchSearchingEan,
  } = useProductsMixSearching();

  useEffect(() => {
    if (!value) {
      setTotalEqualsItems(0);
      setTotalConteainsItems(0);
      setIsGetConteians(false);
    }
  }, [value, setTotalEqualsItems, setTotalConteainsItems, setIsGetConteians]);

  useEffect(() => {
    if (value && !productId) {
      const timer = setTimeout(() => {
        setTotalEqualsItems(0);
        setIsGetConteians(true);
        fetchSearching({
          ...params,
          filters: [
            {
              prod_nome: {
                value,
                operator: "or",
                operatorValue: "equals",
              },
            },
          ],
        });
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [value, type, fetchSearching, setTotalEqualsItems, params, productId]);

  useEffect(() => {
    if (isGetConteains && !productId) {
      const timer = setTimeout(() => {
        setTotalConteainsItems(0);
        setIsGetConteians(false);
        fetchSearchingConteains({
          ...params,
          filters: [
            {
              prod_nome: {
                value,
                operator: "or",
                operatorValue: "contains",
              },
            },
          ],
        });
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [
    value,
    isGetConteains,
    productId,
    params,
    setTotalConteainsItems,
    fetchSearchingConteains,
    setIsGetConteians,
  ]);

  useEffect(() => {
    if (eanValue && !productId) {
      const timer = setTimeout(() => {
        setTotalEan(0);
        fetchSearchingEan({
          ...params,
          prod_codigo_barras: {
            value: eanValue,
            operatorValue: "equals",
            operator: "or",
          },
        });
      }, 1200);
      return () => clearTimeout(timer);
    }
  }, [eanValue, params, fetchSearchingEan, setTotalEan, productId]);

  useEffect(() => {
    if (eanValue && value) {
      const timer = setTimeout(() => {
        setTotalEanAndValue(0);
        fetchSearchingEanAndName({
          ...params,
          filters: [
            {
              prod_nome: {
                operator: "or",
                value,
                operatorValue: "equals",
              },
            },
            {
              prod_codigo_barras: {
                value: eanValue,
                operatorValue: "equals",
                operator: "or",
              },
            },
          ],
        });
      }, 1400);
      return () => clearTimeout(timer);
    }
  }, [eanValue, value, params, setTotalEanAndValue, fetchSearchingEanAndName]);

  useEffect(() => {
    if (dataEquals && totalDocsEquals && !isloadingEquals) {
      setTotalEqualsItems(totalDocsEquals);
    }
  }, [dataEquals, isloadingEquals, totalDocsEquals, setTotalEqualsItems]);

  useEffect(() => {
    if (dataConteains && totalDocsConteains && isLoadingConteains) {
      setTotalConteainsItems(totalDocsConteains);
    }
  }, [
    dataConteains,
    isLoadingConteains,
    totalDocsConteains,
    setTotalConteainsItems,
  ]);

  useEffect(() => {
    if (dataEanItems && isLoadingEan) {
      setItemEanExistent(dataEanItems[0]);
      setTotalEan(dataEanItems.length);
    }
  }, [dataEanItems, isLoadingEan, setTotalEan]);

  useEffect(() => {
    if (dataEanAndName && totalDocsEanAndName && !isLoadingEanAndName) {
      setTotalEanAndValue(totalDocsEanAndName);
    }
  }, [
    dataEanAndName,
    isLoadingEanAndName,
    totalDocsEanAndName,
    setTotalEanAndValue,
  ]);

  return (
    <S.ContainerName>
      <S.ContainerItem width="80%">
        <S.ContainerNameTitle>
          {value || `EX: Biscoito Recheado Bono Chocolate Unidade`}
        </S.ContainerNameTitle>

        <S.ContainerNameDescriptionUl>
          {value && (
            <ItemList
              handleOpenModal={handleOpenModal}
              isLoading={isloadingEquals}
              total={totalEqualsItems}
              perFilter={[
                {
                  columnField: {
                    columnOrField: "and",
                    columnField: "prod_nome",
                    operatorValue: "equals",
                    value,
                  },
                },
              ]}
              isLoadingLabel={
                isloadingEquals &&
                value &&
                `Estamos verificando se existente descrições iguais: `
              }
              value={
                !isloadingEquals && value && totalEqualsItems > 0
                  ? `Total de Produtos iguais a descrição inserida: ${totalEqualsItems}`
                  : isloadingEquals
                  ? value
                  : `Descrição valida para cadastro`
              }
            />
          )}
          {value && (
            <ItemList
              handleOpenModal={handleOpenModal}
              isLoading={isLoadingConteains}
              total={totalConteainsItems}
              perFilter={[
                {
                  columnField: {
                    columnOrField: "and",
                    columnField: "prod_nome",
                    operatorValue: "contains",
                    value,
                  },
                },
              ]}
              isLoadingLabel={
                isLoadingConteains &&
                value &&
                `Estamos verificando quantos produtos contem descrição:`
              }
              value={
                !isLoadingConteains && value && totalConteainsItems > 0
                  ? `Total de Produtos contem a descrição inserida: ${totalConteainsItems}`
                  : isLoadingConteains
                  ? value
                  : `Descrição valida para cadastro`
              }
            />
          )}

          {value && eanValue && (
            <ItemList
              handleOpenModal={handleOpenModal}
              isLoading={isLoadingEanAndName}
              total={totalEanAndValue}
              perFilter={[
                {
                  columnField: {
                    columnOrField: "and",
                    columnField: "prod_nome",
                    operatorValue: "equals",
                    value,
                  },
                },
                {
                  columnField: {
                    columnOrField: "and",
                    columnField: "prod_codigo_barras",
                    operatorValue: "equals",
                    value: eanValue,
                  },
                },
              ]}
              isLoadingLabel={
                isLoadingEanAndName &&
                value &&
                eanValue &&
                `Estamos verificando se existe produtos com a Descrição e o Ean inseridos:`
              }
              value={
                !isLoadingEanAndName && value && totalEanAndValue > 0
                  ? `Total de produtos que batem com a Descrição e o Ean inseridos:  ${totalEanAndValue}`
                  : isLoadingEanAndName
                  ? `${value} + ${eanValue}`
                  : `Descrição e Ean validos para cadastro para cadastro`
              }
            />
          )}
        </S.ContainerNameDescriptionUl>
      </S.ContainerItem>
      {eanValue && !productId ? (
        <S.ContainerItem width="20%">
          <ItemEanValid
            isLoading={isLoadingEan}
            total={totalEan}
            value={eanValue}
            item={itemEanExistent}
            setValue={setValue}
            history={history}
          />
        </S.ContainerItem>
      ) : (
        ``
      )}
    </S.ContainerName>
  );
};
