import { useEffect } from "react";
import { useRegisterLawsGradesContext } from "context/RegisterLawsContext";

import { useRegisterLaws } from "hooks/useRegisterLaws";

export const useWatch = () => {
  const { values } = useRegisterLaws();

  const { addNcms, addSceneries, addCests, setTax } =
    useRegisterLawsGradesContext();

  useEffect(() => {
    if (values?.ncms) addNcms(values?.ncms);
  }, [values?.ncms, values?.ncms?.length, addNcms]);

  useEffect(() => {
    if (values?.cenarios) addSceneries(values?.cenarios);
  }, [values?.cenarios, values?.cenarios?.length, addSceneries]);

  useEffect(() => {
    if (values?.cests) addCests(values?.cests);
  }, [values?.cests, values?.cests?.length, addCests]);

  useEffect(() => {
    if (values?.taxa) setTax(values?.taxa);
  }, [values?.taxa, setTax]);
};
