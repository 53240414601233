export const COLLUNS = [
  {
    field: "id",
    headerText: "",
    width: 100,
    sortable: true / false,
  },
  {
    field: "prod_nome",
    headerText: "Nome do Produto (Descrição)",
    minWidth: 500,
    sortable: true / false,
  },
  {
    field: "prod_codigo_barras",
    headerText: "EAN",
    width: 170,
    sortable: true / false,
  },
  {
    field: "ncm",
    headerText: "NCM",
    width: 150,
    sortable: true / false,
  },
  {
    field: "ncm_ex",
    headerText: "NCM EX",
    width: 150,
    sortable: true / false,
  },
  {
    field: "mer_departamento",
    headerText: "DEPARTAMENTO",
    width: 180,
    sortable: true / false,
  },
  {
    field: "mer_setor",
    headerText: "SETOR",
    width: 150,
    sortable: true / false,
  },
  {
    field: "mer_grupo",
    headerText: "GRUPO",
    width: 250,
    sortable: true / false,
  },
  {
    field: "mer_subgrupo",
    headerText: "SUBGRUPO",
    width: 250,
    sortable: true / false,
  },
];
