import { useCallback } from "react";
import { useAPIAuthenticateToast } from "hooks";

export const useLawsBulkGradesMerge = () => {
  const { isLoading, onSend } = useAPIAuthenticateToast();

  const onMerge = useCallback(
    data =>
      onSend({
        endpoint: `/laws/bulk/merge-grades`,
        messageSuccess: "Assessor foi removido com sucesso!",
        messageFail: "Assessor não foi removido",
        data,
      }),
    [onSend],
  );

  return {
    onMerge,
    isLoading,
  };
};
