import React from "react";
import * as S from "../styles";

export const PermissionForm = ({ register, data }) => {
  return (
    <>
      <S.SectionCheckbox>
        <p>Permissões de Exportação</p>
        <div>
          <div className="checkBox">
            <input
              {...register("export_xml")}
              type="checkbox"
              name="export_xml"
              defaultChecked={data?.permissions?.export?.xml}
            />
            <p>Exportar XML</p>
          </div>
          <div className="checkBox">
            <input
              {...register("export_pdf")}
              type="checkbox"
              name="export_pdf"
              defaultChecked={data?.permissions?.export?.pdf}
            />
            <p>Exportar PDF</p>
          </div>
        </div>
        <div>
          <div className="checkBox">
            <input
              {...register("export_csv")}
              type="checkbox"
              name="export_csv"
              defaultChecked={data?.permissions?.export?.csv}
            />
            <p>Exportar CSV</p>
          </div>
          <div className="checkBox">
            <input
              {...register("export_txt")}
              type="checkbox"
              name="export_txt"
              defaultChecked={data?.permissions?.export?.txt}
            />
            <p>Exportar TXT</p>
          </div>
        </div>
      </S.SectionCheckbox>
      <S.SectionCheckbox>
        <p>Permissões de Importação</p>
        <div>
          <div className="checkBox">
            <input
              {...register("import_xml")}
              type="checkbox"
              name="import_xml"
              defaultChecked={data?.permissions?.import?.xml}
            />
            <p>Importar XML</p>
          </div>
          <div className="checkBox">
            <input
              {...register("import_pdf")}
              type="checkbox"
              name="import_pdf"
              defaultChecked={data?.permissions?.import?.pdf}
            />
            <p>Importar PDF</p>
          </div>
        </div>
        <div>
          <div className="checkBox">
            <input
              {...register("import_csv")}
              type="checkbox"
              name="import_csv"
              defaultChecked={data?.permissions?.import?.csv}
            />
            <p>Importar CSV</p>
          </div>
          <div className="checkBox">
            <input
              {...register("import_txt")}
              type="checkbox"
              name="import_txt"
              defaultChecked={data?.permissions?.import?.txt}
            />
            <p>Importar TXT</p>
          </div>
        </div>
      </S.SectionCheckbox>
    </>
  );
};
