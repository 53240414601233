import React, { useState, useEffect, useCallback } from "react";
import { Icon, Tooltip } from "@mix/mixfiscal-designsystem";
import { useParams, useHistory } from "react-router-dom";
// import { useToast } from "../../context/ToastContext";
import authenticationApi from "../../services/authenticationApi";

import DataTable from "../../components/DataTable";
import { Container } from "./styles";

function Report() {
  const [isEditing, setIsEditing] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const [data, setData] = useState([]);

  const { id } = useParams();
  const history = useHistory();

  console.log({ isEditing, setDescription });

  const getReportData = useCallback(async () => {
    const token = localStorage.getItem("@mixfiscal:authenticatorToken");
    if (id) {
      const {
        data: { reports: report },
      } = await authenticationApi.get(`/report/${id}`, {
        headers: {
          Authorization: token,
        },
      });

      if (report) {
        setTitle(report.name);
        setData(JSON.parse(report.content));
      }
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      getReportData();
    }
  }, [getReportData, id]);

  return (
    <Container>
      <div className="board-wrapper">
        <section className="column1">
          <div
            role="button"
            style={{ marginLeft: "auto", cursor: "pointer" }}
            onClick={() => history.push(`/relatorio/${id}`)}
          >
            <Tooltip title="Editar Relatório" position="top">
              <Icon name="editar" color="#000" size={24} />
            </Tooltip>
          </div>
          <div>
            <div className="board-row small-size no-border">
              <h2>{title}</h2>
            </div>
          </div>
          {description && (
            <div className="board-row small-size no-border">
              <p role="button" onClick={() => setIsEditing("description")}>
                {description}
              </p>
            </div>
          )}
          <div
            className="board-row large-size flex-column no-border"
            style={{ marginTop: "20px" }}
          >
            {data &&
              data.length > 0 &&
              data.map(dataTableValues => {
                return (
                  <DataTable
                    key={Math.random()}
                    rawData={dataTableValues.rows}
                    columns={dataTableValues.columns}
                  />
                );
              })}
          </div>
        </section>
      </div>
    </Container>
  );
}

export default Report;
