import React, { useState } from "react";
import { Datatable, Pagination } from "@mix/mixfiscal-designsystem";
import { useHistory } from "react-router-dom";
import IconComponent from "components/IconExporter/index";
import { useACL } from "context";

import { useUsers } from "useCases";
import Card from "components/UI/Card";

import { CardHeader } from "components/contexts/users-and-permissions";
import { CardFilter } from "./Filter";

import * as S from "../../styles";

export const ListUsers = ({ baseUrl, selectedCompany }) => {
  const history = useHistory();
  const { hasEdit, hasCreate } = useACL("users");
  const [search, setSearch] = useState("");
  const [invitUsers, setInvitUsers] = useState(false);
  const [isShowFilter, setIsShowFIlter] = useState(false);
  const [limit, setLimit] = useState(100);
  const [page, setPages] = useState(1);

  const { isLoading: isLoadingUsers, data: dataUsers } = useUsers({
    search,
    page,
    limit,
    companyId:
      selectedCompany && selectedCompany.length > 0
        ? selectedCompany[0]._id
        : "",
  });

  const userButton = _id => {
    const onclick = () => {
      if (hasEdit) {
        history.push(`${baseUrl}/${_id}`);
      }
    };
    return (
      <div role="button" onClick={onclick}>
        <IconComponent name="edit" className="edit-icon" />
      </div>
    );
  };

  return (
    <>
      <Card title="USUÁRIOS" headerColor="#256925" iconName="erp">
        <>
          <CardHeader
            url={`${baseUrl}/new`}
            urlItem={`${baseUrl}/convidar-usuario`}
            title="Selecione uma usuário para editar."
            totalItems={dataUsers?.users?.totalDocs}
            limitItems={limit}
            permission={{ post: hasCreate }}
            labelFilterBtn="FILTRO"
            btnItemLabel="CONVIDAR"
            labelButtonAdd="CRIAR USUÁRIO"
            isShowItem={isShowFilter}
            setIsShowItem={setIsShowFIlter}
            valueItem={invitUsers}
            setItem={setInvitUsers}
          />
          {dataUsers ? (
            <>
              <CardFilter
                isShowFilter={isShowFilter}
                setIsShowFIlter={setIsShowFIlter}
                setSearch={setSearch}
              />
              <S.CardSubItemPagination>
                <Pagination
                  handlePageChange={setPages}
                  initialPage={page}
                  siblingsCount={1}
                  totalPages={dataUsers?.users?.totalPages}
                  amountVariations={[10, 20, 30, 300, 500]}
                  handleChangeAmount={setLimit}
                />
              </S.CardSubItemPagination>
              <Datatable
                pagination={false}
                amountVariations={[10, 20, 30, 300, 500]}
                isLoading={isLoadingUsers}
                size="12px"
                columns={[
                  { field: "action", title: "" },
                  { field: "name", title: "NOME" },
                  { field: "surname", title: "SOBRENOME" },
                  { field: "email", title: "EMAIL" },
                ]}
                rawData={dataUsers?.users?.docs.map(user => ({
                  action: userButton(user._id),
                  name: user.name,
                  surname: user.surname,
                  email: user.email,
                }))}
              />
            </>
          ) : (
            `Nenhum usuario cadastrado`
          )}
        </>
      </Card>
    </>
  );
};
