import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px;

  & > input {
    width: 200px;
    border-bottom: 1px solid #e5e5e5;

    margin-bottom: 16px;
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      border: 1px solid #ccc;
      border-radius: 8px;
      background-color: transparent;
      padding: 4px 8px;
      transition: all 0.2s;
    }

    button:hover {
      background-color: #ccc;
      color: #fff;
    }

    button:not(:last-of-type) {
      margin-right: 8px;
    }
  }
`;
