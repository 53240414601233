import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  width: fit-content;
  z-index: 10;
  height: 35px;

  /* p {
    color: ${({ theme }) => theme.colors.slateGray};
    font-size: 15px;
    margin: 8px;
    span {
      cursor: pointer;
      display: inline-block;
      font-weight: bold;
      margin: 0 4px;
      text-decoration: underline;

      div {
        display: block;
      }
    }
  } */

  & ul > li {
    align-items: center;
    color: #000;
    cursor: pointer;
    display: flex;
    list-style-type: none;
    transition: all 0.1s ease-in-out;
  }
  ul > li {
    &:hover {
      background-color: #0002;
      border-radius: 3px;
    }
  }

  .clients {
    color: #000;
    flex-direction: column;
  }

  > input {
    cursor: pointer;
    margin: 10px;
  }

  > label {
    margin: 8px;
    overflow-x: hidden;
    width: 150px;
  }

  .drop_client div {
    margin-left: -100px;
    padding: 0px;
    width: 200px;

    li:first-child {
      background-color: #0002;
      border-radius: 3px;
      font-weight: bold;
    }
  }

  .drop_store div {
    margin-left: -200px;
    padding: 0px;

    li:first-child {
      background-color: #0002;
      border-radius: 3px;
      font-weight: bold;
    }
  }
`;
export const ContainerItem = styled.div`
  display: relative;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadows.default};
  align-items: center;
  display: flex;
  gap: 8px;
  justify-content: center;
  margin: 0px 0px 0px 7px;
  padding: 0px 7px 0px 7px;
`;

export const WrapperAdvancedSearch = styled.div`
  padding: 0 8px;
  position: relative;
`;
export const WrapperAdvancedSearchAction = styled.div`
  display: flex;
`;

export const WrapperIcon = styled.div`
  cursor: pointer;
  padding: 15px 8px 8px 0;

  svg {
    transform: ${({ open }) => (open ? `rotate(180deg)` : `unset`)};

    transition: transform 0.2s;
  }
`;

export const SearchIcon = styled.button`
  background-color: transparent;
  display: flex;

  svg {
    margin-top: ${({ isLabel }) => (isLabel ? `8px` : `0`)};
  }
`;
export const SearchIconLabel = styled.span`
  padding-left: 5px;
`;
export const SearchSelect = styled.div`
  ${({ active }) => css`
    top: 0;
    right: 0;
    background: #fff;
    border-radius: 5px;
    position: absolute;
    padding-bottom: 9px;
    margin-top: 150px;
    margin-right: ${active ? `47px` : `0px`};
    min-width: 250px;
    visibility: ${active ? `visible` : `hidden`};
    opacity: ${active ? `1` : `0`};
    transition: all 250ms ease-out;
    box-shadow: ${({ theme }) => theme.shadows.default};
  `}
`;

export const SearchSelectItem = styled.div`
  ${({ active }) => css`
    display: ${active ? `flex` : `none`};
  `}
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-height: 60px;
  padding-top: 10px;
`;

export const LoadingContainer = styled.div`
  display: flex;
  height: ${({ height }) => height || 30}px;
  justify-content: center;
`;
