import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  gap: 24px;
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const TopPart = styled.div`
  display: flex;
  margin-bottom: 100px;
`;

export const ColumnFormItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const CollumForm = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 8px 5px 8px;
  width: 100%;
  ${ColumnFormItem} {
    &:not(:last-of-type) {
      margin-bottom: 10px;
    }
  }
`;

export const Divider = styled.div`
  width: 1px;
  align-self: stretch;
  background-color: #0000002e;
  margin: 0 36px;
`;
export const Footer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 38px;
  justify-content: center;
`;
