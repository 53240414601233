import React from "react";
import { AppNotaFiscal } from "@hdn/mixfiscal-nfe-frontend";
import "@hdn/mixfiscal-nfe-frontend/dist/index.css";

const NotasFiscaisTax = () => (
  <>
    <AppNotaFiscal
      token="123"
      width="100%"
      height="100%"
      currentRoute="/nota-fiscal/tax-note57"
      onChangeRoute={() => {}}
    />
  </>
);

export default NotasFiscaisTax;
