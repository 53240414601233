import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { BLANK_SELECT_LIST } from "constants/types";

const schema = yup.object().shape({
  firstName: yup.string().required(),
  age: yup.number().positive().integer().required(),
});

export const defaultValues = {
  type_organization: BLANK_SELECT_LIST[0],
  types: {
    icms: false,
    cofins: false,
    pis: false,
  },
  publish_date: new Date(2021, 6, 16),
  vigency_date: new Date(2021, 6, 18),
  validate_date: new Date(2021, 6, 19),
  operacao_e: BLANK_SELECT_LIST[0],
  operacao_s: BLANK_SELECT_LIST[0],
  ncm_cest: "",
  ncm_tipi: "",
  state_entry: BLANK_SELECT_LIST[0],
  state_out: BLANK_SELECT_LIST[0],
  caract_nao_tributadas: [
    {
      name: "",
      value: "",
      id: "",
    },
  ],
  cst_e: {
    tributado: false,
    credito: false,
    alq_zero: false,
    substituicao_tributaria: false,
  },
  cst_s: {
    tributado: false,
    credito: false,
    alq_zero: false,
    substituicao_tributaria: false,
  },
  csosn: {
    imune_icms: false,
    nao_tributada_simples_nacional: false,
    icms_cobrado_anteriormento: false,
  },
  alq_icms_e: {
    18: false,
    12: false,
    "06": false,
    0: false,
  },
  alq_icms_s: {
    18: false,
    12: false,
    "06": false,
    0: false,
  },
  alq_piscofins_e: {
    18: false,
    12: false,
    "06": false,
    0: false,
  },
  alq_piscofins_s: {
    18: false,
    12: false,
    "06": false,
    0: false,
  },
  alq_reducao_base_calc_e: {
    18: false,
    12: false,
    "06": false,
    0: false,
  },
  alq_reducao_base_calc_s: {
    18: false,
    12: false,
    "06": false,
    0: false,
  },
  alq_carga_tributaria_e: {
    18: false,
    12: false,
    "06": false,
    0: false,
  },
  alq_carga_tributaria_s: {
    18: false,
    12: false,
    "06": false,
    0: false,
  },
  efeito_tributario_sku: {
    alq: false,
    onerados: false,
  },
  cenarios: [],
  ncms: [],
  cests: [],
};

export default yupResolver(schema);
