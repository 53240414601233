import React, { useState, useEffect, useRef } from "react";
import { useOnClickOutside } from "hooks";
import { useAuth } from "context/AuthContext";
import { useUser } from "useCases/user";
import { Icon, Tooltip } from "@mix/mixfiscal-designsystem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faCircleXmark,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import theme from "styles/theme";
import { usePermissions } from "context/PermissionsContext";
import { useToast } from "context/ToastContext";
import { UseCompaniesSearching } from "useCases";

import { CardAdvancedSearch } from "components/contexts/dashboard/advanced-search/card";
import { LabelPermissions } from "components/contexts/dashboard/advanced-search/label-permissions";
import { Preload } from "components/structure/preload";
import * as S from "./styles";

function Permissions({ activeTheme }) {
  const { data: dataUser } = useUser();
  const { setCompaniesSessionToken } = useAuth();
  const ref = useRef();
  const auth = useAuth();
  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);
  const [time, setTime] = useState(Date.now());
  const {
    isLoadingDashBoards,
    selectedCompany,
    stores,
    setCompaniesSearch,
    selectedStore,
    selectedCompanySearch,
    refetchPermissionsAll,
  } = usePermissions();

  const { data: dataCompaniesSearch } = UseCompaniesSearching({});

  // TODO: TOAST ADD
  const { addToast } = useToast();

  // TODO: VARIABLES "IS"
  const [isShowSearch, setIsShowSearch] = useState(false);

  // TODO: Advanced search
  const blockToggle = () => Date.now() - time < 120;

  const toggleAdvancedSearch = () => {
    if (blockToggle()) return;

    setShowAdvancedSearch(true);
  };

  // TODO: ADVANCED SEARCH
  useEffect(() => setTime(Date.now()), [showAdvancedSearch]);

  useOnClickOutside(ref, () => setIsShowSearch(false));

  // TODO: SEARCH ONCHANGE VALUE COMPANIES
  useEffect(() => {
    if (dataCompaniesSearch && dataCompaniesSearch.length === 0) {
      addToast({
        type: "error",
        title: "Erro ao buscar matriz",
        description: `Não existem matriz para essa busca.`,
      });
    }
    if (setCompaniesSearch) {
      setCompaniesSearch(dataCompaniesSearch);
    }
  }, [
    dataCompaniesSearch,
    setCompaniesSearch,
    selectedCompanySearch,
    addToast,
  ]);

  useEffect(() => {
    if (selectedCompany?.length && !stores?.length) {
      addToast({
        type: "error",
        title: "Erro ao selecionar uma Matriz",
        description: `Não existem Matriz para selecionar.`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stores]);

  const clearFilter = () => {
    refetchPermissionsAll();
    setCompaniesSessionToken(dataUser?.token, dataUser?.tokenBase);
  };

  return (
    <>
      {isLoadingDashBoards && <Preload label="Carregando dados..." />}
      <S.Container activeTheme={activeTheme}>
        <S.ContainerItem>
          <Icon
            size={21}
            name="resumo"
            color={
              activeTheme ? theme.colors.icon.navyBlue : theme.colors.icon.green
            }
          />
          <LabelPermissions
            showAdvancedSearch={showAdvancedSearch}
            setShowAdvancedSearch={setShowAdvancedSearch}
            isShowSearch={isShowSearch}
          />
          {auth.isAdvancedUser() && (
            <S.WrapperAdvancedSearch>
              <S.WrapperAdvancedSearchAction>
                {selectedCompany && selectedStore && (
                  <Tooltip body="Limpar filtro" position="top" zIndex={99999}>
                    <FontAwesomeIcon
                      onClick={() => clearFilter()}
                      icon={faCircleXmark}
                      style={{
                        marginTop: "22px",
                        marginRight: "10px",
                        fontSize: "12px",
                      }}
                      color="#596778"
                    />
                  </Tooltip>
                )}

                <S.WrapperIcon
                  open={showAdvancedSearch}
                  onClick={toggleAdvancedSearch}
                >
                  {!showAdvancedSearch ? (
                    <FontAwesomeIcon
                      icon={faMagnifyingGlass}
                      size="lg"
                      style={{
                        fontSize: "16px",
                        marginBottom: `3px`,
                      }}
                      color="#000"
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faXmark}
                      size="lg"
                      style={{
                        fontSize: "17px",
                        marginBottom: `3px`,
                      }}
                      color="#000"
                    />
                  )}
                </S.WrapperIcon>
              </S.WrapperAdvancedSearchAction>

              {showAdvancedSearch && (
                <CardAdvancedSearch
                  open={showAdvancedSearch}
                  close={() => setShowAdvancedSearch(false)}
                />
              )}
            </S.WrapperAdvancedSearch>
          )}
        </S.ContainerItem>
      </S.Container>
    </>
  );
}

export default Permissions;
