import React from "react";
import { Handle } from "react-flow-renderer";

import { Container } from "./styles";

function Circle({ data: { label } }) {
  return (
    <>
      <Container>
        <p>{label}</p>
      </Container>
      <Handle
        id={label.toLowerCase() === "fim" && "tail"}
        type={label.toLowerCase() === "fim" ? "target" : "source"}
        position={label.toLowerCase() === "fim" ? "top" : "bottom"}
        style={{
          width: "10px",
          height: "10px",
          background: `${
            label.toLowerCase() === "fim" ? "#7b867e" : "#0e7c34"
          }`,
          bottom: "-5px",
        }}
      />
    </>
  );
}

export default Circle;
