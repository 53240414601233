import React, { useState } from "react";

import { Alert, Button } from "components/structure";
import { useAnalyticsGrade } from "useCases/grid";
import { LawProductsDiffModal } from "components/contexts/law-grade/LawProductsDiffModal";
import * as S from "./LawReplicateInformations.styles";

const AlertContent = ({
  totalProducts = 0,
  totalProductsCompare = 0,
  idLaw,
  notInLawId,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  if (totalProducts === totalProductsCompare) {
    return null;
  }

  return (
    <div>
      <LawProductsDiffModal
        isOpen={isOpen}
        handleClose={() => setIsOpen(false)}
        handleCancel={() => setIsOpen(false)}
        handleConfirm={() => setIsOpen(false)}
        lawId={idLaw}
        notInLawId={notInLawId}
      />
      <p>
        A Lei selecionada possuí&nbsp;
        <b>{totalProductsCompare}</b>
        &nbsp;e a lei atual tem &nbsp;
        <b>{totalProducts}</b>
        &nbsp; produtosMix
      </p>
      <S.AlertDiffAction>
        <Button type="button" onClick={() => setIsOpen(true)}>
          Visualizar diferença
        </Button>
      </S.AlertDiffAction>
    </div>
  );
};

export const LawReplicateInformations = ({
  idLaw,
  notInLawId,
  totalProducts = 0,
}) => {
  const { data, isLoading } = useAnalyticsGrade({ lawId: idLaw });

  if (isLoading) {
    return null;
  }

  if (totalProducts === data?.gradesIcms?.totalProductsMix) {
    return null;
  }

  return (
    <>
      <Alert
        title="Alerta"
        content={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <AlertContent
            totalProducts={totalProducts}
            totalProductsCompare={data?.gradesIcms?.totalProductsMix ?? 0}
            idLaw={idLaw}
            notInLawId={notInLawId}
          />
        }
      />
    </>
  );
};
