import React from "react";
import { Route as ReactRouterDomRoute, Redirect } from "react-router-dom";

import { useAuth } from "context/AuthContext";

// const MobileScreen = styled.div`
//   width: 100%;
//   height: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `;

export const RouteShield = ({
  isPrivate = false,
  component: Component,
  ...rest
}) => {
  const { token } = useAuth();

  // if (window.innerWidth < 1024) {
  //   return (
  //     <ReactRouterDomRoute
  //       to="/"
  //       render={() => (
  //         <MobileScreen>
  //           <h1>Essa aplicação só está disponível em telas acima de 1024px</h1>
  //         </MobileScreen>
  //       )}
  //     />
  //   );
  // }

  return (
    <ReactRouterDomRoute
      {...rest}
      render={() => {
        return isPrivate === !!token ? (
          <Component />
        ) : token ? (
          <Component />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
            }}
          />
        );
      }}
    />
  );
};

export const Route = ({ component: Component, ...rest }) => (
  <RouteShield {...rest} component={Component} />
);

export default Route;
