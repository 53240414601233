import React, { useCallback, useEffect, useState } from "react";
import { useRegisterLaws } from "hooks/useRegisterLaws";
import { Card } from "@mix/mixfiscal-designsystem";
import Dialog from "components/UI/Dialog";
import { useGradeFilters } from "hooks/useGrade";
import { useRegisterLawsGradesContext } from "context/RegisterLawsContext";

import { LawNcm as Ncm } from "components/contexts/law-grade/LawNcm";
import { LawCest as Cest } from "components/contexts/law-grade/LawCest";
import { LawScenery as Scenery } from "components/contexts/law-grade/LawScenery";

import { ListProducts } from "../ResultsProducts/ListProducts";
import { List } from "../List";

import * as S from "./MoveProductsModal.styles";

export const MoveProductsModal = ({
  isOpen = false,
  handleClose,
  handleConfirm,
  productsSelecteds = [],
}) => {
  const [dialogConfirm, setDialogConfirm] = useState(false);
  const [grades, setGrades] = useState([]);
  const { setValues, values } = useRegisterLaws();
  const { values: lawsValues } = useRegisterLawsGradesContext();

  const params = useGradeFilters({
    values,
  });

  const handleSelect = ({ type, value }) => {
    if (type === "add") {
      return setGrades(state => [...state, ...[value]]);
    }

    return setGrades(state => state.filter(gradeItem => gradeItem !== value));
  };

  const handleActionConfirm = useCallback(
    () => handleConfirm(grades),
    [handleConfirm, grades],
  );

  const handleActionPopupConfirm = () => setDialogConfirm(true);
  const handleActionPopupCancel = () => setDialogConfirm(false);

  useEffect(() => {
    setValues({
      cenarios: lawsValues.cenarios,
      ncms: lawsValues.ncms,
      cests: lawsValues.cests,
      taxa: lawsValues.taxa,
    });
  }, [
    setValues,
    lawsValues?.cenarios,
    lawsValues?.ncms,
    lawsValues?.cests,
    lawsValues?.taxa,
  ]);

  useEffect(() => {
    if (!isOpen) {
      setDialogConfirm(false);
      setGrades([]);
    }
    return () => setDialogConfirm(false);
  }, [isOpen, setDialogConfirm]);

  return (
    <>
      <Dialog
        isOpen={dialogConfirm}
        title="Alerta"
        handleConfirm={handleActionConfirm}
        zIndex="100002"
        handleCancel={handleActionPopupCancel}
        handleClose={handleActionPopupCancel}
      >
        <h3 style={{ textAlign: "center", marginTop: 10 }}>
          Deseja realmente mover esses produtos ?
        </h3>
      </Dialog>
      <Dialog
        isOpen={isOpen}
        title="Mover produtos para outra grade"
        handleClose={handleClose}
        handleConfirm={handleActionPopupConfirm}
        fullScreen
      >
        <S.ModalContent>
          {(values.taxa?.pis || values.taxa?.cofins || values.taxa?.icms) && (
            <Card
              headerColor="#E5E5E5"
              textColor="#304156"
              title="CENÁRIO E NORMAS"
              className="flatCard"
            >
              <Scenery scenery={values?.scenery} />
              <br />
            </Card>
          )}
          <Ncm hasRemoveNcmGradeContext={false} />
          <Cest hasRemoveNcmGradeContext={false} />
          <Card
            headerColor="#E5E5E5"
            textColor="#304156"
            title="Grades"
            className="flatCard"
          >
            <List params={params} hasSelect handleSelect={handleSelect} />
          </Card>
          <Card
            headerColor="#E5E5E5"
            textColor="#304156"
            title="Produtos"
            className="flatCard"
          >
            <ListProducts
              data={productsSelecteds.productsMove}
              isAction={false}
            />
          </Card>
        </S.ModalContent>
      </Dialog>
    </>
  );
};
