import React, { useState, createContext, useCallback, useContext } from "react";
import { useToast } from "hooks";

const AppContext = createContext({});

const defaultValues = {
  appBusinessIntelligence: false,
  disableAppBusinessIntelligence: false,
};

export const AppProvider = ({ children }) => {
  const { addToast } = useToast({});
  const [appState, setAppState] = useState(defaultValues);

  const setBusinessIntelligence = useCallback(value => {
    setAppState(state => ({ ...state, appBusinessIntelligence: value }));
  }, []);

  const setDisableBusinessIntelligence = useCallback(value => {
    setAppState(state => ({ ...state, disableAppBusinessIntelligence: value }));
  }, []);

  const handleCopy = (value, msg) => {
    if (!value) {
      return addToast({
        type: "error",
        align: "top",
        title: "Error ao copiar",
        description: `Não foi possível copiar esse item`,
      });
    }
    navigator.clipboard.writeText(value);
    return addToast({
      type: "success",
      align: "top",
      title: "Copiado com sucesso",
      description: `${msg || "Copiado"} com sucesso`,
    });
  };

  return (
    <AppContext.Provider
      value={{
        app: appState,
        setBusinessIntelligence,
        setDisableBusinessIntelligence,
        handleCopy,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useApp = () => {
  const context = useContext(AppContext);
  if (!context)
    throw new Error(
      "AppContext precisa ser utilizado dentro de um AppProvider",
    );
  return context;
};
