import React, { useEffect, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import MidCard from "components/contexts/dashboard/mid-card";
import { useGraphicConsult } from "useCases/productsConsult";
import { ResponsiveBar } from "@nivo/bar";
import { format, subMonths, subYears } from "date-fns";
import { LoadPlaceholder } from "components/structure/Load";
import { getTagTypeDashoard, getDatesMonths } from "utils/context/dashboard";

import { MidCardContainer, MidCardBlocOne, MidCardBlocTwo } from "../styles";

const BLANK_TEXT = "Sem dados para o período";
const theme = {
  labels: { text: { fontSize: 10 } },
};
const FormatDate = value => format(value, "yyyy-MM-dd");
function DashTickets(props) {
  const { monthDateStart, monthDateEnd, title, fileType } = props;
  const [blankValue, setBlankValue] = useState(0);
  const [vendasDateStart, setVendasDateStart] = useState(monthDateStart);
  const [vendasDateEnd, setVendasDateEnd] = useState(monthDateEnd);
  const [dataVendas, setDataVendas] = useState([]);
  const [tag, setTag] = useState(fileType ?? "XML");
  const [linearValues, setLinearValues] = useState([]);
  const [hasDates, setHasDates] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dataVendasOrig, setDataVendasOrig] = useState({
    vbruto: 0,
    vbrutoperiodo: 0,
    vbrutoano: 0,
    percentDiffFromLastPeriod: 0,
    percentDiffFromLastYearPeriod: 0,
  });

  const {
    isLoading: isLoadingCurrent,
    data: dataGraphicCurrent,
    fetch: fetchGraphicCurrent,
  } = useGraphicConsult();

  const {
    isLoading: isLoadingGraphicPastMonth,
    data: dataGraphicPastMonth,
    fetch: fetchGraphicPastMonth,
  } = useGraphicConsult();

  const {
    isLoading: isLoadingGraphicPastYear,
    data: dataGraphicPastYear,
    fetch: fetchGraphicPastYear,
  } = useGraphicConsult();

  const tagType = getTagTypeDashoard(tag);

  const getData = async (dtStart, dtEnd, current, pastMonth, pastYear) => {
    try {
      setIsLoading(true);

      setIsLoading(false);

      const getLabelPeriodo = id => {
        let periodo;
        if (id === "vbruto") {
          if (!current) {
            return BLANK_TEXT;
          }
          periodo = `${format(new Date(dtStart), "dd")} a ${format(
            new Date(dtEnd !== null ? dtEnd : dtStart),
            "yyyy-MM-dd",
          )}`;
        }

        if (id === "vbrutoperiodo") {
          if (!pastMonth) {
            return BLANK_TEXT;
          }
          periodo = `${format(new Date(dtStart), "dd")} a ${format(
            new Date(
              dtEnd !== null
                ? subMonths(new Date(dtEnd), 1)
                : subMonths(new Date(dtStart), 1),
            ),
            "yyyy-MM-dd",
          )}`;
        }
        if (id === "vbrutoano") {
          if (!pastYear) {
            return BLANK_TEXT;
          }
          periodo = `${format(new Date(dtStart), "dd")} a ${format(
            new Date(
              dtEnd !== null
                ? subYears(new Date(dtEnd), 1)
                : subYears(new Date(dtStart), 1),
            ),
            "dd/MM/yyyy",
          )}`;
        }

        return periodo;
      };
      const percentualDiff = (a, b) => {
        if (b > a) return -1 * (100 * Math.abs((a - b) / ((a + b) / 2)));
        return 100 * Math.abs((a - b) / ((a + b) / 2));
      };

      const percentDiffFromLastPeriod =
        current !== 0 ? percentualDiff(current, pastMonth) : 0;
      const percentDiffFromLastYearPeriod =
        current !== 0 ? percentualDiff(current, pastYear) : 0;

      const dVendasOrig = {
        vbruto: current,
        vbrutoperiodo: pastMonth,
        vbrutoano: pastYear,
        percentDiffFromLastPeriod,
        percentDiffFromLastYearPeriod,
      };

      const currentBlankValue =
        [current / 1000, pastMonth / 1000, pastYear / 1000].sort(
          (a, b) => b - a,
        )[0] - 0.02;

      setBlankValue(currentBlankValue);
      const dVendas = [
        {
          vbruto: current === 0 ? currentBlankValue : current / 1000,
          vbrutoperiodo: pastMonth === 0 ? currentBlankValue : pastMonth / 1000,
          vbrutoano: pastYear === 0 ? currentBlankValue : pastYear / 1000,
          dateLabelOne: getLabelPeriodo("vbruto"),
          dateLabelTwo: getLabelPeriodo("vbrutoperiodo"),
          dateLabelThree: getLabelPeriodo("vbrutoano"),
        },
      ];

      const lValue = Math.ceil(
        Math.max(current / 1000, pastMonth / 1000, pastYear / 1000),
      );

      let linear = [];
      if (lValue > 0 && lValue <= 600) {
        linear = [0, 120, 240, 360, 480, 600];
      }
      if (lValue > 600 && lValue <= 1000) {
        linear = [0, 600, 800, 900, 1000];
      }
      if (lValue > 1000 && lValue <= 3000) {
        linear = [0, 500, 1000, 1500, 2000, 3000];
      }
      if (lValue > 3000 && lValue <= 5000) {
        linear = [0, 1500, 3000, 4000, 5000];
      }
      if (lValue > 5000 && lValue <= 10000) {
        linear = [0, 2500, 5500, 8500, 10000];
      }
      if (lValue > 10000 && lValue <= 20000) {
        linear = [0, 5000, 10000, 150000, 20000];
      }
      setLinearValues(linear);
      setDataVendasOrig(dVendasOrig);
      setDataVendas(dVendas);
    } catch (e) {
      console.log(e);
    }
  };

  const handleClick = (start, end) => {
    const [startDate, endDate] = getDatesMonths(start, end);
    setVendasDateStart(startDate);
    setVendasDateEnd(endDate);
    if (end !== null) {
      getData(start, end);
    }
  };

  const handleClickType = item => {
    setTag(item);
  };

  useEffect(() => {
    const [dateStart, dateEnd] = getDatesMonths(monthDateStart, monthDateEnd);
    getData({
      current: dataGraphicCurrent?.data?.data ?? 0,
      pastMonth: dataGraphicPastMonth?.data?.data ?? 0,
      pastYear: dataGraphicPastYear?.data?.data ?? 0,
      dtStart: dateStart,
      dtEnd: dateEnd,
    });
  }, [
    dataGraphicCurrent?.data?.data,
    dataGraphicPastMonth?.data?.data,
    dataGraphicPastYear?.data?.data,
    monthDateEnd,
    monthDateStart,
  ]);

  useEffect(() => {
    if (hasDates && monthDateStart && monthDateEnd) {
      const [dateStart, dateEnd] = getDatesMonths(monthDateStart, monthDateEnd);
      fetchGraphicCurrent({
        dataMovimentoIni: FormatDate(dateStart),
        dataMovimentoFin: FormatDate(dateEnd),
        tipo: tagType,
        only: true,
      });
      fetchGraphicPastMonth({
        dataMovimentoIni: FormatDate(subMonths(dateStart, 1)),
        dataMovimentoFin: FormatDate(subMonths(dateEnd, 1)),
        tipo: tagType,
        only: true,
      });

      fetchGraphicPastYear({
        dataMovimentoIni: FormatDate(subYears(dateStart, 1)),
        dataMovimentoFin: FormatDate(subYears(dateEnd, 1)),
        tipo: tagType,
        only: true,
      });
    }
  }, [
    fetchGraphicCurrent,
    fetchGraphicPastMonth,
    fetchGraphicPastYear,
    hasDates,
    monthDateEnd,
    monthDateStart,
    tag,
    tagType,
  ]);

  useEffect(() => {
    const [comprasDateStartValue, monthDateEndValue] = getDatesMonths(
      monthDateStart,
      monthDateEnd,
    );
    setVendasDateStart(comprasDateStartValue);
    setVendasDateEnd(monthDateEndValue);
    if (monthDateStart && monthDateEnd) setHasDates(true);
  }, [monthDateStart, monthDateEnd]);

  return (
    <>
      <MidCard
        tag={fileType}
        title={title}
        addMonthsLength={0}
        typeList="month"
        subtitle=""
        total=""
        tagsList={[
          "XML",
          {
            dtStart: vendasDateStart,
            dtEnd: vendasDateEnd,
          },
        ]}
        handleClick={(start, end) => handleClick(start, end, "venda")}
        handleClickType={item => handleClickType(item)}
        cssClass="card-custom"
      >
        <MidCardContainer>
          <MidCardBlocOne>
            <LoadPlaceholder
              show={
                isLoadingCurrent ||
                isLoadingGraphicPastMonth ||
                isLoadingGraphicPastYear
              }
              showNoData={
                !(
                  isLoadingCurrent ||
                  isLoadingGraphicPastMonth ||
                  isLoadingGraphicPastYear
                ) && linearValues.length === 0
              }
            >
              <ResponsiveBar
                theme={theme}
                data={dataVendas}
                keys={["vbruto", "vbrutoperiodo", "vbrutoano"]}
                indexBy=""
                margin={{ top: 3, right: 20, bottom: 35, left: 25 }}
                padding={0.05}
                groupMode="grouped"
                layout="horizontal"
                valueScale={{ type: "linear" }}
                indexScale={{ type: "band", round: false }}
                valueFormat={{ format: " >-$", enabled: true }}
                colors={e => {
                  const colors = {
                    vbruto: "#A2C659",
                    vbrutoperiodo: "#cce39f",
                    vbrutoano: "#10b39d",
                  };

                  if (e.value === blankValue) {
                    return "rgb(255 255 255 / 24%)";
                  }

                  return colors[e.id];
                }}
                borderColor={{
                  from: "color",
                  modifiers: [["darker", 1.6]],
                }}
                axisTop={0}
                axisRight={0}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 0,
                  tickRotation: 0,
                  legend: "Valor (k)",
                  legendOffset: 30,
                  legendPosition: "middle",
                  tickValues: linearValues,
                }}
                axisLeft={{
                  tickSize: 0,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: "",
                  legendPosition: "middle",
                  legendOffset: -20,
                }}
                labelSkipWidth={10}
                labelSkipHeight={12}
                labelTextColor="#FFF"
                label={e =>
                  e.id === "vbruto"
                    ? e.data.dateLabelOne
                    : e.id === "vbrutoperiodo"
                    ? e.data.dateLabelTwo
                    : e.data.dateLabelThree
                }
                legends={[]}
                tooltip={e => {
                  const titleTip =
                    e.id === "vbruto"
                      ? "Valor Bruto"
                      : e.id === "vbrutoperiodo"
                      ? "Valor Bruto/Período"
                      : "Valor Bruto/Ano";
                  const value =
                    e.id === "vbruto"
                      ? e.data.vbruto
                      : e.id === "vbrutoperiodo"
                      ? e.data.vbrutoperiodo
                      : e.data.vbrutoano;
                  return (
                    <div
                      style={{
                        fontSize: "10px",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <span
                        style={{
                          width: "10px",
                          height: "10px",
                          backgroundColor: `${e.color}`,
                          marginRight: "5px",
                        }}
                      />
                      <p>
                        {`${titleTip}: ${
                          value === blankValue
                            ? BLANK_TEXT
                            : (value * 1000)?.toLocaleString("pt-br", {
                                style: "currency",
                                currency: "BRL",
                              })
                        }`}
                      </p>
                    </div>
                  );
                }}
              />
            </LoadPlaceholder>
          </MidCardBlocOne>
          <MidCardBlocTwo>
            <p>
              {`${
                dataVendasOrig?.vbrutoperiodo?.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                }) || "0,00"
              } (${dataVendasOrig?.percentDiffFromLastPeriod || "0"}%)`}
            </p>
            <p>
              {`${
                dataVendasOrig?.vbrutoano?.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                }) || "0,00"
              } (${dataVendasOrig?.percentDiffFromLastYearPeriod || "0"}%)`}
            </p>
          </MidCardBlocTwo>
        </MidCardContainer>
        {isLoading && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              zIndex: 1699,
            }}
          >
            <CircularProgress style={{ color: "#A2C659" }} />
          </div>
        )}
      </MidCard>
    </>
  );
}

export default DashTickets;
