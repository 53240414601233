import React from "react";

import { InputContainerForm, InputContainer } from "components/form";
import { SelectSearch } from "components/contexts/stores/SelectSearch";
import { BtnAdd } from "components/contexts/shared";
import { useAssessorClientAction } from "useCases/assessor";

const FormSelectClient = ({ data, setData }) => (
  <InputContainer>
    <InputContainerForm>
      <SelectSearch
        closeMenuOnSelect
        isMulti={false}
        label="Cliente"
        listItemsSelected={data?.stores ?? []}
        handleSelect={selected =>
          setData(state => ({
            ...state,
            stores: [selected],
            idStore: selected?._id,
          }))
        }
      />
    </InputContainerForm>
  </InputContainer>
);

export const AssessoresClientesModal = ({ id, isDisabled = false }) => (
  <BtnAdd
    textBtn="Cliente"
    hook={useAssessorClientAction}
    id={id}
    FormContent={FormSelectClient}
    isDisabled={isDisabled}
  />
);
