import styled, { css } from "styled-components";
import media from "styled-media-query";

export const ContainerTributeFooter = styled.div`
  > span {
    > h4 {
      color: #1b66b0;
      font-size: 18px;
    }
    > h5 {
      color: #596778;
    }
  }
`;

export const ContainerValues = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    margin-top: ${theme.spacing["2xs"]};
    > h2 {
      &:first-child {
        margin-top: 5px;
        color: #ff5500;
      }
    }
  `}
`;

export const Title = styled.h2`
  ${({ theme }) => css`
    font-size: 1.1rem;
    color: ${theme.colors.gray};
    margin-top: ${theme.spacing["2xs"]};

    ${media.lessThan("huge")`
      font-size: 1rem;
    `}
  `}
`;

export const TitlePercent = styled(Title)`
  ${({ theme }) => css`
    font-size: 1.2rem;
    margin-left: ${theme.spacing.md};

    ${media.lessThan("huge")`
      margin-left: ${theme.spacing.xs};
      font-size: 1.1rem;
    `}
  `}
`;

export const SmallTextContainer = styled.div`
  ${({ theme }) => css`
    flex: 1;
    padding-left: ${theme.spacing.sm};
    padding-top: ${theme.spacing["2xs"]};

    ${media.lessThan("huge")`
      padding-left: ${theme.spacing.xs};
    `}
  `}
`;

export const SmallText = styled.p`
  ${({ theme }) => css`
    font-size: 13px;
    color: ${theme.colors.gray};
    line-height: 16px;

    ${media.lessThan("huge")`
      font-size: 12px;
    `}
  `}
`;
