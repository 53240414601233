import React, { createContext, useState, useContext, useCallback } from "react";

const defaultValues = {
  stores: [],
  store: null,
};

export const AssessorPainelContext = createContext(defaultValues);

export const AssessorPainelProvider = ({ children }) => {
  const [values, setValues] = useState(defaultValues);

  const setStore = useCallback(
    storeValue => {
      const store = values?.stores.find(
        v => v.id_store._id === storeValue.id_store,
      );
      setValues(state => {
        const { stores: list } = state;
        const storeIndexOf = state[store];

        storeValue.totalMessages = 0;
        if (!store) {
          list.push(storeValue);
          return {
            ...state,
            stores: list,
            store: storeValue,
          };
        }
        store.totalMessages = 0;
        list[storeIndexOf] = store;

        return {
          ...state,
          stores: list,
          store,
        };
      });
    },
    [values?.stores, setValues],
  );

  const newMessage = useCallback(
    (idCli, message) => {
      const store = values?.stores.find(v => v.id_store._id === idCli);

      if (!store.id_store.messages) {
        store.id_store.messages = [];
      }

      if (store.id_store.messages.find(mess => message?._id === mess?._id)) {
        return false;
      }

      store.id_store.messages.push(message);
      setStore(store);
    },

    [setStore, values?.stores],
  );

  const loadMessages = useCallback(
    (idCli, messages = []) => {
      const store = values?.stores.find(v => v.id_store._id === idCli);

      if (!store.id_store.messages) {
        store.id_store.messages = [];
      }

      store.id_store.messages = [
        ...store.id_store.messages,
        ...messages.filter(
          message =>
            !store.id_store.messages.find(mess => mess._id === message._id),
        ),
      ];
      setStore(store);
    },
    [setStore, values?.stores],
  );

  const setWhatsAppNumber = useCallback(
    (idCli, whatsapp = "") => {
      const store = values?.stores.find(v => v.id_store._id === idCli);
      store.id_store.whatsapp = whatsapp;
      setStore(store);
    },
    [setStore, values?.stores],
  );

  return (
    <AssessorPainelContext.Provider
      value={{
        stores: values?.stores,
        store: values?.store ?? null,
        setStore,
        newMessage,
        loadMessages,
        setWhatsAppNumber,
      }}
    >
      {children}
    </AssessorPainelContext.Provider>
  );
};

export const useAssessorPainel = () => {
  const context = useContext(AssessorPainelContext);
  if (!context)
    throw new Error(
      "useAssessorPainel precisa ser utilizado dentro de um AssessorPainelProvider",
    );
  return context;
};
