import React from "react";
import { Card } from "@mix/mixfiscal-designsystem";
import Hr from "components/contexts/dashboard/hr";

import { BodyCard, HeaderCard } from "./styles";

const TributeCard = ({ children, title, subtitle, titleLeft, titleRight }) => (
  <Card className="card-custom">
    <HeaderCard>
      <h1>{title}</h1>
      <h4>{subtitle}</h4>
    </HeaderCard>
    <Hr />
    <BodyCard>
      <span>{titleLeft}</span>
      <span style={{ color: "green" }}>{titleRight}</span>
    </BodyCard>
    <div style={{ height: "300px" }}>{children}</div>
  </Card>
);

export default TributeCard;
