import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const InputContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const SelectWrapper = styled.div`
  flex: 1;
`;

export const PlusBtn = styled.div`
  display: flex;
  align-items: end;
`;
