import React, { lazy, Suspense } from "react";
import "@hdn/mixfiscal-nfe-frontend/dist/index.css";
import { useAuth } from "context/AuthContext";
import * as S from "./styles";

const AppProduct = lazy(() => import("./AppProduct"));

const Produtos = () => {
  const { tokenCompanies } = useAuth();

  return (
    <S.Container>
      <Suspense fallback={<div>Carregando...</div>}>
        <AppProduct
          token={tokenCompanies}
          width="100%"
          height="100%"
          currentRoute="/produto/gestao-de-produtos"
          onChangeRoute={() => {}}
        />
      </Suspense>
    </S.Container>
  );
};

export default Produtos;
