import React, { useEffect, useState, useCallback } from "react";
import Select, { components } from "react-select";

import { Tooltip, Datatable } from "@mix/mixfiscal-designsystem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRemove } from "@fortawesome/free-solid-svg-icons";
import { UseStoresSearch } from "useCases";
import { LoadCircle } from "components/structure";
import { usePermissions } from "context/PermissionsContext";

import { OptionsComponent } from "./Tooltip";
import { InputSearch } from "./Components";
import * as S from "./styles";

export const FooterOption = ({ total }) => {
  const {
    listItemsSelected,
    setListItemsSelected,
    listItemsMatriz,
    setListItemMatriz,
    refetchPermissions,
    refetchPermissionsAdv,
  } = usePermissions({});

  const handleClickSelectedAll = () => {
    const arrayOne = listItemsMatriz.map(company => ({
      label:
        company.company_name ||
        company.tradeName ||
        company.companyName ||
        company.cnpj ||
        company.company_social_name,
      value: company,
      ...company,
    }));
    if (listItemsSelected && listItemsSelected.length > 0) {
      const newArray = listItemsSelected.concat(arrayOne);
      setListItemsSelected(newArray);
    } else {
      setListItemsSelected(arrayOne);
    }
  };

  const handleClickSelectedRemove = () => {
    const arrayOne = listItemsSelected.map(company => ({
      label:
        company.company_name ||
        company.tradeName ||
        company.companyName ||
        company.cnpj ||
        company.company_social_name,
      value: company,
      ...company,
    }));
    setListItemsSelected([]);
    setListItemMatriz(arrayOne);
  };

  const handleCancel = () => {
    refetchPermissions();
    refetchPermissionsAdv();
  };

  return total || listItemsSelected.length > 0 ? (
    <S.FooterOption>
      <S.FooterOptionText>
        {total > 0 ? (
          <S.FooterOptionTextItem>
            Total encontradas:
            <b>{total}</b>
          </S.FooterOptionTextItem>
        ) : (
          ``
        )}
        {listItemsSelected && listItemsSelected.length > 0 ? (
          <S.FooterOptionTextItem>
            Total Selecionadas:
            <b>{listItemsSelected.length}</b>
          </S.FooterOptionTextItem>
        ) : (
          ``
        )}
      </S.FooterOptionText>
      <S.FooterOptionAction>
        <S.FooterOptionActionBtn
          type="green"
          onClick={() => {
            handleClickSelectedAll();
          }}
        >
          Selecionar todas
        </S.FooterOptionActionBtn>
        {listItemsSelected && listItemsSelected.length > 0 ? (
          <S.FooterOptionActionBtn
            type="warning"
            onClick={() => {
              handleClickSelectedRemove();
            }}
          >
            Limpar Filtro
          </S.FooterOptionActionBtn>
        ) : (
          ``
        )}
        <S.FooterOptionActionBtn
          onClick={() => {
            handleCancel();
          }}
          type="inativo"
        >
          Cancelar
        </S.FooterOptionActionBtn>
      </S.FooterOptionAction>
    </S.FooterOption>
  ) : (
    ""
  );
};

const MenuList = ({
  data,
  innerRef,
  innerProps,
  children,
  options,
  selectProps,
  handleSelect = null,
  ...rest
}) => {
  const { MenuListFooter = null, MultiValue = null } = selectProps.components;
  const {
    listItemsSelected,
    setListItemsSelected,
    listItemsMatriz,
    setListItemMatriz,
  } = usePermissions();

  const handleSelected = item => {
    if (handleSelect) {
      return handleSelect(item);
    }

    const copyList = [...listItemsMatriz];
    const newList = copyList.filter(company =>
      company._id !== item._id ? company : null,
    );

    if (listItemsSelected?.length) {
      setListItemsSelected([...listItemsSelected, item]);
    } else {
      setListItemsSelected([{ ...item }]);
    }

    setListItemMatriz([...newList]);
  };

  return (
    <>
      <components.MenuList {...rest}>
        {options.map(item => (
          <components.Option {...rest}>
            <S.Select onClick={() => handleSelected(item)}>
              <OptionsComponent data={item} />
            </S.Select>
          </components.Option>
        ))}
      </components.MenuList>
      {MenuListFooter}
      {children.length && MultiValue}
    </>
  );
};

const formatOptionLabel = ({ value }) => {
  if (value) {
    return (
      <>
        <S.FormatOptionLabel>
          <S.Span>
            {value.company_name ||
              value.tradeName ||
              value.companyName ||
              value.cnpj ||
              value.company_social_name}
          </S.Span>
          <Tooltip
            style={{
              position: "absolute",
            }}
            body={`CNPJ:  ${value.cnpj}`}
            position="top"
            title={`${
              value.company_name ||
              value.tradeName ||
              value.companyName ||
              value.cnpj ||
              value.company_social_name
            }`}
            zIndex={99999}
          >
            <S.FormatOptionLabelBadge
              type={value.isActive ? `ativo` : `inativo`}
            />
          </Tooltip>
        </S.FormatOptionLabel>
      </>
    );
  }
  return null;
};

export const CustomTooltipMultiples = ({ selected }) => {
  return (
    <>
      <S.Title>
        <S.TitleText>
          <h5>Matrizes selecionadas</h5>
        </S.TitleText>
      </S.Title>
      <S.ContainerTooltip>
        <Datatable
          pagination={false}
          columns={[
            {
              field: "label",
              title: "Matriz",
            },
            {
              field: "cnpj",
              title: "CNPJ",
            },
          ]}
          rawData={selected?.map(company => ({
            label:
              company.company_name ||
              company.tradeName ||
              company.companyName ||
              company.cnpj ||
              company.company_social_name,
            cnpj: company.cnpj,
            ...company,
          }))}
          selectableRowsAll
          selectedItems={[]}
          totalRawData={21}
        />
      </S.ContainerTooltip>
    </>
  );
};

// eslint-disable-next-line no-unreachable
const MoreSelectedBadge = ({ items }) => {
  const { length } = items;
  // eslint-disable-next-line no-shadow
  const label = `+ ${length} ${length !== 1 ? "s" : ""} selecionada`;

  return (
    <Tooltip
      isPopOver
      component={<CustomTooltipMultiples selected={items} />}
      position="bottom"
      title="Outros selecionados"
      zIndex={9999999}
      zIndexBackdrop={1}
    >
      <S.StyledBadgePlus type="ativo">{label}</S.StyledBadgePlus>
    </Tooltip>
  );
};

const MultiValue = ({ index, getValue, ...props }) => {
  const maxToShow = 10;
  const overflow = getValue()
    .slice(maxToShow)
    .map(x => x.label);

  return index < maxToShow ? (
    <components.MultiValue {...props} />
  ) : index === maxToShow ? (
    <MoreSelectedBadge items={overflow} />
  ) : null;
};

export const MultiValueRemove = props => {
  const { data } = props;

  const {
    listItemsSelected,
    setListItemsSelected,
    listItemsMatriz,
    setListItemMatriz,
  } = usePermissions({});

  const handleRemove = useCallback(
    index => {
      if (listItemsSelected) {
        setListItemMatriz([...listItemsMatriz, data]);

        const item = listItemsSelected.filter(
          itemMartiz => itemMartiz._id !== index,
        );
        setListItemsSelected(item);
      }
    },
    [
      data,
      listItemsSelected,
      setListItemsSelected,
      listItemsMatriz,
      setListItemMatriz,
    ],
  );

  return (
    <>
      <S.ButtonRemove
        onClick={() => {
          handleRemove(data?._id);
        }}
      >
        <FontAwesomeIcon
          icon={faRemove}
          size="lg"
          style={{
            fontSize: "15px",
          }}
          color="#000"
        />
      </S.ButtonRemove>
    </>
  );
};

export const SelectCustom = ({
  data,
  menuIsOpen,
  isLoading,
  setMenuIsOpen,
  searchValue,
  setDebouncedTerm,
  listItemsSelected,
  isMulti = true,
  closeMenuOnSelect = false,
  handleSelect,
}) => {
  const messageBlank =
    ({ isLoadingItem, dataLength, searchLength }) =>
    () => {
      if (isLoadingItem) {
        return <LoadCircle width="25px" height="25px" />;
      }

      if (!isLoadingItem && searchLength === 0 && !dataLength) {
        return "Digite o Razão Social ou CNPJ";
      }

      return "Não foi possivel encontrar dados com os parametros digitados";
    };

  const filterOptions = () => true;

  const onInputChange = value => {
    setDebouncedTerm(value);
  };

  return (
    <>
      <Select
        menuIsOpen={menuIsOpen}
        name="company"
        labelType="top"
        isMulti={isMulti}
        closeMenuOnSelect={closeMenuOnSelect}
        placeholder="Razão Social ou CNPJ"
        styles={S.customStyles}
        noOptionsMessage={messageBlank({
          isLoadingItem: isLoading,
          dataLength: data?.length,
          searchLength: searchValue?.length,
        })}
        onClick={() => {
          setMenuIsOpen(!menuIsOpen);
        }}
        components={{
          Input: props => (
            <InputSearch
              key="input-search"
              onInputChange={onInputChange}
              searchValue={searchValue}
              {...props}
            />
          ),
          MenuList: props => (
            <MenuList
              key="menu-list"
              handleSelect={handleSelect}
              setMenuIsOpen={setMenuIsOpen}
              closeMenuOnSelect={closeMenuOnSelect}
              {...props}
            />
          ),
          MultiValue,
          MenuListFooter: isMulti && (
            <FooterOption data={data} total={data.length} />
          ),
          MultiValueRemove,
        }}
        formatOptionLabel={formatOptionLabel}
        filterOption={filterOptions}
        total={data?.length}
        value={listItemsSelected}
        options={
          isLoading
            ? []
            : data?.map(company => ({
                label:
                  company.company_name ||
                  company.tradeName ||
                  company.companyName ||
                  company.cnpj ||
                  company.company_social_name,
                value: company,
                ...company,
              }))
        }
      />
    </>
  );
};

export const SelectSearchPermission = ({
  setListItemsSelected,
  setDisabledSearchItems,
}) => {
  const { listItemsMatriz, setListItemMatriz, listItemsSelected } =
    usePermissions({});

  const [menuIsOpen, setMenuIsOpen] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [debouncedTerm, setDebouncedTerm] = useState(searchValue);

  const { isLoading, data, fetch: searching } = UseStoresSearch();

  useEffect(() => {
    const timer = setTimeout(() => {
      setSearchValue(debouncedTerm);
    }, 1000);
    return () => clearTimeout(timer);
  }, [debouncedTerm]);

  useEffect(() => {
    if (searchValue !== "") {
      searching({
        search: searchValue,
      });
    }
  }, [searchValue, searching]);

  useEffect(() => {
    if (data && data.length === 0) {
      setListItemMatriz([]);
    }
    if (data && data.length > 0) {
      const check = [...data];

      const checkExistent = check.filter(item => {
        const ie = listItemsSelected.find(
          itemCheck => itemCheck._id === item._id,
        );

        if (ie) {
          return;
        }

        return item;
      });

      if (checkExistent) {
        setListItemMatriz([...checkExistent]);
      } else {
        setListItemMatriz([]);
      }
    }
  }, [data, setListItemMatriz, listItemsSelected]);

  useEffect(() => {
    if (debouncedTerm) {
      setDisabledSearchItems(true);
    }
    if (debouncedTerm === "") {
      setDisabledSearchItems(false);
    }
  }, [debouncedTerm, setDisabledSearchItems]);

  useEffect(() => {
    if (listItemsSelected.length > 0) {
      setDisabledSearchItems(true);
    }
    if (listItemsSelected && !listItemsSelected.length) {
      setDisabledSearchItems(false);
    }
  }, [listItemsSelected, setDisabledSearchItems]);

  return (
    <S.SelectWrapper>
      <S.Label>Matriz</S.Label>
      <SelectCustom
        data={listItemsMatriz}
        setList={setListItemMatriz}
        menuIsOpen={menuIsOpen}
        isLoading={isLoading}
        setMenuIsOpen={setMenuIsOpen}
        searchValue={searchValue}
        setDebouncedTerm={setDebouncedTerm}
        listItemsSelected={listItemsSelected}
        setListItemsSelected={setListItemsSelected}
      />
    </S.SelectWrapper>
  );
};

export const SelectSearch = ({
  setListItemsSelected,
  listItemsSelected = [],
  label = "Matriz",
  isMulti,
  closeMenuOnSelect = false,
  handleSelect,
}) => {
  const [menuIsOpen, setMenuIsOpen] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [debouncedTerm, setDebouncedTerm] = useState(searchValue);

  const { isLoading, data, fetch: searching } = UseStoresSearch();

  useEffect(() => {
    const timer = setTimeout(() => {
      setSearchValue(debouncedTerm);
    }, 1000);
    return () => clearTimeout(timer);
  }, [debouncedTerm]);

  useEffect(() => {
    if (searchValue !== "") {
      searching({
        search: searchValue,
      });
    }
  }, [searchValue, searching]);

  return (
    <S.SelectWrapper>
      <S.Label>{label}</S.Label>
      <SelectCustom
        isMulti={isMulti}
        data={(data ?? []).filter(
          item =>
            !listItemsSelected?.find(itemCheck => itemCheck._id === item._id),
        )}
        menuIsOpen={menuIsOpen}
        isLoading={isLoading}
        setMenuIsOpen={setMenuIsOpen}
        searchValue={searchValue}
        setDebouncedTerm={setDebouncedTerm}
        listItemsSelected={listItemsSelected}
        setListItemsSelected={setListItemsSelected}
        closeMenuOnSelect={closeMenuOnSelect}
        handleSelect={handleSelect}
      />
    </S.SelectWrapper>
  );
};
