import styled from "styled-components";

export const Scenario = styled.div`
  width: 70%;
  display: grid;
  margin-top: 9px;
  margin-left: 8px;
  grid-template-areas:
    "l1c1  l1c2 l1c3 l1c4 l1c5 l1c6 l1c7"
    "l1c1  l2c2 l2c3 l2c4 l2c5 l2c6 l1c7";
  grid-template-rows: 39px 39px;
  grid-template-columns: 80px 100px 100px 100px 100px 117.6px;
  text-align: center;
  p {
    font-size: 12px;
    font-family: Cairo;
    color: #596778;
    span {
      width: 0px;
    }
  }
  div {
    box-shadow: 0 0 0 0px;
    border-radius: 0px;
    border-style: none;
  }
  div:hover {
    border-color: #596778;
  }

  .l1c1 {
    grid-area: l1c1;
    display: flex;
    align-items: center;
    border: 1px solid #596778;
    background-color: #adc86a;

    p {
      margin-left: 15px;
      color: #fff;
      font-weight: bold;
    }
  }
  .l1c2 {
    grid-area: l1c2;
    border-right: 1px solid #596778;
    border-top: 1px solid #596778;
    border-bottom: 1px solid #596778;
    p {
      margin-top: 9px;
    }
  }
  .l1c3 {
    grid-area: l1c3;
    border-right: 1px solid #596778;
    border-top: 1px solid #596778;
    border-bottom: 1px solid #596778;
    p {
      margin-top: 9px;
    }
  }
  .l1c4 {
    grid-area: l1c4;
    border-right: 1px solid #596778;
    border-top: 1px solid #596778;
    border-bottom: 1px solid #596778;
    p {
      margin-top: 9px;
    }
  }
  .l1c5 {
    grid-area: l1c5;
    border-right: 1px solid #596778;
    border-top: 1px solid #596778;
    border-bottom: 1px solid #596778;
    p {
      margin-top: 9px;
    }
  }
  .l1c6 {
    grid-area: l1c6;
    border-right: 1px solid #596778;
    border-top: 1px solid #596778;
    border-bottom: 1px solid #596778;
    p {
      margin-top: 9px;
    }
  }
  .l1c7 {
    grid-area: l1c7;
    display: flex;
    align-items: center;
    border-right: 1px solid #596778;
    border-top: 1px solid #596778;
    border-bottom: 1px solid #596778;
    background-color: #adc86a;
    cursor: pointer;

    button {
      margin: 0 3px;
      background-color: #adc86a;
      color: #fff;
      font-weight: bold;
      font-size: 12px;
    }
  }
  .l2c2 {
    grid-area: l2c2;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    p {
      font-weight: bold;
    }
  }
  .l2c3 {
    grid-area: l2c3;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    p {
      font-weight: bold;
    }
  }
  .l2c4 {
    grid-area: l2c4;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    p {
      font-weight: bold;
    }
  }
  .l2c5 {
    grid-area: l2c5;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    p {
      font-weight: bold;
    }
  }
  .l2c6 {
    grid-area: l2c6;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    p {
      font-weight: bold;
    }
  }
`;

export const CompraVendaContainer = styled.div`
  display: grid;
  width: 270px;
  margin-left: 8px;
  margin-top: 9px;
  grid-template-areas:
    "icms icms piscofins piscofins icmsvd icmsvd pisvd pisvd"
    "cstcp aliqcp cstpis aliqpis cstvd aliqvd cstpisvd aliqpisvd"
    "valcstcp valaliqcp valcstpis valaliqpis valcstvd valaliqvd valcstpisvd valaliqpisvd";

  grid-template-columns: 83px 83px 83px 83px 83px 83px 83px 83px;
  grid-template-rows: 30px 30px 30px;
  p {
    font-family: Cairo;
    font-size: 12px;
  }
  .icms {
    grid-area: icms;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #596778;
    background-color: #fbdeba;
    p {
      color: #596778;
      font-weight: bold;
    }
  }
  .cstcp {
    grid-area: cstcp;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #596778;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    background-color: #fbdeba;
    p {
      line-height: 10px;
      padding: 5px;
      border-radius: 5px;
      color: #596778;
    }
  }
  .aliqcp {
    grid-area: aliqcp;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    background-color: #fbdeba;
    p {
      line-height: 10px;
      padding: 5px;
      border-radius: 5px;
      color: #596778;
    }
  }
  .valcstcp {
    grid-area: valcstcp;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #596778;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    background-color: #fbdeba;
    p {
      line-height: 10px;
      padding: 5px;
      border-radius: 5px;
      color: #596778;
    }
  }
  .valaliqcp {
    grid-area: valaliqcp;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    background-color: #fbdeba;

    p {
      line-height: 10px;
      padding: 5px;
      border-radius: 5px;
      color: #596778;
    }
  }

  .piscofins {
    grid-area: piscofins;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #596778;
    border-bottom: 1px solid #596778;
    border-right: 1px solid #596778;
    background-color: #fbdeba;
    p {
      color: #596778;
      font-weight: bold;
    }
  }
  .cstpis {
    grid-area: cstpis;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    background-color: #fbdeba;
    p {
      line-height: 10px;
      padding: 5px;
      border-radius: 5px;
      color: #596778;
    }
  }
  .aliqpis {
    grid-area: aliqpis;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    background-color: #fbdeba;
    p {
      line-height: 10px;
      padding: 5px;
      border-radius: 5px;
      color: #596778;
    }
  }
  .valcstpis {
    grid-area: valcstpis;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    background-color: #fbdeba;
    p {
      line-height: 10px;
      padding: 5px;
      border-radius: 5px;
      color: #596778;
    }
  }
  .valaliqpis {
    grid-area: valaliqpis;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    background-color: #fbdeba;

    p {
      line-height: 10px;
      padding: 5px;
      border-radius: 5px;
      color: #596778;
    }
  }

  .icmsvd {
    grid-area: icmsvd;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #596778;
    border-top: 1px solid #596778;
    border-bottom: 1px solid #596778;
    background-color: #e6eed2;
    p {
      color: #596778;
      font-weight: bold;
    }
  }
  .cstvd {
    grid-area: cstvd;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    background-color: #e6eed2;
    p {
      line-height: 10px;
      padding: 5px;
      border-radius: 5px;
      color: #596778;
    }
  }
  .aliqvd {
    grid-area: aliqvd;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    background-color: #e6eed2;
    p {
      line-height: 10px;
      padding: 5px;
      border-radius: 5px;
      color: #596778;
    }
  }
  .valcstvd {
    grid-area: valcstvd;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    background-color: #e6eed2;
    p {
      line-height: 10px;
      padding: 5px;
      border-radius: 5px;
      color: #596778;
    }
  }
  .valaliqvd {
    grid-area: valaliqvd;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    background-color: #e6eed2;

    p {
      line-height: 10px;
      padding: 5px;
      border-radius: 5px;
      color: #596778;
    }
  }

  .pisvd {
    grid-area: pisvd;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #596778;
    border-top: 1px solid #596778;
    border-bottom: 1px solid #596778;
    background-color: #e6eed2;
    p {
      color: #596778;
      font-weight: bold;
    }
  }

  .cstpisvd {
    grid-area: cstpisvd;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    background-color: #e6eed2;
    p {
      line-height: 10px;
      padding: 5px;
      border-radius: 5px;
      color: #596778;
    }
  }
  .aliqpisvd {
    grid-area: aliqpisvd;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    background-color: #e6eed2;
    p {
      line-height: 10px;
      padding: 5px;
      border-radius: 5px;
      color: #596778;
    }
  }
  .valcstpisvd {
    grid-area: valcstpisvd;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    background-color: #e6eed2;
    p {
      line-height: 10px;
      padding: 5px;
      border-radius: 5px;
      color: #596778;
    }
  }
  .valaliqpisvd {
    grid-area: valaliqpisvd;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    background-color: #e6eed2;

    p {
      line-height: 10px;
      padding: 5px;
      border-radius: 5px;
      color: #596778;
    }
  }
`;

export const ConstitutionContainer = styled.div`
  width: 70%;
  display: grid;
  margin-top: 9px;
  margin-left: 8px;
  grid-template-areas:
    "l1c1  l1c2 l1c3"
    "l1c1  l2c2 l2c3"
    "l1c1  l3c2 l3c3"
    "l1c1  l4c2 l4c3";
  grid-template-rows: 28px 28px 28px 28px;
  grid-template-columns: 116px 274px 274px;
  text-align: center;
  p {
    font-size: 12px;
    font-family: Cairo;
    color: #596778;
  }

  .l1c1 {
    grid-area: l1c1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid #596778;

    p {
      margin-left: 4px;
      line-height: 14px;
      font-weight: bold;
    }
  }
  .l1c2 {
    grid-area: l1c2;
    border-right: 1px solid #596778;
    border-top: 1px solid #596778;
    border-bottom: 1px solid #596778;
    background-color: #e5e5e5;
  }
  .l1c3 {
    grid-area: l1c3;
    border-right: 1px solid #596778;
    border-top: 1px solid #596778;
    border-bottom: 1px solid #596778;
    background-color: #e5e5e5;
  }
  .l2c2 {
    grid-area: l2c2;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    p {
      font-weight: bold;
      white-space: pre;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .l2c3 {
    grid-area: l2c3;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    p {
      font-weight: bold;
      white-space: pre;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .l3c2 {
    grid-area: l3c2;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    background-color: #e5e5e5;
  }
  .l3c3 {
    grid-area: l3c3;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    background-color: #e5e5e5;
  }
  .l4c2 {
    grid-area: l4c2;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    p {
      font-weight: bold;
      white-space: pre;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .l4c3 {
    grid-area: l4c3;
    border-right: 1px solid #596778;
    border-bottom: 1px solid #596778;
    p {
      font-weight: bold;
      white-space: pre;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;
