import { useFetchAuthenticate, useAPIAuthenticateToast } from "hooks";
import { getURLWithParams } from "utils/context";

export const useAccessGroups = (params = {}) =>
  useFetchAuthenticate(getURLWithParams("/accessGroups", params));

export const useAccessGroup = id =>
  useFetchAuthenticate(id ? getURLWithParams(`/accessGroup/${id}`) : null);

export const useAccessGroupRemove = () => {
  const { isLoading, onSend } = useAPIAuthenticateToast();

  const onRemove = id => {
    onSend({
      endpoint: `/accessGroup/${id}`,
      messageSuccess: `GRUPO DE ACESSO DELETADO`,
      messageFail: "Falha ao tentar deletar Grupo de Acesso!",
      type: "DELETE",
    });
  };

  return {
    onRemove,
    isLoading,
  };
};

export const useAccessGroupCreateEdit = id => {
  const { isLoading, onSend } = useAPIAuthenticateToast();

  const onSave = data =>
    onSend({
      endpoint: id ? `/accessGroup/${id}` : "accessGroup",
      messageSuccess: `GRUPO DE ACESSO ${
        id ? "atualizada" : "criada"
      } com sucesso!`,
      messageFail: "Falha ao tentar salvar Grupo de acesso!",
      data,
      type: id ? "PUT" : "POST",
    });

  return {
    onSave,
    isLoading,
  };
};
