import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  align-items: center;
`;

export const Label = styled.label`
  font-weight: bold;
  margin-right: 10px;
`;

export const Value = styled.span`
  width: 100px;
  height: 5px;
  border-radius: 50%;
  background-color: green;
`;
