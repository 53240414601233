import React from "react";
import { Switch } from "components/form";

import * as S from "./WhatsAppForm.styles";

export const WhatsAppForm = ({ active = false, onChange }) => (
  <S.Container>
    <div>
      <Switch
        value={active}
        onChange={onChange}
        name="whatsAppActive"
        label="Deseja integrar com o WhatsApp ?"
      />
    </div>
  </S.Container>
);
