import React from "react";
import { useHistory } from "react-router-dom";

import * as S from "./styles";

export const CardHeader = ({
  title,
  url,
  totalItems,
  limitItems,
  permission,
  labelButtonAdd,
  labelFilterBtn,
  isShowItem,
  setIsShowItem,
  btnItemLabel,
  urlItem,
}) => {
  const history = useHistory();
  return (
    <S.CardSubHeader>
      <S.CardSubHeaderDescription>
        <p>{title}</p>
        {totalItems ? (
          <p>{`Mostrando ${limitItems} de ${totalItems} resultado(s)`}</p>
        ) : (
          ``
        )}
      </S.CardSubHeaderDescription>
      <S.CardSubHeaderAction>
        {btnItemLabel && urlItem && permission?.post && (
          <S.CardSubHeaderActionButton
            onClick={() => history.push(`${urlItem}`)}
          >
            {btnItemLabel}
          </S.CardSubHeaderActionButton>
        )}

        {permission?.post && labelButtonAdd && (
          <S.CardSubHeaderActionButton onClick={() => history.push(`${url}`)}>
            {labelButtonAdd}
          </S.CardSubHeaderActionButton>
        )}
        {labelFilterBtn && (
          <S.CardSubHeaderActionButton
            onClick={() => setIsShowItem(!isShowItem)}
          >
            {labelFilterBtn}
          </S.CardSubHeaderActionButton>
        )}
      </S.CardSubHeaderAction>
    </S.CardSubHeader>
  );
};
