import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useAPIAuthenticate } from "hooks";
import { useACL, StoreProvider } from "context";

import { BtnRemove } from "components/contexts/shared";
import {
  useStore,
  useStoreForm,
  useStoreDadosCertificate,
  useStoreRemove,
  useCompanies,
} from "useCases";

import { useStoreContext } from "context/StoreContext";
import { useToast } from "context/ToastContext";

import { Button, Alert } from "components/structure";

import {
  StoreForm,
  StoreConfig,
  Assessor,
} from "components/contexts/users-and-permissions/StoreForm";

import { formatCnpj, getOnlyNumbers } from "utils";
import { usePermissions } from "context/PermissionsContext";
import Card from "components/UI/Card";

import * as S from "./styles";

export const CreateStoreContainer = ({ id }) => {
  const { hasEdit, hasCreate, hasRemove } = useACL("lojas");

  const { handleSubmit, control, register, reset, setValue, getValues, watch } =
    useForm();

  const api = useAPIAuthenticate();
  const {
    certificate,
    setCertificate,
    specialTaxRegimes,
    loadSpecialTaxRegimes,
    listSelectedUsersWallet,
  } = useStoreContext();

  const [searchCnpjReceita, setSearchCnpjReceita] = useState(false);
  const [differentTimeWeekend, setDifferentTimeWeekend] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [selectedCompany, setSelectedCompany] = useState({});
  const [isActive, setIsActive] = useState(false);
  const [parseCompanies, setParseCompany] = useState({});
  const [companyValueId, setTableCompanyValue] = useState("");
  const [cnpjValue, setCnpjValue] = useState("");
  const [logo, setLogo] = useState("");
  const [actualLogo, setActualLogo] = useState();
  const [certificatePassword, setCertificatePassword] = useState("");
  const [zipCodeValue, setZipCodeValue] = useState("");

  const { addToast } = useToast();

  const { data: dataCompanies } = useCompanies({ page: 1, limit: 9999 });

  const { selectedGroup } = usePermissions();

  // TODO: LOADING DATA STORE DETAIL
  // const { isLoading, data: dataStore } = useStore({ id });
  const { isLoading, data: dataStore } = useStore(id);

  // TODO: DADOS CERTIFICATE
  const { onSave: onSaveDadosCertificate } = useStoreDadosCertificate({});

  const { onSave: onSaveStore, isLoading: isLoadingOnSave } = useStoreForm({
    id,
  });

  useEffect(() => {
    if (dataCompanies?.companies?.length) {
      setParseCompany(
        dataCompanies?.companies.map(item => ({
          label: item.company_name,
          value: item._id,
          cnpj: item.cnpj,
        })),
      );
    }
  }, [dataCompanies?.companies]);

  const uploadImage = async () => {
    if (!logo) return "";
    try {
      let urlPhoto = "";
      const formData = new FormData();
      formData.append("file", logo);

      await api.post(`/uploadPhoto`, formData).then(response => {
        const { photo } = response.data.user;
        urlPhoto = photo;
      });

      return urlPhoto;
    } catch (err) {
      addToast({
        title: "Erro ao salvar o logo",
        type: "error",
        description: `Ocorreu o seguinte erro: ${err.message}`,
      });
    }
  };

  const uploadCertificate = async () => {
    if (!certificate) return "";

    try {
      let urlFile = "";

      const formData = new FormData();
      formData.append("file", certificate);

      const { data: resData } = await api.post(`/uploadFile`, formData);

      urlFile = resData.file;

      return urlFile;
    } catch (err) {
      addToast({
        title: "Erro ao salvar certificado",
        type: "error",
        description: `Ocorreu o seguinte erro: ${err.message}`,
      });
    }
  };

  const handleSubmit2 = async fields => {
    const photo = logo && (await uploadImage());
    const urlCertificate = certificate && (await uploadCertificate());

    if (urlCertificate && urlCertificate !== ``) {
      await onSaveDadosCertificate({
        certificate,
        cnpj: cnpjValue,
        certificatePassword: fields.certificatePassword?.trim(),
        companyName: fields.companyName,
      });
    }

    console.log(`companyId`, fields.companyId);

    const formObj = {
      cnpj: cnpjValue,
      companyName: fields.companyName,
      tradeName: fields.tradeName,
      namePartnerOne: fields.namePartnerOne,
      namePartnerTwo: fields.namePartnerTwo,
      legalNature: fields.legalNature,
      openDate: fields.openDate,
      lastUpdate: fields.lastUpdate,
      address: fields.address,
      number: fields.number,
      complement: fields.complement,
      zipcode: fields.zipcode,
      district: fields.district,
      city: fields.city,
      state: fields.state,
      phone: fields.phone,
      cell: fields.cell,
      taxRegime: fields.taxRegime,
      companyType: fields.companyType,
      companyId: fields.companyId,
      openingTime: fields.openingTime,
      closingTime: fields.closingTime,
      differentTimeWeekend,
      weekendOpeningTime: fields.weekendOpeningTime,
      weekendClosingTime: fields.weekendClosingTime,
      employees: fields.employees,
      storeSize: fields.storeSize,
      whatsapp_name: fields.whatsapp_name,
      whatsapp: fields.whatsapp,
      in_store_function: fields.in_store_function,
      isActive: isActive || true,
      certificatePassword: fields.certificatePassword?.trim(),
      logo: photo || fields.logo,
      certificate: urlCertificate || fields.certificate || ``,
      type: fields.type,
      accessGroupId: selectedGroup?._id,
      specialTaxRegimes: Object.values(specialTaxRegimes),
      origin: "authenticate",
    };

    if (!id) {
      formObj.mainActivity = fields.mainActivity;
      formObj.secondaryActivity = fields.secondaryActivity;
    }
    if (!id) {
      formObj.wallets = listSelectedUsersWallet;
    }

    if (fields?.taxRegime === "SN") {
      formObj.receitaBruta = fields.receitaBruta;
    }

    await onSaveStore(formObj);
  };
  const handleChangeCnpj = value => {
    setCnpjValue(value);
    if (value.length > 12) {
      const type = value.includes("0001") ? "MATRIZ" : "FILIAL";
      setValue("type", type);
      if (type === "Filial") {
        const splitCnpj = value.slice(0, 8);
        const filterParsedCompany = parseCompanies.find(item =>
          getOnlyNumbers(item.cnpj).includes(splitCnpj),
        );
        if (filterParsedCompany) {
          setTableCompanyValue(filterParsedCompany.value);
          setSelectedCompany(filterParsedCompany);
        }
      }
    }
  };

  useEffect(() => {
    if (dataStore && dataStore?.stores && !isLoading) {
      setData({
        ...dataStore?.stores,
        type: dataStore?.stores?.type,
      });
      reset({
        ...dataStore?.stores,
        cnpj: formatCnpj(dataStore?.stores.cnpj),
        type: dataStore?.stores?.type,
        taxRegime: dataStore?.stores?.taxRegime,
      });

      setIsActive(dataStore?.stores?.isActive);
      setDifferentTimeWeekend(dataStore?.stores?.differentTimeWeekend);
      setCnpjValue(dataStore?.stores?.cnpj);
      setActualLogo(dataStore?.stores?.logo);
      setCertificate(dataStore?.stores?.certificate);
      setCertificatePassword(dataStore?.stores?.certificatePassword);
      setZipCodeValue(dataStore?.stores?.zipcode);
      loadSpecialTaxRegimes(dataStore?.stores?.specialTaxRegimes);
    }
  }, [
    dataStore,
    isLoading,
    setData,
    setIsActive,
    reset,
    setActualLogo,
    setDifferentTimeWeekend,
    setCertificatePassword,
    setCnpjValue,
    setZipCodeValue,
    loadSpecialTaxRegimes,
    setCertificate,
  ]);

  return (
    <S.Container>
      <S.Section>
        <Card
          headerColor="#256925"
          title={id ? "EDITAR LOJA" : "CRIAR NOVA LOJA"}
          iconName="person"
          action={
            id && (
              <BtnRemove
                isDisabled={!hasRemove}
                id={id}
                hook={useStoreRemove}
                module="Loja"
              />
            )
          }
        >
          {searchCnpjReceita && (
            <Alert
              type="info"
              title="Atualização de dados"
              content={`Foi feito a busca do CNPJ: ${formatCnpj(
                cnpjValue,
              )} na receita federal e com isso atualizamos os dados do formulario.`}
            />
          )}
          <S.Form className="forms" onSubmit={handleSubmit(handleSubmit2)}>
            <S.TopPart className="top-part">
              <S.CollumForm>
                <StoreForm
                  id={id}
                  watch={watch}
                  handleChangeCnpj={handleChangeCnpj}
                  setValue={setValue}
                  reset={reset}
                  cnpjValue={cnpjValue}
                  setCnpjValue={setCnpjValue}
                  loading={loading}
                  setLoading={setLoading}
                  data={data}
                  control={control}
                  register={register}
                  setData={setData}
                  zipCodeValue={zipCodeValue}
                  parseCompanies={parseCompanies}
                  selectedCompany={selectedCompany}
                  companyValueId={companyValueId}
                  setTableCompanyValue={setTableCompanyValue}
                  certificatePassword={certificatePassword}
                  setSelectedCompany={setSelectedCompany}
                  setSearchCnpjReceita={setSearchCnpjReceita}
                  getValues={getValues}
                />
              </S.CollumForm>
              <S.Divider className="divider" />
              <S.CollumForm>
                <Card title="Assessor" headerColor="#256925">
                  <Assessor id={id} />
                </Card>
                <StoreConfig
                  id={id}
                  isActive={isActive}
                  setIsActive={setIsActive}
                  setValue={setValue}
                  cnpjValue={cnpjValue}
                  setCnpjValue={setCnpjValue}
                  getValues={getValues}
                  register={register}
                  logo={logo}
                  setLogo={setLogo}
                  actualLogo={actualLogo}
                  setActualLogo={setActualLogo}
                  data={data}
                  control={control}
                  differentTimeWeekend={differentTimeWeekend}
                  setDifferentTimeWeekend={setDifferentTimeWeekend}
                />
              </S.CollumForm>
            </S.TopPart>
            <S.Footer>
              <Button
                goBack
                type="button"
                color="gray"
                style={{ marginRight: 15 }}
              >
                VOLTAR
              </Button>
              <Button
                isDisabled={!hasEdit || !hasCreate}
                type="submit"
                isLoading={isLoadingOnSave}
              >
                {id ? "SALVAR" : "CRIAR"}
              </Button>
            </S.Footer>
          </S.Form>
        </Card>
      </S.Section>
    </S.Container>
  );
};

export const CreateStore = props => {
  const { id } = useParams();

  return (
    <StoreProvider>
      <CreateStoreContainer id={id !== "new" && id} {...props} />
    </StoreProvider>
  );
};
