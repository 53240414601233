import styled from "styled-components";

export const Container = styled.div`
  padding: 10px 10px 0px 10px;
  border: 1px solid green;
  width: min-content;
  height: min-content;
  justify-content: center;
  display: flex;
  border-radius: 3px;
`;
