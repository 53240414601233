import React, { useEffect, useState } from "react";
import { onlyNumbers } from "utils/utils";
import { useNcmNivel } from "useCases/ncm";
import { hasValueNcm } from "utils/context/laws";
import { ButtonAdd } from "components/structure";
import { Select } from "components/form";

import * as S from "./NcmInputs.styles";

const getParams = ({ codigo, parentValue, name = "capitulo" }) => {
  if (parentValue && !codigo) {
    return { sessao: parentValue };
  }

  if (!codigo) {
    return null;
  }

  return { [name ?? "capitulo"]: codigo };
};

export const NcmInputs = ({ handleAdd }) => {
  const [fields, setState] = useState({});

  const codigo = onlyNumbers(fields.codigo?.value);

  const { dataMap: dataSession } = useNcmNivel("sessao");

  const paramsCapitulo = getParams({
    codigo: codigo ? codigo?.substring(0, 2) : null,
    parentValue: fields?.sessao?.value,
  });

  const { dataMap: dataCapitulo } = useNcmNivel(
    paramsCapitulo ? "capitulo" : null,
    paramsCapitulo,
  );

  const { dataMap: dataPosicao } = useNcmNivel(
    codigo || fields?.capitulo?.value ? "posicao" : null,
    codigo || fields?.capitulo?.value
      ? {
          ncm: codigo
            ? `${codigo?.substring(0, 2)}.${codigo?.substring(2, 4)}`
            : fields?.capitulo?.value,
        }
      : null,
  );

  const { dataMap: dataSubPosicao } = useNcmNivel(
    codigo ? "sub-posicao" : null,
    codigo
      ? { ncm: `${codigo?.substring(0, 4)}.${codigo?.substring(4, 6)}` }
      : null,
  );

  const subItemNcm = hasValueNcm([
    fields?.codigoSearch,
    fields?.subposicao?.value,
    fields?.posicao?.value,
    fields?.capitulo?.value,
    fields?.sessao?.value,
  ]);

  const { dataMap: subItemsData } = useNcmNivel(
    "item",
    subItemNcm ? { ncm: subItemNcm } : null,
  );

  useEffect(() => {
    // TODO: Retirar o blank do retorno
    if (dataCapitulo?.length === 2) {
      setState(state => ({
        ...state,
        capitulo: dataCapitulo[1],
        sessao: dataCapitulo[1]?.sessoes[1],
      }));
    }
  }, [dataCapitulo, dataCapitulo?.length, setState]);

  useEffect(() => {
    // TODO: Retirar o blank do retorno
    if (dataPosicao?.length === 2) {
      setState(state => ({ ...state, posicao: dataPosicao[1] }));
    }
  }, [dataPosicao, dataPosicao?.length, setState]);

  useEffect(() => {
    // TODO: Retirar o blank do retorno
    if (dataSubPosicao?.length === 2) {
      setState(state => ({ ...state, subposicao: dataSubPosicao[1] }));
    }
    if (dataSubPosicao?.length === 1) {
      setState(state => ({ ...state, subposicao: null }));
    }
  }, [dataSubPosicao, dataSubPosicao?.length, setState]);

  const onChange = e => {
    setState({ ...fields, codigoSearch: e });
  };

  const onChangeItem = value => {
    setState(state => {
      return {
        ...state,
        codigo: value,
        sessao: value.sessoes[1],
        codigoSearch: "",
      };
    });
  };

  const onChangeSessao = value => {
    setState(state => ({
      ...state,
      codigo: null,
      sessao: value,
      posicao: null,
      capitulo: null,
      subposicao: null,
    }));
  };

  const onChangeDefault = (name, value) => {
    setState(state => ({
      ...state,
      [name]: value,
    }));
  };

  const handleLocalNew = () => {
    handleAdd(fields);
    setState({
      codigo: null,
      sessao: null,
      posicao: null,
      capitulo: null,
      subposicao: null,
    });
  };

  return (
    <>
      <S.Container>
        <S.InputContainer style={{ paddingTop: 4 }}>
          <Select
            label="Item"
            name="codigo"
            labelType="top"
            value={fields?.codigo}
            onInputChange={onChange}
            data={subItemsData ?? []}
            onChange={onChangeItem}
          />
        </S.InputContainer>
        <S.InputContainer>
          <Select
            required
            name="sessao"
            value={fields?.sessao}
            data={dataSession}
            label="Sessão"
            onChange={onChangeSessao}
          />
        </S.InputContainer>
        <S.InputContainer>
          <Select
            required
            name="capitulo"
            value={fields?.capitulo}
            data={dataCapitulo}
            label="Capítulo"
            onChange={e => onChangeDefault("capitulo", e)}
            isDisabled={dataCapitulo?.length === 1}
          />
        </S.InputContainer>
        <S.InputContainer>
          <Select
            required
            name="posicao"
            value={fields?.posicao}
            data={dataPosicao}
            label="Posição"
            onChange={e => onChangeDefault("posicao", e)}
            isDisabled={dataPosicao?.length === 1}
          />
        </S.InputContainer>
        <S.InputContainer>
          <Select
            required
            value={fields?.subposicao}
            name="subposicao"
            onChange={e => onChangeDefault("subposicao", e)}
            isDisabled={dataSubPosicao?.length === 1}
            data={dataSubPosicao}
            label="Sub-posição"
          />
        </S.InputContainer>

        {handleAdd && (
          <S.AddPlus>
            <ButtonAdd type="button" onClick={handleLocalNew} />
          </S.AddPlus>
        )}
      </S.Container>
    </>
  );
};
