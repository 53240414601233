import styled, { css } from "styled-components";

export const Container = styled.div``;

export const LogsItems = styled.div`
  margin-bottom: 14px;
`;

export const LogItemsAction = styled.div`
  width: 470px;
  display: flex;
  margin-top: 14px;
  justify-content: center;
`;

export const WrapperContainer = styled.div`
  display: flex;
`;

export const Label = styled.label`
  font-size: 0.9rem;
`;

export const LabelValue = styled(Label)`
  ${({ theme }) => css`
    margin-left: ${theme.spacing.xs};
    font-weight: bold;
    font-size: 0.9rem;
  `}
`;
