import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  padding: 10px;

  header {
    width: 100%;

    .instruction-bar {
      display: flex;
      align-items: center;
      width: 100%;
      background-color: #596778;
      padding: 10px;
      color: white;
      border-radius: 5px;

      .page-title {
        font-size: 15px;
        font-weight: bold;
        margin-right: 35px;
      }

      .tip-wrapper {
        display: flex;
        align-items: center;
        margin-right: 18px;
        cursor: pointer;

        &:last-of-type {
          margin-right: 0;
        }

        .icon {
          margin-right: 6px;
        }

        p {
          font-size: 10px;
        }
      }

      .expand-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        cursor: pointer;
      }
    }

    .tool-bar {
      width: 100%;
      display: flex;
      justify-content: space-between;
      background-color: white;
      padding: 18px;
      margin-bottom: 12px;

      .tool-menu {
        list-style: none;
        display: flex;
        align-items: center;

        .tool-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-right: 30px;
          color: #8c8b8b;
          cursor: pointer;

          &:last-of-type {
            margin-right: 0;
          }

          &:hover {
            color: #45924f;
          }
        }

        .active {
          color: #45924f;
        }
      }

      .api-key-wrapper {
        display: flex;
        align-items: center;
        background-color: #5a8dee;
        color: white;
        padding: 8px 16px;
        border-radius: 5px;
        box-shadow: 0 3px 6px #6d98f599;

        svg {
          margin-right: 10px;
        }

        p {
          font-size: 15px;

          span {
            font-weight: bold;
          }
        }
      }
    }
  }

  .board-wrapper {
    display: flex;
    gap: 15px;
    width: 100%;

    .column1 {
      display: flex;
      flex-direction: column;
      flex: 1;
      background-color: white;
      padding: 45px;
      width: 100%;
      min-width: 0px;

      .double-board {
        display: flex;
      }

      .small-size {
        height: 82px;
      }

      .medium-size {
        height: 173px;
      }

      .large-size {
        height: auto;
        padding: 24px;
      }

      .board-row {
        display: flex;
        align-items: center;
        padding-left: 30px;
        border: 1px dashed #bababa;
        width: 100%;

        input {
          width: 100%;
        }

        h2 {
          color: #304156;
        }

        p {
          font-size: 18px;
          color: #596778;
        }
      }

      .no-border {
        border: none;
      }

      .flex-column {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
    }

    .column2 {
      display: flex;
      display: none;
      flex-direction: column;
      flex: 1;
      max-width: 270px;
      max-height: 681px;
      background-color: white;

      .graph-chart {
        padding: 8px 16px;
        border-bottom: 1px solid #e4e4e4;

        .graph-title {
          margin-bottom: 10px;

          p {
            color: #596778;
            font-size: 14px;
          }
        }

        .graph-row {
          width: 100%;
          height: 55px;
          background-color: #eeeeee;
          border-radius: 2px;
          margin-bottom: 6px;

          &:last-of-type {
            margin-bottom: 0;
          }
        }

        .graph-row-group {
          display: flex;

          .graph-row {
            height: 83px;
            margin-right: 5px;

            &:last-of-type {
              margin-right: 0;
            }
          }

          .smaller {
            width: 45%;
          }
        }
      }
    }
  }
`;
