import React, { useState, useEffect } from "react";
import {
  // MdAttachFile,
  MdDelete,
  MdClose,
  MdRemoveRedEye,
  MdInsertChart,
  MdKeyboardArrowUp,
  MdKeyboardArrowDown,
  MdSearch,
} from "react-icons/md";
import { produce } from "immer";
import { Tooltip } from "@mix/mixfiscal-designsystem";

// import templatesApi from "../../../../services/templatesApi";
import { useFlow } from "../../../../context/FlowContext";
import {
  Container,
  HeaderContainer,
  ContentContainer,
  ActionsContainer,
  List,
} from "./styles";

function CustomNode({ id, data }) {
  const {
    label,
    dataType,
    fields,
    selectedTemplateFileId,
    selectedFileColumnsIndex,
  } = data;

  const [selectedFields, setSelectedFields] = useState([]);
  const [sortDirection, setSortDirection] = useState("");
  const [sortingBy, setSortingBy] = useState("");
  const [rawFieldsData, setRawFieldsData] = useState([]);
  const [fieldsData, setFieldsData] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const {
    handleOpenSinglePreview,
    handleRemoveNode,
    handleUpdateSelectedField,
    // setIsAttachingFileToNode,
    setSelectedCallToActionElementId,
  } = useFlow();

  const handleFieldSelection = (field, index) => {
    const tempSet = new Set(selectedFields);
    if (tempSet.has(field.description)) {
      tempSet.delete(field.description);
    } else {
      tempSet.add(field.description);
    }
    setSelectedFields([...tempSet]);
    handleUpdateSelectedField({ id, fieldIndex: index });
  };

  const handleSelectAllFields = e => {
    if (!e.target.checked) {
      handleUpdateSelectedField({ id, fieldIndex: "none" });
      return setSelectedFields([]);
    }
    const values = fieldsData.map(field => field.description);
    handleUpdateSelectedField({ id, fieldIndex: "all" });
    return setSelectedFields(values);
  };

  const getSelectedFieldsStructure = () => {
    const fieldsStructure = selectedFields.map(selectedField => {
      const payload = {
        index: fieldsData.findIndex(
          field => field.description === selectedField,
        ),
        fieldName: selectedField,
      };
      return payload;
    });
    return fieldsStructure;
  };

  const compare = (baseState, key, currentDirection) =>
    produce(baseState, draft => {
      draft.sort((a, b) => {
        let comparisonValue = 0;
        let firstValue = a[key];
        let secondValue = b[key];

        if (currentDirection === "down")
          [firstValue, secondValue] = [secondValue, firstValue];

        if (typeof firstValue === "string") {
          if (firstValue > secondValue) {
            comparisonValue = 1;
            return comparisonValue;
          }
          if (firstValue < secondValue) {
            comparisonValue = -1;
            return comparisonValue;
          }
          if (firstValue === secondValue) {
            comparisonValue = 0;
            return comparisonValue;
          }
        }

        if (typeof firstValue === "number")
          comparisonValue = firstValue - secondValue;
        return comparisonValue;
      });
    });

  const handleSortDirection = sortBy => {
    if (sortBy !== sortingBy) {
      setSortingBy(sortBy);
      setSortDirection("ascending");
    }

    switch (sortDirection) {
      case "":
        setFieldsData(compare(fieldsData, "description", "up"));
        return setSortDirection("ascending");
      case "ascending":
        setFieldsData(compare(fieldsData, "description", "down"));
        return setSortDirection("descending");
      case "descending":
        setSortingBy("");
        setFieldsData(fields);
        return setSortDirection("");
      default:
        return null;
    }
  };

  const handleOpenPreview = async () => {
    const currentSelectedFields = getSelectedFieldsStructure();
    handleOpenSinglePreview({
      id,
      selectedColumns: currentSelectedFields,
      dataType,
      label,
      register: data.register,
      selectedTemplateFileId,
      selectedFileColumnsIndex,
    });
    setSelectedCallToActionElementId(id);
  };

  useEffect(() => {
    if (fields?.length > 0 && fieldsData.length <= 0) {
      setRawFieldsData(fields);
      setFieldsData(fields);
    }
  }, [fields, fieldsData]);

  useEffect(() => {
    const tempSet = new Set();
    if (fields.length > 0) {
      fields.forEach(field => {
        if (field.isSelected) {
          tempSet.add(field.description);
        }
      });
      if ([...tempSet].length > 0) setSelectedFields([...tempSet]);
    }
  }, [fields]);

  useEffect(() => {
    if (!searchValue) {
      return setFieldsData(rawFieldsData);
    }

    const filteredData = rawFieldsData.filter(field =>
      field.description.toLowerCase().includes(searchValue.toLocaleLowerCase()),
    );

    setFieldsData(filteredData);
  }, [rawFieldsData, searchValue]);

  return (
    <Container className="nowheel">
      <HeaderContainer>
        <div>
          <MdInsertChart size={24} color="#fff" />
          <p>{label}</p>
        </div>
        <div
          className="buttonContainer"
          role="button"
          onClick={() => handleRemoveNode(id)}
        >
          <MdClose size={20} />
        </div>
      </HeaderContainer>
      <ContentContainer>
        {fieldsData.length > 0 && (
          <List>
            <div>
              <div className="searchBox">
                <MdSearch size={24} color="#ADC86A" />
                <input
                  type="text"
                  placeholder="Digite o nome do campo"
                  value={searchValue}
                  onChange={e => setSearchValue(e.target.value)}
                />
              </div>
              <div className="listTitle">
                <input
                  type="checkbox"
                  checked={selectedFields.length === fieldsData.length}
                  onChange={e => handleSelectAllFields(e)}
                />
                <p role="button" onClick={() => handleSortDirection("column")}>
                  Coluna
                </p>
                {sortDirection === "ascending" && (
                  <MdKeyboardArrowDown size={16} />
                )}
                {sortDirection === "descending" && (
                  <MdKeyboardArrowUp size={16} />
                )}
              </div>
              {fieldsData.map((field, index) => (
                <div key={field._id} className="fieldContainer">
                  <input
                    type="checkbox"
                    checked={
                      selectedFields.length > 0 &&
                      selectedFields.includes(field.description)
                    }
                    onChange={() => handleFieldSelection(field, index)}
                  />
                  <p>{field.description}</p>
                </div>
              ))}
            </div>
          </List>
        )}
      </ContentContainer>
      <ActionsContainer>
        <Tooltip zIndex={999999} title="Visualizar Bloco" position="top">
          <MdRemoveRedEye
            size={24}
            color="#596778"
            onClick={handleOpenPreview}
          />
        </Tooltip>
        <Tooltip zIndex={999999} title="Remover Bloco" position="top">
          <MdDelete
            size={24}
            color="#F44336"
            onClick={() => handleRemoveNode(id)}
          />
        </Tooltip>
      </ActionsContainer>
      {/* <Handle
        type="target"
        position="left"
        style={{
          width: "30px",
          height: "30px",
          background: "#555",
          left: "-15px",
        }}
        onConnect={params => console.log("handle onConnect", params)}
      />
      <Handle
        type="source"
        position="right"
        id="a"
        style={{
          width: "30px",
          height: "30px",
          background: "#0e7c34",
          right: "-15px",
        }}
        onConnect={params => getDataForDataTransfer(params)}
      /> */}
    </Container>
  );
}

export default CustomNode;
