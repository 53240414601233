import React, { useState, useEffect } from "react";

import Select from "react-select";

import { UseStoresSearch } from "useCases";

export const StoreSelectSearch = ({ handleValue }) => {
  const { isLoading, data, fetch: searching } = UseStoresSearch();

  const [searchValue, setSearchValue] = useState("");
  const [debouncedTerm, setDebouncedTerm] = useState(searchValue);

  const filterOptions = () => true;

  const onInputChange = value => {
    setDebouncedTerm(value);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setSearchValue(debouncedTerm);
    }, 1000);
    return () => clearTimeout(timer);
  }, [debouncedTerm]);

  useEffect(() => {
    if (searchValue !== "") {
      searching({
        search: searchValue,
      });
    }
  }, [searchValue, searching]);

  return (
    <>
      <Select
        placeholder="Nome da loja ou Razão social"
        filterOptions={filterOptions}
        name="value"
        isLoading={isLoading}
        onInputChange={value => {
          onInputChange(value);
        }}
        onChange={e => {
          handleValue(e.value);
        }}
        options={
          isLoading
            ? []
            : data?.map(company => ({
                label:
                  company.company_name ||
                  company.tradeName ||
                  company.companyName ||
                  company.cnpj ||
                  company.company_social_name,
                value: company?._id,
              }))
        }
      />
    </>
  );
};
