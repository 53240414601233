import styled from "styled-components";

export const Container = styled.div`
  width: 200vw;
  height: 200vh;

  transform: translate(-50%, -50%);

  position: absolute;
  top: 0;
  left: 0;
  background-color: ${props => props.bgColor};
  z-index: ${props => props.zIndex};
`;
