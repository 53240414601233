import styled, { css } from "styled-components";
import * as S from "components/structure/Pagination/Pagination.styles";

export const TitleAssessores = styled.h3`
  margin: 3px 0 0 0;
  color: #000;
  font-size: 15px;
  text-transform: uppercase;
`;

export const AssessorUl = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  ${S.Pagination} {
    display: flex;
    justify-content: center;
  }
`;

export const AssessorLi = styled.li`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin: 3px 0 3px 0;
  padding: 8px 0 8px 0;

  &:first-child {
    border-top: none;
  }
  border-top: 1px solid #0000002e;
`;

export const AssessorLiItem = styled.div`
  &::first-child {
    width: 50px;
  }
`;

export const Line = styled.div`
  width: 100%;
  height: 2px;
  align-self: stretch;
  background-color: #0000002e;
  margin: 10px 0 10px 0;
`;

export const InputInline = styled.div`
  display: flex;
`;
export const Btn = styled.button`
  ${({ background = "#256925" }) => css`
    margin: 0 0 0 10px;
    padding: 0 5px 0 5px;
    border-radius: 5px;
    background: ${background};
    color: #fff;
    font-weight: bold;
  `}
`;
