import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const ContainerName = styled.div`
  flex: 2;
`;

export const ContainerCheck = styled.div`
  flex: 1;
  margin-left: 30px;
  padding-top: 25px;
`;
