import React from "react";
import { Switch, useHistory } from "react-router-dom";
import { RouteShield } from "routes/Routes";
import { usePermissions } from "context/PermissionsContext";

import { CreateEditGroup, ListGroups } from "./Groups";
import { CreateEditApiKeys } from "./ApiKeys/CreateEdit";
import { CreateUser } from "./Users";
import { ListUsers } from "./Users/List";
import { InvitUsers } from "./Users/InvitUsers";
import { CreateStore, ListStores } from "./Stores";
import * as S from "./styles";

import ListKeys from "./ApiKeys/List";

export const UsersAllConfigs = () => {
  const history = useHistory();
  const baseUrl = "/usuarios-e-configs";

  const { selectedCompany } = usePermissions();

  const handleEditView = ({ id, url }) => {
    history.push(`${baseUrl}/${url}/${id}`);
  };

  return (
    <S.Container>
      <Switch>
        <RouteShield
          exact
          isPrivate
          path="/usuarios-e-configs"
          component={() => (
            <ListUsers
              baseUrl={baseUrl}
              selectedCompany={selectedCompany}
              handleEditView={handleEditView}
            />
          )}
        />
        <RouteShield
          exact
          isPrivate
          path="/usuarios-e-configs/convidar-usuario"
          component={InvitUsers}
        />
        <RouteShield
          isPrivate
          path="/usuarios-e-configs/grupos-de-acesso/:id"
          component={CreateEditGroup}
        />
        <RouteShield path="/usuarios-e-configs/grupos-de-acesso" isPrivate>
          <ListGroups baseUrl={baseUrl} handleEditView={handleEditView} />
        </RouteShield>
        <RouteShield
          isPrivate
          path="/usuarios-e-configs/chaves-api/:id"
          component={CreateEditApiKeys}
        />
        <RouteShield path="/usuarios-e-configs/chaves-api" exact isPrivate>
          <ListKeys baseUrl={baseUrl} handleEditView={handleEditView} />
        </RouteShield>
        <RouteShield
          path="/usuarios-e-configs/lojas/:id"
          component={CreateStore}
          isPrivate
        />
        <RouteShield path="/usuarios-e-configs/lojas" exact isPrivate>
          <ListStores baseUrl={baseUrl} selectedCompany={selectedCompany} />
        </RouteShield>
        <RouteShield
          path="/usuarios-e-configs/:id"
          exact
          isPrivate
          component={CreateUser}
        />
      </Switch>
    </S.Container>
  );
};
