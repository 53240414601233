import React from "react";
import { Card } from "@mix/mixfiscal-designsystem";
import { ListProducts } from "components/contexts/grid-manager/ResultsProducts/ListProducts";
import Dialog from "components/UI/Dialog";

import * as S from "./MovedProductsModal.styles";

export const MovedProductsModal = ({
  isOpen = false,
  handleClose,
  handleConfirm,
  products = [],
  isNewLaw = false,
  type,
}) => {
  return (
    <Dialog
      isOpen={isOpen}
      title={
        isNewLaw
          ? "Esses produtos inseridos nessa Lei/Grade"
          : "Esses produtos serão movidos dessa GRADE"
      }
      handleClose={handleClose}
      handleConfirm={handleConfirm}
    >
      <S.ModalContent>
        <Card
          headerColor="#E5E5E5"
          textColor="#304156"
          title="Produtos"
          className="flatCard"
        >
          <ListProducts
            isRemove
            data={products}
            isAction={false}
            typeSelectionProduct={type}
          />
        </Card>
      </S.ModalContent>
    </Dialog>
  );
};
