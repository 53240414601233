import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-columns: 30% 70%;
  padding: 10px;

  .label-checkbox-tributary {
    display: flex;
    align-items: center;

    gap: 10px;
    padding: 5px 0;

    > div {
      width: 25px;
      min-width: 25px;
    }
  }

  .card-custom {
    margin: 0 0px 10px 0px;
    padding-bottom: 5px;
  }

  span {
    font-size: 12px;
  }

  h1 {
    font-weight: bold;
  }

  .row-eleito-trib {
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 5px 0;

    .label-checkbox {
      display: flex;
      gap: 8px;
      align-items: center;

      div {
        border: 2px solid #8c8b8b;
      }
    }
  }

  .row-legislation {
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-around;

    border: 2px solid gray;
    border-radius: 5px;
  }

  .row {
    width: 100%;

    display: flex;
    flex-direction: row;

    > div {
      width: 100%;
      padding: 2px;
    }

    .form-group-datepicker label {
      padding-left: 8px;
    }
  }

  .aliquota-icms {
    display: flex;
    flex-direction: column;

    h5 {
      padding-left: 10px;
      font-weight: bold;
      color: #304156;
    }
  }
`;

export const Filters = styled.div`
  width: 100%;

  .enable-user-select {
    user-select: initial;
  }
`;

// aqui estão prontos
export const RowTagsName = styled.div`
  width: 100%;
  padding: 2px 0;
  border-bottom: 2px solid #e5e5e5;
  margin: 10px 0;
  padding-bottom: 15px;
  display: flex;
`;

export const RowLegislation = styled.div`
  width: 100%;

  > div {
    width: 100%;
    padding: 5px;

    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: space-around;

    border: 1px solid #596778;
    border-radius: 10px;
  }

  label {
    display: flex;
    flex-direction: row;
    gap: 15px;
  }
`;

export const DescriptionLegislation = styled.div`
  width: 100%;
  margin: 5px 0;
  padding: 10px;
  display: flex;
  align-items: center;

  line-height: 1.2;
  color: #5f5e5d;
  background: #fef7e5;
  border-radius: 5px;
`;

export const InputNcmTipi = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;

  div {
    width: 100%;
  }

  span {
    background: #f0f4f7;
    color: #71777d;

    height: 85%;
    padding: 8px;
    display: flex;
    align-items: center;
    border: 1px solid #596778;
  }

  .label-start {
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
  }

  .label-end {
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
  }
`;

export const CardCustomCst = styled.div`
  width: 100%;
  padding: 15px !important;
  margin: 6px 8px;

  background: ${props => (props.type === "sale" ? "#e6eed2" : "#fdeedc")};
  border-radius: 5px;

  h1 {
    color: ${props => (props.type === "sale" ? "#479350" : "#f38336")};
  }
`;

export const CardCustomDefault = styled.div`
  width: 100%;
  padding: 15px !important;
  color: #596778;

  margin: 8px 8px;
  border: 2px solid #596778;
  border-radius: 8px;
`;

export const TaxEffect = styled.div`
  width: 100%;
  padding: 5px 10px;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CheckBox = styled.div`
  display: flex;
  align-items: center;

  gap: 10px;
  padding: 5px 0;

  > div {
    width: 25px;
    min-width: 25px;
  }
`;
