import React from "react";
import { FormProvider } from "react-hook-form";

import {
  Form as FormStyle,
  InputContainer,
  InputContainerForm,
} from "./Form.styles";

const Form = ({ form, children: Children, ...props }) => {
  if (form) {
    return (
      <FormProvider {...form}>
        <FormStyle {...props}>{Children}</FormStyle>
      </FormProvider>
    );
  }

  return <FormStyle {...props}>{Children}</FormStyle>;
};

export { Form, InputContainer, InputContainerForm };
