import styled from "styled-components";

export const Container = styled.div`
  width: 110px;
  height: 42px;
  background-color: #ee6b09;
  border-radius: 12px;

  display: flex;
  align-items: center;
  justify-content: center;

  p {
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
  }
`;
