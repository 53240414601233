import React, { useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { onlyNumbers } from "utils/utils";
import { useNcmNivel } from "useCases/ncm";
import {
  Select,
  TextField,
  DatePicker,
  MaskField,
  Form,
  TextHiddenField,
} from "components/form";
import { Button, Alert } from "components/structure";
import { YES_OR_NO_LIST } from "constants/types";
import { NCMS } from "constants/routes";

import * as S from "./NcmForm.styles";

const getType = totalNumber => {
  if (!totalNumber) {
    return false;
  }
  if (totalNumber === 1) {
    return "sessao";
  }
  if (totalNumber > 1 && totalNumber <= 2) {
    return "capitulo";
  }
  if (totalNumber > 1 && totalNumber <= 4) {
    return "posicao";
  }
  if (totalNumber > 1 && totalNumber <= 6) {
    return "subposicao";
  }

  return "item";
};

const LABELS_FIELDS = {
  capitulo: "Capitulo",
  posicao: "Posição",
  subposicao: "Sub-posição",
  item: "Item",
};

export const NcmForm = ({ data: fieldsData, onSave = () => {} }) => {
  const { handleSubmit, control, setValue, reset, getValues } = useForm({
    defaultValues: fieldsData,
  });

  const codigo = onlyNumbers(useWatch({ control, name: "codigo" }));

  const { dataMap: dataSession } = useNcmNivel("sessao");

  const { dataMap: dataCapitulo } = useNcmNivel(
    codigo ? "capitulo" : null,
    codigo ? { capitulo: codigo?.substring(0, 2) } : null,
  );

  const { dataMap: dataPosicao } = useNcmNivel(
    codigo ? "posicao" : null,
    codigo
      ? { ncm: `${codigo?.substring(0, 2)}.${codigo?.substring(2, 4)}` }
      : null,
  );

  const { dataMap: dataSubPosicao } = useNcmNivel(
    codigo ? "sub-posicao" : null,
    codigo
      ? { ncm: `${codigo?.substring(0, 4)}.${codigo?.substring(4, 6)}` }
      : null,
  );

  const onSubmit = () => onSave(getValues());

  useEffect(() => {
    if (fieldsData) reset(fieldsData);
  }, [fieldsData, reset]);

  useEffect(() => {
    // TODO: Retirar o blank do retorno
    if (dataCapitulo?.length === 2) {
      setValue("capitulo", dataCapitulo[1]);
      setValue("sessao", dataCapitulo[1]?.sessoes[1]);
    }
  }, [dataCapitulo, dataCapitulo?.length, setValue]);

  useEffect(() => {
    // TODO: Retirar o blank do retorno
    if (dataPosicao?.length === 2) {
      setValue("posicao", dataPosicao[1]);
    }
  }, [dataPosicao, dataPosicao?.length, setValue]);

  useEffect(() => {
    // TODO: Retirar o blank do retorno
    if (dataSubPosicao?.length === 2) {
      setValue("subposicao", dataSubPosicao[1]);
    }
    if (dataSubPosicao?.length === 1) {
      setValue("subposicao", null);
    }
  }, [dataSubPosicao, dataSubPosicao?.length, setValue]);

  useEffect(() => {
    setValue("type", getType(codigo?.length));
  }, [setValue, codigo]);

  const typeField = getType(codigo?.length);

  const hasHasError = () => {
    return (
      (typeField === "posicao" && dataCapitulo?.length === 1) ||
      (typeField === "subposicao" && dataPosicao?.length === 1)
    );
  };

  const columnsWidth = {
    ncmCode: typeField === "item" ? "20%" : "40%",
    descricao: typeField === "item" ? "40%" : "50%",
    validade: "10%",
    tipo: "10%",
  };

  return (
    <Form className="flex-column" onSubmit={handleSubmit(onSubmit)}>
      {/* TODO: Retirar o uso da classe input-container */}
      <div className="input-container">
        <S.InputContainer maxWidth={columnsWidth.ncmCode} flex={0.6}>
          <MaskField
            name="codigo"
            control={control}
            label="Código NCM"
            mask="9999.99.99"
          />
        </S.InputContainer>
        <S.InputContainer maxWidth={columnsWidth.descricao} flex={0.8}>
          <TextField
            label="Descrição"
            name="descricao"
            control={control}
            required
          />
        </S.InputContainer>
        <S.InputContainer maxWidth={columnsWidth.validade} flex={1}>
          <DatePicker label="Validade" control={control} name="valid" />
        </S.InputContainer>
        {typeField === "item" && (
          <S.InputContainer maxWidth={columnsWidth.tipo} flex={1}>
            <Select
              required
              defaultValue={YES_OR_NO_LIST[1]}
              name="ncm_ex"
              data={YES_OR_NO_LIST}
              label="Exceção NCM ?"
              control={control}
            />
          </S.InputContainer>
        )}
        <S.InputContainer maxWidth={columnsWidth.tipo} flex={1}>
          <label>Tipo</label>
          <TextHiddenField name="type" control={control} />
          <b>{LABELS_FIELDS[typeField]}</b>
        </S.InputContainer>
      </div>
      {/* // TODO: Sessão / Capítulo */}
      <div className="input-container">
        {codigo?.length >= 1 && (
          <S.InputContainer maxWidth="100%" flex={0.6}>
            <Select
              required
              name="sessao"
              data={dataSession}
              label="Sessão"
              control={control}
            />
          </S.InputContainer>
        )}
        {codigo?.length >= 2 && typeField !== "capitulo" && (
          <S.InputContainer maxWidth="100%" flex={0.6}>
            <Select
              required
              name="capitulo"
              data={dataCapitulo}
              label="Capítulo"
              control={control}
              isDisabled={dataCapitulo?.length === 1}
            />
          </S.InputContainer>
        )}
      </div>
      <div className="input-container">
        {codigo?.length >= 4 && typeField !== "posicao" && (
          <S.InputContainer maxWidth="100%" flex={0.6}>
            <Select
              required
              name="posicao"
              data={dataPosicao}
              label="Posição"
              control={control}
              isDisabled={dataPosicao?.length === 1}
            />
          </S.InputContainer>
        )}
        {codigo?.length >= 6 && typeField !== "subposicao" && (
          <S.InputContainer maxWidth="100%" flex={0.6}>
            <Select
              required
              name="subposicao"
              isDisabled={dataSubPosicao?.length === 1}
              data={dataSubPosicao}
              label="Sub-posição"
              control={control}
            />
          </S.InputContainer>
        )}
      </div>

      {typeField === "posicao" && dataCapitulo?.length === 1 && (
        <Alert
          type="danger"
          title="Alerta"
          content={`O código NCM ${codigo} escrito não pode ser uma posição pois não foi encontrado um capitulo para relacionar`}
        />
      )}
      {typeField === "subposicao" && dataPosicao?.length === 1 && (
        <Alert
          type="danger"
          title="Alerta"
          content={`O código NCM ${codigo} escrito não pode ser uma sub-posição pois não possuí uma posição relacionada`}
        />
      )}

      <S.FooterActions>
        <Button
          label="Voltar"
          color="secondary"
          toLink={NCMS.BASE}
          style={{ marginRight: "15px" }}
        />
        <Button type="submit" label="Salvar" isDisabled={hasHasError()} />
      </S.FooterActions>
    </Form>
  );
};
