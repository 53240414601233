import React, { useState } from "react";
import { QrCode, DialogConfirm } from "components/structure";
import { useWhatsAppClient } from "useCases/whatsapp";
import { WhatsAppStatus } from "components/contexts/user/WhatsApp/WhatsAppStatus";
import { WhatsAppStatusAlert } from "components/contexts/user/WhatsApp/WhatsAppStatusAlert";

import * as S from "./WhatsAppInfo.styles";

export const WhatsAppInfo = ({
  clientId,
  isPooling = true,
  data = {},
  value,
  onChangeStatus = () => {},
  onChangeField,
}) => {
  const [dialogConfirm, setDialogConfirm] = useState(false);

  const { isLoading, data: dataRes } = useWhatsAppClient({
    clientId,
    isPooling,
  });

  const handleCancel = () => {
    setDialogConfirm(false);
    onChangeField({ target: { value: true } });
  };

  const handleConfirm = () => {
    onChangeStatus({ target: { value: false } });
    setDialogConfirm(false);
  };

  const openDialog = ({ target }) => {
    setDialogConfirm(!target.value);
  };

  return (
    <S.Container>
      <DialogConfirm
        isOpen={dialogConfirm}
        handleConfirm={handleConfirm}
        handleCancel={handleCancel}
        text="Deseja realmente remover a integração com o whatsApp ?"
      />
      <WhatsAppStatus
        status={dataRes?.whatsApp?.status ?? data?.status}
        statusValue={value}
        onChange={openDialog}
      />
      {dataRes?.whatsApp?.status !== "connected" && (
        <S.ContainerWhatsApp>
          {dataRes?.whatsApp?.qrcode && (
            <QrCode text={dataRes?.whatsApp?.qrcode} isLoading={isLoading} />
          )}
        </S.ContainerWhatsApp>
      )}
      <WhatsAppStatusAlert
        data={dataRes?.whatsApp}
        status={dataRes?.whatsApp?.status ?? data?.status}
      />
    </S.Container>
  );
};
