import React from "react";
import { Typography, Icon } from "@mix/mixfiscal-designsystem";
import { useTheme } from "hooks";
import { CARD_HEADERS_COLORS_TYPES } from "constants/enums";
import { Format } from "utils";
import * as S from "./styles";

const CardTemplate = ({
  id,
  color = CARD_HEADERS_COLORS_TYPES.default,
  title,
  subTitle,
  createdAt,
  updatedAt,
  creator,
  disableEditing = false,
  hasAdd = false,
  hasFinish = false,
  onEdit,
  onDuplicate,
  onDelete,
}) => {
  const { colors } = useTheme();
  return (
    <S.SectionCards>
      <S.SectionCardHeader colorHeader={color} />
      <S.TitleCard>
        <Typography
          variant="h3"
          color={colors.navyBlue}
          weight="bold"
          label={title}
        />

        <Typography
          variant="h4"
          color={colors.navyBlue}
          label={`Categoria: ${subTitle}`}
        />
      </S.TitleCard>

      <S.Actions>
        {!disableEditing && (
          <>
            <S.ActionItem onClick={() => onEdit(id)}>
              <Icon name="editar" color={colors.navyBlue} />
              <span>Editar</span>
            </S.ActionItem>
            <S.ActionItem onClick={() => onDuplicate(id)}>
              <Icon name="editar" color={colors.navyBlue} />
              <span>Duplicar</span>
            </S.ActionItem>
            <S.ActionItem onClick={() => onDelete(id)}>
              <Icon name="editar" color={colors.navyBlue} />
              <span>Excluir</span>
            </S.ActionItem>
          </>
        )}
        {hasAdd && (
          <S.ButtonAction>
            <Icon color={colors.lightGreen} name="adicionar" size={32} />
          </S.ButtonAction>
        )}
        {hasFinish && (
          <S.ButtonAction>
            <Icon color={colors.gray200} name="adicionar" size={32} />
          </S.ButtonAction>
        )}
      </S.Actions>

      <S.RowContentCard>
        <S.ContainerDaedline>
          {creator && (
            <S.Info>
              <label>Criado por:</label>
              {creator}
            </S.Info>
          )}

          {createdAt && (
            <S.Info>
              <label>Data da criação:</label>
              {Format(createdAt)}
            </S.Info>
          )}

          {updatedAt && (
            <S.Info>
              <label>Última edição:</label>
              {Format(updatedAt)}
            </S.Info>
          )}
        </S.ContainerDaedline>
      </S.RowContentCard>
    </S.SectionCards>
  );
};

export default CardTemplate;
