export const origem = [
  { label: "Entrada Industria", value: "EI" },
  { label: "Entrada Distribuidor", value: "ED" },
  { label: "Entrada Simples Nacional", value: "ES" },
  { label: "Bonificação", value: "BO" },
  { label: "Transferência", value: "TR" },
];

export const ufEntrada = [
  { label: "AC", value: "AC" },
  { label: "AL", value: "AL" },
  { label: "AP", value: "AP" },
  { label: "AM", value: "AM" },
  { label: "BA", value: "BA" },
  { label: "CE", value: "CE" },
  { label: "ES", value: "ES" },
  { label: "GO", value: "GO" },
  { label: "MA", value: "MA" },
  { label: "MT", value: "MT" },
  { label: "MS", value: "MS" },
  { label: "MG", value: "MG" },
  { label: "PA", value: "PA" },
  { label: "PB", value: "PB" },
  { label: "PR", value: "PR" },
  { label: "PE", value: "PE" },
  { label: "PI", value: "PI" },
  { label: "RJ", value: "RJ" },
  { label: "RN", value: "RN" },
  { label: "RS", value: "RS" },
  { label: "RO", value: "RO" },
  { label: "RR", value: "RR" },
  { label: "SC", value: "SC" },
  { label: "SP", value: "SP" },
  { label: "SE", value: "SE" },
  { label: "TO", value: "TO" },
  { label: "DF", value: "DF" },
];

export const saida = [
  { label: "Saida Atacado Contribuinte", value: "SAC" },
  { label: "Saida Atacado Simples Nacional", value: "SAS" },
  { label: "Saida Varejo Contribuinte", value: "SVC" },
  { label: "Saida Não contribuinte", value: "SNC" },
  { label: "Industrialização", value: "IND" },
  { label: "Transferência", value: "TRA" },
];

export const ufSaida = [
  { label: "AC", value: "AC" },
  { label: "AL", value: "AL" },
  { label: "AP", value: "AP" },
  { label: "AM", value: "AM" },
  { label: "BA", value: "BA" },
  { label: "CE", value: "CE" },
  { label: "ES", value: "ES" },
  { label: "GO", value: "GO" },
  { label: "MA", value: "MA" },
  { label: "MT", value: "MT" },
  { label: "MS", value: "MS" },
  { label: "MG", value: "MG" },
  { label: "PA", value: "PA" },
  { label: "PB", value: "PB" },
  { label: "PR", value: "PR" },
  { label: "PE", value: "PE" },
  { label: "PI", value: "PI" },
  { label: "RJ", value: "RJ" },
  { label: "RN", value: "RN" },
  { label: "RS", value: "RS" },
  { label: "RO", value: "RO" },
  { label: "RR", value: "RR" },
  { label: "SC", value: "SC" },
  { label: "SP", value: "SP" },
  { label: "SE", value: "SE" },
  { label: "TO", value: "TO" },
  { label: "DF", value: "DF" },
];

export const regime = [
  { label: "LUCRO REAL", value: "LR" },
  { label: "LUCRO PRESUMIDO", value: "LP" },
  { label: "SIMPLES NACIONAL", value: "SN" },
  { label: "PESSOA FÍSICA", value: "PF" },
];
