import styled from "styled-components";

export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 67px;
  justify-content: flex-start;

  > div {
    :last-child {
      > div {
        > div {
          min-height: 30px;

          > div {
            padding-bottom: 0px;
            padding-top: 0px;
            > div {
              padding-bottom: 0px;
              padding-top: 0px;
              > span {
                font-size: 14px !important;
              }
            }
          }
        }
      }
    }
  }

  :not(:last-child) {
    margin-right: 10px;
  }

  > div {
    cursor: pointer;
    max-height: 30px;
    min-width: 110px;
  }
`;

export const Label = styled.div`
  color: ${({ theme }) => theme.colors.slateGray};
  font-size: 12px;
  padding-left: 8px;
`;

export const customStyles = {
  option: provided => ({
    ...provided,
    minHeight: 30,
    padding: "0 8px",
    fontSize: 13,
    cursor: "pointer",
  }),
  control: (styles, { selectProps, isDisabled }) => {
    const extraStyles = {};

    if (selectProps.variant === "simple") {
      extraStyles.border = "none";
      extraStyles.borderBottom = "1px solid #596778";
      extraStyles.borderRadius = 0;
      extraStyles["&:hover"] = {
        borderColor: "#596778",
        boxShadow: "none",
      };
    }

    extraStyles.border = "1px solid #596778";

    if (isDisabled) {
      extraStyles.border = "1px solid #cacccf";
      extraStyles["& svg"] = {
        fill: "#cacccf",
      };
    }

    return {
      ...styles,
      backgroundColor: "white",
      height: 30,
      fontSize: 13,
      cursor: "pointer",
      ...extraStyles,
    };
  },
  indicatorSeparator: styles => ({
    ...styles,
    width: 0,
  }),

  valueContainer: styles => ({
    ...styles,
    height: 30,
  }),

  placeholder: styles => ({
    ...styles,
    fontSize: 14,
  }),

  singleValue: (styles, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    return {
      ...styles,
      fontSize: 14,
      opacity,
    };
  },
};
