import React from "react";
import { Select } from "components/form";
import { useCestsSegments } from "useCases/cest";

export const CestSegmentInput = ({ params, value, ...props }) => {
  const { data: dataSegments, isLoading } = useCestsSegments(params);

  const items = dataSegments?.segments?.map(segment => ({
    value: segment._id,
    label: `${segment.name} (${segment.countCests})`,
    name: segment.name,
  }));

  return (
    <Select
      name="segmento"
      label="Segmento"
      labelType="top"
      value={items?.find(
        segment => segment.name === value || segment.name === value?.name,
      )}
      isLoading={isLoading}
      data={items}
      {...props}
    />
  );
};
