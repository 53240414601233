import React, {
  createContext,
  useCallback,
  useEffect,
  useState,
  useContext,
} from "react";
import { useAPIAuthenticate } from "hooks";
import { useProductsMixSearching, useProductPostOrPut } from "useCases";

import { splitMulti } from "utils";

import { useToast } from "context/ToastContext";

export const ProductsContext = createContext({});

const escapeRegExp = value => {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
};

export const getProductPayload = data => ({
  prod_codigo_barras: data?.pcli_codigo_barras || null,
  prod_nome: data.pcli_nome || null,
  prod_ean_valido: null,
  prod_desvinculado: null,
  prod_data_revisao: null,
  ncm: data?.pcli_ncm || null,
  ncm_ex: data?.ncm_ex || null,
  cest: data?.pcli_cest || null,
  mer_departamento: data?.departament || null,
  mer_setor: data?.sector || null,
  mer_grupo: data?.group || null,
  mer_subgrupo: data?.subGroup || null,
  id_prod_cli: data?.id_prod_cli || null,
  name_in: data?.name_in || null,
  marca_in: data?.marca_in || null,
  modificador_in: data?.modificador_in || null,
  value_in: data?.value_in || null,
  unid_in: data?.unid_in || null,
  embalagem_in: data?.embalagem_in || null,
  unidade_medida_in: data?.unidade_medida_in || null,
  isIgnoreBlankFields: data?.isIgnoreBlankFields || null,
});

export const defaultValues = {
  success: null,
  error: null,
  loading: false,
  formData: {},
  ncmData: null,
  description: "",
};

export const ncmPosition = ncm =>
  String(ncm)
    .slice(0, 4)
    .replace(/(\d{2})?(\d{2})/, "$1.$2");

export const ncmSubPosition = ncm => String(ncm).slice(0, 6);

export const ncmFields = loadedData => ({
  session: loadedData.sessao,
  chapter: String(loadedData.capitulo).padStart(2, "0"),
  position: ncmPosition(loadedData.codigo),
  subPosition: ncmSubPosition(loadedData.codigo),
  item: undefined,
  subItem: loadedData.codigo,
});

/**
 * Provider to handle New Mix Product
 * @param {JSX.Element} children components tree that will use context provider
 * @returns returns a provider to New Product Context
 */

export const ProductsProvider = ({ children }) => {
  const { addToast } = useToast();

  const api = useAPIAuthenticate();

  const [ncmValues, setNcmValues] = useState({});

  const [loaded, setLoaded] = useState(defaultValues.loading);
  const [ncmData, setNcmData] = useState(defaultValues.ncmData);
  const [currentId, setCurrentId] = useState(null);
  const [uploadFile, setUploadFile] = useState();

  const [isShowDialog, setIsShowDialog] = useState({
    show: false,
    type: "",
  });
  const [isShowFilter, setIsShowFilter] = useState(false);
  const [filters, setFIlters] = useState([
    {
      columnField: {
        columnOrField: "and",
        columnField: "prod_nome",
        operatorValue: "notEmpty",
        value: "",
      },
    },
  ]);
  const [prodNomeDescriptions, setProdNomeDescriptions] = useState("");
  const [listExistentEquals, setListExistentEquals] = useState([]);
  const [listExistentEqualsSelecteds, setListExistentEqualsSelecteds] =
    useState([]);

  const [allProductAggregate, setAllProductAggregate] = useState([]);

  const [blacklist, setBlackList] = useState([]);
  const [whiteList, setWhiteList] = useState([]);
  const [formData, setFormData] = useState(defaultValues.formData);

  const [rows, setRows] = useState([]);
  const [rowsDialog, setRowsDialog] = useState([]);
  const [searchText, setsearchText] = useState("");

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [limit, setLimit] = useState(100);

  const checkMultiples = prodNomeDescriptions
    ? splitMulti(prodNomeDescriptions, ["|", "/", ",", ":"])
    : 0;

  const [params, setParams] = useState({
    page,
    limit,
  });

  const { onSave, isLoading: isLoadingOnSave } = useProductPostOrPut({
    id: currentId,
  });

  const {
    isLoading,
    data: dataProductMix,
    totalPages: totalPagesMixPost,
    fetch: fetchProductMixSearch,
  } = useProductsMixSearching();

  // toogle open filter
  const toogleFilter = useCallback(() => {
    setIsShowFilter(!isShowFilter);
  }, [isShowFilter, setIsShowFilter]);

  const handleSetSessionFilter = useCallback(
    filteresItems => {
      setFIlters([...filteresItems]);
    },
    [setFIlters],
  );

  // onChange filters
  const handleOnChangeFilter = useCallback(
    (field, value, index) => {
      const list = [...filters];
      list[index].columnField[field] = value;
      setFIlters([...list]);
    },
    [setFIlters, filters],
  );

  // onClick add filter
  const handleAddFilter = useCallback(() => {
    setFIlters([
      ...filters,
      {
        columnField: {
          columnOrField: "and",
          columnField: "",
          operatorValue: "",
          value: "",
        },
      },
    ]);
  }, [setFIlters, filters]);

  // onClick remove unic filter
  const handleRemoveFilter = useCallback(
    index => {
      const list = [...filters];
      list.splice(index, 1);
      setFIlters(list);
      // handleFilter(list);
    },
    [setFIlters, filters],
  );

  // handleSearch list
  const handleNewList = useCallback(
    (search = []) => {
      fetchProductMixSearch({
        ...params,
        filters: search,
      });
    },
    [fetchProductMixSearch, params],
  );

  // onClick remove all filters
  const handleRemoveFilterAll = useCallback(() => {
    setFIlters([
      {
        columnField: {
          columnOrField: "and",
          columnField: "prod_nome",
          operatorValue: "notEmpty",
          value: "",
        },
      },
    ]);
    handleNewList();
    setIsShowFilter(false);
  }, [setFIlters, setIsShowFilter, handleNewList]);

  const loadNcmData = useCallback(
    data => {
      // get loaded data from request object
      const [loadedData] = data?.data?.data?.docs || [];

      // if has valid ncm
      if (loadedData?.sessao) {
        // set product description
        // setDescription(loadedData.descricao);
        // set ncm fields
        setNcmData(ncmFields(loadedData));
        // finish loading
        setLoaded(true);
      }
    },
    [setNcmData],
  );

  const handleExistentRow = searchValue => {
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = (rows || []).filter(row => {
      return Object.keys(row).some(field => {
        return searchRegex.test(row[field].toString());
      });
    });

    return filteredRows || 0;
  };

  useEffect(() => {
    if (!isLoading && dataProductMix) {
      setTotalPages(totalPagesMixPost);
      if (isShowDialog?.show) {
        // const checkExistentAggregate = allProductAggregate.map(
        //   item => item?._id,
        // );

        // const newList = dataProductMix
        //   ?.filter(item => !checkExistentAggregate.includes(item?._id))
        //   .map(item => {
        //     return {
        //       id: item._id,
        //       ...item,
        //     };
        //   });
        // if (!newList?.length && allProductAggregate.length && isLoading) {
        //   setPage(page+ 1)
        //   setParams({
        //     page: page + 1,
        //     limit: 100,
        //   });
        //   return setRowsDialog([
        //     {
        //       id: 0,
        //       prod_nome: "Todos os protudos dessa página já estão vinculado",
        //     },
        //   ]);
        // }

        return setRowsDialog(
          dataProductMix
            // ?.filter(item => !s.includes(item?._id))
            .map(item => {
              return {
                id: item._id,
                ...item,
              };
            }),
        );
      }
      return setRows(
        dataProductMix?.map(item => {
          return {
            id: item._id,
            ...item,
          };
        }),
      );
    }
  }, [
    page,
    setPage,
    setParams,
    dataProductMix,
    isLoading,
    totalPagesMixPost,
    allProductAggregate,
    isShowDialog,
    setRowsDialog,
    setTotalPages,
  ]);

  const handleSelectedListExistentEquals = useCallback(
    rowsSelected => {
      setListExistentEqualsSelecteds(rowsSelected);
    },
    [setListExistentEqualsSelecteds],
  );

  // callback para adicionar item na whitelist
  const handleAddListExistentWhite = useCallback(
    item => {
      if (whiteList.length > 0) {
        setWhiteList([...whiteList, item]);
      }
      setWhiteList([...item]);
    },
    [whiteList, setWhiteList],
  );

  // callback para adicionar items na agrupados
  const handleAddListExistentAgrupados = useCallback(
    item => {
      if (allProductAggregate.length > 0) {
        setListExistentEqualsSelecteds([]);
        return setAllProductAggregate([...allProductAggregate, ...item]);
      }
      setListExistentEqualsSelecteds([]);
      return setAllProductAggregate([...item]);
    },
    [
      allProductAggregate,
      setAllProductAggregate,
      setListExistentEqualsSelecteds,
    ],
  );

  // callback para adicionar items na blacklist
  const handleAddListExistentBlack = useCallback(
    item => {
      if (blacklist.length > 0) {
        setBlackList([...blacklist, item]);
      }
      setBlackList([...item]);
    },
    [blacklist, setBlackList],
  );

  // callback to handle changes on input text fields
  const handleChange = useCallback(
    e => {
      const { name, value } = e.target;
      setFormData(fields => ({ ...fields, [name]: value }));
    },
    [setFormData],
  );

  // retorno de chamada para lidar com alterações em seleções
  const handleChangeField = useCallback(
    (field, value) => {
      setFormData(fields => ({
        ...fields,
        [field]: value,
      }));
    },
    [setFormData],
  );

  // callback to handle changes remove item update-item-list-negative
  const handleUpdateItemListNegative = useCallback(
    (index, selected) => {
      const getOthers = (blacklist || []).filter((r, key) => key !== index);
      const getItem = (blacklist || []).filter((r, key) => key === index);

      if (getItem) {
        const setVinculo = {
          prod_codigo_barras: getItem[0]?.prod_codigo_barras,
          prod_nome: getItem[0]?.prod_nome,
          id_prod: selected[0]?.id || selected[0]?._id,
          item: selected[0],
        };

        return setBlackList([...getOthers, setVinculo]);
      }
      return false;
    },
    [setBlackList, blacklist],
  );

  // callback to handle changes remove item update-item-list-negative
  const handleUpdateItemListNegativeId = useCallback(
    (id, selected) => {
      const getOthers = (blacklist || []).filter(r => r._id !== id);
      const getItem = (blacklist || []).filter(r => r._id === id);

      if (getItem) {
        const setVinculo = {
          _id: getItem[0]?._id,
          prod_codigo_barras: getItem[0]?.prod_codigo_barras,
          prod_nome: getItem[0]?.prod_nome,
          id_prod: id,
          item: selected[0],
        };

        return setBlackList([...getOthers, setVinculo]);
      }
      return false;
    },
    [setBlackList, blacklist],
  );

  // callback to handle changes remove item white-list
  const handleRemoveWhiteList = useCallback(
    (item, index) => {
      const checkExistentItemRemove = (whiteList || []).filter(
        (r, key) => key === index && r.prod_nome === item.prod_nome,
      );

      const newList = (whiteList || []).filter((r, key) => key !== index);

      if (checkExistentItemRemove) {
        return setWhiteList([...newList]);
      }

      return false;
    },
    [setWhiteList, whiteList],
  );

  // callback to handle changes remove item black-list
  const handleRemoveBlackList = useCallback(
    (item, index) => {
      const checkExistentItemRemove = (blacklist || []).filter(
        (r, key) => key === index && r.prod_nome === item.prod_nome,
      );

      const newList = (blacklist || []).filter((r, key) => key !== index);

      if (checkExistentItemRemove) {
        return setBlackList([...newList]);
      }

      return false;
    },
    [setBlackList, blacklist],
  );

  const handleAddAgreggate = useCallback(
    (value, value2) => {
      const checkExistentAllProduct = (allProductAggregate || []).find(
        item => item.prod_codigo_barras === value2,
      );

      if (!checkExistentAllProduct) {
        if (allProductAggregate && allProductAggregate.length > 0) {
          return setAllProductAggregate([
            ...allProductAggregate,
            {
              prod_nome: value,
              prod_codigo_barras: value2,
            },
          ]);
        }
        return setAllProductAggregate([
          {
            prod_nome: value,
            prod_codigo_barras: value2,
          },
        ]);
      }
    },
    [allProductAggregate, setAllProductAggregate],
  );

  const handleRemoveAgreggate = useCallback(
    (item, index) => {
      const checkExistentItemRemove = (allProductAggregate || []).filter(
        (r, key) => key === index && r.name === item.name,
      );

      const newList = (allProductAggregate || []).filter(
        (r, key) => key !== index,
      );

      if (checkExistentItemRemove) {
        return setAllProductAggregate([...newList]);
      }

      return false;
    },
    [setAllProductAggregate, allProductAggregate],
  );

  // callback to handle changes add item white-list
  const handleAddlistWhite = useCallback(
    (value, value2) => {
      if (value && value2) {
        const checkExistentEan = (whiteList || []).find(
          item => item.prod_codigo_barras === value2,
        );

        if (!checkExistentEan) {
          if (whiteList && whiteList.length > 0) {
            return setWhiteList([
              ...whiteList,
              {
                prod_nome: value,
                prod_codigo_barras: value2,
              },
            ]);
          }
          return setWhiteList([
            {
              prod_nome: value,
              prod_codigo_barras: value2,
            },
          ]);
        }
        return true;
      }
      return false;
    },
    [setWhiteList, whiteList],
  );

  // callback to handle changes add item black-list
  const handleAddlistBlack = useCallback(
    (value, value2) => {
      if (value && value2) {
        const checkExistentName = (blacklist || []).filter(
          item => item.prod_nome === value,
        );
        const checkExistentEan = (blacklist || []).filter(
          item => item.prod_codigo_barras === value2,
        );

        if (checkExistentName.length === 0 || !checkExistentEan.length === 0) {
          if (blacklist.length > 0) {
            return setBlackList([
              ...blacklist,
              {
                prod_nome: value,
                prod_codigo_barras: value2,
              },
            ]);
          }
          return setBlackList([
            {
              prod_nome: value,
              prod_codigo_barras: value2,
            },
          ]);
        }
        return true;
      }

      return false;
    },
    [blacklist, setBlackList],
  );

  const uploadImage = useCallback(async () => {
    if (!uploadFile) return "";
    try {
      let urlPhoto = "";
      const formDataItem = new FormData();
      formDataItem.append("file", uploadFile);

      await api.post(`/uploadPhoto`, formDataItem).then(response => {
        const { photo } = response.data.user;
        urlPhoto = photo;
      });

      return urlPhoto;
    } catch (err) {
      addToast({
        title: "Erro ao salvar o logo",
        type: "error",
        description: `Ocorreu o seguinte erro: ${err.message}`,
      });
    }
  }, [api, uploadFile, addToast]);

  // callback para onSubmit
  const onSubmit = useCallback(async () => {
    const image = uploadFile ? await uploadImage() : false;

    const dados = {
      ...formData,
      image: image || "",
      whitelist: whiteList,
      blacklist,
      grouped: allProductAggregate,
    };
    await onSave(dados);
  }, [
    formData,
    whiteList,
    blacklist,
    allProductAggregate,
    onSave,
    uploadImage,
    uploadFile,
  ]);

  // callback para setParams
  const handleParams = (items = []) => {
    items.map(item => {
      const filter = {};
      setParams({
        page: 1,
        limit: 75,
        [item.columnField]: item.value,
        operatorValue: item.operatorValue,
      });
      return filter;
    });
  };

  return (
    <ProductsContext.Provider
      value={{
        isShowFilter,
        setIsShowFilter,
        filters,
        setFIlters,
        toogleFilter,
        handleOnChangeFilter,
        handleAddFilter,
        handleRemoveFilter,
        handleRemoveFilterAll,
        handleSetSessionFilter,
        handleNewList,
        ncmValues,
        setNcmValues,
        uploadFile,
        setUploadFile,
        isShowDialog,
        setIsShowDialog,
        formData,
        setFormData,
        rowsDialog,
        setRowsDialog,
        rows,
        setRows,
        isLoading,
        handleExistentRow,
        setPage,
        setLimit,
        totalPages,
        setTotalPages,
        searchText,
        setsearchText,
        setParams,
        handleParams,
        onSubmit,
        handleChangeField,
        handleChange,
        whiteList,
        isLoadingOnSave,
        setWhiteList,
        blacklist,
        setBlackList,
        checkMultiples,
        prodNomeDescriptions,
        setProdNomeDescriptions,
        handleAddlistWhite,
        handleAddlistBlack,
        handleRemoveWhiteList,
        handleRemoveBlackList,
        listExistentEquals,
        setListExistentEquals,
        listExistentEqualsSelecteds,
        setListExistentEqualsSelecteds,
        handleSelectedListExistentEquals,
        handleAddListExistentWhite,
        handleAddListExistentBlack,
        loadNcmData,
        ncmData,
        loaded,
        allProductAggregate,
        setAllProductAggregate,
        handleAddAgreggate,
        handleRemoveAgreggate,
        handleAddListExistentAgrupados,
        currentId,
        setCurrentId,
        handleUpdateItemListNegative,
        handleUpdateItemListNegativeId,
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
};

export const useProductsContext = () => {
  const context = useContext(ProductsContext);
  if (!context)
    throw new Error(
      "useProductsContext precisa ser utilizado dentro de um ProductProvider",
    );
  return context;
};
