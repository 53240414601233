import styled, { css } from "styled-components";
import colors from "styles/tokens/colors";

export const Container = styled.div`
  width: 100%;
  padding: 0 8px 0px 0px;

  display: flex;
  gap: 24px;

  .edit-icon {
    cursor: pointer;

    path {
      fill: #565656;
      transition: all 0.1s ease-in-out;
    }

    &:hover {
      path {
        fill: #adc86a;
      }
    }
  }
`;

export const Section1 = styled.section`
  flex: 0.2;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .tableContainer {
    font-size: 12px;
  }

  .div-overflow {
    min-height: 300px;
    overflow: hidden;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    &.storestest tr th:first-child > .headerCell {
      display: none;
    }
  }

  & > div {
    display: flex;
    flex-direction: column;
    p.title {
      color: #17456b;
      font-size: 15px;
    }

    table {
      border-collapse: collapse;
      margin: 15px 0;
      width: 100%;
    }

    th > div {
      padding: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        font-size: 13px;
        font-weight: bold;
        color: #565656;
      }
    }

    tr {
      border: 1px solid #bababa;
      transition: all 0.1s ease-in-out;
      cursor: pointer;

      &:hover {
        background-color: #f2f4f4;
      }
    }

    td {
      padding: 4px 8px;

      &.action {
        text-align: center;
      }
      p {
        font-size: 13px;
        color: #565656;
      }
    }

    thead {
      tr {
        cursor: normal;
        background-color: transparent;
      }
    }
  }
`;

export const Section2 = styled.section`
  flex: 0.8;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const CardSubHeader = styled.div`
  display: flex;
  margin-bottom: 5px;
`;

export const CardSubHeaderDescription = styled.div`
  flex: 0.8;
`;
export const CardSubItemPagination = styled.div`
  width: 100%;
  .hqzNoU {
    border-bottom: 0px;
    margin-top: 0px;
    padding: 8px;
    .iuBnlF {
      font-size: 15px;
      padding: 2px 8px;
    }
  }
`;
export const CardSubHeaderAction = styled.div`
  flex: 0.2;
  text-align: right;
`;

export const CardSubHeaderActionButton = styled.button`
  background-color: #256926 !important;
  color: #fff !important;
  font-size: 13px !important;
  padding: 4px 8px !important;
  border-radius: 5px;
  margin-top: 0 !important;
  span {
    display: none;
    margin-left: 5px;
    overflow: hidden;
    white-space: nowrap;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    text-overflow: ellipsis;
  }
`;

export const Shareitems = styled.div`
  content: "";
  width: calc(100% - 30px);
  height: 1px;
  background-color: ${colors.slateGray};
  border-radius: 100px;
  opacity: 0.4;
  margin-left: 30px;
  ${props =>
    props.isSelected &&
    css`
      & {
        width: 0 !important;
        height: 0 !important;
      }
    `}
`;

export const Isloading = styled.div`
  text-align: center;
  padding: 15px 0px 15px 0px;
`;

const customBadge = {
  setType: type => css`
    ${type === `MATRIZ` &&
    `
    background-color: #adc86a;
    color:#fff
    `}
    ${type === `Matriz` &&
    `
    background-color: #adc86a;
    color:#fff
    `}
    ${type === `ativo` &&
    `
    background-color: #adc86a;
    color:#fff
    `}
    ${type === `Filial` &&
    `
    background-color: #17456B;
    color:#fff
    `}
     ${type === `FILIAL` &&
    `
    background-color: #17456B;
    color:#fff
    `}
      ${type === `inativo` &&
    `
    background-color: #f4a300cc;
    color:#fff
    `}
  `,
};
export const BadgeContainer = styled.div`
  display: flex;
`;
export const Badge = styled.div`
  ${({ type }) => css`
    min-width: 30px;
    font-size: 12px;
    padding: 0px 10px 0px 10px;
    border-radius: 20px;
    font-weight: bold;
    margin: 0 4px 0 4px;
    ${type && customBadge.setType(type)}
  `}
`;
