import React, { useEffect, useState } from "react";
import { GridManagerProvider } from "context/GridManagerContext";
import {
  useEanConsult,
  useEan0200Consult,
  useTributesConsult,
  useGraphicConsult,
  useProductsConsult,
  useProductsConsultMix,
} from "useCases/productsConsult";
import Select from "react-select";
import moment from "moment";
import Tabs from "../../components/UI/Tabs";
import ComercialSide from "./Comercial/Side";
import ComercialMain from "./Comercial/Main";
import ProductDetail from "./ProductDetail";
import TributeSide from "./Tributes/Side";
import TributeMain from "./Tributes/Main";

import * as S from "./styles";

function ProductsConsult({ dataProduct }) {
  const [tabIndex, setTabIndex] = useState("Comercial");
  const [searchBoxContent, setSearchBoxContent] = useState([]);
  const [selectedFornecedor, setSelectedFornecedor] = useState({});
  const { data: eanDetails, fetch: fetchEanConsult } = useEanConsult({});

  const { data: ean0200Details, fetch: fetch0200EanConsult } =
    useEan0200Consult({});

  const { data: tributeConsult, fetch: fetchTributeConsult } =
    useTributesConsult(dataProduct.id_prod);

  const { data: eanGraphicDetails, fetch: fetchGraphicConsult } =
    useGraphicConsult({});

  const {
    data: fullDataProductsCustomer,
    fetch: fetchProductsCustomerConsult,
  } = useProductsConsult();

  const {
    data: fullDataProductsCustomerMix,
    fetch: fetchProductsCustomerConsultMix,
  } = useProductsConsultMix();

  const dataProdSelect = fullDataProductsCustomer?.data?.customerProduct;
  const dataProductMix = fullDataProductsCustomerMix?.data?.product;

  useEffect(() => {
    fetchTributeConsult();
    fetchEanConsult({ ean: dataProduct.ean });
    fetch0200EanConsult({ ean: dataProduct.ean });
  }, [
    dataProduct.ean,
    fetch0200EanConsult,
    fetchEanConsult,
    fetchTributeConsult,
  ]);

  useEffect(() => {
    fetchGraphicConsult();
    if (dataProduct.id) {
      fetchProductsCustomerConsult(dataProduct.id);
    }
    if (dataProduct.id_prod) {
      fetchProductsCustomerConsultMix(dataProduct.id_prod);
    }
  }, [
    dataProduct.id,
    dataProduct.id_prod,
    fetchGraphicConsult,
    fetchProductsCustomerConsult,
    fetchProductsCustomerConsultMix,
  ]);

  useEffect(() => {
    if (ean0200Details && ean0200Details?.data?.length > 0) {
      const fornecedores = ean0200Details.data.map(item => ({
        label: item.nome,
        value: item.cnpj_fornecedor,
      }));
      setSearchBoxContent(fornecedores);
    }
  }, [ean0200Details]);

  const changeTabIndex = item => {
    setTabIndex(item);
  };

  const handleFornecedor = fornecedor => {
    const findedFornecedor = ean0200Details?.data.find(
      line => line.cnpj_fornecedor === fornecedor.value,
    );
    setSelectedFornecedor(findedFornecedor);
  };
  return (
    <GridManagerProvider>
      <S.Header />
      <S.Container>
        <S.Header>
          <div className="column-one">
            {tabIndex === "Comercial" ? (
              <p>
                Você compra esse produto de&nbsp;
                <span>{searchBoxContent && searchBoxContent.length}</span>
                &nbsp;fornecedores:
              </p>
            ) : (
              <p>
                Selecione um fornecedor ou configure um cenário.
                <br />
                <span>
                  {searchBoxContent.nome && searchBoxContent.nome.length}
                </span>
              </p>
            )}
            <Select
              options={searchBoxContent}
              className="select-providers"
              name="operacao_e"
              placeholder="Fornecedor"
              defaultValue={searchBoxContent[0]}
              onChange={handleFornecedor}
            />
          </div>
          <div className="column-two">
            <Tabs
              items={["Comercial", "Tributária"]}
              type="slim"
              handleClickCallback={item => changeTabIndex(item)}
            />
          </div>
        </S.Header>
        <S.BodyContainer>
          <S.ColumnOneContainer>
            <ProductDetail
              dataProduct={dataProdSelect}
              ProductTwin={dataProductMix}
            />
            {tabIndex === "Comercial" ? (
              <ComercialMain
                eanDetails={eanDetails?.data?.data}
                ean0200Details={selectedFornecedor}
                dataProduct={dataProdSelect}
                eanGraphicDetails={eanGraphicDetails}
              />
            ) : (
              <TributeMain
                dataProduct={dataProdSelect}
                dataProductId={dataProduct}
                dataTribute={tributeConsult}
                ProductTwin={dataProductMix}
              />
            )}
          </S.ColumnOneContainer>
          <S.ColumnTwoContainer>
            {tabIndex === "Comercial" ? (
              <ComercialSide
                dataProduct={dataProdSelect}
                ean0200Details={selectedFornecedor}
                eanDetails={eanDetails?.data?.data}
                ProductTwin={dataProductMix}
              />
            ) : (
              <TributeSide
                dataProduct={dataProdSelect}
                dataTribute={tributeConsult}
                ProductTwin={dataProductMix}
                ean0200Details={selectedFornecedor}
              />
            )}
          </S.ColumnTwoContainer>
        </S.BodyContainer>
        {tabIndex === "Comercial" ? (
          <div className="last-information">
            <span>
              Última Atualização:&nbsp;
              {moment(Date()).format("DD/MM/YYYY")}
            </span>
            <span>Atualizar Agora!</span>
          </div>
        ) : (
          ""
        )}
      </S.Container>
    </GridManagerProvider>
  );
}

export default ProductsConsult;
