import { useState, useEffect, useCallback } from "react";
import { useCheckLinkAPI } from "../../../hooks";
import { getURLWithParams } from "../../../utils/context";
import { getSessionUser } from "../../../utils/session";

const useTasks = () => {
  const api = useCheckLinkAPI();
  const [tasks, setTasks] = useState([]);
  const sessionUser = getSessionUser();

  const getAll = useCallback(
    async ({ filters = {}, limit = 10, page = 0 }) => {
      try {
        const values = await api.get(
          getURLWithParams("/tasks", {
            filter: JSON.stringify({
              ...filters,
              "tasks.assigned.userId": sessionUser._id,
            }),
            limit,
            page,
          }),
        );
        setTasks([]);
        setTimeout(() => {
          setTasks(values.data.docs);
        }, 10);
      } catch (e) {
        console.log(e);
      }
    },
    [api, sessionUser._id],
  );

  const update = useCallback(
    async (id, payload) => {
      try {
        await api.patch(`/tasks/${id}`, payload);
      } catch (e) {
        console.log(e);
      }
    },
    [api],
  );

  useEffect(() => getAll({}), [getAll]);

  return {
    tasks,
    getAll,
    update,
  };
};

export default useTasks;
