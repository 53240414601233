import React, { useCallback, useState, useRef, useEffect } from "react";

import * as S from "./styles";
import * as CS from "../../../../structure";
import { DropdownCheckbox } from "../dropdown-checkbox";
import { useOnClickOutside } from "../../../../../hooks";

export const DropdownSearch = ({
  width,
  label,
  selected,
  isOpen,
  onClick,
  close,
  placeholder,
  footerMessage,
  onChangeCallback,
  onSelectAll,
  selectedAll,
  multiselect = false,
  data,
}) => {
  const ref = useRef();
  const listInnerRef = useRef();
  const [search, setSearch] = useState("");
  const [slice, setSlice] = useState(10);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [filtered, setFiltered] = useState([]);

  // label match helper
  const checkLabelMatch = useCallback(
    option => option.label.toLowerCase().includes(search.toLowerCase()),
    [search],
  );

  // Set new options based on slice
  useEffect(() => setOptions(data.slice(0, slice)), [data, slice]);

  // Filter options by search
  useEffect(() => {
    setFiltered(options.filter(o => checkLabelMatch(o)));
  }, [options, checkLabelMatch]);

  // Call hook for handling close
  useOnClickOutside(ref, useCallback(close, [close]));

  // Set loading when scroll reach end
  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      const reachedBottom = scrollTop + clientHeight === scrollHeight;
      if (reachedBottom) {
        let newSlice;

        if (slice + 10 > data.length) {
          newSlice = data.length - slice;
        } else {
          newSlice = slice + 10;
        }

        if (slice < data.length) {
          setLoading(true);

          setTimeout(() => {
            setLoading(false);
            setSlice(newSlice);
          }, 500);
        }
      }
    }
  };

  // Set delay to call a new search.
  // default: 0 (no-delay)
  const handleChangeSearch = e => {
    const timeout = setTimeout(() => setSearch(e.target.value), 0);
    return () => clearTimeout(timeout);
  };

  // check selected helper
  const isChecked = useCallback(
    option =>
      multiselect
        ? selected.some(s => s._id === option._id)
        : selected?._id === option._id,
    [multiselect, selected],
  );

  return (
    <S.Container ref={ref}>
      <span role="button" onClick={onClick}>
        {label}
      </span>
      {isOpen && (
        <S.Dropdown open={!!filtered.length} width={width}>
          <S.InputContainer>
            <S.InputWrapper>
              <S.InputSearch
                placeholder={placeholder}
                search={search}
                onChange={handleChangeSearch}
              />
            </S.InputWrapper>
          </S.InputContainer>
          {!!filtered.length && (
            <>
              <S.DropdownWrapper>
                {onSelectAll && (
                  <S.SelectAllWrapper>
                    <DropdownCheckbox
                      name="select-all"
                      label="Selecionar todas"
                      checked={onSelectAll && selectedAll}
                      onClick={onSelectAll}
                    />
                  </S.SelectAllWrapper>
                )}
                <S.OptionsWrapper onScroll={onScroll} ref={listInnerRef}>
                  {filtered.map((option, index) => (
                    <DropdownCheckbox
                      search={search}
                      label={option.label}
                      onClick={() => onChangeCallback(index, option)}
                      checked={isChecked(option)}
                      withHover
                    />
                  ))}
                  {loading && (
                    <S.LoadingContainer>
                      <CS.CircularLoader size={16} />
                    </S.LoadingContainer>
                  )}
                </S.OptionsWrapper>
              </S.DropdownWrapper>
              <S.FooterWrapper>
                <S.LabelFooter>
                  {`${data.length} ${
                    data.length === 1
                      ? footerMessage.singular
                      : footerMessage.plural
                  }`}
                </S.LabelFooter>
              </S.FooterWrapper>
            </>
          )}
        </S.Dropdown>
      )}
    </S.Container>
  );
};
