import styled from "styled-components";
import * as SelectStyles from "components/form/Select/styles";

export const Container = styled.div``;

export const ContainerPlus = styled.div`
  display: flex;
  width: 100%;

  ${SelectStyles.Select} {
    flex: 1;
  }
`;

export const Action = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const RowTagsName = styled.div`
  width: 100%;
  padding: 2px 0;
  border-bottom: 2px solid #e5e5e5;
  margin: 10px 0;
  padding-bottom: 15px;
  display: flex;
  flex-flow: row wrap;
  gap: 7px;
  position: relative;
`;

export const ActionRemove = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;
