import {
  COMPANIES_TYPES,
  TRIBUTARY_SITUATION,
  TRIBUTARY_ALQ,
  ENTRIES_TYPES,
  OUTS_TYPES,
  ENTRADA,
  SAIDA,
  Grades,
} from "constants/types";

export const hasPisCofinsTax = value =>
  Grades.PISCOFINS === value || value === "pis";

export const hasLucro = type =>
  COMPANIES_TYPES.LR === type || COMPANIES_TYPES.LP === type;

export const getOperacaoLabel = operacao => {
  if (!operacao) {
    return "Sem operação definida";
  }
  return (
    ENTRIES_TYPES[operacao.toUpperCase()] ?? OUTS_TYPES[operacao.toUpperCase()]
  );
};

export const getAlqs = grade => {
  const alqsSaida = grade?.alq_saida || [];
  const alqsEntrada = grade?.alq || [];

  const list =
    alqsEntrada.length >= alqsSaida.length
      ? alqsEntrada.map(alq => ({ alq, type: "entrada" }))
      : alqsSaida.map(alq => ({ alq, type: "saida" }));

  const listReturn = list.map((item, i) => {
    if (item.type === "entrada") {
      return { entrada: item.alq, saida: alqsSaida[i] };
    }

    return {
      saida: item.alq,
      entrada: alqsEntrada[i],
    };
  });

  return listReturn;
};

export const getAlqLabel = ({ entrada, saida }) => {
  if (!entrada && !saida) {
    return "Sem Alíquota";
  }

  if ((entrada || entrada === 0) && (saida || saida === 0)) {
    return `${entrada}%X${saida}%`;
  }

  if (entrada || entrada === 0) {
    return `${entrada}%`;
  }

  return `${saida}%`;
};

export const getOperacaoType = operacao =>
  ENTRIES_TYPES[operacao?.toUpperCase()] ? ENTRADA : SAIDA;

export const hasGradeEntrada = operacao =>
  getOperacaoType(operacao) === ENTRADA;

export const getListSelecteds = (list = []) => list.join(" + ");

export const getListTagsValid = list =>
  list.filter(item => item !== null && item !== undefined && item !== "");

export const getTags = values => Object.keys(values).filter(row => values[row]);

export const hasScenery = (type, values = []) => {
  let exists = false;
  if (type === "entrada") {
    const idx = values?.findIndex(s => s.ufOrigem);

    if (idx !== -1) {
      exists = true;
    }
  } else {
    const idx = values?.findIndex(s => s.ufDestino);

    if (idx !== -1) {
      exists = true;
    }
  }

  return exists;
};

export const getTagsWithLabel = (values, prefix = "cst_e") =>
  Object.keys(values)
    .filter(row => values[row])
    .map(
      value =>
        TRIBUTARY_SITUATION[`${prefix}.${value}`] ??
        TRIBUTARY_ALQ[`${prefix}.${value}`] ?? {
          label: "Não identificada",
          value: undefined,
        },
    );

export const hasTags = values => getTags(values).length > 0;

export const getAttributes = (values = []) => {
  const list = {};

  values.forEach(row => {
    if (!row.name) return false;

    if (!list[row.name]) {
      list[row.name] = {
        name: row.name,
        id: row.id,
        list: [],
      };
    }

    list[row.name]?.list.push(row);
  });

  return Object.keys(list).map(row => list[row]);
};
