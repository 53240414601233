import { useState, useEffect, useCallback } from "react";
import { useCheckLinkAPI } from "../../../hooks";
import { getURLWithParams } from "../../../utils/context";

const useModules = () => {
  const api = useCheckLinkAPI();
  const [modules, setModules] = useState([]);

  const getAll = useCallback(
    async ({ limit = 10, page = 0 }) => {
      try {
        const { data } = await api.get(
          getURLWithParams("/modules", { limit, page }),
        );
        setModules(data);
      } catch (e) {
        console.log(e);
      }
    },
    [api],
  );

  const create = useCallback(
    async payload => {
      try {
        await api.post(getURLWithParams("/modules", payload));
      } catch (e) {
        console.log(e);
      }
    },
    [api],
  );

  useEffect(() => getAll({}), [getAll]);

  return {
    modules,
    getAll,
    create,
  };
};

export default useModules;
