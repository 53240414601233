import { useRef } from "react";
import axios from "axios";
import { getSessionCompanies } from "utils/session";

const BASE_URL = "https://api.builder.mixfiscal.com.br/integrations";
const externalToken =
  "1wBzE5gdMqoCwzseVcROZ1VwrZdwq3ICf4ouhrMZy6uxPJdtUl+kbZ9qtqnCsfRHjKHHF/m7xUzc/b+XqHVXWrfCXZokA7QNKSGb2Aghblt649bfMPSeNkk0dkWldj2LydbB8sNmrmX+TtpT7nLRCw==";

export const useAPILinkMix = () => {
  const apiRef = useRef(axios.create({ baseURL: BASE_URL }));

  apiRef.current.interceptors.request.use(config => {
    config.headers["x-token"] = getSessionCompanies();
    config.headers["X-API-Key"] = externalToken;
    return config;
  });

  // TODO: Adicionar toast automatico quando as requisições falharem!
  apiRef.current.interceptors.response.use(
    response => response,
    error => {
      // console.log(error.response);
      return Promise.reject(error);
    },
  );

  return apiRef.current;
};
