import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBoxesPacking,
  faTruckRampBox,
} from "@fortawesome/free-solid-svg-icons";

import * as S from "./ProductList.styles";

export const CustomRowsOverlayLoading = ({ margin }) => {
  return (
    <S.StyledGridOverlay setMargin={margin}>
      <FontAwesomeIcon
        icon={faTruckRampBox}
        style={{
          margin: "10px 0 20px 0",
          fontSize: "60px",
        }}
        color="#256925"
      />
      <h3>Carregando... Aguarde!</h3>
    </S.StyledGridOverlay>
  );
};

export const CustomNoRowsOverlay = ({ title, margin }) => {
  return (
    <S.StyledGridOverlay setMargin={margin}>
      <FontAwesomeIcon
        icon={faBoxesPacking}
        style={{
          marginTop: "10px",
          fontSize: "60px",
        }}
        color="#848484"
      />
      <h3>{title || "Sem linha"}</h3>
    </S.StyledGridOverlay>
  );
};
