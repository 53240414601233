import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

export const InfoCloneLink = styled.b`
  margin-left: 8px;
`;
