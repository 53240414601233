import {
  STATUS_GRADES,
  CST_ICMS_REDUCAO_BASE,
  CST_ICMS_ALIQ_ISENTO,
  CST_ENTRADA_TRIBUTADO,
  CST_ENTRADA_NAO_TRIBUTADO,
} from "constants/types";

import { hasGradeEntrada } from "utils/context/grid-manager";

export const hasGradeConflito = status => status === STATUS_GRADES.CONFLITO;

export const hasGradePreGrade = status => status === STATUS_GRADES.PRE_GRADE;

export const hasGradeArchived = status => status === STATUS_GRADES.ARQUIVADA;

/**
 * Calculo de carga que vai ser aplicada alq - alq * (rbc / 100)
 * @param {Number} alq
 * @param {Number} rbc
 * @returns {Number}
 */
export const calcCarga = (alq, rbc) =>
  parseFloat(alq - alq * (rbc / 100)).toFixed(2);

/**
 * Retorna se a aliq pode ser usada para determinado CST ou seja nao e isento!
 * @param {*} cstValue
 * @returns {Boolean}
 */
export const hasAliq = cstValue => !CST_ICMS_ALIQ_ISENTO.includes(cstValue);

/**
 * Verifica se o CST passado nao e do tipo reducao de base
 * @param {*} cstValue
 * @returns {Boolean}
 */
export const hasReducaoBase = cstValue =>
  CST_ICMS_REDUCAO_BASE.includes(cstValue);

/**
 * Verificação de CST de entrada se realmente e tributado!
 * @param {*} cstValue
 * @returns {Boolean}
 */
export const hasCstEntradaTributado = cstValue =>
  CST_ENTRADA_TRIBUTADO.includes(cstValue);

/**
 * Verificação se o CST de entrada realmente por ser usado para tributar
 * @param {*} cstValue
 * @returns {Boolean}
 */
export const hasCstEntradaNaoTributado = cstValue =>
  !CST_ENTRADA_NAO_TRIBUTADO.includes(cstValue);

export const ncmBuilderToLinkmix = (ncms = []) =>
  (ncms ?? []).map(ncm => ({
    ...ncm,
    value: ncm.linkmix_id,
    data: {
      vc_codigo_sem_formatacao: ncm.codigo,
    },
  }));

export const cestsBuilderToLinkmix = (cests = []) =>
  (cests ?? []).map(cest => ({
    ...cest,
    value: cest.linkmix_id,
    data: {
      vc_codigo: cest.codigo,
    },
  }));

export const attributesBuilderToScreen = (values = {}) => ({
  ...values,
  aliqEntrada: values.aliqCstEntrada,
  aliqSaida: values.aliqCstSaida,
});

export const hasValueNcm = (values = []) => {
  for (const value of values) {
    if (value !== "Selecione" && value) {
      return value;
    }
  }
  return null;
};

export const attributesBuilderToScenary = ({ values = {} }) => {
  const { uf, uf_saida: ufSaida, operacao, ...grade } = values;
  if (hasGradeEntrada(operacao)) {
    return {
      ufOrigem: uf?.toUpperCase(),
      ufDestino: ufSaida?.toUpperCase(),
      origem: operacao?.toUpperCase(),
      tipoEmpresa: grade.regime,
      atividade: grade.atividade,
      saida: grade.operacao_saida,
      tipoEmpresaSaida: grade.regime_saida,
    };
  }

  return {
    ufDestino: uf?.toUpperCase(),
    saida: operacao?.toUpperCase(),
    tipoEmpresa: grade.regime,
    tipoEmpresaSaida: grade.regime_saida,
    atividade: grade.atividade,
    origem: null,
    ufOrigem: null,
  };
};

export const generateIdScenery = scenery => {
  return [
    scenery.origem,
    scenery.saida,
    scenery.ufOrigem,
    scenery.ufDestino,
    scenery.tipoEmpresa,
    scenery.atividade,
    scenery.origem?.value,
    scenery.saida?.value,
    scenery.ufOrigem?.value,
    scenery.ufDestino?.value,
    scenery.tipoEmpresa?.value,
    scenery.atividade?.value,
    scenery.tipoEmpresaSaida?.value,
  ]
    .filter(item => item && item !== "")
    .join("-");
};

const newLevel = (values = [], cb = () => {}) =>
  values.forEach(value => cb(value));

const generateSceneryUfOrigemXUfDestino = (scenery, cb = () => {}) => {
  const destinoCenery = ufOrigem =>
    newLevel(scenery.ufDestino, ufDestino => {
      cb({
        ...scenery,
        ufDestino: ufDestino?.value,
        ufOrigem: ufOrigem?.value,
      });
    });

  if (scenery?.ufDestino?.length && !scenery?.ufOrigem?.length) {
    return destinoCenery();
  }

  newLevel(scenery.ufOrigem, ufOrigem => {
    if (scenery?.ufDestino?.length) {
      destinoCenery(ufOrigem);
    } else {
      cb({ ...scenery, ufOrigem: ufOrigem?.value });
    }
  });
};

const generateSceneryOrigemXSaida = (scenery, sceneries = [], concat = {}) => {
  const saidaCenery = origem =>
    newLevel(scenery.saida, saida => {
      sceneries.push({
        ...scenery,
        ...concat,
        saida: saida?.value,
        origem: origem?.value,
      });
    });

  if (scenery?.saida?.length && !scenery?.origem?.length) {
    return saidaCenery();
  }

  newLevel(scenery?.origem, origem => {
    if (scenery?.saida?.length) {
      saidaCenery(origem);
    } else {
      sceneries.push({ ...scenery, ...concat, origem: origem?.value });
    }
  });
};

export const generateSceneries = scenery => {
  const sceneries = [];
  const generateX = (extendsX = {}) =>
    generateSceneryUfOrigemXUfDestino(scenery, ({ ufDestino, ufOrigem }) => {
      generateSceneryOrigemXSaida(scenery, sceneries, {
        ...scenery,
        ufDestino,
        ufOrigem,
        ...extendsX,
      });
    });

  newLevel(scenery?.atividade, atividade => {
    const generateSaida = (extraParams = {}) =>
      newLevel(scenery.tipoEmpresaSaida, tipoEmpresaSaida => {
        generateX({
          atividade: atividade?.value,
          tipoEmpresaSaida: tipoEmpresaSaida?.value,
          ...extraParams,
        });
      });

    if (scenery?.tipoEmpresa) {
      newLevel(scenery?.tipoEmpresa, tipoEmpresa => {
        if (scenery?.tipoEmpresaSaida?.length) {
          generateSaida({
            tipoEmpresa: tipoEmpresa?.value,
          });
        } else {
          generateX({
            tipoEmpresa: tipoEmpresa?.value,
            atividade: atividade?.value,
          });
        }
      });
    } else {
      generateSaida({ tipoEmpresa: null });
    }
  });

  return sceneries;
};
