import React, { useState, useEffect } from "react";
import { Tag, Dropdown } from "@mix/mixfiscal-designsystem";
import DatePicker, { registerLocale } from "react-datepicker";
import { format, differenceInCalendarMonths } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import "react-datepicker/dist/react-datepicker.css";

import * as S from "./styles";

registerLocale("ptBR", ptBR);
const TagsList = ({
  tags = [],
  onChangeDate,
  onChangeType,
  tag,
  hideTag,
  hideTags = false,
}) => {
  const [startDate, setStartDate] = useState(tags[1]?.dtStart);
  const [endDate, setEndDate] = useState(tags[1]?.dtEnd);
  const [itemList, setItemList] = useState(tag ?? tags[0]);

  useEffect(() => {
    setStartDate(tags[1]?.dtStart);
    setEndDate(tags[1]?.dtEnd);
  }, [tags]);

  const onChange = dates => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    onChangeDate(start, end);
  };

  const onChangeItemList = item => {
    setItemList(item);
    onChangeType(item);
  };

  const tagsList = () => {
    const tagsDropList = ["Sped Contribuições", "Sped Fiscal", "XML"];
    return (
      <ul>
        {tagsDropList.map(item => {
          return (
            <li
              role="button"
              key={item}
              onClick={() => {
                onChangeItemList(item);
              }}
            >
              {item}
            </li>
          );
        })}
      </ul>
    );
  };

  const ExampleCustomInput = React.forwardRef(({ onClick }, ref) => {
    const difMonth = differenceInCalendarMonths(endDate, startDate);
    const period = `${format(
      new Date(startDate),
      difMonth > 0 ? "dd/MM/yyyy" : "dd",
    )} a ${format(
      new Date(endDate !== null ? endDate : startDate),
      "dd/MM/yyyy",
    )}`;

    return (
      <Tag label={period} handleClick={onClick} ref={ref}>
        {period}
      </Tag>
    );
  });

  if (hideTags) {
    return null;
  }

  return (
    <S.ContainerFooter>
      {tags?.map(item => (
        <S.TagContainer>
          {item !== "XML" && item !== "XML1" && item !== "XML2" ? (
            <DatePicker
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              customInput={<ExampleCustomInput />}
              locale="ptBR"
              monthsShown={2}
              showYearDropdown
            />
          ) : !hideTag ? (
            <S.ConstainerDropDown>
              <p>
                <Dropdown dropContent={tagsList()} fitToParentWidth={false}>
                  <span>
                    <Tag label={itemList} type="simple" />
                  </span>
                </Dropdown>
              </p>
            </S.ConstainerDropDown>
          ) : (
            ""
          )}
        </S.TagContainer>
      ))}
    </S.ContainerFooter>
  );
};

export default TagsList;
