import React from "react";
import { Icon, Tooltip } from "@mix/mixfiscal-designsystem";

import { TooltipMenu } from "../TootipMenu";

import * as S from "../../styles";

export const MenuPrimary = ({
  isSecondaryActive,
  activeTheme,
  selectedMenu,
  menuPrimary,
  menuSecundary,
  handleMenuSelection,
}) => {
  const shorCompare = (a, b) => {
    if (a && b && a.position && b.position) {
      if (a.position > b.position) return 1;
      if (b.position > a.position) return -1;
      return 0;
    }

    return 0;
  };

  return (
    <>
      <S.PrimaryContainer isSecondaryActive={isSecondaryActive}>
        {!activeTheme &&
          menuPrimary &&
          menuPrimary
            .filter(item => item !== undefined && item !== "")
            .sort(shorCompare)
            .map((menu, index) =>
              menu && menu.name ? (
                <S.ItemContainer
                  key={index}
                  isSelected={selectedMenu === menu.name}
                  onClick={() =>
                    handleMenuSelection({
                      hasSecondary: menu.hasSubMenu,
                      ...menu,
                    })
                  }
                >
                  {isSecondaryActive ? (
                    <Tooltip
                      component={<TooltipMenu title={menu.title} body="" />}
                      position="right-end"
                    >
                      {menu.icon ?? (
                        <Icon name="dashboard" size={30} color="#fff" />
                      )}
                    </Tooltip>
                  ) : (
                    menu.icon ?? (
                      <Icon name="dashboard" size={30} color="#fff" />
                    )
                  )}
                  <p>{menu.title}</p>
                </S.ItemContainer>
              ) : (
                ""
              ),
            )}
        {activeTheme &&
          menuSecundary
            .filter(item => item !== undefined && item !== "")
            .sort(shorCompare)
            .map((menu, index) =>
              menu && menu.name ? (
                <S.ItemContainer
                  key={index}
                  isSelected={selectedMenu === menu.name}
                  onClick={() =>
                    handleMenuSelection({
                      hasSecondary: menu.hasSubMenu,
                      ...menu,
                    })
                  }
                >
                  {isSecondaryActive ? (
                    <Tooltip
                      component={<TooltipMenu title={menu.title} body="" />}
                      position="right-end"
                    >
                      {menu.icon ?? (
                        <Icon name="dashboard" size={30} color="#fff" />
                      )}
                    </Tooltip>
                  ) : (
                    menu.icon ?? (
                      <Icon name="dashboard" size={30} color="#fff" />
                    )
                  )}
                  <p>{menu.title}</p>
                </S.ItemContainer>
              ) : (
                ""
              ),
            )}
      </S.PrimaryContainer>
    </>
  );
};
