import React, { createContext, useContext } from "react";

import { useSocket as useSocketHook, IoProvider } from "socket.io-react-hook";
import { useAuth } from "context/AuthContext";
import { getSessionToken, getSessionCompanies } from "utils/session";

const SocketContext = createContext({});

export const useAuthenticatedSocket = () => {
  const { token } = useAuth();
  return useSocketHook(`${process.env.REACT_APP_AUTHENTICATE_ENDOINT}`, {
    enabled: !!token,
    extraHeaders: {
      Authorization: getSessionToken(),
      "X-token-user": getSessionCompanies(),
    },
  });
};

export const useSocket = () => {
  const context = useContext(SocketContext);
  if (!context) throw Error("useSocket must be used within a SocketContext");
  return context;
};

export const SocketContentProvider = ({ children }) => {
  const { socket } = useAuthenticatedSocket();
  return (
    <SocketContext.Provider value={{ socket }}>
      {children}
    </SocketContext.Provider>
  );
};

export const SocketProvider = ({ children }) => {
  return (
    <IoProvider>
      <SocketContentProvider>{children}</SocketContentProvider>
    </IoProvider>
  );
};
