import { useRef } from "react";
import axios from "axios";
import { getSessionToken, getSessionCompanies } from "utils/session";

const BASE_URL =
  process.env.REACT_APP_CHECKLINK ||
  "https://checklink-backend.mixfiscal.com.br";

export const useCheckLinkAPI = () => {
  const apiRef = useRef(axios.create({ baseURL: BASE_URL }));

  apiRef.current.interceptors.request.use(config => {
    config.headers.Authorization = getSessionToken();
    config.headers["X-token-user"] = getSessionCompanies();
    return config;
  });

  apiRef.current.interceptors.response.use(
    response => response,
    error => {
      return Promise.reject(error);
    },
  );

  return apiRef.current;
};
