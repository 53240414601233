import React, { useEffect } from "react";
import { IoAdd } from "react-icons/io5";
import { useForm } from "react-hook-form";
import { Form, Select } from "components/form";
import { Tag } from "@mix/mixfiscal-designsystem";
import { ACTIVITIES_LIST } from "constants/types";
import { getListSelecteds, getListTagsValid } from "utils/context/grid-manager";
import { generateIdScenery, generateSceneries } from "utils/context/laws";
import { useRegisterLawsGradesContext } from "context/RegisterLawsContext";

import { useRegisterLaws } from "hooks/useRegisterLaws";
import Mocks from "pages/Laws/mocks";

import { Button, InputContainer, SceneryContainer } from "./LawScenery.styles";
import Validation from "./validations";

const lawsCeneRepository = new Mocks();

const origin = lawsCeneRepository.getOrigin().map(item => ({
  label: `${item.code} - ${item.description}`,
  value: item.code,
}));

const exitType = lawsCeneRepository.getExitType().map(item => ({
  label: `${item.code} - ${item.description}`,
  value: item.code,
}));
const uf = lawsCeneRepository.getUF().map(item => ({
  label: `${item.code} - ${item.description}`,
  value: item.code,
}));

const companyType = lawsCeneRepository.getCompanyType().map(item => ({
  label: `${item.code} - ${item.description}`,
  value: item.code,
}));

const ufsFederal = [{ value: "FEDERAL", label: "FEDERAL" }];

const defaultValue = {
  atividade: [],
  origem: [],
  saida: [],
  ufOrigem: null,
  ufDestino: null,
  tipoEmpresa: null,
  tipoEmpresaSaida: null,
};

export const LawScenery = ({ scenery: sceneryDefaultValues }) => {
  const { values, setValues, setComplexValue } = useRegisterLaws();
  const { removeSceneries } = useRegisterLawsGradesContext();

  const hasPisCofins = values.taxa?.pis || values.taxa?.cofins;

  const { handleSubmit, reset, control } = useForm({
    resolver: Validation,
    defaultValues: defaultValue,
  });

  useEffect(() => {
    reset(defaultValue);
  }, [values.taxa, reset]);

  const handleNewScenery = scenery => {
    const sceneries = generateSceneries(scenery)
      .filter(
        sceneryI =>
          !values?.cenarios?.find(
            cenario => cenario.internalId === generateIdScenery(sceneryI),
          ),
      )
      .map(sceneryY => ({
        ...sceneryY,
        saida: Array.isArray(sceneryY?.saida) ? null : sceneryY?.saida,
        origem: Array.isArray(sceneryY?.origem) ? null : sceneryY?.origem,
        internalId: generateIdScenery(sceneryY),
      }));

    setComplexValue("cenarios", [...(values?.cenarios || []), ...sceneries]);
    reset(defaultValue);
  };

  useEffect(() => {
    if (sceneryDefaultValues) {
      reset(sceneryDefaultValues);
      if (Object.keys(sceneryDefaultValues).length > 1) {
        reset({});
      }
    }
  }, [reset, sceneryDefaultValues]);

  async function deleteScenery(scenery) {
    const newScenery = values?.cenarios?.filter(
      s => generateIdScenery(scenery) !== generateIdScenery(s),
    );

    removeSceneries([scenery]);

    setValues({ ...values, cenarios: newScenery });
  }

  return (
    <>
      <h3 className="card-subtitle">Selecione o cenário</h3>
      <Form
        className="input-container"
        onSubmit={handleSubmit(handleNewScenery)}
      >
        <InputContainer>
          <Select
            isMulti
            closeMenuOnSelect={false}
            control={control}
            label="Origem"
            data={origin}
            name="origem"
            isDisabled={hasPisCofins}
          />
        </InputContainer>
        <InputContainer>
          <Select
            isMulti
            closeMenuOnSelect={false}
            control={control}
            label="Saída"
            data={exitType}
            name="saida"
            isDisabled={hasPisCofins}
          />
        </InputContainer>
        <InputContainer>
          <Select
            isMulti
            control={control}
            closeMenuOnSelect={false}
            label="UF Origem"
            data={hasPisCofins ? ufsFederal : uf}
            name="ufOrigem"
          />
        </InputContainer>
        <InputContainer>
          <Select
            isMulti
            closeMenuOnSelect={false}
            control={control}
            label="UF Destino"
            data={hasPisCofins ? ufsFederal : uf}
            name="ufDestino"
          />
        </InputContainer>
        <InputContainer>
          <Select
            isMulti
            closeMenuOnSelect={false}
            control={control}
            label="Tipo Empresa Origem"
            data={companyType}
            name="tipoEmpresa"
          />
        </InputContainer>
        <InputContainer>
          <Select
            isMulti
            control={control}
            closeMenuOnSelect={false}
            label="Tipo Empresa Destino"
            data={companyType}
            name="tipoEmpresaSaida"
          />
        </InputContainer>
        <InputContainer>
          <Select
            isMulti
            closeMenuOnSelect={false}
            control={control}
            label="Atividade"
            data={ACTIVITIES_LIST}
            name="atividade"
          />
        </InputContainer>
        <div>
          <Button type="submit">
            <IoAdd />
          </Button>
        </div>
      </Form>
      <SceneryContainer className="scenery-container">
        {values?.cenarios?.map((s, key) => {
          return (
            <div className="tax-tag" key={key}>
              <Tag
                type="advanced"
                label={[
                  getListSelecteds(
                    getListTagsValid([
                      s.origem,
                      s.saida,
                      s.ufOrigem,
                      s.ufDestino,
                      s.tipoEmpresa,
                      s.atividade,
                      s.tipoEmpresaSaida,
                    ]),
                  ),
                ]}
                handleDelete={() => deleteScenery(s)}
              />
            </div>
          );
        })}
      </SceneryContainer>
    </>
  );
};
