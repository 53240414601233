import React from "react";
import ReactDOM from "react-dom";
import { MdClose } from "react-icons/md";

import Button from "../Button";

import {
  Container,
  BackDrop,
  DialogContainer,
  TitleContainer,
  ContentContainer,
  ActionsContainer,
} from "./styles";

const Dialog = ({
  title,
  fullScreen = false,
  children,
  isOpen,
  handleClose,
  handleConfirm,
  handleCancel,
  zIndex = "100001",
  width = "auto",
  confirmText = "Confirmar",
  cancelText = "Cancelar",
}) => {
  if (!isOpen) return null;

  const Title = title;

  return ReactDOM.createPortal(
    <Container zIndex={zIndex}>
      <BackDrop onClick={() => handleClose()} />
      <DialogContainer width={width} fullScreen={fullScreen}>
        <TitleContainer>
          {typeof Title === "string" ? (
            <p>{title.toUpperCase() ?? ""}</p>
          ) : (
            Title
          )}
          <div
            className="buttonContainer"
            role="button"
            onClick={() => handleClose()}
          >
            <MdClose size={20} />
          </div>
        </TitleContainer>
        <ContentContainer hasHandleConfirm={!!handleConfirm}>
          {children}
        </ContentContainer>
        {handleConfirm && (
          <ActionsContainer>
            {handleCancel && (
              <div style={{ marginRight: 10 }}>
                <Button onClick={handleCancel}>{cancelText}</Button>
              </div>
            )}
            <Button onClick={handleConfirm}>{confirmText}</Button>
          </ActionsContainer>
        )}
      </DialogContainer>
    </Container>,
    document.getElementById("root"),
  );
};

export const DialogConfirm = ({
  isOpen,
  title = "Alerta",
  handleConfirm,
  handleCancel,
  handleClose,
  text = "",
}) => (
  <Dialog
    isOpen={isOpen}
    title={title}
    handleConfirm={handleConfirm}
    zIndex="100002"
    handleCancel={handleCancel}
    handleClose={handleCancel || handleClose}
  >
    <h3 style={{ textAlign: "center", marginTop: 10 }}>{text}</h3>
  </Dialog>
);

export default Dialog;
