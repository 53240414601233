import { useState, useCallback } from "react";
import { useCheckLinkAPI } from "../../../hooks";
import { getURLWithParams } from "../../../utils/context";
import { useToast } from "../../../context/ToastContext";

const useCheckLinks = () => {
  const api = useCheckLinkAPI();
  const { addToast } = useToast();
  const [checkLink, setCheckLink] = useState();
  const [checkLinks, setCheckLinks] = useState([]);
  const [checkLinksTotal, setCheckLinksTotal] = useState(0);

  const getOne = useCallback(
    async id => {
      try {
        const values = await api.get(`/checklinks/${id}`);
        setCheckLink(values.data);
      } catch (e) {
        console.log(e);
      }
    },
    [api],
  );

  const getAll = useCallback(
    async (limit = 10, page = 0) => {
      try {
        const values = await api.get(
          getURLWithParams("/checklinks", { limit, page }),
        );
        setCheckLinks(values.data.docs);
        setCheckLinksTotal(values.data.total);
      } catch (e) {
        console.log(e);
      }
    },
    [api],
  );

  const create = useCallback(
    async payload => {
      try {
        await api.post("/checklinks", payload);
        addToast({
          type: "success",
          description: "Checklink criado com sucesso",
        });
        return true;
      } catch (e) {
        addToast({
          type: "error",
          description: "Erro ao atualizar um checklink",
        });
        return false;
      }
    },
    [api, addToast],
  );

  const update = useCallback(
    async (id, payload) => {
      try {
        await api.patch(`/checklinks/${id}`, payload);
        addToast({
          type: "success",
          description: "Checklink atualizado com sucesso",
        });
        return true;
      } catch (e) {
        addToast({
          type: "error",
          description: "Erro ao atualizar um checklink",
        });
        return false;
      }
    },
    [api, addToast],
  );

  const detele = useCallback(
    async id => {
      try {
        await api.delete(`/checklinks/${id}`);
        getAll();
        addToast({
          type: "success",
          description: "Checklink excluído com sucesso",
        });
        return true;
      } catch (e) {
        addToast({
          type: "error",
          description: "Erro ao excluir um checklink",
        });
        return false;
      }
    },
    [api, getAll, addToast],
  );

  return {
    checkLink,
    checkLinks,
    checkLinksTotal,
    getOne,
    getAll,
    create,
    update,
    detele,
  };
};

export default useCheckLinks;
