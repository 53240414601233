import styled from "styled-components";

export const Container = styled.div`
  width: 934px;
  height: 535px;
  padding: -2px;

  .last-information {
    margin-left: 20px;
    margin-top: -5px;
    color: #596778;
    opacity: 1;
    text-align: left;
    font: normal normal normal 10px/12px Cairo;
    letter-spacing: 0px;

    span:nth-child(2) {
      margin-left: 450px;
      cursor: pointer;
    }
  }
`;

export const Header = styled.div`
  width: 962px;
  display: flex;
  flex-direction: row;
  .search-box {
    margin-left: 10px;

    input {
      font-size: 16px;
      background-color: #f2f4f4;
      width: 260px;
    }

    svg {
      display: none;
    }

    div {
      margin-top: 0px;

      c {
        z-index: 9999;
        overflow: hidden;
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;

        ::-webkit-scrollbar {
          display: none;
        }
      }

      p {
        margin-left: 19px;
        width: 250px;
      }
      p:nth-child(1) {
        margin-top: -15px;
      }
      p:nth-child(odd) {
        background-color: #ffffff;
      }
      p:nth-child(even) {
        background-color: #ffffff;
      }
    }
  }

  .column-one {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 60%;
    margin-left: 8px;
    p {
      margin-left: 12px;
      font-size: 12px;
      font-family: Cairo;
      color: #596778;
      width: auto;
    }
    .select-providers {
      margin-left: 8px;
      margin-right: 10px;
      width: 270px;
      max-width: 270px;
      font-size: 12px;

      input {
        font-size: 12px;
        font-family: Cairo;
      }
    }
  }
  .column-two {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-left: -16px;
  }
`;

export const BodyContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-left: 2px;
`;

export const ColumnOneContainer = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
`;

export const ColumnTwoContainer = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`;

export const SearchBox = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`;
