/* eslint-disable react/jsx-one-expression-per-line */
import { Tag, Card } from "@mix/mixfiscal-designsystem";
import React, { useState } from "react";
import { IoAdd } from "react-icons/io5";
import { Checkbox as CustomCheckbox } from "components/form";

import * as S from "./CardLegislation.styles";

export const CardLegislationContainer = ({
  normsValue = [],
  types,
  register,
}) => {
  const [indeterminateValidity, setIndeterminateValidity] = useState(false);
  const [normLabel, setNormLabel] = useState("Selecione");

  const tipoNorma = register("tipoNorma");
  const validadeIndeterminada = register("validadeIndeterminada");

  const handleNormsChange = item => {
    // reset({});
    setNormLabel(item);
  };

  return (
    <>
      <S.RowLegislation>
        <div>
          <CustomCheckbox
            ref={register}
            name="types.pis"
            disabled={types.icms}
            value="1"
            label="Pis"
          />

          <CustomCheckbox
            ref={register}
            name="types.cofins"
            value="1"
            disabled={types.icms}
            label="Cofins"
          />

          <CustomCheckbox
            ref={register}
            disabled={types.cofins || types.pis}
            name="types.icms"
            value="1"
            label="ICMS"
          />
        </div>
      </S.RowLegislation>
      <div className="input-container max-width-400">
        <S.InputContainer>
          <label>Normas</label>
          <select
            placeholder="Selecione a norma"
            value={normLabel}
            onChange={e => {
              tipoNorma.onChange(e);
              handleNormsChange(e.target.value);
            }}
            ref={tipoNorma.ref}
          >
            <option value="Selecione">Selecione</option>
            {normsValue.map(norm => (
              <option key={norm.label} value={norm.label}>
                {norm.label}
              </option>
            ))}
          </select>
        </S.InputContainer>
        {normLabel !== "Selecione" && (
          <div className="tag-margin">
            <Tag type="simple" label={normLabel} />
          </div>
        )}
      </div>
      {normLabel !== "Selecione" && (
        <div className="input-container">
          <S.InputContainer flex={0.6}>
            <label>{normLabel}</label>
            <input type="text" {...register("descricao")} />
          </S.InputContainer>
          <S.InputContainer flex={0.6}>
            <label>Data Publicação</label>
            <input type="date" {...register("dataPublicacao")} />
          </S.InputContainer>
          <S.InputContainer flex={0.6}>
            <label>Vigência</label>
            <input type="date" {...register("vigencia")} />
          </S.InputContainer>
          <S.InputContainer flex={0.6}>
            <label>Validade</label>
            <input
              type="date"
              {...register("validade")}
              disabled={indeterminateValidity}
            />
          </S.InputContainer>
          <div className="checkbox-container">
            <input
              type="checkbox"
              checked={indeterminateValidity}
              onChange={e => {
                setIndeterminateValidity(Boolean(e.currentTarget.checked));
                validadeIndeterminada.onChange(e);
              }}
              ref={validadeIndeterminada.ref}
            />
            <label>Validade indeterminada</label>
          </div>
        </div>
      )}
      {[
        "Lei",
        "Lei Complementar",
        "Decreto",
        "Convenção",
        "Resolução",
        "Instrucao Normativa",
      ].includes(normLabel ?? "") && <hr />}

      <div className="input-container">
        {[
          "Lei",
          "Lei Complementar",
          "Decreto",
          "Convenção",
          "Resolução",
          "Instrucao Normativa",
        ].includes(normLabel ?? "") && (
          <S.InputContainer maxWidth="195px">
            <label>Seção</label>
            <input type="text" {...register("secao")} />
          </S.InputContainer>
        )}
        {[
          "Lei",
          "Lei Complementar",
          "Decreto",
          "Convenção",
          "Emenda",
          "Resolução",
          "Instrucao Normativa",
          "Portaria",
          "Portaria Conjunta",
          "Portaria Normativa",
        ].includes(normLabel ?? "") && (
          <S.InputContainer maxWidth="195px">
            <label>Artigo</label>
            <input type="text" {...register("artigo")} />
          </S.InputContainer>
        )}
        {[
          "Lei",
          "Lei Complementar",
          "Decreto",
          "Convenção",
          "Emenda",
          "Resolução",
          "Instrucao Normativa",
          "Portaria",
          "Portaria Conjunta",
          "Portaria Normativa",
        ].includes(normLabel ?? "") && (
          <S.InputContainer maxWidth="195px">
            <label>Incíso</label>
            <input type="text" {...register("inciso")} />
          </S.InputContainer>
        )}
        {[
          "Lei",
          "Lei Complementar",
          "Decreto",
          "Convenção",
          "Emenda",
          "Resolução",
          "Instrucao Normativa",
          "Portaria",
          "Portaria Conjunta",
          "Portaria Normativa",
        ].includes(normLabel ?? "") && (
          <S.InputContainer maxWidth="195px">
            <label>Alínea</label>
            <input type="text" {...register("alinea")} />
          </S.InputContainer>
        )}
        {[
          "Lei",
          "Lei Complementar",
          "Decreto",
          "Convenção",
          "Emenda",
          "Resolução",
          "Instrucao Normativa",
          "Portaria",
          "Portaria Conjunta",
          "Portaria Normativa",
        ].includes(normLabel ?? "") && (
          <S.InputContainer maxWidth="195px">
            <label>Parágrafo</label>
            <input type="text" {...register("paragrafo")} />
          </S.InputContainer>
        )}
        {[
          "Lei",
          "Lei Complementar",
          "Decreto",
          "Convenção",
          "Resolução",
          "Instrucao Normativa",
        ].includes(normLabel ?? "") && (
          <S.InputContainer maxWidth="195px">
            <label>Título</label>
            <input type="text" {...register("titulo")} />
          </S.InputContainer>
        )}
        {[
          "Lei",
          "Lei Complementar",
          "Decreto",
          "Convenção",
          "Emenda",
          "Resolução",
          "Instrucao Normativa",
          "Portaria",
          "Portaria Conjunta",
          "Portaria Normativa",
        ].includes(normLabel ?? "") && (
          <S.InputContainer maxWidth="195px">
            <label>Caput</label>
            <input type="text" {...register("caput")} />
          </S.InputContainer>
        )}
      </div>
      <div className="input-container">
        {[
          "Lei",
          "Lei Complementar",
          "Decreto",
          "Convenção",
          "Resolução",
          "Instrucao Normativa",
        ].includes(normLabel ?? "") && (
          <S.InputContainer maxWidth="195px">
            <label>Capítulo</label>
            <input type="text" {...register("capitulo")} />
          </S.InputContainer>
        )}
        {[
          "Lei",
          "Lei Complementar",
          "Decreto",
          "Convenção",
          "Resolução",
          "Instrucao Normativa",
          "Portaria Conjunta",
        ].includes(normLabel ?? "") && (
          <S.InputContainer maxWidth="195px">
            <label>Anexo</label>
            <input type="text" {...register("anexo")} />
          </S.InputContainer>
        )}
        {[
          "Lei",
          "Lei Complementar",
          "Decreto",
          "Convenção",
          "Resolução",
          "Instrucao Normativa",
        ].includes(normLabel ?? "") && (
          <S.InputContainer maxWidth="195px">
            <label>Parte</label>
            <input type="text" {...register("parte")} />
          </S.InputContainer>
        )}
        {[
          "Lei",
          "Lei Complementar",
          "Decreto",
          "Convenção",
          "Resolução",
          "Instrucao Normativa",
        ].includes(normLabel ?? "") && (
          <S.InputContainer maxWidth="195px">
            <label>Subseção</label>
            <input type="text" {...register("subsecao")} />
          </S.InputContainer>
        )}
        {[
          "Lei",
          "Lei Complementar",
          "Decreto",
          "Convenção",
          "Resolução",
          "Instrucao Normativa",
        ].includes(normLabel ?? "") && (
          <S.InputContainer maxWidth="195px">
            <label>Livro</label>
            <input type="text" {...register("livro")} />
          </S.InputContainer>
        )}
        {[
          "Lei",
          "Lei Complementar",
          "Decreto",
          "Convenção",
          "Resolução",
          "Instrucao Normativa",
        ].includes(normLabel ?? "") && (
          <S.InputContainer maxWidth="195px">
            <label>Item</label>
            <input type="text" {...register("item")} />
          </S.InputContainer>
        )}

        {["Parecer"].includes(normLabel ?? "") && (
          <S.InputContainer maxWidth="195px">
            <label>Preâmbulo</label>
            <input type="text" {...register("preambulo")} />
          </S.InputContainer>
        )}

        {["Parecer"].includes(normLabel ?? "") && (
          <S.InputContainer maxWidth="195px">
            <label>Ementa</label>
            <input type="text" {...register("ementa")} />
          </S.InputContainer>
        )}

        {["Solução de consulta"].includes(normLabel ?? "") && (
          <S.InputContainer maxWidth="195px">
            <label>Assunto</label>
            <input type="text" {...register("assunto")} />
          </S.InputContainer>
        )}

        {["Solução de consulta"].includes(normLabel ?? "") && (
          <S.InputContainer maxWidth="195px">
            <label>Dispositivos Legais</label>
            <input type="text" {...register("dispositivosLegais")} />
          </S.InputContainer>
        )}

        {["Parecer", "Solução de consulta"].includes(normLabel ?? "") && (
          <S.InputContainer maxWidth="195px">
            <label>Relatório</label>
            <input type="text" {...register("relatorio")} />
          </S.InputContainer>
        )}

        {["Solução de consulta"].includes(normLabel ?? "") && (
          <S.InputContainer maxWidth="195px">
            <label>Fundamento</label>
            <input type="text" {...register("fundamento")} />
          </S.InputContainer>
        )}

        {["Parecer"].includes(normLabel ?? "") && (
          <S.InputContainer maxWidth="195px">
            <label>Fundamentação</label>
            <input type="text" {...register("fundamentacao")} />
          </S.InputContainer>
        )}

        {["Parecer"].includes(normLabel ?? "") && (
          <S.InputContainer maxWidth="195px">
            <label>Dispositivo</label>
            <input type="text" {...register("dispositivo")} />
          </S.InputContainer>
        )}

        {["Solução de consulta"].includes(normLabel ?? "") && (
          <S.InputContainer maxWidth="195px">
            <label>Conclusão</label>
            <input type="text" {...register("conclusao")} />
          </S.InputContainer>
        )}

        {["Resposta à consulta"].includes(normLabel ?? "") && (
          <S.InputContainer maxWidth="195px">
            <label>Relato</label>
            <input type="text" {...register("relato")} />
          </S.InputContainer>
        )}
      </div>

      {normLabel !== "Selecione" && (
        <>
          <div className="input-container">
            <S.InputContainer maxWidth="none">
              <label>URL</label>
              <input type="text" {...register("url")} />
            </S.InputContainer>
          </div>

          <div className="input-container">
            <S.InputContainer maxWidth="none">
              <label>Monitoramento</label>
              <textarea {...register("monitoramento")} />
            </S.InputContainer>
            <div>
              <S.Button type="submit">
                <IoAdd />
              </S.Button>
            </div>
          </div>
        </>
      )}

      {/* {values?.normas?.map(norm => (
        <CardWithLateralHeader key={norm.descricao}>
          <header>
            {String(norm.tipoNorma).length > 10
              ? `${String(norm.tipoNorma).substring(0, 10)}...`
              : norm.tipoNorma}{" "}
            {norm.descricao}
            <button type="button" onClick={() => deleteNorm(norm)}>
              <IoClose />
            </button>
          </header>
          <main>
            {norm.tipoNorma} n° {norm.descricao} de{" "}
            {norm.dataPublicacao
              ? norm.dataPublicacao.split("T")[0].split("-").reverse().join("/")
              : "-"}
            {norm.secao ? `, seção ${norm.secao}` : ""}
            {norm.artigo ? ` , art. ${norm.artigo}` : ""}
            {norm.inciso ? `, inciso ${norm.inciso}` : ""}
            {norm.alinea ? `, alínea ${norm.alinea}` : ""}
            {norm.paragrafo ? `, § ${norm.paragrafo}` : ""}.
            <br />
            {norm.titulo ? `Titulo: ${norm.titulo}` : ""}
            {norm.caput ? ` , caput ${norm.caput}` : ""}
            {norm.capitulo ? ` , capitulo ${norm.capitulo}` : ""}
            {norm.anexo ? ` , anexo ${norm.anexo}` : ""}
            {norm.parte ? ` , parte ${norm.parte}` : ""}
            {norm.subsecao ? ` , subseção ${norm.subsecao}` : ""}
            {norm.livro ? ` , livro ${norm.livro}` : ""}
            {norm.item ? ` , item ${norm.item}` : ""}. <br />
            . <br />
            {norm.vigencia
              ? `Data da Vigência: ${norm.vigencia
                  .split("T")[0]
                  .split("-")
                  .reverse()
                  .join("/")}`
              : "---"}
            .
          </main>
        </CardWithLateralHeader>
      ))} */}
    </>
  );
};

const headerColorGray = "#e5e5e5";
const textColorGray = "#304156";

export const CardLegislation = props => (
  <Card
    className="card-custom"
    handleCtaClick={() => {}}
    hasElevation
    headerColor={headerColorGray}
    textColor={textColorGray}
    title="LEGISLAÇÃO"
  >
    <CardLegislationContainer {...props} />
  </Card>
);
