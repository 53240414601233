import React from "react";
import { Datatable, Icon } from "@mix/mixfiscal-designsystem";

import { useStoreContext } from "context/StoreContext";

/**
 * Lista de Regimes Tributários
 * @param {Object} data
 * @returns tabela de dados editáveis
 */
export const ListSpecialTaxRegimes = ({ data }) => {
  const { removeSpecialTaxRegime } = useStoreContext();

  const removeButton = specialTaxRegime => {
    const onclick = () => removeSpecialTaxRegime(specialTaxRegime.id);

    return (
      <div role="button" onClick={onclick}>
        <Icon name="fechar2" color="#000" className="close-icon" size={16} />
      </div>
    );
  };

  return (
    <Datatable
      pagination={false}
      columns={[
        { field: "regime", title: "Regime Especial" },
        { field: "action", title: "Ação" },
      ]}
      rawData={Object.values(data).map(selectedSpecialTaxRegime => ({
        action: removeButton(selectedSpecialTaxRegime),
        regime: selectedSpecialTaxRegime.label,
      }))}
    />
  );
};
