import axios from "axios";
import { getSessionCompanies, getSessionToken } from "utils/session";

const authenticationApi = axios.create({
  baseURL:
    process.env.REACT_APP_AUTHENTICATE ||
    "https://authentication-builder.mixfiscal.com.br/v1",
});

authenticationApi.interceptors.request.use(
  config => {
    config.headers.Authorization = getSessionToken();
    config.headers["X-token-user"] = getSessionCompanies();
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

export default authenticationApi;
