import styled from "styled-components";

export const Select = styled.select`
  width: 100%;
`;

export const SelecWrapper = styled.div`
  display: flex;
`;

export const SelectedItem = styled.div`
  width: ${props => (props.width ? props.width : `95%`)};
`;
export const SelectedItemButton = styled.div`
  margin-left: 5px;
  width: 5%;
  text-align: center;
  button {
    height: 44px;
  }
`;
