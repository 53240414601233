import React from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faBellSlash,
  faCalendarDays,
  faClose,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";

import colors from "styles/tokens/colors";
import * as SLocal from "./styles";

export const Alert = ({ title, msg, type = `error` }) => {
  return (
    <SLocal.NotificationContentItemOpenAlert>
      <SLocal.NotificationContentItemDescriptionFullAlert>
        <FontAwesomeIcon
          spin={type === `spinner`}
          icon={type === `error` ? faBellSlash : faSpinner}
          style={{
            fontSize: "30px",
            opacity: `0.6`,
          }}
          color={colors.slateGray}
        />
        <h4>{title}</h4>
        <p>{msg}</p>
      </SLocal.NotificationContentItemDescriptionFullAlert>
    </SLocal.NotificationContentItemOpenAlert>
  );
};

export const ContentNotify = ({
  list,
  isLoading,
  isOpenDescription,
  setIsOpenDescription,
  setNotificationsListSelected,
}) => {
  return (
    <>
      {isLoading && (
        <Alert title="Aguarde!" msg="Carregando..." type="spinner" />
      )}
      {list ? (
        (list || []).map((item, index) => {
          return (
            <SLocal.NotificationContentItem key={index}>
              <SLocal.NotificationContentItemDescription
                onClick={() => {
                  setIsOpenDescription(!isOpenDescription);
                  setNotificationsListSelected(item);
                }}
              >
                <h3>{item.title}</h3>
                <SLocal.SpanDate>
                  {" "}
                  <FontAwesomeIcon
                    icon={faCalendarDays}
                    style={{
                      fontSize: "11px",
                    }}
                    color={colors.slateGray}
                  />
                  {moment(item.createdAt).format(`DD/MM/YYYY h:mm:ss a`)}
                </SLocal.SpanDate>
                <p>{item.message}</p>
              </SLocal.NotificationContentItemDescription>
              <SLocal.NotificationContentItemAction
                onClick={() => {
                  setIsOpenDescription(!isOpenDescription);
                  setNotificationsListSelected(item);
                }}
              >
                <FontAwesomeIcon
                  icon={faBell}
                  style={{
                    fontSize: "17px",
                  }}
                  color={
                    item.status === `new`
                      ? `rgb(235 45 45 / 68%)`
                      : colors.slateGray
                  }
                />
              </SLocal.NotificationContentItemAction>
            </SLocal.NotificationContentItem>
          );
        })
      ) : (
        <Alert title="Ops!" msg="Nenhuma notificação." type="error" />
      )}
    </>
  );
};

export const ContentNotifySelected = ({
  itemSelected,
  setIsOpenDescription,
  setNotificationsListSelected,
}) => {
  return (
    <>
      <SLocal.NotificationContentItemOpen>
        <SLocal.NotifyHeaderContentItemHeader>
          <h4>{itemSelected?.title}</h4>

          <SLocal.NotifyHeaderContentItemHeaderIcon
            onClick={() => {
              setIsOpenDescription(false);
              setNotificationsListSelected(null);
            }}
          >
            <FontAwesomeIcon
              icon={faClose}
              style={{
                fontSize: "17px",
              }}
              color={colors.slateGray}
            />
          </SLocal.NotifyHeaderContentItemHeaderIcon>
        </SLocal.NotifyHeaderContentItemHeader>
        <SLocal.NotificationContentItemDescriptionFull>
          <SLocal.SpanDate>
            <FontAwesomeIcon
              icon={faCalendarDays}
              style={{
                fontSize: "11px",
              }}
              color={colors.slateGray}
            />
            {moment(itemSelected.createdAt).format(`DD/MM/YYYY`)}
          </SLocal.SpanDate>
          <p>{itemSelected?.message}</p>
        </SLocal.NotificationContentItemDescriptionFull>
      </SLocal.NotificationContentItemOpen>
    </>
  );
};
