import styled from "styled-components";

export const Container = styled.div`
  background: #f2f4f4 0% 0% no-repeat padding-box;
  border-radius: 5px;
  box-shadow: ${({ theme }) => theme.shadows.default};
  max-height: 450px;
  margin-top: 10px;
  opacity: 1;
  position: absolute;
  right: 0;
  width: 623px;
  z-index: 10;
`;
