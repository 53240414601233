import React, { useCallback, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faBell,
  faEllipsisV,
  faGears,
} from "@fortawesome/free-solid-svg-icons";
import colors from "styles/tokens/colors";
import { useOnClickOutside } from "../../../../../hooks";
import * as SLocal from "./styles";

export const NotificationCard = ({
  isShowNotify,
  close,
  typeCard,
  setTypeCard,
  title,
  children,
  btnRef,
}) => {
  const ref = useRef();
  useOnClickOutside(
    ref,
    useCallback(
      el => {
        if (!btnRef.current.contains(el.target) && close) close();
      },
      [close, btnRef],
    ),
  );
  return (
    <>
      {isShowNotify && (
        <SLocal.NotificationContainer ref={ref} close={close}>
          <SLocal.NotificationHeader>
            <SLocal.NotifyHeaderTitle>
              <SLocal.NotifyHeaderIcon>
                <FontAwesomeIcon
                  icon={typeCard === `listAll` ? faBell : faGears}
                  style={{
                    fontSize: "17px",
                  }}
                  color={colors.slateGray}
                />
              </SLocal.NotifyHeaderIcon>
              <h4>{title}</h4>
            </SLocal.NotifyHeaderTitle>
            <SLocal.NotifyHeaderFunction
              onClick={() => {
                setTypeCard(typeCard === `listAll` ? `config` : `listAll`);
              }}
            >
              <FontAwesomeIcon
                icon={faEllipsisV}
                style={{
                  fontSize: "20px",
                  fillOpacity: "0.6",
                }}
                color={colors.slateGray}
              />
            </SLocal.NotifyHeaderFunction>
          </SLocal.NotificationHeader>
          <SLocal.NotificationContent>{children}</SLocal.NotificationContent>
        </SLocal.NotificationContainer>
      )}
    </>
  );
};
