/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState } from "react";
import { useRegisterLaws } from "hooks/useRegisterLaws";

import { LawNormsCard } from "components/contexts/law-grade/LawNormCard";
import { LawNorms } from "constants/Types/Law.types";

import { NormForm } from "./LawNormForm";

export const LawNorm = ({
  normsValue = LawNorms,
  isActive = false,
  hasNormName = true,
}) => {
  const [indeterminateValidity] = useState(false);

  const { values, setComplexValue, setValues } = useRegisterLaws();

  const handleNewNorm = norm => {
    setComplexValue("normas", {
      ...norm,
      validadeIndeterminada: indeterminateValidity,
    });
  };

  const deleteNorm = ({ norm }) => {
    const indexOf = values?.normas?.indexOf(norm);
    const newNorms = values?.normas?.filter((_, index) => index !== indexOf);

    setValues({ ...values, normas: newNorms });
  };

  const handleSave = ({ norm, index }) => {
    const newNorms = values?.normas?.map((n, i) => (i === index ? norm : n));

    return setValues({ ...values, normas: newNorms });
  };

  return (
    <>
      {(values.cenarios?.length > 0 || isActive) && (
        <>
          <h3 className="card-subtitle">
            Selecione a <strong>Norma</strong> e <strong>configure</strong> os
            campos abaixo
          </h3>
          <NormForm
            normsValue={normsValue}
            handleNewNorm={handleNewNorm}
            values={{}}
            hasNormName={hasNormName}
          />
        </>
      )}

      <LawNormsCard
        handleSave={handleSave}
        list={values?.normas}
        normsValue={normsValue}
        handleDelete={deleteNorm}
      />
    </>
  );
};
