import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { v4 as uuid } from "uuid";
import { MdAssessment, MdAdd } from "react-icons/md";
import { isNode } from "react-flow-renderer";
import LoadingIcon from "../../../UI/LoadingIcon";
import Dialog from "../../../UI/Dialog";

import { useFlow } from "../../../../context/FlowContext";
import {
  Container,
  Header,
  Content,
  Card,
  AddOutputContainer,
  DroppableContainer,
  DraggableContainer,
  DraggableItem,
} from "./styles";

function GeneralPreview({ handleClose }) {
  const [columns, setColumns] = useState(null);
  const [outputName, setOutputName] = useState("");

  const {
    elements,
    handleSaveGeneralPreviewDragColumns,
    getPreviewDataFromNode,
  } = useFlow();

  const history = useHistory();

  useEffect(() => {
    const getGeneralPreviewData = async () => {
      const { generalPreviewStructure } = await getPreviewDataFromNode(
        JSON.parse(JSON.stringify(elements.filter(el => isNode(el)))),
      );

      setColumns(generalPreviewStructure);
    };

    getGeneralPreviewData();
  }, [elements, getPreviewDataFromNode]);

  const handleDragEnd = result => {
    if (!result.destination) return;
    const { source, destination } = result;

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const sourceItems = JSON.parse(JSON.stringify(sourceColumn.items));
      const destColumn = columns[destination.droppableId];
      const destItems = JSON.parse(JSON.stringify(destColumn.items));

      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);

      setColumns(prevState => {
        return {
          ...prevState,
          [source.droppableId]: {
            ...sourceColumn,
            items: sourceItems,
          },
          [destination.droppableId]: {
            ...destColumn,
            items: destItems,
          },
        };
      });
    } else {
      const column = columns[source.droppableId];
      const items = JSON.parse(JSON.stringify(column.items));
      const [removed] = items.splice(source.index, 1);
      items.splice(destination.index, 0, removed);

      setColumns(prevState => {
        return {
          ...prevState,
          [source.droppableId]: {
            ...column,
            items,
          },
        };
      });
    }
  };

  const onClose = () => {
    handleSaveGeneralPreviewDragColumns(columns);
    handleClose();
  };

  const handleAddOutput = () => {
    if (!outputName) return;
    setColumns(prevState => {
      return {
        ...prevState,
        [uuid()]: {
          name: outputName,
          items: [],
        },
      };
    });
    setOutputName("");
  };

  const formatPayloadToReport = payload => {
    let biggestDataSet = 0;
    const dataSetMap = {};
    const reportHeaderColumns = [];
    const reportBodyRows = [];

    payload.forEach(row => {
      if (row.elementData.length > biggestDataSet)
        biggestDataSet = row.elementData.length;
      dataSetMap[row.id] = row.elementData;

      const headerColumn = {
        title: row.description ?? row.label,
        field: row.id,
      };
      reportHeaderColumns.push(headerColumn);
      return row;
    });

    for (let i = 0; i < biggestDataSet; i += 1) {
      const row = {};
      Object.keys(dataSetMap).forEach(key => {
        row[key] = dataSetMap[key][i];
      });
      reportBodyRows.push(row);
    }
    console.log({ dataSetMap });
    console.log({ reportHeaderColumns });
    console.log({ reportBodyRows });

    return {
      columns: reportHeaderColumns,
      rows: reportBodyRows,
    };
  };

  const handleCreateReport = async () => {
    console.log({ columns });
    const reports = [];
    Object.keys(columns).forEach(key => {
      const structuredPayload = formatPayloadToReport(columns[key].items);
      reports.push({ name: columns[key].name, payload: structuredPayload });
    });

    console.log({ reports });

    history.push({
      pathname: "/relatorio",
      state: {
        reports,
      },
    });
  };

  const HeaderComponent = () => (
    <Header>
      <div>
        <MdAssessment size={24} color="#FFF" />
        <p>Resumo Geral</p>
      </div>
    </Header>
  );

  console.log(columns);

  return (
    <Dialog
      width="90%"
      title={<HeaderComponent />}
      isOpen
      handleClose={onClose}
    >
      <Container>
        <Content>
          <AddOutputContainer>
            <button
              type="button"
              style={{ padding: "4px 8px", borderRadius: "5px" }}
              onClick={handleCreateReport}
            >
              Ir para Relatório
            </button>
          </AddOutputContainer>
          <AddOutputContainer>
            <input
              type="text"
              value={outputName}
              placeholder="* Digite um nome para uma nova saída"
              onChange={e => setOutputName(e.target.value)}
            />
            <div role="button" className="icon" onClick={handleAddOutput}>
              <MdAdd type="button" size={24} />
            </div>
          </AddOutputContainer>
          {columns ? (
            <DragDropContext onDragEnd={result => handleDragEnd(result)}>
              <DroppableContainer>
                {columns &&
                  Object.keys(columns).map(column => (
                    <React.Fragment key={column}>
                      <h2>{columns[column].name}</h2>
                      <Droppable
                        droppableId={column}
                        direction="horizontal"
                        ignoreContainerClipping
                      >
                        {(provided, snapshot) => (
                          <DraggableContainer
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            snapshot={snapshot}
                          >
                            {columns[column].items.map((item, index) => (
                              <Draggable
                                key={item.id}
                                draggableId={item.id}
                                index={index}
                              >
                                {(providedItem, snapshotItem) => (
                                  <DraggableItem
                                    ref={providedItem.innerRef}
                                    {...providedItem.draggableProps}
                                    {...providedItem.dragHandleProps}
                                    {...providedItem.draggableProps.style}
                                  >
                                    <Card
                                      snapshot={snapshotItem}
                                      isOperations={
                                        item.label === "Operações Avançadas" ||
                                        item.label === "Operações"
                                      }
                                    >
                                      <div className="header">
                                        <h1>{item.label}</h1>
                                        <h2>{item.description ?? "-"}</h2>
                                      </div>
                                      <div className="list">
                                        {item?.elementData
                                          .slice(0, 5)
                                          .map(row => {
                                            // // prevent to render selected index to be columns
                                            // if (row === item.description) {
                                            //   return null;
                                            // }
                                            return <p>{row || "-"}</p>;
                                          })}
                                        {/* {previewData &&
                                    previewData[
                                      item.elementIndex
                                    ].body[0].data.map((row, rowIndex) => {
                                      // prevent to render selected index to be columns
                                      if (
                                        rowIndex ===
                                        item.elementData.data
                                          .selectedFileColumnsIndex
                                      )
                                        return null;
                                      return row.map((data, dataIndex) => {
                                        // renders only the selected fields
                                        if (item.fieldIndex === dataIndex) {
                                          return <p>{data || "-"}</p>;
                                        }
                                        return null;
                                      });
                                    })} */}
                                      </div>
                                    </Card>
                                  </DraggableItem>
                                )}
                              </Draggable>
                            ))}
                          </DraggableContainer>
                        )}
                      </Droppable>
                    </React.Fragment>
                  ))}
              </DroppableContainer>
            </DragDropContext>
          ) : (
            <div style={{ marginTop: "200px" }}>
              <LoadingIcon isLoading={!columns} color="#0e7c34" size={56} />
            </div>
          )}
        </Content>
      </Container>
    </Dialog>
  );
}

export default GeneralPreview;
