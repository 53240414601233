import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faNetworkWired } from "@fortawesome/free-solid-svg-icons";
import { Datatable, Pagination, Tooltip } from "@mix/mixfiscal-designsystem";
import { useClientAssociation } from "useCases/useClientAssociation";
import { CardHeader } from "components/contexts/users-and-permissions";
import { useACL } from "context";

const ActionRender = ({ hasEdit, onClick }) => (
  <>
    {!hasEdit ? (
      ``
    ) : (
      <Tooltip
        body="Associar"
        position="right-start"
        zIndex={2}
        zIndexBackdrop={1}
      >
        <FontAwesomeIcon
          icon={faNetworkWired}
          style={{
            cursor: "pointer",
            margin: "0 0 0 10px",
          }}
          onClick={onClick}
        />
      </Tooltip>
    )}
  </>
);

export const List = ({ params, ...props }) => {
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(100);
  const { data, isLoading } = useClientAssociation({
    page,
    limit,
    ...params,
  });

  useEffect(() => {
    if (data?.data?.totalPages) {
      setTotalItems(data?.data?.totalDocs);
      setTotalPages(data?.data?.totalPages);
    }
  }, [data]);

  return (
    <ListContent
      isLoading={isLoading}
      params={params}
      limit={limit}
      page={page}
      totalItems={totalItems}
      setLimit={setLimit}
      totalPages={totalPages}
      data={data?.data?.docs}
      setPage={setPage}
      {...props}
    />
  );
};

export const ListContent = ({
  totalItems,
  setPage,
  limit,
  setLimit,
  page,
  data = [],
  totalPages,
  isLoading = false,
  onHandleDetail = () => {},
}) => {
  const { hasEdit } = useACL("associacao");

  const columns = [
    {
      field: "action",
      title: "",
    },
    {
      field: "nome",
      title: "Cliente",
    },
    {
      field: "cnpj",
      title: "CNPJ",
    },
    {
      field: "total",
      title: "Total",
    },
    {
      field: "associados",
      title: "Total Associados",
    },
    {
      field: "nao_associados",
      title: "Total Nao Associados",
    },
  ];

  return (
    <>
      <CardHeader
        title="Associar produtos de clientes"
        totalItems={totalItems}
        limitItems={limit}
      />
      <Datatable
        handleChangePage={setPage}
        pagination={false}
        columns={columns}
        isLoading={isLoading}
        rawData={data.map(item => {
          return {
            ...item,
            action: (
              <ActionRender
                hasEdit={hasEdit}
                item={item}
                onClick={() => onHandleDetail(item)}
              />
            ),
            nome: item?.id_cli?.companyName ? item?.id_cli?.companyName : "",
            cnpj: item?.id_cli?.cnpj,
            total: item?.total,
            associados: item?.associados,
            nao_associados: item?.nao_associados,
          };
        })}
      />

      <Pagination
        handlePageChange={value => setPage(value)}
        initialPage={page}
        siblingsCount={1}
        totalPages={totalPages}
        amountVariations={[50, 10, 150, 300]}
        handleChangeAmount={setLimit}
      />
    </>
  );
};
