import React, { useState } from "react";
import { Select } from "components/form";
import { Tooltip } from "@mix/mixfiscal-designsystem";
import { Button } from "components/structure";
import { FiPlus } from "react-icons/fi";
import { useLawsRegimes } from "useCases";
import * as S from "./styles";

/**
 * Parse para normalização de opções do select
 * @param {Object} taxRegimes objeto de regimes tributários
 * @returns regimes especiais normalizados
 */
const parseSelectOptions = taxRegimes => {
  if (taxRegimes) {
    const specialTaxRegimes = taxRegimes.docs;
    return Object.values(specialTaxRegimes || {}).map(option => ({
      ...option,
      label: option.name,
    }));
  }
  return [];
};

/**
 * Select múltiplo de regimes tributários especiais
 * @param {function} handleAdd handler para adição
 * @returns react select wrapper
 */
export const SelectSpecialTaxRegime = ({ handleAdd }) => {
  const { data: lawsRegimes } = useLawsRegimes();
  const [selectedSpecialTaxRegime, setSelectedSpecialTaxRegime] = useState();

  const handleClick = () => handleAdd(selectedSpecialTaxRegime);

  return (
    <S.SelectWrapper>
      <S.ContainerSelected>
        <Select
          labelType="top"
          options={parseSelectOptions(lawsRegimes || [])}
          placeholder="Selecione"
          styles={S.customStyles}
          onChange={setSelectedSpecialTaxRegime}
          value={selectedSpecialTaxRegime}
        />
      </S.ContainerSelected>

      <S.ContainerButton>
        <Button type="button" onClick={handleClick}>
          <Tooltip title="Adicionar Regime Especial" position="top">
            <FiPlus size={22} color="white" className="icon" />
          </Tooltip>
        </Button>
      </S.ContainerButton>
    </S.SelectWrapper>
  );
};
