import React, { createContext, useState, useContext } from "react";
import { defaultValues } from "components/contexts/grid-manager/Filters/validations";

export const NcmsCollectionContext = createContext({
  setUpdate: () => {},
  setFilterFields: () => {},
  filterFields: defaultValues,

  ncmsIds: [],
  status: [],
});

export const useNcmsCollectionContext = () => {
  const context = useContext(NcmsCollectionContext);

  if (!context) {
    if (!context)
      throw new Error(
        "useGridManager precisa ser utilizado dentro de um GridManagerProvider",
      );
    return context;
  }
};

export const NcmsCollectionsProvider = ({ children }) => {
  const [ncmsCollection, setNcmsCollection] = useState({});
  const [filterFields, setFilterStateFields] = useState(defaultValues);

  const setUpdate = (options = {}) => {
    setNcmsCollection(prevState => ({ ...prevState, ...options }));
  };

  const setFilterFields = (options = {}) => {
    setFilterStateFields({ ...filterFields, ...options });
  };

  const removeField = name => {
    setFilterStateFields(state => {
      const fields = state;
      delete fields[name];

      return fields;
    });
  };

  return (
    <NcmsCollectionContext.Provider
      value={{
        ncmsCollection,
        filterFields,
        setUpdate,
        setFilterFields,
        removeField,
        setNcmsCollection,
      }}
    >
      {children}
    </NcmsCollectionContext.Provider>
  );
};
