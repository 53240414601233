import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  body {
    background-color: #eee;
    -webkit-font-smoothing: antialiased;
    width: 100%;
    height: 100vh;
    overflow: hiden;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  body, input, button, textarea {
    font: 16px 'Cairo', sans-serif;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 500;
  }

  button {
    cursor: pointer;
  }

  button, input {
    border: none;
    outline: none;
  }

  a {
    text-decoration: none;
  }

  #root {
    width: 100%;
    height: 100%;
  }
`;
