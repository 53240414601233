/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import { MdRemoveCircleOutline } from "react-icons/md";
import { NORMS } from "constants/routes";

import {
  ButtonEditing,
  Card,
  Button,
  ButtonContainerIcon,
  ButtonOpenNewTag,
  Container,
} from "components/structure";

import { LawNormFormat } from "components/contexts/law-grade/LawNormFormat";
// eslint-disable-next-line import/no-cycle
import { NormForm } from "components/contexts/law-grade/LawNorm/LawNormForm";

import * as S from "./LawNormCard.styles";

export const LawNormsCard = ({
  list = [],
  normsValue = [],
  handleDelete = () => {},
  handleSave = () => {},
  disableActions = false,
}) => {
  return list.map((norm, index) => {
    return norm?.id_norm?._id || norm?.id_norm?.value ? (
      <LawNormGerenciador
        key={`norm-${norm.id_norm._id}`}
        norm={norm}
        handleDelete={() => handleDelete({ norm })}
        disableActions={disableActions}
      />
    ) : (
      <LawNormCard
        norm={norm}
        handleSave={normFields => handleSave({ norm: normFields, index })}
        handleDelete={() => handleDelete({ norm })}
        key={norm.descricao}
        normsValue={normsValue}
        disableActions={disableActions}
      />
    );
  });
};

export const LawNormCard = ({
  norm = {},
  normsValue = [],
  handleDelete,
  handleSave,
  disableActions = false,
}) => {
  const [editing, setEdit] = useState(false);

  const handleChange = normFields => {
    setEdit(false);
    handleSave(normFields);
  };

  const handleCancel = () => setEdit(false);

  return (
    <S.CardWithLateralHeader key={norm.descricao}>
      <header>
        {String(norm.tipoNorma).length > 10
          ? `${String(norm.tipoNorma).substring(0, 10)}...`
          : norm.tipoNorma}{" "}
        {norm.descricao}
        {!disableActions && (
          <button type="button" onClick={handleDelete}>
            <IoClose />
          </button>
        )}
      </header>
      <main>
        {editing ? (
          <NormForm
            isEditing
            normsValue={normsValue}
            values={norm}
            handleNewNorm={handleChange}
            handleCancel={handleCancel}
          />
        ) : (
          <S.CardContext>
            <LawNormFormat norm={norm} />
            {!disableActions && (
              <S.CardContextActions>
                <ButtonEditing onClick={() => setEdit(true)} />
              </S.CardContextActions>
            )}
          </S.CardContext>
        )}
      </main>
    </S.CardWithLateralHeader>
  );
};

const LawNormGerenciadorActions = ({ handleDelete }) => {
  return (
    <Button type="button" color="secondary" size="x2" onClick={handleDelete}>
      <ButtonContainerIcon>
        <MdRemoveCircleOutline />
      </ButtonContainerIcon>
      remover
    </Button>
  );
};

export const LawNormGerenciador = ({
  norm,
  handleDelete,
  disableActions = false,
}) => (
  <S.CardContainer>
    <Card
      type="local"
      title={`Norma - ${norm?.id_norm?.norm?.nome}`}
      headerColor="#E5E5E5"
      action={
        <Container isFlex gridGap>
          {!disableActions && (
            <LawNormGerenciadorActions handleDelete={handleDelete} />
          )}
          <ButtonOpenNewTag
            openNewAba
            toLink={NORMS.DETAIL(norm.id_norm?.value)}
            title="Abrir detalhe da Norma no Gerenciador de Normas"
          />
        </Container>
      }
    >
      <LawNormsCard disableActions list={norm?.id_norm?.norm.normas} />
    </Card>
  </S.CardContainer>
);
