import React from "react";
import { Skeleton } from "@material-ui/lab";

import * as S from "./styles";

export const SkeletonItem = ({ active }) => {
  const skeletonsArr = [1, 2, 3, 4, 5, 7, 8, 9];
  return (
    <S.SkeletonContainer>
      {skeletonsArr.map(item => {
        return (
          <S.SkeletonRow key={item}>
            <Skeleton
              animation="wave"
              style={{
                borderRadius: "100%",
                marginLeft: active ? "52px" : "15px",
                marginTop: "0",
              }}
              width={40}
              height={65}
            />
            <Skeleton
              animation="wave"
              variant="text"
              style={{ marginLeft: active ? "20px" : "15px", marginTop: "0" }}
              width={active ? 100 : 40}
              height={20}
            />
          </S.SkeletonRow>
        );
      })}
    </S.SkeletonContainer>
  );
};
