import React from "react";

import { useProductPostOrPut } from "useCases";

import { useACL } from "context";

import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearchPlus,
  faSpinner,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

import * as S from "./Columns.styles";

const ButtonClick = ({ id }) => {
  const history = useHistory();

  const { onRemove, isLoading } = useProductPostOrPut({ id });

  const { hasEdit, hasRemove } = useACL("product-production");

  return (
    <>
      {hasEdit && (
        <S.Button
          color="#39672e"
          onClick={() => {
            history.push(`/product/detail/${id}`);
          }}
        >
          <FontAwesomeIcon
            icon={faSearchPlus}
            style={{
              fontSize: "14px",
            }}
          />
        </S.Button>
      )}

      {hasRemove && (
        <S.Button
          color="#ed0f0f"
          onClick={() => {
            onRemove();
          }}
        >
          <FontAwesomeIcon
            spin={isLoading}
            icon={!isLoading ? faTrash : faSpinner}
            style={{
              fontSize: "14px",
            }}
          />
        </S.Button>
      )}
    </>
  );
};

export const COLLUNS = [
  {
    field: "----",
    headerText: " ",
    width: 100,
    sortable: false,
    renderCell: params => {
      return <ButtonClick id={params?.row?.id} />;
    },
  },
  {
    field: "id",
    headerText: " ",
    width: 100,
    sortable: true / false,
  },
  {
    field: "companyId",
    headerText: "Loja",
    width: 100,
    sortable: true / false,
  },
  {
    field: "prod_nome",
    headerText: "Nome do Produto (Descrição)",
    width: 350,
    sortable: true / false,
  },
  {
    field: "prod_codigo_barras",
    headerText: "EAN",
    width: 170,
    sortable: true / false,
  },
  {
    field: "ncm",
    headerText: "NCM",
    width: 150,
    sortable: true / false,
  },
  {
    field: "ncm_ex",
    headerText: "NCM EX",
    width: 150,
    sortable: true / false,
  },
  {
    field: "mer_departamento",
    headerText: "DEPARTAMENTO",
    width: 180,
    sortable: true / false,
  },
  {
    field: "mer_setor",
    headerText: "SETOR",
    width: 150,
    sortable: true / false,
  },
  {
    field: "mer_grupo",
    headerText: "GRUPO",
    width: 250,
    sortable: true / false,
  },
  {
    field: "mer_subgrupo",
    headerText: "SUBGRUPO",
    width: 250,
    sortable: true / false,
  },
];
