import React from "react";
import { useGridManagerContext } from "context/GridManagerContext";
import { TagsAction } from "components/structure/Actions";
import { getTags } from "components/contexts/grid-manager/Filters/useCases";

export const ResultTags = () => {
  const { filterFields } = useGridManagerContext();
  // TODO: adicionar remover tags
  return (
    <>
      <TagsAction
        bodyTooltip="Remover todas as tags de pesquisar por"
        tags={getTags(filterFields, ["all"])}
      />
    </>
  );
};
