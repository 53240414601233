import styled from "styled-components";

export const Container = styled.div`
  padding: 24px 0;

  width: 1200px;
  overflow: auto;

  & > table {
    width: 100%;
    table-layout: auto;
    border-collapse: collapse;

    th {
      background-color: #e5e5e5;
    }

    th > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px;
      color: #6c7887;
      overflow: hidden;

      p {
        margin-right: 8px;
        text-transform: uppercase;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 200px;
      }
    }

    td > div {
      padding: 8px;
    }

    th,
    td {
      border: ${props => (props.hasData ? "1px solid black" : "none")};
    }
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;

  p {
    color: #fff;
  }

  svg {
    margin-right: 8px;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 24px;

  div {
    display: flex;
    flex-direction: column;
    margin-right: 24px;
    color: #304156;

    p {
      margin-bottom: 8px;
    }

    & select {
      width: 100%;
      height: 40px;
      border: 1px solid #c7cfd6;
    }
  }

  & > div:last-of-type {
    cursor: pointer;
    margin-left: auto;
  }
`;
