import React from "react";

import NodeBasedDragAndDrop from "../../components/NodeBasedDragAndDrop";
import { Container, MainSection } from "./styles";

function TemplateEditor() {
  return (
    <Container>
      <MainSection>
        <NodeBasedDragAndDrop />
      </MainSection>
    </Container>
  );
}

export default TemplateEditor;
