import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex: 1;
`;

export const ColumnAttributes = styled.div`
  width: 100%;
  height: 100%;
  min-height: 80px;
  display: flex;
  flex-direction: column;
`;

export const ColumnAttributesHeader = styled.div`
  height: 50px;
  width: 100%;
  background-color: #1b66b0;
  border-radius: 4px;
  padding: 7px 10px;
`;

export const ColumnAttributesBody = styled.div`
  display: flex;
  flex: 1;
`;
