import styled, { css } from "styled-components";

const getDefault = (value, defaultValue) =>
  typeof value === "string" ? value : defaultValue;

const containerModifiers = {
  displayFlex: () => css`
    display: flex;
  `,
  justifyCenter: () => css`
    ${containerModifiers.displayFlex()}
    justify-content: center;
  `,
  justifyEnd: () => css`
    ${containerModifiers.displayFlex()}
    justify-content: flex-end;
  `,
  spaceBetween: () => css`
    ${containerModifiers.displayFlex()}
    justify-content: space-between;
  `,
  padding: ({ paddingSize = "xs", theme }) => css`
    padding: ${theme.spacing[getDefault(paddingSize, "xs")]};
  `,

  gridGap: ({ paddingSize = "xs", gridGap, theme }) => css`
    gap: ${theme.spacing[getDefault(gridGap, getDefault(paddingSize, "xs"))]};
  `,

  gridColumns: ({ gridColumns = "30% 70%;" }) => css`
    display: grid;
    grid-template-columns: ${getDefault(gridColumns, "30% 69%")};
  `,
  flexFullWidth: () => css`
    flex: 1;
  `,
  flexColumn: () => css`
    flex-direction: column;
  `,
  maxWidth: ({ maxWidth }) => css`
    max-width: ${maxWidth};
  `,
};

export const Container = styled.div`
  ${({
    justifyCenter,
    gridGap,
    paddingSize,
    justifyEnd,
    displayFlex,
    isFlex,
    gridColumns,
    flexFullWidth,
    maxWidth,
    flexColumn,
    ...props
  }) => css`
    ${(displayFlex || isFlex) && containerModifiers.displayFlex({ ...props })}
    ${paddingSize && containerModifiers.padding({ paddingSize, ...props })}
    ${justifyCenter && containerModifiers.justifyCenter()}
    ${justifyEnd && containerModifiers.justifyEnd()}
    ${gridGap && containerModifiers.gridGap({ gridGap, ...props })}
    ${gridColumns && containerModifiers.gridColumns({ gridColumns, ...props })}
    ${flexFullWidth && containerModifiers.flexFullWidth()}
    ${maxWidth && containerModifiers.maxWidth({ maxWidth })}
    ${flexColumn && containerModifiers.flexColumn()}
  `}
`;
